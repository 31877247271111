<template>
  <div class="jackpot"> 
        <div class="jackpot_main flex" v-if="item.length">
          <div class="jackpot_main_item start" @click="awardsView(item[0])">
            <div class="text-center hover_none item_text"></div>
            <div class="text-center tip item_text">点击查看</div>
            <div class="img_box">
              <div class="img_bg img_bg1">
                <img :src="item[0].cover" alt="" class="prize">
              </div>
            </div>
            <img src="../../../assets/love/5.png" width="110" />
          </div>
          <div class="jackpot_main_item " @click="awardsView(item[3+x])" v-for="x in 4" :key="x">
            <div class="text-center hover_none item_text"></div>
            <div class="text-center tip item_text">点击查看</div>
            <div class="img_box">
              <div class="img_bg img_bg4">
                <img :src="item[3+x].cover" alt="" class="prize">
              </div>
            </div>
          </div>
          <div class="jackpot_main_item end" @click="awardsView(item[3])">
            <div class="text-center hover_none item_text"></div>
            <div class="text-center tip item_text">点击查看</div>
            <div class="img_box">
              <div class="img_bg img_bg3">
                <img :src="item[3].cover" alt="" class="prize">
              </div>
            </div>
            <img src="../../../assets/love/4.png" width="110" />
          </div>
        </div>
        
        <div class="jackpot_main flex" v-if="item.length">
          <div class="jackpot_main_item start " @click="awardsView(item[1])">
            <div class="text-center hover_none item_text"></div>
            <div class="text-center tip item_text">点击查看</div>
            <div class="img_box">
              <div class="img_bg img_bg2">
                <img :src="item[1].cover"  alt="" class="prize">
              </div>
            </div>
            <img src="../../../assets/love/1.png" width="110" />
          </div>
          <div class="jackpot_main_item " @click="awardsView(item[7+a])" v-for="a in 4" :key="a">
            <div class="text-center hover_none item_text"></div>
            <div class="text-center tip item_text">点击查看</div>
            <div class="img_box">
              <div class="img_bg img_bg4">
                <img :src="item[7+a].cover" alt="" class="prize">
              </div>
            </div>
          </div>
          <div class="jackpot_main_item end" @click="awardsView(item[2])">
            <div class="text-center hover_none item_text"></div>
            <div class="text-center tip item_text">点击查看</div>
            <div class="img_box">
              <div class="img_bg img_bg2">
                <img :src="item[2].cover" alt="" class="prize">
              </div>
            </div>
            <img src="../../../assets/love/3.png" width="110" />
          </div>
        </div>
  </div>
</template>

<script>
export default {
  props:{
    item:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return{

    }
  },
  methods:{
    awardsView(data){
      this.$emit('awardsView',data)
    }
  }
}
</script>

<style>
</style>