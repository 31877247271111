
import {request} from "../index";


function getBoxList(){
    return request({
        url: `/box/list`,
        method:'get'
    })
}

function getBoxHistory(id,typeId){
    if(id==undefined && typeId==undefined){
        return request({
            // url: `/box/history`,
            url: `/top_history`,
            method:'get'
        })
    }else {
        return request({
            url: `/box/history?id=${id}&type_id=${typeId}`,
            method:'get'
        })
    }
}

function getBoxDetail(id){
    return request({
        url: `/box/detail?id=${id}`,
        method:'get'
    })
}
function getGbBoxDetail(id){
    return request({
        url: `/box/gb-detail?id=${id}`,
        method:'get'
    })
}

function getBoxOpen(data){
    return request({
        url: `/box/open`,
        method:'post',
        data
    })
}

function getRecycle(data) {
    return request({
        url: `/cash`,
        method:'post',
        data
    })
}

function getROllListthree() {
    return request({
        url: `/rooms`,
        method:'get',

    })
}

function get_box_hot() {
    return request({
        url: `/box/hot`,
        method:'get',

    })
}

export default {
    getGbBoxDetail,
    getBoxList,
    getBoxHistory,
    getBoxDetail,
    getBoxOpen,
    getRecycle,
    getROllListthree,
    get_box_hot

}
