import {request} from "../index";

function getWelfare(){
    return request({
        url: `/welfare`,
        method:'get',
        async: false
    })
}

function get_box_detail(id){
    return request({
        url: `/box/detail?id=${id}`,
        method:'get',
        async: true
    })
}
function get_welfare_open(data){
    return request({
        url: `/welfare/open`,
        method:'post',
        data,
        async: false
    })
}

export default {
    getWelfare,
    get_box_detail,
    get_welfare_open
}
