<template>
    <div class="d-flex">
        <div class="pay_main">
            <img v-if="sUserAgent !== 'phone'" src="../../assets/img/ADT/user/cz_title.png" width="140" />
            <div class="list_main_head">
                <h2 v-if="sUserAgent == 'phone'">在线充值</h2>
                <div class="pay_banner" @click="keFu"> 
                    <img class="pc-none" src="../../assets/img/pay/pay_banner.png" alt=""> 
                    <img class="m-none" src="../../assets/img/pay/pay_banner_web.png" alt="">
                </div>
                <div class="head_lv">
                    当前会员等级<img :src="`${require(`@/assets/img/ADT/common/V${user_info.vip_level}@2x.png`)}`" alt="">充值加成<span class="text-huang text-bold margin-left-xs">{{Vip ? Vip.rebate : '0.00' }}%</span>
                    <span v-if="sUserAgent !== 'phone'">(<span>您距离升级{{Viptips.next_level}}还需充值<img src="@/assets/img/ADT/home/UP@2x.png" width="28" />{{Viptips.next_lack}}.将享受 <span class="text-huang">{{Viptips.next_rebate}}%</span> 充值加成。</span>)</span>
                </div>
                <div @click="getUsetInof" v-if="payTipsShow" class="_a common-btn-main margin-top">
                    充值完成
                </div>
            </div>
            <div class="pay_main_list">
                <div class="pay_main_list_head" v-if="sUserAgent !== 'phone'">
                    <div class="_a" v-for="(item,index) in payData.wechat" :key="'w'+index" @click="payModeWeChat(item.paycode)">
                        <img src="../../assets/img/pay/gou_set.png" v-if="pay_way==item.paycode" />
                        <img src="../../assets/img/pay/gou.png" v-else/>
                        <img  class="pay" src="../../assets/img/pay/we.png"/>微信支付
                        <!-- {{index+1}} -->
                    </div>
                    <div class="_a" v-for="(item,index) in payData.alipay" :key="'a'+index" @click="payModeAlipay(item.paycode)">
                        <img src="../../assets/img/ADT/user/x.png" v-if="pay_way==item.paycode" />
                        <img src="../../assets/img/ADT/user/xx.png" v-else/>
                        <img class="pay" v-if="pay_way_list[item.paycode]" :src="require(`@/assets/img/ADT/user/${pay_way_list[item.paycode].img}`)" width="35"/>
                        <img class="pay" v-else src="../../assets/img/ADT/user/alipay.png" width="35"/>
                        {{ getPayWayVal(item.paycode, 'name') || '支付宝' }}
                        <!-- {{index+1}} -->
                    </div>
                </div>
                <div class="pay_main_list_main">
                    
                    <div class="list_main_main">
                        <div class="item_main _a" v-for="(item,index) in beansList" :key="index" @click="beansClick(item,(index+1))" :class="index == beansIndex - 1?'active':''">
                            <div class="money-box">
                                <img src="@/assets/img/ADT/home/UP@2x.png" width="30" /> {{(item.bean*1).toFixed(0)}}
                            </div>
                            <img class="tiptip" v-if="index==2 || index==4 || index==5 || index==7" src="@/assets/img/ADT/user/hot.png" width="60" />
                            <div class="text-huang chargeFirst" style="font-size:12px" v-if="tableData.length == 0">
                                首充赠送<img src="@/assets/img/ADT/home/UP@2x.png" width="14" /><span style="color: #30B1D4;font-weight: bold;">{{(Number((item.bean*1).toFixed(0))*item.firdt.ratio)/100}} </span>
                                <span>,实际到账<img src="@/assets/img/ADT/home/UP@2x.png" width="14" />{{Number((item.bean*1).toFixed(0))+(Number((item.bean*1).toFixed(0))*item.firdt.ratio)/100}}</span>
                            </div>
                            <div class="text-huang chargeFirst" style="font-size:12px" v-if="tableData.length != 0">
                                赠送<img src="@/assets/img/ADT/home/UP@2x.png" width="14" /><span style="color: #30B1D4;font-weight: bold;">{{((item.price*(1+((Vip?Vip.rebate:0)/100))).toFixed(2) - item.price).toFixed(2) }} </span>
                                <span>,实际到账<img src="@/assets/img/ADT/home/UP@2x.png" width="14" />{{(item.price*(1+((Vip?Vip.rebate:0)/100))).toFixed(2)}}</span>
                            </div>
                            <img v-if="index == beansIndex - 1" class="success" src="@/assets/img/ADT/user/success.png" />
                        </div>
                    </div>
                    <div class="recharge_tips" v-if="sUserAgent == 'phone'">(<span>您距离升级{{Viptips.next_level}}还需充值 <money width="22px" />{{Viptips.next_lack}}.将享受<span class="text-huang">{{Viptips.next_rebate}}%</span>充值加成.</span>)</div>
                    <div class="taobaojili_name">吉利卡权益兑换</div>
                    <el-row class="taobaojili">
                        <input class="cards" type="text" v-model="taobaojili.card" placeholder="请输入卡号"> <br>
                        <input class="password" type="text" v-model="taobaojili.password" placeholder="请输入密码"><br>
                        <button class="btn" @click="taobaoCardRecharge">权益卡激活</button>
                    </el-row>
                    <div class="list_main_recharge" v-if="beansIndex>0">
                        实付金额
                        <span class="text-blue text-bold">{{resData.price}}</span>
                        <span class="margin-lr-xs">实际到账</span>
                        <span class="text-bold text-huang" v-if="tableData.length != 0">
                            <img src="@/assets/img/ADT/home/UP@2x.png" width="18" />{{(resData.price*(1+((Vip?Vip.rebate:0)/100))).toFixed(2)}}
                        </span>
                        <span class="text-bold text-huang" v-else-if="beansList.length>0&&tableData.length == 0">
                            <img src="@/assets/img/ADT/home/UP@2x.png" width="18" />{{Number((beansList[beansIndex-1].bean*1).toFixed(0))+(Number((beansList[beansIndex-1].bean*1).toFixed(0))*beansList[beansIndex-1].firdt.ratio)/100}}
                        </span>
                        <div class="submit" v-if="sUserAgent !== 'phone'" @click="rechargeConfirm()">
                            确认充值
                        </div>
                    </div>
                    <div class="pay_list" v-if="sUserAgent === 'phone'">
                        <div class="bg_green"  v-for="(item,index) in payData.wechat" :key="'w'+index" @click="payModeWeChat(item.paycode)">
                            <img src="../../assets/img/pay/we.png"/>微信支付
                            <!-- {{index+1}} -->
                        </div>
                       <div class="bg_blue" v-for="(item,index) in payData.alipay" :key="'a'+index" @click="payModeAlipay(item.paycode)">
                            <img v-if="pay_way_list[item.paycode]" :src="require(`@/assets/img/ADT/user/${pay_way_list[item.paycode].img}`)">
                            <img v-else src="../../assets/img/ADT/user/alipay.png" />
                            {{ getPayWayVal(item.paycode, 'name') || '支付宝' }}
                            <!-- {{index+1}} -->
                        </div>
                     
                    </div>
                    <!-- <div class="list_foot justify-between">
                        <div class="flex">
                            <input class="input" placeholder="" type="text" v-model="CardRecharge">
                            <div class="btn" @click="getCardRecharge">卡密充值</div>
                        </div>
                        <div class="servce" style="line-height:40px">
                            如果充值遇到问题，请更换充值方式，或者<span class="text-huang _a" @click="keFu">联系客服</span>
                        </div>
                    </div> -->
                </div>
                
                <!-- <div class="tips">为了保证您能顺利的领取到饰品，请您在充值前确认本账号是否被 Steam限制交易，请自行前往Steam社区查看该账号的交易安全内容</div> -->
                <div class="tipsRow">
                    <div>
                        充值遇到问题?<br class="m-none"> 请更换充值方式 <br class="m-none">或者 <br class="m-none"> <span class="text-huang _a" @click="keFu">联系客服</span>
                    </div>
                    <div class="tips">
                        <el-checkbox class="tipschecked" v-model="checkedTip"></el-checkbox>本人已满18周岁且具备完全行为能力，充值即代表同意<a href="/active/help/2">《用户协议》</a> <a href="/active/help/3">《隐私条款》</a> <br>
                        <br>网站内禁止转卖饰品<br>
                        开箱娱乐请理性消费，切勿上头<br>
                        未满十八 (18)岁不得使用本服务<br>
                        在充值前请您仔细阅读《用户协议》<br>
                        成功充值即代表您对协议无异议
                    </div>
                </div>
            </div>
        </div>


        <!--微信支付h5 显示二位提供截图保存-->
        <div class="qrbox"  v-if="isWechatSuccess">
            <div class="qrcontent" >
                <div class="close"  @click="webchatClose" >×</div>
                <div class="tiptext">支付金额</div>
                <div class="price" >￥{{resData.price}}</div>
                <div style="width: 240px; height: 240px;padding-top: 1rem;">
                    <img :src="wechatUrl" style="width: 15rem;height: 15rem;"  />
                </div>
                <div class="tiptext" style="margin-top: 2.5rem;">截图保存二维码，使用微信扫描识别二维码</div>
                <div class="saveqr" @click="saveqr">截图保存二维码</div>
            </div>
        </div>
        <el-dialog title="" custom-class="custom_dialog" top="30vh" :close-on-click-modal="false" :visible.sync="dialogVisible" append-to-body center :width="sUserAgent !== 'phone'?'400px':'80%'">
           <div class="padding">
                <div v-if="sUserAgent !== 'phone'">
                    <div class="mt-5 d-sm-block d-none text-center" style="font-size: 18px">
                        <div class="mb-3">实付金额</div>
                        <div style="font-size:16px">
                            <span style="font-size:12px">RMB</span>{{resData.price}}
                        </div>
                    </div>
                    <div class="d-sm-block d-none">
                        <div class="mt-5 d-flex justify-content-center">
                            <img class="" width="200px" height="200px" v-lazy="codeIsShow || require('@/assets/img/code_pay.png')" alt="">
                        </div>
                    </div>
                    <div class="text-center d-sm-block d-none mt-4" style="font-size: 12px">
                        请使用 <span class="color-yellow">”{{payModeActiveName}}扫一扫“</span>确认支付
                        <div class="mt-2">二维码只能使用一次，再次充值请重新选择金额</div>
                    </div>
                </div>
                <div v-else class="pay-tip-show">
                    请确认订单是否充值完成
                </div>
                <div @click="getUsetInof" class="_a common-btn-main margin-top text-white">
                    充值完成
                </div>
           </div>
        </el-dialog>

        <!-- 淘宝支付弹窗 -->
        <taobao_pay_win :dialogVisibleTaobao.sync="dialogVisibleTaobao" :h5_link="h5_link" :pay_way="pay_way"></taobao_pay_win>
        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 700px; left: calc(50% - 350px); top: 35vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2 goCenter" style="line-height: 2">
                    尊敬的用户您好,您暂未实名认证,请前去认证才能完成充值操作。
                    <a @click="$router.push('/user/user')"> 去认证 <img src="@/assets/newsImg/shangjinrenwu.webp" /></a>
                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px; top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i>
                    </div>
                </div>
            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
        </div>
    </div>
</template>


<script>

    import $api from '@/api/user/index'
    import $apiVip from '@/api/vip/index'
    import sUserAgent from '@/assets/js/common.js'
    import {mapState} from 'vuex'
    import money from '../../common/money/money.vue'
    import taobao_pay_win from './taobao_pay_win.vue'

    export default {
    components: { money,taobao_pay_win },
        name: "recharge",
        data() {
            return {
                dialogVisibleTaobao: false,
                h5_link: '',
                pay_way_list: {
                    100: {
                        name : '淘宝支付',
                        img : 'taobao.png'
                    },
                    99: {
                        name : '京东支付',
                        img : 'jd.png'
                    },
                    97: {
                        name : '淘宝吉利',
                        img : 'taobao.png'
                    }
                },
                playingMethod:false,
                payTipsShow:false,
                dialogVisible:false,
                isWechatSuccess:false,
                wechatUrl:'',
                pay_way:0,//支付通道 默认6
                // 苹果充值
                isiOS:false,
                // 苹果地址链接
                isiOSUrl:'',
                // 充值记录
                tableData: [],
                // 充值的列表 数据
                beansList:[],
                // d点击那个显示那个
                beansIndex:0,
                // 显示数据
                resData:{
                    price:''
                },
                // 卡密
                CardRecharge:'',
                // 充值方式 支付宝Alipay 微信WeChat
                payModeActive:'Alipay',
                // 二维码显示
                payUrl:'',
                // 获取当前的余额
                money:'',
                // 销毁定时器
                time:'time',
                total:0,
                pageSize:0,
                // 首充奖励
                firdtList:[],
                // 点击充值保存
                beansClickData:{
                    id:0
                },
                // 关于vip
                Vip:{},
                Viptips:{},
                payData:{},
                checkedTip: true,
                wechatCodes: [8,10,12,26,32,35],
                taobaojili: {
                    card: '',
                    password: ''
                }
            }
        },
        created() {

        },
        mounted() {
            this.payList();
        },
        watch:{
            isWechatSuccess(val){
                if(!val){
                    this.getUsetInof()
                }
            }
        },
        computed:{
            ...mapState(['user_info','web_info']),
            codeIsShow:{
                get(){
                    return this.payUrl
                }
            },
            sUserAgent(){
                return sUserAgent.sUserAgent()
            },
            payModeActiveName() {
                return this.payModeActive == 'Alipay' ? '支付宝': '微信'
            },
            pay_way_list_ids() {
                return Object.keys(this.pay_way_list).map((val) => {
                    return Number(val)
                })
            }
        },
        beforeDestroy(){
            clearInterval(this.time)
        },
        methods:{
            taobaoCardRecharge() {
                if (!this.taobaojili.card) {
                    this.$message('请输入卡号'); return
                }
                if (!this.taobaojili.password) {
                    this.$message('请输入密码'); return
                }
                let {code} = $api.taobaoCardRecharge(this.taobaojili)
                if (code == 500) return 
                this.$message.success('激活成功')
            },
            getPayWayVal(code, key) {
                return this.pay_way_list[code] ? this.pay_way_list[code][key] : ''
            },
            async payList() {
                let {data} = await $api.getPayList()
                // if (data.alipay) {
                //     data.alipay.push({paycode:100})
                // }
                
                this.payData=data;
                if( data.alipay.length != 0 && data.wechat.length !=0  ){
                    this.pay_way = data.alipay[0].paycode;
                }
                if( data.alipay.length != 0 && data.wechat.length ==0 ){
                    this.pay_way = data.alipay[0].paycode;
                }
                if( data.alipay.length == 0 && data.wechat.length !=0 ){
                    this.pay_way = data.wechat[0].paycode;
                }
                // console.log(this.payData)
                this.init();
            },
            keFu(){
                window.open('/chatlink.html',"_blank")
            },
            async init(){
                this.getBeans()
                this.getRecharge_record()

                // 刷新之后会有一个间隔获取不到vuex上的值
                setTimeout(()=>{
                    this.money = JSON.parse(JSON.stringify(this.$store.state.user_info.bean))
                    this.getVipList()
                },500)
            },
            webchatClose(){
                this.isWechatSuccess=false
                this.dialogVisible=true
            },
            saveqr(){
                this.$message('请截图保存二维码')
            },
            // 初始化
            async getBeans(){
                try {
                    let {data} = await $api.getBeans()
                    let {data:firdt} = await $api.getbeans_first()
                    data.forEach((item,i)=>{
                        item.firdt = firdt[i]
                    })
                    this.firdtList = firdt
                    this.beansList = data
                    this.resData.price = data[0].price
                    // await this.getRecharge()
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            // 获取vip信息
            getVipList(){
                $apiVip.getVipList().then(res =>{
                    this.Vip = res.data.list.find((item) =>{
                        return  item.level == this.user_info.vip_level
                    })

                    this.Viptips = res.data.tips
                })
            },
            // 选择充值金额
            beansClick(item,index){
                this.beansIndex = index
                // 右侧显示对应的金额
                this.resData.price = item.price

                let data = {
                    id:item.id,pay_way:this.pay_way
                }
                this.beansClickData = data
                if(this.sUserAgent === 'web'){
                    if (this.payModeActive == 'Alipay') {
                        this.payModeAlipay(this.pay_way)
                    } else {
                        this.payModeWeChat(this.pay_way)
                    }
                }
            },
            // 选择支付宝充值
            payModeAlipay(pay_way=9){
                this.payModeActive = 'Alipay'
                this.pay_way=pay_way;
                if(this.beansClickData.id == 0){
                    //this.$message('选择充值金额')
                    return
                }
                if (!this.checkedTip) {
                    this.$message('选择勾选充值协议')
                    return
                }
                // this.beansIndex = 0
                // this.payUrl = ''
                if(this.sUserAgent === 'phone')this.getRecharge({id:this.beansClickData.id,pay_way:pay_way})
                // this.init()
            },
            //确认充值
            rechargeConfirm(){
                if (!this.checkedTip) {
                    this.$message('选择勾选充值协议')
                    return
                }
                
                if( !this.user_info.is_verify ){
                    this.$notify.closeAll()
                    this.$notify({
                        type:'warning',
                        message:'尊敬的用户您好,您暂未实名认证,请前去认证才能完成充值操作。',
                        position: 'top-left'
                    });
                    setTimeout(() => {
                        this.$router.push('/user/user')
                    },1000)
                    return;
                }
                this.getRecharge({id:this.beansClickData.id,pay_way:this.pay_way})
                this.payTipsShow=true
                if (!this.pay_way_list_ids.includes(this.pay_way)) {
                    this.dialogVisible=true
                }
                
            },
            // 选择微信充值
            payModeWeChat(pay_way=12){
                this.payModeActive = 'WeChat'
                this.pay_way=pay_way
                if(this.beansClickData.id == 0){
                    // this.$message('选择充值金额')
                    return
                }
                if (!this.checkedTip) {
                    this.$message('选择勾选充值协议')
                    return
                }
                // this.beansIndex = 0
                
                // this.payUrl = ''
                if(this.sUserAgent === 'phone'){
                    this.getRecharge({id:this.beansClickData.id,pay_way:pay_way})
                }
                // this.init()
            },
            // 卡密充值
            async getCardRecharge(){
                if (!this.checkedTip) {
                    this.$message('选择勾选充值协议')
                    return
                }
                try {

                    let {code,message} = await $api.getCardRecharge({card:this.CardRecharge})
                    if(code == 500)return
                    if(code == 200){
                        if(this.beansList.length == 0 || this.user_info.is_recharge == 0){
                            this.$baidu.baiduApi(10,this.user_info.bd_vid)
                            if(this.user_info.bd_vid)this.shenMaLogin(this.user_info.bd_vid)
                        }
                        this.$message.success(message)
                        this.$store.dispatch('userInfo')
                        this.CardRecharge = ''
                    }
                    this.init()
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            // 充值记录
            async getRecharge_record(page = 1){
                try {
                    let {data,code} =await $api.getRecharge_record(page)
                    if(code == 500)return
                    this.tableData = data.data
                    this.total = data.total
                    this.pageSize = data.per_page
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            // 翻页
            currentChange(val){
                this.getRecharge_record(val)
            },
            // 充值，默认支付宝充值
            async getRecharge(data = {id:1,pay_way:11}){
                // 充值方式 支付宝Alipay 微信WeChat
                // console.log(data.pay_way)
                if(this.wechatCodes.includes(data.pay_way)){
                    this.payModeActive='WeChat'
                }else{
                    this.payModeActive='Alipay'
                }

                

                // let iosUrl = null
                if(this.sUserAgent !='web'){
                    if(!this.wechatCodes.includes(data.pay_way)){
                        // iosUrl =  window.open('','_blank ')
                    }
                }

                let loading = null
                try {
                    loading = this.$loading({
                        lock: true,
                        text: '正在加载',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    })
                    
                    let {data:resData,code} = await $api.getRecharge(data)

                    if(code == 500) {
                        // this.$message('错误，' + message)
                        loading.close();
                        return
                    }
                    

                    if(this.sUserAgent =='web'){
                        if(this.pay_way_list_ids.includes(data.pay_way)){
                            this.h5_link = resData.list.h5
                            this.dialogVisibleTaobao = true
                            // window.location.href = resData.list.h5
                        } else {
                            this.payUrl = resData.list.qr_url
                        }
                    }else {
                        console.log(this.wechatCodes.includes(data.pay_way))
                        if(this.wechatCodes.includes(data.pay_way)){
                            this.wechatUrl = resData.list.qr_url
                            this.isWechatSuccess=true
                        } else {
                            if (this.pay_way_list_ids.includes(data.pay_way)) {
                                this.h5_link = resData.list.h5
                                this.dialogVisibleTaobao = true
                            } else {
                                window.location.href = resData.list.h5
                            }
                        }
                    }
                    setTimeout(async () => {
                        loading.close();
                        this.$message('请稍后......')
                        this.getUsetInof()
                    }, 500);
                }catch (e) {
                    // 直接跳转到一个页面  在这个页面进行支付宝支付
                    loading.close();
                    this.getUsetInof()
                    this.$message('抱歉，请稍后......')
                    let routeUrl = this.$router.resolve({
                        path: "/user/Payment",
                        query: {
                            id: data.id,
                            pay_way:data.pay_way
                        }
                    });
                    // console.log(routeUrl.href)
                    window.open(routeUrl.href, '_self')
                }
                if(this.sUserAgent !=='web' && this.payModeActive==='Alipay'){
                    this.dialogVisible=true
                }
            },
            //神马对接
            shenMaLogin(code){
                this.$baidu.uploadConvertData({link:encodeURIComponent(code),event_type:11})
            },
            // 轮询获取个人资料的金额，和现在保存的金额做比较，。之后大于现在保存的金额的话就是充值成功
            async getUsetInof(){
                // this.time = setInterval(async ()=>{
                    try {
                        let {data,code} = await $api.getUsetInof()
                        if(code == 500)return

                        if(parseFloat(data.bean) > parseFloat(this.money)){
                            this.isWechatSuccess=false;
                            // 更新用户
                            this.$store.dispatch('userInfo')
                            // 充值提示
                            this.$message.success('充值成功')
                            this.dialogVisible=false
                            // 更新价格
                            this.money = JSON.parse(JSON.stringify(this.$store.state.user_info.bean))

                            // 充值成功之后直接走这个
                            if(this.beansList.length == 0 || this.user_info.is_recharge == 0){
                                // let bd_vid = await window.localStorage.getItem('popularize') // 耿哥写好之后 将这个删除
                                //this.$baidu.baiduApi(10,bd_vid) // 修改这个位置传输的是user内的地址

                                this.$baidu.baiduApi(10,this.user_info.bd_vid)
                                

                                this.shenMaLogin(this.user_info.bd_vid)
                            }
                            // 停止定时器
                            // clearTimeout(this.time)
                        }
                    }catch (e) {
                        // window.localStorage.setItem('popularize','0')
                        console.log(e)
                    }
                // },1000)
            },
        }
    }
</script>

<style lang="scss">
.taobaojili_name {
    text-align: left;
    padding: 8px 0;
    @media (max-width:600px) {
        padding: 8px 10px;
    }
}
.taobaojili {
    display: flex;
    align-items: center;
    text-align: left;
    @media (max-width:600px) {
        padding: 0 10px;
        display: block;
        input {
            margin-bottom: 5px;
            width: 100%;
        }
        .btn {
            margin-bottom: 20px;
        }
    }
    .name {
        margin-right: 5px;
    }
    input {
        border: 1px solid #1f3a55;
        padding: 8px;
        margin-right: 5px;
        color: #fff;
    }
    .btn {
        background: #1f3a55;
        color: #fff;
        padding: 7px;
    }
}
.tipsRow {
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 20px;
    @media (max-width:600px) {
        flex-direction: column-reverse;
        line-height: 24px;
    }
    >div:nth-child(1)  {
        margin-top: 20px;
        text-align: left;
        ._a {
            border: 2px solid #465770;
            padding: 0 15px;
            border-radius: 20px;
            color: #fff;
            margin-top: 5px;
            display: inline-block;
        }
        @media (max-width:600px) {
            text-align: center;
        }
        
    }
    >div:nth-child(2)  {
        a {
            color: #fff;
        }
        text-align: right;
        @media (max-width:600px) {
            text-align: left;
        }
    }
    .tipschecked {
        padding-right: 10px;
        .el-checkbox__inner {
            background-color:unset;
            border-color: #005fa7;
        }
        .el-checkbox__input.is-checked {
            background-color: unset !important;
            .el-checkbox__inner {
                background-color:unset;
                &::after {
                    border-color: #409EFF;
                }
            }
        }
    }
}
.list_main_head{
    @media (max-width:600px) {
        > h2{
            text-align: left;
            font-weight: bold;
            padding-left: 10px;
            font-size: 18px;
            margin-bottom: 12px;
        }
    }
    .pay_banner {
        margin: 15px 10px;
        img {
            width: 100%;
        }
        @media (min-width: 600px) {
            margin: 15px 0;
        }
    }
    > .head_lv{
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:600px) {
            margin-bottom: 14px;
            font-size: 14px;
            justify-content: left;
            margin-bottom: 10px;
            color: #ccc;
            padding-left: 10px;
            > img{
                height: 20px;
                width: 20px !important;
                @media (max-width:600px) {
                    margin: 0 5px !important;
                }
            }
        }
        > img{
            width: 30px;
            margin: 0 10px;
        }
        >span{
            font-family: Monaco !important;
            display: flex;
            align-items: center;
            > span {
                display: flex;
                align-items: center;
                font-family: Monaco;
                > img{
                    width: 18px !important;
                    margin: 0 5px;
                }
                > span {
                    font-family: Monaco;
                }
            }
        }
    }
}

.goCenter{
    display: flex;
    align-items: center;
    font-size: 16px !important;
    @media (max-width:600px) {
        flex-direction: column;
        padding: 20px 20px !important;
    }
    > a{
        display: flex;
        align-items: center;
        color: #E5B375 !important;
        text-decoration:underline !important;
        cursor: pointer;
    }
}
@media (max-width:600px) {
    .user_new{
        .el-dialog{
            width:100%;
            margin: 0 10px;
            min-height:inherit;
            top:25vh;
        }
    }
}
.pay_main{
    max-width: 1200px;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    margin: 0 auto;
    > img{
        margin-bottom: 20px;
    }
    .chargeFirst{
        font-weight:400;
        img{
            vertical-align: middle;
        }
    }
    .recharge_tips{
        font-size: 12px;
        background-color: #21263D;
        padding: 4px 0 6px 0;
        margin-bottom: 2px;
        @media (max-width:600px) {
            font-size: 12px;
            font-weight: 300;
            font-family: Monaco;
            background-color: transparent;
            padding: 0 10px;
            margin-bottom: 12px;
            > span {
                font-family: Monaco;
                > span {
                    font-family: Monaco;
                }
            }
        }
        img{
            vertical-align: middle;
        }
    }
    &_list{
        &_head{
            margin-top:30px;
            width: 100%;
            margin-bottom: 20px;
            background: #19354a8f;
            padding: 15px 30px;
            display: flex;
            @media (max-width:1300px) {
                display: none;
            }
            div{
                line-height: 50px;
                display: flex;
                font-family: Monaco;
                align-items: center;
                margin-right: 20px;
                .pay{
                    margin-right: 10px;
                    border-radius: 50%;
                    margin-left: 15px;
                }
            }
        }
        &_main{
            // background: #19354a8f;
            .list_main_head{
                text-align: center;
                position: relative;
                line-height: 30px;
                padding: 20px;
                .head_lv{
                    position: absolute;
                    left: 20px;
                    line-height: 30px;
                    img{
                        line-height: 30px;
                        margin: 0 10px;
                        vertical-align: middle;
                    }
                    @media (max-width:1300px) {
                        position: relative;
                    }
                }
                .common-btn-main{
                    font-size: 14px;
                    position: absolute;
                    top: -10px;
                    right: 20px;
                    @media (max-width:1300px) {
                        display: none;
                    }
                }
            }
            .list_main_main{
                padding: 0px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 20px;
                margin-bottom: 30px;
                @media (max-width:600px) {
                    grid-gap: 10px;
                    padding: 10px;
                }
                .item_main{
                    text-align: center;
                    position: relative;
                    font-weight: bold;
                    padding:30px 0; 
                    font-size: 24px;
                    background: #244866b7;
                    border: solid 2px transparent !important;
                    color: #fff;
                    border-radius: 4px;
                    @media (max-width:600px) {
                        background-color: #182941c6;
                        border: solid 1px #202E54;
                    }
                    > .success{
                        height: 45px;
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        @media (max-width:600px) {
                            height: 30px;
                        }
                    }
                    > .tiptip{
                        width:55px;
                        position: absolute;
                        left:0px;
                        top:0px;
                    }
                    > .money-box{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 30px;
                        font-family: Monaco;
                        margin-bottom: 16px;
                        @media (max-width:600px) {
                            font-size: 18px;
                            font-weight: bold;
                        }
                        > img{
                            margin-right: 8px;
                            @media (max-width:600px) {
                                width: 18px;
                                margin-right: 4px;
                            }
                        }
                        
                    }
                    > .chargeFirst{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @media (max-width:600px) {
                                display: inherit;
                                align-items: inherit;
                                justify-content: inherit;
                                padding: 0 5px;
                            }
                            > img{
                                margin: 0 4px;
                            }
                            > span {
                                display: flex;
                                align-items: center;
                                
                                font-family: Monaco;
                                font-size: 14px;
                                @media (max-width:600px) {
                                    display: inline-block;
                                    font-weight: 300;
                                    align-items: inherit;
                                    font-size: 12px;
                                }
                                > img{
                                    margin: 0 4px;
                                }
                            }
                        }
                    @media (max-width:1300px) {
                        height: auto;
                        font-size: 20px;
                        padding-top:16px;
                        padding-bottom:16px;
                        > .tiptip{
                            width:40px;
                        }
                        > .money-box{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            > .money{
                                margin-top:0px !important;
                            }
                        }
                    }
                }
                .item_main.active{
                    background: #f9c64510;
                    border: 2px solid #F9C545 !important;
                }
            }
            .list_main_recharge{
                width: 100%;
                padding: 0px 20px;
                text-align: right;
                line-height: 20px;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media (max-width:1300px) {
                    text-align: center;
                    padding: 10px 20px 15px 20px;
                    font-size: 14px;
                    > span {
                        > img{
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
                img{
                    vertical-align: middle;
                    @media (max-width:1300px) {
                        height: 50px;
                    }
                }
                > .text-bold{
                    color: #FFC67B;
                }
                > span {
                    display: flex;
                    align-items: center;
                    font-family: Monaco;
                    > img{
                        margin-right: 4px;
                    }
                }
                > .submit{
                    cursor: pointer;
                    background-image: url(../../assets/img/ADT/user/czbtn.png);
                    background-size: 240px 60px;
                    width: 240px;
                    height: 60px;
                    text-align: center;
                    line-height: 60px;
                    color: #444;
                    font-weight: bold;
                    font-size: 18px;
                    margin-left: 20px;
                    > img{
                        width: 300px;
                    }
                }
            }
            .list_foot{
                padding: 40px 20px;
                display: flex;
                align-items: center;
                @media (max-width:1300px) {
                    font-size: 14px;
                    padding: 0px;
                    margin: 0 10px;
                    display: block;
                    > div{
                        justify-content: center;
                        margin-bottom: 0px;
                    }
                    > .servce{
                        text-align: left;
                        color: #ccc;
                        font-weight: 300;
                        font-size: 12px;
                    }
                }
                .input{
                    width: 300px;
                    line-height: 40px;
                    background: #1B1C25;
                    border-radius: 6px;
                    padding: 0 10px;
                    margin-right: 10px;
                    @media (max-width:1300px) {
                        width: 200px;
                        height: 45px;
                        line-height: 35px;
                        font-family: Monaco;
                        flex: 2;
                        border: solid .5px #444;
                    }
                }
                .btn{
                    width: 160px;
                    line-height: 45px;
                    background: #1A5066;
                    color: #fff;
                    @media (max-width:1300px) {
                        width: 120px;
                        height: 45px;
                        flex: 1;
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .tips{
        margin-top:20px;
        font-size: 14px;
        color: #ddd;
        line-height: 24px;
        text-align: left;
        @media (max-width:600px) {
            padding: 0 20px;
            line-height: 24px;
            font-family: Monaco;
            color: #ccc;
            font-weight: 300;
        }
    }
    .pay_list{
        padding: 0 20px;
        @media (max-width:600px) {
            border-radius: 4px;
            margin: 0 10px;
            padding: 0;
        }
        >div{
            padding: 15px 20px;
            font-size: 20px;
            border-radius: 4px;
            margin-bottom: 15px;
            img{
                height: 26px;
                vertical-align: middle;
                margin-right: 10px;
            }
            @media (max-width:600px) {
                font-family: Monaco;
                font-size: 18px;
                padding: 12px 20px;
            }
        }
        .bg_green{
            background: #23372C;
            border: 1px solid #5EC750;
            color: #1aff00;
            @media (max-width:600px) {
                background:#23372C;         
                border: none;      
            }
        }
        .bg_blue{
            background: #212741;
            border: 1px solid #5085C7;
            @media (max-width:600px) {
                background:inherit;     
                border: none;
                background-color: #1B677D;                  
            }
        }
    }
}
    .pay-tip-show{
        font-size: 18px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }
     /*微信h5需要截图保存  start*/
    .qrbox{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.3);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .qrbox .qrcontent{
        width: 18rem;
        height:26rem; ;
        background: #4f5769;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.04rem solid #ffa22a;
        border-radius: 0.10667rem;
        position: relative;
        color: #fff;
        padding-bottom: 0.36rem;
    }

    .qrbox .qrcontent .close{
        position: absolute;
        right: 0.13333rem;
        top: -0.06667rem;
        color: red;
        font-size: 2.66667rem;
    }



    .qrbox .qrcontent .price {
        font-size: 1.5rem;
        color: #ffa22a;
        padding-top: .5rem;
    }

    .qrbox .qrcontent .qr {
        margin: 0.53333rem 0 0.4rem;
        border-radius: 0.13333rem;
    }
    qrbox .qrcontent .uniontip {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .37333rem;
    }

    .qrbox .qrcontent .tiptext {
        font-size: 0.9rem;
        padding: 0 2px;
        text-align: center;
        font-weight: bold;
        width: 18rem;
        margin: 0.86667rem 0 0.33333rem;
    }

    .qrbox .qrcontent .saveqr {
        width: 6.92rem;
        height: 1.8rem;
        font-size: 0.8rem;
        background: #ffa22a;
        border-radius: 0.3rem;
        text-align: center;
        line-height: 1.90667rem;
        margin-top: 0.5rem;
    }
    /*微信h5需要截图保存  end*/
</style>