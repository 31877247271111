<template>
    <div class="taobaopay">
        <el-dialog
        title=""
        :visible.sync="dialogVisible"
        :width="width"
        >
        <div class="pay_1">{{ pay_name }}支付</div>
        <div class="pay_2"> <img src="@/assets/img/pay/taobaopay_bar.png" alt=""> </div>
        <div class="pay_3">充值说明</div>
        <div class="pay_4">
            <div class="pay_4_1">
                <p>复制用户手机号</p>
                <p>点击打开{{ pay_name }}充值</p>
                <p>到{{ pay_name }}第三方合作商铺购买</p>
                <p>在{{ pay_name }}订单界面充值账号处填入用户手机号购买完成，点 击已确定充值，或者刷新界面</p>
            </div>
        </div>
        <div class="pay_5"> 当前用户手机号： <span class="mobile">{{ user_info.mobile }}</span> <br class="pc-none"> <span class="copy" @click="CopyText(user_info.mobile)">复制</span></div>
        <div class="pay_6" @click="openUrl">打开{{ pay_name }}充值</div>
        <div class="pay_7" @click="getUsetInof_in">已确定充值</div>
        <div class="pay_8" @click="keFu"><img src="@/assets/img/pay/kefub.png" alt=""> 有任何问题，请联系网站客服</div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
    </el-dialog>
    </div>
    
</template>

<script>
// import {mapState} from 'vuex'
import common from '@/assets/js/common.js'
export default {
    name: 'taobao_pay_win',
    props: ['dialogVisibleTaobao','h5_link','pay_way'],
    computed: {
        user_info() {
            return this.$store.state.user_info
        },
        width() {
            return common.sUserAgent() == 'web' ? '25%' : '90%'
        },
        pay_name() {
            return {100:'淘宝', 99:'京东', 97: '淘宝吉利'}[this.pay_way]
        }
    },
    data() {
        return {
            dialogVisible:false
        }
    },
    watch: {
        dialogVisible: {
            handler(v) {
                this.$emit('update:dialogVisibleTaobao', v)
            }
        },
        dialogVisibleTaobao: {
            handler(v) {
                if (v) {
                    this.dialogVisible = this.dialogVisibleTaobao
                }
            }
        }
    },
    mounted() {
        // console.log(this.$store.state.user_info)
    },
    methods: {
        getUsetInof_in() {
            this.dialogVisible = false
            this.$parent.getUsetInof()
        },
        handleClose() {

        },
        CopyText(val) {
            common.CopyText({content: val})
        },
        openUrl() {
            console.log(this.h5_link)
            window.open(this.h5_link, '_blank')
        },
        keFu() {
            this.$parent.keFu()
        }
    }
}
</script>

<style lang="scss">
.taobaopay {
    .el-dialog {
        background: #17192C !important;
        opacity: 0.98;
        border-radius: 20px;
        padding: 0 45px;
        width: 520px !important;
        .el-dialog__close {
            color: #1E7A91;
            font-size: 30px;
            font-weight: bold;
        }
        @media (max-width:600px) {
            transform: scale(0.9);
            width: 100% !important;
            padding: 0 30px;
        }
    }
    .pay_1 {
        text-align: center;
        padding: 20px 0 5px 0;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
    }
    .pay_2 {
        text-align: center;
    }
    .pay_3 {
        color: #fff;
        font-size: 17px;
        font-weight: bold;
    }
    .pay_4 {
        background-image: url("../../assets/img/pay/taobaopay_list.png");
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 24px;
        height: 180px;
        padding-left: 35px;
    }
    .pay_4_1 p {
        margin-top: 18px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #20EEFF;
        // margin-bottom: 24px;
    }
    .pay_5 {
        margin: 20px 0 30px 0;
        color: #fff;
        
        
        font-size: 18px;
        @media (max-width:600px) {
            line-height: 25px;
        }
        .mobile {
            color: #FFC000;
            
            font-family: Microsoft YaHei;
        }
        .copy {
            padding: 8px 15px;
            color: #fff;
            cursor: pointer;
            background: #31344F;
            border-radius: 6px;
            margin-left: 5px;
            font-size: 14px;
        }
    }
    .pay_6 {
        width: 100%;
        padding: 15px 0;
        background-color: #1E7A91;
        color: #fff;
        text-align: center;
        margin-top: 15px;
        cursor: pointer;
        
        border-radius: 4px;
    }
    .pay_7 {
        @extend .pay_6;
        background-color: #18364D;
    }
    .pay_8 {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        gap: 5px;
        cursor: pointer;
    }
}
</style>
<style lang="scss" scoped>

</style>