<template>
    <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class="bg-bg weapon bg-size-100 bg-position-center"
         style="'width:96%;margin:0 auto'"
    >

        <div class="  bg-repeat position-relative
        justify-content-center align-items-center border-bottom-0"

        >
            <div class=" bg-bg text-center d-flex
             justify-content-center w-100 p-2" style="flex-direction: column;align-items: center;">
                <div class="flex" style="color: #fada62;font-weight:700;font-size:1rem;line-height:1.5rem"><money />{{resData.bean}}</div>
                <div style="height:12px"></div>
            </div>
            <div class="d-flex justify-content-center weapon-img" style="">
                <img height="100%"  class="position-relative z-index-1 p-4 weapon-image"
                     :src="resData.cover" alt="">
            </div>

        </div>
        <div class="px-2 text-center  border-top-0 " style="line-height: 24px;font-size: 12px;">
            <div class="text-overflow-1">{{resData.name}}</div>
            <div class="text-overflow-1">{{resData.dura_alias || '暂无'}}</div>
        </div>
        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            // 传输武器整体宽度
            width:{
                type:String,
                default:"180px"
            },
            // 武器显示的高度，宽度和整体宽度是一样的
            heightWeapon:{
                type:String,
                default:"150px"
            },
            // 传输背景 style 对象形式传输
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            // 武器对应的宽度 高度 带单位 style 对象形式传输
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
            //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
    .weapon{
        @media (max-width: 1200px) {
            font-size: 12px!important;
        }
        &-img{
            height: 120px;
            @media (max-width: 1200px) {
                height: 100px;
                font-size: 12px!important;
            }
        }
        &-image{

            @media (max-width: 680px) {

                width: 8rem;
            }
        }
        &-name{
            padding: 10px 0;
        }
    }
</style>
