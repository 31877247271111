import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/layout.vue'
import ceshi from '@/views/Home.vue'
import historyPage from '@/views/history/index'

import CSGO from '@/views/CSGO/index'
import compound from '@/views/compound/index'
import shopping from '@/views/shopping/index'
import lucky from '@/views/lucky/index'
import timeBox from '@/views/timeBox/index'
import mysteryBox from '@/views/mysteryBox/index2';
import creationpkHome from '@/views/mysteryBox/creationHome'
import rollHome from '@/views/rollHome/index'
import help from '@/views/help/index'
import memberVip from '@/views/memberVip/index2.vue'
import outOfTheFree from '@/views/outOfTheFree/indexV2'
import wxpage from '@/views/WXPage/index'
// import appdown from '@/views/APPDown/index.vue'
import activityImg from '@/views/APPDown/activityImg.vue'
// import clearance from '@/views/clearance/index'

// 拆弹
import battlefield from '@/views/battlefield/index'
import tongtianTower from '@/views/tongtianTower/index'

import traditional from '@/views//traditional/index'


import activityUrl from '@/views/activityUrl/index'

import CSGOActive from '@/views/CSGO/active'
import rollHomeActive from '@/views/rollHome/active'
import CreateRoom from '@/views/rollHome/CreateRoom'
import helpActive from '@/views/help/active'
import packsackActive from '@/views/packsack/active'
import luckyActive from '@/views/lucky/active'
import luckyHistory from '@/views/lucky/history'
import mysteryBoxActive from '@/views/mysteryBox/active'
import outOfTheFreeActive from '@/views/outOfTheFree/active'

import user from '@/views/user/info'


import Bill from '@/views/user/components/bill'
import Knapsack from '@/views/user/components/knapsack'
import Record from '@/views/user/components/record'
import Czrecord from '@/views/user/components/czrecord'
import Partner from '@/views/partner/index';


import info from '@/views/user/user2' 
import bill from '@/views/user/bill'
import detail from '@/views/user/detail'
import packsack from '@/views/user/packsack'
import recharge from '@/views/user/recharge2'
import welfare from '@/views/user/welfare2'
import payment from '@/views/user/payment'
import csactive from '@/views/user/csactive'

// app 下载页
import appDownload from '@/views/appDownload'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}




const routes = [
  {
    path: '/', // 名称
    component: layout, // 父组件 组件
    children: [
      {
        path: '/',
        name: 'SCGOindex',
        component: CSGO,
        beforeEach(to, from, next) {
          // console.log(to, from, next)
          next()
        },
        meta: { title: '高爆盲盒', icon: 'icon-kaixiangyanhuo', phone: false, login: false, isShow: true, side: '箱子' },
      },
      {
        path:'/traditional',
        name: 'traditional',
        component: traditional,
        meta: { title: '幸运520', icon: 'icon-a-ziyuan3', login: true },
      },
      {
        path: 'FmysteryBox',
        name: 'mysteryBox',
        component: mysteryBox,
        meta: { title: '竞技场', icon: 'icon-duizhan2x', phone: false, login: false, isShow: true, side: '竞技场' },
      },
      {
        path: 'Fcompound',
        name: 'compound',
        component: compound,
        meta: { title: '汰换升级', icon: 'icon-jiaohuan', phone: true, login: true, isShow: true, side: '升级' },
      },
      {
        path: 'battlefield',
        name: 'Battlefield',
        component: battlefield,
        meta: { title: '拆弹专家', icon: 'icon-jiaohuan', phone: true, login: true, isShow: true, side: '炸弹' },
      },

      {
        path: 'tongtianTower',
        name: 'tongtianTower',
        component: tongtianTower,
        meta: { title: '通天塔', icon: 'icon-jiaohuan', phone: true, login: true, isShow: true, side: '通天塔' },
      },
      {
        path: 'FrollHome',
        name: 'rollHome',
        component: rollHome,
        meta: { title: 'ROLL房', icon: 'icon-a-ziyuan5', phone: false, login: false, isShow: true, side: '赠品' },
      },
      {
        path: 'Fshopping',
        name: 'shopping',
        component: shopping,
        meta: { title: '积分商城', icon: 'icon-gouwuche', phone: true, login: true, isShow: true, side: '积分商城' },
      },
      {
        path: 'FoutOfTheFree',
        name: 'outOfTheFree',
        component: outOfTheFree,
        meta: { title: '福利宝箱', icon: 'icon-mianfei', phone: true, login: false, isShow: true, side: '福利宝箱' },
      },
      {
        path: 'appDownload',
        name: 'appDownload',
        component: appDownload,
        meta: { title: 'APP下载', icon: 'icon-mianfei', phone: true, login: false, isShow: true, side: 'APP下载' },
      },
      {
        path: 'user/welfare',
        name: 'welfare',
        component: welfare,
        meta: { title: '合伙人', icon: 'icon-guanfang2', phone: true, login: true, side: '合伙人', isShow: true, },
      },

      {
        path: 'Flucky',
        name: 'lucky',
        component: lucky,
        meta: { title: '追梦', icon: 'icon-lucky_left', phone: false, login: true, isShow: true, side: '幸运拉货' },
      },
      {
        path: 'TimeBox',
        name: 'timeBox',
        component: timeBox,
        meta: { title: '时间盲盒', icon: 'icon-lucky_left', phone: false, login: true, isShow: true, side: '时间盲盒' },
      },
      {
        path: 'FmemberVip',
        name: 'memberVip',
        component: memberVip,
        meta: { title: '会员等级', icon: 'icon-VIP', phone: true, login: true, isShow: true, side: 'VIP福利' },
      },
      {
        path: 'packsack',
        name: 'packsack',
        component: packsack,
        meta: { title: '我的背包', icon: 'icon-pack', phone: false, login: true, isShow: false, side: '个人中心' },
      },
      {
        path: 'help',
        name: 'help',
        component: help,
        meta: { title: '帮助中心', icon: 'icon-wenhao', phone: false, login: false, isShow: false },
      },
      
    ]
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
  },
  // 详情
  {
    path: '/active', // 名称
    name: 'active',
    component: layout, // 父组件 组件
    children: [
      {
        path: ':id',
        name: 'CSGOActive',
        component: CSGOActive,
        meta: { title: '开箱详情页', icon: '', login: false, side: '最近掉落' },
      },
      {
        path: 'rollHome/:id',
        name: 'rollHomeActive',
        component: rollHomeActive,
        meta: { title: 'ROLL福利', icon: '', login: false, side: 'ROLL福利' },
      },
      {
        path: 'CreateRoom/creat',
        name: 'CreateRoom',
        component: CreateRoom,
        meta: { title: 'ROLL福利创建房间', icon: 'icon-a-ziyuan3', login: true, side: 'ROLL福利' },
      },
      {
        path: 'lucky/:id',
        name: 'luckyActive',
        component: luckyActive,
        meta: { title: '幸运开箱', icon: '', login: false, side: '幸运开箱' },
      },
      {
        path: 'luckyHistory/:id',
        name: 'history',
        component: luckyHistory,
        meta: { title: '记录', icon: '', login: false, side: '记录' },
      },
      {
        path: 'help/:id',
        name: 'helpActive',
        component: helpActive,
        meta: { title: '帮助中心', icon: '', login: false, side: '帮助中心' },
      },
      {
        path: 'packsack/:id',
        name: 'packsackActive',
        component: packsackActive,
        meta: { title: '我的背包', icon: '', login: true },
      },
      {
        path: 'mysterybox/:id',
        name: 'mysteryBoxs',
        component: mysteryBoxActive,
        meta: { title: '竞技场', icon: '', login: false, side: '竞技场' },
      },
      {
        path: 'creation/pkHome',
        name: 'creationpkHome',
        component: creationpkHome,
        meta: { title: '创建对战', icon: '', login: false, side: '创建对战' },
      },
      {
        path: 'outOfTheFree/:id',
        name: 'outOfTheFreeActive',
        component: outOfTheFreeActive,
        meta: { title: '充值福利', icon: '', login: false, side: '充值福利' },
      },
    ]
  },
  // 用户
  {
    path: '/user', // 名称
    name: 'user',
    component: layout, // 父组件 组件
    children: [
      {
        path: 'info',
        name: 'user',
        component: user,
        meta: { title: '个人中心', icon: '', login: false, side: '个人中心', isShow: true },
      },
      {
        path: 'user',
        name: 'info',
        component: info,
        meta: { title: '个人资料', icon: '', login: false, side: '个人资料', isShow: true },
      },
      {
        path: 'packsack',
        name: 'packsackUser',
        component: packsack,
        meta: { title: '我的背包', icon: 'icon-beibao', login: false, side: '我的背包', isShow: true },
      },
      {
        path: 'recharge',
        name: 'recharge',
        component: recharge,
        meta: { title: '充值中心', icon: 'icon-a-ziyuan3', login: true, side: '充值中心', isShow: true },
      },
      {
        path: 'bill',
        name: 'bill',
        component: bill,
        meta: { title: '提货账单', icon: 'icon-a-ziyuan3', login: false, side: '提货账单', isShow: true },
      },
      {
        path: 'detail',
        name: 'detail',
        component: detail,
        meta: { title: '收支明细', icon: 'icon-a-ziyuan3', login: false, side: '收支明细', isShow: true },
      },

      {
        path: 'welfare',
        name: 'welfare',
        component: welfare,
        meta: { title: '会员推广', icon: 'icon-a-ziyuan3', login: false, side: '会员推广', isShow: true },
      },
      {
        path: 'payment',
        name: 'payment',
        component: payment,
        meta: { title: '充值跳转', icon: 'icon-a-ziyuan3', login: false, side: '充值跳转', isShow: true },
      },
      {
        path: 'csactive',
        name: 'csactive',
        component: csactive,
        meta: { title: '任务中心', icon: 'icon-a-ziyuan3', login: false, side: '任务中心', isShow: true },
      },
      {
        path:'bill1',
        name:'bill1',
        component:Bill,
        meta: { title: '提货账单', icon: 'icon-a-ziyuan3', login: true, side: '提货账单', isShow: true },
      },
      {
        path:'knapsack',
        name:'knapsack',
        component:Knapsack,
        meta: { title: '背包', icon: 'icon-a-ziyuan3', login: true, side: '背包', isShow: true },
      },
      {
        path:'record',
        name:'record',
        component:Record,
        meta: { title: '记录', icon: 'icon-a-ziyuan3', login: true, side: '记录', isShow: true },
      },
      {
        path:'czrecord',
        name:'czrecord',
        component:Czrecord,
        meta: { title: '充值记录', icon: 'icon-a-ziyuan3', login: true, side: '充值记录', isShow: true },
      }
    ]
  },
  // 记录
  {
    path: '/history',
    name: 'history',
    component: layout, // 父组件 组件
    children: [
      {
        path: ':id',
        name: '*',
        component: historyPage,
        meta: { title: '个人信息', icon: 'icon-a-ziyuan3', login: false },
      },
    ]
  },

  

  {
    path: '/wxpage',
    name: 'wxpage',
    component: wxpage,
    meta: { title: '微信跳转', icon: 'icon-a-ziyuan3', login: false },
  },
  {
    path: '*',
    name: '*',
    redirect: '/', // 重定向
    meta: { title: 'CSGO', icon: 'icon-a-ziyuan3', login: false },
  },

  // {
  //   path: '/', // 名称
  //   component: layout, // 父组件 组件
  //   name: 'appdown',
  //   children: [
  //     {
  //       path: 'appdown',
  //       name: 'APPdown',
  //       component: appdown,
  //     },
  //   ]
  // },
  {
    path: '/activity', // 名称
    name: 'activity',
    component: activityImg,
  },
  {
    path: '/activityUrl/:id', // 名称
    name: 'activityUrl',
    component: activityUrl,
  },
  {
    path: '/', // 名称
    component: layout, // 父组件 组件
    name: 'login',
    children: [
      {
        path: 'Fnologin',
        name: 'nologin',
        component: ceshi,
        meta: { title: 'nologin', icon: 'icon-a-ziyuan3', login: false },
      },
    ]
  },


]
const router = new VueRouter({
  mode: "history", //hash  history
  base: process.env.BASE_URL,
  routes
})

export default router
