<template>
  <div class="extension_index">
    <img class="extension_index_img" src="../../assets/img/news/partner_tit.png"/>
    <div class="extension_main">
        <div class="extension_main_top">
            <div class="main_lv">
                <div class="text-name">我的等级</div>
                <div><img :src="require(`../../assets/img/news/partner/${user.promotion_level}.png`)" /></div>
            </div>
            <div class="main_superior">
                <div class="text-name">我的上级</div>
                <div>{{user_info.inviter != null?'' + user_info.inviter.invite_code : '暂无'}}</div>
            </div>
            <div class="main_superior">
                <div class="text-name">邀请总人数</div>
                <div>{{user.people_invited_num}}人</div>
            </div>
            <div class="main_superior">
                <div class="text-name">返佣比例</div>
                <div>{{user.promotion?user.promotion.rebate:'0.00'}}%</div>
            </div>
            <div>
                <div class="text-name">下线总充值 </div>
                <div class="flex text-yellow" style="line-height:30px"><money :width="sUserAgent == 'phone'?'20px':'30px'"></money>{{(user.invite_total_recharge)}}</div>
            </div>
        </div>
        <div class="extension_main_bottom">
            <div>
                <div class="text-name">我的合伙人链接</div>
                <div class="flex">
                    <div class="input">{{invite_url}}{{user.invite_code}}</div>
                    <div class="common-btn common-btn-blue" @click="authCopy">复制链接</div>
                </div>
            </div>
            <div class="main_code">
                <div class="text-name">我的邀请码</div>
                <div class="flex">
                    <div class="codeInvitation">{{user.invite_code}}</div>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="highlighted-message text-center">
        <p class="highlighted-message-body">主播合作请联系商务QQ：3425754792  请勿恶意推广，upcsgo保留最终解释权！</p>
    </div>
    <div class="extension_list">
        <div class="table_head">
            <div>合伙人等级</div>
            <div>下线充值</div>
            <div>充值返佣</div>
            <div>说明</div>
        </div>
        <div class="table_main">
            <div class="table_td" v-for="(item,index) in list" :key="index">
                <div><img :src="require(`../../assets/img/news/partner/${item.level}.png`)" /></div>
                <div class="flex text-yellow padding-top-xs" style="line-height:30px"><money :width="sUserAgent == 'phone'?'20px':'30px'"/>{{item.invite_total}}</div>
                <div class="text-yellow">{{item.rebate + "%"}}</div>
                <div>{{item.description}}</div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
  import {mapState} from 'vuex'
  import $api from '@/api/user/index'
import sUserAgent from '@/assets/js/common.js'

  export default {
    name: "index",
    components: {},
    data() {
      return {
        invite_url: '//www.upcsgo.com/?ref_code=',
        resData: {},
        list: [],
        user: {promotion_level:1},
      }
    },
    computed: {
        invite_url_mode(){
            return this.invite_url + this.user.invite_code
        },
        sUserAgent() {
        return sUserAgent.sUserAgent();
        },
      ...mapState(['user_info','web_info'])
    },
    created() {
      setTimeout(() => {
        this.resData = this.user_info
      }, 500)
    },
    mounted() {
      this.init()
      setTimeout(() => {
          // let host = window.location.host;
          let host = 'www.upcsgo.com'
          this.invite_url = 'https://' + host + '/?ref_code='
      }, 100)
    },
    methods: {
      init() {
        this.getPromote()
      },
      // 拷贝推广链接
      authCopy() {
        this.$common.CopyText({content: this.invite_url + this.resData.invite_code})
      },
      // 推广数据
      async getPromote() {
        try {
          let {data, code} = await $api.getPromote()
          if (code == 500) return
          this.list = data.list
          this.user = data.user
        } catch (e) {
          console.log(e)
        }
      },
      async amendCopy() {

      }
    }
  }
</script>


<style lang="scss">
.extension_index{
    width: 1720px;
    margin: 0 auto;
    @media (max-width:1300px) {
        width: 100%;
    }
    .extension_index_img{
        @media (max-width:1300px) {
            width: 100%;
        }
    }
    .highlighted-message{
        padding: 10px 10px;
        border-radius: 4px;
        background-color: #1A1B25;
        @media (max-width:1300px) {
            padding: 2px 10px;
            font-size: 14px;
        }
    }
    .text-yellow{
        color: #FEDB7F;
    }
    .extension_main{
        &_top{
            background-color: #1A1B25;
            display: flex;
            padding:20px;
            font-size: 20px;
            font-weight: bold;
            line-height: 40px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            @media (max-width:1300px) {
                padding:8px 2px;
                font-size: 12px;
                line-height: 30px;
            }
            >div+div{
                padding-left:20px;
                border-left: 1px solid rgba(255, 255, 255, 0.4);
                @media (max-width:1300px) {
                    padding-left:2px;
                    border-left: 0;
                }
            }
            .main_lv{
                width: 200px;
                @media (max-width:1300px) {
                    width: 20%;
                    img{
                        width: 30px;
                    }
                }
            }
            .main_superior{
                width: 300px;
                @media (max-width:1300px) {
                    width: 20%;
                }
            }
        }
        &_bottom{
            display: flex;
            background-color: #22232E;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            padding:20px;
            @media (max-width:1300px) {
                display: block;
            }
            .flex{
                @media (max-width:1300px) {
                    display: block;
                }
            }
            .codeInvitation{
                width: 300px;
                font-size: 36px;
                font-weight: 400;
                color: #FFC67B;
            }
            .input{
                width: 500px;
                font-size: 12px;
                height: 40px;
                line-height: 40px;
                padding: 0 10px;
                color: rgba(255, 255, 255, 0.4);
                @media (max-width:1300px) {
                    width: 100%;
                }
            }
            .common-btn{
                margin-left: 20px;
                width: 200px;
                @media (max-width:1300px) {
                    margin-top: 10px;
                    margin-left: 0px;
                    width: 120px;
                    line-height: 30px;
                }
            } 
            .main_code{
                margin-left: 20px;
                padding-left: 20px;
                border-left: 1px solid rgba(255, 255, 255, 0.4);
                @media (max-width:1300px) {
                    margin-left: 0px;
                    padding-left: 0px;
                     border-left: 0;
                     .text-name{
                        margin-top:10px
                     }
                }
            }
        }
        .text-name{
            font-size: 12px;
            font-weight: 100;
            margin-bottom: 20px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.4);
            @media (max-width:1300px) {
                line-height:20px;
                margin-bottom: 10px;
            }
        }
    }
    .extension_list{
        margin-top:20px;
        @media (max-width:1300px) {
            font-size: 12px;
        }
        .table_head{
             background-color: #22232E;
            border-radius: 4px;
            padding: 20px;
            display: flex;
            div:nth-child(1){
                width: 400px;
                @media (max-width:1300px) {
                    width: 25%;
                }
            }
            div:nth-child(2){
                width: 400px;
                @media (max-width:1300px) {
                    width: 25%;
                }
            }
            div:nth-child(3){
                width: 500px;
                @media (max-width:1300px) {
                    width: 25%;
                }
            }
            div:nth-child(4){
                width: 420px;
                @media (max-width:1300px) {
                    width: 25%;
                }
            }
        }
        .table_main{
            .table_td{
                display: flex;
                background-color: #16171F;
                line-height: 50px;
                padding: 10px 20px;
                border-bottom: 2px solid #0D101A;
                border-radius: 4px;
                div:nth-child(1){
                    width: 400px;
                    @media (max-width:1300px) {
                    width: 25%;
                        img{
                            margin-top:10px;
                            width: 30px;
                        }
                    }
                }
                div:nth-child(2){
                    width: 400px;
                    @media (max-width:1300px) {
                    width: 25%;
                        img{
                            width: 30px;
                        }
                    }
                }
                div:nth-child(3){
                    width: 500px;
                    @media (max-width:1300px) {
                    width: 25%;
                    }
                }
                div:nth-child(4){
                    width: 420px;
                    @media (max-width:1300px) {
                    width: 25%;
                        font-size: 12px;
                        line-height: 1.5 !important;
                    }
                }
            }
        }
    }
}
</style>