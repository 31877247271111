<template>

  <!-- 需要flex布局 -->
  <div class="app d-flex">
    <header-top class="z-index-99"></header-top>

    <div style="width: 100%" class="" id="content">
      <swiper-user></swiper-user>
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
      <footer-botton></footer-botton>
    </div>
    <div :class="[(sUserAgent == 'phone'&&$route.path != '/') ? 'common_bg':'comm-style',(sUserAgent == 'web') ? 'common_bg':'']"  @click="bodyCilkck" ></div>
    <!-- v-if="sUserAgent == 'web'" -->
    <!-- <silde class="d-md-block"></silde> -->

    <div class="position-fixed TaskIn d-none" >
      <Task-in :bottom-right="false">
        <div class="cursor-pointer silde-serve silde-W d-flex flex-column
                  justify-content-center align-items-center">
          <img width="50%" :src="require('@/assets/img/silde/QD.png')" alt="">
          <div>
            签到
          </div>
        </div>
      </Task-in>
    </div>
  </div>
</template>

<script>
  import headerTop from './headerTop.vue'
  import swiperUser from './swiperUser.vue'
  import footerBotton from "./footerBotton";
  // import silde from "./silde";
  import TaskIn from '@/views/TaskIn/index'
  import $api from '@/api/login.js'
  import { setWebInfo } from '@/utils/auth'
  import sUserAgent from '@/assets/js/common.js'
  export default {
    name: "layout",
    components:{
      headerTop,
      swiperUser,
      footerBotton,
      // silde,
      TaskIn
    },
    data(){
      return {
        // 遍历导航
        routers:[],
        // 给内容唯一的key
        key:this.$route.path,
      }
    },

    computed:{
      sUserAgent() {
      return sUserAgent.sUserAgent();
    }
    },
    created() {
      this.routers = this.$router.options.routes[0].children
      this.$store.dispatch('userInfo')
      },
    mounted() {
      $api.getWebInfo().then(res =>{
        setWebInfo(res.data)
        this.$store.commit('WEB_INFO',res.data)
      })

     let popularize = this.$route
     let str = JSON.stringify(popularize.query)
      let index = popularize.fullPath.indexOf('bd_vid')
      if(index!==-1){
        window.localStorage.setItem('popularize','bd_vid')
      }
     if(str != "{}"){
      for(let key in popularize.query){
       
        if(key === 'bd_vid'){
          window.localStorage.setItem('popularize',popularize.query[key])
        }
      }
     }
    },
    methods: {
      bodyCilkck(){
        this.$store.commit('ISSHOWNAVPC',false)
      }
    },
  }
</script>

<style scoped lang="scss">
  .TaskIn{
    right: 0;
    bottom:60%;
    z-index: 999;
    // display: none;
  }
  #content{
    overflow: hidden;
    /*margin-left: 250px;*/
    // height: calc(100vh - 70px);
    // overflow-y: scroll;
    // overflow-x: hidden;
    /* 手机端放大效果 */
    @media (max-width: 1000px) {
      height: auto;
      overflow-y: auto;
      margin-bottom: 60px;
    }
    /*&::-webkit-scrollbar {display:none}*/
  }
  .common_bg{
    // background: url("../assets/img/ADT/home/home-bg.png") no-repeat top right;
    background-color: #15172C;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: -2;
  }

  .comm-style {
    background-color: #15172C;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: -2;
  }

</style>
