<template>
    <el-row class="timeBox">
        <el-row class="content">
            <el-row class="box_top">
                <el-row class="time_two_hour">
                    <img src="@/assets/img/timeBox/time_two_hour.png" alt="">
                </el-row>
                <el-row class="tips_text">登录并充值100以上即可自动参加</el-row>
                <el-row class="time_count">
                    <el-col :xs="3" :sm="2" :md="2" :lg="2" :xl="2">
                        <el-row>{{ dateInfo.hours }}</el-row><el-row>时</el-row>
                    </el-col>
                    <el-col :xs="3" :sm="2" :md="2" :lg="2" :xl="2">
                        <el-row>{{ dateInfo.minutes }}</el-row><el-row>分</el-row>
                    </el-col>
                    <el-col :xs="3" :sm="2" :md="2" :lg="2" :xl="2">
                        <el-row>{{ dateInfo.seconds }}</el-row><el-row>秒</el-row>
                    </el-col>
                </el-row>
                <el-row class="things_img">
                    <img :src="timeBoxList.skins?.cover" alt="">
                </el-row>
                <el-row class="been">
                    <img v-if="timeBoxList.skins" src="@/assets/img/ADT/home/up.png" alt="">
                    <span>{{ timeBoxList.skins?.bean}}</span>
                </el-row>
                <el-row class="things_name">{{ timeBoxList.skins?.name }} </el-row>
            </el-row>
            
        </el-row>
        <el-row class="footer_bg">
            <el-row class="rencent">
                <el-row class="header_bg"><img src="@/assets/img/timeBox/recent_bg.png" alt=""></el-row>
                <div class="table">
                    <el-table
                        class="table_data"
                        :data="timeBoxRecords"
                        style="width: 100%">
                        <el-table-column
                            prop="gain_time"
                            label=""
                            >
                            <template slot="header">
                                <div class="table_header">
                                    <img class="table_header_img" src="@/assets/img/timeBox/recent_time_bg.png" alt="">
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <span class="timelist">{{ scope.row.gain_time }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop=""
                            label=""
                            >
                            <template slot="header">
                                <div class="table_header"><img class="table_header_img" src="@/assets/img/timeBox/recent_user_bg.png" alt=""></div>
                            </template>
                            <template slot-scope="scope">
                                {{ scope.row.user?.name }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop=""
                            label="">
                            <template slot="header">
                                <div class="table_header"><img class="table_header_img" src="@/assets/img/timeBox/recent_things_bg.png" alt=""></div>
                            </template>
                            <template slot-scope="scope">
                                {{ scope.row.skins?.name.replace(/\s*/g, "") }}
                            </template>
                        </el-table-column>
                </el-table>
                </div>
            </el-row>
        </el-row>
    </el-row>
</template>
<script>
import $api from '@/api/time'
export default {
    data() {
        return {
            timeBoxRecords: [],
            timeBoxList: {
                end_time: 0,
                skins: {
                    name: '',
                    cover: "",
                    bean: 0,
                }
            },
            dateInfo: {
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00",
            },
            countdown: null
        }
    },
    created() {
        this.getTimeBoxList()
    },
    methods: {
        getTimelimit() {
            let remaining = new Date(this.timeBoxList.end_time * 1000).getTime() - new Date().getTime()
            this.dateInfo = this.remainingTime(remaining)
            if (remaining && remaining > 0) {
                this.countdown = setInterval(() => {
                    if (remaining < 1000) {
                        this.dateInfo = {
                            ays: "00",
                            hours: "00",
                            minutes: "00",
                            seconds: "00",
                        }
                        clearTimeout(this.countdown)
                        this.getTimeBoxList()
                        return;
                    } else {
                        remaining -= 1000;
                        this.dateInfo = this.remainingTime(remaining);
                    }
                }, 1000);
            } else {
                this.getTimeBoxList()
            }
            
        },
        async getTimeBoxList() {
            let { code, data } = await $api.getTimeBoxList()
            if (code == 200) {
                if (data[0]) {
                    this.timeBoxList = data[0]
                }
                this.getTimelimit()
                this.getTimeBoxRecords()
            }
        },
        async getTimeBoxRecords() {
            if (!this.timeBoxList.id) return
            let { code, data } = await $api.getTimeBoxRecords(this.timeBoxList.id)
            if (code == 200) {
                this.timeBoxRecords = data
            }
        },
        // 计算剩余时间
        remainingTime(remaining) {
            const lasttime = {
                days: Math.floor(remaining / (1000 * 60 * 60 * 24)),
                hours: Math.floor(
                    (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                ),
                minutes: Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((remaining % (1000 * 60)) / 1000),
            }
            // console.log(lasttime)
            for (const key in lasttime) {
                if (lasttime[key] < 10 && lasttime[key] >= 0) {
                    lasttime[key] = '0' + lasttime[key]
                }
                if (lasttime[key] < 0) {
                    lasttime[key] = '00'
                }
            }
            
            return lasttime
        },
    }
}
</script>

<style lang="scss" scoped>
.timelist {
    font-family: MicrosoftYaHei;
    @media (max-width:600px) {
        line-height: 18px;
        width: 90px;
        display: inline-block;
    }
}
.timeBox {
    .content {
        width: 600px;
        height: auto;
        @media (max-width:600px) {
            width: 90%;
            margin-top: 20px;
        }
    }
    .footer_bg {
        display: flex;
        justify-content: center;
        padding-bottom: 100px;
        background-color: #15172C;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width:600px) {
            width: 96%;
            margin-top: 20px;
        }
    }
}
.box_top {
    position: relative;
    background-image: url(../../assets/img/timeBox/time_box_bg.png);
    background-position: center 0;
    width: 100%;
    height: 600px;
    @media (max-width:600px) {
        background-image: url(../../assets/img/timeBox/phone/time_box_bg.png);
        height: 500px;
        background-size: 360px auto;
    }
    
    
    background-size: contain;
    background-repeat: no-repeat;
    .time_two_hour {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        img {
            width: 42%;
            @media (max-width:600px) {
                width: 50%;
            }
        }
    }
    .tips_text {
        color: #08FFDF;
        text-align: center;
        margin: 20px 0;
    }
    .time_count {
        display: flex;
        justify-content: center;
        div.el-col {
            
            text-align: center;
            background-color: #6600CE;
            margin: 0 10px;
            padding: 5px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // gap: 10px;
            >div {
                padding: 5px 0;
                &:nth-child(1) {
                    font-family: MicrosoftYaHei-Bold;
                    font-weight: bold;
                    font-size: 20px;
                }
                &:nth-child(2) {
                    font-size: 14px;
                }
            }
        }
    }
    @keyframes things_img_move {
        0% {
            margin-top: 10px;
        }
        50% {
            margin-top: -10px;
        }
        100% {
            margin-top: 10px;
        }
    }
    .things_img {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        height: 155px;
        img {
            position: absolute;
            height: 155px;
            animation: things_img_move 1.28s ease-in-out  infinite;
            @media (max-width:600px) {
                height: 155px;
            }
        }
        @media (max-width:600px) {
            margin-top: 0;
        }
    }
    .things_name {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
    }
    .been {
        font-size: 14px;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap:5px;
        span {
            font-family: MicrosoftYaHei;
        }
        img {
            width: 20px;
        }

        @media (max-width:600px) {
            margin-top: 0;
        }
    }
}
.rencent {
    @extend .content;
    margin-top: -130px;
    @media (max-width:600px) {
        margin-top: -22px !important;
    }
    .header_bg {
        display: flex;
        justify-content: center;
        img {
            width: 100%;
        }
    }
    .table {
        margin-top: 30px;
        @media (max-width:600px) {
            margin-top: 25px;
        }
        .table_header {
            display: flex;
            justify-content: center;
        }
        .table_header_img {
            width: 20%;
            @media (max-width:600px) {
                width: 30%;
            }
        }
        :deep(td) {
            border: none;
        }
        :deep(th.el-table__cell.is-leaf) {
            border: none;
        }
        :deep(.el-table__header) {
            background-color: #211743;
        }
        :deep(th) {
            background: none !important;
        }
        :deep(tr) {
            background: none !important;
        }
        :deep(.el-table__body-wrapper) {
            background: none !important;
        }
        .el-table {
            background: none !important;
            border: none !important;
            &::before {
                background: none;
            }
        }
        :deep(.cell) {
            text-align: center;
            font-family: MicrosoftYaHei;
        }
    }
}
</style>