import {request} from "./index";
async function userLoginToken(data){
    let {stast,code,mobile,verify} = data
    if(stast == 'login' && code == true){
        let obj = {
            verify,
            mobile
        }
        return request({
            url: `/sms_login`,
            method:'post',
            data:obj
        })
    }
    if(stast == 'login'  && code == false){
        return request({
            url: `/login`,
            method:'post',
            data
        })
    }
    if(stast == 'register'){
        return request({
            url: `/register`,
            method:'post',
            data
        })
    }
    if(stast == 'forget'){
        return request({
            url: `/reset_password`,
            method:'post',
            data
        })
    }

}
function getSendSms(data) {
    return request({
        url: `/send_sms`,
        method:'post',
        data
    })
}
function getCaptcha() {
    return request({
        url: `/get_code`,
        method:'post'
    })
}

function getUsetInof() {
    return request({
        url: `/me`,
        method:'get'
    })
}
function getWebInfo() {
    return request({
        url: `/info`,
        method:'get'
    })
}
function getSteamHandle(redirectedFrom) {

    return request({
        url: `/steam/handle?${redirectedFrom}`,
        method:'get'
    })
}
function getSteamHandleLogin(data) {
    data.verify =  Number(data.verify)
    return request({
        url: `/steam/bind_mobile`,
        method:'post',
        data
    })
}
function getRefreshToken(){
    return request({
        url: `/auth/refresh`,
        method:'post'
    })
}

function get_bind_mobile(data){
    return request({
        url: `/bind_mobile`,
        method:'post',
        data
    })
}

export default {
    userLoginToken,
    getSendSms,
    getCaptcha,
    getUsetInof,
    getWebInfo,
    getSteamHandle,
    getRefreshToken,
    getSteamHandleLogin,
    get_bind_mobile
}
