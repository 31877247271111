<template>
    <div class="mt-5 content ">
        <activeHead class="margin-bottom" :roomStatus="roomStatus" :details="details" :token="token"></activeHead>
        <!-- 中奖者 -->
        <div v-if="carry_off_prize || details.status == 1" class=" row mt-lg-4 mt-mt-2 mt-3">
            <div class="mt-5 mb-3 text-center font-weight-bold h4 w-100">
                <img style="height: 80px;" src="../../assets/img/ADT/roll/hjmd.png" alt="">
            </div>
            <div class="col-lg-2 col-md-4 col-6 mb-4"
                v-for="(item, index) in bearThePalm.FilterTableData.slice((bearThePalm.currentPage - 1) * bearThePalm.pageSize, bearThePalm.currentPage * bearThePalm.pageSize)"
                :key="index">
                <div class="text-center d-flex flex-column justify-content-around align-items-center bg-size-100"
                    style="width: 100%;height: 250px"
                    :class="item.box_record.user_id === user_info.id ? 'bg-red' : 'bg-blue'">
                    <div class="h6" v-if="details.user_id != item.box_record.user.id">{{ item.box_record.user.name }}
                    </div>
                    <div style="width: 114px;height: 114px" class="bg-size bg-repeat cursor-pointer position-relative"
                        :style="`background-image: url(${require('@/assets/img/ADT/roll/hjmd-bg.png')})`">
                        <img style="position: absolute;top:25px;left: 50%;transform: translateX(-50%);" width="60%"
                            class="ROLL-users-user-img" :src="item.box_record.cover" alt="抢">
                        <img class="position-absolute ROLL-users-user-user ROLL-users-user-img" width="45px" height="45px"
                            style="border: 3px solid #166172;" v-if="details.user_id != item.box_record.user.id"
                            :src="item.box_record.user.avatar" alt="头像">
                    </div>
                    <div class="mb-2" style="font-size: 16px">
                        {{ item.box_record.name }}
                    </div>
                    <div style="font-size: 14px">
                        {{ item.box_record.dura_alias }}
                    </div>
                    <div class="flex align-items-center" style="font-size: 12px;color: #F9C545;">
                        价值：
                        <money />{{ item.bean }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="(carry_off_prize || details.status == 1) && bearThePalm.count > 50">
            <el-pagination background layout="prev, pager, next" :total="bearThePalm.count"
                :page-size="bearThePalm.pageSize" :pager-count="5"
                @current-change="currentChange($event, 'bearThePalm')"></el-pagination>
        </div>

        <div class="flex paging justify-content-center" v-if="bearThePalm.FilterTableData.length !== 0">
            <div class="paging-item _a" @click="laypage(0)">上一页</div>
            <div class="paging-item _a" @click="laypage(1)">下一页</div>
        </div>


        <div class="roll-container-tabs">
            <div :class="active == 1 ? 'active' : ''" class="tabs" @click="active = 1">包含奖品</div>
            <div :class="active == 2 ? 'active' : ''" class="tabs" @click="active = 2">参与用户</div>
        </div>

        <div v-if="active === 1">
            <!-- <div class="mt-5 mb-3 text-center font-weight-bold h3 ">
            赠品包含
        </div> -->
            <div class="w-100 d-flex px-3">
                <div class="d-flex flex-wrap">
                    <div class="mb-3 list-box " style=""
                        v-for="item in boxList.FilterTableData.slice((boxList.currentPage - 1) * boxList.pageSize, boxList.currentPage * boxList.pageSize)"
                        :key="item.id">
                        <div style="width: 95%">
                            <weapon :res-data="item"></weapon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex paging justify-content-center mb-4" v-if="boxList.FilterTableData !== 0">
                <div class="paging-item _a" @click="laypageAwards(0)">上一页</div>
                <div class="paging-item _a" @click="laypageAwards(1)">下一页</div>
            </div>
        </div>

        <div v-if="active === 2">
            <!-- 参与人数 -->
            <!-- <div  class="h3 text-white font-weight-bold text-center my-5
        position-relative d-flex justify-content-center align-items-center">
            <div>已加入用户</div>
        </div> -->
            <div class="d-flex flex-wrap">
                <div class="mb-3 f-Registered-user" style=""
                    v-for="(item, index) in participants.FilterTableData.slice((participants.currentPage - 1) * participants.pageSize, participants.currentPage * participants.pageSize) "
                    :key="index">
                    <div class="ROLL-users-user d-flex flex-column justify-content-center align-items-center py-4">
                        <div class="d-flex justify-content-center align-items-center"
                            style="width: 114px;height: 114px;background-size: 100%"
                            :style="`background-image: url(${require('@/assets/img/f_new/ROLL_users.png')})`">
                            <img width="100" class="ROLL-users-user-img" :src="item.user.avatar" alt="">
                        </div>
                        <div class="text-center mt-3">
                            {{ item.user.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex paging justify-content-center mb-4" v-if="participants.FilterTableData !== 0">
                <div class="paging-item _a" @click="laypageUser(0)">上一页</div>
                <div class="paging-item _a" @click="laypageUser(1)">下一页</div>
            </div>
        </div>

        <!-- 免费房间密码弹窗 -->

        <!-- 支付弹窗 -->
        <div class="password-room-pop-up" v-if="isShowPopUpRoom">
            <div style="width: 500px;left: calc(50% - 250px);top:20vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2" style="width: 100%;line-height: 2.0">

                    <div class="py-2 mx-auto mb-3" v-if="details.is_pwd == 1"
                        style="border: 1px solid rgb(67, 132, 83); border-radius: 5px;max-width:300px ">
                        <input style="width: 300px" class="text-white pl-3" type="password" v-model="pass"
                            placeholder="请输入密码">
                    </div>
                    <div class="password-room-pop-up-btns">
                        <div @click="isShowPopUpRoom = false" class="password-room-pop-up-cancellation">取消</div>
                        <div @click="confirmPassRomm" class="password-room-pop-up-confirm">确认</div>
                    </div>
                </div>
                <div @click="isShowPopUpRoom = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <img class="cursor-pointer" style="width:20px" src="../../assets/img/ADT/zm/luckyPup/close.png" alt="">
                    </div>
                </div>
            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;">
            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/rollHome/index.js'
import weapon from './common/weapon.vue'
import activeHead from './common/activeHead.vue'
import { getToken } from '@/utils/auth'
import { mapState } from 'vuex'
export default {
    name: "active",
    components: {
        weapon, activeHead
    },
    data() {
        return {
            isShowPopUpRoom: false, // 输入密码弹窗1
            active: 1,
            roomStatus: 0,
            id: '',
            details: {},
            state: 'pond',
            pass: '',
            roll: '',
            dataFUQ: 0,
            awards: [],
            users: [],
            awards12: [],
            page2: 1,
            page1: 1,
            page0: 1,
            bearThePalm: {
                PolicyTableData: [],
                FilterTableData: [],
                count: 0,
                currentPage: 1,
                pageSize: 50,
            },
            boxList: {
                PolicyTableData: [],
                FilterTableData: [],
                count: 0,
                currentPage: 1,
                pageSize: 50,
            },
            participants: {
                PolicyTableData: [],
                FilterTableData: [],
                count: 0,
                currentPage: 1,
                pageSize: 50,
            }
        }
    },
    created() {
        this.id = this.$route.params.id

    },
    async mounted() {
        let roll = this.$route.query.roll
        this.roll = roll
        this.init()

    },
    computed: {
        ...mapState(['user_info']),
        token() {
            return getToken()
        },
        carry_off_prize() {
            return this.details.status == 1 && this.state != 'pond' && this.state != 'num'
        },
    },
    methods: {
        async confirmPassRomm() {
            await this.AddRollHome()
        },
        currentChange(val, index) {
            this[index].currentPage = val;
        },
        async init() {
            await this.getRoomsDetail()
            await this.getRoomsAwards()
            await this.getRoomsUsers()
            await this.getAwardsUser()
        },
        // 立即加入按钮
        async AddRollHome() {
            try {
                let { message, code } = await $api.getRoomsJoin({ id: this.id, password: this.pass })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        message: message,
                        type: 'success',
                        position: 'top-left'
                    });
                    this.isShowPopUpRoom = false;
                }
                this.init()
                this.pass = ''
            } catch (e) {
                this.$store.commit("LOGIN_TOP", true)
            }
        },
        // 获取详情
        async getRoomsDetail() {
            try {
                let { data, code, timestamp } = await $api.getRoomsDetail(this.id)
                if (code == 500) return
                this.dataFUQ = timestamp * 1000
                if (Date.parse(data.end_time) / 1000 - timestamp < 0) {
                    data.status = 1
                }
                this.details = data;
                console.log("data------------", data);

                if (data.status != 1) {
                    // 是否加入房间
                    this.isAddRoom(this.id)
                }
            } catch (e) {
                console.log(e)
            }
        },
        async isAddRoom(id) {
            let { data } = await $api.getUserRoomStatus(id);
            this.roomStatus = data.status;


            if (this.details.is_pwd == 1 && this.roomStatus != 1) {
                this.isShowPopUpRoom = true;
            }
        },
        async getRoomsAwards() {
            try {
                let { data, code } = await $api.getRoomsAwards(this.id, this.page1, 20)
                if (code == 500) return;
                //参与人数
                if (!data.awards.length) return this.page1--
                //获奖人数
                // let meDate={};
                // let drawList=[]
                // data.awards.map(a=>{
                //     if(a.box_record.user_id !== data.user_id){
                //         drawList.push(a)
                //     }
                // })
                // console.log(drawList)
                // drawList.map((a,i)=>{
                //     if(a.box_record.user_id === this.user_info.id){
                //         meDate=a;
                //         return drawList.splice(i,1)
                //     }
                // })
                // console.log(drawList)
                // if(meDate.box_record){
                //     drawList.unshift(meDate)
                // }

                // this.bearThePalm.PolicyTableData = drawList;          
                // this.bearThePalm.FilterTableData = drawList;
                // if(drawList)this.bearThePalm.count = drawList.length

                //箱子
                this.awards = data.awards;
                if (this.page1 == 1) {
                    this.awards12 = data.awards;
                }
                this.boxList.PolicyTableData = data.awards;
                this.boxList.FilterTableData = data.awards;
                if (data.awards) this.boxList.count = data.awards.length
            } catch (e) {
                console.log(e)
            }
        },
        async getRoomsUsers() {
            try {
                let { data, code } = await $api.getRoomsUsers(this.id, this.page2, 20)
                if (code == 500) return;
                //参与人数
                if (!data.length) return this.page2--
                this.users = data;
                this.participants.PolicyTableData = data;
                this.participants.FilterTableData = data;
                if (data.users) this.participants.count = data.length
            } catch (e) {
                console.log(e)
            }
        },
        async getAwardsUser() {
            try {
                let { data, code } = await $api.getAwardsUser(this.id, this.page0, 12)
                if (code == 500) return;
                //参与人数
                if (!data.length) return this.page0--
                this.bearThePalm.PolicyTableData = data;
                this.bearThePalm.FilterTableData = data;
                if (data) this.bearThePalm.count = data.length
            } catch (e) {
                console.log(e)
            }
        },
        laypage(val) {
            if (val) {
                this.page0++
                this.getAwardsUser(this.page0)
                // window.scrollTo(0,0);
            } else if (this.page0 > 1) {
                this.page0--
                this.getAwardsUser(this.page0)
                // window.scrollTo(0,0);
            }
        },
        laypageUser(val) {
            if (val) {
                this.page2++
                this.getRoomsUsers(this.page2)
                // window.scrollTo(0,0);
            } else if (this.page2 > 1) {
                this.page2--
                this.getRoomsUsers(this.page2)
                // window.scrollTo(0,0);
            }
        },
        laypageAwards(val) {
            if (val) {
                this.page1++
                this.getRoomsAwards(this.page1)
                // window.scrollTo(0,0);
            } else if (this.page1 > 1) {
                this.page1--
                this.getRoomsAwards(this.page1)
                // window.scrollTo(0,0);
            }
        },
        // 导航切换
        tabClick(state) {
            this.state = state
            this.getRoomsDetail()
        },

        addRollHomeJR() {
            let userId = this.$store.state.user_info

            //已参加用户
            if (this.details.users) {
                let user = this.details.users.findIndex(item => {
                    return item.user_id == userId.id
                })
                return user === -1 ? true : false
            }

        }
    }
}
</script>

<style scoped lang="scss">
.password-room-pop-up {
    .password-room-pop-up-title {
        font-weight: 700;
        color: #ddd;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .password-room-pop-up-info {
        margin: 30px 0;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .password-room-pop-up-btns {
        display: flex;
        justify-content: space-around;
        color: #ddd;
        font-family: Monaco;
        cursor: pointer;

        &>div {
            border: 1px solid rgba(248, 225, 21, .5843137255);
            background-color: rgba(106, 97, 13, .3294117647);
            border-radius: 4px;
            padding: 8px 0;
            width: 130px;
            justify-content: center;
            display: flex;
            font-size: 14px;
            align-items: center;
            font-family: Monaco;
        }

    }
}

.roll-container-tabs {
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    .tabs {
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 400;
        color: #CCCCCC;
    }

    .tabs:last-child {
        margin-left: 36px;
    }

    .tabs.active {
        font-weight: bold;
        color: #35C9EE;
        background-image: url(../../assets/img/ADT/common/active-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        height: 60px;
        line-height: 60px;
    }
}


.f_ROLL_left {
    // clip-path: polygon(0 0, calc(100% - 14%) 0, 100% 100%, 0% 100%)
}

.f_ROLL_right {
    // clip-path: polygon(14% 0, 100% 0, 100% 100%, 0% 100%);
}

@media (max-width:1200px) {

    .f_ROLL_left,
    .f_ROLL_right {
        clip-path: initial;
    }
}

.bg-red {
    background-image: url('../../assets/img/f_new/ROLL_Z_J-2.png');
    border-radius: 8px;
}

.bg-blue {
    background: rgb(9, 145, 170, .1);
    border-radius: 8px;
}

.f-Registered-user {
    width: 20%;

    @media (max-width:1200px) {
        width: 50%
    }

    @media (max-width:1366px) {
        width: 30%
    }

    @media (max-width:960px) {
        width: 50%
    }
}

.list-box {
    width: calc(100% / 5);

    @media (max-width: 1600px) {
        width: calc(100% / 5);
    }

    @media (max-width: 1200px) {
        width: calc(100% / 3);
    }

    @media (max-width: 960px) {
        width: calc(100% / 2);
    }
}

.ROLL-gun {
    width: 80px;
    height: 80px;
    margin-left: 14%;
}

.ROLL-users {
    background-color: rgba(9, 145, 170, 0.1);

    // border-style: solid;
    // border-width: 1px;
    // border-image-slice: 1;
    // border-image-source: linear-gradient(to right, rgba(255,255,255, 0), rgba(255,255,255, 0) 28%, rgba(255,255,255,1) 50%, rgba(255,255,255, 0) 74%, rgba(255,255,255, 0) 0%);
    &-user {
        width: 98%;
        background-color: rgba(9, 145, 170, 0.1);
        border-style: solid;
        border-width: 1px;
        border-image-slice: 1;
        border-image-source: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 28%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 74%, rgba(255, 255, 255, 0) 0%);

        &-user {
            right: -15px;
            top: 55%;

        }

        &-img {
            // clip-path: polygon(25% 10%, 75% 10%, 100% 50%, 75% 90%, 25% 90%, 0% 49%);
            border-radius: 50%;
            // border: 3px solid RGBA(22, 97, 114, 1);
        }


    }

    &-conter {

        width: 9.9%;
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 50%;
        z-index: 3;
        transform: translateX(-50%);
        background-image: linear-gradient(to right, rgba(233, 77, 139, 0.08), transparent 30%, transparent 70%, rgba(233, 77, 139, 0.08));
        border-top: 1px solid rgb(233, 77, 139);
        border-bottom: 1px solid rgb(233, 77, 139);
        border-image: initial;
        border-left: none;
        border-right: none;

        @media (max-width:1000px) {
            width: 48%;
        }

        &::before {
            top: -1px;
            border-width: 7px 8px 0 8px;
            border-color: #e94d8b transparent transparent transparent;
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            z-index: 4;
            border-style: solid;
        }

        &::after {
            bottom: -1px;
            border-width: 0 8px 7px 8px;
            border-color: transparent transparent #e94d8b transparent;
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            z-index: 4;
            border-style: solid;
        }
    }
}

.ROLL-user {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 14%;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
        background: linear-gradient(144deg, rgba(57, 85, 232, 0) -11%, rgba(57, 85, 232, 0.6) 111%);

    }

    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, .3);
}

.content {
    width: 80%;
    margin: 0 auto;

    &-top {
        width: 100%;
    }

    @media (max-width:1366px) {
        width: 95%;
    }
}

.weapon-phone {
    width: 10%;

    @media (max-width:1600px) {
        width: 20%;
    }

    @media (max-width:1366px) {
        width: 25%;
    }

    @media (max-width:960px) {
        width: 33.33%;
    }

    @media (max-width:760px) {
        width: 50%;
    }
}
</style>
