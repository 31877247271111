<template>
<div>
  <div class="d-flex justify-content-between">
    <div class="h4 font-weight-bold d-md-block d-none">
      充值历史
    </div>

  </div>
  <div>
    <div class="mt-3  content-list">
      <table cellspacing="0" cellpadding="0" border="0" class="content-list-box" style="">
        <colgroup>
          <col width="100">
          <col width="100">
          <col width="200">
          <col width="100">
          <col width="200">
        </colgroup>
        <thead class="text-danger">
        <tr class="grade">
          <th colspan="1" rowspan="1">
            <div class="cell">充值金额 </div>
          </th>

          <th colspan="1" rowspan="1">
            <div class="cell">充值价格</div>
          </th>

          <th colspan="1" rowspan="1">
            <div class="cell">订单号</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell">状态</div>
          </th>

          <th colspan="1" rowspan="1">
            <div class="cell">时间</div>
          </th>

        </tr>
        </thead>
        <tr class="grade lucky-H-bg border-bottom"  v-for="(item,index) in tableData" :key="index">

          <td >
            <div class="cell">{{item.bean }}</div>
          </td>

          <td >
            <div class="cell">{{item.price }}</div>
          </td>


          <td >
            <div class="cell">{{[100, 99].includes(item.pay_code) ? item.trade_no : item.code }}</div>
          </td>

          <td >
            <div class="cell">{{item.status_alias }}</div>
          </td>

          <td >
            <div class="cell">{{item.created_at}}</div>
          </td>
        </tr>
      </table>
      <div class="mt-3">
        <!-- <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                @current-change="currentChange">
        </el-pagination> -->
      </div>
    </div>

  </div>
</div>
</template>

<script>
  export default {
    name: "pay",
    props:{
      tableData:{
        type:Array,
        default(){return []}
      },
      total:{type:Number || String,default() {
          return '';
        }},
      pageSize:{type:Number || String,default() {
          return '';
        }}
    },
    methods:{
      currentChange(val){
        this.$emit('currentChange',val)
      }
    }
  }
</script>


<style scoped lang="scss">
  .content-list{
    overflow-x: scroll;
    width: 100%;
    &-box{
      width: 100%;
    }
  }

  td{
    height: 100px;
    vertical-align: middle;
  }
  .grade {
    background-image: linear-gradient(0deg,#13161b,#20232b),linear-gradient(#13161b,#13161b);
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }



  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
  }

  .content {
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
      font-size: 12px !important;
    }
  }

  .cell {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 14px;
    @media (max-width: 1200px) {
      width: 100px;
      word-break: break-all;
      line-height: 1.5;
      padding: 5px;
    }
  }
  .highlighted-message {
    padding: 19px 5px 19px 16px;
    border: 1px solid #fada62;
    border-radius: 8px;
    background-color: rgba(250,218,98,0.12);
  }

</style>
