<template>
  <div class="mb-activity">
    <div class="activity-head">
      <div class="activity-close" @click="$router.go(-1)">
        <!-- <i  class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 28px;"></i> -->
        <img style="width: 25px;" src="../../../assets/img/ADT/event-hall/close.png" alt="">
      </div>
    </div>
    <div class="mb-tabs-list">
      <div class="tabs-list flex justify-center" @click="listTabsVal = item.value"
        :class="{ 'selected-tabs': listTabsVal === item.value }" v-for="(item, index) in listTabs" :key="index">
        <!-- <img v-if="index===1||index===2"  src="../../../static/media/hot-icon.png" width="12px" height="14px"> -->
        {{ item.label }}
        <!-- <div class="red" v-if="index===1||index===2"></div> -->
      </div>
    </div>
    <!-- 每日签到 -->
    <div style="min-height:400px" v-show="listTabsVal === -1">
      <mrqd></mrqd>
    </div>
    <!-- 热门活动 -->
    <div style="min-height:400px" v-show="listTabsVal === 0">
      <div class="home-activity" @click="toUrl(item)" v-for="(item, x) in activityList" :key="x">
        <div class="home-activity-head">
          <div>{{ item.title }}</div>
          <div>{{ item.intro }}</div>

        </div>
        <div class="home-activity-main-img">
          <img :src="item.image" alt="" />

        </div>
      </div>
    </div>
    <div class="mission-list" v-show="listTabsVal === 1">
      <div class="mission-list-tip">
        <div style="width: 20px;margin: 0 10px 0px;">
          <img style="width: 100%;" src="../../../assets/img/ADT/event-hall/laba.png" alt="">
        </div>
        <div style="width:100%">
          <el-carousel height="30px" :interval="2000" direction="vertical" indicator-position="none">
            <el-carousel-item v-for="(item, a) in inform" :key="a">
              <p class="medium"> {{ item.timeTips }} <span class="text-orange">{{ item.name }}</span> 完成 <span
                  class="text-orange">{{ item.task }}</span> 获得 <span class="text-orange">{{ item.award }}</span> !</p>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div @click="missionabsVal = item.id" :class="{ 'selected-tabs': missionabsVal === item.id }"
        v-for="(item, index) in taskList" :key="index">
        <div class="tabs-item">
          <p>{{ item.title }}</p>
          <img src="../../../assets/img/ADT/zm/down.png" alt="">
        </div>
           <!-- 任务中心 -->
      <div class="mission-center" v-if="missionabsVal === item.id">
        <!-- 任务中心 卡片化店家 -->
        <div class="task-list" v-for="(item, index) in taskList[missionabsVal === 1 ? 0 : 1].list" :key="index">
          <div class="padding-tb-xs">
            <div class="task-list-img">
              <!-- <img src="@/static/media/xiangzi1.png"
                :class="{ 'received-img': !(item.threshold > item.hasBean) && !item.has_get }" /> -->
              {{ item.type_name }}
            </div>
            <div style="margin:10px 0">{{ item.name }}（<span class="text-orange">{{ item.hasBean > item.threshold ?
              item.threshold : item.hasBean }}</span>/{{ item.threshold }}）</div>
          
            <div class="flex" style="font-size:12px;line-height:1.5">任务奖励：
              <div class="margin-right-xs" v-if="item.min_reward">
                <span class="text-orange">保底{{ item.min_reward }}</span>
                <img src="@/assets/img/ADT/home/up.png" alt="" style="width: 12px; height: 12px;margin-left:4px;">
              </div>
            
            </div>
          </div>
          <div class="task-right">
            <div class="task-list-right ok _a" v-if="!(item.threshold > item.hasBean) && !item.has_get"><a
                @click="tapDraw(item)" style="color:rgb(23, 180, 237)"><img
                  src="@/assets/img/ADT/event-hall/lqjl.png" /></a></div> <!--达到了数额但是没有领取-->
            <div class="task-list-right accomplish" v-else-if="!(item.threshold > item.hasBean) && item.has_get"><img
                src="@/assets/img/ADT/event-hall/ywc.png" /></div>
            <a class="task-list-right main" v-else style="color:rgb(23, 180, 237)"
              :href="item.type === 2 ? '/FmysteryBox' : item.type === 1 ? '/' : '/Flucky'"><img
                src="@/assets/img/ADT/event-hall/go.png" /> </a>
          </div>
        </div>

      </div>
      </div>
   
    </div>


    <!-- 红包列表 -->
    <div v-show="listTabsVal === 2">
      <!-- <redenvelopes></redenvelopes> -->
      <vipfl></vipfl>
    </div>

    <!-- 等级福利 -->
    <div v-show="listTabsVal === 5">
      <div class="tb-skype">
        <div class="tb-head">
          <div class="tb-fox fox-0"></div>
          <div class="tb-fox fox-1">等级</div>
          <div class="tb-fox fox-3">充值金额</div>
          <div class="tb-fox fox-4">升级奖励</div>
          <div class="tb-fox fox-5">充值加成</div>
        </div>
        <div class="tb-body"
          :class="{ 'pitch-box-set': item.level == Viptips.next_level_value, 'pitch-box': item.level - 1 < Viptips.next_level_value }"
          v-for="(item, index) in VipList" :key="index">
          <div class="tb-fox fox-0"><img v-if="item.level == Viptips.next_level_value" src="@/static/media/up.gif"></div>
          <div class="tb-fox fox-1">V{{ item.level }}</div>
          <div class="tb-fox fox-3">{{ Number(item.threshold) }}</div>
          <div class="tb-fox fox-img fox-4">
            <money />{{ Number(item.packet) }}
          </div>
          <div class="tb-fox fox-5">{{ item.rebate }}%</div>
        </div>
      </div>

      <div class="highlighted-message text-center ">
        <div class="highlighted-message-body">
          您距离升级{{ Viptips.next_level }} 还需充值
          <money /> {{ Viptips.next_lack }}.将享受 {{ Viptips.next_rebate }} % 充值加成。
        </div>
      </div>
      <div class="h3 text-white font-weight-bold text-center mt-5 mb-3 position-relative">
        <div class="mb-2">VIP等级说明</div>
      </div>
      <div class="desc ">
        <div class="item">
          <!--                <div class="tilte">会员如何升级？</div>-->
          <div class="info">
            自动降级后，仅需充值对应等级的40%额度即可完成升级。 <br>
            举例：一月充值2588.88F到达VIP3，二月一号降级至VIP2，二月份充值1036F（2588.88x0.4）即可再次升级至VIP3。
          </div>
        </div>
      </div>

    </div>
    <gradebenefits v-show="listTabsVal === 4"></gradebenefits>
    <el-dialog custom-class="custom_dialog" title="" :close-on-click-modal="false" :visible.sync="operateVisible"
      append-to-body center width="300px">
      <div class="text-center padding-tb text-white">恭喜您完成任务,领取到了<span class="text-orange">{{ muVal }}U币</span></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="operateVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import $api from '@/api/vip/index'
import $activityApi from '@/api/activity.js'
import sUserAgent from "@/assets/js/common.js";
// import redenvelopes from '@/layout/common/Redenvelopes.vue'
import vipfl from '@/layout/common/vipfl.vue'
import gradebenefits from '@/layout/common/gradebenefits.vue'
// import freeskin from '@/layout/common/freeskin.vue'

import mrqd from '../../../layout/common/mrqd.vue'
export default {
  components: {
    vipfl,
    mrqd,
    // redenvelopes,
    // freeskin,
    gradebenefits
  },
  data() {
    return {
      dialogVisible: false,
      operateVisible: false,
      muVal: '',
      listTabs: [
      // '热门活动', '任务中心', 'VIP福利'
      {
          label: "每日签到",
          value:-1
        },
        {
          label: "热门活动",
          value:0
        },
        {
          label: "任务中心",
          value:1
        },
        {
          label: "VIP福利",
          value:2
        },
      ],
      // ['热门活动', '任务中心','充值补给', '口令红包']
      activityList: [],
      taskReceiveForm: {
        days: 0,
        all_configs: [],
        today_signed: false,
      },
      taskList: [
        {
          id: 1,
          title: '盲盒任务',
          finishAward: 10,   //完成奖励
          finishState: false,   //完成奖励状态
          list: [],
          icon: require(`../../../assets/img/ADT/event-hall/1.png`),
          active: require(`../../../assets/img/ADT/event-hall/11.png`),
        },
        {
          id:2,
          title:'对战任务',
          finishAward:10,   //完成奖励
          finishState:false,   //完成奖励状态
          list:[],
          icon: require(`../../../assets/img/ADT/event-hall/0.png`),
          active: require(`../../../assets/img/ADT/event-hall/00.png`),
        },
        {
          id: 3,
          title: '追梦任务',
          finishAward: 10,   //完成奖励
          finishState: false,   //完成奖励状态
          list: [],
          icon: require(`../../../assets/img/ADT/event-hall/2.png`),
          active: require(`../../../assets/img/ADT/event-hall/22.png`),
        },
      ],
      missionabsVal: 1,
      listTabsVal: -1,
      checkedNumber: 2,

      inform: [
        {
          timeTips: '12分钟之前',   //时间
          name: "小李**",   //人名
          task: '上山',   //目标
          award: '保底20任务目标'   //奖励
        },
        { timeTips: '2分钟之前', name: "猪猪**", task: '刀山', award: '保底120任务目标' },
        { timeTips: '32分钟之前', name: "不知名**", task: '火海', award: '保底120任务目标' },
      ],
      Viptips: {},

      VipList: []
    };
  },
  watch: {
    missionabsVal: {
      handler(val) {
        console.log("val----", val);
        if (val) this.taskDataList(val)
        else this.taskReceiveList()
      },
      deep: true,
      immediate: true,
    }
  },
  computed: {
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  methods: {
    toUrl(data) {
      console.log("data", data);
      // if (!data.link_url || data.id === 8 || data.id === 4) return
      
      if (this.sUserAgent == 'phone') {
        this.$router.push({ path: data.link_url })
      } else {
        let routerPath = this.$router.resolve({ path: data.link_url })
        window.open(routerPath.href, '_blank')
      }
      
      
    },
    getVipList() {
      $api.getVipList().then(res => {
        if (res.code === 200) {
          this.VipList = res.data.list.reverse()
          this.Viptips = res.data.tips
          this.Viptips.next_level_value = this.Viptips.next_level_value - 1
          this.checkedNumber = res.data.tips.next_level
        }
      })
    },
    // 领取奖励
    tapDraw(data) {
      $activityApi.consumePost({ beans_id: data.id, type: data.type, by_type: 1 }).then(res => {
        if (res.code === 200) {
          this.operateVisible = true
          this.muVal = res.data.reward_bean
          this.taskDataList(this.missionabsVal)
        }
      })
    },
    // 查询海报
    inquireList() {
      $activityApi.activityList().then(res => {
        if (res.code === 200) {
          this.activityList = res.data
        }
      })
    },
    taskDataList(val) {
      $activityApi.getConsume({ by_type: 1, type: val }).then(res => {
        console.log("this.missionabsVal----->", this.missionabsVal);
        let index = 0
        if (this.missionabsVal == 1) {
          index = 0
        } else {
          index = 1
        }
        this.taskList[index].list = res.data
      })

    },
    // 签到列表
    taskReceiveList() {
      $activityApi.taskReceive().then(res => {
        if (res.code === 200) {
          this.taskReceiveForm = res.data
        }
      })
    },
    // 消息列表
    informationList() {
      $activityApi.remindList().then(res => {
        if (res.code === 200) {
          this.inform = res.data
        }
      })
    },
    // 签到
    topAttendance() {
      $activityApi.signUp().then(() => { this.taskReceiveList() })
    },
    //领取签到奖励
    receiveAward(data) {
      $activityApi.signIn({ day: data.day, type: 1 }).then(res => {
        if (res.code === 200) {
          this.taskReceiveList()
          this.muVal = res.data.amount
          this.operateVisible = true
        }
      })
    }
  },
  created() {
    if (this.sUserAgent != "phone") {
      return this.$router.push('/')
    }
    this.getVipList()
    this.inquireList()
    this.informationList()
    this.dialogVisible = true
  }
};
</script>
<style scoped lang="scss">
.tabs-line-plact {
  width: 23px;
  height: 2px;
  background: transparent;
  border-radius: 2px;
  margin: 10px auto 0 auto;
}

.tabs-line {
  width: 23px;
  height: 2px;
  background: #F9C545;
  border-radius: 2px;
  margin: 10px auto 0 auto;
}

@keyframes move {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes rock {
  10% {
    transform: rotate(15deg)
  }

  20% {
    transform: rotate(-10deg)
  }

  30% {
    transform: rotate(5deg)
  }

  40% {
    transform: rotate(-5deg)
  }

  50%,
  100% {
    transform: rotate(0)
  }
}

.mb-activity {
  background-color: RGBA(21, 23, 44, 1);

  .draw {
    position: relative;

    .lingQu {
      left: 55px;
      top: 0;
      position: absolute;
    }
  }

  .sign-rule {
    img {
      width: 16px;
      vertical-align: middle;
      margin-right: 10px;
    }

    width: 330px;
    line-height: 1.5;
    font-size:12px;
    padding-left:20px;
    padding-top:80px;
    height: 240px;
    color: #fff;
    background: url('../../../static/media/qiandao/rule.png') no-repeat;
    background-size: 100% 100%;
  }

  .pitch-box-set {
    color: rgb(233, 177, 14);
  }

  .received-img {
    animation: move 1s 0.15s linear infinite;
  }

  .highlighted-message {
    width: 83%;
    line-height: 1.3;
    margin: 10px auto;
    padding: 19px 5px 19px 16px;
    border: 1px solid #fada62;
    border-radius: 8px;
    background-color: rgba(250, 218, 98, 0.12);

  }

  .desc {
    width: 90%;
    margin: 20px auto;
    line-height: 1.3;

    @media (max-width: 1200px) {
      justify-content: flex-start;
      width: 90%;
      font-size: 12px !important;
      line-height: 1.3;
    }

    & .item {
      margin-bottom: 20px;

      & .tilte {
        margin-bottom: 10px;
        font-size: 12px;
        color: rgb(255, 255, 255);
      }

      & .info {
        color: rgb(181, 187, 194);
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }

  .tb-skype {
    padding: 10px;
    width: 100%;
    margin-top: 10px;

    .tb-head {
      text-align: center;
      display: -webkit-box;
      box-sizing: border-box;
    }

    .tb-fox {
      width: 80px;
      line-height: 30px;
      height: 30px;
    }

    .fox-0 {
      width: 20px;

      img {
        width: 16px;
        height: 20px;
        vertical-align: middle;
        line-height: 30px;
      }
    }

    .tb-body {
      display: -webkit-box;
      box-sizing: border-box;
      text-align: center;
      position: relative;

      .arrive-img {
        position: absolute;
        top: 32px;
        left: calc(50% - 10px);
      }
    }

    .fox-img {
      vertical-align: middle;

      img {
        margin-right: 4px;
      }
    }

    .fox-schedule {
      width: 100%;
      background: #3C2E04;
      height: 6px;
      margin: 12px 0;
    }

    .arrive {
      height: 100%;
      position: absolute;
      background: linear-gradient(224deg, #F6D920 0%, #E9B10E 100%);
      box-shadow: 0px 0px 8px 0px rgba(255, 212, 88, 0.51);
      border-radius: 4px;
    }
  }

  .activity-head {
    width: 100%;
    height: 140px;
    position: relative;
    background: url("../../../assets/img/ADT/event-hall/task_mb.png") no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 2;

    .activity-close {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 10px;
      top: 10px;
      // background: url('https://zeroskins.com/static/media/close.5ac8e41c.svg') no-repeat;
      background-size: 100% 100%;
    }
  }

  //切换样式
  .mb-tabs-list {
    display: flex;
    font-size: 14px;
    z-index: 3;

    .tabs-list {
      width: 100px;
      text-align: center;
      height: 36px;
      flex: 1;
      display: flex;
      position: relative;
      align-items: center;
      line-height: 36px;
      font-size: 12px;
      margin: 20px 0 22px 0;
      // background: linear-gradient(0deg, #1B1C27, #313348);
      // border: 1px solid #000000;



      >.red {
        position: absolute;
        width: 6px;
        height: 6px;
        right: 10px;
        top: 6px;
        border-radius: 50%;
        background-color: red;
      }

      >img {
        margin-right: 4px;
      }
    }

    >.tabs-list:first-child {
      color: #fff;
      background-image: url(../../../assets/img/ADT/roll/gf-d.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
      // background: linear-gradient(to top, #D2704A, #FFBE83);

    }

    >.tabs-list:nth-child(2) {
      color: #fff;
      background-image: url(../../../assets/img/ADT/roll/zb-d.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
      // background: linear-gradient(to top, #4CD4BB, #9AEDDF);
    }

    >.tabs-list:nth-child(3) {
      color: #fff;
      background-image: url(../../../assets/img/ADT/roll/zb-d.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
      // background: linear-gradient(to top, #4CD4BB, #9AEDDF);
    }

    >.tabs-list:nth-child(4) {
      color: #fff;
      background-image: url(../../../assets/img/ADT/roll/wcyd-d.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
      // background: linear-gradient(to top, #6D90D1, #96DFFF);
    }

    >.tabs-list:first-child.selected-tabs {
      color: #55330B !important;
      // background: #000 !important;
      font-weight: bold;
      background-image: url(../../../assets/img/ADT/roll/gf-active.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
    }

    >.tabs-list:nth-child(2).selected-tabs {
      color: #55330B !important;
      // background: #000 !important;
      font-weight: bold;
      background-image: url(../../../assets/img/ADT/roll/zb-active.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
    }

    >.tabs-list:nth-child(3).selected-tabs {
      color: #55330B !important;
      // background: #000 !important;
      font-weight: bold;
      background-image: url(../../../assets/img/ADT/roll/zb-active.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
    }
    >.tabs-list:nth-child(4).selected-tabs {
      color: #55330B !important;
      // background: #000 !important;
      font-weight: bold;
      background-image: url(../../../assets/img/ADT/roll/wcyd-active.png);
      background-repeat: no-repeat;
      background-size: 90% 100%;
      background-position: center;
    }


  }

  .mission-list {
    color: #C3C3E2;
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #1B3B53;
    // background-color: #282832;

    .mission-list-tip {
      width: 100%;
      height: 30px;
      display: flex;
      margin: 0 auto 10px auto;
      position: relative;
      font-size: 14px;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: hidden;
      line-height: 30px;
      white-space: nowrap;
      border-radius: 5px;
      text-overflow: ellipsis;
      background-color: rgb(0, 0, 0, 0.3);

      .medium {
        width: 100%;
        font-size: 12px;
        ;
        color: #848492;
        line-height: 30px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }

    .tabs-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;

      &>img {
        width: 25px;
      }
    }
      // 任务中心
  .mission-center {
    padding: 10px;

    .task-list {
      width: 100%;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      position: relative;
      // background: #3F4151;
      box-sizing: border-box;
      min-height: 85px;
      padding-left: 5px;
      border-radius: 5px;
      margin-bottom: 10px;

      .task-list-img {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        img {
          width: 30px;
          height: 22px;
          margin-right: 10px;
        }
      }

      .task-right {
        line-height: 80px;
        text-align: center;
        min-width: 70px;

        a {
          width: 100%;
          height: 100%;
          display: block;
        }

        img {
          width: 80%;
          line-height: 80px;
          vertical-align: middle;
        }
      }

      .task-list-right.ok {
        height: 100%;
        // background: #53A228;

        img {
          width: 75%;
          margin-top: 14px;
        }
      }

      .task-list-right.main {
        height: 100%;

        // background: #FFC67B;
        img {
          margin-top: 14px;
        }
      }

      .task-list-right.accomplish {
        height: 100%;

        // background: #494949;
        img {
          width: 80%;
          margin-top: 10px;
        }
      }
    }

    .selected-tabs {
      color: #E9B10E;
    }



  }

    >div {
      position: relative;
      width: 100%;
      font-size: 12px;
      color: #00B9D4;

      >.red {
        position: absolute;
        width: 6px;
        height: 6px;
        right: 14px;
        top: -4px;
        border-radius: 50%;
        background-color: red;
      }

      >img {
        margin-right: 4px;
      }
    }

    >.selected-tabs {
      // background: linear-gradient(to top, #FFC67B, #282832);
      color: #F9C545;
      font-weight: bold;
    }

    div+div {
      // border-left:1px solid #48485694;
    }
  }



  .MuiBox-award {
    background: url("https://zeroskins.com/static/media/h5_task.11dfc72f.png") 0% 0% / 100% 100% no-repeat;

    .award-left {
      color: #E9B108;
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding: 20px 0;
    }

    .award-right {
      width: 100%;
      color: #C3C3E2;
      text-align: center;
      line-height: 70px;
    }

    .award-main {
      width: 100%;
      text-align: center;

      .award-tip {
        color: #FFFFFF;
        font-size: 12px;
        margin-top: 10px;
      }

      img {
        height: 78px;
        animation: move 1.5s ease infinite;
      }

      .award-bottom {
        color: #E9B108;
        font-size: 14px;
      }
    }
  }

  .home-activity {
    padding-top: 10px;
    margin-bottom: 20px;

    .home-activity-head {
      color: #E9B10E;
      position: relative;
      font-size: 14px;
      text-align: left;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 0 10px;

      &>div:first-child {

        font-size: 14px;
        font-weight: bold;
        color: #F9C545;
      }

      &>div:last-child {

        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }

      .home-activity-head-img {
        top: 50%;
        left: 50%;
        width: 100%;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }
  }

  .welfare-tips {
    text-align: center;
    font-size: 12px;
    margin: 10px 0;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
  }

  .home-activity-main-img {
    padding: 10px 10px 0 10px;

    img {
      width: 100%;
    }

    .activity-mian-text {
      color: #fff;
      width: 100%;
      padding: 15px;
      font-size: 14px;
      background: #333;
    }
  }

  .text-orange {
    color: #E9B108;
  }
}</style>
