<template>
    <div class="grade-membership">
        <div class="grade-h2">会员等级</div>
        <div class="tb-skype">
            <div class="tb-head">
                <div class="tb-fox fox-1"></div>
                <div class="tb-fox fox-2">等级</div>
                <div class="tb-fox fox-3">充值金额</div>
                <div class="tb-fox fox-4">升级奖励</div>
                <div class="tb-fox fox-5">充值加成</div>
            </div>
            <div class="tb-body" :class="{'pitch-box-set' :item.level == Viptips.next_level_value,'pitch-box' :item.level-1 < Viptips.next_level_value}" v-for="(item,index) in VipList"
                :key="index">
                <div class="tb-fox fox-1">V{{ index+1 }}</div>
                <div class="tb-fox fox-2 fox-schedule"></div>
                <div class="tb-fox fox-3">{{ Number(item.threshold) }}</div>
                <div class="tb-fox fox-img fox-4"><money style="width:10px;height:10px" />{{  Number(item.packet) }}</div>
                <div class="tb-fox fox-5">{{item.rebate }}%</div>
                <img v-if="item.level === Viptips.next_level_value"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACyklEQVRIS7WWT0hUQRzHf7+ZeW/fquw+3bY1U4otlkAKSiEiJBMRKjqFl+jSoUPULbqmdY1uRYcOXaJLdIoQRMyQiEANCiGkpNDMbdt8u6n79r2Z+cXuZmqi9u/9rjPz+8x8f/8GYRMjIgS4igDjCEMZLG9vjxNAMwH0ECLSRi4qB34xIkB40M0gOcnASwvQrsg5vkBRggGQRIrahgRmSTATEiaTGrofaERYA1sDIOplMPqIp7PTpuSLllUwLa39sEIdCpHBS4Ai+ooTKzJmFNyw5wpV5SZijR60nFSIvXrlfVcBys777hlzRtbSLqtBXoxKD21hYEQqrGZAZumwBvQEpwXpU16Y5JAK5Zil52v9mAvHzvgrIT8BS85zOlNFPkYV13GJvN6qb9pX3ZjqYNWRZiZMuwyQnqMX8uML0xOD7uzUK0FqliuWQYNyURZfXAmpaFoK5GirmJubrMJ5qtVCbZM8lKzbf/ScsONtAMDWCaSWTmb468snd4QqTjLJP1ENztXWJhehZUSWEuAHoJel+2+HhSdtEH4DkZmyDx6/IqpqUptlWWldLn6bcF70XUP0JkAaM9IUTqLrfKEkVQUw0mJ8dCYi4XlKaG7ssls7Lwk7fuR3nC/tkU7mqTMycIMp/12hBtPb7VQeW0d9LMvzvMnKZgt1Snk7rIadHZG9bVc3kGU9rs6/Hu5xZ94Pcm5+iMXCX+HQlIvl4D67WZ3JegkuYHfkQNdlYW/t+JPbL7/i82B+rP+6kvA2HjPTcPjiAhJ189zj/ojSqkEC37Ol/dQtZliJvwFo301/GXp4QYB6wxmfiZ7oyiM9aRf54lhEEW9Uvmre0nn6LiAL/Q0ASBe/DNw/yw0+zlFNR0IH/i+ASBezawCBSxR8kANO08ALrdKLgm4VQTe7pVeUZkEg7XqpqAIdOKsgQY3MZUiAQ3/tD+Pfvi3fARAoZuPsumIkAAAAAElFTkSuQmCC"
                    class="arrive-img" icon="1" orientation="horizontal" />
            </div>
        </div>
        <div class="highlighted-message text-center ">
            <div class="highlighted-message-body">
                您距离升级{{ Viptips.next_level }} 还需充值
                <money /> {{ Viptips.next_lack }}.将享受 {{ Viptips.next_rebate }} % 充值加成。
            </div>
        </div>
        <div class="h3 text-white font-weight-bold text-center mt-5 mb-3 position-relative">
            <div class="mb-2">VIP等级说明</div>
        </div>
        <div class="desc ">
            <div class="item">
                <!--                <div class="tilte">会员如何升级？</div>-->
                <div class="info">
                    自动降级后，仅需充值对应等级的40%额度即可完成升级。 <br>举例：一月充值2588F到达VIP3，二月一号降级至VIP2，二月份充值1035.2F（2588x0.4）即可再次升级至VIP3。
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $api from '@/api/vip/index'
export default {
    data() {
        return {
            checkedNumber: 2,
            Viptips:{},
            VipList: []
        };
    },
    mounted() {
        this.getVipList()
    },
    methods: {
        getVipList() {
            $api.getVipList().then(res => {
                this.VipList = res.data.list
                this.Viptips = res.data.tips
                this.Viptips.next_level_value = this.Viptips.next_level_value-1
                this.checkedNumber = res.data.tips.next_level
            })
        }
    }
}
</script>
<style scoped lang="scss">
.grade-membership {
    max-width: 710px;
    overflow-x: auto;
    color: #fff;

    .grade-h2 {
        text-align: center;
        font-size: 20px;
        line-height: 2;
        font-weight: bold;
    }

    .tb-skype {
        display: -webkit-box;
        font-size: 12px;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        margin-top: 10px;

        .tb-head {
            width: 60px;
        }

        .tb-fox {
            line-height: 30px;
            height: 30px;
        }

        .tb-body {
            width: 64px;
            text-align: center;
            position: relative;

            .arrive-img {
                position: absolute;
                top: 32px;
                left: calc(50% - 10px);
            }
        }

        .fox-img {
            vertical-align: middle;

            img {
                margin-right: 4px;
            }
        }

        .fox-schedule {
            width: 100%;
            background: #3C2E04;
            height: 6px;
            margin: 12px 0;
        }

        .arrive {
            height: 100%;
            position: absolute;
            background: linear-gradient(224deg, #F6D920 0%, #E9B10E 100%);
            box-shadow: 0px 0px 8px 0px rgba(255, 212, 88, 0.51);
            border-radius: 4px;
        }
    }

    .tb-skype::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
    }

    .pitch-box {
        .fox-schedule {
            width: 100%;
            background: #F6D920;
            box-shadow: 0px 0px 8px 0px rgb(255 212 88 / 51%);
            height: 6px;
            margin: 12px 0;
        }
    }
    .pitch-box-set {
        color: rgb(233, 177, 14);
    }
    .highlighted-message {
        width: 83%;
        margin: 10px auto;
        padding: 19px 5px 19px 16px;
        border: 1px solid #fada62;
        border-radius: 8px;
        background-color: rgba(250, 218, 98, 0.12);

        @media (max-width: 1200px) {
            width: 700px;
        }
    }
    .h3{
        @media (max-width: 1200px) {
            width: 700px;
        }
    }
    .grade-h2{
        @media (max-width: 1200px) {
            width: 700px;
        }
    }
    .desc {
        width: 90%;
        margin: 20px auto;

        @media (max-width: 1200px) {
            justify-content: flex-start;
            width: 700px;
            font-size: 12px !important;
            line-height: 1.3;
        }

        & .item {
            width: 650px !important;
            margin-bottom: 50px;

            & .tilte {
                margin-bottom: 20px;
                font-size: 17px;
                color: rgb(255, 255, 255);
            }

            & .info {
                width: 650px !important;
                color: rgb(181, 187, 194);
                font-size: 17px;
                line-height: 1.5;
            }
        }
    }
}
</style>