<template>
    <div class="content">

        <!-- VS PK -->
        <div  class="animate__animated">

            <div class="content-bottom d-flex justify-content-around align-items-center ">

                <div class="content-bottom-left text-center" style="">
                        <img width="230px" :src="detail.cover" alt="">
                        <div class="h4 my-3" >
                            {{detail.dura_alias}}
                        </div>
                        <div class="text-overflow-1">
                            {{detail.name}}
                        </div>
                    </div>


                <div  class="content-bottom-centerVs " style="font-size: 80px">
                    <div class="content-bottom-centerVs-ripple rounded-pill position-relative">
                        <div class="content-bottom-centerVs-ripple-title rounded-pill d-flex justify-content-center align-items-center">
                            <div>{{successRate}}%</div>
                        </div>
                        <div class="content-bottom-centerVs-ripple-bg position-absolute"
                             :style="`top:${80 - successRate }%;background: url(${require('@/assets/img/commone/ripple.png')})`">
                        </div>
                    </div>
                </div>


                <div class="content-bottom-right  text-center">
                        <img width="230px" :src="require('@/assets/img/random_goods.png')" alt="">
                        <div class="h4 my-3" >
                            随机饰品
                        </div>
                        <div class="text-overflow-1">
                            随机奖励任意饰品
                        </div>
                    </div>



            </div>
        </div>
        <!-- 进度条 -->
        <div class="content-top d-flex justify-content-center align-items-center">
            <div class="content-top-left d-md-block d-none">
<!--                <el-progress class="content-top-left-progress" :stroke-width="10" type="circle"-->
<!--                             :percentage="successRate"  :color="'#f8b911'"></el-progress>-->
            <div class="content-top-left-rate">
                {{successRate}}%
            </div>
            </div>
            <div class="content-top-center">
                <div class="text-center content-top-center-title">您有<span class="h3 color-yellow"> {{successRate}}% </span>的机会获得饰品，并追加额外获得一个随机饰品。</div>
                <el-slider class="content-top-center-slider" :min="5" :max="75"  v-model="successRate "></el-slider>
                <img :src="require('@/assets/img/slider-bottom.png')" alt="">
                <div class="d-flex justify-content-between content-top-center-yesNo" style="margin-top: 30px;">
                    <div>成功</div>
                    <div>

                    </div>
                    <div>失败</div>
                </div>
            </div>
            <div class="content-top-right d-md-block d-none">
<!--                <el-progress class="content-top-right-progress" :stroke-width="10" type="circle"-->
<!--                             :percentage="100 - successRate"></el-progress>-->
                <div class="content-top-right-rate">{{100 - successRate}}%</div>
            </div>
        </div>
        <!-- 开启按钮 -->

        <div class="d-flex flex-column justify-content-center align-items-center content-bottom-center">
          <div class="mb-3">
            <money/>
            {{money}}
          </div>
          <div class=" d-flex
            justify-content-center align-items-center cursor-pointer bg-danger rounded"
              style="width: 120px;line-height: 50px"
               @click="openBox">
            <div >

              <span class="ml-2">开始</span>
            </div>
          </div>
        </div>

      <div v-show="isShow" :class="isShow?'animate__bounceInUp':''"
           class="overflow-hidden  mx-auto mt-5 zhishi animate__animated"
           :style="`background:url(${require('@/assets/img/commone/animate_open.png')});background-size: 100%;`"
           style="width: 1100px;background-repeat: no-repeat;background-size: 100%">
        <draw-lottery
          :multi-draw-data="multiDrawData"
          :draw-x-y="false"
          ref="helloworld"
          :win-prize-index="'-8800'"
          :tween-max-init="{ delay: 0 }"
          :res-data="resData"
          :winIndex="3"
          :get-complete="getComplete"
        />
      </div>

        <!-- 中将弹窗 -->
        <div v-show="openShow">
            <div  style=""
                  class="awardListPop position-fixed pop-up z-index-100 py-5 px-4" >
                <div>
                    <div class="awardListPop-box d-flex justify-content-center pt-5 flex-wrap ">
                        <div style="height:fit-content"
                             :class="'pic-bg-border-'+ Math.floor((Math.random()*4)+1)"
                             class="text-center award-box ">
                            <img width="80%" :src="drawDetail.cover" alt="">
                            <div class="my-1 text-overflow-1 ">{{drawDetail.name}}</div>
                            <div class="d-flex justify-content-between" style="font-size: 12px">
                                <div class="border-0 text-center w-50 p-2"
                                     :class="'pic-bg-border-'+ drawDetail.dura">
                                    <money class="money-phone"/>{{drawDetail.bean}}
                                </div>
                                <div class="border-0 text-center w-50 p-2
                                cursor-pointer" :class="'pic-bg-border-'+ drawDetail.dura">
                                    {{drawDetail.dura_alias}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex px-md-5 justify-content-center mt-2">
                        <div @click="openShowOff" >
                            <bottom-bg >
                                <div style="color: #FFFFFF;font-size: 12px">
                                    收下饰品
                                </div>
                            </bottom-bg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
    </div>
</template>

<script>
    // import ParticleEffectButton from "vue-particle-effect-buttons"
/*luckyBoxOpen.svga*/
    import $api from '@/api/lucky/index'
    import bottomBg from "@/components/bottomBg";
    export default {
        name: "active",
        components:{bottomBg,
            // ParticleEffectButton
        },
        data() {
            return {
                btnOps: {
                    type: "triangle",
                    easing: "easeOutQuart",
                    size: 6,
                    particlesAmountCoefficient: 4,
                    oscillationCoefficient: 2,
                    color: function () {
                        return Math.random() < 0.5 ? "#f4c401" : "rgba(0,0,0,0)";
                    },
                    onComplete: () => {
                        console.log("complete");
                    },
                    onBegin: () => {
                        console.log("begin");
                    },
                    visible: true,
                    animating: false
                },
                // 开箱率
                successRate:5,
                // 详情ID
                id:'',
                // 详情
                detail:{},
                // 弹窗的中将信息
                drawDetail:{},
                // 是否显示弹窗
                openShow:true,
                // 抽奖
                isShow:false,
                // 阻止点击
                zuzhidianji:false,
                multiDrawData:[
                    {
                        drawData: [],
                        drawDataSuccess: {}

                    }
                ],
                resData: {
                    WH: {
                        width: "220",
                        height: "150",

                    },
                    IdBox: ["wi", "id_box",'fontSize'],
                    classBox: ["fontSize", ""],
                    classImg: ["imgwh"],
                },
                // 关闭定时器
                timeplay:false,
            }
        },
        computed:{
            money(){
                return ((this.successRate/100)*(this.detail.bean*1)).toFixed(2)
            },
            successRateNum(){
                return this.successRate
            }
        },
        created() {
          this.timeplay = false
            this.id = this.$route.params.id
            this.init()
            },
        mounted() {
        this.init()
            },
        beforeDestroy(){
          this.timeplay = true
        },
        destroyed () {
        this.timeplay = true
      },
        methods:{
            svgaInit(){
            },
            async init(){
                await this.getluckydetail()
            },
            // 动画结束
            getComplete(){
              if(!this.timeplay){
                document.getElementById('succeed').play()
              }

              this.$refs.helloworld.play.restart(true);
              this.$refs.helloworld.play.pause()
              this.zuzhidianji = false
              this.openShow = true
            },
            async getluckydetail(){
                try {
                    let {data,code} = await $api.getluckydetail(this.id)
                    if(code == 500)return
                    this.detail = data
                    let drawData = {
                        src:this.detail.cover,
                        name:this.detail.name,
                    }
                    let arr = []
                    for (let i = 0;i<10;i++){
                        arr.push({
                            src:require('@/assets/img/random_goods.png'),
                            name:'随机饰品',
                        })
                        arr.unshift(drawData)
                    }
                    this.multiDrawData[0].drawData.push(...arr)
                    console.log(arr)
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            // 点击打开
            async openBox(){
                try {
                    let {data,code} = await $api.getluckyOpen({id:this.id,percent:(this.successRate/100).toFixed(2)})
                    if(code == 500)return
                    this.btnOps.visible = false
                    window.scrollTo(0,1000);
                    if(this.zuzhidianji){return}
                    this.zuzhidianji = true
                    this.isShow = true
                    this.$refs.helloworld.playClick()
                    if(this.detail.id == data.skin_id){
                        this.multiDrawData[0].drawDataSuccess = {
                            src:data.cover,
                            name: data.name,
                        }
                    }else {
                        this.multiDrawData[0].drawDataSuccess = {
                            src:require('@/assets/img/random_goods.png'),
                            name:'随机饰品',
                        }
                    }
                    this.drawDetail = data
                    this.$store.dispatch('userInfo')
                }catch (e) {
                    console.log(e)
                    return
                }
            },
            openShowOff(){
                this.btnOps.visible = true
                this.openShow = false
                this.isShow = false
            },
        }
    }
</script>

<style lang="scss" scoped>
    .award-box{
        box-shadow: 0px 0px 7px 2px rgba(0,0,0,.5);
        width: 30%;
        margin: 10px 0.5%;
        @media (max-width: 1200px) {
            width: 48%;
            margin: 10px 0.5%;
        }
    }
    .awardListPop{
        width: 700px;left: calc(50% - 350px);top:25vh;
        z-index: 1002;position: relative;
        @media (max-width:1200px) {
            width: 98%;
            left: 1%;
        }
        &-box{

        }
    }
    .zhishi{
        padding: 30px 0;
        /*box-shadow: inset 0 0 10px #000;*/
        position: relative;
        background-size: 100% 100% !important;
        background-repeat: no-repeat!important;
        clip-path: circle(65%);
        &::before{
            content: "";
            width: 340px;
            height: 15px;
            /*background-image: url("../../assets/img/openIndex/zhishi.png");*/
            background-repeat: no-repeat;
            position: absolute;
            left: calc(50% - 550px);
            bottom: 0;

        }
        @media (max-width:1200px) {
            padding: 0;
            left: calc(50% - 550px);
         }
      @media (max-width:960px) {
        padding: 0;
      }
    }
    .imgwh{
        width: 100%;
    }
    #luckyBoxOpen{

    }
    .lucky-box{
        width: 500px;
        padding: 20px 10px;
        top: calc(50% - 300px);
        left: calc(50% - 250px);
        z-index: 99;
        background-color: rgba(25,34,45,.8);
    }
    .content{
        width: 90%;
        padding: 20px;
        @media (max-width:1366px){
            width: 100%;
        }
        margin: 0 auto;
        &-top{
            width: 80%;
            height: 300px;
            background-size: 100%;
            position: relative;
            margin: 0 auto;
            @media (max-width:1366px){
                width: 70%;
                background-repeat: no-repeat;
            }
            &-left{
                width: 100px;
                height: 100px;
                position: relative;
                background-image: url("../../assets/img/lucky/left.png");
                background-size: 100% 100%;
                &-rate{
                    position: absolute;
                    left: 33%;
                    top: 42%;
                    background-color: rgba(220,53,69,.2);
                box-shadow: 0 0 32px 3px #dc3545;
            }
                margin-right: 50px;
            }
            &-center{
                width: 500px;
                @media (max-width:1366px){
                    position: absolute;
                    width: 100%;
                    top: 250px;
                    & img{
                        display: none;
                    }
                }
                & img{
                    width: 500px;
                }
                &-title{
                    font-size: 18px;
                    margin-bottom: 30px;
                    @media (max-width:1366px){
                        font-size: 14px;
                    }
                }
                &-yesNo{
                    font-size: 40px;
                    & div:nth-child(1){
                        color: var(--main-yellow);
                    }
                    & div:nth-child(2){
                        color: var(--main-dark-brown);
                    }
                }
            }
            &-right{
                width: 100px;
                height: 100px;
                position: relative;
                background-image: url("../../assets/img/lucky/right.png");
                background-size: 100% 100%;
                &-rate {
                    position: absolute;
                    left: 33%;
                    top: 42%;
                    background-color: rgba(60, 60, 60, .2);
                    box-shadow: 0 0 32px 3px #3c3c3c;
                }

                margin-left: 50px;

            }
         }
        &-bottom{
            width: 80%;
            height: 300px;
            padding: 0 50px;
            margin: 50px auto 0 auto;
            background-size: 100%;
            background-repeat: no-repeat;
            @media (max-width:1366px){
                width: 95%;
                display: block !important;
                margin: 30px auto 0 auto;
            }
            &-center{
                font-size: 18px;
                width: 150px;
                height: 50px;
                margin: 0 auto;
              @media (max-width:1366px){
                font-size: 16px!important;
                width: 150px;
                height: 50px;
                margin: 150px auto 0 auto;
              }
                @media (max-width:1200px){
                    font-size: 16px!important;
                    width: 150px;
                    height: 50px;
                    margin: 150px auto 0 auto;
                }
            }
            &-left{
                width: 400px;
                padding-bottom: 20px;
                background-image: url("../../assets/img/lucky/left_back.png");
                & img{
                    animation:topBotton 3s infinite alternate;
                    animation-timing-function: ease;
                }
                background-size: 100% 100%;
                @media (max-width:1366px){
                    display: block !important;
                    margin: 0 auto;
                    background-image: url("../../assets/img/commone/right_bottom.png");
                }
                @media (max-width:960px){
                    width: 100%;
                }
            }
            &-centerVs{
                overflow: hidden;
                @media (max-width:1366px){
                }
                &-ripple{
                    width: 150px;
                    height: 150px;
                    border: 4px solid #e9b10e;
                    clip-path: circle(50%);
                    &-title{
                        border: 4px solid #e9b10e;
                        font-size: 2.2rem;
                        width: 150px;
                        height: 150px;
                        position: absolute;
                        left: -4px;
                        top: -4px;
                    }
                    &-bg{
                        z-index: -1;
                        width: 100000px;
                        height: 500px;
                        background-repeat: revert;
                        animation:ripple 500s linear;
                        animation-iteration-count:infinite
                    }
                    @media (max-width:1366px){
                        margin: 0 auto;
                       display: flex;
                        justify-content: center;
                        &-bg{
                            left: 0;
                        }
                    }
                }
            }
            &-right{
                width: 400px;
                padding-bottom: 20px;
                /*height: 300px;*/
                background-image: url("../../assets/img/lucky/right_back.png");
                & img{
                    animation:topBotton 3s infinite alternate;
                    animation-timing-function: ease;
                }
                background-size: 100% 100%;
                @media (max-width:1366px){
                    display: none;

                }
            }
        }
    }
    @keyframes topBotton {
        0%{
            transform:translateY(0px)
        }
        100%{
            transform:translateY(-50px)
        }
    }
    @keyframes ripple {
        0%{
            transform:translateX(0)
        }
        100%{
            transform:translateX(-100000px)
        }
    }

</style>
