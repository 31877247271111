

import {request} from "../index";
function getRoomsList(page,status,type){
    return request({
        url: `/rooms?page=${page}&status=${status}&type=${type}`,
        method:'get'
    })
}

function getUserRooms(page){
    return request({
        url: `/user/rooms?page=${page}`,
        method:'get'
    })
}

function getRoomsJoin(data){
    return request({
        url: `/room/join`,
        method:'post',
        data
    })
}
function getRoomsDetail(id){
    return request({
        url: `/room/detail?id=${id}`,
        method:'get'
    })
}
function getRoomsSave(data){
    return request({
        url: `/room/save`,
        method:'post',
        data
    })
}
function getStorage(page){
    return request({
        url: `/storage?page=${page}`,
        method:'get',

    })
}


function getUserRoomStatus(id){
    return request({
        url: `room/user_room_status?room_id=${id}`,
        method:'get',
    })
}

function getAwardsUser(id,page,pagesize){
    return request({
        url:`/room/detail_awards_user?room_id=${id}&page=${page}&pagesize=${pagesize}`,
        method:'get'
    })
} 

function getRoomsUsers(id,page,pagesize){
    return request({
        url:`/room/detail_users?room_id=${id}&page=${page}&pagesize=${pagesize}`,
        method:'get'
    })
} 
function getRoomsAwards(id,page,pagesize){
    return request({
        url:`/room/detail_Awards?room_id=${id}&page=${page}&pagesize=${pagesize}`,
        method:'get'
    })
}

export default {
    getRoomsList,
    getRoomsJoin,
    getRoomsDetail,
    getRoomsSave,
    getStorage,
    getUserRooms,

    getRoomsAwards,
    getRoomsUsers,
    getAwardsUser,
    getUserRoomStatus
}
