<template>
  <div>
    <div v-if="0" class="taskcenter-inform">
      <img style="width: 50px;padding: 0 15px;" src="../../assets/img/ADT/event-hall/laba.png" alt="">
      <div style="width:100%">
        <el-carousel height="30px" :interval="2000" direction="vertical" indicator-position="none">
          <el-carousel-item v-for="(item, index) in inform" :key="index">
            <p class="medium">
              {{ item.timeTips }}
              <span class="text-orge">{{ item.name }}</span>
              完成
              <span class="text-orge">{{ item.task }}</span>
              获得
              <span class="text-orge">{{ item.award }}</span>
              !
            </p>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- <div class="item-title-wrap">
        <div @click="selectsign(item, index)" :class="active==index?'active':''" v-for="(item,index) in lists" :key="index">{{item.title}}</div>
    </div>
     -->
    <div class="content">
      <div class="left">
        <div class="left1" :class="type_menu == 1 && 'active'" @click="type_menu = 1">
          <img v-if="type_menu == 1" src="@/assets/img/common/sign.png" alt="">
          <img v-else src="@/assets/img/common/sign0.png" alt="">
          每日签到
        </div>
        <div class="left2" :class="type_menu == 2 && 'active'" @click="type_menu = 2">
          <img v-if="type_menu == 2" src="@/assets/img/common/redbag.png" alt="">
          <img v-else src="@/assets/img/common/redbag.png" alt="">
          福利红包
        </div>
        <div class="left3" :class="type_menu == 3 && 'active'" @click="type_menu = 3">
          <img v-if="type_menu == 3" src="@/assets/img/common/promotion_hover.png" alt="">
          <img v-else src="@/assets/img/common/promotion.png" alt="">
          拉新福利
        </div>
      </div>
      <div class="right" v-show="type_menu == 1">
        <!-- <div class="item-day-wrap">
          <div v-for="(num) in dayNum" :key="num" class="item-day" :class="signDays+1 > num  ? (signDays == num ? 'active today' : 'active') : ''">
            <span class="item-mark"></span>
          </div>
        </div> -->
        <!-- :class="signDays+1 > num  ? (signDays == num ? 'active tobox' : 'active') : ''" -->

        <div class="qd">
          <div class="left">
            <span>登录签到</span>
            <span></span>
            <span>已签到
                  <span>{{ lists[0].days }}</span>天
                </span>
          </div>
          <div class="right" v-if="lists[0].can_sign == 0 ||lists[0].can_sign == 1">
            <a href="#" @click="getTask(0)">签到</a>
          </div>
          <div class="right" v-if="lists[0].can_sign == 2">
            已签到
          </div>
          <div class="item-btn" v-if="lists[0].is_receive" @click="open(6)">领取</div>
        </div>

        <div class="item-box-wrap">
          <div v-for="(num) in dayNum" :key="num"
               :class="['item-box',(signDays+1 > num||signDays+1 == num)?'yiActive':'noActive']">
                  <span v-if="signDays+1 > num" class="active signed">
                    <img src="@/assets/img/common/sign_3.png" width="20"/>
                  </span>
            <span v-if="signDays+1 == num" class="active" :class="lists[0].can_sign == 2 ? 'signed':'sign'">
                    <img v-if="lists[0].can_sign == 2" src="@/assets/img/common/sign_3.png" width="20"/>
                    <span v-if="lists[0].can_sign != 2">
                      第 <span class="numberDay">{{ numberDay[num - 1] }}</span> 天</span>
                  </span>
            <span v-if="signDays+1 < num" class="">
                    第 <span class="numberDay">{{ numberDay[num - 1] }}</span> 天
                  </span>
          </div>
        </div>


        <p class="qdInfo">累计7天登录签到，第七天最高可获得33.33U币。连续七天签到 断签重新归零签到</p>

        <div class="qd incomef">
          <div class="left">
            <span>充值签到</span>
            <span></span>
            <span>已签到<span>{{ lists[1].days }}</span>天</span>
          </div>
          <div class="right" v-if="lists[1].can_sign == 0 ||lists[1].can_sign == 1">
            <a href="#" @click="getTask(1)">签到</a>
          </div>
          <div class="right" v-else-if="lists[1].can_sign == 2">
            已完成
          </div>
          <div class="item-btn" v-if="lists[1].is_receive" @click="open(1)">领取</div>
        </div>

        <div class="item-box-wrap">
          <div v-for="(num) in dayNum" :key="num"
               :class="['item-box',(signDaysOne+1 > num||signDaysOne+1 == num)?'yiActive':'noActive' ]">
                  <span v-if="signDaysOne+1 > num" class="active signed">
                    <img src="@/assets/img/common/sign_3.png" width="20"/>
                  </span>
            <span v-if="signDaysOne+1 == num" class="active" :class="lists[1].can_sign == 2 ? 'signed':'sign'">
                    <img v-if="lists[1].can_sign == 2" src="@/assets/img/common/sign_3.png" width="20"/>
                    <span v-if="lists[1].can_sign != 2">
                       第<span class="numberDay">{{ numberDay[num - 1] }}</span>
                       天
                    </span>
                  </span>
            <span v-if="signDaysOne+1 < num" class="">
                    第<span class="numberDay">{{ numberDay[num - 1] }} </span> 天
                  </span>
          </div>
        </div>


        <p class="qdInfo">累计7天充值签到，第七天最高可获得88.88U币。连续七天签到 断签重新归零签到</p>
      </div>

      <div class="right_2" v-show="type_menu == 2">
        <div class="cdk_1">
          <div>CDK兑换</div>
          <span>官方交流群：619342798，抢更多福利!</span></div>
        <div class="cdk_2">
          <input type="text" v-model=" cdk " placeholder="请输入CDK口令">
          <button @click="dhcdkHandle"></button>
        </div>

        <div class="list">
          <div v-show="item.status == 1" class="list_item" v-for="(item, index) in redList" :key="index">
            <div class="title">
              <span class="title1">{{ item.title }}</span>
              <span class="title2">总数量：{{ item.num }}</span>
            </div>
            <div class="con">
              <div class="con_1">
                <div class="con_1_1">
                  <img src="@/assets/img/common/bag_list.png" alt="">
                </div>
                <div class="con_1_2">
                  <div>参与条件：<span class="income">{{ item.briefe }}</span></div>
                  <div class="end_time">结束时间：{{ item.end_time }}</div>
                </div>
              </div>
              <div class="con_2">
                <img v-if="item.remainder == 1" @click="UserRedOpen(1, item)" src="@/assets/img/common/lq.png" alt="">
                <img v-if="item.remainder == 0" src="@/assets/img/common/yl.png" alt="">
                <span v-if="item.remainder == 0" class="yl_txt"> 已领 </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_3" v-show="type_menu == 3">
        <user-promotion-show></user-promotion-show>
        <user-promotion-reward ref="reward"></user-promotion-reward>
      </div>

    </div>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth';
import $api from '@/api/compound'
import $api_vd from "@/api/vd/index";
import {mapState} from 'vuex'
import UserPromotionReward from "@/components/user-promotion-reward.vue";
import UserPromotionShow from "@/components/user-promotion-show.vue";

export default {
  name: "redPacket",
  props: ['type'],
  computed: {...mapState(['user_info']),},
  data() {
    return {
      numberDay: ['1', '2', '3', '4', '5', '6', '7'],
      dayNum: 7,
      lists: [{
        title: '登录签到'
      },
        {
          title: '充值签到'
        }],
      active: 0,
      cdk: undefined,
      type_menu: 1,
      redList: [],
      signDays: 0,
      signDaysOne: 0,
      isShow: false,
      TaskTitle: true,
      // signDays: {
      //   days: -1,
      //   is_receive: 0
      // },// 已连续签到天数 7天可领取奖励
      todayBoxBean: {},// 当日开箱消耗金币数量 50金币可领取奖励
      todayGameBean: {},// 当日对战消耗金币数量 50金币可领取奖励
      weekBoxTimes: {},// 当周开箱次数 88次可领取奖励
      weekGameTimes: {},// 当周对战次数 88次可领取奖励
      is_can: 0,
      inform: [
        {
          timeTips: '12分钟之前',   //时间
          name: "小李**",   //人名
          task: '对战消耗任务',   //目标
          award: '保底20任务目标'   //奖励
        },
        {timeTips: '2分钟之前', name: "猪猪**", task: '盲盒消耗任务', award: '保底120任务目标'},
        {timeTips: '32分钟之前', name: "不知名**", task: '火海', award: '保底120任务目标'},
      ],
    }
  },
  components: {UserPromotionReward, UserPromotionShow},
  created() {
  },
  mounted() {
    this.active = this.type;
    this.init();
  },
  watch: {
    'type': {
      handler(type) {
        this.active = type;
        this.selectsign(this.lists[0]) //首次展示
        this.selectsignOne(this.lists[1]) //首次展示
      }
    },
    type_menu(v) {
      if (v == 2) {
        this.getUserRed()
      }
      if (v == 3) {
        this.$refs.reward.getPromote()
      }
    }
  },
  beforeDestroy() {

  },
  methods: {
    getUserRed() {
      $api.UserRed().then(res => {
        this.redList = res.data
      })
    },
    UserRedOpen(sum, item) {
      let redOpen = {};
      if (sum == 1) {
        redOpen = {
          type: sum,
          id: item.id,
        };
      }
      $api.UserRedOpen(redOpen).then(res => {
        if (res.code === 200) {
          this.$message.success('恭喜您领取到了' + res.data + 'U币');
          this.$store.dispatch('userInfo');
          this.getUserRed()
        }
      })
    },
    // cdk兑换
    async dhcdkHandle() {
      this.$notify.closeAll();
      if (!this.cdk) {
        this.$notify({
          type: 'error',
          message: '请输入口令',
          position: 'top-left'
        });
        return
      }
      let params = {
        type: 2,
        key: this.cdk,
      }
      try {
        let res = await $api_vd.getOpenRebBack(params);
        if (res.code === 200) {
          this.$message.success('恭喜您领取到了' + res.data + 'U币');
          this.$store.dispatch("userInfo");
        }

      } catch (e) {
        console.log(e);
      }
    },
    isCDKSuccessPopUp() {
      this.isSuccessCDKpopUp = false;
    },

    // let type = 1
    //   if(this.active == 1){
    //     type = 6//充值签到
    //   }
    open(type) {
      if (type == 6) {
        $api.signUpLoginS().then(res => {
          if (res.code === 200) {
            this.$message.success('恭喜您领取到了' + res.data.amount + 'U币');
            // this.muVal=res.data.amount
            // this.dialogVisible=true
            this.$store.dispatch('userInfo');
            this.init();
          }
        })
      } else if (type == 1) {
        $api.signIn({type: 1}).then(res => {
          if (res.code === 200) {
            this.$message.success('恭喜您领取到了' + res.data.amount + 'U币');
            // this.muVal=res.data.amount
            // this.dialogVisible=true
            this.$store.dispatch('userInfo');
            this.init();
          }
        })
      }
    },
    // 初始化
    init() {
      if (!getToken()) {
        return false;
      }
      $api.taskReceive().then(({data}) => {
        if (data) {
          // is_receive 0不可领 1可领取(领奖励)
          // can_sign 0不可签 1可签到 2已签到（签到）
          // days 天数
          const {signDaysLogin, signDays} = data
          this.lists = JSON.parse(JSON.stringify([{...this.lists[0], ...signDaysLogin}, {...this.lists[1], ...signDays}]))
          this.selectsign(this.lists[0]) //首次展示
          this.selectsignOne(this.lists[1]) //首次展示

          // let login_is_receive = signDaysLogin.is_receive;
          // const {is_receive} = signDays

          // 自动领取 例如签到按钮点击以后
          // if(login_is_receive){
          //   this.open(6);
          // }
          // if(is_receive){
          //   this.open(1);
          // }

        }
      })
    },
    selectsign(data) {

      if (data.can_sign == 2) {
        //已签到的要往前推一天
        this.signDays = data.days - 1
      } else {
        this.signDays = data.days
      }
    },
    selectsignOne(data) {

      if (data.can_sign == 2) {
        //已签到的要往前推一天
        this.signDaysOne = data.days - 1
      } else {
        this.signDaysOne = data.days
      }
    },

    // 点击签到
    getTask(type) {
      console.log("type-----------", type);

      console.log("$api---------", $api);
      const {lists} = this;
      const {can_sign} = lists[type];
      if (can_sign == 0 && type == 1) {
        // this.$router.push('/user/recharge');
        this.$message.error('请先进行充值才可签到')
        return false;
      }

      if (type == 0) {
        $api.signUpLogin().then(res => {
          if (res.code == 200) {
            this.init()
            this.$message.success(res.message)
          }
        })
      } else {
        $api.signUp().then(res => {
          if (res.code == 200) {
            this.init()
            this.$message.success(res.message)
          }
        })
      }

    },
  },
}
</script>

<style scoped lang="scss">
.taskcenter-inform {
  margin-top: 10px;
  width: 100%;
  height: 35px;
  background-color: rgb(0, 0, 0, 0.3);
  border-radius: 6px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 18px;

  .medium {
    width: 100%;
    color: #fff;
    font-size: 14px;;
    line-height: 30px !important;
  }
}

.incomef {
  margin-top: 50px;
  font-size: 12px;
  line-height: 15px;
}

.qd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: solid 1px #2F445E;
  > .left {
    > span:first-child {
      color: #E6C40E;
      font-size: 16px;
    }

    > span:nth-child(2) {
      width: 1px;
      height: 12px;
      margin: 0 10px;
      background-color: #7D7D7D;
      display: inline-block;
    }

    > span:last-child {
      > span {
        // font-size:20px;
        color: #F9C545;
        padding: 0 6px;
        font-weight: bold;
      }
    }
  }

  > .right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 32px;
    color: #45638F;
    background: rgba(249, 197, 69, 0.1);
    border-radius: 16px;

    color: #B28E36;

    font-size: 14px;

    > img {
      width: 70px;
      cursor: pointer;
    }

    > a {
      width: 100%;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F9C545;
      color: #000;
      border-radius: 16px;
    }
  }
}

.qdInfo {
  font-size: 12px;
  color: #999;
}

.item-day-wrap {
  margin-top: 10px;
  display: flex;
  border: 1px solid var(--pan-main);
  border-radius: 10px;

  .numberDay {
    font-weight: bold;
  }

  .item-day {
    width: calc(16.66% - 6.66px);
    height: 10px;
    // background-color: var(--pan-main);
    position: relative;

    &:first-child {
      width: 20px;
      border-radius: 10px 0 0 10px;
    }

    // &:last-child{width: 20px;}
    .item-mark {
      position: absolute;
      display: block;
      width: 20px;
      height: 40px;
      // background: url(../imgs/d3.png) no-repeat top;
      background-size: 20px auto;
      top: -7.6px;
      right: -10px;
      z-index: 9;
    }

    &.active {
      background-color: var(--pan-main);

      .item-mark {
        //   background-image: url(../imgs/d2.png);
      }
    }

    &.today {

      .item-mark {
        //   background-image: url(../imgs/d1.png);
      }
    }
  }
}

.item-title-wrap {
  display: flex;

  div {
    flex: 1;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    margin-bottom: 15px;
  }

  .active {
    background-color: var(--main-blue);
  }
}

.item-box-wrap {
  padding-top: 15px;
  display: flex;
  margin-bottom: 20px;

  .item-box {
    flex: 1;
    margin: 0 2px;
    height: 66px;
    text-align: center;
    font-size: 12px;
    background: #007D8F;
    clip-path: polygon(0 10px, 0 100%, calc(100% - 12px) 100%, 100% calc(100% - 10px), 100% 0, 12px 0);
    @media (max-width: 600px) {
      height: 44px;
      clip-path: polygon(0 5px, 0 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 0, 5px 0);
    }

    > span {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
    }

    span {
      // background: url(../imgs/red3.png) no-repeat top;
      background-size: 103%;
      background-position: center;
      background-repeat: no-repeat;

      &.active {
        background-image: url(../../assets/img/common/sign_1.png);
        @media (max-width: 600px) {
          background-image: url(../../assets/img/common/sign_1_p.png);
        }
      }

      &.tobox {
        // background-image: url(../imgs/red2.png);
      }

      &.sign {
        background-image: url(../../assets/img/common/sign_2.png);
        @media (max-width: 600px) {
          background-image: url(../../assets/img/common/sign_2_p.png);
        }
      }
    }

    .numberDay {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .yiActive {
    background-image: none;
    background-color: #1C3257;
  }

  .noActive {
    color: #fff;
  }

  .tobox {
    background-image: linear-gradient(0deg, #764F3E, #FCEE94);
  }
}

.item-btn {
  margin: 20px auto;
  width: 100px;
  height: 30px;
  // background: url(../imgs/btn.png) no-repeat top;
  background-size: contain;
  text-align: center;
  line-height: 30px;
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
}

.TaskIn {
  &-conV2 {
    &-list {
      @media (max-width: 768px) {
        width: 100%;
        justify-content: space-around;
      }
    }

    &-item {

      &-yuan {
        width: 30px;
        height: 30px;
        background-color: #2d2c33;

        & i {
          opacity: 0;
        }
      }

      &-xian {
        width: 60px;
        height: 6px;
        background-color: #2d2c33;

        @media (max-width: 768px) {
          display: none;
        }
      }

      & .active {
        transition: all .5s;
        background-color: var(--pan-main);

        & i {
          opacity: 1;
        }
      }

    }
  }
}

@keyframes GY {
  to {
    background-color: var(--pan-main)
  }

  from {
  }
}

.TaskIn {
  width: 60%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &-con {

    &-bottom {
      @media (max-width: 1000px) {
        font-size: 12px;
      }

      &-yuan {
        width: 16px;
        height: 16px;
        border: 3px solid #b6dcff;
        border-radius: 50%;
        position: relative;
        margin: 15px;

        @media (max-width: 1000px) {
          width: 14px;
          height: 14px;
        }

        &::before {
          content: '';
          border-radius: 50%;
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: #b6dcff;
          left: calc(50% - 4px);
          top: calc(50% - 4px);
        }

        & span {
          content: '';
          width: 50px;
          height: 6px;
          background-color: #b6dcff;
          position: absolute;
          right: 300%;
          top: calc(50% - 4px);

          @media (max-width: 1000px) {
            display: none;
          }
        }
      }

      & .active {
        border: 3px solid red !important;

        &::before {
          content: '';
          border-radius: 50%;
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: red !important;
          left: calc(50% - 4px);
          top: calc(50% - 4px);
        }

        & span {
          content: '';
          width: 50px;
          height: 6px;
          background-color: red;
          position: absolute;
          right: 300%;
          top: calc(50% - 4px);

          @media (max-width: 1000px) {
            display: none;
          }
        }
      }

      &-win {
        padding: 10px;
        color: #ffffff;
        font-size: 14px;
        border-radius: 10px;
      }


    }
  }

  &-btn {
    width: 50%;
    padding: 20px;
    background-color: var(--pan-main-15);
    border: 1px solid var(--pan-main);
    border-radius: 5px;
    font-size: 22px;
  }

  &-renwu {
    border-radius: 10px;

    @media (max-width: 1000px) {
      margin-bottom: 20px;
    }
  }

  &-bottom-box {
    @media (max-width: 1000px) {
      height: 250px;
      overflow-y: scroll;
    }
  }
}

.content {
  display: flex;
  margin-top: 20px;
  @media (max-width: 600px) {
    display: block;
    padding: 0 10px 40px 10px;
  }

  > .left {
    width: 100px;
    border-right: 1px solid #1B3B53;
    margin-right: 30px;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0;
      border-right: none;
      border-bottom: 1px solid #1B3B53;
      display: flex;
      justify-content: flex-start;
      gap: 0;
      margin-bottom: 20px;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;
      gap: 5px;
      font-size: 14px;
      color: #00B9D4;
      transition-duration: .6s;

      &.active {
        color: #F9C545;
      }

      @media (max-width: 600px) {
        margin-bottom: 10px;
        width: 80px;
        &.active {
          color: #F9C545;

          padding: 5px 0;
          box-sizing: border-box;
          position: relative;

          &::after {
            content: '';
            width: 100%;
            border-bottom: 2px solid #F9C545;
            border-radius: 2px;
            position: absolute;
            bottom: -10px;
          }
        }
      }
    }

    img {
      width: 35px;
    }
  }

  > .right {
    flex: 1;
  }
}

.right_2 {
  width: 100%;
  flex: 1;

  .cdk_1 {
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    gap: 20px;
    color: #fff;

    @media (max-width: 600px) {
      background: #007D8F;
      padding: 15px 10px 10px 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 13px;
      align-items: flex-start;
    }

    > div {
      white-space: nowrap;
    }

    > span {
      font-size: 13px;
      display: inline-block;
      color: #D1D1D1;
      font-weight: 400;
      font-family: Microsoft YaHei;
      @media (max-width: 600px) {
        font-size: 13px;
      }
    }
  }

  .cdk_2 {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    @media (max-width: 600px) {
      background: #007D8F;
      padding: 0 10px 10px 10px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 16px;
    }

    input {
      padding-left: 10px;
      width: 400px;
      background: #16172B;
      height: 44px;
      color: #fff;
      @media (max-width: 600px) {
        height: 35px;
        border-radius: 5px;
        font-size: 13px;
      }
    }

    button {
      background: url(../../assets/img/common/dh.png) no-repeat;
      background-position: center;
      width: 94px;
      height: 44px;
      border: none;
      background-size: 100%;
      @media (max-width: 600px) {
        width: 132px;
      }
    }
  }

  .list {
    height: 300px;
    overflow: auto;
    position: relative;
    @media (max-width: 600px) {
      height: auto;
    }

    .list_item {
      margin-bottom: 10px;
      height: 150px;
      background-image: url(../../assets/img/common/list_bg.png);
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      @media (max-width: 600px) {
        background-image: url(../../assets/img/common/list_bg_p.png);
        height: 111px;
      }

      .title {
        display: flex;
        justify-content: center;
        gap: 55%;
        padding: 15px 0;
        color: #fff;

        .title2 {
          color: #ccc;
        }

        @media (max-width: 600px) {
          padding: 8px 0;
          gap: 10%;
          font-size: 13px;
        }
      }

      .con {
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 600px) {
          padding: 0 20px;
        }

        .con_1 {
          display: flex;
          justify-content: flex-start;
          padding: 20px 0;
          @media (max-width: 600px) {
            padding: 10px 0;
          }

          .con_1_1 {
            img {
              width: 60px;
              @media (max-width: 600px) {
                width: 45px;
              }
            }
          }

          .con_1_2 {
            @media (max-width: 600px) {
              font-size: 13px;
            }

            > div {
              margin: 8px 0 12px 10px;
              @media (max-width: 600px) {
                margin: 8px 0 5px 10px;
              }
            }

            > div:nth-child(1) {
              color: #fff;
            }

            .income {
              color: #EFC75E;
              @media (max-width: 600px) {
                font-size: 12px;
              }
            }

            .end_time {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #A9A9A9;
              @media (max-width: 600px) {
                font-size: 12px;
              }
            }
          }
        }

        .con_2 {
          display: flex;
          align-items: center;
          position: relative;

          img {
            height: 40px;
            cursor: pointer;
            @media (max-width: 600px) {
              height: 30px;
            }
          }

          .yl_txt {
            position: absolute;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.right_3 {
  //width: 100%;
  max-height: 490px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 600px) {
    height: auto;
    max-height: max-content;
  }
}


</style>
