<template>
  <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
  <div
    class="weapon_jifen
      d-flex
      align-items-center
      flex-column
      bg-bg-2 bg-size-100 bg-position-center ggbg1
    " 
    style="width: 100%"
  >
    <div style="height: 80px">
      <img
        height="100%"
        class="position-relative z-index-1"
        :src="resData.cover"
        alt=""
      />
    </div>

    <div
      class="px-2 text-center align-items-center d-flex"
      style="line-height: 24px; font-size: 12px; height: 24px"
    >
      {{ resData.dura_alias }}
    </div>
    <div
      class="px-2 text-center border-top-0 text-overflow-1"
      style="line-height: 24px; font-size: 12px"
    >
      {{ resData.name }}
    </div>
    <div class="d-flex justify-content-center w-100 p-2 bg-bg">
      <div class=" flex" style="line-height: 30px;color:#65DFF4">
        <money :integral="false" />{{ resData.mall_integral }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "weapon",
  props: {
    // 传输武器整体宽度
    width: {
      type: String,
      default: "180px",
    },
    // 武器显示的高度，宽度和整体宽度是一样的
    heightWeapon: {
      type: String,
      default: "150px",
    },
    // 传输背景 style 对象形式传输
    bgSize: {
      type: Object,
      default() {
        return {
          height: "102px",
        };
      },
    },
    // 武器对应的宽度 高度 带单位 style 对象形式传输
    weaponSize: {
      type: Object,
      default() {
        return {
          width: "115px",
          marginTop: "25px",
        };
      },
    },
    //    传输的item
    resData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.weapon_jifen {
  border-radius: 12px;
  &-name {
    padding: 10px 0;
  }
  .bg-bg{
    border-top: 1px solid #181924;
    background: #202231 !important;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
</style>
