<template>
  <div class="d-flex">
    <div class="content mt-2">
      <div class="h3 text-white font-weight-bold text-center my-5">
        <div>历史记录</div>
      </div>


      <div class="content-open mt-5 position-relative history_bg">
        <div class="content-open-box">
          <div class="position-relative content-open-box-bg d-flex align-items-center  justify-content-center
                    mx-auto bg-size-100 bg-repeat">
            
            <img
              class="animate__animated content-open-center px-5 rounded-circle"
              :src="avatar"
              alt="" width="150px">
           

          </div>
        </div>
        <!-- 金额 -->
        <div class="my-3 text-center py-2 px-1 title_qian">{{name}}
        </div>
      </div>


      <!-- 开箱列表 -->
      <div class="mt-5">
          <div class="title_qian h5 font-weight-bold ">
              <div>最近掉落</div>
          </div>
          <div class="bg-secondary" style="height: 1px;width: 100%;margin-bottom:10px"></div>
      </div>
      <div class="d-flex flex-wrap">
        <div class="mb-3 list-box" style="" v-for="(item,index) in box_data" :key="index">
          <div style="width: 90%">
            <weapon :res-data="item"></weapon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import weapon from "./common/weapon";
  import $api from '@/api/history/index'
  import {mapState} from 'vuex'
  export default {
    name: "active",
    components:{
      weapon,
    },
    data(){
      return{
        avatar:'',
        name:'',
        // 宝箱ID
        user_id:'',
        // 宝箱数据
        box_data:[],
      }
    },
    computed:{
      ...mapState(['user_info','steam_handle','login_top']),
    },
    mounted() {
      this.init()

    },
    methods:{
      async init(){
        this.user_id = this.$route.params.id
        this.avatar = this.$route.query.avatar
        this.name = this.$route.query.name
        await this.getBoxDetail()
      },
      // 初始化获取数据
      async getBoxDetail(){
        try {
          let {data,code} = await $api.getHistory(this.user_id)
          console.log(data)
          if(code == 500)return
          this.box_data = data
        }catch (e) {
          console.log(e)
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .money-phone{
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .award-box{
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,.5);
    width: 31%;
    margin: 10px 0.5%;
    @media (max-width: 1200px) {
      width: 48%;
      margin: 10px 0.5%;
    }
  }
  .awardListPop{
    width: 700px;
    left: calc(50% - 350px);
    top:100px;
    z-index: 999;
    position: relative;
    @media (max-width:1200px) {
      width: 98%;
      left: 1%;
    }
    &-box{
      @media (max-width:1200px) {
        height: 500px;
        overflow: auto;
        &::-webkit-scrollbar {display:none}
      }
    }
  }
  .list-box{
    width: 14%;
    @media (max-width: 1600px) {
      width: 16%;
    }
    @media (max-width: 1366px) {
      width: 20%;
    }
    @media (max-width: 1200px) {
      width: 25%;
    }
    @media (max-width: 960px) {
      width: 50%;
    }
  }
  .scroll{
    & ::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
      display: none;
    }
  }
  .anZhuan{
    transition: all 7s;
    transform: rotate(360000deg)
  }
  .side-box {
    width: 200px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5)
  }
  .content{
    width:90%;
    margin: 0 auto;
    @media (max-width:1366px){
      width: 95%;
    }
    &-open{
      &-box{
        width: 80%;
        margin: 0 auto;
        &-bg{
        }
      }
      &-add{
        width: 860px;
      }
      &-left{
        @media (max-width: 1366px) {
          display: none;
        }
      }
      &-center{
        width: 180px;
      }
      &-right{
        @media (max-width: 1366px) {
          display: none;
        }
      }
      &-gun{
        @media (max-width: 1366px) {
          width: 30%;
        }
        @media (max-width: 960px) {
          width: 40%;
        }
        @media (max-width: 768px) {
          width: 50%;
        }
      }
      &-box2{
        @media (max-width: 1366px) {
          width: 30%;
        }
        @media (max-width: 960px) {
          width: 40%;
        }
        @media (max-width: 768px) {
          width: 50%;
        }
      }
    }
    &-add{

    }
  }
  .opentow-bg{
    width: 100vw;
    height: 150vh;
    position: fixed;
    background: #000000;
    opacity: .8;
    z-index: 3;
    top: 0;
  }
  .open-slot{
    & ::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
      display: none;
    }
    &-list{
      width: 1600px;
      height: 170px;
      left: calc(50% - 800px);
      position: relative;
      box-shadow: inset 0 0 10px #000;
      &:before{
        content: "";
        width: 340px;
        height: 15px;
        background-image: url("../../assets/img/openIndex/zhishi.png");
        background-repeat: no-repeat;
        position: absolute;
        left: calc(50% - 170px);
        bottom: 0;
        /*z-index: 99999;*/
      }
    }

  }
  .an-multi{
    z-index: 1000;
    &-list{
    }
  }
</style>
