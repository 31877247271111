<template>
  <div class="content">

    <div class="content-nav bg-size-100 bg-repeat  align-items-end">
      <!-- 武器分类 -->
      <div class="content-nav-list d-flex flex-wrap w-100">

        <!-- 全部 -->
        <div :class="chooseIndex == -1 ? 'active' : ''" class="content-nav-list-item text-center ml-md-2"
          @click="ChooseWeapons({ id: 0 }, -1)">
          <div class="py-2 d-flex align-items-center justify-content-center font-size-16-12">
            <!-- <div class="content-nav-list-icon mr-1"
                 :class="chooseIndex  == -1?'content-nav-list-icon-active':''">
            </div> -->
            <div> 全部</div>
          </div>
        </div>
        <div v-for="(item, index) in chooseNav" :key="index" :class="index == chooseIndex ? 'active' : ''"
          class="content-nav-list-item text-center ml-md-2" @click="ChooseWeapons(item, index)">
          <div class="py-2 d-flex align-items-center justify-content-center font-size-16-12">
            <!-- <div class="content-nav-list-icon mr-1"
                 :class="index == chooseIndex?'content-nav-list-icon-active':''">

            </div> -->
            <div class="list_type_name"> {{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 武器搜索 -->
      <div class="money d-md-flex d-block align-items-center" style="margin-top: 20px;">
        <div class="d-flex align-items-center">
          <div style="width: 85px;
height: 40px;
background: rgba(30,122,145,0.2);
border: 2px solid #175B6C;
border-radius: 4px;" @click="sequence" class=" p-2 d-flex border-bg-2 cursor-pointer bg-brown">
            <div class="price">价格</div>
            <div :class="sequenceTab ? 'active' : ''" class="money-icon_shenxu ml-2"></div>
          </div>
          <div style="height: 40px;
    line-height: 40px;" class="d-flex align-items-center ml-2 p-md-2 p-1 border-bg-2">
            <div class="mr-1 price-between">价格区间</div>
            <div class="d-flex align-items-center price-between-box">
              <div><input v-model="start" class="money-qian" type="number"></div>
              <div> - </div>
              <div><input v-model="end" class="money-qian" type="number"></div>
            </div>

          </div>
          <div @click="IntervalSearch(start, end)" class="">
            <div style="width: 72px;
height: 40px;
background: rgba(30,122,145,0.2);
border: 2px solid #175B6C;
border-radius: 4px;text-align: center;
line-height: 40px;">确定</div>
          </div>
        </div>
        <div class="money-s border-bg-2 d-flex ml-auto align-items-center mb-3" style="width: 373px;height: 40px;background: rgba(255,255,255,0.03);
    border: 2px solid #323232;
    border-radius: 4px;">
          <div class="ml-3">
            <input v-model="search" class="text-white input-width" type="text" placeholder="输入装备关键字"
              @keyup.enter="getluckySearch">
          </div>
          <div @click="getluckySearch" class="bg-dark-brown input-height px-3 ml-auto cursor-pointer ">搜索</div>
        </div>
      </div>
    </div>
    <!-- 武器列表 -->
    <div class="content-weapon">
      <div class="flex-wrap mt-3 d-flex row">
        <div class="col-lg-3  col-md-3 col-6 mb-4 px-md-3 px-1 " @click="pitchOn(item)"
          v-for="(item, index) in chooseluckyList" :key="index">

          <weapon :res-data="item">
          </weapon>
        </div>
      </div>
    </div>
    <div class="py-3 d-flex justify-content-center">
      <el-pagination v-if="pagination" background layout="prev, pager, next" :total="total" :page-size="pageSize"
        @current-change="currentChange" :pager-count="5">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import weapon from "./weapon";
import $api from '@/api/lucky/index'
export default {
  name: "luckyIndex",
  data() {
    return {
      // 搜索
      search: '',
      // nav 点击按个那个更换active
      chooseIndex: -1,
      // 武器导航
      chooseNav: [],
      // 幸运武器列表
      chooseluckyList: [],
      // 选择武器类型
      type_id: 0,
      // 一共多少条
      total: 0,
      // 一页多少个
      pageSize: 0,
      // 多少个之后隐藏
      count: 4,
      // 搜索翻页
      pageSearch: 1,
      // 翻页隐藏显示
      pagination: true,
      // 升序降序
      sequenceTab: 1,
      // 价格区间
      start: 0,
      end: '',

    }
  },
  components: {
    weapon
  },
  created() {

  },
  mounted() {
    this.init()
  },
  computed: {

  },
  methods: {
    // 选中那个
    pitchOn(item) {
      this.$emit('pitchOn', { item })
    },
    // 价格区间搜索
    async IntervalSearch() {
      let end = 100000
      if (this.end == '') {
        end = 10000
      } else {
        end = this.end
      }
      if (this.start == '') {
        this.start = 0
      }
      let { data } = await $api.getluckyList(this.pageSearch, this.type_id, this.search, this.start, end, this.sequenceTab)
      this.chooseluckyList = data.data
      this.total = data.total
      this.pageSize = data.per_page

    },
    // 排序
    async sequence() {
      this.sequenceTab = this.sequenceTab ? 0 : 1
      let { data } = await $api.getluckyList(this.pageSearch, this.type_id, this.search, this.start, this.end, this.sequenceTab)
      this.chooseluckyList = data.data
      this.total = data.total
      this.pageSize = data.per_page
    },
    async init() {

      // 获取武器导航
      await this.getluckyType()
      await this.getluckyList()
    },
    // 获取武器导航的
    async getluckyType() {
      try {
        let { data, code } = await $api.getluckyType()
        if (code == 500) return
        this.chooseNav = data
      } catch (e) {
        console.log(e)
      }
    },
    // 获取武器分类
    async getluckyList(page = 1, type_id = 0) {
      try {
        let { data, code, message } = await $api.getluckyList(page, type_id, this.search, this.start, this.end, 1)
        if (code == 500) return
        if (code != 200) {
          this.$message.error(message)
        }
        this.total = data.total
        this.pageSize = data.per_page
        this.chooseluckyList = data.data
      } catch (e) {
        console.log(e)
      }
    },
    // 点击选择武器导航
    async ChooseWeapons(item, index) {
      let loading = this.$loading({ lock: true, text: '正在加载', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      document.getElementById('mysound').play()
      this.chooseIndex = index
      this.type_id = item.id
      this.search = ''
      this.pagination = false
      this.pagination = true
      this.getluckyList(1, item.id)
      setTimeout(() => {

        loading.close();
      }, 500);

    },
    // 武器搜索
    async getluckySearch() {
      document.getElementById('mysound').play()
      try {
        let { data, code } = await $api.getluckyList(this.pageSearch, this.type_id, this.search, this.start, this.end, this.sequenceTab)
        if (code == 500) return
        let loading = this.$loading({ lock: true, text: '正在加载', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
        setTimeout(() => {
          this.chooseluckyList = data.data
          this.total = data.total
          this.pageSize = data.per_page
          loading.close();
        }, 500);
      } catch (e) {
        console.log(e)
      }
    },
    // 翻页
    async currentChange(val) {

      let { data } = await $api.getluckyList(val, this.type_id, this.search, this.start, this.end, this.sequenceTab)
      this.total = data.total
      this.pageSize = data.per_page
      this.chooseluckyList = data.data
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style scoped lang="scss">
.list_type_name {
  color: #C0C0C0;
}
.font-size-16-12 {
  font-size: 16px;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
}

.price-between-box {
  width: 124px;
  height: 40px;
  background: rgba(255,255,255,0.03);
  border: 2px solid #175B6C;
  border-radius: 4px;
  }
  @media (max-width: 600px) {
    .price-between,
    .price {
      font-size: 12px;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
    .price-between-box {
      width: 108px;
    }
  }


.money {
  .price {
    display: flex;
    align-items: center;
  }
  &-qian {
    width: 44px;
    // background: #3b3027;
    color: #bababa;
    text-align: center;
  }

  &-icon_shenxu {
    transition: all .5s;
    width: 12px;
    height: 16px;
    background-position: center 1px;
    background-size: 100% 100%;
    background-image: url("../../../assets/img/ADT/zm/luckyPup/up.png");
  }

  & .active {
    transform: rotate(180deg);
    background-position: center -1px;
  }

  @media (max-width:800px) {
    & .money-s {
      margin-top: 10px;
      width: 100% !important;
      /*display: none!important;*/
    }
  }
}

.content {
  width: 90%;

  @media (max-width:1366px) {
    width: 95%;
  }

  &-weapon {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &-nav {
    width: 100%;

    @media (max-width:1366px) {
      display: block !important;
    }

    &-list {
      width: 70%;

      &-icon {
        width: 20px;
        height: 20px;
        // background-image: url("../../../assets/img/lucky/icon_def.png");
        background-size: 100% 100%;

        @media (max-width:1200px) {
          width: 10px;
          height: 10px;
        }
      }

      &-icon-active {
        // background-image: url("../../../assets/img/lucky/icon_sel.png") !important;
      }

      &-item {
        padding: 10px 28px;

        background-color: #1E2032;

        border-radius: 4px;
        cursor: pointer;

        @media (max-width:1366px) {
          padding: 0 0;
          width: 11%;
          margin-bottom: 0;
        }

        & img {
          // filter: grayscale(1);
        }

        @media (max-width:800px) {
          width: 20%;
        }
      }

      & .active {
        background: #1E7A91;

        & img {
          // filter: grayscale(0) !important;
        }

        background-size: 100% !important;
      }
    }

    &-weapon {
      margin-top: 20px;

    }
  }

}

.input-height {
  line-height: 40px;
  /*width: 150px;*/
  text-align: center;
  background-color: #F9C545;
  font-size: 16px;
  color: #483301;

  border-radius: 0px 4px 4px 0px;
}</style>
