<template>
  <!-- 活动弹窗 -->
    <div
      class="ActivityCenter position-fixed z-index-333"
      v-show="ActiveShow"
      style="
        width: 100vw;
        height: 100vh;z-index:5;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
      "
    >
      <div class="ActivityCenter1">
        <!-- title -->
        <div class="activeTitle"></div>
        <!-- 活动主页 -->
        <div class="ActivityCenter2">
          <div class="active-left">
            <div class="left-top">
              <div class="left-top-button">
                <button
                  class="active-button tab_act"
                  v-for="(item, index) in Activebutton"
                  :key="index"
                  :class="{ tab_act2: currentIndex === index }"
                  @click="liClick(index)"
                >
                  <span class="wrapper">{{ item }}</span>
                </button>
              </div>
            </div>
            <div class="left-center">
              <component :is="comName" ref="comName"></component>
            </div> 
          </div>
          <div class="active-right">
            <commonMy @drawTap="drawTap" ref="myName"></commonMy>
          </div>
        </div>
        <div class="activeDel Btn_a" @click="activeDel">
          <span class="activedel1">
            <i  class="el-icon-close cursor-pointer" style="font-size: 28px;font-weight: bold !important;"></i>
          </span>
        </div>
      </div>
    </div>
</template>

<script>
import {getToken} from '@/utils/auth'
// import sUserAgent from "@/assets/js/common.js";
// import { mapState } from "vuex";
// import $api from "@/api/vd/index";
import TaskIn from "@/views/TaskIn/index";
import redenvelopes from "./common/Redenvelopes.vue";
import activehot from "./common/activehot.vue";
import freeskin from "./common/freeskin.vue";
import commonMy from "./common/my.vue";
import gradebenefits from "./common/gradebenefits.vue";
import taskcenter from "./common/taskcenter.vue";
export default {
    props:{
        show:{
            type: Boolean,
            default:false,
        }
    },
    name: "silde",
    components: {
        TaskIn,
        commonMy,
        redenvelopes,
        activehot,
        freeskin,
        gradebenefits,
        taskcenter,
    },
    watch:{
        show(val,oval){
            if(val !== oval && this.ActiveShow !== val){
                this.ActiveShow=val
                if(val)this.$refs.myName.taskGetList()
            }
        },
        ActiveShow(val){
            this.$emit('update:show',val)
        }
    },
    data() {
        return {
            dialogVisible:false,
            muVal:'',
            comName: "activehot",
            immediatelyShopping: false,
            QQ_qr_code: "",
            QQ_qr_code_is_show: false,
            qq_group_number: 123456789,
            packet: "",
            redList: [],
            silde: 0,
            // 是否显示侧边栏
            isShowSidle: false,
            // 侧栏滚动距离
            sidleSum: 0,
            ActiveShow: false,
            // 点击事件
            currentIndex: 0,
            //   按钮名称
            Activebutton: [
                "热门活动",
                "任务中心",
                // "充值补给",
                "口令红包",
                // "等级福利",
            ],
        };
    },
    methods:{
        liClick(index) {
        this.currentIndex = index;
          if (index == 0) {
              this.comName = activehot;
          }else if (index == 1) {
              this.comName = taskcenter;
          }else if (index == 2) {
              this.comName = redenvelopes;
          }else if (index == 3) {
              this.comName = redenvelopes;
          }else if (index == 4) {
              this.comName = gradebenefits;
          }
          // if (index == 0) {
          //     this.comName = activehot;
          // }else if (index == 1) {
          //     this.comName = taskcenter;
          // }else if (index == 2) {
          //     this.comName = freeskin;
          // }else if (index == 3) {
          //     this.comName = redenvelopes;
          // }else if (index == 4) {
          //     this.comName = gradebenefits;
          // }
        },
        drawTap(){
            if(this.$refs.comName)this.$refs.comName.taskDataList()
        },
        // 跳转活动页面
        async csactive() {
            let token= await getToken()
            if (!token) {
                return this.$store.commit("LOGIN_TOP", true)
            }
            if (this.sUserAgent == "phone") {
                this.$router.push("/user/csactive");
            } else {
                this.ActiveShow = true;
                this.currentIndex=0
                this.comName='activehot'
                if(this.$refs.myName)this.$refs.myName.taskGetList()
                this.$store.state.mission_state = !this.$store.state.mission_state
            }
        },
        activeDel() {
            this.ActiveShow = false;
        },
    }
}
</script>

<style scoped lang="scss">
@keyframes move  {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
.welfare_img{
  margin-left: -2px;
  img{
    width: 50px;
     animation: move 3s 0.15s linear infinite;
  }
  @media (max-width: 1300px) {
     img{
      width: 40px !important;
    }
  }
}
.silde {
  top: 30%;
  right: 0;
  width: 60px;
  z-index: 999;
  padding: 20px 0;
  background-image: url("../assets/img/silde/Rank.png");
  background-size: 100% 100%;
  transition: all 0.5s;
  @media (max-width: 1000px) {
    top: 25%;
    font-size: 12px;
    width: 40px;
    .silde-box{
      padding-left: 0 !important;
    }
    /*display: none;*/
  }
  &-off {
    top: 40%;
    left: -20px;
    background-color: var(--main-yellow);
    width: 20px;
    height: 50px;
    font-size: 24px;
    line-height: 50px;
  }
  &-serve {
    /*border:1px solid #81cdf8;*/
    border-radius: 50px;
    margin-bottom: 0px;
    // width: 70px;
    height: 80px;
    @media (max-width: 1200px) {
      width: 40px;
      height: 60px;
      background-size: 100% 100%;
    }
  }
  &-weixin {
    /*border: 1px solid #81cdf8;*/
    right: 100%;
    border-radius: 10px;
    padding-right: 10px;
    display: none;
  }
  &-W:hover &-weixin {
    display: block;
  }
}
.immediatelyShopping {
  width: 350px;
  left: calc(50% - 175px);
  top: calc(25%);
  z-index: 999;
  background-color: #c02932;
  border-radius: 10px;
  &-list {
    height: 300px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-input {
    width: 150px;
    margin: 10px auto;
    background-color: #fffad8;
    border-radius: 5px;
    & input {
      width: 140px;
    }
  }
  &-item {
    &-img {
      width: 87px;
      height: 80px;
    }
  }
  &-pk {
    width: 100%;
    margin: 5px auto;
    background-color: #bd3840;
    padding: 10px;
    border-radius: 5px;
  }
  @media (max-width: 1200px) {
    width: 70%;
    left: 15%;
    top: calc(80px + 5%);
    &-box {
      &-con {
        &-img {
          width: 50%;
        }
        & img {
          width: 100%;
        }
        &-dis {
          margin-left: 20px !important;
          margin-top: 10px;
        }
      }
    }
  }
}
.jss163 {
  width: 20px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #3e3838;
  top: 50%;
  left: -20px;
  cursor: pointer;
  position: absolute;
  margin-top: -35px;
}
.ActivityCenter {
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #848492;

  .ActivityCenter1 {
    width: 1129px;
    height: 781px;
    left: calc(50% - 550px);
    top:  calc(50% - 390px);
    position: relative;
    border-right: 10px;
    background-size: 100% 100%;
    .activeTitle {
      width: 100%;
      height: 144px;
      background: url("../assets/img/news/task_pc.png") no-repeat;
      background-size: 100% 100%;
    }
    .ActivityCenter2 {
      display: flex;
      background: #22232E;
      padding: 20px;
      z-index: 999;
      .active-left {
        flex: 1;
        height: 100%;
        // background: linear-gradient(180deg, #1d492524 0%, #22300b 100%);
        background: url("../assets/img/renwu/back.jpg") no-repeat;
        background-size: 100% 100%;
        border-radius: 5px;
        .left-top {
          .left-top-button {
            white-space: nowrap;
            display: flex;
            overflow: hidden;
            .active-button {
              width: 100%;
              border: 0;
              max-width: 100%;
              min-width: auto;
              min-height: 44px;
              margin-left: 1px;
              padding-top: 6px;
              margin-right: 1px;
              padding-left: 12px;
              padding-right: 12px;
              padding-bottom: 6px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              font-size: 14px;
              text-align: center;
              color: #c3c3e2;
            }
            .tab_act {
              background: linear-gradient(0deg, #1B1C27, #313348);
              border: 1px solid #000000;  
            }
            .tab_act2 {
              color: #e9b108;
              background: #000;
            }
          }
          .left-center {
            width: 100%;
            height: calc(100% - 44px);
            background-color: yellow;
          }
        }
      }
      .active-right {
        width: 25%;
        height: 100%;
        margin-left: 14px;
      }
    }
    .activeDel {
      position: absolute;
      top: 0;
      right: -20px;
      z-index: 9999;
      color: rgb(255, 183, 165);
      .activedel1 {
        padding:10px;
        color: #000;
        font-weight: bold;
        background: #FFC67B;
        border-radius: 50%;
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}
</style>
