<template>
  <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
<div>
  <div class=" rounded position-relative margin-top" :style="'width:100%'">

<div
  class="bg-size-100 act_weapon bg-repeat position-relative d-flex flex-wrap justify-content-center align-items-center "
  :class="'up-bg' + resData.lv">
  <!-- <div class="position-absolute bglogo-e" style="width: 30px; height: 30px;z-index:999"></div> -->
  <img class="weapon_act_img" :src="resData.cover" alt="">
</div>
<div class="position-absolute get_user">
  <img width="100%" :src="resData.get_user.avatar" alt="">
</div>

</div>
<div class="text-money" style="font-size: 12px;line-height:20px">
      <div class="flex justify-content-center align-items-center"><money />{{resData.bean}}</div>
    </div>
</div>
</template>

<script>
export default {
  name: "weaponhist",
  props: {
    //    传输的item
    resData: {
      type: Object,
      default() { return {} }
    }
  },
}
</script>

<style scoped lang="scss">
.get_user {
  top: -25px;
  left: calc(50% - 25px);
  right: -8px;
  width: 50px;
  height: 50px;

  img {
    border-radius: 50%;
    border: 2px solid #19778E;
  }

  @media (max-width:1300px) {
    top: -20px;
    left: calc(50% - 20px);
    right: -8px;
    width: 40px;
    height: 40px;
  }

  // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.text-money {
  width: 100%;
  & > div {
    margin-top: 4px;
  }
}

.weapon {
  &-name {
    padding: 10px 0;
  }
}

.act_weapon {
  border-radius: 5px;
  height: 120px;

  @media (max-width:1300px) {
    padding: 0 20px;
    // height: 90px;
  }
  @media (max-width:600px) {
    padding: 0 30px;
    height: 90px;
  }
}

.weapon_act_img {
  height: 70px;

  @media (max-width:1300px) {
    height: 50px;
  }
}</style>
