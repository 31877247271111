<template>
    <div class="knapsack">
        <div class="title">
            <div class="left">
                <a :class="`${ active == 0? 'active':'' }`" @click="fnAvtive(0)">我的背包</a>
                <!-- <a :class="`${ active == 1? 'active':'' }`" @click="fnAvtive(1)">正在取回</a>
                <a :class="`${ active == 2? 'active':'' }`" @click="fnAvtive(2)">取回记录</a> -->
            </div>
            <div class="right">
                <div>共 <span> {{ recyleMoney.index }} </span> 件饰品</div>
                <!-- <div>价值 <span><img src="@/assets/img/ADT/home/UP@2x.png" /> {{recyleMoney.benr}}</span></div> -->
            </div>
        </div>
        <div class="type">
            <div class="left" @click="toggleBean()">
                <!-- <a>获得时间 <img src="@/assets/img/ADT/user/jg.png" /></a> -->
                <a>价格 <img :style="`transform: rotate(${ sort == 0?'180deg':'0deg' });`" src="@/assets/img/ADT/user/jg.png" /></a>
            </div>
            <div class="right" v-if="ua == `web`">
                <a class="all" @click="allList">
                    <img src="@/assets/img/ADT/user/xx.png" v-if="!isAll" /> <img src="@/assets/img/ADT/user/x.png" v-else /> 全选
                </a>
                <a class="total">
                    共<span><img src="@/assets/img/ADT/home/UP@2x.png" />{{recyleMoney.benr}}</span>
                </a>
                <a class="qh" @click="extract">
                    取回
                </a>
                <a class="fj" @click="recycle">
                    分解
                </a>
            </div>
            <div class="right" v-if="ua != `web`">
                <a class="total">
                    共<span>{{recyleMoney.index}}</span>件
                </a>

            </div>
        </div>

        <div class="listBox">
            <a :class="item.isShow ? 'active' : ''" v-for="(item,index) in StorageList"
                :style="`background-image:url(${require(`../../../assets/img/ADT/common/bg5/all-bg${ item.lv }.png`)})`" :key="index">
                <div :class="`slv${ item.lv } title`"  @click="itemOne(item, index)">
                    <div class="left">
                        <img src="@/assets/img/ADT/home/UP@2x.png" width="20" /> {{item.bean}}
                    </div>
                    <div class="right">
                        {{item.dura_alias}}
                    </div>
                </div>
                <div  @click="itemOne(item, index)">
                    <img :src="item.cover" />
                </div>
                <p>{{item.name.replace(/\s*/g, "")}}</p>
                <a v-if="item.back_message" @click="shoppinng(item)">查看原因</a>
            </a>
        </div>

        <div class="knapbottom">
                    <div>
                <a class="all" @click="allList">
                    <img src="@/assets/img/ADT/user/xx.png" v-if="!isAll"  /> <img src="@/assets/img/ADT/user/x.png" v-else /> 全选
                </a>
                <a class="total">
                    共<span><img src="@/assets/img/ADT/home/UP@2x.png" />{{recyleMoney.benr}}</span>
                </a>
            </div>
            <div>
                <a class="qh" @click="extract">
                    取回
                </a>
                <a class="fj" @click="recycle">
                    分解
                </a>
            </div>
        </div>
        <vacancy v-if="StorageList.length == 0" />
        <div class="flex paging" v-if="StorageList.length != 0">
            <div class="paging-item _a" @click="laypage(0)">上一页</div>
            <div class="paging-item _a" @click="laypage(1)">下一页</div>
        </div>

        <el-dialog
            custom-class="pop-up"
            :show-close="false"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :visible.sync="ReturnReason"
            width="700px">
            <div class="p-4 text-white">
            <h3 class="h3 text-center mb-4">退回信息</h3>
            <p class="mb-3">
            {{back_message}}
            </p>
            <div @click="ReturnReason = false" class="position-absolute" style="right: 20px;top: 30px">
                <div>
                <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                </div>
            </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import $api from '@/api/user/index';
import sUserAgent from '@/assets/js/common.js'
export default {
    name: "index",
    data() {
        return {
            page:1,
            StorageList:[],
            active:0,
            sort:0,
            isAll:false,

            ReturnReason:false,
            back_message:''
        }
    },
    mounted(){
        this.getStorage(1, 0)
    },
    computed:{
        ua() {
            return sUserAgent.sUserAgent()
        },
        recyleMoney() {
            let benr = 0
            let index = 0
            this.StorageList.forEach(item => {
                if (item.isShow == true) {
                    benr += (item.bean) * 1
                    index++
                }
            })
            return { benr: benr.toFixed(2), index }
        },
    },
    methods:{
        shoppinng(item){
            console.log(item.back_message)
            if(item.back_message != ''){
            this.ReturnReason = true
            this.back_message = item.back_message
            }
        },
        laypage(val) {
            if (val) {
                this.page++
                this.getStorage(this.page)
                // window.scrollTo(0,0);
            } else if (this.page > 1) {
                this.page--
                this.getStorage(this.page)
                // window.scrollTo(0,0);
            }
        },
        // 点击全选
        allList() {
            this.isAll=!this.isAll
            this.StorageList.forEach(item => {
                item.isShow = !item.isShow
            })
        },
        async toggleBean(){
            this.sort = this.sort == 1?0:1;
            this.getStorage1(1,this.active)
        },
        async fnAvtive(index){
            this.active = index;
            this.getStorage1(1,this.active == 2?4:this.active)
        },
        async getStorage(page = 1) {
            try {
                let { data, code } = await $api.getStorage(page, this.active == 2?4:this.active, this.sort)
                if (code == 500) return
                if (!data.data.length) return this.page--
                data.data.forEach(item => {
                    if (item.back_message != '') {
                        console.log(123)
                    }
                    item.isShow = false
                })
                this.StorageList = data.data
                console.log(this.StorageList)
                // .forEach(item => {
                //     item.isNew = false;
                // })
            } catch (e) {
                console.log(e)
            }
        },
        async getStorage1(page = 1) {
            try {
                let { data, code } = await $api.getStorage(page, this.active, this.sort)
                if (code == 500) return
                data.data.forEach(item => {
                    if (item.back_message != '') {
                        console.log(123)
                    }
                    item.isShow = false
                })
                this.StorageList = data.data
            } catch (e) {
                console.log(e)
            }
        },
        // 点击单独一个
        itemOne(item) {
            this.isAll=false;
            item.isShow = !item.isShow
        },
        // 点击提取
        async extract() {
            let arr = []
            this.StorageList.forEach(item => {
                if (item.isShow) {
                    arr.push(item.id)
                }
            })
            try {
                let { message, code } = await $api.getExtract({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                    this.isAll = false;
                }
                this.getStorage1(1, 0)
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },
         // 点击分解
         async recycle() {
            let arr = []
            try {
                this.StorageList.forEach(item => {
                    if (item.isShow) {
                        arr.push(item.id)
                    }
                })
                let { message, code } = await $api.getCash({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                    this.isAll = false;
                }
                this.getStorage1(1, 0)
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.knapbottom {
    display: none;

    @media (max-width:600px) {
        position: fixed;
        bottom: 65px;
        background: #0E1C2B;
        border: 2px solid #123E4A;
        z-index: 9;
        border-radius: 10px;
        width: 95%;
        left: 50%;
        margin-left: -47.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;

        >div {
            display: flex;
            align-items: center;

            >.qh {
                background-image: url(../../../assets/img/ADT/user/qh_w.png);
                background-size: 70px 40px;
                width: 70px;
                height: 40px;
                text-align: center;
                font-size: 14px;
                line-height: 40px;
            }

            >.fj {
                background-image: url(../../../assets/img/ADT/user/fj_w.png);
                background-size: 70px 40px;
                width: 70px;
                height: 40px;
                text-align: center;
                font-size: 14px;
                line-height: 40px;
            }

            >.all {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-right: 14px;

                >img {
                    margin-right: 4px;
                    height: 20px;
                }
            }

            >.total {
                display: flex;
                align-items: center;
                font-size: 14px;

                >span {
                    display: flex;
                    font-family: Monaco;
                    align-items: center;
                    color: #ECBA44;

                    >img {
                        width: 18px;
                        margin: 0 4px;
                    }
                }
            }
        }
    }
}

.knapsack {
    padding: 40px;
    background-color: rgba(25, 53, 74, 0.7764705882);
    border-radius: 6px;
    padding-top: 20px;

    @media (max-width:600px) {
        padding: 10px;
        background-color: inherit;
    }

    >.title {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #354966;
        margin-bottom: 20px;
        height: 60px;

        @media (max-width:600px) {
            margin-bottom: 15px;
        }

        >.left {
            >a {
                color: #ddd;
                font-size: 20px;
                font-weight: bold;
                margin-right: 45px;
                cursor: pointer;
                height: 60px;
                line-height: 60px;
                display: inline-block;
                font-weight: 400;

                @media (max-width:600px) {
                    margin-right: 28px;
                    font-size: 16px;


                }
            }

            >.active {
                border-bottom: solid 4px #35C9EE;
                color: #35C9EE;
                font-weight: bold;
            }
        }

        >.right {
            display: flex;
            align-items: center;
            height: 60px;

            @media (max-width:600px) {
                display: none;

            }

            >div:first-child {
                padding-right: 10px;
                border-right: solid 1px #404751;
                margin-right: 10px;
            }

            >div {
                display: flex;
                align-items: center;
                color: #ccc;

                >span {
                    display: flex;
                    font-family: Monaco;
                    align-items: center;
                    margin: 0 10px;
                    color: #fff;
                    font-weight: bold;

                    >img {
                        width: 16px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }

    >.type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        @media (max-width:600px) {
            display: flex;
            margin-bottom: 10px;
        }

        >.left {
            display: flex;
            align-items: center;

            >a {
                display: flex;
                align-items: center;
                background-color: #1e7a914b;
                margin-right: 10px;
                padding: 12px 25px;
                border-radius: 4px;

                @media (max-width:600px) {
                    padding: 10px 20px;
                    font-size: 14px;
                }

                >img {
                    width: 25px;

                    @media (max-width:600px) {
                        width: 20px;
                    }
                }
            }
        }

        >.right {
            display: flex;
            align-items: center;

            @media (max-width:600px) {}

            >a {
                display: flex;
                cursor: pointer;
                align-items: center;

                >span {
                    display: flex;
                    align-items: center;

                    >img {
                        width: 20px;
                    }
                }
            }

            >.qh {
                background-size: 116px 50px;
                width: 116px;
                margin-right: 12px;
                height: 50px;
                flex: 0 0 116px;
                display: flex;
                justify-content: center;
                color: #34C3E7;
                line-height: 50px;
                background-image: url(../../../assets/img/ADT/user/qh.png);
            }

            >.fj {
                background-size: 116px 50px;
                margin-right: 4px;
                width: 116px;
                flex: 0 0 116px;
                height: 50px;
                text-align: center;
                color: #F2C044;
                display: flex;
                justify-content: center;
                line-height: 50px;
                background-image: url(../../../assets/img/ADT/user/fj.png);
            }

            >.all {
                margin-right: 20px;
                color: #34C3E7;

                >img {
                    margin-right: 4px;
                }
            }

            >.total {
                margin-right: 20px;

                @media (max-width:600px) {
                    margin-right: 0px;
                }

                >span {
                    font-family: Monaco;
                    color: #F2C044;

                    >img {
                        margin-left: 8px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    >.listBox {
        @media (min-width:600px) {
            height: calc(53vh);
            overflow: auto;
        }
        
        display: flex;
        flex-wrap: wrap;

        >a {
            flex: 0 0 302px;
            max-width: 302px;
            margin-right: 20px;
            margin-bottom: 20px;
            height: 270px;
            border: solid 2px #1E3648;
            background-size: 302px 266px;
            background-repeat: no-repeat;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            > a{
                position: absolute;
                top: 60px;
                cursor: pointer;
                left: 10px;
                color: red;
                @media (max-width:600px) {
                    font-size: 12px;
                    top: 50px;
                    left: 4px;
                }
            }

            @media (max-width:600px) {
                flex: 0 0 49%;
                max-width: 49%;
                margin-right: 2%;
                height: 180px;
                border: solid 2px #191A20;
                margin-bottom: 2%;
            }

            >.title {
                display: flex;
                justify-content: space-between;
                background-color: #8967e02e;
                height: 50px;
                padding: 0 20px;
                align-items: center;

                @media (max-width:600px) {
                    padding: 0 10px;
                    height: 40px;
                }

                >.left {
                    display: flex;
                    align-items: center;
                    font-family: Monaco;

                    @media (max-width:600px) {
                        font-size: 14px;
                    }

                    >img {
                        margin-right: 5px;

                        @media (max-width:600px) {
                            width: 16px;
                        }
                    }
                }

                >.right {
                    @media (max-width:600px) {
                        font-size: 14px;
                    }
                }
            }

            > .slv0,>.slv5{
                background-color: #00000026;
            }

            > .slv2{
                background-color:#e76253ae;
            }
            > .slv1{
                background-color:#f9c644ae;
            }

            > .slv4{
                background-color: #061b355c;
            }

            > .slv2{
                background-color: #8967e02e;
            }

            >div {
                text-align: center;

                >img {
                    height: 160px;
                    margin-bottom: 8px;
                    @media (max-width:600px) {
                        height: 100px;
                    }
                }
            }

            >p {
                text-align: center;
                font-family: Monaco;
                font-size: 16px;
                font-weight: bold;
                width: 260px;
                margin: 0 auto;
                overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                @media (max-width:600px) {
                    font-size: 14px;
                    width: 140px;
                margin: 0 auto;
                }
            }
        }

        @media (max-width:600px) {
            >a:nth-child(2n) {
                margin-right: 0px;
            }
        }

        >.active {
            border: solid 2px #ECBA44;
        }

        >a:nth-child(4n) {
            margin-right: 0px;
        }
    }
}</style>