<template>
  <div class="Payment">
    正在跳转 请稍后 ......
  </div>
</template>

<script>
  import $api from '@/api/user/index'
  export default{
    name:'Payment',
    data(){
      return{

      }
    },
    created() {
      $api.getRecharge(this.$route.query).then((res) => {
        location.replace(res.data.list.h5)
      })
    },
    mounted() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
    }
  }
</script>

<style lang="scss">
  .Payment{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
