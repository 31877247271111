<template>
    <div class="steam">
        <h2>STEAM交易地址</h2>
        <div class="stBox">
            <input :disabled="steamStatus" v-model="steam_url" placeholder="请输入您的STEAM URL" />
        </div>
        <p class="p1">1、交易链接一旦绑定则无法更换,请谨慎操作,同一链接更新token不限制. </p>
        <p>2、请勿绑定他人的steam交易链接</p>
        <div class="btns">
            <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
                            target="_blank">获取</a>
            <a @click="getSteamLink()"><img src="@/assets/img/ADT/user/save.png" /> 保存地址</a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import $api from '@/api/user/index';
export default {
    name: "index",
    data() {
        return {
            steam_url:``,
            steamStatus:false,
        }
    },
    computed: {
        ...mapState(['user_info', 'web_info'])
    },
    created(){
        setTimeout(() => {
            this.steam_url = this.user_info.steam_url
            this.steamStatus = this.user_info.steam_url?true:false
        }, 500)
    },
    methods:{
        // setam 链接
        async getSteamLink() {
            let { message, code } = await $api.getSet_steam_url({ steam_url: this.steam_url })
                if (code == 500) return
                this.$notify({
                    title: '成功',
                    message: message,
                    type: 'success', position: 'top-left'
                });
        },
    }
}
</script>

<style lang="scss" scoped>

.steam{
    padding: 40px;
    background-color: rgba(25, 53, 74, 0.7764705882) !important;
    border-radius: 6px;

    > h2 {
        font-size: 18px;
        font-family: Monaco;
        margin-bottom: 28px;
    }

    >div{
        
        > input{
            font-family: Monaco;
            color: #fff;
            width: 100%;
        }
    }
    >.stBox{
        background-color: #15182C;
        width: 100%;
        line-height: 50px;
        height: 50px;
        text-indent: 15px;
        margin-bottom: 10px;
    }
    > p{
        font-size: 14px;
        font-family: Monaco;
        color: #ccc;
        font-weight: 300;
        margin-bottom: 28px;
    }
    > .p1{
        margin-bottom: 5px;
    }
    > .btns{
        display: flex;
        > a{
            background-color: #1D536A;
            display: flex;
            align-items: center;
            margin-right: 10px;
            padding: 16px 60px;
            border-radius: 4px;
            cursor: pointer;
            color: #ddd;
            > img{
                width: 20px;
                margin-right: 4px;
            }
        }
    }
}
</style>