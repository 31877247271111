<template>
    <div class="knapsack">
        <div class="title">
            <div class="left">
                <a :class="`${ active == 1? 'active':'' }`" @click="fnAvtive(1)">提取完成</a>
                <a :class="`${ active == 2? 'active':'' }`" @click="fnAvtive(2)">已兑换</a>
                <a :class="`${ active == 4? 'active':'' }`" @click="fnAvtive(4)">申请提货</a>
                <a :class="`${ active == 5? 'active':'' }`" @click="fnAvtive(5)">正在发货</a>
                <a :class="`${ active == 6? 'active':'' }`" @click="fnAvtive(6)">等待收货</a>
            </div>
            
        </div>
        

        <div class="listBox">
            <a v-for="(item,index) in StorageList"
                :style="`background-image:url(${require(`../../../assets/img/ADT/common/bg5/all-bg${ item.lv }.png`)})`" :key="index">
                <div :class="`slv${ item.lv } title`">
                    <div class="left">
                        <img src="@/assets/img/ADT/home/UP@2x.png" width="20" /> {{item.bean}}
                    </div>
                    <div class="right">
                        {{item.dura_alias}}
                    </div>
                </div>
                <div>
                    <img :src="item.cover" />
                </div>
                <p>{{item.name.replace(/\s*/g, "")}}</p>
            </a>
            
        </div>
        <vacancy v-if="StorageList.length == 0" />
        <div class="flex paging pagenation" v-if="StorageList.length != 0">
            <div class="paging-item _a" @click="laypage(0)">上一页</div>
            <div class="paging-item _a" @click="laypage(1)">下一页</div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/user/index';
import sUserAgent from '@/assets/js/common.js'
export default {
    name: "index",
    data() {
        return {
            page:1,
            StorageList:[],
            active:1,
            sort:0,
            isAll:false,
        }
    },
    mounted(){
        this.getStorage1(1, 1)
    },
    computed:{
        ua() {
            return sUserAgent.sUserAgent()
        },
        recyleMoney() {
            let benr = 0
            let index = 0
            this.StorageList.forEach(item => {
                if (item.isShow == true) {
                    benr += (item.bean) * 1
                    index++
                }
            })
            return { benr: benr.toFixed(2), index }
        },
    },
    methods:{
        laypage(val) {
            if (val) {
                this.page++
                this.getStorage(this.page)
                // window.scrollTo(0,0);
            } else if (this.page > 1) {
                this.page--
                this.getStorage(this.page)
                // window.scrollTo(0,0);
            }
        },
        // 点击全选
        allList() {
            this.isAll=!this.isAll
            this.StorageList.forEach(item => {
                item.isShow = !item.isShow
            })
        },
        async toggleBean(){
            this.sort = this.sort == 1?0:1;
            this.getStorage1(1,this.active)
        },
        async fnAvtive(index){
            this.active = index;
            this.getStorage1(1)
        },
        async getStorage(page = 1) {
            try {
                let { data, code } = await $api.getStorage(page, this.active, this.sort)
                if (code == 500) return
                if (!data.data.length) return this.page--
                data.data.forEach(item => {
                    if (item.back_message != '') {
                        console.log(123)
                    }
                    item.isShow = false
                })
                this.StorageList = data.data
                console.log(this.StorageList)
                // .forEach(item => {
                //     item.isNew = false;
                // })
            } catch (e) {
                console.log(e)
            }
        },
        async getStorage1(page = 1) {
            try {
                let { data, code } = await $api.getStorage(page, this.active, this.sort)
                if (code == 500) return
                data.data.forEach(item => {
                    if (item.back_message != '') {
                        console.log(123)
                    }
                    item.isShow = false
                })
                this.StorageList = data.data
            } catch (e) {
                console.log(e)
            }
        },
        // 点击单独一个
        itemOne(item) {
            this.isAll=false;
            item.isShow = !item.isShow
        },
        // 点击提取
        async extract() {
            let arr = []
            this.StorageList.forEach(item => {
                if (item.isShow) {
                    arr.push(item.id)
                }
            })
            try {
                let { message, code } = await $api.getExtract({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                }
                this.getStorage1(1, 0)
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },
         // 点击分解
         async recycle() {
            let arr = []
            try {
                this.StorageList.forEach(item => {
                    if (item.isShow) {
                        arr.push(item.id)
                    }
                })
                let { message, code } = await $api.getCash({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                }
                this.getStorage1(1, 0)
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.pagenation {
    padding-bottom: 10px;
}
.knapsack {
    padding: 40px;
    background-color: rgba(25, 53, 74, 0.7764705882);
    border-radius: 6px;
    padding-top: 20px;

    @media (max-width:600px) {
        padding: 0 10px;
        background-color: transparent;
    }

    >.title {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #354966;
        margin-bottom: 20px;
        height: 60px;

        @media (max-width:600px) {
            margin-bottom: 15px;
        }

        >.left {
            @media (max-width:600px) {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
            >a {
                color: #ddd;
                font-size: 20px;
                font-weight: bold;
                margin-right: 60px;
                cursor: pointer;
                height: 60px;
                line-height: 60px;
                display: inline-block;
                font-weight: 400;
                @media (max-width:600px) {
                    font-size: 14px;
                    flex: 1;
                    text-align: center;
                    margin-right: 0px;
                }
            }

            >.active {
                border-bottom: solid 4px #35C9EE;
                color: #35C9EE;
                font-weight: bold;
                @media (max-width:600px) {
                    border-bottom: solid 2px #35c9eea6;
                }
            }
        }

        >.right {
            display: flex;
            align-items: center;
            height: 60px;

            >div:first-child {
                padding-right: 10px;
                border-right: solid 1px #404751;
                margin-right: 10px;
            }

            >div {
                display: flex;
                align-items: center;
                color: #ccc;

                >span {
                    display: flex;
                    font-family: Monaco;
                    align-items: center;
                    margin: 0 10px;
                    color: #fff;
                    font-weight: bold;

                    >img {
                        width: 16px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }

    >.type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        >.left {
            display: flex;
            align-items: center;

            >a {
                display: flex;
                align-items: center;
                background-color: #1e7a914b;
                margin-right: 10px;
                padding: 12px 25px;
                border-radius: 4px;

                >img {
                    width: 25px;
                }
            }
        }

        >.right {
            display: flex;
            align-items: center;

            >a {
                display: flex;
                cursor: pointer;
                align-items: center;
                >span {
                    display: flex;
                    align-items: center;
                    >img {
                        width: 20px;
                    }
                }
            }
            
            > .qh{
                background-size:116px 50px;
                width: 116px;
                margin-right: 12px;
                height: 50px;
                flex: 0 0 116px;
                display: flex;
                justify-content: center;
                color: #34C3E7;
                line-height: 50px;
                background-image: url(../../../assets/img/ADT/user/qh.png);
            }
            > .fj{
                background-size:116px 50px;
                margin-right: 4px;
                width: 116px;
                flex: 0 0 116px;
                height: 50px;
                text-align: center;
                color: #F2C044;
                display: flex;
                justify-content: center;
                line-height: 50px;
                background-image: url(../../../assets/img/ADT/user/fj.png);
            }
            > .all{
                margin-right: 20px;
                color: #34C3E7;
                > img{
                    margin-right: 4px;
                }
            }
            > .total{
                margin-right: 20px;
                >span{
                    font-family: Monaco;
                    color: #F2C044;
                    > img{
                        margin-left: 8px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    > .listBox{
        @media (min-width:600px) {
            height: calc(53vh);
            overflow: auto;
        }
        display: flex;
        flex-wrap: wrap;
        > a{
            flex: 0 0 302px;
            max-width: 302px;
            margin-right: 20px;
            margin-bottom: 20px;
            height: 270px;
            border: solid 2px transparent;
            background-size: 302px 266px;
            background-repeat: no-repeat;
            border-radius: 8px;
            overflow: hidden;
            @media (max-width:600px) {
                flex: 0 0 49%;
                max-width: 49%;
                margin-right: 2%;
                height: 180px;
                margin-bottom: 2%;
                border: solid 2px #191A20;
            }
            > .title{
                display: flex;
                justify-content: space-between;
                background-color: #8967e02e;
                height: 50px;
                padding: 0 20px;
                align-items: center;
                @media (max-width:600px) {
                    padding: 0 10px;
                    height: 40px;
                }
                > .left{
                    display: flex;
                    align-items: center;
                    font-family: Monaco;
                    @media (max-width:600px) {
                        font-size: 14px;
                    }
                    > img{
                        margin-right: 5px;
                        @media (max-width:600px) {
                            width: 16px;
                        }
                    }
                }
                >.right {
                    @media (max-width:600px) {
                        font-size: 14px;
                    }
                }
            }
            > .slv0,>.slv5{
                background-color: #00000026;
            }

            > .slv2{
                background-color:#e76253ae;
            }
            > .slv1{
                background-color:#f9c644ae;
            }

            > .slv4{
                background-color: #061b355c;
            }

            > .slv2{
                background-color: #8967e02e;
            }
            > div{
                text-align: center;
                > img{
                    height: 160px;
                    @media (max-width:600px) {
                        height: 100px;
                    }
                }
            }
            > p{
                text-align: center;
                font-family: Monaco;
                font-size: 18px;
                font-weight: bold;
                width: 260px;
                margin: 0 auto;
                overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                @media (max-width:600px) {
                    font-size: 14px;
                    width: 140px;
                margin: 0 auto;
                }
            }
        }
        @media (max-width:600px) {
            >a:nth-child(2n) {
                margin-right: 0px;
            }
        }
        > .active{
            border: solid 2px #ECBA44;
        }
        > a:nth-child(4n){
            margin-right: 0px;
            @media (max-width:600px) {
                margin-right: 0%;
            }
        }
    }
}</style>