<template>
  <div class="shopping_index">
    <div class="h3 text-white font-weight-bold text-center" style="margin-bottom:0">
    </div>
    <div class="content">
      <div class="d-flex justify-content-between flex-wrap">
        <div :class="getluckyTypeActive == index?'active':''" @click="getluckyTypeClick(item,index)" class="text-center border _a p-2 f_shopping_class" v-for="(item,index) in getluckyType" :key="index">
          <img :src="require(`@/assets/img/news/jifenshangcheng/${getluckyTypeActive == index?'act':'no'}/${index+1}.png`)" alt="">
          <div class="margin-top-xs">{{item.name}}</div>
        </div>
      </div>
      <div v-if="sUserAgent!='phone'" class="d-flex choice_bg flex-wrap align-items-center">
        <div class="d-flex mb-1" style="height: 40px;line-height: 40px">
          <div class="sort_btn px-3 cursor-pointer" :class="{'bg-bg':sortSum==1}" @click="sort('1')">
            <div class=" icon-size">
              <i class="iconfont icon-shangsheng" style="font-size:20px"></i>
            </div>
          </div>
          <div class="sort_btn margin-left-xs px-3 cursor-pointer" :class="{'bg-bg':sortSum==2}"  @click="sort('2')">
            <div class=" icon-size icon-zhuan">
              <i class="iconfont icon-shangsheng text-bold" ></i>
            </div>
          </div>
        </div>
        <div class="d-flex mb-1 margin-left" style="color:#65DFF4">
          <money :integral="false" color="#fff"></money>
          <div class=" px-2 py-1">
            {{user_info.integral || 0}}
          </div>
        </div>
        <div class="flex margin-left">
          <div style="line-height:36px" class="margin-right-xs">外观:</div>
          <el-select v-model="search_dura_value" clearable placeholder="请选择">
            <el-option
              v-for="(item,index) in search_dura" 
              :key="index"
              :label="item"
              :value="index">
            </el-option>
          </el-select>
        </div>
        <div class="flex margin-left">
          <div style="line-height:36px" class="margin-right-xs">品质:</div>
          <el-select v-model="search_rarity_value" clearable placeholder="请选择">
            <el-option
              v-for="(item,index) in search_rarity"
              :key="index"
              :label="item"
              :value="index">
            </el-option>
          </el-select>
        </div>
        <div class="d-flex ml-md-auto mb-1">
          <div class="d-md-flex d-none  mr-3 align-items-center">
            <div class="bg-bg px-2 input">
              <input placeholder="最低积分" v-model="min_bean" style="width: 100px;font-size: 12px;height: 40px" type="text" class="text-white">
            </div>
            <!-- <div style="width:40px;height: 2px" class="bg-yellow "></div> -->
            <div class="bg-bg  px-2 input margin-left-xs">
              <input placeholder="最高积分" v-model="max_bean" style="width: 100px;font-size: 12px;height: 40px" type="text" class="text-white" @keyup.enter="filtrateMoney">
            </div>
          </div>
          <div class=" d-flex ml-auto" style="height: 40px">
            <input placeholder="搜索名称" v-model="search" style="width: 200px;font-size: 12px" type="text"
                   class="text-white px-2 input">

            <div @click="searchName" class="px-md-5
             px-3 ml-3 common-btn common-btn-blue cursor-pointer"
                 style="line-height: 40px">搜索
            </div>
          </div>

        </div>

      </div>
      <div v-else class="d-flex choice_bg flex-wrap align-items-center">
        <div class="flex">
          <el-select style="width:48%" v-model="search_dura_value" placeholder="请选择外观">
            <el-option
              v-for="(item,index) in search_dura"
              :key="index"
              :label="item"
              :value="index">
            </el-option>
          </el-select>
          <el-select style="width:48%;margin-left:4%" v-model="search_rarity_value" placeholder="请选择品质">
            <el-option
              v-for="(item,index)  in search_rarity"
              :key="index"
              :label="item"
              :value="index">
            </el-option>
          </el-select>
        </div>
        <div class="flex margin-top-xs" style="width:100%">
          <el-input placeholder="最低积分" v-model="min_bean" style="width: 48%;font-size: 12px;height: 40px" type="text" class="text-white"></el-input>
          <el-input placeholder="最高积分" v-model="max_bean" style="width: 48%;margin-left:4%;font-size: 12px;height: 40px"  type="text" class="text-white" @keyup.enter="filtrateMoney"></el-input>
        </div>
        <div class="flex margin-top-xs">
          <div class="d-flex mb-1" style="height: 40px;line-height: 40px">
          <div class="sort_btn px-3 cursor-pointer" :class="{'bg-bg':sortSum==0}" @click="sort('0')">
            <div class=" icon-size">
              <i class="iconfont icon-shangsheng" style="font-size:20px"></i>
            </div>
          </div>
          <div class="sort_btn margin-left-xs px-3 cursor-pointer" :class="{'bg-bg':sortSum==1}"  @click="sort('1')">
            <div class=" icon-size icon-zhuan">
              <i class="iconfont icon-shangsheng text-bold" ></i>
            </div>
          </div>
        </div>
        <div class="d-flex margin-left-xs" style="width: calc(100% - 100px);">
          <el-input placeholder="搜索名称" v-model="search" style="width: calc(100% - 100px);font-size: 12px" type="text"></el-input>
          <div @click="searchName" class="px-md-5 px-3 ml-3 common-btn common-btn-blue cursor-pointer" style="line-height: 36px;height: 38px">搜索</div>

        </div>
        </div>

      </div>

      <div class="content-list my-5">
        <div class="mb-2 weapon" style=""
             v-for="(item,index) in shoppingList" :key="index">
          <weapon style="width: 90%" @click.native="shoppinng(item,index)"
                  :res-data="item">
          </weapon>
        </div>
        <vacancy class="d-flex justify-content-center" v-if="shoppingList.length == 0"></vacancy>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>


    <!-- 立即购买 -->
    <div v-show="immediatelyShopping">
      <div style=""
           class="position-fixed pop-up z-index-100 py-5 immediatelyShopping ggbg1">
        <div class="pt-5 px-5 immediatelyShopping-box">
          <div class="immediatelyShopping-box-con">
            <div class="px-2 immediatelyShopping-box-con-img text-center">
              <img width="80%" :src="Shopping.cover" alt="">
            </div>
            <div class="text-center immediatelyShopping-box-con-dis">
              <div class="mb-3 text-bold" style="line-height: 24px">{{Shopping.name}}</div>
              <div class="mt-3">
                {{Shopping.dura_alias}}
              </div>
              <div class="mt-3">
                <el-input-number v-model="num" :min="1" :max="99"></el-input-number>
              </div>
              <div class="mt-3 flex justify-content-center" style="line-height:30px">
                <money :integral="false" color="#fff"></money>{{(Shopping.mall_integral*num).toFixed(2)}}
              </div>
            </div>
          </div>
          <div class="d-flex mt-5 justify-content-center">
            <div @click="ShoppingMoney">
              <bottom-bg>
                <div class="" style="font-size: 16px;color: #ffffff">
                  积分兑换
                </div>
              </bottom-bg>
            </div>
          </div>
          <div @click="immediatelyShopping = false" class="position-absolute"
               style="right: 20px;top: 20px">
            <div>
              <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
            </div>
          </div>

        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1"
           style="width: 100vw;height: 100vh;left: 0;top: 0;">
      </div>
    </div>
  </div>
</template>

<script>
  import bottomBg from "@/components/bottomBg";
  import weapon from "./common/weapon";
  import $api from '@/api/shopping/index'
  import $luckyType from '@/api/lucky/index'
  import {mapState} from 'vuex'
import sUserAgent from "@/assets/js/common.js";

  export default {
    name: "index",
    components: {
      bottomBg,
      weapon,
    },
    data() {
      return {
        // 弹出框
        immediatelyShopping: false,
        type: 1,
        // 选择数量
        num: 1,
        // 渲染商城列表
        shoppingList: [],
        // 翻页
        total: 0,
        pageSize: 0,
        // 价格区间
        min_bean: '',
        max_bean: '',
        // 排序
        sortSum: 1,
        // 弹出的武器信息
        Shopping: {},
        // 更具名称搜索
        search: '',
        search_dura_value: '',
        search_dura: ['无', '崭新出厂', '略有磨损', '久经沙场', '破损不堪', '战痕累累', '无涂装'],
        search_rarity_value: '',
        search_rarity: ['无', '消费级', '工业级', '军规级', '受限', '保密', '隐秘', '普通级', '高级', '非凡', '奇异', '卓越', '违禁', '探员:高级', '探员:卓越', '探员:非凡', '探员:大师'],
        getluckyType: [],
        getluckyTypeActive: false,

      }
    },
    computed: {
      ...mapState(['user_info']),
      sUserAgent() {
        return sUserAgent.sUserAgent();
      },
    },
    created() {

    },
    mounted() {
      this.init()
    },
    methods: {
      // 点击分类
      async getluckyTypeClick(item, index) {
        this.getluckyTypeActive = index
        this.type = item.id
        let {data} = await $api.getShopListType(item.id)
        this.shoppingList = data.data
        this.total = data.total
        this.pageSize = data.per_page

      },
      // 筛选
      filtrateMoney() {
        this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, this.search,)
      },
      // 增加搜索
      async searchName() {
        await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, this.search, this.search_dura_value, this.search_rarity_value)
      },
      async search_dura_value_click(item, index) {
        this.search_dura_value = index
        if (this.search_rarity_value !== '') {
          await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', index, this.search_rarity_value)
        } else {
          await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', index,)
        }
      },
      async search_rarity_value_click(item, index) {
        this.search_rarity_value = index
        if (this.search_dura_value !== '') {
          await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', this.search_dura_value, index)
        } else {
          await this.getShopList(this.sortSum, this.min_bean, this.max_bean, this.num, '', '', index,)
        }

      },
      unlimited(t) {
        this.search_rarity_value = t
        this.search_dura_value = t
        this.getShopList()
      },
      unlimitedWG(t) {
        this.search_rarity_value = t
        this.search_dura_value = t
        this.getShopList()
      },
      // 初始化
      init() {
        this.getShopList()
        this.getluckyTypeList()
      },
      // 点击打开购买窗口
      shoppinng(item) {
        document.getElementById('mysound').play()
        this.immediatelyShopping = true
        this.num = 1
        this.Shopping = item
      },
      // 获取商城列表
      async getShopList(sort = 1, min_bean = '', max_bean = '', page = 1, name = '', dura = '', rarity = '') {
        try {
          let {data, code} = await $api.getShopList(sort, min_bean, max_bean, page, name, dura, rarity,this.type)
          if (code == 500) return
          this.shoppingList = data.data
          this.total = data.total
          this.pageSize = data.per_page
        } catch (e) {
          console.log(e)
        }
      },
      // 获取商城武器分类
      async getluckyTypeList() {
        try {
          let {data} = await $luckyType.getluckyType()
          this.getluckyType = data
          this.type = data[0].id
        } catch (e) {
          console.log(e)
        }

      },
      // 排序
      sort(sum) {
        this.sortSum = sum
        this.getShopList(sum, this.min_bean, this.max_bean, 1)
      },
      // 购买
      async ShoppingIntegral() {
        try {
          document.getElementById('mysound').play()
          let {code, message} = await $api.getShopBuy({id: this.Shopping.id, num: this.num})
          if (code == 500) return
          if (code == 200) {
            this.$notify({
              title: message,
              type: 'success',
              position: 'top-left'
            });
            this.$store.dispatch('userInfo')
          }
        } catch (e) {
          console.log(e)
        }

        this.immediatelyShopping = false

      },
      async ShoppingMoney() {
        try {
          document.getElementById('mysound').play()
          let {code, message} = await $api.getShopexchange({id: this.Shopping.id, num: this.num})
          if (code == 500) return
          if (code == 200) {
            this.$notify({
              title: message,
              type: 'success',
              position: 'top-left'
            });
            this.$store.dispatch('userInfo')
          }
        } catch (e) {
          console.log(e)
        }
        this.immediatelyShopping = false

      },
      // 翻页
      currentChange(val) {
        this.getShopList(0, '', '', val)
      }
    }
  }
</script>

<style scoped lang="scss">
.shopping_index{
   @media (max-width: 1300px) {
    font-size: 14px;
  }
  .h3{
    width: 1720px;
    margin: 0 auto;
    height: 237px;
    background: url('../../assets/img/news/jifenshangcheng.jpg') no-repeat;
    @media (max-width: 1300px) {
      width: 100%;
      height: 200px;
      background-size: 1300px 190px;
      background-position: center;
    }
  }
}
  .f_shopping_class {
    width: calc(100% / 8);
    background-color: #22232E;
    font-size: 18px;
    img{
      width: 100px;
    }
    @media (max-width: 960px) {
      font-size: 16px;
      width: 25%;
      img{
        width: 50px;
      }
    }
  }
  .border.f_shopping_class {
    border: 1px solid #000 !important;
  }
  .border.f_shopping_class.active {
    border-bottom: 4px solid #F5C480 !important;
    color: #F5C480;
  }
  .shaixuan {
    @media (max-width: 960px) {
      overflow-x: scroll;
    }
    &::-webkit-scrollbar{
      height: 2px;
    }
  }
  .choice_bg{
    padding:10px;
    background: #22232E;
    .el-select{
      @media (max-width: 1300px) {
        width: 120px;
        border-radius: 6px !important;
      }
    }
  }
  .sort_btn{
    background: #292B3C;
  }
  .immediatelyShopping {
    z-index: 3;
    width: 600px;
    left: calc(50% - 300px);
    top: calc(25%);

    @media (max-width: 1200px) {
      width: 95%;
      left: 2.5%;

      &-box {
        padding-left: 10px !important;
        padding-right: 10px !important;

        &-con {
          &-img {
            width: 50%;
            margin: 0 auto;
          }

          & img {
            width: 100%;
          }

          &-dis {
            margin-left: 20px !important;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }
  }

  .weapon {
    width: calc(100% / 7);
    @media (max-width: 1600px) {
      width: calc(100% / 6);
    }
    @media (max-width: 1366px) {
      width: calc(100% / 5);
    }
    @media (max-width: 1200px) {
      width: calc(100% / 3);
    }
    @media (max-width: 960px) {
      width: 50%;
    }
  }

  .content {
    width: 90%;
    margin: auto;
    overflow: hidden;
    @media (max-width: 1366px) {
      width: 95%;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;

      & .weapon {
        @media (max-width: 1200px) {
          width: 50% !important;
        }
      }
    }
  }

  .icon-size {
    font-weight: bold;
    font-size: 14px !important;
  }

  .icon-zhuan {
    font-weight: bold;
    transform: rotate(180deg);
  }

</style>
