<template>
    <div class="my-welfare">
        <!-- <div class="jss1352">任务中心</div> -->
        <!-- <div class="MuiBox-root jss1442 jss1355">
            <div class="MuiBox-root jss1443 jss1356">
                <img :src="user_info.avatar" class="jss1357" />
                <div class="MuiBox-root jss1444" style="top: -11px; left: 50%; transform: translateX(-50%);"><img
                        src="https://img.zeroskins.com/level/zeroskins_head1.svg" style="width: 82px;"></div>
                <div class="MuiBox-root jss1445" style="left: 50%; transform: translateX(-50%);">
                    <div class="MuiBox-root jss1468 jss538" :style="`backgroundImage: url(${require(`@/assets/img/nav/${user_info.vip_level}.png`)})`">
                    </div>
                </div>
            </div>
            <div class="MuiBox-root jss1447"
                style="width: 60%; display: flex; flex-flow: column wrap; justify-content: center;">
                <div class="MuiBox-root jss1448 jss1358">{{user_info.name}}</div> -->
                <!-- <div class="MuiBox-root jss1449 jss1359">
                    <span>今日已领：</span><span style="color: rgb(233, 177, 14);">
                        <img src="/img/shandian_ceshi.2688a202.png" alt="" style="width: 12px; height: 12px;" />
                        0</span>
                </div> -->
            <!-- </div>
        </div> -->
        <!-- <div class="MuiBox-root jss2156" style="margin-top: 10px;">
            <div class="MuiBox-root jss2157 jss2047">APP专属任务</div>
            <div class="MuiBox-root jss2158 jss2049">更多任务，请前往APP查看<div class="MuiBox-root jss2159"><a
                        class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                        style="color: rgb(23, 180, 237); cursor: pointer; position: absolute; right: 10px; bottom: 10px;">立即下载</a>
                </div>
            </div>
        </div> -->
        <!-- <div class="MuiBox-root jss2137">
            <div class="MuiBox-root text-bold tjrw">推荐任务</div>
            <div class="MuiBox-root jss2052">
                <div class="MuiBox_root_box" v-for="(item,a) in tasksRecommendedList" :key="a">
                    <div class="MuiBox-root jss2145 text-bold">{{item.type_name}}</div>
                    <div class="MuiBox-root jss2146" style="margin: 5px 0px;font-size:12px;">{{item.name}}（<span class="text-orange">{{item.hasBean > item.threshold ? item.threshold : item.hasBean}}</span>/{{item.threshold}}）</div>
                    <div class="MuiBox-root jss2147 jss2050" style="display: flex;justify-content: space-between;">
                        <div class="MuiBox-root flex jss2148">
                            奖励：
                            <div class="flex"><money/><span class="text-orange" v-if="item.front_reward">保底{{item.min_reward}}</span></div>
                        </div>
                    </div>
                    <div class="flex justify-between">
                        <div></div>
                        <div class="MuiBox-root jss2149 common-btn common-btn-main">
                            <a  v-if="!(item.threshold>item.hasBean) && !item.has_get" @click="tapDraw(item)">点击领取</a>
                            <span v-else-if="!(item.threshold>item.hasBean) && item.has_get">已完成</span>
                            <a  v-else :href="item.type===2?'/FmysteryBox':item.type===1?'/':'/Flucky'">立即前往</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <el-dialog  custom-class="custom_dialog" title="" :close-on-click-modal="false" :visible.sync="operateVisible" top="30vh" append-to-body  width="300px">
            <div class="text-center padding-tb" style="color:#fff">恭喜您完成任务,领取到了<span class="text-orange">{{muVal}}U币</span></div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="operateVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {mapState} from 'vuex'
import $api from '@/api/activity.js'
export default {
    computed: {
        ...mapState(['user_info', 'steam_handle', 'login_top', 'login_is_show', 'is_show_nav_pc', 'is_show_nav_phone','login_top_is_xie_yi']),
    },
    data() {
        return {
            tasksRecommendedList:[],
            operateVisible:false,
            muVal:'',
        };
    },
    watch:{
        '$store.state.mission_state':{   //任务列表被操作则重新请求一次列表接口
            handler(val,oval){
                if(oval !== undefined){
                    this.taskGetList()
                }
            },
            immediate:true,
        }
    },
    methods: {
        taskGetList(){
           $api.recoList().then(res =>{
                this.tasksRecommendedList=res.data
            })
        },
        tapDraw(data){
           $api.consumePost({beans_id:data.id,type:data.type,by_type:1}).then(res =>{
                if(res.code===200){
                    this.$emit('drawTap')
                    this.operateVisible=true
                    this.$store.dispatch('userInfo')
                    this.muVal=res.data.reward_bean
                }
                this.taskGetList()
            })
        },
    }
}
</script>
<style scoped lang="scss">
.my-welfare {
    .MuiBox-root.jss2137 {
        margin-top: 10px;
        padding: 0 10px;
    }
    .tjrw {
        height: 45px;
background: rgba(0,185,212,.2);
border-radius: 6px;
font-size: 16px;
font-weight: bold;
color: #FFFFFF;
line-height: 45px;
padding-left: 20px;
    }
    .jss1352 {
        color: #C3C3E2;
        font-size: 18px;
        text-align: center;
        margin-bottom: 19px;
    }

    .jss1445 {
        width: 100%;
        bottom: 0px;
        position: absolute;
        text-align: center;
    }

    .jss1355 {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .jss1356 {
        width: 60px;
        position: relative;
        margin-right: 10px;
    }

    .jss1357 {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    .jss1444 {
        bottom: 0px;
        position: absolute;
        text-align: center;
    }

    .jss538 {
        width: 46px;
        height: 20px;
        margin: 0 auto;
        font-size: 12px;
        border-radius: 3px;
        background-size: 100% 100%;
        background-color: transparent !important;
        background-repeat: no-repeat;
    }

    .jss1358 {
        color: #C3C3E2;
        width: 100%;
        overflow: hidden;
        font-size: 16px;
        word-break: keep-all;
        line-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .jss1359 {
        color: #8D8DFE;
        display: flex;
        font-size: 14px;
        align-items: center;
        line-height: 26px;
    }
    .jss2049 {
    color: #C3C3E2;
    width: 100%;
    height: 108px;
    padding: 10px;
    position: relative;
    font-size: 14px;
    background: #050732;
    box-sizing: border-box;
    line-height: 20px;
    border-radius: 5px;
}
.jss2047 {
    color: #8D8DFE;
    font-size: 14px;
    line-height: 22px;
}
.jss2047 {
    color: #8D8DFE;
    font-size: 14px;
    line-height: 22px;
}
.jss2052 {
    color: #C3C3E2;
    width: 100%;
    height: 488px;
    // padding: 0 10px;
    font-size: 14px;
    box-sizing: border-box;
    overflow-y: auto;
    line-height: 20px;
    border-radius: 5px;
}
.MuiBox_root_box{
    padding:15px;
    border-radius: 8px;
    background: rgba(0,185,212,.2);
    margin-bottom: 10px;
    margin-top: 10px;
    .common-btn-main{
        font-size: 12px;
        line-height: 30px;
        margin-top:10px;
        padding: 0 10px;
        background: rgba(0,185,212,.2);
        border: none;
    }
}
}
</style>