<template>
  <div>
    <!-- 用户信息 -->
    <div class="head-use" :style="{ height: sUserAgent === 'phone' ? 'auto' : '80px' }">
      <div class="flex justify-between w1720" :style="{ padding: sUserAgent === 'phone' ? '10px 0 10px 0' : '' }">
        <div class="flex m-none width100per">
          <div class="cursor-pointer" style="padding: 17px 28px 15px 0;" @click="$router.push({ path: '/' })">
            <img height="50px" src="../assets/img/ADT/home/logo-max.png">
          </div>

          <!-- 顶部导航栏 -->
          <div class="head-navigation m-none nav_menu">
            <div class="flex" style="width: 100%;justify-content: space-between;">
              <div>
                <nav class="nav">
                  <div @click="$router.push({ name: 'SCGOindex' })"
                       class="nav-link-phone _a nuxt-link-exact-active nuxt-link-active" aria-current="page"
                       :class="{ 'active': $route.path === '/' }">
                    <div class="d-flex justify-center-center align-items-center hvr-wobble-skew">
                      <img v-if="$route.path === '/'" style="width: 20px;" src="../assets/img/ADT/home/box-a.png"
                           alt="">
                      <img v-else style="width: 20px;" src="../assets/img/ADT/home/box.png" alt="">
                      <span class="nav-link-title">宝箱</span>
                    </div>
                  </div>
                  <!-- FmysteryBox 对战 -->
                  <div @click="$router.push({ name: 'mysteryBox' })" class="nav-link-phone _a"
                       :class="{ 'active': $route.path === '/FmysteryBox' }">
                    <div class="d-flex justify-center-center align-items-center hvr-wobble-skew">
                      <img v-if="$route.path === '/FmysteryBox'" style="width: 20px;"
                           src="../assets/img/ADT/home/saolei-a.png" alt="">
                      <img v-else style="width: 20px;" src="../assets/img/ADT/home/saolei.png"/>
                      <span class="nav-link-title">竞技场</span>
                    </div>
                  </div>
                  <div @click="$router.push({ name: 'rollHome' })" class="nav-link-phone _a"
                       :class="{ 'active': $route.path === '/FrollHome' }">
                    <div class="d-flex justify-center-center align-items-center hvr-wobble-skew">
                      <img v-if="$route.path === '/FrollHome'" style="width: 20px;"
                           src="../assets/img/ADT/home/roll-a.png" alt="">
                      <img v-else style="width: 20px;" src="../assets/img/ADT/home/roll.png" alt="">
                      <span class="nav-link-title">ROLL房</span>
                    </div>
                  </div>
                  <div @click="$router.push({ name: 'lucky' })" class="nav-link-phone _a"
                       :class="{ 'active': $route.path === '/Flucky' }">
                    <div class="d-flex justify-center-center align-items-center hvr-wobble-skew">
                      <img v-if="$route.path === '/Flucky'" style="width: 20px;" src="../assets/img/ADT/home/zm-a.png"
                           alt="">
                      <img v-else style="width: 20px;" src="../assets/img/ADT/home/zm.png" alt="">
                      <span class="nav-link-title">追梦</span>
                    </div>
                  </div>
                  <div @click="$router.push({ name: 'Battlefield' })" class="nav-link-phone _a"
                       :class="{ 'active': $route.path === '/battlefield' }">
                    <div class="d-flex justify-center-center align-items-center hvr-wobble-skew">
                      <img v-if="$route.path === '/battlefield'" style="width: 20px;"
                           src="../assets/img/ADT/home/saolei-a.png" alt="">
                      <img v-else style="width: 20px;" src="../assets/img/ADT/home/saolei.png"/>
                      <span class="nav-link-title">拆弹战场</span>
                    </div>
                  </div>
                  <!--                  <div @click="$router.push({ name: 'tongtianTower' })" class="nav-link-phone _a"-->
                  <!--                       :class="{ 'active': $route.path === '/tongtianTower' }">-->
                  <!--                    <div class="d-flex justify-center-center align-items-center hvr-wobble-skew">-->
                  <!--                      <img v-if="$route.path === '/tongtianTower'" style="width: 20px;"-->
                  <!--                           src="../assets/img/ADT/home/saolei-a.png" alt="">-->
                  <!--                      <img v-else style="width: 20px;" src="../assets/img/ADT/home/saolei.png"/>-->
                  <!--                      <span class="nav-link-title">宝莲灯</span>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <timeBoxTitle></timeBoxTitle>
                </nav>
              </div>
              <div class="head-top-info d-flex align-items-center ">
                <!--                <div class="loginOractivity _a" @click="tologin()">-->
                <!--                  <div>活动大厅</div>-->
                <!--                </div>-->
                <div class="loginOr _a" v-if="login_is_show || !user_info.name" @click="tologin()">
                  <div>登录/注册</div>
                </div>
                <div class="flex" v-else>
                  <div class="info-avatar position-relative" style="margin-right: 10px"
                       @click="$router.push({ path: '/user/user?index=1' })">
                    <img class="avatar-box" :style="{ width: sUserAgent === 'phone' ? '40px' : '55px' }"
                         :src="user_info.avatar" alt="">
                    <span class="header-user-name-vip position-absolute"><img
                        :src="`${require(`@/assets/img/nav/${user_info.vip_level}.png`)}`" alt=""></span>
                  </div>
                  <div class="mx-2" style="" @click="$router.push({ path: '/user/recharge' })">
                    <div class="info-text text-overflow-1 cursor-pointer  d-flex align-items-center text-white "
                         style="font-size: 14px;">
                      <div>
                        <div class="d-flex" style="font-size: 16px;">

                          {{ user_info.name }}
                        </div>
                        <div class="d-flex" style="color:#F9C545;">
                          <money :width="sUserAgent === 'phone' ? '20px' : '30px'"/>
                          {{
                            user_info.bean ||
                            '0.00'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-none">
                    <i class="el-icon-s-unfold h2" @click="mobile_click_nav = !mobile_click_nav"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pc-none head_mb align-items-center width100per">
          <button class="common-btn menu" @click="phoneShow = true"></button>
          <a href="/" class="logo nuxt-link-exact-active nuxt-link-active" aria-current="page">
            <img src="@/assets/img/ADT/home/logo-max.png">
          </a>
          <div class="phone-top-right">
            <div class="money _a" v-if="login_is_show || !user_info.name"
                 @click="$router.push({ path: '/user/recharge' })">
              <div><img src="@/assets/img/ADT/home/phone/money.gif"></div>
            </div>
            <div class="loginOr _a" v-if="login_is_show || !user_info.name" @click="tologin()">
              <div>登录/注册</div>
            </div>
          </div>

        </div>
        <div v-if="user_info.name" class="d-flex align-items-center pc-none"
             style="color:#F9C545;margin-right: 30px;white-space: nowrap;">
          <div @click="$router.push({ path: '/user/recharge' })">
            <img style="height: 30px;vertical-align: middle;margin-right: 5px;"
                 src="@/assets/img/ADT/home/phone/money.gif"
                 alt="">
          </div>
          <div>
            <money class="money-box"/>
            {{
              user_info.bean ||
              '0.00'
            }}
          </div>
        </div>

      </div>
    </div>

    <!-- 顶部滚动饰品 -->
    <!-- :class="!isShowSwiperOff ? '' : 'swiper'" -->
    <div class="row m-0 flex-wrap swiper-container" :class="!isShowSwiperOff ? '' : 'swiper'">
      <div v-if="isShowSwiperOff" class="swiper-list row m-0 p-0">
        <div class="swiper-list-content d-flex position-relative" @click="warp($event)">
          <div class="warp overflow-hidden">
            <ul class="" style="display: flex;">
              <!-- 循环 -->
              <li v-for="(item, index) in listData" :key="index + item"
                  class="position-relative swiper-list-content-box"
                  :class="flag && !index && 'flaganimation'">
                <a :href="item.type | openUrl(item)"
                   class="d-block position-relative swiper-list-content-box-item overflow-hidden">
                  <div class="bg-size-100 bg-repeat w-100">
                    <div class=" px-1 d-flex position-relative justify-content-center">
                      <img style="z-index: 2;width: 50%;top: 35px;" :src="item.cover" alt=""
                           class="position-relative m-auto swiper-list-content-box-item-phone-img img-position">
                      <!-- <img class="position-absolute z-index-1" v-if="item.lv_bg_image" :src="item.lv_bg_image" alt=""> -->
                      <img class="position-absolute z-index-1 img-height"
                           :src="require(`../assets/img/ADT/common/bg4/${item.lv}.png`)" alt="">
                      <!-- {{item.lv}} -->
                    </div>
                    <div class=" position-relative img-position-text">
                      <div class="mt-1 px-2 text-overflow-1 text-center text-size-sty">
                        {{ item.name }}
                      </div>

                    </div>
                  </div>
                </a>
                <a :href="item.type | openUrl(item)"
                   class="position-relative swiper-list-content-box-item overflow-hidden">
                  <div class="bg-size-100 bg-repeat w-100  d-flex justify-content-center align-items-center flex-column"
                       style="height: 70%">
                    <div class="align-items-center d-flex position-relative mb-2">
                      <img class="rounded-pill" width="50px" height="50px" :src="item.get_user.avatar" alt="">
                    </div>
                    <div class="ml-2">
                      <div class=" fon14 text-overflow-1 ">{{ item.get_user.name }}</div>
                    </div>
                  </div>
                </a>
              </li>
              <!-- end循环 -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录窗口 -->
    <el-dialog custom-class="login_egister" :show-close="false" :close-on-press-escape="false"
               :close-on-click-modal="false" :visible.sync="login_show" :top="sUserAgent === 'phone' ? '10vh' : '0vh'"
               :width="sUserAgent === 'phone' ? '' : '557px'" style="overflow: hidden;">
      <div class="login login-nav">
        <div class="text-center text-white font-weight-bold h2" style="line-height: 70px;z-index:2;">
          <span style="opacity:0">{{ tabLoginRFName }}</span>
          <img style="width: 100%;" src="../assets/img/ADT/home/logo.png" alt="">
        </div>
        <div class="p-5" style="padding-top: 0px !important;" :style="`${tabLoginRF == 'login' ? '' : ''}`">
          <div class="login-head">
            <div @click="loginUser('login')" class="nav-li" :class="tabLoginRF == 'login' ? ' active' : 'common-btn'">登录
            </div>
            <div @click="loginUser('register')" class="nav-li"
                 :class="tabLoginRF == 'register' ? ' active' : 'common-btn'">注册
            </div>
            <!-- <div v-if="tabLoginRFName === '找回密码'" class="nav-li active">忘记密码</div> -->
          </div>
          <div v-if="tabLoginRF === 'login'">
            <!-- //登录 -->
            <div class="common-form-input mt-3">
              <input v-model="loginForm.mobile" class="text-white d-block w-100" type="text"
                     onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="11" placeholder="请输入手机号">
            </div>
            <div v-if="loginForm.steam_data != '' || code == true" class="d-flex align-items-center mt-3">
              <div class="common-form-input">
                <input v-model="loginForm.verify" class="text-white d-block code-phone w-100" type="text"
                       onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="6" placeholder="请输入短信验证码">
              </div>
              <div @click="getCode" :class="getCodeSeond != '获取验证码' ? 'cursor-not' : 'cursor-pointer'"
                   class="text-center bg-bg-2 px-1 input-code color-yellow  code-phone-size">
                <div class="">
                  {{ getCodeSeond }}
                </div>
              </div>
            </div>
            <div v-if="(loginForm.steam_data == '' && code == false)"
                 class="d-flex border-dark-brown align-items-center mt-3">
              <div class="common-form-input">
                <input @keyup.enter="login" v-model="loginForm.pass" class="text-white d-block w-100" maxlength="30"
                       type="password" placeholder="请输入密码">
              </div>
            </div>
            <div class="d-none">
              <input v-model="form.steam_data" type="text">
            </div>
          </div>
          <!-- 注册 -->
          <div v-else>
            <div class="common-form-input mt-3">
              <input v-model="form.mobile" class="text-white d-block w-100" type="text"
                     onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="11" placeholder="请输入手机号">
            </div>
            <div v-if="tabLoginRF != 'login' || form.steam_data != '' || code == true"
                 class="d-flex align-items-center mt-3">
              <div class="common-form-input">
                <input v-model="form.verify" class="text-white d-block code-phone w-100" type="text"
                       onkeyup="this.value=this.value.replace(/\D/g,'')" maxlength="6" placeholder="请输入短信验证码">
              </div>
              <div @click="getCode" :class="getCodeSeond != '获取验证码' ? 'cursor-not' : 'cursor-pointer'"
                   class="text-center bg-bg-2 px-1 input-code color-yellow  code-phone-size">
                <div class="">
                  {{ getCodeSeond }}
                </div>
              </div>
            </div>
            <div v-if="(form.steam_data == '' && code == false) || tabLoginRF == 'register'"
                 class="d-flex border-dark-brown align-items-center mt-3">
              <div class="common-form-input">
                <input @keyup.enter="login" v-model="form.pass" class="text-white d-block w-100" maxlength="30"
                       type="password" placeholder="请输入密码">
              </div>
            </div>
            <div v-if="tabLoginRF == 'register'" :class="tabLoginRF == 'register' ? 'w-100' : ''"
                 class="d-flex border-dark-brown align-items-center mt-3">
              <div class="common-form-input">
                <input v-model="form.invite" class="text-white d-block w-100" type="text"
                       placeholder="请输入邀请码（没有可以不填）">
              </div>
            </div>
            <div class="d-none">
              <input v-model="form.steam_data" type="text">
            </div>
          </div>

          <div class="d-flex align-items-center cursor-pointer mt-2 xieyi"
               style="font-size: 14px;line-height:30px ;color: #ffffff;">
            <div class="flex" style="align-items: center;flex-wrap: wrap;" @click="agreementClick">
              <!-- <i class="iconfont text-white icon-danxuan" style="font-size: 18px"
                :class="agreement ? 'color-yellow' : ''"></i> -->
              <img style="width: 20px;height: 20px;vertical-align: middle;" v-if="!agreement"
                   src="../assets/img/ADT/home/no-select.png" alt="">
              <img style="width: 20px;height: 20px;vertical-align: middle;" v-else
                   src="../assets/img/ADT/home/yes-select.png" alt="">
              <span style="margin-left: 5px;">确认已满十八周岁,并同意</span>
              <span class="color-yellow" @click="ligin_zhuce(1)">《用户协议》</span>
              <span class="color-yellow" @click="ligin_zhuce(2)">《协议与条款》</span>
              <span class="color-yellow" @click="ligin_zhuce(3)">《反洗钱协议》</span>
              ，承诺理性消费
            </div>
            <!-- <div  class="color-yellow"  @click="ligin_zhuce(1)">《用户协议》</div>
            <div  class="color-yellow"  @click="ligin_zhuce(2)">《协议与条款》</div> -->
          </div>

          <div class="login-btn _a">
            <a @click="login">{{ tabLoginRFName }}</a>
            <div @click="loginUser('forget')" class="forget-the-password _a">
              忘记密码?
            </div>
            <div class="login-btn-footer flex align-items-center justify-content-center">
              <div v-if="tabLoginRF == 'login'" class="h6 font-weight-bold switchingLogin text-white cursor-pointer">
                <img style="width:20px;height:20px;vertical-align: middle;margin-right: 5px;"
                     src="@/assets/img/ADT/home/lock.png" alt="">
                <span style="font-size: 16px;color:#fff;font-weight: 400;" v-if="code == false"
                      @click="code = true">验证码登录</span>
                <span style="font-size: 16px;color:#fff;font-weight: 400;" v-if="code == true"
                      @click="code = false">密码登录</span>
              </div>
              <div style="margin: 0 5px;"></div>
              <div v-if="tabLoginRF == 'login'" class="h6 font-weight-bold switchingLogin text-white cursor-pointer">
                <img style="width:20px;height:20px;vertical-align: middle;margin-right: 5px;" v-if="code == false"
                     src="@/assets/img/ADT/home/steam.png" alt="">
                <span style="font-size: 16px;color:#fff;font-weight: 400;" v-if="code == false"
                      @click="steamLogin">Steam登录</span>
              </div>
              <!-- <div v-if="tabLoginRF == 'register' || tabLoginRFName === '找回密码'" class="h6 _a" @click="loginUser('login')">
              已有帐号！<br />
              <span class="text-white cursor-pointer">登录</span>
            </div> -->
            </div>
          </div>

          <!-- <div v-if="tabLoginRF == 'login'"
            class="mx-3 d-flex justify-content-center align-items-center mt-3 rounded p-3 bg-bg-2">
            <div class="text-center">
              <div class="mb-3">第三方登录</div>
              <div @click="steamLogin" class="mb-md-2 mb-1">
                <img src="../assets/img/steam.png" style="width:3rem" alt="">
              </div>
              <div class="">
                steam无法登录点击这里！
                <br>
                <br>
                如有问题联系QQ：{{ web_info.qq_group_number }}
              </div>
            </div>
          </div> -->
          <div @click="off_login" class="position-absolute hvr-wobble-skew" style="right: 15px;top: 15px;z-index:8;">
            <div>
              <img src="../assets/img/ADT/home/close.png" style="width: 25px;" alt="">
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 手机 顶部导航栏 -->
    <el-drawer title="" direction="ltr" custom-class="head-navigation-mb" :withHeader="false" size="75%"
               :visible.sync="phoneShow">
      <div class="head-navigation-mb position-relative">
        <div class="navigation-head flex justify-between">
          <div>
            <h1 class="logo"></h1>
          </div>
          <div @click="phoneShow = false">
            <img width="30px" src="../assets/img/news/clouse.png">
          </div>
        </div>
        <!-- <div class="navigation-info" v-if="user_info.avatar">
          <div class="navigation-foot-user flex justify-between">
            <div class="flex">
              <div class="px-1 position-relative" style="margin-right: 10px"
                @click="$router.push({ path: '/user/user' }), phoneShow = false">
                <el-avatar :size="50" :src="user_info.avatar"></el-avatar>
                <span class="header-user-name-vip">
                  <img :src="`${require(`@/assets/img/nav/${user_info.vip_level}.png`)}`" alt="">
                </span>
              </div>
              <div @click="$router.push({ path: '/user/recharge' }), phoneShow = false"
                class="text-overflow-1 cursor-pointer  d-flex align-items-center text-white " style="font-size: 14px;">
                <div>
                  <div class="d-flex " style="font-size: 12px;line-height: 20px;">
                    <money color="#FFF" /> {{ user_info.bean || '0.00' }}
                  </div>
                  <div class="d-flex" style="font-size: 12px;line-height: 20px;margin-top:6px">
                    <money :integral="false" color="#FFF" /> {{ user_info.integral || '0.00' }}
                  </div>
                </div>
              </div>
            </div>
            <button class="info-b-r common-btn margin-top-xs"
              @click="$router.push({ path: '/user/recharge' }), phoneShow = false">
              <p class="txt">充值<i class="el-icon-plus"></i></p>
            </button>
          </div>
        </div> -->
        <div class="navigation-main">
          <nav class="nav">
            <!-- <div class="foot-bottom-img" style="margin-top:10px;margin-bottom: 10px;" @click="csactive()">
              <img src="../assets/img/news/banner2.jpg"  width="100%"/>
            </div> -->
            <div
                class="luck_word"
            >
              Hi，朋友，祝你好运！
            </div>
            <!-- <div @click="$router.push({name:'SCGOindex'}),phoneShow=false" :class="{'active':$route.path==='/'}" class="nav-link-phone _a nuxt-link-exact-active nuxt-link-active" aria-current="page">
            <i class="iconfont icon-jk4"></i>
            <span class="nav-link-title">盲盒</span>
          </div> -->
            <div @click="$router.push({ path: '/' }), phoneShow = false" class="nav-link-phone _a">
              <!-- <i class="iconfont icon-icon_fuli"></i> -->
              <img style="width: 20px;margin-right: 10px;" src="../assets/img/ADT/home/phone/home.png" alt="">
              <span class="nav-link-title">盲盒首页</span>
            </div>
            <div @click="$router.push({ name: 'outOfTheFree' }), phoneShow = false" class="nav-link-phone _a">
              <!-- <i class="iconfont icon-icon_fuli"></i> -->
              <img style="width: 20px;margin-right: 10px;" src="../assets/img/ADT/home/phone/Free2.png" alt="">
              <span class="nav-link-title">免费宝箱</span>
            </div>
            <!--            <div @click="$router.push({ path: '/user/csactive' }), phoneShow = false" class="nav-link-phone _a">-->
            <!--              &lt;!&ndash; <i class="iconfont icon-icon_fuli"></i> &ndash;&gt;-->
            <!--              <img style="width: 20px;margin-right: 10px;" src="../assets/img/ADT/home/phone/hd.png" alt="">-->
            <!--              <span class="nav-link-title">活动大厅</span>-->
            <!--            </div>-->
            <div @click="$router.push({ name: 'rollHome' }),phoneShow = false" class="nav-link-phone _a">
              <img style="width: 20px;margin-right: 10px;" class="opacity" src="../assets/img/ADT/home/phone/roll2.png"
                   alt="">
              <span class="nav-link-title">ROLL房</span>
            </div>
            <!-- <div @click="$router.push({ path: '/user/recharge' }), phoneShow = false" class="nav-link-phone _a">
              <money />
              <span class="nav-link-title">充值</span>
            </div> -->
            <!-- <div @click="showCDK" class="nav-link-phone _a">
              <img style="width: 20px;margin-right: 10px;" :src="require('@/assets/img/ADT/home/cdk-icon.png')" alt="">
              <span class="nav-link-title">CDK</span>
            </div> -->
            <hr class="hr_nav">
            <div @click="$router.push({ name: 'mysteryBox' }),phoneShow = false" class="nav-link-phone _a">
              <img style="width: 20px;margin-right: 10px;" class="opacity" src="../assets/img/ADT/home/phone/saolei.png"
                   alt="">
              <span class="nav-link-title">竞技场</span>
            </div>
            <div @click="$router.push({ path: '/Flucky' }),phoneShow = false" class="nav-link-phone _a">
              <img style="width: 20px;margin-right: 10px;" class="opacity" src="../assets/img/ADT/home/phone/zm.png"
                   alt="">
              <span class="nav-link-title">追梦</span>
            </div>

            <div @click="$router.push({ name: 'Battlefield' }),phoneShow = false" class="nav-link-phone _a">
              <img style="width: 20px;margin-right: 10px;" class="opacity" src="../assets/img/ADT/home/phone/saolei.png"
                   alt="">
              <span class="nav-link-title">拆弹</span>
            </div>
            <hr class="hr_nav">
            <!-- <div v-if="!filterDomain()" @click="$router.push({ path: '/' }), phoneShow = false" class="nav-link-phone _a">
              <img style="width: 20px;margin-right: 10px;" src="../assets/img/ADT/home/qq-icon.png" alt="">
              <span class="nav-link-title">QQ群</span>
            </div> -->
            <div v-if="!filterDomain()" @click="showQQImg"
                 class="QQ-container nav-link _a">
              <img style="width: 20px;margin-right: 10px;" class="opacity"
                   :src="require('@/assets/img/ADT/home/phone/QQ.png')" alt=""/>
              <span class="nav-link-title">QQ群</span>
              <!-- QQ -->
              <div v-if="isQQShow" class="QQ-pop-up">
                <p class="p1">QQ群</p>
                <p class="p2">619342798</p>
                <img src="../assets/img/ADT/common/QQ.png" alt="">
                <p class="p3">其它相关信息</p>
              </div>
            </div>
            <div @click="keFu(), phoneShow = false" class="nav-link-phone _a">
              <!-- <img style="width: 20px;margin-right: 5px;" src="../assets/img/ADT/home/kefu-icon.png" alt=""> -->
              <img style="width: 20px;margin-right: 10px;" class="opacity_1" src="../assets/img/ADT/home/phone/kefu.png"
                   alt="">
              <span class="nav-link-title">客服</span>
            </div>
            <div v-if="showApp()" @click="$router.push({ name: 'appDownload' }), phoneShow = false"
                 class="nav-link-phone _a">
              <img style="width: 20px;margin-right: 10px;" class="opacity_1" src="../assets/img/ADT/home/phone/APP.png"
                   alt="">
              <span class="nav-link-title">APP下载</span>
            </div>

            <!-- <div @click="$router.push({ name: 'compound' }), phoneShow = false"
              :class="{ 'active': $route.path === '/Fcompound' }" class="nav-link-phone _a">
              <i class="iconfont icon-a-ziyuan22"></i>
              <span class="nav-link-title">汰换升级</span>
            </div> -->
            <!-- <div @click="$router.push({ name: 'welfare' }), phoneShow = false" class="nav-link-phone _a">
              <i class="iconfont icon-taobaoketuiguang"></i>
              <span class="nav-link-title">推广分红</span>
            </div> -->
            <!-- <div @click="$router.push({ name: 'mysteryBox' }), phoneShow = false"
              :class="{ 'active': $route.path === '/FmysteryBox' }" class="nav-link-phone _a">
              <i class="iconfont icon-a-ziyuan31"></i>
              <span class="nav-link-title">决战沙场</span>
            </div> -->
            <!-- <div @click="$router.push({ name: 'memberVip' }), phoneShow = false" class="nav-link-phone _a">
              <i class="iconfont icon-huiyuan1"></i>
              <span class="nav-link-title">会员福利</span>
            </div> -->
            <!-- <div @click="$router.push({ name: 'lucky' }), phoneShow = false"
              :class="{ 'active': $route.path === '/Flucky' }" class="nav-link-phone _a">
              <i class="iconfont icon-a-ziyuan41"></i>
              <span class="nav-link-title">追梦</span>
            </div> -->

            <!-- <div @click="$router.push({name:'rollHome'}),phoneShow=false" :class="{'active':$route.path==='/FrollHome'}" class="nav-link-phone _a">
            <i class="iconfont icon-a-ziyuan51"></i>
            <span class="nav-link-title">ROLL</span>
          </div> -->
            <!-- <div class="nav-link-phone _a">
            <i class="iconfont icon-qunliao"></i>
            <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=grJKzIvrxvv0Qn00Ow6lBvXO018UV66R&jump_from=webapi&authKey=Iy+xe2yB2sNcAJCyXosajli9sU5b63P//aiiqhJKcIXs7j902c/n7xYYX/wTyNwx"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="UPCSGO交流8群" title="UPCSGO交流8群"></a>
          </div> -->
            <!-- <a
              href="/active/help/10" :class="{ 'active': $route.path === '/Fshopping' }" class="nav-link-phone _a">
              <i class="iconfont icon-a-ziyuan61"></i>
              <span class="nav-link-title">合规声明</span>
            </a> -->
            <!-- <div @click="$router.push({ name: 'shopping' }), phoneShow = false"
              :class="{ 'active': $route.path === '/Fshopping' }" class="nav-link-phone _a">
              <i class="iconfont icon-a-ziyuan61"></i>
              <span class="nav-link-title">积分商城</span>
            </div> -->
            <!-- <div @click="keFu(),phoneShow=false"  class="nav-link-phone _a">
            <span class="nav-link-title">在线客服</span>
          </div> -->
          </nav>
        </div>
        <div class="navigation-foot">
          <div class="foot_outLogin">
            <div class="foot_outLogin_btn" @click="loginOut">退出登录</div>
          </div>
        </div>

      </div>

    </el-drawer>

    <!--  公告  -->
    <div class="swiper-top d-none">
      <div class="pr-1">
        <img style="width: 12px" src="../assets/img/laba.png" alt="">
      </div>
      <marquee :scrollamount="scrollamount" class="swiper-top-marquee" width="100%" behavior="scroll">
        {{ web_info.site_bulletin }}
      </marquee>
    </div>
    <!-- 新人红包 -->
    <el-dialog custom-class="login_redBao" :show-close="false" :close-on-press-escape="false"
               :close-on-click-modal="false" :visible.sync="login_red_show" top="5vh">
      <i class="el-icon-circle-close" @click="login_red_show = false"></i>
      <div class="text-num-text">
        恭喜获得
      </div>
      <div class="text-num">
        {{ user_info.bean }}U币
      </div>
      <div class="redBao_btn _a" @click="login_red_show = false, $router.push({ path: '/FoutOfTheFree' })">
      </div>
    </el-dialog>
    <huodong :show.sync="ActiveShow"></huodong>
    <silde ref="silde" @openActivity="tologin()"></silde>
  </div>
</template>
<!-- 埋点点击js文件 -->
<script type="text/javascript" src="https://down.jiamianjia.com/js/comm.js">
</script>
<script>
import {getToken} from '@/utils/auth'
// import vueSeamlessScroll from 'vue-seamless-scroll'
import {setToken, setExpires} from '@/utils/auth'
import $api from '@/api/login.js'
import $api_history from '@/api/CSGO/index.js'
import {mapState} from 'vuex'
import sUserAgent from '@/assets/js/common.js'
import huodong from './huodong'
import silde from './silde.vue'
import timeBoxTitle from '@/common/timeBoxTitle'

export default {
  components: {
    // vueSeamlessScroll,
    huodong,
    silde,
    timeBoxTitle
  },
  data() {
    return {
      flag: false,
      ios: false,
      domain_url: '',
      //注册成功后显示
      login_red_show: false,
      isRegisterShow: false,
      urlCode: "",
      captchaKey: '',
      phoneShow: false,
      // 公告
      scrollamount: 6,
      // 数组长度长点 无限循环
      listData: [],
      // 无限循环
      classOption: {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 15, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      loginRegisterShow: false,
      tabLoginRF: 'login',
      getCodeSeond: '获取验证码',
      code: false,
      agreement: false,
      form: {
        mobile: '',
        pass: "",
        // name: '',
        verify: '',
        invite: 'UPCSGO',
        steam_data: '',
        password: '',
        bd_vid: '',
        captcha: '',
        code: true
      },
      loginForm: {
        mobile: '',
        pass: "",
        // name: '',
        verify: '',
        invite: 'UPCSGO',
        steam_data: '',
        password: '',
        bd_vid: '',
        captcha: '',
        code: true
      },
      // 开箱状态
      openBoxState: {
        openBox: 0,
        userSum: 0,
        onLine: 0,
        pkSum: 0
      },
      ActiveShow: false,
      checked: false,
      isShowSwiperOff: false,
      isQQShow: false,
    }
  },
  filters: {
    openUrl(ty, item) {
      switch (ty) {
        case 1: // 开箱
          return '/active/' + item.box_id;
        case 2:// 福利箱
          return '/active/outOfTheFree/' + item.box_id;
        case 3: // 盲盒对战
          return '/mysteryBox'
        case 4: // 幸运开箱
          return '/lucky';
        case 5: // 饰品商城
          return '/shopping';
        case 6: // 夺宝
          return '/';
        case 7: // 装备合成
          return '/compound';
      }
    }
  },
  created() {
    this.domain_url = window.location.host;
    let invite = this.$route.query.ref_code
    if (invite) {
      this.form.invite = invite
      if (this.user_info) {
        this.loginRegisterShow = false
      } else {
        this.loginRegisterShow = true
      }
    }
    this.init()
    // this.getCaptcha();
  },
  computed: {
    ...mapState(['user_info', 'steam_handle', 'login_is_show', 'login_top', 'login_register', 'web_info']),
    tabLoginRFName: function () {
      if (this.tabLoginRF == 'login') {
        return '登录'
      } else if (this.tabLoginRF == 'register') {
        return '注册'
      }
      return '找回密码'
    },
    login_show() {
      return this.loginRegisterShow || this.login_top
    },
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },

    domain_other() {
      let domain_url = window.location.host;
      return ['upcsgo.jiamianjia.com', 'youxi007.upcsgo.com'].includes(domain_url) ? true : false
    },

  },
  watch: {
    login_register() {
      this.tabLoginRF = 'register'
    },
    phoneShow: {
      handler(val) {
        if (!val) {
          this.isQQShow = false
        }
      }
    }
  },
  mounted() {
    // 获取用户的注册验证码
    let codeRef = window.sessionStorage.getItem('codeRef')
    if (codeRef) {
      this.form.invite = codeRef
    }
    let ua = window.sessionStorage.getItem('ua')
    if (ua == 'phone') {
      this.scrollamount = 2
    }
    this.isIos();

  },
  methods: {
    showApp() {
      return sUserAgent.AppLimit()
    },
    showQQImgPc() {
      return this.sUserAgent === 'phone' ? this.isQQShow = true : false
    },
    showQQImg() {
      this.isQQShow = !this.isQQShow;
    },
    showCDK() {
      this.$refs.silde.showCDK()
    },
    // 过滤域名
    filterDomain() {
      if ([`upcsgo.rzxiyou.cn`, `upcsgo.rzxyxx.cn`, `upcsgo.xinghouse.cn`, `upcsgo.022net.org.cn`].includes(window.location.host)) {
        return true;
      } else {
        return false;
      }
    },
    //退出登录
    loginOut() {
      this.$store.commit("LOGIN_IS_SHOW", true)
      this.$store.dispatch('removeInfo')
      this.phoneShow = false
      this.$router.push('/')
    },
    isIos() {
      var browser = this.browser()
      if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
        this.ios = true;
      }
    },
    browser() {
      var browser = {
        versions: function () {
          var u = navigator.userAgent;
          return { //移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
          };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      }
      return browser;
    },
    // 手机下载
    downloadApp() {
      if (!this.ios) {
        window.location = 'https://www.upcsgo.com/android.apk?v=1';
      } else {
        window.location = 'https://www.upcsgo.com/ios.mobileconfig';
      }
    },
    // 跳转活动页面
    async csactive() {
      let token = await getToken()
      if (!token) {
        this.phoneShow = false
        return this.$store.commit("LOGIN_TOP", true)
      }
      if (this.sUserAgent == "phone") {
        this.phoneShow = false
        this.$router.push("/user/csactive");
      } else {
        this.ActiveShow = true;
      }
    },
    tologin() {
      this.login_is_show_pup = false
      if (!getToken()) {
        this.$store.commit("LOGIN_TOP", true)
      } else {
        this.$store.commit("LOGIN_IS_SHOW", false)
        this.$EventBus.$emit('showHD')

      }
    },
    focus() {//获取焦点的时候进行触发
      window.addEventListener('resize', () => {
        if (document.activeElement.tagName == 'INPUT') {
          window.setTimeout(() => {
            document.activeElement.scrollIntoViewIfNeeded();
          }, 100);
        }
      })
    },
    blur() {//失去焦点的时候，进行触发
      var u = navigator.userAgent;
      if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {//安卓手机
        this.$refs.input.scrollIntoView(false);
      } else {
        window.scroll(0, 0);//苹果
      }
    },
    isShowSwiper() {
      this.isShowSwiperOff = !this.isShowSwiperOff

    },
    keFu() {
      this.isRegisterShow = false;
      window.open('/chatlink.html', "_blank");
    },
    async init() {

      let baidu_url_bd_vid = window.location.href
      let index = baidu_url_bd_vid.indexOf('bd_vid')
      let isConnect = baidu_url_bd_vid.indexOf('isConnect')   //神马推广
      if (index > -1) {
        window.localStorage.setItem('baidu_url_bd_vid', baidu_url_bd_vid)
      }
      window.localStorage.setItem('shenma_isConnect', isConnect > -1 ? baidu_url_bd_vid : '')
      this.form.steam_data = this.steam_handle
      await this.getBoxHistory()
      await this.steamInit()
      setTimeout(() => {
        this.isShowSwiperOff = true
      }, 100)
      this.sumState()
    },
    sumState() {
      this.openBoxState.userSum = 10050 + Math.floor(Math.random() * 100)
      this.openBoxState.onLine = 500 + Math.floor(Math.random() * 100)
      this.openBoxState.openBox = 100500 + Math.floor(Math.random() * 100)
      this.openBoxState.pkSum = 10000 + Math.floor(Math.random() * 100)
      let i = 0
      this.time = setInterval(() => {
        if (Math.floor(Math.random() * 10) > 3) {
          setTimeout(() => {
            this.openBoxState.openBox += Math.floor(Math.random() * 2050)
            this.openBoxState.onLine += Math.floor(Math.random() * 50)
          }, 500)
          this.openBoxState.userSum += Math.floor(Math.random() * 500)
          this.openBoxState.pkSum += Math.floor(Math.random() * 200)
        } else {
          setTimeout(() => {
            this.openBoxState.openBox -= Math.floor(Math.random() * 2050)
            this.openBoxState.onLine -= Math.floor(Math.random() * 50)
          }, 1500)
          this.openBoxState.userSum -= Math.floor(Math.random() * 250)
          this.openBoxState.pkSum -= Math.floor(Math.random() * 100)
        }
        i++
        if (i === 50) {
          clearInterval(this.time)
          this.sumState()
        }
      }, 2000)
    },
    // steam 提示登录
    steamLogin() {
      this.$confirm('打开加速器，跳转更快捷呦！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        window.open("https://api.mmcsgo.com/api/steam/login", '_blank')
      }).catch(() => {

      });
    },
    // setam登录
    steamInit() {
      let redirectedFrom = this.$route.fullPath.split('?')[1]
      setTimeout(() => {
        if (this.$route.fullPath.length > 300) {
          $api.getSteamHandle(redirectedFrom).then(res => {
            if (res.code == 422) {
              this.$store.commit("STEAM_HANDLE", res.data)
              this.form.steam_data = res.data
              this.loginRegister()
              this.loginRegisterShow = true
              this.agreement = false
            }
            if (res.code == 200) {
              let token = res.data.access_token
              let expires_in = res.data.expires_in
              setToken(token)
              setExpires(expires_in)
              this.$store.dispatch('userInfo')
              this.off_login()
              this.$nextTick(() => {
                this.$router.go(0)
              })
            }

          })
        }
      }, 500)
    },
    warp(event) {
      console.log(event)
      return

    },
    loginRegister() {

      this.loginRegisterShow = true
      this.$store.commit("LOGIN_TOP", true)
    },
    off_login() {
      this.tabLoginRF = 'login'
      this.loginRegisterShow = false
      this.$store.commit("LOGIN_TOP", false)
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false)

      this.$router.push('/')
    },
    loginUser(start) {
      this.tabLoginRF = start
      this.form.mobile = ''
      this.form.verify = ''
      this.form.pass = ''
      if (start == 'register') {
        //this.agreement = true
      } else {
        this.code = false
        //this.agreement = true
      }
    },
    async getCaptcha() {
      let {code, data} = await $api.getCaptcha()
      if (code == 200) {
        this.urlCode = data.code.img
        this.captchaKey = data.code.key
      }

    },
    async getCode() {
      let is_use = 1
      if (this.tabLoginRF == 'register') {
        is_use = 1
      } else {
        is_use = 2
      }
      let mobile = this.form.mobile || this.loginForm.mobile
      let captcha = this.form.captcha || this.loginForm.captcha
      if (mobile.length != 11) {
        return this.$notify({
          message: '请正确输入手机号码',
          position: 'top-left'
        });
      }
      // if(this.form.captcha.length!=4){
      //   this.$notify({
      //     message:'请正确输入图形验证码',
      //     position: 'top-left'
      //   });
      //   return false;
      // }


      let data = await $api.getSendSms({mobile: mobile, is_use, captcha: captcha, codekey: this.captchaKey})
      if (data.code == 200) {
        this.$notify.closeAll()
        this.$notify({
          message: data.message,
          position: 'top-left'
        });

        this.getCodeSeond = 60
        let time = setInterval(() => {
          this.getCodeSeond -= 1
          if (this.getCodeSeond == 0) {
            this.getCodeSeond = '获取验证码'
            clearInterval(time)
          }
        }, 1000)


      } else {

        this.$notify.closeAll()
        this.$notify({
          message: data.message,
          position: 'top-left'
        });
        this.$notify.closeAll()
        // this.getCaptcha();

      }


    },
    agreementClick() {
      this.agreement = !this.agreement
    },
    //神马对接
    shenMaLogin(code) {
      this.$baidu.uploadConvertData({link: encodeURIComponent(code), event_type: 12})
    },
    isEmpty(obj) {
      if (obj == null) {
        return true;
      } else if (obj === "null") {
        return true;
      } else if (typeof (obj) == "undefined") {
        return true;
      } else if (obj === "undefined") {
        return true;
      } else if (obj === "") {
        return true;
      } else if (obj.trim() === "") {
        return true;
      } else if (obj.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return '';
    },
    askHttpHandle(pId) {
      var comm = window.comm || {};
      var qhclickid = this.getQueryString('qhclickid');
      if (!this.isEmpty(qhclickid)) {
        var xmlHttp = null;
        if (window.ActiveXObject) {
          xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
        } else if (window.XMLHttpRequest) {
          xmlHttp = new XMLHttpRequest();
        }
        if (xmlHttp != null) {
          xmlHttp.open("POST", "http://ret.huizbt.com/mtg_api/gateway/api/qh/eventPcSearchNew", true);
          xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
          xmlHttp.send("pId=" + pId + "&tabId=" + qhclickid + "&eventTime=" + new Date().getTime());
          xmlHttp.onreadystatechange = function () {
            //  console.info(xmlHttp.responseText)
          };
        }
      }
    },

    // 用户登录
    async login() {
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false)
      this.loginForm.stast = this.tabLoginRF
      this.loginForm.password = this.loginForm.pass
      this.loginForm.bd_vid = window.location.href
      this.loginForm.code = this.code

      this.form.stast = this.tabLoginRF
      this.form.password = this.form.pass
      this.form.bd_vid = window.location.href
      this.form.code = this.code
      window.localStorage.setItem('popularize', window.location.href);
      if (!this.agreement) {
        await this.$notify.closeAll()
        return this.$notify({
          message: '请阅读并勾选相关协议',
          type: 'warning',
          position: 'top-left'
        });
      }
      if (this.tabLoginRF != 'login' && this.domain_other) {
        console.log('askHttpHandle');
        this.askHttpHandle("1000071")
      }
      if (this.form.steam_data != '') {
        let {
          code,
          data,
          message
        } = await $api.getSteamHandleLogin(this.tabLoginRF == 'login' ? this.loginForm : this.form)
        if (code != 200) {
          return
        }
        setToken(data.access_token)
        setExpires(data.expires_in)
        this.$store.dispatch('userInfo')

        if (this.tabLoginRF == 'forget') {
          this.tabLoginRF = 'login'
          this.$notify({
            message: message,
            type: 'success',
            position: 'top-left'
          });
        }
        this.off_login()
        this.$store.commit("LOGIN_IS_SHOW", false)
      } else {

        let index = this.form.bd_vid.indexOf('bd_vid')
        if (index == -1) {//如果这里没有bd_vid就取保存起来的
          let baidu_url_bd_vid = await window.localStorage.getItem('baidu_url_bd_vid')
          let shenma_isConnect = await window.localStorage.getItem('shenma_isConnect')
          if (baidu_url_bd_vid != null) {
            index = baidu_url_bd_vid.indexOf('bd_vid')
            if (index != -1) {
              this.form.bd_vid = baidu_url_bd_vid
            }
          } else if (shenma_isConnect) {
            if (index != -1) {
              this.form.bd_vid = shenma_isConnect
            }
          }
        }


        let {code, data, message} = await $api.userLoginToken(this.tabLoginRF == 'login' ? this.loginForm : this.form)
        if (code != 200) {
          return
        }
        setToken(data.access_token)
        setExpires(data.expires_in)
        this.$store.dispatch('userInfo')
        if (this.tabLoginRF == 'register') {
          this.login_red_show = true
          //this.isRegisterShow=true;
          this.$baidu.baiduApi(49, this.form.bd_vid)
          let shenma_isConnect = await window.localStorage.getItem('shenma_isConnect')
          if (shenma_isConnect) this.shenMaLogin(shenma_isConnect)
        }
        if (this.tabLoginRF == 'forget') {
          this.tabLoginRF = 'login'
          this.form.verify = ''
          this.$notify({
            message: message,
            type: 'success',
            position: 'top-left'
          });
        }
        this.off_login()
        this.$store.commit("LOGIN_IS_SHOW", false)

      }

      if (this.$route.name == 'CSGOActive') {
        this.$router.go(0)
      } else if (this.$route.name == 'nologin') {
        this.$router.go(-1)
      }
    },
    // 获取轮播内容
    async getBoxHistory() {
      try {
        let {data} = await $api_history.getBoxHistory()
        this.addBoxHistory(data);
        // setInterval(() => {
        // console.log(this.listData)
        // $api_history.getBoxHistory().then(res => {
        //   let listData = this.listData.findIndex((item) => {
        //     return res.data.findIndex(it => {
        //       return item.id == it.id
        //     })

        //   })
        //   this.listData.push(...res.data.slice(0, listData + 5))
        //   this.listData.splice(0, listData + 5)

        // })
        // }, 5000)
      } catch (e) {
        console.log(e)
      }
    },
    addBoxHistory(data) {
      data = data.filter(item => item.lv !== 5)
      const {flag} = this;
      this.flag = !flag;
      if (!this.listData.length) {
        this.listData = data.splice(0, 15);
      }
      setTimeout(() => {
        if (data.length > 0) {
          if (flag) {
            this.listData = this.getBoxHistoryList(data.splice(0, 1)[0])
          }
          this.addBoxHistory(data);
        } else {
          this.getBoxHistory();
        }
      }, Math.floor(Math.random() * 1500 + 500));
    },
    getBoxHistoryList(first) {
      const {listData} = this;
      let item = [];
      item.push(first);
      for (let i = 0; i < listData.length && i < 20; i++) {
        item.push(listData[i]);
      }
      return item;
    },
    // 点击注册跳转
    ligin_zhuce(type) {
      this.loginRegisterShow = false
      this.$store.commit("LOGIN_TOP", false)
      setTimeout(() => {
        if (type == 1) {
          this.$router.push('/active/help/2')
        }
        if (type == 2) {
          this.$router.push('/active/help/4')
        }
        if (type == 3) {
          this.$router.push('/active/help/11')
        }
      }, 500)

    }
  }
}
</script>

<style lang="scss" scoped>
.ead-use {
  display: flex;
}

.width100per {
  width: 100%;

  .nav_menu {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  @media (min-width: 600px) and (max-width: 1300px) {
    img {
      height: 40px !important;
    }
  }
}

.hr_nav {
  width: 100%;
  height: 0.01rem;
  background: #333;
  margin: 10px 0;
}

.nav-link-phone {
  @extend .nav-link;
  padding: 24px 1rem;
  height: 42px !important;
}

// 时间盲盒
.timeBox {
  background-image: url(../assets/img/timeBox/time_box_nav.webp);
  background-repeat: no-repeat;
  background-position: center center;
  width: 400px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 40px;
    text-align: center;

    &:nth-child(1) {
      margin-left: 55px;
    }
  }
}

.opacity {
  opacity: 1;
}

.navigation-foot {
  position: relative;
  top: 20px;
}

.QQ-container {
  position: relative;

  .QQ-pop-up {
    width: 194px;
    height: 257px;
    background-image: url(../assets/img/ADT/common/QQ-bj.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100%;
    position: fixed;
    top: 50vh;
    right: 10px;
    transform: translateY(-50%);
    text-align: center;
    padding-top: 20px;

    & > p {
      font-size: 18px;
      color: #35C9EE;
    }

    .p2 {
      margin: 10px 0;
    }

    .p3 {

      font-size: 14px;
    }

    & > img {
      width: 118px;
      height: 118px !important;
    }
  }
}

.text-size-sty {
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  font-family: Monaco;

  @media (max-width: 600px) {
    transform: scale(0.7);
  }
}

.swiper-container {
  // display: none;
  // margin-bottom: 10px !important;
  padding-top: 15px !important;
  @media (max-width: 600px) {
    padding-top: 0 !important;
  }

  .swiper-list {

  }
}

.img-position {
  top: 35px;

  @media (max-width: 600px) {
    top: 10px !important;
  }
}

.img-position-text {
  z-index: 3;
  top: 35px;

  @media (max-width: 600px) {
    top: 0 !important;
  }
}

.img-height {
  height: 107px;

  @media (max-width: 600px) {
    height: 65px !important;
  }
}

.money-box {
  margin-right: 0;

  :deep(img) {
    width: 20px;
    margin-right: 0px;
    vertical-align: middle;
  }
}

.avatar-box {
  clip-path: polygon(25% 10%, 75% 10%, 100% 50%, 75% 90%, 25% 90%, 0% 49%);
  border: 1px solid rgba(0, 185, 212, 1);
}

.fhe {
  position: relative;
  cursor: pointer;

  > .ani {
    position: absolute;
    left: 120px;
    top: 65px
  }
}

@import "./swiperUser.scss";

.luck_word {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 13px;
  color: rgba(255, 255, 255, .25);
  padding: 0 1rem 0 1rem;
}

.phone-top-right {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-content: center;
}

@media (max-width: 600px) {
  .pc-none.head_mb.align-items-center {
    width: 100%;

    .common-btn.menu {
      width: 32px !important;
      height: 32px !important;
      margin-right: 10px !important;
      margin-top: 0 !important;
    }

    .phone-top-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;

      .money._a {
        margin-top: 2px;
      }

      .loginOr._a {
        margin: 0 10px !important;
        width: 85px !important;
      }
    }

    .logo.nuxt-link-exact-active.nuxt-link-active {
      & > img {
        height: 24px !important;
        margin-right: 15px !important;

        @media (max-width: 600px) {
          // margin-right: 40px !important;
        }
      }
    }
  }

  .xieyi {
    transform: scale(0.8);
    margin-left: -40px;
    width: 480px;
  }
}

.indexPup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, .5);

  @media (max-width: 680px) {
    top: 0px;

    & img {
      width: 60%;
    }
  }
}

.warp {
  height: 120px;

  @media (max-width: 1300px) {
    height: 80px;
  }

  .position-absolute.z-index-1 {
    height: 107px;

    @media (max-width: 1300px) {
      height: 95px;
    }
  }
}

.login-nav {
  .login-head {
    width: 100%;
    height: 54px;
    background-color: #21212a;
    border-radius: 10px 10px 0 0;
    display: flex;
    overflow: hidden;
    z-index: 1;

    .nav-li.active {
      background: #1E7A91;
      background-blend-mode: normal, normal;
      color: #FFFFFF;
    }

    .nav-li {
      flex: 1;
      min-width: 0;
      height: 100%;
      text-align: center;
      line-height: 54px;
      font-weight: 700;
      font-size: 14px;
      color: #8b8e9e;
      transition-duration: .3s;
      -webkit-transition-duration: .3s;
    }
  }

  @media (max-width: 1000px) {
    .login-head {
      height: 44px !important;

      .nav-li {
        line-height: 44px !important;
      }
    }
  }

  @media (max-width: 1000px) {
    .common-form-input {
      height: 40px !important;
      font-size: 16px !important;
      color: #6a6d81;
      padding-left: 11px !important;
      line-height: 36px !important;
    }

    .input-code {
      height: 40px !important;
      line-height: 40px !important;
      width: 200px;
      border-radius: 6px;
      margin-left: 10px
    }

    .codeImg {
      width: 60px !important;
    }
  }

  .common-form-input {
    width: 100%;
    height: 50px;
    background-color: #111114;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    color: #6a6d81;
    padding-left: 21px;
    line-height: 50px;
  }

  .input-code {
    height: 50px;
    line-height: 50px;
    width: 200px;
    border-radius: 6px;
    margin-left: 10px
  }

  .codeImg {
    cursor: pointer;
    width: 94px;
    border-radius: 6px;
  }
}

.el-button.common-btn.cdk {
  line-height: 70px;
  height: 70px;
  text-align: center;
  background: none;
  padding: 0 0 0 28px !important;
}

.position-relative {
  position: relative !important
}

.heaght_0 {
  height: 0 !important;
}

.info {
  border-top: 1px solid var(--f-main);
}

.info-swiper {

  margin: 0 auto;
  width: 150px;
  padding: 2px 34px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: .9rem;
  line-height: 1.3rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--f-main);
  -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%)
}

.content-hove:hover {
  color: #FFFFFF;
}

.f_login {
  background-color: #e94d8b;
  color: #ffffff;
}

.code-phone {
  @media (max-width: 1200px) {
    max-width: 150px !important;
  }

  &-size {
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }
}

.login_egister {
  background: #17192C;
  opacity: 0.98;
  border-radius: 20px;

  .border-login {
    background-image: linear-gradient(90deg, #473c40, #3e3840 50%, #343440);
    background-blend-mode: normal, normal;
    color: #f7bb77;
  }

  .teb_cut {
    width: 400px;
    height: 50px;
    line-height: 50px;
  }

  .login-btn {
    > a {
      margin: 25px 0 0px 0;
      width: 100%;
      height: 56px;
      text-align: center;
      line-height: 56px;
      display: inline-block;
      color: #fff !important;
      background: #1E7A91;
      background-blend-mode: normal, normal;
      border-radius: 8px;
      border: 1px solid #1E7A91;
    }

    .switchingLogin {
      line-height: 56px;
    }

    @media (max-width: 600px) {
      > a {
        width: 100%;
        height: 56px;
      }
    }


    @media (max-width: 1200px) {
      .login-btn {
        width: 100% !important;
        height: 42px !important;
        line-height: 42px !important;

      }

      .switchingLogin {
        line-height: 42px !important;
      }
    }

    .forget-the-password {
      /* height: 55px; */
      line-height: 20px;
      text-align: right;
      /* margin: 4px 0 10px 0; */
      height: 20px;
      color: #ccc;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

}

.login {
  &-right {
    width: 100%;

    &-input {
      color: #FFFFFF;
      font-size: 25px;
    }
  }
}

.swiper {
  width: 100%;
  // height: auto !important;

  @media (max-width: 1000px) {
    height: 80px !important;
  }

  .flaganimation {
    animation: scrollFirstChild 1s;

    @media (max-width: 680px) {
      animation: scrollFirstChild 1.5s;
    }
  }

  &-top {
    width: 90%;
    margin: 5px auto;
    padding: 5px 10px;
    background-color: var(--main-bg-2);
    border-radius: 15px;
    display: flex;
    align-content: center;
    font-size: 14px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  &-user {
    width: 200px;
    height: 100%;
    font-size: 16px;

    & svg {
      font-size: 40px;
    }

    &-box {
      text-align: center;
      padding: 4px 0;
    }
  }

  &-list {
    width: 100%;

    @media (max-width: 1000px) {
      // transform: scale(1,.7) translateY(-15%);
    }

    &-content {
      width: 100%;

      &-box {
        top: 0;
        transition: all .5s;
        // border-right: 2px solid #000;

        &:hover {
          top: -130px;
        }

        &-item {
          transition: all .5s;
          width: 120px;
          height: 120px;

          @media (max-width: 1300px) {
            display: flex;
            width: 70px;
            height: 80px;
            padding-top: 10px;
          }

          > .bg-size-100 {
            height: 100%;
          }

          &:nth-child(1)::before {
            content: '';
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9999;
            background-size: 100% 100%;
            // background-image: url("../assets/img/f_new/jiangli.png");
          }

          &-phone-img {
            width: 70%;

            @media (max-width: 1300px) {
              width: auto !important;;
              height: 42px !important;
              transform: scale(0.9, 1.23);
            }
          }
        }

        &-itemnone {
          opacity: 0;
          transition: all .5s;
          width: 170px;
          height: 100px;

          &-title {
            text-align: center;
            padding: 10px 0;
          }

          &-img {
            transition: all 1s;
          }
        }

        &:hover &-item {
          /*transform:rotateY(360deg);*/
        }

        &:hover &-itemnone {
          // opacity: 1;
        }

        &:hover &-itemnone-img {
          // transform:rotateY(180deg);
        }
      }
    }
  }
}

.fon14 {
  font-size: 14px;
}


.swiper {
  // height: 150px;
  // line-height: 150px;
  background-color: #15172C;
  width: 100%;
  border: 1px transparent solid;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: rgb(98, 168, 200);
  }
}

.ceshi {
  transform-origin: 100% 50% 0;
  -webkit-animation: scrollAn .65s ease;
  animation: scrollAn .65s ease;
}

@keyframes scrollAn {
  0% {
    margin-left: -130px;
    opacity: 0;
    transform: perspective(800px) rotateY(-90deg) translateZ(0);
  }

  100% {
    margin-left: 1px;
    opacity: 1;
    transform: perspective(800px) rotateY(0deg) translateZ(0);
  }
}

@keyframes scrollFirstChild {
  0% {
    width: 0px;
    opacity: 0;
  }

  100% {
    width: 140px;
    opacity: 1;
  }
}
</style>
