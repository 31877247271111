<template>
  <div class="content d-flex justify-content-center align-items-center">
      <div>
        <div class="h1 text-center mb-3 text-primary">请先登录</div>
        <!-- <div class="text-center mb-4 text-primary" style="font-size: 14px">请登录</div> -->
        <div @click="login" class="cursor-pointer text-center text-white f_btn">登录/注册</div>
      </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
      }
    },
    components: {
    },
    created() {

    },
    mounted() {
      this.$store.commit("LOGIN_IS_SHOW", true)
    },
    beforeDestroy(){
    },
    methods:{
      login(){
        this.$store.commit("LOGIN_TOP", true)
      }
    },
  };
</script>
<style lang="scss" scoped>
 .content{
  height: 50vh
 }
</style>
