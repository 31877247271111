<template>
  <div>
    <section class="pg-m">
      <img src="../../assets/img/news/member_tit.png" alt="" class="icon" />
      <div class="user" v-if="vipLevelReward != 12">
        <div class="user-main">
          <div class="progress">
            <img
              v-if="Viptips.next_level_value - 1 < 10"
              :src="
                require(`../../assets/img/news/lv/${
                  Viptips.next_level_value - 1 || 0
                }.png`)
              "
              alt=""
              class="progress-now mr-10"
            />
            <img
              v-else
              :src="
                require(`../../assets/img/news/lv/${
                  Viptips.next_level_value - 1 || 0
                }.gif`)
              "
              alt=""
              class="progress-now mr-10"
            />
            <div class="progress-info">
              <p class="progress-exp">
                <span>{{ Viptips.atPresent }} </span>
                <span
                  >/{{
                    Viptips.next_level_value && VipList.length
                      ? Number(
                          VipList[Viptips.next_level_value - 1].threshold || 0
                        )
                      : 0
                  }}</span
                >
              </p>
              <div class="progress-react margin-lr-sm">
                <el-progress
                  :percentage="percentage.toFixed(0)"
                  color="#F29924"
                  :stroke-width="16"
                  :format="format"
                  :text-inside="true"
                ></el-progress>
              </div>
            </div>
            <div></div>
            <img
              v-if="Viptips.next_level_value - 1 < 10 && vipLevelReward != 12"
              :src="
                require(`../../assets/img/news/lv/${Viptips.next_level_value}.png`)
              "
              alt=""
              class="progress-now ml-10"
            />
            <img
              v-else
              :src="
                require(`../../assets/img/news/lv/${Viptips.next_level_value}.gif`)
              "
              alt=""
              class="progress-now ml-10"
            />
          </div>
          <!---->
        </div>
      </div>
      <div class="full-scale-box" v-else></div>
      <div class="bj" style="color:#4CBE00;display:flex;align-items: center;font-size: 14px;" v-if="Number( Viptips.rechargeTotal ) >= Number( Viptips.bjValue )"><img style="margin-right: 10px;" src="../../assets/img/news/ok.png" alt=""/>保级成功</div>
      <div class="bj" v-else-if="user_info.vip_level != 12" style="font-family: Monaco;">{{ Viptips.bjdate }}起经验值: <span style="font-family: Monaco;color:#F29924">{{ Viptips.rechargeTotal }}</span>,30天内保级所需经验值:<span style="color:#F29924;font-family: Monaco;">{{ Viptips.bjValue }}</span></div>
      <div class="footer">
        <p class="title">
          会员等级奖励 <img src="@/assets/pk/tip.png" @click="playingMethod=true" />
        </p>

        <div class="list">
          <div
            :class="sUserAgent === 'phone' ? 'dt-phone' : ''"
            class="dt-birthday"
          >
            
            <div>
              <div class="box" @click="birthdayHandle">
                <img src="../../assets/img/news/dt/birthday.png" alt="" />
                <span>生日礼包</span>
              </div>
              <img src="@/assets/pk/tip.png" @click="playingMethod1=true" />
            </div>
          </div>

          <div class="thead">
            <p class="nth">会员等级</p>
            <p class="nth">经验值</p>
            <p class="nth">升级奖励</p>
            <p class="nth">充值加成</p>
            <p v-if="sUserAgent !== 'phone'" class="nth">专属红包</p>
          </div>
          <div class="table">
            <ul class="table-list">
              <li
                class="table-list-li"
                v-for="(item, index) in VipList"
                :key="index"
              >
                <div class="info">
                  <div class="nth">
                    <img
                      v-if="index + 1 < 10"
                      :src="
                        require(`../../assets/img/news/lv/${index + 1}.png`)
                      "
                      alt=""
                    />
                    <img
                      v-else
                      :src="
                        require(`../../assets/img/news/lv/${index + 1}.gif`)
                      "
                      alt=""
                    />
                  </div>
                  <div class="nth">
                    <div class="cp cffcb77">
                      <span
                        class="cp-num"
                        style="padding-left: 2px; margin-top: 2px"
                      >
                        {{ item.threshold }}</span
                      >
                    </div>
                  </div>
                  <div class="nth">
                    <div class="cp cffcb77">
                      <money />
                      <span
                        class="cp-num"
                        style="padding-left: 2px; margin-top: 2px"
                      >
                        {{ item.packet }}</span
                      >
                    </div>
                  </div>
                  <div class="nth">
                    <div
                      class="cp cffcb77"
                      style="padding-left: 2px; margin-top: 2px"
                    >
                      {{ item.rebate }}%
                    </div>
                  </div>
                  <div class="nth m-none">
                    <div class="dt-draw-box" v-if="item.vip_reward">
                      <img
                        v-if="item?.vip_reward?.status"
                        src="../../assets/img/news/ok.png"
                        alt=""
                      />
                      <div
                        v-else
                        :style="{
                          marginLeft: !item?.vip_reward?.status ? '0' : '20px',
                          transform: !item?.vip_reward?.status
                            ? 'translateX(-22px)'
                            : '',
                        }"
                        class="dt-draw"
                        @click="getViprewards(item.id)"
                      >
                        领取福利
                      </div>
                    </div>
                    <div v-else>
                      <img
                        v-if="item.level <= vipLevelReward"
                        src="../../assets/img/news/ok.png"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../assets/img/news/dt/no.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="nth pc-none">
                  <div v-if="item.vip_reward">
                    <div
                      style="color: #00ab2b; background: none"
                      class="btn sur-bottom btn0"
                      v-if="item?.vip_reward?.status"
                    >
                      已领取
                    </div>
                    <div
                      v-else
                      @click="getViprewards(item.id)"
                      class="btn sur-bottom btn0"
                    >
                      已达到
                    </div>
                  </div>
                  <div v-else>
                    <div
                      style="color: #00ab2b; background: none"
                      class="btn sur-bottom btn0"
                      v-if="item.level <= vipLevelReward"
                    >
                      已领取
                    </div>
                    <div v-else class="btn info-bottom btn0">未达到</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- 生日福利身份认证 -->
    <div v-show="birthdayPopUp">
      <div class="position-fixed z-index-100 py-5 birthdayPopUp">
        <div class="px-5 birthdayPopUp-box">
          <div class="birthdayPopUp-box-con">
            <div class="px-2 birthdayPopUp-box-con-img text-center"></div>
            <div class="text-center birthdayPopUp-box-con-dis">
              <!-- <p class="dt-title">未实名账号登记提示</p> -->
              <div class="common-form-input mt-3">
                <input
                  id="idcard"
                  name="idcard"
                  v-model="form.idcard"
                  class="text-white d-block w-100"
                  type="text"
                  maxlength="18"
                  placeholder="请输入身份证号码"
                />
              </div>
              <div class="common-form-input mt-3">
                <input
                  v-model="form.name"
                  class="text-white d-block w-100"
                  type="text"
                  maxlength="11"
                  placeholder="请输入姓名"
                />
              </div>
              <div
                @click="sendBirthday"
                class="mt-3 flex justify-content-center common-btn common-btn-blue dt-btn-sty"
              >
                确认
              </div>
            </div>
          </div>
          <div class="d-flex mt-5 justify-content-center">
            <!-- <div @click="ShoppingMoney">
              <bottom-bg>
                <div class="" style="font-size: 16px;color: #ffffff">
                  积分兑换
                </div>
              </bottom-bg>
            </div> -->
          </div>
          <div
            @click="birthdayPopUp = false"
            class="position-absolute"
            style="right: 20px; top: 20px"
          >
            <div>
              <i
                class="iconfont text-white icon-guanbi2 cursor-pointer"
                style="font-size: 30px"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div
        class="position-fixed bg-bg-2 op-4 z-index-1"
        style="width: 100vw; height: 100vh; left: 0; top: 0"
      ></div>
    </div>

    <!-- 生日福利-领取弹窗 -->
    <div
      v-show="birthdayPopUpSuccess"
      :class="sUserAgent !== 'phone' ? 'isPc' : ''"
      class="birthday-pop-up-success"
    >
      <div class="position-fixed z-index-100 py-5 birthdayPopUp success">
        <div class="px-5 birthdayPopUp-box">
          <div class="birthdayPopUp-box-con">
            <div class="px-2 birthdayPopUp-box-con-img text-center"></div>
            <div class="text-center birthdayPopUp-box-con-dis">
              <div class="dt-content">
                <p v-if="FKdate" class="first-p">领取日期：{{ FKdate }}</p>
                <p class="last-p" v-if="FKamount">
                  <img src="../../assets/img/news/shandian_ceshi.png" alt="" />
                  <span>{{ FKamount }}</span>
                </p>
              </div>

              <div
                @click="gotIt"
                class="mt-3 flex justify-content-center common-btn common-btn-blue dt-btn-sty"
              >
                知道了
              </div>
            </div>
          </div>
          <div class="d-flex mt-5 justify-content-center"></div>
          <div
            @click="birthdayPopUpSuccess = false"
            class="position-absolute"
            style="right: 20px; top: 20px"
          >
            <!-- <div>
              <i
                class="iconfont text-white icon-guanbi2 cursor-pointer"
                style="font-size: 30px"
              ></i>
            </div> -->
          </div>
        </div>
      </div>
      <div
        class="position-fixed bg-bg-2 op-4 z-index-1"
        style="width: 100vw; height: 100vh; left: 0; top: 0"
      ></div>
    </div>

    <!-- 玩法介绍 -->
    <div class="" v-if="playingMethod">
      <div
        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg notice">
        <div class="text-white p-md-4 p-2" style="line-height: 2">
          <p style="font-size:16px;font-weight:bold;margin-bottom: 10px;">会员如何升级?</p>
          <p style="color:#ccc">答: 达到VipA后，30天内充值Vip (A+1) 与VipA的门槛差值即可升级Vip等级; 充值1U币 = 1经验值。</p>
          <p>&nbsp;</p>
          <p style="font-size:16px;font-weight:bold;margin-bottom: 10px;">是否可以一次上升多个等级?</p>
          <p style="color:#ccc">答: 会员单次充值金额若满足多个设置条件的，则自动升级至其中最高的会员等级</p>
          <p>&nbsp;</p>
          <p style="font-size:16px;font-weight:bold;margin-bottom: 10px;">会员等级是否永久有效?</p>
          <p style="color:#ccc">答: 不是的。成为VipA后，30天内需要保级经验，充值满【VipA门槛-Vip（A-1）门槛】*0.3  即可维持VipA；充值不满【VipA门槛-Vip（A-1）门槛】*0.3，则下降1级至VIP（A-1）初始经验值，直至下降至Vip3。</p>
          <p>&nbsp;</p>
          <p style="font-size:16px;font-weight:bold;margin-bottom: 10px;">会员经验值是否会扣减?</p>
          <p style="color:#ccc">答: 会的，Vip当月保级扣除保级所需要的经验值，即【VipA门槛-Vip（A-1）门槛】*0.3 ;Vip3及以下等级不扣减。保级经验值计算起点：到达VipA 之后的第一笔充值开始计算。</p>
          <p>&nbsp;</p>
          <p style="font-size:16px;font-weight:bold;margin-bottom: 10px;">升级专属红包只能领取1次吗?</p>
          <p style="color:#ccc">答: 是的，每个等级只可领取一次等级升级奖励。如果降级后再次升级，等级升级奖励也不可以再次领取。</p>
        </div>
        <div @click="playingMethod = false" class="position-absolute" style="right: 20px; top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i>
          </div>
        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
    </div>
    <div class="" v-if="playingMethod1">
      <div style="width: 700px; left: calc(50% - 350px); top: 15vh"
        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
        <div class="text-white p-md-4 p-2" style="line-height: 2">
          <p>1、领取生日福利必须完成实名登记噢！</p>
          <p>2、 生日福利最高可获取888.88U币，等级越高福利越多!</p>
          <p> 礼物如约而至,感谢一路有你!</p>
        </div>
        <div @click="playingMethod1 = false" class="position-absolute" style="right: 20px; top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i>
          </div>
        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
    </div>
  </div>
</template>

<script>
import $api from "@/api/vip/index";
import sUserAgent from "@/assets/js/common.js";
import Money from "../../common/money/money.vue";
import { mapState } from "vuex";
export default {
  name: "index",
  components: { Money },
  data() {
    return {
      playingMethod:false,
      playingMethod1:false,
      FKamount: "", // 已领取金额
      FKdate: "", // 已领取日期
      isVerify: 0, // 0未认证 1已认证
      vipLevelReward: 0, // 0 - 12 vip等级
      form: {
        idcard: undefined, // 身份证号
        name: undefined, // 姓名
        // verification: undefined, // 验证
        // code: undefined, // 图形验证码
      },
      birthdayPopUpSuccess: false,
      birthdayPopUp: false, // 生日福利身份认证弹窗
      VipList: [],
      Viptips: { next_level_value: 1, atPresent: 0 },
      percentage: 0,
    };
  },
  computed: {
    ...mapState(["user_info"]),
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  created() {},
  beforeDestroy() {
    document.getElementById("birthday").load();
    document.getElementById("birthday").pause();
  },
  async mounted() {
    await this.getVipList();
  },
  methods: {
    gotIt() {
      this.birthdayPopUpSuccess = false;
      document.getElementById("birthday").load();
      document.getElementById("birthday").pause();
    },
    sendBirthday() {
      console.log("触发生日礼包按钮");
      this.getVipVerify();
    },
    birthdayHandle() {
      console.log("--------", this.isVerify);
      if (this.isVerify) {
        this.getVipbrithday();
      } else {
        this.birthdayPopUp = true;
      }
    },
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    getVipList() {
      $api.getVipList().then((res) => {
        this.VipList = res.data.list;
        this.Viptips = res.data.tips;
        this.isVerify = res.data.is_verify; // 0未认证 1已认证
        this.vipLevelReward = res.data.vip_level_reward; // 0 - 12 vip等级
        console.log("--res.data.vip_level_reward--", res.data.vip_level_reward);
        if (this.vipLevelReward >= 12) return;
        this.Viptips.atPresent =
          this.VipList[this.Viptips.next_level_value - 1].threshold -
          Number(this.Viptips.next_lack2);
        this.percentage =
          Number(
            (
              this.Viptips.atPresent /
              this.VipList[this.Viptips.next_level_value - 1].threshold
            ).toFixed(2)
          ) * 100;
      });
    },

    //身份认证
    getVipVerify() {
      const { form } = this;
      console.log("form", form);
      $api
        .getVipVerify({ name: form.name, idcard: form.idcard })
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.$notify.closeAll();
            this.$notify({
              message: res.message,
              position: "top-left",
            });
            this.birthdayPopUp = false;

            this.getVipList(); // 重新获取列表数据
          }
        });
    },

    //会员生日奖励
    getVipbrithday() {
      $api.getVipbrithday().then((res) => {
        if (res.code == 200) {
          console.log("会员生日奖励----", res);
          this.birthdayPopUpSuccess = true;
          document.getElementById("birthday").play();
          this.FKamount = res?.data?.data?.bean || "";
          this.FKdate = res?.data?.data?.date || "";
          // this.$notify.closeAll();
          // this.$notify({
          //   message: res.message,
          //   position: "top-left",
          // });
          this.getVipList(); // 重新获取列表数据
        }
      });
    },
    //会员升级奖励
    getViprewards(id) {
      $api.getViprewards({ vip_id: id }).then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.$notify.closeAll();
          this.$notify({
            message: res.message,
            position: "top-left",
          });
          this.getVipList(); // 重新获取列表数据
        }
      });
    },
  },
};
</script>

<style lang="scss">
.notice {
  width: 700px;
  left: calc(50% - 350px); 
  top: 15vh;
  // @media (max-width:600px) {
  //   width: 98%;
  // }
}
.birthdayPopUp.success {
  height: 380px;
  background-image: url("../../assets/img/news/dt/birthday-pop-up-success-bg.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.birthday-pop-up-success.isPc {
  .last-p {
    top: 135%;
    left: 53%;
    white-space: nowrap;
    & > span {
      font-size: 18px;
    }
  }
}
.birthday-pop-up-success {
  .birthdayPopUp {
    .dt-content {
      position: absolute;
      left: 50%;
      bottom: 25%;
      transform: translateX(-50%);
      .first-p {
        white-space: nowrap;
      }
      .last-p {
        white-space: nowrap;
        & > img {
          position: relative;
          top: 10px;
          width: 28px;
          height: 28px;
        }
        & > span {
          font-size: 18px;
        }
      }
    }

    .dt-btn-sty {
      width: 45%;
      left: 50%;
      bottom: 5%;
      transform: translateX(-50%);
      position: absolute;
    }
  }
}

.birthdayPopUp {
  z-index: 3;
  width: 600px;
  left: calc(50% - 300px);
  top: calc(25%);
  background-image: url("../../assets/img/news/dt/authentication.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .birthdayPopUp-box {
    margin-top: 165px;
  }
  .dt-title {
    margin: 25px 0 65px 0;
    font-size: 25px;
    @media (max-width: 600px) {
      margin: 0;
      font-size: 18px;
    }
  }
  @media (max-width: 1200px) {
    width: 95%;
    left: 2.5%;

    &-box {
      padding-left: 10px !important;
      padding-right: 10px !important;

      &-con {
        &-img {
          width: 50%;
          margin: 0 auto;
        }

        & img {
          width: 100%;
        }

        &-dis {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .common-form-input {
      height: 40px !important;
      font-size: 16px !important;
      color: #6a6d81;
      padding-left: 11px !important;
      line-height: 36px !important;
    }
    .input-code {
      height: 40px !important;
      line-height: 40px !important;
      width: 200px;
      border-radius: 6px;
      margin-left: 10px;
    }
    .codeImg {
      width: 60px !important;
    }
  }
  .common-form-input.dt-other {
    display: flex;
    background: none !important;
    .dt-input-other {
      width: 30% !important;
      background: #111114;
    }
    .code {
      margin-left: 50px;
      background-color: red;
    }
  }
  .common-form-input {
    width: 100%;
    // height: 50px;
    background-color: #111114;
    border-radius: 8px;
    border: 2px solid #32354f;
    font-size: 16px;
    color: #6a6d81;
    padding-left: 21px;
    line-height: 50px;
  }
  .input-code {
    height: 50px;
    line-height: 50px;
    width: 200px;
    border-radius: 6px;
    margin-left: 10px;
  }
  .codeImg {
    cursor: pointer;
    width: 94px;
    border-radius: 6px;
  }
  .dt-btn-sty {
    margin-top: 30px !important;
  }
}
.dt-draw-box {
  display: flex;
  align-content: center;
  & > img {
    width: 24px;
  }
  .dt-draw {
    // margin-left: 20px;
    color: #ffcb77;
    cursor: pointer;
    // border: 1px solid #ffcb77;
    // border-radius: 20px;
    // padding: 5px 10px;
    font-size: 16px;
  }
}

.pg-m {
  margin: 0 auto;
  max-width: 1720px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1300px) {
    padding: 15px;
    padding-top: 0px;
  }
  .icon {
    display: block;
    width: 1026px;
    height: 500px;
    -o-object-fit: contain;
    object-fit: contain;
    @media (max-width: 1300px) {
      width: 100%;
      height: 180px;
    }
  }
  .txt {
    line-height: 1 !important;
    margin-top: 20px;
    font-size: 18px;
    color: #8b8c97;
    @media (max-width: 1300px) {
      font-size: 12px;
    }
  }
  .bj{
    font-size: 12px;
  }
  .user {
    width: 100%;
    margin-top: -140px;
    height: 118px;
    border-radius: 8px;
    padding-left: 85px;
    padding-right: 45px;
    @media (max-width: 1300px) {
      margin-top: -60px;
      height: 78px;
      padding-left: 0;
      padding-right: 0;
    }
    .user-main {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .img {
        flex-shrink: 0;
        width: 80px;
        height: 54px;
        -o-object-fit: contain;
        object-fit: contain;
        display: block;
        margin-right: 60px;
      }
      .info {
        flex-shrink: 0;
        width: 337px;
        height: 50px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        @media (max-width: 1300px) {
          width: 137px;
        }
        .head {
          flex-shrink: 0;
          width: 50px;
          height: 100%;
          background-color: #b4b8cd;
          border-radius: 8px;
          overflow: hidden;
          @media (max-width: 1300px) {
            width: 35px;
            height: 35px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .nickname {
          padding-left: 13px;
          max-width: calc(100% - 50px);
          font-size: 14px;
          font-weight: 700;
        }
      }
      .progress {
        flex-shrink: 0;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        background: none;
        padding-right: 20px;
        .progress-now {
          width: 56px;
          height: 37.8px;
          display: block;
          @media (max-width: 1300px) {
            width: 40px;
            height: 27px;
          }
        }
        .progress-info {
          width: 420px;
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 1300px) {
            width: 200px;
          }
          .progress-exp {
            top: calc(50% - 34px);
            position: absolute;
            font-family: Montserrat-SemiBold;
            font-size: 16px;
            line-height: 1;
            span:first-child {
              color: #ffcb77;
            }
            @media (max-width: 1300px) {
              font-size: 12px;
              top: calc(50% - 24px);
            }
          }
          .progress-react {
            width: 100%;
          }
        }
      }
    }
  }
  .full-scale-box {
    width: 420px;
    height: 118px;
    text-align: center;
    background-image: url("../../assets/img/news/lv/full-scale.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .footer {
    width: 100%;
    margin-top: 30px;
    @media (max-width: 1300px) {
      margin-top: 15px;
    }
    .title {
      line-height: 1;
      text-align: center;
      width: 100%;
      height: 42px;
      border-bottom: 1px solid #33333c;
      font-size: 18px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      > img{
        cursor: pointer;
        margin-left: 10px;
      }
    }
    .title:after {
      content: "";
      position: absolute;
      left: calc(50% - 160px);
      bottom: -1px;
      width: 320px;
      height: 1px;
      background-color: #b4b8cd;
      @media (max-width: 1300px) {
        left: calc(50% - 80px);
        width: 160px;
      }
    }
    .nth {
      flex: 1;
      min-width: 0;
      padding-right: 20px;
      @media (max-width: 1300px) {
        padding-right: 10px;
      }
    }
    .list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      @media (max-width: 1300px) {
        margin-top: 10px;
      }
      .dt-birthday.dt-phone {
        width: initial;
        padding-right: 0;
      }
      .dt-birthday {
        width: 100%;
        padding-right: 40px;
        display: flex;
        justify-content: right;
        div{
          display: flex;
          justify-content: center;
          > img{
            cursor: pointer;
          }
          & > .box {
            margin-right: 10px;
            color: #ffcb77;
            cursor: pointer;
            text-align: right;
            // width: 88px;
            border-bottom: 2px solid #ffcb77;
            padding-bottom: 4px;
            & > span {
              padding-left: 5px;
              width: 70px;
            }
            & > img {
              width: 20px;
              height: 16px;
              vertical-align: bottom;
            }
        }
        }
      }
      .thead {
        width: calc(100% - 80px);
        display: flex;
        align-items: center;
        background-color: #22232e;
        padding: 10px 40px;
        margin: 10px auto;
        @media (max-width: 1300px) {
          margin-bottom: 10px;
          padding: 0 15px;
        }
        .nth {
          line-height: 40px !important;
          font-size: 14px;
          color: #8b8c97;
        }
        @media (max-width: 1300px) {
          line-height: 40px !important;
          font-size: 12px;
          width: 100%;
        }
      }
    }
    .table {
      width: 100%;
      background-color: rgba(17, 17, 20, 0.5);
      border-radius: 8px;
      padding: 0 40px;
      @media (max-width: 1300px) {
        padding: 0;
      }
      .table-list {
        width: 100%;
        overflow: hidden;
        .table-list-li {
          width: 100%;
          height: 86px;
          background-color: #28282bc2;
          border-radius: 8px;
          margin-bottom: 10px;
          @media (max-width: 1300px) {
            padding: 10px;
          }
          .info {
            width: 1560px;
            height: 100%;
            display: flex;
            margin: 0 auto;
            @media (max-width: 1300px) {
              width: 100%;
              height: 40px;
            }
            .nth {
              height: 100%;
              display: flex;
              align-items: center;
              @media (max-width: 1300px) {
                height: 30px;
              }
              img {
                @media (max-width: 1300px) {
                  width: 35px;
                }
              }
            }
            .txt {
              margin-top: 0;
              font-size: 14px;
              color: #b4b8cd;
              line-height: 1 !important;
            }
            .btn {
              width: 102px;
              height: 40px;
              text-align: center;
              line-height: 24px;
              border-radius: 8px;
              font-size: 14px;
            }
            .btn.btn0 {
              color: #535461;
              border: 1px solid #31303e;
            }
            .cp.cffcb77 {
              color: #ffcb77;
            }
            .cp {
              height: 16px;
              font-size: 16px;
              display: flex;
              align-items: center;
              max-width: 100%;
              @media (max-width: 1300px) {
                font-size: 12px;
              }
              .cp-img {
                flex-shrink: 0;
                height: 100%;
                -o-object-fit: contain;
                object-fit: contain;
              }
            }
          }
        }
      }
      .info-bottom {
        width: 100%;
        height: 30px;
        text-align: center;
        color: #535461;
        border: 1px solid #31303e;
        border-radius: 8px;
        font-size: 12px;
      }
      .sur-bottom {
        width: 100%;
        height: 30px;
        text-align: center;
        color: #fff;
        background: rgb(0, 171, 43);
        border-radius: 8px;
        font-size: 12px;
      }
    }
  }
}
</style>