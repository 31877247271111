<template>
  <div>
    <div class="content mt-3 position-relative">
      <!-- 对战回合 :class="detailsData.status == 2?'filter-grayscale-1':''" -->
      <!-- 声音开关 - 定位 -->
      <div class="position-absolute d-none" style="right:0;top:0">
        <el-switch class="mr-3" v-model="voiceSwitch" active-color="#ff4949" inactive-color="#979797">
        </el-switch>{{ voiceSwitch ? '打开声音' : '关闭声音' }}
      </div>
      <div class="">
        <div class="h4 ftiitle">
          <div class="left">
            <span @click="$router.push(`/FmysteryBox?typeurl=${urlType}&hisPageRecord=${hisPageRecord}`)">返回</span>
            <span>房间编号：{{ game_arena_id }}</span>
          </div>
          <div class="game_type_name"><span v-if="detailsData.game_type" class="type1">地转</span> <span class="type0" v-else>天旋</span> </div>
          <div class="right">总价: {{ detailsData.total_bean }}</div>
          
        </div>
        <div class="d-flex content-bout history_bg ">
          <div class="content-bout-box d-flex list" :class="detailsData.status == 2 ? '' : ''">
            <div v-for="(item, index) in detailsData.game_arena_box" :key="index"
              class="text-center content-bout-box-item weaponBox" @click="lockBoxGun(item, index)"
              :style="index + 1 < slotIndex && activeStatus && 'filter: grayscale(100%)'"
              :class="index + 1 == slotIndex ? detailsData.status == 2 ? '' : (activeStatus ? 'active_bg' : '') : ''">
              <div style="width:50px;height:60px;border-radius: 4px;">
                <img width="100%" :src="item.box?.type == 1 ? item.box?.intact_cover : item.box?.cover" alt="">
              </div>
              <!-- <div class="mt-2" style="font-size: 12px">{{ item.box.name || '' }}</div> -->
              <img class="weapon" v-if="item.box?.type == 0" :src="item.box?.weapon_cover" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="slotMatchina">
        <div v-for="(item, index) in detailsData.game_arena_player" :key="index">
          <div :class="item?.user.id == user_info.id ? 'slotDongF_me': 'slotDongF'" v-if="!moneyJieShu" :style="{ height: detailsData.status == 1 ? '120px' : '0px' }">
            <div class="line_light" v-if="detailsData.status == 1"></div>
            <div class="slotDong" v-if="!moneyJieShu" :style="{ height: detailsData.status == 1 ? '150px' : '0px' }">
            <div :class="`${slotAn ? 'slotListBox' : 'slotListBox1'}`"
              :style="{ transform: `translateY(${slotAn ? '-' + slotY + 'px' : '0px'})` }">
              <div :style="{ display: slotAn ? 'block' : 'none' }" class="ls"
                v-for="(child, childIndex) in slotList[index]" :key="childIndex">
                <img :src="child?.skins?.cover" height="60" />
                <p>{{ child?.skins?.name.replace(/\s*/g, "") }}</p>
                <p class="slotMoney"><img src="@/assets/img/shandian_ceshi.png" height="20" />{{ child?.skins?.bean }}</p>
              </div>
              <div :style="{ display: !slotAn && slotIndex != 0 ? 'block' : 'none' }" class="ls">
                <img
                  :src="detailsData.game_arena_player[index]?.game_award?.[slotIndex - (slotStatus == 1 ? 2 : 1)]?.skins?.cover"
                  height="60" />
                <p>{{
                  detailsData.game_arena_player[index]?.game_award?.[slotIndex - (slotStatus == 1 ? 2 :
                    1)]?.skins?.name.replace(/\s*/g,
                      "") }}</p>
                <p class="slotMoney"><img src="@/assets/img/shandian_ceshi.png" height="20" />{{
                  detailsData.game_arena_player[index]?.game_award?.[slotIndex - (slotStatus == 1 ? 2 : 1)]?.skins?.bean
                }}</p>

              </div>
            </div>
          </div>
          </div>

          <!-- 包裹的div 是定义失败成功的 -->
          <div v-if="item" :class="item.success ? '' : detailsData.status == 2 ? '' : ''"
            class="overflow-hidden slotMatchinaSuccess">


            <!-- 老虎机抽奖页面 -->
            <div class="content-pk-item-pk d-fle "
              :class="item.game_gun ? 'active_light content-pk-item-pk-bg' : ''"
              :style="{ height: detailsData.status == 1 && !moneyJieShu ? '0px' : '80px' }">
              <div class="content-pk-item-pk-box">
                <div class="text-center content-pk-item-pk-box-item an-item position-relative " :name="t.a"
                  v-for="(t, index) in item.game_arena_box[indexBox].box.contains" :key="index">
                  <!-- 结束的时候 -->
                  <div v-if="detailsData.status == 2" class="successbgF">
                    <div v-if="item.success" class="successbg">
                      <img class="victory" src="@/assets/img/pk/victory.png" alt="">
                      <!-- <button style="font-size: 12px; color: #fff "
                        class="sefl_pk_button">
                        <div class="self_pk_right"></div>
                        大获全胜
                        <div class="self_pk_right"></div>
                      </button>
                      <div style="font-size: 14px; font-weight: 700; margin-top: 20px; color: #3c6">
                        胜利<countTo :decimals="2" :startVal='0' :endVal='(item.sum) * 1' :duration='2000'></countTo>
                      </div> -->
                    </div>
                    <div v-else :class="item.user.id == user_info.id ? 'failbg_me':'failbg'">
                      <img class="defeat" src="@/assets/img/pk/defeat.png" alt="">
                      <!-- <button style="font-size: 12px; background-color: #dd5d47!important; color: #fff "
                        class="sefl_pk_button">
                        <div class="self_pk_right"></div>
                        饮恨败北
                        <div class="self_pk_right"></div>
                      </button> -->
                    </div>

                  </div>

                  <!-- 当不是进行中的时候显示 -->
                  <div v-if="detailsData.status == 0 || detailsData.status == 3" :class="item.user.id == user_info.id ? 'waitting_me':'waitting'">
                    <img class="prepare" src="@/assets/img/pk/prepare.png" alt="">
                    <img class="round" src="@/assets/img/pk/round.png" alt="">
                    <img class="added" src="@/assets/img/pk/added.png" alt="">
                    <div class="status_text">准备完毕</div>
                    <!-- <div class="m-auto"> -->
                      <!-- <button class="sefl_pk_button">
                        <div class="self_pk_right"></div>
                        准备就绪
                        <div class="self_pk_right"></div>
                      </button> -->
                      <!--<img width="50px" height="50px" class="rounded-pill"
 :src="item.user.avatar" alt="">
<div class="mt-2 text-overflow-1" style="font-size: 12px">{{item.user.name}}</div>-->
                    <!-- </div> -->
                  </div>

                  <!-- 进行中的时候显示 -->
                  <div v-if="detailsData.status == 1">
                    <div v-if="moneyJieShu" style="font-size: 20px;line-height:20px;"
                      class="d-flex justify-content-center align-items-center">
                      <money :font-size="'20px'" />
                      <countTo :decimals="2" :startVal='0' :endVal='(item.money) * 1' :duration='1000'>
                      </countTo>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 用户 -->
            <div :class="item.success ? ' active' : detailsData.status == 2 ? 'pk_lose_bg1' : ''"
              style=""
              class="d-md-flex content-pk-item-sum align-items-center border-left-0 border-right-0 userinfo">
              <div v-if="detailsData.status == 2 && item.sum" class="total_sum text-center justify-content-center d-flex"
                style="line-height:20px;">
                <!-- 结束的时候显示 -->
                <span class="d-md-block" v-if="detailsData.user_num < 4">本轮累计：</span>
                <money class="ml-2" width="18px"></money><span style="color: #F9C545;"> {{ item.sum }}</span>
              </div>
              <img width="30px" height="30px" class="rounded-pill" :src="item.user.avatar" alt=""
                style="margin-bottom:10px">
              <div class="d-flex align-items-center justify-content-center">
                <div class="text-overflow-1">{{ item.user.name }}</div>
              </div>
              <!-- <div v-if="detailsData.status == 0" class="d-md-block ">
                {{ detailsData.status_alias }}
              </div> -->
              <div v-if="detailsData.status == 1" class="d-flex" style="line-height:20px;">
                <!-- 进行中显示 -->
                <!-- <span class="d-md-flex d-none">{{ detailsData.status_alias }} </span> -->
                <!-- 金币 -->
                <money class="ml-2"></money>
                <!-- 使用数字滚动插件 -->
                <countTo :decimals="2" :startVal='0' :endVal='(item.money) * 1' :duration='1000'></countTo>
              </div>
            </div>

            <!-- 获奖展示 进行中 -->
            <div v-if="detailsData.status == 1" class="d-flex  flex-wrap w-12">
              <div class="content-award-1 col-lg-4 col-md-4 col-sm-6 col-12 animate__animated "
                :class="t.skins.game_gun ? 'content-award-active' : ''" v-for="(t, i) in item.game_award_pk" :key="i">
                <div class="game_award">
                  <img width="55%" :src="t.skins.cover" alt="">
                  <div class="text-overflow-1 text-center" style="font-size: 12px">
                    {{ t.skins.name }}
                  </div>
                  <div class="mt-2 d-flex align-items-center justify-content-center"
                    style="font-size: 12px; line-height: 20px; color: var(--pk-green)">
                    <money font-size="12px"></money>
                    {{ t.skins.bean }}
                  </div>
                  <div class="bottom_line"></div>
                </div>
              </div>
            </div>


            <!-- 结束 -->
            <div v-if="detailsData.status == 2" class="d-flex 
          flex-wrap w-12">
              <div class="content-award-1 col-lg-4 col-md-4 col-sm-6 col-12 " v-for="(t, i) in item.game_award" :key="i">
                <div class="game_award">
                  <img width="55%" :src="t.skins.cover" alt="">
                  <div class="text-overflow-1 text-center m-auto" style="font-size: 12px;width: 80%">
                    {{ t.skins.name }}
                  </div>
                  <div class="mt-2 d-flex align-items-center justify-content-center"
                    style="font-size: 12px; line-height: 20px; color: var(--pk-green)">
                    <money font-size="12px"></money>
                    {{ t.skins.bean || (fildErr / yessSum).toFixed(2) }}
                  </div>
                  <div class="bottom_line"></div>
                </div>
              </div>
            </div>

          </div>

          <template v-if="!item">
            <div class="overflow-hidden ">
              <div class="content-pk-item-pk-box-item w-100 waitting">
                <img class="waitimg" src="@/assets/img/pk/wait.webp" alt="">
                <div class="status_text">等待中...</div>
                <!-- <div style="" @click="addPk(index)" class="text-center ">
                  <button style="font-size: 16px;color:#fff" class="sefl_pk_button">
                    <div class="self_pk_right"></div>
                    {{ peopleStatus == 2 ? '等待加入中' : '点击加入' }}
                    <div class="self_pk_right"></div>
                  </button>
                </div> -->
              </div>
              <div
                class=" d-md-flex content-pk-item-sum align-items-center border-left-0 border-right-0 addPkF">
                <button @click="addPk(index)" class="addPk">
                  <img src="@/assets/img/pk/add_pk.png" alt="">
                </button>
              </div>
            </div>
          </template>

        </div>
      </div>

      <!-- 几人房间 -->
      <!--        <div class="h3 mt-4 text-center"><span class="color-yellow h1">{{detailsData.user_num}}</span> 人房间</div>-->

      <!-- 对战内容 -->
      <!--
      item.success 胜利的时候时空，下面胜利的时候添加active 进行中肯定是空的，之后判断是不是结束的状态 是的话添加灰度，不是为空
      下面的是的话添加reeor
      :class="item.success ? '': detailsData.status == 2 ? 'filter-grayscale-1':''"
      -->

    </div>
    <!-- 宝箱内的武器 -->
    <div v-if="lockBoxGunIsShow">
      <div style="" class="position-fixed pop-up content-pop z-index-100">
        <div>
          <div class="h5 mt-3 ml-3">包含以下奖励</div>
          <div class="d-flex flex-wrap content-pop-lock align-content-start">

            <div v-for="(item, index) in boxGunListContains" :key="index"
              class="content-pop-lock-item col-lg-3 col-md-3 col-6 " :class="'all-bg'+item.level">
              <img width="100%" class="mx-auto d-block mt-3" :src="item.skins.cover" alt="">
              <div class="text-center px-3 text-overflow-1">
                <div class="gunboxtext text-overflow-1">{{ item.skins.name }}</div>
              </div>
              <div class="mx-auto d-flex justify-content-between align-items-center  text-center p-2  mt-2 cursor-pointer"
                style="">
                <div class="text-overflow-1" style="font-size: 12px;color:#ddd ">{{ item.skins.dura_alias }}</div>
                <div class="flex" style="font-size: 12px;line-height:20px; color: #75dc9e; font-weight: 900; ">
                  <money /> {{ item.skins.bean }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div @click="lockBoxGunIsShow = false" class="position-absolute" style="right: 20px;top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi-02-02 cursor-pointer" style="font-size:30px"></i>
          </div>
        </div>
      </div>
      <div class="position-fixed pk_gun_bg op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
    </div>
    <audio preload="auto" ref="audio" :src="openMp"></audio>
  </div>
</template>

<script>

import $api from '@/api/pk/index'
import countTo from 'vue-count-to';
import { mapState } from 'vuex'
export default {
  name: "phoneWeb",
  components: { countTo },
  data() {
    return {
      openMp: require('@/assets/music/open1.mp3'),
      // 声音开关
      voiceSwitch: true,
      // 对战ID
      game_arena_id: '',
      // 渲染页面 数据 对战详情 数据在disposalData这个函数兰的，需要整理数据
      detailsData: {},
      // 对战回合显示
      box_num: 0,
      // 找到哪一个宝箱进行循环遍历
      indexBox: 0,
      urlType: '',
      // 动画
      openAn: false,
      // 渲染页面显示不出来了，放外面一个
      status: 0,
      // 对战结束的时候弹出窗口
      Against_the_end: false,
      // 有几人加入了
      sum: 0,
      // 失败这的金额
      fildErr: 0,
      // 中将人数
      yessSum: 1,
      // 是否结束 0等待 2结束代表这正在进行
      full_house: 0,

      successAndErr: false,
      // 对战结束的时候
      moneyJieShu: false,
      // 进行中使用 underway进行到多少回合
      underway: 0,
      // 是否是进行中round = 1为进行中，别的没传此参数
      round: null,
      //
      detailsDataUnderway: {},
      // 看士第几回合进来的
      box_huihe: 0,
      // 两个定时器
      time1: null,
      tiem2: null,
      time: null,
      // 显示前面几回合的枪支
      huihe_gun: true,
      // 指示灯
      game_gun: false,
      // settem的定时器
      settime: null,

      // 包厢内武器
      boxGunListContains: [],
      lockBoxGunIsShow: false,
      currentType: 1,
      hisPageRecord: null,
      peopleStatus: 0,
      activeStatus: true,

      slotList: [
        {},
        {},
        {},
        {}
      ],
      slotIndex: 0,
      slotAn: false,
      slotNumber: 0,
      slotY: 0,
      slotStatus: 0,
      slotSuccess: []
    }
  },
  computed: {
    user_num: {
      get() {
        return this.detailsData.user_num - this.detailsData.game_arena_player.length
      },

    },
    ...mapState(['user_info']),
  },
  mounted() {
    window.scrollTo(0, 0)
    document.getElementById('content').scrollTop = 0;
    this.$refs.audio.load()
    this.init()

  },
  created() {
    this.game_arena_id = this.$route.params.id
    this.sum = this.$route.query.sum
    // 进行中使用 underway进行到多少回合
    // 是否是进行中round = 1为进行中，别的没传此参数
    this.urlType = this.$route.query.typeurl
    this.hisPageRecord = String(this.$route.query.hisPageRecord) == 'undefined' ? 1 : this.$route.query.hisPageRecord;
    this.peopleStatus = this.$route.query.peopleStatus;
    this.underway = this.$route.query.underway
    this.round = this.$route.query.round
    this.initWebSocket()
  },
  beforeDestroy() {
    clearInterval(this.time1)
    clearInterval(this.tiem)
    clearInterval(this.tiem2)
    clearTimeout(this.settime)
    this.$refs.audio.pause()
    this.$router.go(0)
  },
  watch: {
    detailsData: {
      handler(newVal) {
        this.detailsData = newVal
      },
      immediate: true,//页面监听开始的时候就直接调用：见上文解释
      deep: true,//见上文解释
    },

  },
  methods: {
    async joinBot(bot_id, game_arena_id) {
      let { code } = await $api.joinBot({
        bot_id,
        game_arena_id,
        seat: 1
      })
      console.log(code)
    },
    // 观战的时候显示
    async underwayPk() {

      let { data } = await $api.getPKarenaDetail(this.game_arena_id)
      let resData = JSON.parse(JSON.stringify(data))

      if (this.round > resData.box_num) {
        this.round = resData.box_num - 1
      }
      // resData.game_arena_box.splice(resData.game_arena_box.length*1 - this.round,this.round)
      resData.status = 1
      resData.status_alias = '进行中'
      let box_huihe = this.round * 1
      this.box_huihe = JSON.parse(JSON.stringify(box_huihe))
      this.indexBox = box_huihe
      await this.disposalData(resData)
      await this.turn()

    },
    async init() {
      // if (this.underway == 1) {
      //   await this.underwayPk()
      // } else {
      await this.getPKarenaDetail()
      // }
    },
    // 获取结束的时候数据 初始化
    async getPKarenaDetail() {
      try {
        let { data, code } = await $api.getPKarenaDetail(this.game_arena_id)
        if (code == 500) return
        

        if (data.status == 0) {
          // this.slotList = data.game_arena_box[this.slotIndex]?.box?.contains;
          for (var i = 0; i < data.user_num; i++) {
            this.slotList[i] = this.anwe_sort(data.game_arena_box[this.slotIndex]?.box?.contains)

          }
          for (var o = 0; o < data.game_arena_player.length; o++) {
            if (data.game_arena_player[o]?.game_award) {
              this.slotList[data.game_arena_player[o].seat].push(data.game_arena_player[o]?.game_award[this.slotIndex])
            } else {
              data.game_arena_player[o].game_award = []
            }
          }
          this.slotNumber = data.game_arena_box[this.slotIndex]?.box?.contains.length + 1;
          this.slotY = (this.slotNumber - 1) * 150
        } else if (data.status == 1) {
          let ii = data.current_round
          this.slotIndex = ii
          for (var j = 0; j < data.user_num; j++) {
            this.slotList[j] = this.anwe_sort(data.game_arena_box[ii - 1]?.box?.contains)
          }
          for (var o1 = 0; o1 < data.game_arena_player.length; o1++) {
            if (data.game_arena_player[o1]?.game_award) { 
              this.slotList[data.game_arena_player[o1].seat].push(data.game_arena_player[o1]?.game_award[this.slotIndex])
            } else {
              data.game_arena_player[o1].game_award = []
            }
            
          }
          // this.slotList = data.game_arena_box[i - 1]?.box?.contains;
          this.slotNumber = data.game_arena_box[ii - 1]?.box?.contains.length + 1;
          this.slotY = (this.slotNumber - 1) * 150;

        }

        let obj = this.disposalData(data)
        this.detailsData = obj
        if (obj.play_type == 2) {
          setTimeout(() => {
            // if( this.peopleStatus == 2 ){
            this.joinBot(this.$route.query.bot_id, this.$route.params.id)
            // }
          }, 3000)
        }

        let game_arena_player = JSON.parse(JSON.stringify(data))
        // 结束的时候
        if (this.detailsData.status == 2) {
          // 当超过两个人中将的时候
          if (obj.win_user_id.length >= 2) {
            document.getElementById('succeed').play()
            this.yessSum = obj.win_user_id.length
            // 计算总金额
            let sum = 0
            this.detailsData.game_arena_player.forEach((item) => {
              item.game_award.forEach(a => {
                sum = sum + (a.skins.bean * 1)
              })
            })
            this.detailsData.game_arena_player.forEach((item, index) => {
              item.success = false
              // 中奖的钱金额增加
              obj.win_user_id.forEach(t => {
                if (item.user_id == t) {
                  item.success = true
                  item.sum = (sum / obj.win_user_id.length).toFixed(2);
                }
              })
              // 失败的人
              if (!item.success) {
                // 获取失败人 枪支的总价格
                item.game_award.map(a => {
                  this.fildErr = this.fildErr + (a.skins.bean * 1)
                })
                // 这里是大于两人 显示的失败状态
                item.game_award = [
                  {
                    skins: {
                      cover: require('@/assets/img/liangliang.png'),
                      name: '惜败',
                      // bean:0.01
                    }
                  }
                ]
              } else { //胜利的人
                item.game_award.push({
                  skins: {
                    cover: require('@/assets/img/money.png'),
                    name: '钱袋子',
                    bean: 0
                  }
                })
              }
              item = game_arena_player.game_arena_player[index]
            })
          } else { // 中将这是一个人的时候
            let arrAllList = []
            this.detailsData.game_arena_player.forEach(item => {
              let arrList = JSON.stringify(item.game_award)
              arrAllList.push(...JSON.parse(arrList))
            })
            this.detailsData.game_arena_player.forEach(item => {
              item.game_award = arrAllList
              obj.win_user_id.map(t => {
                if (item.user_id == t) {
                  item.success = true
                  // 计算价格
                  let sum = 0
                  item.game_award.forEach(m => {
                    sum = sum + (m.skins.bean * 1)
                  })
                  // 输出价格
                  item.sum = sum.toFixed(2)
                  return item.game_award
                } else {
                  item.game_award = [
                    {
                      skins: {
                        cover: require('@/assets/img/liangliang.png'),
                        name: '惜败',
                        // bean:0.01
                      }
                    }
                  ]
                }
              })
            })
          }
        }
        if (this.detailsData.status == 1) {
          // console.log('进来了')
          // clearInterval(this.time1)
          // clearInterval(this.tiem)
          // clearInterval(this.tiem2)
          // clearTimeout(this.settime)
          // this.openAn = false;
          // document.getElementById('open').pause()
          // this.turnEverOne( Number(obj.current_round)-1 )

          setTimeout(() => {
            this.goingSocket(obj.id, this.user_info?.id)
          }, 200)
          this.currentType = 2;

        }
      } catch (e) {
        console.log(e)
      }
      setTimeout(() => {
        this.successAndErr = true
      }, 2000)
    },
    async goingSocket(id, user_id) {
      await $api.goingSocket({
        game_arena_id: id,
        user_id
      })
    },
    // 整理数据
    disposalData(res) {
      // 进行深拷贝对象，如果返回的是一个数组需要转换成对象的形式
      let data = JSON.parse(JSON.stringify(res))
      console.log(data);
      if (this.detailsData.status == 0) {
        // 等待的时候走着
        this.detailsData = data
        return
      }
      // 根据座位号排序
      // 下面是进行中
      data.game_arena_player.forEach((item, index, arr) => {

        // 全部宝箱给到每一个用户
        item.game_arena_box = JSON.parse(JSON.stringify(data.game_arena_box))
        // 进行中中将数组
        item.game_award_pk = []
        // 进行中的金额
        item.money = 0
        // 亮灯
        item.game_gun = false
        // 中将ID - 需要深拷贝，记住深拷贝
        let awar_id = JSON.parse(JSON.stringify(item.game_award))
        // 获取中将的数组
        let awar_id_arr = []
        // 奖中奖IDpush到中将数组中
        awar_id.forEach(i => {
          awar_id_arr.push(i)
        })

        // 判断是几个人，开始的，之后根绝这个给下面展示的枪支添加一个true或者空
        if (data.user_num == 2 && data.user_num == arr.length) {
          for (let j = 0; j < data.box_num; j++) {
            let sum = data.game_arena_player[0].game_award[j].skins.bean
            let sum2 = data.game_arena_player[1].game_award[j].skins.bean
            if (sum > sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
            } else {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
            if (sum == sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
          }
        }
        if (data.user_num == 3 && data.user_num == arr.length) {

          for (let j = 0; j < data.box_num; j++) {
            let sum = data.game_arena_player[0].game_award[j].skins.bean
            let sum2 = data.game_arena_player[1].game_award[j].skins.bean
            let sum3 = data.game_arena_player[2].game_award[j].skins.bean
            if (sum > sum2) {
              if (sum > sum3) {
                data.game_arena_player[0].game_award[j].skins.game_gun = true
              }
            }
            if (sum2 > sum) {
              if (sum2 > sum3) {
                data.game_arena_player[1].game_award[j].skins.game_gun = true
              }
            }
            if (sum3 > sum) {
              if (sum3 > sum3) {
                data.game_arena_player[2].game_award[j].skins.game_gun = true
              }
            }
            if (sum == sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
            if (sum == sum3) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
            if (sum2 == sum3) {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
          }
        }
        if (data.user_num == 4 && data.user_num == arr.length) {
          for (let j = 0; j < data.box_num; j++) {
            let sum = data.game_arena_player[0].game_award[j].skins.bean
            let sum1 = data.game_arena_player[1].game_award[j].skins.bean
            let sum2 = data.game_arena_player[2].game_award[j].skins.bean
            let sum3 = data.game_arena_player[3].game_award[j].skins.bean
            if (sum > sum1) {
              if (sum > sum2) {
                if (sum > sum3) {
                  data.game_arena_player[0].game_award[j].skins.game_gun = true
                }
              }
            }
            if (sum1 > sum) {
              if (sum1 > sum2) {
                if (sum1 > sum3) {
                  data.game_arena_player[1].game_award[j].skins.game_gun = true
                }
              }
            }
            if (sum2 > sum) {
              if (sum2 > sum1) {
                if (sum2 > sum3) {
                  data.game_arena_player[2].game_award[j].skins.game_gun = true
                }
              }
            }
            if (sum3 > sum) {
              if (sum3 > sum1) {
                if (sum3 > sum2) {
                  data.game_arena_player[2].game_award[j].skins.game_gun = true
                }

              }
            }
            if (sum == sum1) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
            if (sum == sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
            if (sum == sum3) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[3].game_award[j].skins.game_gun = true
            }
            if (sum1 == sum2) {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
            if (sum1 == sum3) {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
              data.game_arena_player[3].game_award[j].skins.game_gun = true
            }
            if (sum2 == sum3) {
              data.game_arena_player[2].game_award[j].skins.game_gun = true
              data.game_arena_player[3].game_award[j].skins.game_gun = true
            }
          }
        }

        // 全部的武器，进行遍历，之后和中将数组中的箱子id比较，如果相同直接返回对象之后渲染
        item.game_arena_box.forEach((e) => {
          let obj = awar_id_arr.find(t => {
            if (e.box_id == t.box_id) {
              // 防止重复的箱子重复循环
              t.box_id = ''
              return e
            }
          })
          let contains = []
          for (let i = 0; i < 70; i++) {
            let index = Math.floor(Math.random() * e.box.contains.length)
            contains.push(e.box.contains[index])
          }
          e.box.contains = [...contains]
          if (obj != undefined) {
            obj.level = 1
            e.box.contains.forEach((item) => {
              if (item.skin_id == obj.skins.id) {
                obj.level = item.level
              }
            })
            // 哪一个中将  现在是第一个
            obj.a = '1'
            e.box.contains.splice(60, 1, obj)
          }
        })
      })
      let arrData = JSON.parse(JSON.stringify(data))
      // data.game_arena_player.forEach(item=>{
      //   arrData.game_arena_player.splice(item.seat,1,item)
      // })

      // 新加 为了让参与的用户和回合数相等
      // arrData.game_arena_player.length = arrData.user_num

      let arrIndex = []
      arrIndex.length = arrData.user_num
      for (let index = 0; index < arrData.game_arena_player.length; index++) {
        let arrDataIndex = arrData.game_arena_player[index]
        arrIndex[arrDataIndex.seat] = arrDataIndex
      }
      arrData.game_arena_player = arrIndex

      // end 新加
      this.detailsData = arrData
      return arrData
    },
    // 打乱数组
    anwe_sort(arr) {
      let sort = []
      for (let i = 0; i < arr.length; i++) {
        let item = arr[Math.floor(Math.random() * arr.length)]
        sort.push(item)
      }
      return sort
    },
    // 点击加入对战
    async addPk(item) {
      // let seat = 0
      // switch (this.detailsData.game_arena_player.length*1) {
      //   case  1:
      //     seat = 1
      //     break;
      // }
      // console.log(seat)
      // if(this.detailsData.game_arena_player.length*1)return

      if (this.voiceSwitch) {
        document.getElementById('mysound').play()
      }
      try {
        let obj = {
          game_arena_id: this.game_arena_id,
          seat: item
        }
        let { message, code } = await $api.getPKarenaJoin(obj)
        if (code == 500) return
        if (code == 200) {
          this.$notify({
            message: message, position: 'top-left'
          });
          this.$store.dispatch('userInfo')
          this.user_num = this.sum * 1 + 1

        }

      } catch (e) {
        console.log(e)
      }

    },
    // 转动
    turnEverOne(indexBox) {

      this.openAn = true
      if (this.voiceSwitch) {
        this.$refs.audio.play()
      }
      if (this.currentType == 2) {
        if (this.detailsData.box_num != this.indexBox + 1) {
          this.indexBox++
        }
        this.detailsData.game_arena_player.forEach(item => {
          // 金币
          console.log(indexBox + 1);
          [...Array(indexBox + 1)].map((c, i) => {
            console.log(i);
            item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
          })
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, (indexBox) + 1)
          item.game_gun = item.game_award[indexBox].skins.game_gun;
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
          this.currentType = 1;
        })
      }

      // setTimeout(()=>{
      //   if( this.currentType == 3  ){
      //     this.$refs.audio.pause()
      //     this.$refs.audioCopy.play()
      //     this.currentType = 1;
      //   }
      // },8850)


      this.tiem = setTimeout(() => {
        this.detailsData.game_arena_player.forEach(item => {
          // 金币
          item.money = ((item.money * 1) + (item.game_award[indexBox].skins.bean * 1)).toFixed(2)
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, indexBox + 1)
          item.game_gun = item.game_award[indexBox].skins.game_gun
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
        })
      }, 5800)


      this.time1 = setTimeout(() => {
        if (indexBox == this.detailsData.box_num - 1) {
          this.detailsData.game_arena_player.map(item => {
            item.money = 0
          })
          this.moneyJieShu = true
          this.openAn = false
        } else {
          setTimeout(() => {
            this.openAn = false
          }, 900)
        }
        this.$refs.audio.pause()
        this.$refs.audioCopy.pause()
      }, 9000)
    },
    // 转动
    turnEverOne1(indexBox) {

      this.openAn = true
      if (this.voiceSwitch) {
        // this.$refs.audio.play()
      }
      if (this.currentType == 2) {
        if (this.detailsData.box_num != this.indexBox + 1) {
          this.indexBox++
        }
        this.detailsData.game_arena_player.forEach(item => {
          [...Array(indexBox)].map((c, i) => {
            item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
          })
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, indexBox)
          item.game_gun = item.game_award[indexBox].skins.game_gun;
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
          this.currentType = 1;
        })
      }

      // setTimeout(()=>{
      //   if( this.currentType == 3  ){
      //     this.$refs.audio.pause()
      //     this.$refs.audioCopy.play()
      //     this.currentType = 1;
      //   }
      // },8850)

      this.tiem = setTimeout(() => {
        this.detailsData.game_arena_player.forEach(item => {
          // 金币
          item.money = ((item.money * 1) + (item.game_award[indexBox].skins.bean * 1)).toFixed(2)
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, indexBox + 1)
          item.game_gun = item.game_award[indexBox].skins.game_gun
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
          item.game_gun = false
        })
      }, 5800)
      this.time1 = setTimeout(() => {
        if (indexBox == this.detailsData.box_num - 1) {

          this.detailsData.game_arena_player.map(item => {
            item.money = 0
          })
          this.moneyJieShu = true
          // this.detailsData.status = 2
          this.openAn = false
        } else {
          setTimeout(() => {
            this.openAn = false
          }, 900)
        }
        this.$refs.audio.pause()
      }, 9000)
    },
    // 转动
    turn() {
      if (this.round == this.detailsData.box_num) {
        this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=0&round=' + 0)
        this.$router.go(0)
        return
      }
      if (this.round > 0) {
        this.indexBox = this.round * 1
        this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=1&round=' + this.round)
        this.detailsData.game_arena_player.forEach(item => {
          // 金币
          item.money = ((item.money * 1) + (item.game_award[this.indexBox].skins.bean * 1)).toFixed(2)
          // 进行中的展示奖品
          console.log('item.game_award')
          console.log(item.game_award.slice(0, this.indexBox))
          item.game_award_pk = item.game_award.slice(0, this.indexBox)
        })
      }
      // 第一次转动
      this.settime = setTimeout(() => {

        this.openAn = true
        if (this.voiceSwitch) {
          document.getElementById('open').play()
        }
      }, 100)
      // 后面的几次转动
      if (this.detailsData.status == 1) {
        /* 不知道为啥需要两个定时器 */
        this.tiem = setInterval(() => {
          this.detailsData.game_arena_player.forEach(item => {
            // 金币
            item.money = ((item.money * 1) + (item.game_award[this.indexBox].skins.bean * 1)).toFixed(2)
            // 进行中的展示奖品
            item.game_award_pk.push(item.game_award[this.indexBox])
            item.game_gun = item.game_award[this.indexBox].skins.game_gun
            setTimeout(() => {
              item.game_gun = false
            }, 2000)
          })
        }, 8900)
        // 10秒的话停顿2秒 css控制的8秒
        this.time1 = setInterval(async () => {

          this.indexBox += 1
          if (this.indexBox >= this.detailsData.box_num) {
            try {
              document.getElementById('open').pause()

              this.indexBox = 0
              clearInterval(this.time1)
              clearInterval(this.tiem)
              this.openAn = false
              this.moneyJieShu = true
              this.detailsData.game_arena_player.map(item => {
                item.money = 0
                item.success = false
                item.gun = false
                item.game_award_pk.forEach(it => {
                  it.gun = false
                })
                this.detailsData.win_user_id.forEach(t => {
                  if (item.user_id == t) {
                    item.success = true
                  }
                })
                return item
              })
              let i = 0
              this.tiem2 = setInterval(() => {
                // if(i > this.detailsData.game_arena_player.length + 1){
                //   this.getPKarenaDetail() // 初始化数据 - 结束的时候
                //   this.detailsData.status = 2
                //   clearInterval(this.tiem2)
                // }
                try {
                  this.detailsData.game_arena_player.forEach(item => {
                    // 金币
                    item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
                  })
                } catch (e) {
                  i = this.detailsData.game_arena_player.length + 2
                  this.getPKarenaDetail() // 初始化数据 - 结束的时候
                  this.detailsData.status = 2
                  clearInterval(this.tiem2)
                }
                i++

              }, 2000)
              if (this.$route.name == 'mysteryBoxs') {
                this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=0&round=' + this.indexBox)
              }
            } catch (e) {
              console.log(e)
            }
          } else {
            this.openAn = false
            setTimeout(() => {
              this.openAn = true
            }, 100)
            if (this.$route.name == 'mysteryBoxs') {
              this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=1&round=' + this.indexBox)
            }
            if (this.voiceSwitch) {
              document.getElementById('open').play()
            }
          }
        }, 9000)

      }
    },
    timeCeil(Data) {
      // 结束时间

      setInterval(() => {

        var time1 = Date.parse(Data.updated_at) / 1000
        // 当前时间
        var time2 = Date.parse(new Date()) / 1000
        // 删除数据 这个位置直记没有走
        let ceil = Math.ceil((time1 + Data.box_num * 10 - time2) / 10)// 得到回合数
        Data.ceil = Data.box_num - ceil
      }, 3000)
    },
    // 打开弹窗查看武器
    lockBoxGun(item) {
      this.lockBoxGunIsShow = true
      this.boxGunListContains = item.box.contains
    },
    /* 实时通讯 */
    initWebSocket() {
      let _this = this
      this.$ws.addEventListener('message', (event) => {
        let data = event.data
        let { Data, MsgType } = JSON.parse(data)
        /*  创建对战 */
        if (MsgType == 'CreateGroup') {
          if (Data.id == _this.game_arena_id) {
            this.detailsData.status = Data.status
          }
        }
        // 进入房间如果还在进行中
        if (MsgType == 'goingGroup') {
          if (this.game_arena_id == Data.game_arena_id && this.user_info?.id == Data.data.user_id) {
            this.slotSuccess = Data.win_user_id;
            this.slotAn = false;
            this.slotIndex = Data.current_round
            for (var i = 0; i < this.detailsData.user_num; i++) {
              this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[Data.current_round - 1]?.box?.contains);

              this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[Data.current_round - 1])
            }
            // this.slotList = this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains
            this.slotNumber = this.detailsData.game_arena_box[Data.current_round - 1]?.box?.contains.length + 1;
            this.slotY = (this.slotNumber - 1) * 150;
            console.log(`当前回合${this.slotIndex}`)
            this.$refs.audio.pause()
            this.$refs.audio.play()
            this.slotAn = true;
            this.clearReast1()
            _this.indexBox = Number(Data.current_round) - 1
            _this.turnEverOne1(Number(Data.current_round) - 1)

            var time1 = setInterval(() => {
              if (_this.detailsData.game_arena_box.length == this.slotIndex) {
                console.log('本场结束')
                this.clearData(this.slotSuccess)
                clearInterval(time1)
              } else {
                this.slotAn = false;
                this.$refs.audio.pause()
                this.$refs.audio.play()
                this.slotAn = true;
                this.slotIndex = Number(this.slotIndex) + 1
                console.log(`当前回合${this.slotIndex}`)
                this.clearReast1()

                _this.detailsData.status = Data.status
                _this.detailsData.status_alias = Data.status_alias
                // 需要判断是不是当前页面，当前页面需要执行动画，不是当前页面不执行动画
                if (this.$route.name == 'mysteryBoxs' && this.game_arena_id == Data.game_arena_id) {
                  _this.indexBox = this.slotIndex - 1
                  console.log(`当前回合${this.slotIndex}`)

                  let _data = Data.data;
                  _data.game_arena_box = _this.detailsData.game_arena_box
                  if (_this.indexBox == 0) {
                    _this.disposalData(_data)
                  }

                  this.openAn = false
                  clearTimeout(this.time1)
                  clearTimeout(this.tiem)

                  _this.turnEverOne1(_this.indexBox)
                  // if( _this.indexBox > 0 ){
                  //   _this.detailsData.game_arena_player.forEach(item => {
                  //     // 金币
                  //     item.money = ((item.money * 1) + (item.game_award[Data.current_round-1].skins.bean * 1)).toFixed(2)
                  //     // 进行中的展示奖品
                  //     item.game_award_pk = item.game_award.slice(0, Data.current_round-1)
                  //   })
                  // }
                }
              }
            }, 6000)

          }
        }
        /* 加入房间 */
        if (MsgType == "joinGroup") {
          if (Data.game_arena_id == _this.game_arena_id) {
            let data = JSON.parse(JSON.stringify(_this.detailsData.game_arena_player[0]))
            let { game_arena_box, game_award_pk, money } = data
            let obj = {
              seat: Data.seat,
              game_arena_id: Data.game_arena_id,
              user: {
                siyecao: '四叶草',
                id: Data.user_id,
                name: Data.name,
                avatar: Data.avatar,
              },
              game_arena_box,
              game_award: Data.awards_res,
              game_award_pk,
              money
            }
            _this.detailsData.game_arena_player.splice(obj.seat, 1, obj)

            if (_this.detailsData.user_num == 2) {
              _this.slotList[1].push(Data.awards_res[0])
            } else {
              _this.slotList[Data.seat].push(Data.awards_res[0])
              console.log(_this.slotList)
              console.log(Data);
            }
          }
        }
        /* 进行中状态 */
        if (MsgType == "startGroup" && Data.game_arena_id == _this.game_arena_id) {
          this.slotSuccess = Data.win_user_id;
          if (this.slotStatus == 0) {
            this.slotAn = false;
            this.$refs.audio.pause()
            this.$refs.audio.play()
            this.slotAn = true;
            this.slotIndex = this.slotIndex + 1
            this.clearReast()

            _this.detailsData.status = Data.status
            _this.detailsData.status_alias = Data.status_alias
            // 需要判断是不是当前页面，当前页面需要执行动画，不是当前页面不执行动画
            if (this.$route.name == 'mysteryBoxs' && this.game_arena_id == Data.game_arena_id) {
              _this.indexBox = this.slotIndex - 1


              const game_arena_player = Data.data.game_arena_player
              for (var ii = 0; ii < this.detailsData.user_num; ii++) {
                this.slotList[ii] = this.anwe_sort(this.detailsData.game_arena_box[Data.current_round - 1]?.box?.contains);
                this.slotList[ii].push(game_arena_player[ii].game_award[Data.current_round - 1])
              }

              let _data = Data.data;
              _data.game_arena_box = _this.detailsData.game_arena_box
              if (_this.indexBox == 0) {
                _this.disposalData(_data)
              }

              this.openAn = false
              clearTimeout(this.time1)
              clearTimeout(this.tiem)

              _this.turnEverOne(_this.indexBox)
              // if( _this.indexBox > 0 ){
              //   _this.detailsData.game_arena_player.forEach(item => {
              //     // 金币
              //     item.money = ((item.money * 1) + (item.game_award[Data.current_round-1].skins.bean * 1)).toFixed(2)
              //     // 进行中的展示奖品
              //     item.game_award_pk = item.game_award.slice(0, Data.current_round-1)
              //   })
              // }
            }


            var time = setInterval(() => {
              if (_this.detailsData.game_arena_box.length == this.slotIndex) {
                this.clearData(this.slotSuccess)

                clearInterval(time)
              } else {
                this.slotAn = false;
                this.$refs.audio.pause()
                this.$refs.audio.play()
                this.slotAn = true;
                this.slotIndex = this.slotIndex + 1
                this.clearReast()

                _this.detailsData.status = Data.status
                _this.detailsData.status_alias = Data.status_alias
                // 需要判断是不是当前页面，当前页面需要执行动画，不是当前页面不执行动画
                if (this.$route.name == 'mysteryBoxs' && this.game_arena_id == Data.game_arena_id) {
                  _this.indexBox = this.slotIndex - 1

                  let _data = Data.data;
                  _data.game_arena_box = _this.detailsData.game_arena_box
                  if (_this.indexBox == 0) {
                    _this.disposalData(_data)
                  }

                  this.openAn = false
                  clearTimeout(this.time1)
                  clearTimeout(this.tiem)

                  _this.turnEverOne(_this.indexBox)
                  // if( _this.indexBox > 0 ){
                  //   _this.detailsData.game_arena_player.forEach(item => {
                  //     // 金币
                  //     item.money = ((item.money * 1) + (item.game_award[Data.current_round-1].skins.bean * 1)).toFixed(2)
                  //     // 进行中的展示奖品
                  //     item.game_award_pk = item.game_award.slice(0, Data.current_round-1)
                  //   })
                  // }
                }
              }
            }, 6000)

          }

          // else if (this.slotStatus == 1) {
          //   // 刷新页面后的逻辑
          //   this.slotAn = false;
          //   this.slotIndex = this.slotIndex + 1
          //   for (var i = 0; i < this.detailsData.user_num; i++) {
          //     this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains);

          //     this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[this.slotIndex - 1])
          //   }
          //   // this.slotList = this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains
          //   this.slotNumber = this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains.length + 1;
          //   this.slotY = (this.slotNumber - 1) * 150;
          //   setTimeout(() => {
          //     this.slotAn = true;
          //     this.clearReast1()
          //   }, 500)
          // }


        }
        /* 结束 */
        if (MsgType == "endGroup") {
          if (Data.id == _this.game_arena_id) {
            // this.detailsData.win_user_id = Data.win_user_id;
            // this.$refs.audio.pause()
            // this.indexBox = 0
            // this.activeStatus = false;
            // clearTimeout(this.time1)
            // clearTimeout(this.tiem)
            // this.openAn = false
            // this.moneyJieShu = true
            // this.detailsData.game_arena_player.map(item => {
            //   item.money = 0
            //   item.success = false
            //   item.gun = false
            //   item.game_award_pk.forEach(it => {
            //     it.gun = false
            //   })
            //   this.detailsData.win_user_id.forEach(t => {
            //     if (item.user_id == t) {
            //       item.success = true
            //     }
            //   })
            //   return item
            // })
            // let i = 0
            // this.tiem2 = setInterval(() => {
            //   // if(i > this.detailsData.game_arena_player.length + 1){
            //   //   this.getPKarenaDetail() // 初始化数据 - 结束的时候
            //   //   this.detailsData.status = 2
            //   //   clearInterval(this.tiem2)
            //   // }
            //   try {
            //     this.detailsData.game_arena_player.forEach(item => {
            //       // 金币
            //       item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
            //     })
            //   } catch (e) {
            //     i = this.detailsData.game_arena_player.length + 2
            //     this.getPKarenaDetail() // 初始化数据 - 结束的时候
            //     this.detailsData.status = 2
            //     clearInterval(this.tiem2)
            //   }
            //   i++

            // }, 2000)
          }
        }
      })
    },
    clearData(slotSucc) {
      // this.detailsData.win_user_id = Data.win_user_id;
      this.$refs.audio.pause()
      this.indexBox = 0
      this.activeStatus = false;
      clearTimeout(this.time1)
      clearTimeout(this.tiem)
      this.openAn = false
      this.moneyJieShu = true
      this.detailsData.game_arena_player.map(item => {
        item.money = 0
        item.success = false
        item.gun = false
        item.game_award_pk.forEach(it => {
          it.gun = false
        })
        slotSucc.forEach(t => {
          if (item.user_id == t) {
            item.success = true
          }
        })
        return item
      })
      let i = 0
      this.tiem2 = setInterval(() => {
        // if(i > this.detailsData.game_arena_player.length + 1){
        //   this.getPKarenaDetail() // 初始化数据 - 结束的时候
        //   this.detailsData.status = 2
        //   clearInterval(this.tiem2)
        // }
        try {
          this.detailsData.game_arena_player.forEach(item => {
            // 金币
            item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
          })
        } catch (e) {
          i = this.detailsData.game_arena_player.length + 2
          this.getPKarenaDetail() // 初始化数据 - 结束的时候
          this.detailsData.status = 2
          clearInterval(this.tiem2)
        }
        i++

      }, 2000)
    },
    clearReast() {
      setTimeout(() => {

        for (var i = 0; i < this.detailsData.user_num; i++) {
          this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[this.slotIndex]?.box?.contains);

          this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[this.slotIndex])
        }

        this.slotNumber = this.detailsData.game_arena_box[this.slotIndex]?.box?.contains.length + 1;
        this.slotY = (this.slotNumber - 1) * 150;
        this.slotAn = false;

      }, 5600)

    },
    clearReast1() {
      setTimeout(() => {
        for (var i = 0; i < this.detailsData.user_num; i++) {
          this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[this.slotIndex]?.box?.contains);

          this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[this.slotIndex])
        }
        this.slotNumber = this.detailsData.game_arena_box[this.slotIndex]?.box?.contains.length + 1;
        this.slotY = (this.slotNumber - 1) * 150;
        this.slotAn = false;
        this.slotStatus = 0
      }, 5200)
    }

  },
}
</script>

<style scoped lang="scss">
.pop-up {
  left: 50% !important;
}
.slotMatchinaSuccess {
  position: relative;
  >.content-pk-item-pk {
    &::before {
      opacity: 0;
    }
  }
}

.slotMatchina {
  display: flex;
  margin: 20px 0;
  position: relative;
  .slotDongF {
    background-image: url('../../../assets/img/pk/fail.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    .line_light {
      content: "";
      position: absolute;
      top: 65px;
      width: 50%;
      left: 25%;
      z-index: 999;
      height: 1px;
      opacity: .5;
      background-image: linear-gradient( 90deg,hsla(0,0%,100%,0),#bf89fd,hsla(0,0%,100%,0));
    }
  }
  .slotDongF_me {
    @extend .slotDongF;
    background-image: url('../../../assets/img/pk/blue_bg.png');
  }
  .successbgF {
    width: 100%;
    height: auto;
  }
  .successbg {
    background-image: url("../../../assets/img/pk/content_success.png");
    width: 100%;
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80%;
    }
    .victory {
      animation: victoryMove 1.5s ease 0s 1;
    }
    @keyframes victoryMove {
      0% {
        transform: scale(1)
      }
      50% {
        transform: scale(1.5)
      }
      100% {
        transform: scale(1)
      }
    }
  }
  .failbg {
    @extend .successbg;
    background-image: url("../../../assets/img/pk/fail.png");
    img {
      width: 60%;
    }
  }
  .failbg_me {
    @extend .failbg;
    background-image: url("../../../assets/img/pk/blue_bg.png");
  }

  .waitting {
    @extend .successbg;
    background-image: url("../../../assets/img/pk/fail.png");
    position: relative;
    >img {
      position: absolute;
      margin-top: -20px;
    }
    .round {
      width: 55px;
    }
    .prepare {
      width: 25px;
    }
    .added {
      width: 20px;
    }
    >div {
      position: absolute;
      bottom: 10px;
      font-size: 11px;
    }
    .waitimg {
      width: 60px;
    }
  }
  .waitting_me {
    @extend .waitting;
    background-image: url("../../../assets/img/pk/blue_bg.png");
  }

  .addPkF {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .addPk {
    border: none;
    width: calc(1.2 * 15vw + 10px);
    img {
      width: 100%;
    }
  }

  .slotDong {
    overflow: hidden;
    width: 100%;
    height: 150px;
    margin-top: -15px;

    >.slotListBox {
      transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -moz-transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -webkit-transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -ms-transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -o-transition: all 4.6s cubic-bezier(0, 0, 0, 1);

      >.ls {
        height: 150px;
        text-align: center;

        >img {
          margin-top: 20px;
        }

        >p {
          font-family: Monaco;
          font-size: 12px;
          width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0 auto;
          white-space: nowrap;
        }

        >.slotMoney {
          margin-top: 4px;
          display: flex;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          color: #2AC88A;

          >img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }

    >.slotListBox1 {
      >.ls {
        height: 150px;
        text-align: center;

        >img {
          margin-top: 20px;
        }

        >p {
          font-family: Monaco;
          font-size: 12px;
          width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 auto;

        }

        >.slotMoney {
          margin-top: 4px;
          display: flex;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          color: #2AC88A;

          >img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  gap: 2%;

  >div {
    flex: 1;
    background: rgba(34,21,97,0.5);
    
    position: relative;
  }
}

.col-4 {
  flex: 0 0 33.3%
}

.weaponBox {
  background-image: url("../../../assets/img/pk/content_2_bg.png");
  background-repeat: no-repeat;
  background-position: center 100%;
  background-size: 160%;
  &:not(:nth-child(1))::before {
    content: '';
    background-image: url("../../../assets/img/pk/arrow.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -13%;
    top: 60%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
  }
  >.weapon {
    position: absolute;
    height: 30px;
    width: auto !important;
    z-index: 99;
    left: 50%;
    top: 12px;
    margin-left: -20px;
  }
  img {
    margin-top: -10px;
  }
  &.active_bg {
    background-image: url("../../../assets/img/pk/content_2_1_bg.png");
  }
}

.userinfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .total_sum {
    align-items: center;
    font-size: 10px;
    white-space: nowrap;
    position: absolute;
    top: 5px;
  }
}

.ftiitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .game_type_name {
    font-size: 12px;
    .type1 {
      padding: 3px;
      background: #1e9bbb;
      border-radius: 3px;
    }
    .type0 {
      @extend .type1;
      background: #e95500;
    }
  }
  .left {
    font-size: 12px;
    >span:nth-child(1) {
      padding: 5px 10px 4px 10px;
      background: #3F389B;
      border: 2px solid #6D68D8;
      border-radius: 6px;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      line-height: 15px;
    }
  }
  .right {
    font-size: 12px;
  }
}

@media (max-width:600px) {

  .content-pk-item-pk-box {
    .content-pk-item-pk-box-item:first-child {
      >div {
        // height: 66px !important;
      }
    }
  }

}

.gunboxtext {
  font-size: 12px;
  position: absolute;
  top: 10px;
  color: #ddd
}

.active_light {
  position: relative;

  &::before {
    content: '';
    /*background-image: url("../../assets/img/pk/bg.png");*/
    width: 100%;
    left: 0 !important;
    position: absolute;
    top: 100%;
    left: 0 !important;
    z-index: -1;
    animation: light 1s;
    box-shadow: 0 0 50px 20px rgba(255, 0, 0, .8);
    animation-iteration-count: 2;
    opacity: 0;
  }
}

.active_light_err {
  position: relative;

  &::before {
    content: '';
    background-size: 80%;

    width: 100%;
    left: 0 !important;
    position: absolute;
    top: 100%;
    left: 0 !important;
    z-index: -1;
    animation: light 1s;
    box-shadow: 0 0 50px 20px rgba(63, 63, 63, .8);
    animation-iteration-count: 2;
    opacity: 0;
  }
}

.successAndErr {
  display: none;
}

.loading-zhuan {
  animation: clockwise_rotation 2.5s linear infinite;
}

/* 定义动画 */

.an .an-item {
  // transition: all 9s cubic-bezier(0, 0, 0, 1) .1s;
  // transform: translateY(-21000px)
  animation-play-state: running;
  animation: mymove 9.4s cubic-bezier(0, 0, 0, 1) .1s infinite;
}

.an1 .an-item {
  animation-play-state: paused
}

@keyframes mymove {
  0% {
    transform: translateY(0px)
  }

  100% {
    transform: translateY(-7800px)
  }
}

.content {
  width: 90%;
  margin: 0 auto;
  font-size: 12px;

  @media (max-width:1200px) {
    width: 96%;
  }

  &-bout {
    width: 96%;
    padding: 10px 15px;

    @media (max-width:1200px) {
      align-items: initial !important;
      overflow-x: auto;
    }

    &-sum {
      padding: 0 20px 0 0px;

      @media (max-width:1200px) {
        display: none;
      }
    }

    &-box {
      &-item {
        margin: 0 6px;
        position: relative;

        & img {
          @media (max-width:1200px) {
            width: 50px !important;
            margin-bottom: 10px;
          }
        }

        & div {
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 12px;
        }
      }

      & .active div:nth-child(1) {
        border: solid 1px #ffc67bb4;
        border-radius: 4px;
        box-shadow: 0 0 15px #FFC67B;
      }
    }

    &-add-pk {
      @media (max-width:1200px) {
        display: none;
      }
    }
  }

  &-pk {
    &-item {
      margin-top: 0px;

      &-sum {
        padding: 10px 30px;

        @media (max-width:1200px) {
          padding: 5px 2px;
          font-size: 10px;
          text-align: center;
          height: 104px !important
        }
      }

      &-pk {
        overflow: hidden;
        // padding: 10px 0px;
        height: 100px;
        position: relative;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;

        &::before {
          /* 中将标识 */
          /*content: url("../../assets/img/aysmk.png");*/
        }

        &-box {
          height: 100px;

          &-item {
            /*border-top: 1px solid rgba(251,202,1,1);*/
            /*background-image: linear-gradient(0deg,rgba(251,202,1,1), rgba(0,0,0,0));*/
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
            /*padding: 10px 30px;*/
            height: 80px;
            margin: 0px 0px;

            &-gun {
              /*padding: 20px;*/

              @media (max-width: 1200px) {
                width: 80%;
                text-align: center;
              }
            }
          }
        }
      }

      &-pk-bg {
        background-size: 80%;
      }

      & .active {
        /*background-image: url("../../assets/img/success.png");*/
        background-size: 100%;
      }

      & .error {
        /*background-image: url("../../assets/img/error.png");*/
        background-size: 100%;
      }
    }
  }

  &-award-1 {
    /*background-image: linear-gradient(0deg,rgba(251,202,1,1), rgba(0,0,0,0));*/
    /*margin: 0 2px;*/
    /*border-top: 2px solid rgba(251,202,1,1);*/
    /*width: 230px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;

    .game_award {
      margin: 3% 0;
      padding: 10px;
      background-image: radial-gradient(circle at bottom, #6c62a5 -50%, #221561);
      border-radius: 4px;
      text-align: center;
      position: relative;
      
      .bottom_line {
        position: absolute;
        height: 0.08rem;
        background: #8f4aff;
        bottom: 0;
        width: 50%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-active {

      &::before {
        content: '';
        /*background-image: url("../../assets/img/pk/bg.png");*/
        width: 50%;
        left: 25%;
        height: 1px;
        background-size: 50% 50%;
        position: absolute;
        top: -0px;
        background-color: red;
        z-index: -1;
        animation: light 1s;
        box-shadow: 0 0 10px 2px rgba(255, 255, 255, .5);
        animation-iteration-count: 2;
        opacity: 0;
      }
    }
  }
}

@keyframes light {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.content-pop {
  width: 800px;
  left: calc(50% - 350px);
  top: 100px;
  height: 650px;

  &-box {
    height: 450px;
    width: 720px;
    margin: 0 auto;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      border: 1px solid #000000;
      width: 160px !important;
      background-color: #090827;

      &:hover &-add {
        display: block;
      }

      &-add {
        display: none;
      }
    }

    & .active {
      background-color: var(--main-yellow);

      &::before {}
    }
  }

  &-lock {
    margin-top: 20px;
    height: 550px;
    overflow: scroll;
    overflow-x: hidden;

    &-item {
      position: relative;
      background-color: var(--f-main);
      border: 1px solid #000;
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 650px;
  }
}
.history_bg {
  .list {
    justify-content: center;
  }
}
</style>
