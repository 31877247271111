<template>
  <!-- fpk -->
  <div class="fpk">
    <div class="fpk-menu">
      <div class="left" v-if="!isRanking">
        <a v-for="(item, index) in fpkMenu" :class="{ 'active': fpkMenuActive == index }"
          @click="fpkMenuActiveFn(index); PKarenaList = []; defaultPKarenaList = []; play_type = 1; type = 1; by_type = 3; hisVal1 = ''; hisVal2 = 1; hisVal3 = 0; page = 1"
          :key="index">{{ item }}</a>
      </div>

      <div class="wrap-tip" v-if="!isRanking && fpkMenuActive != 2 && fpkMenuActive != 3">
        <img @click="isRanking = true" src="@/assets/pk/tip1.png" alt="">
        <img @click="fpkMenuActive = 3, fpkMenuActiveFn(3); hisVal1 = ''; hisVal2 = 1; hisVal3 = 0"
          src="@/assets/pk/tip2.png" alt="">
        <img @click="fpkMenuActive = 2, fpkMenuActiveFn(2); play_type = 1; type = 1; by_type = 3;"
          src="@/assets/pk/tip3.png" alt="">
      </div>

      <div class="center">
        <div class="play-rule-box" v-if="(!isRanking)" @click="playingMethod = true">
          <div class="play-rule">游戏规则</div>
        </div>
        <div class="old-box">
          <div class="my-history" v-for="(item, index) of fpkNenuRight" :key="index" @click="fpkMenuActiveFn2(index)">
            {{ item }}
          </div>
        </div>
      </div>

      <!-- <div class="right"
        v-if="(user_info.anchor == 1 && user_info.official_bot == 1 && fpkMenuActive == 1) && (!isRanking)"
        @click="drawer = true"></div>
      <div class="right" v-if="(user_info.anchor == 0 && fpkMenuActive == 0) && (!isRanking)" @click="drawer = true">
      </div> -->
    </div>
    <div class="fpk-content" v-if="fpkMenuActive == 0 || fpkMenuActive == 1" :style="`padding-top:0px`">

      <div v-if="!isRanking" class="data dt-data" :style="{ height: heightStatus ? '300px' : 'auto' }">
        <div class="dt-data-content" v-if="fpkShowRobte">
          <div class="left-tips" @click.stop="scrollAct(0)"></div>
          <!-- 盲盒 -->
          <div class="dt-data-box" ref="scrollBox">
            <div class="dt-data-box-item" v-for="(item, index) in boxGunList" :key="index"
              @click="dialogVisible = true; contains = item.contains; containsName = item.name; game_odds_list = item.game_odds_list">
              <img :src="item.intact_cover" width="120" />

              <p class="money"><img src="@/assets/img/ADT/home/up.png">{{ item.game_bean }}</p>
              <p class="name">{{ item.name }}</p>
            </div>
          </div>
          <div class="right-tips" @click.stop="scrollAct(1)"></div>
        </div>
        <!-- 机器人 -->
        <div v-else>

          <div class="dt-data-content">
            <div class="left-tips" @click.stop="scrollAct(0)"></div>
            <!-- 盲盒 -->
            <div class="dt-data-box robit-box" ref="scrollBox">
              <div class="dt-data-box-item robit" v-for="(item, index) in designatedData" :key="index"
                @click="dialogVisible1 = true; designatedDataRcode(item.id)">
                <img class="robotImg" :src="item.avatar || require('../../assets/img/ADT/px/robot.png')" width="120" />
                <!-- <img class="robotIcon" src="@/assets/pk/home/roicon.png" /> -->
                <p class="robotName">{{ item.name || `Robot-0${index + 1}` }}</p>
                <p class="robotLv">
                  <span>胜率:{{ item.win_rate ? (Number(item.win_rate) * 100).toFixed(0) : 0 }}%</span>
                  <span>平率:{{ item.peace_rate ? (Number(item.peace_rate) * 100).toFixed(0) : 0 }}%</span>
                </p>
                <p class="robotKing"><img src="@/assets/pk/home/hg.png" /> {{ item.win_total || 0 }}/{{ item.peace_total
                  || 0
                }}/{{
  item.loss_total || 0 }}</p>
              </div>
            </div>
            <div class="right-tips" @click.stop="scrollAct(1)"></div>
          </div>
          <!-- <div v-for="(item, index) in designatedData" :key="index"
            @click="dialogVisible1 = true; designatedDataRcode(item.id)">
            <img class="robotImg" :src="item.avatar" width="120" />
            <img class="robotIcon" src="@/assets/pk/home/roicon.png" />
            <p class="robotName">{{ item.name }}</p>
            <p class="robotLv">
              <span>胜率:{{ item.win_rate ? (Number(item.win_rate) * 100).toFixed(0) : 0 }}%</span>
              <span>平率:{{ item.peace_rate ? (Number(item.peace_rate) * 100).toFixed(0) : 0 }}%</span>
            </p>
            <p class="robotKing"><img src="@/assets/pk/home/hg.png" /> {{ item.win_total || 0 }}/{{ item.peace_total || 0
            }}/{{
  item.loss_total || 0 }}</p>
          </div> -->
        </div>
      </div>
      <div v-if="!isRanking" class="btn dt-look-robot" @click="fpkShowRobte = !fpkShowRobte">
        <img v-if="fpkShowRobte" src="../../assets/img/ADT/px/look-robot.png" />
        <img v-else src="../../assets/img/ADT/px/look-box.png" />
      </div>
      <div class="toggleRobte">
        <!-- <img src="@/assets/pk/home/14.png" :style="`transform: rotate(${heightStatus ? '0deg' : '180deg'});`" width="1720"
          @click="heightStatus = !heightStatus" /> -->
      </div>


      <div style="text-align: center;" class="create-room pc-none" @click="toCleat">
        <img width="120" src="../../assets/img/ADT/px/create-room.png" alt="">
      </div>

      <div class="pkdata">
        <div class="left" v-if="!isRanking">
          <div class="title create-room-box">
            <!-- <el-checkbox-group v-model="checkList" @change="fifcheckList">
              <el-checkbox label="全部"></el-checkbox>
              <el-checkbox label="进行中"></el-checkbox>
              <el-checkbox label="等待中"></el-checkbox>
              <el-checkbox label="已结束"></el-checkbox>
            </el-checkbox-group> -->

            <div v-if="(user_info.anchor == 1 && user_info.official_bot == 1 && fpkMenuActive == 1) && (!isRanking)" class="create-room m-none" @click="toCleat">
              <img src="../../assets/img/ADT/px/create-room.png" alt="">
            </div>
            <div v-if="(user_info.anchor == 0 && fpkMenuActive == 0) && (!isRanking)" class="create-room m-none" @click="toCleat">
              <img src="../../assets/img/ADT/px/create-room.png" alt="">
            </div>
            <el-tabs class="dt-tabs-box" v-model="activeName" @tab-click="fifcheckList">
              <el-tab-pane label="全部" :value="0"></el-tab-pane>
              <el-tab-pane label="进行中" :value="1"></el-tab-pane>
              <el-tab-pane label="等待中" :value="2"></el-tab-pane>
              <el-tab-pane label="已结束" :value="3"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="info">
            <!-- 对战列表 -->
            <div class="pk-item-box" v-for="(item, index) in PKarenaList" :key="index"
              :style="`background-image:url(${require(`@/assets/img/ADT/px/${item.status == 1 ? (sUserAgent == 'web' ? 'pk-start' : 'pk-start') : item.status == 0 ? (sUserAgent == 'web' ? 'pk-loding' : 'pk-loding') : (sUserAgent == 'web' ? 'pk-end' : 'pk-end')}.png`)})`">


              <!-- 已结束 TIPS -->
              <img v-if="item.status == 2" width="130" class="end-tips" src="../../assets/img/ADT/px/end-tips.png">

              <div class="title warn">
                <div class="center" v-if="item.status == 1">
                  <img src="@/assets/img/ADT/px/2222.png" height="20" /> 竞技场中...
                </div>
                <div class="center warn" v-if="item.status == 0">
                  <img src="@/assets/img/ADT/px/3333.png" height="20" /> 等待中...
                </div>
                <div class="center over" v-if="item.status == 2">
                  <img src="@/assets/img/ADT/px/1111.png" height="20" /> 已结束
                </div>


                <div class="center game_type" :class="item.game_type && 'type1'">{{ item.game_type && '地转' || '天旋' }}</div>


                <div class="right">
                  <span>{{ fpkMenuActive == 0 ? '会员对战' : '主播对战' }}</span>
                  <span :class="item.status == 2 ? 'over' : ''" class="r-text">{{ item.box_num }}</span><span
                    :class="item.status == 2 ? 'over' : ''">回合</span>
                </div>
              </div>
              <div class="wm">
                <!-- 对战人员头像 -->
                <div class="img-box" v-for="(child, childIndex) in item.game_arena_player" :key="childIndex">
                  <!-- VS -->
                  <img class="vs" width="25" src="../../assets/img/ADT/px/vs.png" alt="">
                  <div class="pc-user-sculpture">
                    <img width="80" :src="child.user?.avatar || require('@/assets/img/pk/user.png')" />
                  </div>

                </div>
                <!-- 加入圈圈 -->
                <div class="img-box add-box"
                  v-for="(child, childIndex) in (item.user_num - item.game_arena_player.length)"
                  :key="childIndex + 'child'">
                  <!-- VS -->
                  <img v-if="(item.user_num - item.game_arena_player.length)" class="vs" width="25"
                    src="../../assets/img/ADT/px/vs.png" alt="">
                  <img width="80" src="../../assets/img/ADT/px/addquanquan.png"
                    @click="activeUrl(item.status, item.id, item.ceil)" />
                  <!-- <img width="80" src="../../assets/pk/home/6.png"
                  @click="activeUrl(item.status, item.id, item.ceil)" /> -->

                </div>

                <!-- {{item.user_num}}-{{item.game_arena_player.length}} {{ item.id }} -->
              </div>
              <div class="status" @click="activeUrl(item.status, item.id, item.ceil)">
                <div :class="item.status == 1 ? 'status1' : item.status == 0 ? 'status0' : 'status2'"
                  :style="`color:${item.status == 1 ? '#222' : '#fff'} !important;`">


                  <span v-if="item.status == 0"><img src="@/assets/img/ADT/home/up.png" /> {{ item.total_bean }}</span>

                  <div>
                    <img src="../../assets/img/ADT/px/pk.png" v-if="item.status == 0" /> {{ item.status == 0 ? '立即加入' :
                      item.status
                        == 1 ? '围观一下' : '查看'
                    }}
                  </div>
                </div>
              </div>
              <div class="ornaments">
                <div class="sorll">
                  <div class="imglist guan" v-for="(child, childIndex) in item.game_arena_box" :key="childIndex">
                    <img :src="child.box?.type == 1 ? child.box?.intact_cover : child.box?.cover" />
                    <img class="weapon" v-if="child.box?.type == 0" :src="child.box?.weapon_cover" alt="">
                  </div>
                </div>
                <!-- <img src="../../assets/pk/home/12.png" /> -->
              </div>
            </div>
          </div>
          <div @click="getPKarenaList(page, fpkMenuActive)" class="loadingMore load-more" v-if="PKarenaList.length != 0">
            <img width="150" src="../../assets/img/ADT/px/load-more.png" alt="">
          </div>
        </div>
        <div class="right" :class="{ 'warpRight': sUserAgent != 'web' }" v-if="isRanking && sUserAgent != 'web'">
          <div class="back" @click="isRanking = false">返回</div>
          <div class="top" v-if="arenaRank.yesterday_list.length != 0">
            <img src="@/assets/pk/home/13.png" />
            <img class="avatar" :src="arenaRank.yesterday_list[0]?.user?.avatar"
               />
            <p class="name">{{ arenaRank.yesterday_list[0]?.user?.name }}</p>
            <p class="money"><img src="@/assets/img/ADT/home/up.png"> {{ arenaRank.yesterday_list[0]?.total_win_worth
            }}</p>
          </div>
          <div class="ranking">
            <div class="menu">
              <a :class="{ 'active': pkRank == 0 }" @click="pkRank = 0, pkRankChild = 0">对战榜</a>
              <a :class="{ 'active': pkRank == 1 }" @click="pkRank = 1, pkRankChild = 0">胜率榜</a>
            </div>
            <div class="type">
              <a :class="{ 'active': pkRankChild == 0 }" @click="pkRankChild = 0">今日</a>
              <a :class="{ 'active': pkRankChild == 1 }" @click="pkRankChild = 1">昨日</a>
              <a v-if="pkRank == 0 && fpkMenuActive == 0" :class="{ 'active': pkRankChild == 2 }"
                @click="pkRankChild = 2">欧皇奖励</a>
            </div>
            <div class="list" v-if="pkRank == 0">
              <div v-if="pkRankChild == 0">
                <div v-for="(item, index) in arenaRank.today_list" :key="index">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span class="dt-span-sty">{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money"><img src="@/assets/img/ADT/home/up.png">{{ item.total_win_worth }}</div>
                </div>
              </div>
              <div v-if="pkRankChild == 1">
                <div v-for="(item, index) in awardList" :key="index">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span class="dt-span-sty">{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money"><img src="@/assets/img/ADT/home/up.png">{{ item.total_win_worth }}</div>
                </div>
                <div class="tji" v-if="awardList.length == 0">
                  数据统计中
                </div>
              </div>
              <div v-if="pkRankChild == 2 && fpkMenuActive == 0">
                <div class="tipgift">
                  说明：昨日参加盲盒对战获得道具（U币）超过1000U币以上的前10名玩家可领取对应奖励
                </div>
                <div class="gift" v-for="(item, index) in awardList" :key="index" :style="{
                  backgroundColor: index == 0 ? '#ffe15c37' : index == 1 ? '#9ea9b92b' : index == 2 ? '#f6a57331' : none
                }">
                  <div>
                    <div :style="{
                      color: index == 0 ? '#DDA53D' : index == 1 ? '#C2CAD6' : index == 2 ? '#F5A270' : none
                    }">
                      <img :src="item.user?.avatar" />
                      <!-- <img src="https://api.upcsgo.com/uploads/default_avatar/274.jpg" alt=""> -->
                      <img class="av" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)">
                      <span>{{ item.user?.name }}</span>
                    </div>
                    <div class="money">
                      <img src="@/assets/img/ADT/home/up.png"> {{ item.award_bean }}
                    </div>
                  </div>
                  <div v-if="item.is_me && item.get_status != 2" class="btn">
                    <a @click="getGift(item.id)">立即领取</a>
                  </div>
                </div>
                <div class="tji" v-if="awardList.length == 0">
                  数据统计中
                </div>
              </div>
            </div>
            <div class="list" v-else>
              <div v-if="pkRankChild == 0">
                <div v-for="(item, index) in arenaRate.today_list" :key="index">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span class="dt-span-sty">{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money">{{ (item.win_rate * 100).toFixed(0) }}%</div>
                </div>
              </div>
              <div v-if="pkRankChild == 1">
                <div v-for="(item, index) in arenaRate.yesterday_list" :key="index">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span class="dt-span-sty">{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money">{{ (item.win_rate * 100).toFixed(0) }}%</div>
                </div>
              </div>
              <div v-if="pkRankChild == 2">

              </div>

            </div>
          </div>
        </div>

        <!-- 排行榜 -->
        <!-- <div class="right" v-if="!isRanking && sUserAgent == 'web'">
          <div class="back" @click="isRanking = false">返回</div>
          <div class="top" v-if="arenaRank.yesterday_list.length != 0">
            <img src="@/assets/pk/home/13.png" />
            <img class="avatar" :src="arenaRank.yesterday_list[0]?.user?.avatar"
              @click="userCenterRcode = true; userModelInfo(arenaRank.yesterday_list[0]?.user?.id)" />
            <p class="name">{{ arenaRank.yesterday_list[0]?.user?.name }}</p>
            <p class="money"><img src="@/assets/img/ADT/home/up.png"> {{ arenaRank.yesterday_list[0]?.total_win_worth
            }}</p>
          </div>
          <div class="ranking">
            <div class="menu">
              <a :class="{ 'active': pkRank == 0 }" @click="pkRank = 0, pkRankChild = 0">对战榜</a>
              <a :class="{ 'active': pkRank == 1 }" @click="pkRank = 1, pkRankChild = 0">胜率榜</a>
            </div>
            <div class="type">
              <a :class="{ 'active': pkRankChild == 0 }" @click="pkRankChild = 0">今日</a>
              <a :class="{ 'active': pkRankChild == 1 }" @click="pkRankChild = 1">昨日</a>
              <a v-if="pkRank == 0 && fpkMenuActive == 0" :class="{ 'active': pkRankChild == 2 }"
                @click="pkRankChild = 2">欧皇奖励</a>
            </div>
            <div class="list" v-if="pkRank == 0">
              <div v-if="pkRankChild == 0">
                <div v-for="(item, index) in arenaRank.today_list" :key="index"
                  @click="userCenterRcode = true; userModelInfo(item.user_id)">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span>{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money"><img src="@/assets/img/ADT/home/up.png">{{ item.total_win_worth }}</div>
                </div>
              </div>
              <div v-if="pkRankChild == 1">
                <div v-for="(item, index) in awardList" :key="index"
                  @click="userCenterRcode = true; userModelInfo(item.user_id)">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span>{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money"><img src="@/assets/img/ADT/home/up.png">{{ item.total_win_worth }}</div>
                </div>
                <div class="tji" v-if="awardList.length == 0">
                  数据统计中
                </div>
              </div>
              <div v-if="pkRankChild == 2 && fpkMenuActive == 0">
                <div class="tipgift">
                  说明：昨日参加盲盒对战获得道具（U币）超过1000U币以上的前10名玩家可领取对应奖励
                </div>
                <div class="gift" v-for="(item, index) in awardList" :key="index" :style="{
                  backgroundColor: index == 0 ? '#ffe15c37' : index == 1 ? '#9ea9b92b' : index == 2 ? '#f6a57331' : none
                }">
                  <div>
                    <div @click="userCenterRcode = true; userModelInfo(item.user_id)" :style="{
                      color: index == 0 ? '#DDA53D' : index == 1 ? '#C2CAD6' : index == 2 ? '#F5A270' : none
                    }">
                      <img :src="item.user?.avatar" />
                      <img class="av" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)">
                      <span>{{ item.user?.name }}</span>
                    </div>
                    <div class="money">
                      <img src="@/assets/img/ADT/home/up.png"> {{ item.award_bean }}
                    </div>
                  </div>
                  <div v-if="item.is_me && item.get_status != 2" class="btn">
                    <a @click="getGift(item.id)">立即领取</a>
                  </div>
                </div>
                <div class="tji" v-if="awardList.length == 0">
                  数据统计中
                </div>
              </div>
            </div>
            <div class="list" v-else>
              <div v-if="pkRankChild == 0">
                <div v-for="(item, index) in arenaRate.today_list" :key="index"
                  @click="userCenterRcode = true; userModelInfo(item.user_id)">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span>{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money">{{ (item.win_rate * 100).toFixed(0) }}%</div>
                </div>
              </div>
              <div v-if="pkRankChild == 1">
                <div v-for="(item, index) in arenaRate.yesterday_list" :key="index"
                  @click="userCenterRcode = true; userModelInfo(item.user_id)">
                  <div class="img">
                    <img :src="item.user.avatar" />
                    <span>{{ item.user.name }}</span>
                    <img class="avatar-box" :src="require(`@/assets/pk/no${(index < 3 ? index + 1 : 4)}.png`)" />
                  </div>
                  <div class="money">{{ (item.win_rate * 100).toFixed(0) }}%</div>
                </div>
              </div>
              <div v-if="pkRankChild == 2">

              </div>

            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="fpk-content" v-if="fpkMenuActive == 2">
      <div class="back" @click="fpkMenuActive = 0">返回</div>
      <div class="hisType">
        <div>
          <el-select v-model="hisVal1" @change="updateHis" placeholder="请选择">
            <el-option label="所有记录" value=""></el-option>
            <el-option label="会员对战" :value="0"></el-option>
            <el-option label="主播对战" :value="1"></el-option>
          </el-select>
        </div>
        <div>
          <el-select v-model="hisVal2" @change="updateHis" placeholder="请选择">
            <el-option label="玩家对战" :value="1"></el-option>
            <el-option label="官方对战" :value="2"></el-option>
          </el-select>
        </div>
        <div>
          <el-select v-model="hisVal3" @change="updateHis" placeholder="请选择">
            <el-option label="所有记录" :value="0"></el-option>
            <el-option label="我的" :value="1"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lsBox">
        <div class="title">
          <label>结束时间</label>
          <label>玩家</label>
          <label>类型</label>
          <label>回合</label>
          <label>盲盒信息</label>
          <label>结果总额</label>
        </div>
        <div class="ls" v-for="(item, index) in historyData" :key="index">
          <div>{{ item.updated_at }}</div>
          <div>
            <div v-for="(child, childIndex) in item.game_arena_player" :key="childIndex">
              <img v-if="!child.is_official_bot" :src="child.user?.avatar" width="80px" />
              <img v-else :src="child.user?.avatar" width="80px" />
              <img class="win" v-if="item.win_user_id != null && item.win_user_id[0] == child?.user_id"
                src="@/assets/img/gj.png" width="40" />
            </div>
          </div>
          <div>{{ item.is_anchor == 0 ? '会员对战' : '主播对战' }}</div>
          <div><span>{{ item.box_num }}</span></div>
          <div style="cursor: pointer;"
            @click="$router.push('/active/mysteryBox/' + item.id + '?&typeurl=' + fpkMenuActive + '&hisPageRecord=' + hisPageRecord + '')">
            <div class="scorll">
              <div class="guan" v-for="(child, childIndex) in item.game_arena_box" :key="childIndex">
                <img :src="child.box?.type == 1 ? child.box?.intact_cover : child.box?.cover" width="80px" />
                <img class="weapon" v-if="child.box?.type == 0" :src="child.box?.weapon_cover" alt="">
              </div>
            </div>
            <div class="money"><img src="@/assets/img/ADT/home/up.png" width="22" />{{ item.total_bean }}</div>
          </div>
          <div><img src="@/assets/img/ADT/home/up.png" width="22" />{{ item.total_award_bean }}</div>
        </div>
        <!-- 分页 -->
        <div class="pageTotal" style="margin-top:20px">
          <a @click="typeGage2(1)">上一页</a>
          <a @click="typeGage2(2)">下一页</a>
        </div>
      </div>
    </div>
    <div class="fpk-content" v-if="fpkMenuActive == 3">
      <div class="back" @click="fpkMenuActive = 0">返回</div>
      <div class="type">
        <a :class="{ 'active': play_type == 1 }" @click="play_type = 1, defaultType()">玩家对战</a>
        <a :class="{ 'active': play_type == 2 }" @click="play_type = 2, defaultType()">官方对战</a>
      </div>
      <div class="typeChild">
        <div class="left">
          <a :class="{ 'active': type == 1 }" @click="type = 1, defaultType()"><img
              :src="require(`@/assets/img/ADT/px/select-box/${1 == type ? '4' : '3'}.png`)" width="18" height="18" />
            最大金额</a>
          <a :class="{ 'active': type == 2 }" @click="type = 2, defaultType()"><img
              :src="require(`@/assets/img/ADT/px/select-box/${2 == type ? '4' : '3'}.png`)" width="18" height="18" />
            最大增长</a>
        </div>
        <div class="right">
          <a :class="{ 'active': by_type == 1 }" @click="by_type = 1, defaultType()"><img
              :src="require(`@/assets/img/ADT/px/select-box/${1 == by_type ? '4' : '3'}.png`)" width="18"
              height="18" />今日</a>
          <a :class="{ 'active': by_type == 2 }" @click="by_type = 2, defaultType()"><img
              :src="require(`@/assets/img/ADT/px/select-box/${2 == by_type ? '4' : '3'}.png`)" width="18"
              height="18" />昨日</a>
          <a :class="{ 'active': by_type == 3 }" @click="by_type = 3, defaultType()"><img
              :src="require(`@/assets/img/ADT/px/select-box/${3 == by_type ? '4' : '3'}.png`)" width="18"
              height="18" />近七日</a>
        </div>
      </div>
      <div class="typeContent">
        <div class="ls" v-for="(item, index) in topTen" :key="index"
          :style="`background-image:url(${require(`@/assets/img/ADT/px/${item.status == 1 ? (sUserAgent == 'web' ? 'pk-start' : 'f3') : item.status == 0 ? (sUserAgent == 'web' ? 'pk-loding' : 'f2') : (sUserAgent == 'web' ? 'pk-end' : 'f2')}.png`)})`">
          <div class="title">
            <div class="left"> <img v-if="item.play_type == 1" src="@/assets/pk/home/r1.png" /><img
                v-if="item.play_type == 2" src="../../assets/pk/home/r2.png" /> {{ item.play_type == 1 ? '玩家' : '官方' }}
            </div>
            <div class="right"><span>{{ item.box_num }}</span>回合</div>
          </div>
          <div class="wm">
            <div v-for="(child, childIndex) in item.game_arena_player" :key="childIndex">
              <!-- VS -->
              <img class="vs" width="25" src="../../assets/img/ADT/px/vs.png" alt="">
              <img v-if="!child.is_official_bot" :src="child.user?.avatar" width="80px" />
              <img v-else :src="child.user?.avatar" width="80px" />
              <img class="win" v-if="item.win_user_id[0] == child.user_id" src="@/assets/img/gj.png" width="40" />

            </div>

          </div>
          <div class="num" @click="$router.push('/active/mysteryBox/' + item.id + '?&typeurl=' + fpkMenuActive + '')">
            <img src="@/assets/img/ADT/home/up.png" width="22"> {{ item.total_bean }} <span>></span> <img
              src="@/assets/img/ADT/home/up.png" width="22"> {{ item.total_award_bean }}
          </div>
          <div class="ornaments"
            @click="$router.push('/active/mysteryBox/' + item.id + '?&typeurl=' + fpkMenuActive + '')">
            <div class="sorll">
              <div class="imglist guan" v-for="(child, childIndex) in item.game_arena_box" :key="childIndex">
                <img width="80" :src="child.box?.type == 1 ? child.box?.intact_cover : child.box?.cover" />
                <img class="weapon" v-if="child.box?.type == 0" :src="child.box?.weapon_cover" alt="">
              </div>
            </div>
            <!-- <img src="../../assets/pk/home/12.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 对战选择箱子 -->
    <el-dialog :with-header="false" :show-close="false" :visible.sync="drawer" direction="rtl" custom-class="createPk">
      <div class="createPkBox">
        <div class="left">

        </div>
        <div class="right">
          <div class="title">
            <div class="left">
              选择箱子(<span>{{ tatalNum }}</span>/6)

            </div>
            <div class="right">
              <img width="30" src="../../assets/img/ADT/px/select-box/close.png" @click="drawer = false" />
            </div>
          </div>
          <div class="type">
            <div class="center">
              <a v-for="(item, index) in boxList" :class="{ 'active': index == boxListVal }" @click="boxListVal = index"
                :key="index"><img :src="require(`@/assets/img/ADT/px/select-box/${index == boxListVal ? '4' : '3'}.png`)"
                  width="14" height="14" /> {{ item.name }}</a>
            </div>
            <div class="right">
              <!-- <a @click="meActiveFn()" :class="{ 'active': meActive == 1 }"><img
                  :src="require(`@/assets/pk/home/create/${meActive == 1 ? '3' : '4'}.png`)" width="14" height="14" />只看已读</a> -->
              <!-- <a>默认排序</a> -->
            </div>
          </div>
          <div class="content" v-if="boxList[boxListVal]?.name == '对战盲盒'"
            :style="{ height: boxList[boxListVal]?.box?.length > (sUserAgent == 'web' ? '15' : '4') ? h + 'px' : 'auto' }">
            <div class="ls lsnone" v-for="(item, index) in boxList[boxListVal].box" :key="index">
              <div
                :style="`background-image:url(${require(`@/assets/pk/home/create/${item.activeNum > 0 ? (sUserAgent == 'web' ? '22' : '2n') : '11'}.png`)})`">
                <div @click="boxListActive(index, item.game_bean)">
                  <img v-if="item.activeNum > 0" :src="item.intact_cover" height="40" alt="" />
                  <img v-else :src="item.intact_cover" style="height:110px" alt="" />
                </div>
                <img class="exit" @click="clearActiveNum(index, 2)" v-if="item.activeNum > 0"
                  src="@/assets/img/ADT/px/select-box/yuan-close.png" />
                <div class="addromove" v-if="item.activeNum > 0">
                  <a @click="boxListActiveChange(1, index, item.game_bean)">-</a>
                  <span>{{ item.activeNum }}</span>
                  <a @click="boxListActiveChange(2, index, item.game_bean)">+</a>
                </div>
                <div v-else class="hh" style="height:10px"></div>
                <div class="right" @click="boxListActive(index, item.game_bean)">
                  <div class="title" style="font-family: Monaco;color: #fff;">{{ item.name }}</div>
                  <p> <img src="@/assets/img/ADT/home/up.png" width="18" />{{ item.game_bean }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="content" v-else
            :style="{ height: boxList[boxListVal]?.box?.length > (sUserAgent == 'web' ? '15' : '4') ? h + 'px' : 'auto' }">
            <div class="ls" v-for="(item, index) in boxList[boxListVal]?.box" :key="index"
              :style="`background-image:url(${item.cover}`">
              <div
                :style="`background-image:url(${require(`@/assets/pk/home/create/${item.activeNum > 0 ? (sUserAgent == 'web' ? '2' : '2nn') : '1'}.png`)})`">
                <div @click="boxListActive(index, item.bean)">
                  <img v-if="item.activeNum > 0" :src="item.weapon_cover" height="40" alt="" />
                  <img v-else :src="item.weapon_cover" style="height:110px" alt="" />
                </div>
                <img class="exit" @click="clearActiveNum(index, 1)" v-if="item.activeNum > 0"
                  src="../../assets/img/ADT/px/select-box/yuan-close.png" />
                <div class="addromove" v-if="item.activeNum > 0">
                  <a @click="boxListActiveChange(1, index, item.bean)">-</a>
                  <span>{{ item.activeNum }}</span>
                  <a @click="boxListActiveChange(2, index, item.bean)">+</a>
                </div>
                <div v-else class="hh" style="height:10px"></div>
                <div @click="boxListActive(index, item.bean)" class="right">
                  <div class="title" style="font-family: Monaco;color: #fff;">{{ item.name }}</div>
                  <p> <img src="@/assets/img/ADT/home/up.png" width="18" />{{ item.bean }}</p>
                </div>
              </div>
            </div>
            <div style="text-align: center;width: 100%;color: #35C9EE;" v-if="!boxList[boxListVal]?.box?.length">暂无数据
            </div>
          </div>
          <div class="submit">
            <div class="typePk">
              <div class="left">
                <div>
                  <p @click="peopleStatus = 1; user_num = 0">
                    <img v-if="peopleStatus == 1" src="@/assets/img/ADT/px/select-box/4.png" />
                    <img v-else src="@/assets/img/ADT/px/select-box/3.png" />
                    <span style="color: #fff;">参与人数</span>
                  </p>
                  <p @click="peopleStatus = 2; designated_bot_id = null; getDesignatedId()">
                    <img v-if="peopleStatus == 2" src="@/assets/img/ADT/px/select-box/4.png" />
                    <img v-else src="@/assets/img/ADT/px/select-box/3.png" />
                    <span style="color: #fff;">选机器人</span>
                  </p>
                </div>
                <div v-if="peopleStatus == 1">
                  <div class="btn" :class="{ 'btnActive': user_num == 2 }" @click="user_num = 2"><img
                      src="@/assets/pk/10.png" /> 双人对战</div>
                  <div class="btn" :class="{ 'btnActive': user_num == 3 }" @click="user_num = 3"><img
                      src="@/assets/pk/10.png" /> 三人对战</div>
                  <div class="btn" :class="{ 'btnActive': user_num == 4 }" @click="user_num = 4"><img
                      src="@/assets/pk/10.png" /> 四人对战</div>
                </div>
                <div v-else>
                  <div class="btn btn1" v-for="(item, index) in designatedData1" :key="index"
                    @click="designated_bot_id = item.id" :class="{ 'btnActive': designated_bot_id == item.id }">
                    <img class="avatar" :src="item.avatar" />
                    {{ item.name }}
                  </div>
                </div>
              </div>

            </div>
            <div class="game_type1">
              <el-radio v-model="game_type" :label="0">天旋</el-radio>
              <el-radio v-model="game_type" :label="1">地转</el-radio>
            </div>
            <div class="num">
              <div class="left">
                
                <!-- <span>创建数量 :</span> -->
                <!-- <input type="text" v-model="pknum"> -->
                <!-- <el-input-number v-model="pknum" @change="handleChange" :min="1" :max="9"></el-input-number> -->
              </div>
              <div class="right">
                <div class="money">
                  <img src="@/assets/img/ADT/home/up.png" width="28" /> {{ Math.abs(createMoney).toFixed(2) }}
                </div>
                <div class="last">
                  <img src="../../assets/img/ADT/px/select-box/create-pk.png" @click="createPk" width="220" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :show-close="false" custom-class="kkmodel" :visible.sync="dialogVisible"
      :before-close="handleClose">
      <div class="fpk-model">
        <img class="exit" src="@/assets/img/ADT/px/select-box/close.png" width="30" height="30"
          @click="dialogVisible = false" />
        <div class="title">
          <div class="left">{{ containsName }}</div>
          <!-- <div class="right">
            <div><img src="@/assets/pk/home/model/7.png" />{{ getTypeColor(1) || '0%' }}</div>
            <div><img src="@/assets/pk/home/model/8.png" />{{ getTypeColor(2) || '0%' }}</div>
            <div><img src="@/assets/pk/home/model/9.png" />{{ getTypeColor(3) || '0%' }}</div>
            <div><img src="@/assets/pk/home/model/10.png" />{{ getTypeColor(4) || '0%' }}</div>
            <div><img src="@/assets/pk/home/model/11.png" />{{ getTypeColor(5) || '0%' }}</div>
          </div> -->
        </div>
        <div class="listBox">
          <div class="ls" :class="'all-bg' + item.level" v-for="(item, index) in contains" :key="index">

            <div class="top-box d-flex justify-content-between align-items-center" :class="'ggbg' + item.level">
              <div class="jinbi d-flex align-items-center">
                <img src="@/assets/img/ADT/home/up.png" width="24" />
                <span style="color: #F9C545;">{{ item.skins?.bean }}</span>
              </div>
              <div class="gailv">
                概率{{ item.odds_percent_game }}
              </div>
            </div>

            <img :src="item.skins.cover" alt="" width="40" />
            <div class="title">
              <p>{{ item.skins?.name.replace(/\s*/g, "") }}({{ item.skins?.dura_alias.replace(/\s*/g, "") }})</p>

            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :show-close="false" custom-class="kkRobet" :visible.sync="dialogVisible1"
      :before-close="handleClose">
      <div class="fpk-model">
        <img class="exit" src="@/assets/img/ADT/px/select-box/close.png" width="30" height="30"
          @click="dialogVisible1 = false" />
        <div class="title">
          <el-select v-model="robtVal" @change="robtHis" placeholder="请选择">
            <el-option label="所有记录" value=""></el-option>
            <el-option label="会员对战" :value="0"></el-option>
            <el-option label="主播对战" :value="1"></el-option>
          </el-select>
        </div>
        <div class="listBox">
          <div class="type">
            <label>结束时间</label>
            <label>玩家</label>
            <label>类型</label>
            <label>回合</label>
            <label>盲盒信息</label>
            <label>结果总额</label>
          </div>
          <div class="ls" v-for="(item, index) in historyData1" :key="index">
            <div>{{ item.updated_at }}</div>
            <div>
              <div v-for="(child, childIndex) in item.game_arena_player" :key="childIndex">
                <img v-if="!child.is_official_bot" :src="child.user?.avatar" width="80px" />
                <img v-else :src="child.user?.avatar" width="80px" />
              </div>
            </div>
            <div>{{ item.is_anchor == 0 ? '会员对战' : '主播对战' }}</div>
            <div><span>{{ item.box_num }}</span>回合</div>
            <div>
              <div class="scorll">
                <div v-for="(child, childIndex) in item.game_arena_box" class="guan" :key="childIndex">
                  <img :src="child.box?.type == 1 ? child.box?.intact_cover : child.box?.cover" width="80px" />
                  <img class="weapon" v-if="child.box?.type == 0" :src="child.box?.weapon_cover" alt="">
                </div>
              </div>
              <div><img src="@/assets/img/ADT/home/up.png" width="22" /> {{ item.total_bean }}</div>
            </div>
            <div><img src="@/assets/img/ADT/home/up.png" width="22" /> {{ item.total_award_bean }}</div>
          </div>
        </div>
        <div class="pageTotal">
          <a @click="typeGage1(1)">上一页</a>
          <a @click="typeGage1(2)">下一页</a>
        </div>
      </div>
    </el-dialog>
    <!-- 玩法介绍 -->
    <div class="noticef" v-if="playingMethod">
      <div
        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg notice">
        <div class="text-white p-md-4 p-2 p-2-phone" style="line-height: 2">
          <p>1.创建房间时，可以选择竞技场人数（2人竞技场，3人竞技场和4人竞技场），一个房间最多可以选择 6 个盲盒开箱，房间一但创建，不可取消。
          </p>
          <p>&nbsp;</p>
          <p>
            2.创建双人，三人或四人房间。每个房间入场金额为创建者所选盲盒的价值总和，创建成功/加入房间即开启房间并将扣除相应金额,点击加入后自动支付u币，即视为加入房间，若中途退出房间，并不会退还盲盒或入场费。
          </p>
          <p>&nbsp;</p>
          <p>
            3. 为确保每一个参与者机会均等，游戏规则公开透明。（竞技场内，主播及 AI 不可加入玩家房间，玩家可加入任意房间）</p>
          <p>&nbsp;</p>
          <p>
            4.每场游戏的结果受到（创建时间，玩家ID，玩家位置和开始时间等）多方面因素影响，为完全随机产生的结果。任意盲盒奖励均可以验证公平性。 
          </p>
          <p>&nbsp;</p>
          <p>
            5.创建房间时，所选择的对战机器人获胜概率是和玩家相同的，请放心对战娱乐。
          </p>
          <p>&nbsp;</p>
          <p>
            6.房间内，各方以各自开出盲盒内饰品的价值高低评判胜负，价值高者为获胜者，价值低者为失败者。 
          </p>
          <p>&nbsp;</p>
          <p>
            7.一人获胜：<br>当房间中只有一人获胜时，获胜方将会获得房间中所有盲盒总饰品。<br>
            &nbsp;&nbsp;&nbsp;二人获胜：<br>
            当房间中同时出现两名相同价值的获胜方时，两名获胜方将均分房间中所有的盲盒饰品总价值。
          </p>
          <br>
          <p>
            8.竞技场：可分为天旋和地转两种模式 <br>
            天旋模式:加入同一房间内的用户同时开启同样盲盒，房间内所选盲盒全部开启完毕后，累计获得饰品价值高的玩家为胜者，胜者将获得自己包括其他参与玩家开启的饰品，当出现平局时，各自获得各自开出的饰品。<br>
            地转模式:加入同一房间内的用户同时开启同样盲盒，房间内所选盲盒全部开启完毕后，累计获得饰品价值低的玩家为胜者，胜者将获得自己包括其他参与玩家开启的饰品，当出现平局时，各自获得各自开出的饰品。
          </p>
          <p>&nbsp;</p>
          <p style="color: red">&nbsp;&nbsp;&nbsp;
            声明：upcsgo将根据实际运营情况保留随时调整返佣比例的权利，以上活动最终解释权归upcsgo所有! <br>
            &nbsp;&nbsp;&nbsp;&nbsp;upcsgo温馨提醒您：本平台致力于为广大会员打造一个良好，诚信，公平的盲盒开箱平台，您的建议是我们前进的动力，
UP再次提醒您：请理性消费！
          </p>
        </div>
        <!-- <div @click="playingMethod = false" class="position_change" style="right: 20px; top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i>
          </div>
        </div> -->
      </div>
      <div @click="playingMethod = false" class="close_notice" style="right: 20px;">
        <div>
          <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i>
        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
    </div>
  </div>
</template>
<script>
import $api from '@/api/pk/index';
import { mapState } from 'vuex'
import sUserAgent from "@/assets/js/common.js";

// 导入一些获取token等等方法
import { getToken } from '@/utils/auth'
export default {
  name: "index",
  data() {
    return {
      game_type: 0,
      arrow: false,
      index: 0,
      step: 0,
      giftMoney: [188, 138, 108, 88, 68, 58, 48, 38, 28, 18],
      game_odds_list: [],
      // meActive: false,
      playingMethod: false,
      createMoney: 0,
      robtVal: '',
      contains: [],
      containsName: '',
      userCenterType: 0,
      userCenterRcode: false,
      pknum: 1,
      peopleStatus: 1,
      hisVal1: '',
      hisVal2: 1,
      hisVal3: 0,
      boxListVal: 0,
      // 选中
      fpkMenuActive: 0,
      pkRankChild: 0,
      // fpk
      fpkMenu: ['会员对战', '主播对战'],

      fpkNenuRight: ['历史记录', '我的记录'],
      fpkShowRobte: true,
      drawer: false,
      drawerMenu: ['天选之人', '五五开', '经典盲盒'],
      dialogVisible: false,
      dialogVisible1: false,
      page: 1,
      PKarenaList: [],
      boxGunList: [],
      designatedData: [],
      designatedData1: [],
      heightStatus: true,
      pkRank: 0,
      arenaRank: {
        yesterday_list: [],
        today_list: []
      },
      arenaRate: {
        yesterday_list: [],
        today_list: []
      },
      checkList: [],
      activeName: 0,
      topTen: [],
      historyData: [],
      historyData1: [],
      designated_bot_id: null,
      play_type: 1,
      type: 1,
      by_type: 3,
      // fpk end,
      boxList: [],
      defaultBoxList: [],
      tatalNum: 0,
      user_num: 0,
      defaultPKarenaList: [],
      winWorthData: [],
      latestSevenData: [],
      userOpenStaticData: {},
      blindGame: {},
      latestSevenTotal: 0,
      centerUserHome: {},
      robtId: 0,
      winWorthStatus: true,
      winWorthPage: 1,
      winWorthId: 0,
      h: 0,
      hisPage: 1,
      hisPageStatus: true,

      isRanking: false,
      hisPageRecord: 1,
      hisPageRecordStatus: true,
      createPkStatus: true,
      awardList: []
    }
  },
  created() {
    if (this.$route.query.typeurl) {
      this.fpkMenuActive = this.$route.query.typeurl
      this.hisPageRecord = this.$route.query.hisPageRecord
    }
    this.initWebSocket()
  },


  beforeDestroy() {
    if (this.$refs.scrollBox) {
      this.$refs.scrollBox.removeEventListener("scroll", this.handleScroll);
    }
  },


  mounted() {
    this.getPKarenaList(this.page, this.fpkMenuActive);
    this.getPKarenaboxList();

    this.getArenaDesignatedStatic()
    // 对战榜 & 胜率榜
    this.getArenaRank(this.fpkMenuActive)
    this.getArenaRate(this.fpkMenuActive)
    this.getAwardList(this.fpkMenuActive)

    if (this.fpkMenuActive == 3 || this.fpkMenuActive == 2) {
      this.fpkMenuActiveFn2(this.fpkMenuActive)
    }

    if (this.sUserAgent == 'web') {
      this.h = document.documentElement.clientHeight - 80 - 62 - 100 - 110 - 100 - 10
    } else {
      this.h = document.documentElement.clientHeight - 236 - 50 - 80
    }
  },
  computed: {
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
    ...mapState(['user_info']),
  },
  watch: {
    // game_type(v) {
    //   console.log(v)
    // }
  },
  methods: {
    handelMousedown(event) {
      let mousePlace = event.screenX;
      let _dom = this.$refs.scrollBox;
      let scrollLeft = _dom.scrollLeft;
      let distance = 0;
      let actual = 0;
      var _step = this.step;
      _dom.style.scrollSnapType = "none";
      var smoothScroll = function () {
        _dom.onmousemove = null;
        _dom.onmouseleave = null;
        _dom.onmouseup = null;

        let remainder = _dom.scrollLeft % _step;
        if (_dom.scrollLeft === scrollLeft) return; // 解决单击抖动问题
        let num = 0;
        if (remainder > _step / 2) {
          num = _step - remainder;
        } else {
          num = 0 - remainder;
        }
        let scrollSmooth = _dom.scrollLeft + num;
        _dom.scroll({ left: scrollSmooth, behavior: "smooth" });
        setTimeout(() => {
          _dom.style.scrollSnapType = "x mandatory";
        }, 150);
      };
      //鼠标移动的时候
      _dom.onmousemove = function (ev) {
        let oEvent = ev || event;
        // if(_dom !== oEvent.target) return;
        distance = oEvent.screenX;
        actual = mousePlace - distance;
        _dom.scrollLeft = scrollLeft + actual;
      };
      _dom.onmouseup = function () {
        smoothScroll();
      };
      _dom.onmouseleave = function (event) {
        if (_dom !== event.target) return;
        // console.log('mouseleave');
        smoothScroll();
      };
    },
    handleScroll(event) {
      var _dom = event.target;
      this.left = !(_dom.scrollLeft < this.step);
      this.right = !(
        Math.abs(_dom.scrollLeft) + _dom.clientWidth >
        _dom.scrollWidth - this.step
      );
    },
    scrollAct(act) {
      var _step = this.step;
      this.$nextTick(() => {
        let _dom = this.$refs.scrollBox;
        let _now = _dom.scrollLeft;
        let _to = act ? _now + _step : _now - _step;
        _dom.scroll({ left: _to, behavior: "smooth" });
      });
    },

    async getGift(id) {
      let { code } = await $api.receiveAward({
        is_anchor: this.fpkMenuActive,
        ranking_id: id
      })
      if (code == 200) {
        this.$message.closeAll()
        this.$message({
          message: '领取成功',
          type: 'success'
        });
        this.getAwardList(this.fpkMenuActive)
      }
    },
    async getAwardList(is_anchor) {
      if (is_anchor > 1) return
      let { data, code } = await $api.awardList(is_anchor)
      if (code == 200) {
        this.awardList = data;
      }
    },
    // meActiveFn() {
    //   this.meActive = !this.meActive;
    // },
    getTypeColor(type) {
      let odd = ''
      this.game_odds_list.map((item) => {
        if (item.level == type) {
          odd = item.odds
        }
      })
      return odd;
    },

    // 创建房间
    toCleat() {
      if (!getToken()) {
        this.$router.push("/Fnologin");
      } else {
        // this.$router.push({ name: "creationpkHome" });
        this.drawer = true;
      }
    },
    handleChange() {

    },
    clearActiveNum(index, type) {
      if (type == 1) {
        this.createMoney = this.createMoney - this.boxList[this.boxListVal].box[index].activeNum * Number(this.boxList[this.boxListVal].box[index].bean);
      } else {
        this.createMoney = this.createMoney - this.boxList[this.boxListVal].box[index].activeNum * Number(this.boxList[this.boxListVal].box[index].game_bean);
      }

      this.tatalNum = this.tatalNum - this.boxList[this.boxListVal].box[index].activeNum;
      this.boxList[this.boxListVal].box[index].activeNum = 0;

    },
    typeGage(type) {
      if (type == 1) {
        if (this.winWorthPage == 1) {
          return
        }
        this.winWorthPage--
        this.getWinWorth(this.winWorthId, this.winWorthPage)
      } else {
        if (!this.winWorthStatus) {
          return;
        }
        this.winWorthPage++
        this.getWinWorth(this.winWorthId, this.winWorthPage)
      }
    },
    typeGage1(type) {
      if (type == 1) {
        if (this.hisPage == 1) {
          return
        }
        this.hisPage--
        this.getHistory1(this.hisPage, this.robtVal, this.robtId)
      } else {
        if (!this.hisPageStatus) {
          return;
        }
        this.hisPage++
        this.getHistory1(this.hisPage, this.robtVal, this.robtId)
      }
    },
    typeGage2(type) {
      if (type == 1) {
        if (this.hisPageRecord == 1) {
          return
        }
        this.hisPageRecord--
        this.getHistory(this.hisPageRecord, this.hisVal1, this.hisVal2, this.hisVal3)
      } else {
        if (!this.hisPageRecordStatus) {
          return;
        }
        this.hisPageRecord++
        this.getHistory(this.hisPageRecord, this.hisVal1, this.hisVal2, this.hisVal3)
      }
    },
    currentChange(val) {
      this.getWinWorth(this.winWorthId, val)
    },
    designatedDataRcode(id) {
      this.getHistory1(1, '', id)
      this.robtVal = ''
      this.robtId = id;
    },
    robtHis() {
      this.getHistory1(this.page, this.robtVal, this.robtId)
    },
    handleClose(done) {
      done();
    },
    userModelInfo(id) {
      this.userCenterType = 0;
      this.winWorthId = id;
      this.getWinWorth(id, this.winWorthPage)
      this.getLatestSeven(id)
      // this.getUserOpenStatic(id)
      this.getBlindGame(id)
      this.getUserHome(id)
    },
    async getUserHome(id) {
      let { data, code } = await $api.getUserHome({
        user_id: id
      })
      if (code == 200) {
        this.centerUserHome = data;
      }
    },
    async getBlindGame(id) {
      let { data, code } = await $api.getBlindGame(id)
      if (code == 200) {
        this.blindGame = data
      }
    },
    async getWinWorth(id, page) {
      let { data, code } = await $api.getWinWorth(id, page)
      if (code == 200) {
        if (!data.data.length) {
          this.winWorthStatus = false;
          return;
        }
        this.winWorthStatus = true;
        this.winWorthData = data.data
      }
    },
    async getLatestSeven(id) {
      let { data, code } = await $api.getLatestSeven(id)
      if (code == 200) {
        this.latestSevenData = data.box_records
        this.latestSevenTotal = data.total_bean
      }
    },
    // async getUserOpenStatic(id) {
    //   let { data, code } = await $api.getUserOpenStatic(id)
    //   if (code == 200) {
    //     this.userOpenStaticData = data
    //   }
    // },
    activeUrl(status, id, ceil) {
      if (status == 0) {
        this.$router.push('/active/mysteryBox/' + id + '?underway=0&round=0&typeurl=' + this.fpkMenuActive + '')
      } else if (status == 1) {
        this.$router.push('/active/mysteryBox/' + id + '?underway=1' + '&typeurl=' + this.fpkMenuActive + '&round=' + ceil)
      } else if (status == 2) {
        this.$router.push('/active/mysteryBox/' + id + '?&typeurl=' + this.fpkMenuActive + '')
      }
    },
    async createPk() {
      if (this.createPkStatus) {
        this.createPkStatus = false;
        let box = []
        this.boxList.map((item) => {
          item.box.map((child) => {
            if (child.activeNum != 0) {
              [...Array(child.activeNum)].map(() => {
                box.push(child.id)
              })
            }
          })
        })
        let data1 = {
          game_type: this.game_type,
          play_type: this.peopleStatus,
          box: box,
          num: Number(this.pknum),
          is_anchor: this.fpkMenuActive
        }
        if (this.peopleStatus == 2) {
          data1.designated_bot_id = this.designated_bot_id;
          data1.user_num = 2;
        } else {
          data1.user_num = this.user_num
        }
        let { data, code } = await $api.createPk(data1)
        this.createPkStatus = true;
        if (code == 200) {
          this.$message.closeAll()
          this.$message({
            message: '创建成功',
            type: 'success'
          });
          this.drawer = false;
          this.$router.push(`/active/mysteryBox/${data.id}?peopleStatus=${this.peopleStatus}&bot_id=${this.designated_bot_id}&typeurl=${this.fpkMenuActive}`)
        }
      }
    },
    async getDesignatedId() {
      let { data, code } = await $api.getDesignatedId()
      if (code == 200) {
        this.designatedData1 = data
      }
    },
    clearNum() {
      this.tatalNum = 0;
      let _data = this.boxList.map((item) => {
        item.box.map((child) => {
          child.activeNum = 0
        })
        return item;
      })
      this.boxList = _data;
    },
    boxListActiveChange(type, childIndex, beanVal) {
      if (type == 1) {
        this.tatalNum--;
        this.createMoney = this.createMoney - Number(beanVal);
        this.boxList[this.boxListVal].box[childIndex].activeNum = this.boxList[this.boxListVal].box[childIndex].activeNum - 1;
      } else {
        if (this.tatalNum >= 6) {
          this.$message.closeAll()
          this.$message({
            message: '数量已达上限',
            type: 'warning'
          });
          return;
        }
        this.tatalNum++
        this.createMoney = this.createMoney + Number(beanVal);
        this.boxList[this.boxListVal].box[childIndex].activeNum = this.boxList[this.boxListVal].box[childIndex].activeNum + 1;
      }
    },
    boxListActive(childIndex, beanVal) {
      if (this.tatalNum >= 6) {
        this.$message.closeAll()
        this.$message({
          message: '数量已达上限',
          type: 'warning'
        });
        return;
      }
      this.boxList[this.boxListVal].box[childIndex].activeNum = this.boxList[this.boxListVal].box[childIndex].activeNum + 1;
      this.tatalNum = this.tatalNum + 1
      this.createMoney = this.createMoney + Number(beanVal);

    },
    async getBoxList() {
      let { data, code } = await $api.getBoxList()
      if (code == 200) {
        let _data = data.map((item) => {
          item.box.map((child) => {
            child.activeNum = 0
          })
          return item;
        })
        _data.push({
          name: '对战盲盒',
          box: this.boxGunList
        })
        this.defaultBoxList = _data;
        let da1 = _data.filter((el) => el.id != 29)
        let da3 = da1.filter((el) => el.id != 9)
        this.boxList = da3;
      }
    },
    // 对战榜
    async getArenaRank(is_anchor) {
      let { data, code } = await $api.getArenaRank(is_anchor)
      if (code == 200) {
        this.arenaRank = data;
      }
    },
    async getArenaRate(is_anchor) {
      let { data, code } = await $api.getArenaRate(is_anchor)
      if (code == 200) {
        this.arenaRate = data;
      }
    },
    fpkMenuActiveFn(index) {
      console.log("index", index);

      this.fpkMenuActive = index;
      if (index < 2) {
        this.page = 1;
        this.getPKarenaList(this.page, index);
        this.getArenaRank(index)
        this.getArenaRate(index)
        this.getAwardList(index)
      }
    },

    fpkMenuActiveFn2(index) {
      console.log("index", index);

      // if (index) {
      //   this.fpkMenuActive = 3;
      // } else {
      //   this.fpkMenuActive = 2;
      // }
      this.fpkMenuActive = 2;

      if (index == 0 || index == 2) {
        this.hisVal3 = 0
        this.getHistory(this.hisPageRecord != 1 ? this.hisPageRecord : 1, '', 1, 0)
      } else if (index == 1) {
        this.hisVal3 = 1
        this.getHistory(this.hisPageRecord != 1 ? this.hisPageRecord : 1, '', 1, 1)
      }
    },
    updateHis() {
      this.getHistory(this.hisPageRecord, this.hisVal1, this.hisVal2, this.hisVal3)
    },
    async getHistory(hisPageRecord, is_anchor, play_type, is_me) {
      var _this = this;
      let { data, code } = await $api.getHistory(hisPageRecord, is_anchor, play_type, is_me)
      if (code == 200) {
        if (!data.data.length) {
          _this.hisPageRecordStatus = false;
          _this.historyData = []
          return;
        }
        _this.hisPageRecordStatus = true;
        _this.historyData = data.data
      }
    },
    async getHistory1(hisPage, is_anchor, id) {
      let { data, code } = await $api.getHistory1(hisPage, is_anchor, id)
      if (code == 200) {
        if (!data.data.length) {
          this.hisPageStatus = false;
          this.historyData1 = []
          return;
        }
        this.hisPageStatus = true;
        this.historyData1 = data.data;
      }
    },
    defaultType() {
      this.getTopTen(this.play_type, this.type, this.by_type)
    },
    async getTopTen(play_type, type, by_type) {
      let { data, code } = await $api.getTopTen(play_type, type, by_type)
      if (code == 200) {
        this.topTen = data;
      }
    },
    // 机器人
    async getArenaDesignatedStatic() {
      let { data, code } = await $api.getArenaDesignatedStatic()
      if (code == 200) {
        this.designatedData = data;
      }
    },
    async getPKarenaList(page, is_anchor) {
      let { data, code } = await $api.getPKarenaList(page, is_anchor)
      if (code == 200) {


        if (data.data.length == 0) {
          return;
        }
        this.page++;
        this.PKarenaList = this.PKarenaList.concat(data.data);
        this.defaultPKarenaList = this.defaultPKarenaList.concat(data.data)
        this.fifcheckList()
      }
    },
    async getPKarenaboxList() {
      let { data, code } = await $api.getPKarenaboxList()
      if (code == 200) {
        let _data = data.map((item) => {
          item.activeNum = 0
          return item;
        })
        this.boxGunList = _data;
        this.getBoxList()


        this.$nextTick(() => {
          // ---------------------

          console.log("this.boxGunList", this.boxGunList);
          if (this.boxGunList?.length) {
            // 设置JS的步增值
            this.step = this.$refs.scrollBox?.children[0]?.offsetWidth;
            console.log("this.step", this.step);
            // 判断是否有滚动条
            if (this.$refs.scrollBox.scrollWidth > this.$refs.scrollBox.offsetWidth) {
              this.arrow = true;
            } else {
              this.arrow = false;
            }
          }
          if (this.$refs.scrollBox) {
            this.$refs.scrollBox.addEventListener("scroll", this.handleScroll);
            this.$refs.scrollBox.addEventListener("mousedown", this.handelMousedown);
          }
        })
      }
    },
    async fifcheckList() {
      // 0=等待中
      // 1=进行中
      // 2=已结束
      if (this.activeName == 0) {
        this.PKarenaList = await this.defaultPKarenaList;
      }
      if (this.activeName == 1) {
        await this.filterData(1)
      }
      if (this.activeName == 2) {
        await this.filterData(0)
      }
      if (this.activeName == 3) {
        await this.filterData(2)
      }
    },

    filterData(status) {
      let data = this.defaultPKarenaList;
      let arr = []
      console.log('data', data);
      data.map(item => {
        if (item.status === status) {
          arr.push(item)

        }
      })
      this.PKarenaList = arr;
    },


    fifcheckList1() {
      if (this.checkList.length == 0) {
        this.PKarenaList = this.defaultPKarenaList;
      } else {
        let data = this.defaultPKarenaList;
        let arr = []
        data.map((item) => {
          if (this.checkList.includes(item.status_alias)) {
            arr.push(item)
          }
        })
        this.PKarenaList = arr;
      }
    },
    initWebSocket() {
      let _this = this
      _this.$ws.addEventListener('message', (event) => {
        let data = event.data
        let { Data, MsgType } = JSON.parse(data)
        /*  创建对战 */
        if (MsgType == 'CreateGroup') {
          if (_this.fpkMenuActive == Data.is_anchor) {
            _this.PKarenaList.unshift(Data)
          }
        }
        /* 加入房间 */
        if (MsgType == "joinGroup") {
          let data = {
            seat: Data.seat,
            game_arena_id: Data.game_arena_id,
            user: {
              id: Data.user_id,
              name: Data.name,
              avatar: Data.avatar,
            }
          }
          _this.PKarenaList.forEach(item => {
            if (item.id == Data.game_arena_id) {
              item.game_arena_player.push(data)
            }
          })
          // _this.defaultPKarenaList.forEach(item => {
          //   if (item.id == Data.game_arena_id) {
          //     item.game_arena_player.push(data)
          //   }
          // })
        }
        /* 进行中状态 */
        if (MsgType == "startGroup") {
          _this.PKarenaList.forEach(item => {
            if (item.id == Data.game_arena_id) {
              item.status = Data.status
              item.status_alias = Data.status_alias
            }
          })
          // _this.defaultPKarenaList.forEach(item => {
          //   if (item.id == Data.game_arena_id) {
          //     item.status = Data.status
          //     item.status_alias = Data.status_alias
          //   }
          // })
          // setTimeout(() => {
          //   this.fifcheckList1()
          // },1000)
        }
        /* 结束变成灰色的，之后浏览器删除这个， */
        if (MsgType == "endGroup") {
          // /* 写那个首页列表显示在进行状态 */
          let time = Data.box_num
          /* 对战回合数和老虎机旋转的时间，判断这个是不是在进行中的状态 */
          setTimeout(() => {
            _this.PKarenaList.forEach(item => {
              if (item.id == Data.id) {
                item.status = Data.status
                item.status_alias = Data.status_alias
              }
            })
            // _this.defaultPKarenaList.forEach(item => {
            //   if (item.id == Data.game_arena_id) {
            //     item.status = Data.status
            //     item.status_alias = Data.status_alias
            //   }
            // })
            // this.fifcheckList1()
          }, time * 10000)
        }
      })
    }
  }
}
</script>


<style lang="scss">
@import './index2.scss';

@import './dt.scss';
.game_type {
  padding: 5px;
  background: #e95500;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  color: #ffffff;
}
.game_type.type1 {
  background: #1e9bbb;
}
.game_type1 {
  padding: 5px 0;
  margin-bottom: 5px;
  .el-radio__label {
    color: #fff;
  }
  .el-radio__input.is-checked+.el-radio__label {
    color: #fff;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #f5c244;
    background: #f5c244;
  }
  .el-radio__inner {
    background-color: #878a8b;
  }
}
.close_notice {
  left: calc(50% + 320px);
  position: fixed;
  top: 11vh;
  z-index: 999;
  // display: none;
  @media (max-width:600px) {
    left: 85%;
    top: 10vh;
  }
}
.notice {
  width: 700px; 
  max-height: 70vh;
  left: calc(50% - 350px); 
  top: 15vh;
  overflow-y: scroll;
  position: relative;
  .p-2-phone {
    margin-top: 20px;
    max-height: 70vh;
  }
  @media (max-width:600px) {
    left: 50% !important;
    transform: translateX(-50%);
    .p-2-phone {
      // margin-top: 640px;
    }
  }
  
  .position_change {
    position: absolute;
    @media (max-width:600px) {
      display: none;
    }
  }
}
.loadingMore.load-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 65px;
  cursor: pointer;

  >a {
    @media (max-width:600px) {
      padding: 15px 50px;
      font-size: 14px;
    }
  }
}

.pageTotal {
  display: flex;
  justify-content: center;

  @media (max-width:600px) {
    margin-bottom: 20px;
  }

  >a {
    cursor: pointer;
    background-color: #042D3A;
    padding: 14px 60px;
    margin: 0 1px;
    border-radius: 4px;
    color: #fff !important;
    border: 2px solid rgba(12, 113, 146, 0.71);
  }
}


::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px; //这是竖向滚动条的宽度。如果是横向滚动条则设置height
}

::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  background: #ffffff41;
}

::-webkit-scrollbar-track {
  background: #0e0d0d;
  border-radius: 2.5px;
}

.lv4 {
  color: #62B0F9 !important;
}

.lv4bg {
  background: #1F2748;
}

.lv5 {
  color: #aeaeae !important
}

.lv5bg {
  background: #373737c8;
}

.lv3 {
  color: #D14DF3 !important
}

.lv3bg {
  background: #262347;
}

.lv2 {
  color: #EA5455 !important
}

.lv2bg {
  background: #342232;
}

.lv1 {
  color: #FC9401 !important
}

.lv1bg {
  background: #4C382C;
}

.dt-span-sty {
  display: inline-block;
  height: 30px;
  line-height: 30px;
}</style>