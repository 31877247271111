<template>
    <div class="content battlefield">
        <div>
            <div>
                <img v-if="isBaoZha" class="is-bao-zha" src="../../assets/img/dt/version-2/SKbaozha.webp" alt="">
                <div v-if="!isGameStart">
                    <div class="king-box" v-if="ua == 'web' && kingList?.length">
                        <!-- 排行榜 -->
                        <div class="title"></div>
                        <ul>
                            <li v-for="(item, index) of kingList" :key="index">
                                <div class="img-box">
                                    <img :src="item.user.avatar" alt="">
                                    <span class="name">{{ item.user.name }}</span>
                                </div>
                                <div class="btn">
                                    <div class="nb" v-if="item.status">通关</div>
                                    <div class="no-nb" v-else>{{ `第${item.level}关` }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <img @click="playingMethod = true" class="extension_index_img top-tips"
                        src="@/assets/img/dt/version-2/illustrate.png" style="width: 100px" />
                    <br />

                    <div>
                        <img style="width:200px;" v-if="ua == 'phone'" src="@/assets/img/dt/icon1.png"
                            @click="viewLeaderboard" alt="" />
                    </div>
                    <h2 class="uptitle" v-if="!phoneLeaderboard">选择奖励价格区间</h2>
                    <div v-if="!phoneLeaderboard">
                        <div class="tabs">
                            <div class="tabs-item" :class="active == index ? 'active' : ''"
                                v-for="(item, index) of mineMoneyList" :key="index" @click="tabsHandel(item, index)">
                                {{ item }}</div>
                        </div>
                        <img class="extension_index_img2" src="@/assets/img/dt/reast.png" @click="restting" alt="">

                        <div class="price-info">
                            <!-- <img src="@/assets/img/ADT/home/up.png" style="width:20px;margin:0 10px" />
                    <span>{{ createData.payPrice }}</span> -->
                        </div>
                        <!-- 开始游戏 -->
                        <img v-if="ua=='web'" class="btn-submit" src="@/assets/img/dt/cd.png" @click="btnSubmit" alt="">
                        <img v-else class="btn-submit" src="@/assets/img/dt/cd1.png" @click="btnSubmit" alt="">
                        <div class="upMoney">{{ createData.payPrice }}UP币</div>
                        <div class="f-content-box">
                            <div :class="['item-live', `live-bg${item?.level}`]"
                                v-for="(item, index) of createData.prizeItems" :key="index" @click="itemClick">
                                <div class="top">
                                    {{ dictionary[index - 1] }}
                                </div>
                                <div class="content">
                                    <img class="hvr-bob" :src="item.cover" alt="">
                                    <div class="jewelry-name">{{ item.name }}</div>
                                </div>
                                <div class="bottom">

                                    <div class="bottom-top">
                                        <img src="@/assets/img/ADT/home/up.png" />
                                        <span>{{ item.bean }}</span>
                                    </div>
                                    <div class="bottom-live">第{{ item?.level }}关卡</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="king-box-phone position-relative" v-if="ua == 'phone' && kingList?.length">
                            <!-- 排行榜 -->
                            <div class="title">通关排行榜</div>
                            <div @click="viewLeaderboard" class="position-absolute" style="right: 20px;top: 30px">
                                <div>
                                    <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                                </div>
                            </div>
                            <ul>
                                <li v-for="(item, index) of kingList" :key="index">
                                    <div class="img-box">
                                        <img :src="item.user.avatar" alt="">
                                        <span class="name">{{ item.user.name }}</span>
                                    </div>
                                    <div class="btn">
                                        <div class="nb" v-if="item.status">通关</div>
                                        <div class="no-nb" v-else>{{ `第${item?.level}关` }}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 开始拆弹 -->
                <div v-else>
                    <div class="now-Level">
                        第{{saolei_status ? createData?.passLevelItems[createData?.passLevelItems?.length - (isNextLevel ? 2 : 1)]?.level || 1 : createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level || 1}}关卡
                    </div>

                    <div class="f-content-box">
                        <div :class="['item-live', `live-bg${item?.level}`]" v-for="(item, index) of createData.prizeItems"
                            :key="index" @click="itemClick">
                            <div class="top"
                                :style="{ color: index == (saolei_status ? createData?.passLevelItems[createData?.passLevelItems?.length - (isNextLevel ? 2 : 1)]?.level || 1 : createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level || 1) ? '#80C00C' : '#fff' }">
                                {{ dictionary[index - 1] }}
                            </div>
                            <div class="content">
                                <img class="hvr-bob" :src="item.cover" alt="">
                                <div class="jewelry-name">{{ item.name }}</div>
                            </div>
                            <div class="bottom">

                                <div class="bottom-top">
                                    <img src="@/assets/img/ADT/home/up.png" />
                                    <span>{{ item.bean }}</span>
                                </div>
                                <div class="bottom-live">第{{ item?.level }}关卡</div>
                            </div>
                        </div>
                    </div>

                    <div class="start-saolei-container" v-if="isNextLevel">
                        <!-- 奖区循环 -->
                        <div class="leiqu-box" v-for="(item, index) of createData.prizeItems" :key="index">
                            <template
                                v-if="item?.level === createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level">
                                <div class="right"
                                    v-for="(zhadanItem, zhadanIndex) of saolei_status ? createData?.mineLatticeConfig[createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level][0] + 1 : createData?.passLevelItems?.length > 1 ? createData?.mineLatticeConfig[(createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level)][0] : createData?.mineLatticeConfig[(createData?.passLevelItems[0]?.level)][0]"
                                    :key="zhadanIndex" @click="clickzhadanItem($event, zhadanItem, zhadanIndex)">
                                    <img class="renwua"
                                        :src="require(`../../assets/img/dt/version-2/${saolei_status ? createData?.passLevelItems[createData?.passLevelItems?.length - (isNextLevel ? 2 : 1)]?.level || 1 : createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level || 1}.png`)"
                                        alt="">
                                    <div class="zhadan" v-if="Object.values(baozhaData).length == 0">

                                        <img v-if="isNextLevelData[isNextLevelData?.length - 1]?.noMineLattice?.indexOf(zhadanIndex + 1) != -1 && !isOpen"
                                            class="cover"
                                            :src="!saolei_status ? createData?.prizeItems[index]?.cover : createData?.prizeItems[index - 1]?.cover"
                                            alt="">
                                    </div>
                                    <div class="zhadan" v-else>

                                        <img v-if="isNextLevelData[isNextLevelData?.length - 1]?.noMineLattice?.indexOf(zhadanIndex + 1) != -1 && !isOpen"
                                            class="cover"
                                            :src="!saolei_status ? createData?.prizeItems[index]?.cover : createData?.prizeItems[index - 1]?.cover"
                                            alt="">
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="zhadan-nums">
                            <img src="@/assets/img/dt/moren.webp" alt="">
                            <p>本区域炸弹 <span class="num">{{
                                saolei_status ?
                                createData?.mineLatticeConfig[createData?.passLevelItems[createData?.passLevelItems?.length
                                    - 2]?.level][1] : createData?.passLevelItems?.length > 1 ?
                                    createData?.mineLatticeConfig[(createData?.passLevelItems[createData?.passLevelItems?.length
                                        - 1]?.level)][1] :
                                    createData?.mineLatticeConfig[(createData?.passLevelItems[0]?.level)][1]
                            }}</span>个</p>
                        </div>
                    </div>

                    <div class="start-saolei-container" v-else>
                        <!-- 奖区循环 -->
                        <div class="leiqu-box" v-for="(item, index) of createData.prizeItems" :key="index">
                            <template
                                v-if="item?.level === createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level">
                                <div class="right"
                                    v-for="(zhadanItem, zhadanIndex) of createData.mineLatticeConfig[createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level][0]"
                                    :key="zhadanIndex" @click="clickzhadanItem($event, zhadanItem, zhadanIndex)">
                                    <img class="renwua"
                                        :src="require(`../../assets/img/dt/version-2/${saolei_status ? createData?.passLevelItems[createData?.passLevelItems?.length - (isNextLevel ? 2 : 1)]?.level || 1 : createData?.passLevelItems[createData?.passLevelItems?.length - 1]?.level || 1}.png`)"
                                        alt="">
                                    <div class="zhadan mlgbd-box"
                                        :style="{ position: (saolei_status === 2 && createData?.passLevelItems[createData?.passLevelItems?.length - 2]?.noMineLattice?.indexOf(zhadanIndex + 1) != -1) ? 'absolute' : 'inherit' }"
                                        v-if="Object.values(baozhaData).length == 0">
                                        <div class="mlgbd">

                                            <img v-if="saolei_status === 2 && createData?.passLevelItems[createData?.passLevelItems?.length - 2]?.noMineLattice?.indexOf(zhadanIndex + 1) != -1"
                                                :src="successInfoData[0]?.cover" alt="">
                                        </div>
                                        <img v-if="createData?.passLevelItems[createData?.passLevelItems?.length - 2]?.noMineLattice?.indexOf(zhadanIndex + 2) != -1 && isOpen"
                                            class="cover" :src="item.cover" alt="">
                                    </div>
                                    <div class="zhadan" v-else>

                                        <img v-if="baozhaData?.passLevelItems?.noMineLattice?.indexOf(zhadanIndex + 2) != -1 && isOpen"
                                            class="cover" :src="item.cover" alt="">
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="zhadan-nums">
                            <img src="@/assets/img/dt/moren.webp" alt="">
                            <p>本区域炸弹 <span class="num">{{
                                createData.mineLatticeConfig[createData?.passLevelItems[createData?.passLevelItems?.length
                                    -
                                    1]?.level][1]
                            }}</span>个</p>
                        </div>
                    </div>
                </div>

                <!-- 支付弹窗 -->
                <div class="pay-prop" v-if="payProp">
                    <div style="width: 500px;left: calc(50% - 250px);top:20vh"
                        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                        <div class="text-white p-md-4 p-2" style="width: 100%;line-height: 2.0">
                            <h1 class="pay-prop-title">是否进行挑战?</h1>
                            <p class="pay-prop-info">您需要支付
                                <money />{{ createData?.payPrice }}
                            </p>
                            <div class="pay-prop-btns">
                                <div @click="payProp = false" class="pay-prop-cancellation">取消</div>
                                <div @click="confirmPay" class="pay-prop-confirm">确认</div>
                            </div>
                        </div>
                        <div @click="payProp = false" class="position-absolute" style="right: 20px;top: 30px">
                            <div>
                                <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                            </div>
                        </div>
                    </div>
                    <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;">
                    </div>
                </div>

                <!-- 继续下一关挑战弹窗 -->
                <div class="next-level-pay-prop" v-if="nextLevelPayProp">
                    <div style="width: 500px;left: calc(50% - 250px);top:20vh"
                        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                        <div class="text-white p-md-4 p-2" style="width: 100%;line-height: 2.0">
                            <h1 class="next-level-pay-prop-title">请确认下一步操作?</h1>
                            <p class="next-level-pay-prop-info" style="color: #00ff18;">请仔细阅读活动规则，理性游戏!</p>
                            <div class="next-level-pay-prop-btns">
                                <div @click="nextLevelPayPropHandel" class="next-level-pay-prop-cancellation">继续挑战</div>
                                <div @click="giveUpHandel" class="next-level-pay-prop-confirm">终止挑战</div>
                            </div>
                        </div>
                    </div>
                    <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;">
                    </div>
                </div>

                <!-- 挑战成功/失败弹窗 -->
                <div class="error-pay-prop" v-if="infoPayProp">
                    <div style="width: 500px;left: calc(50% - 250px);top:20vh"
                        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                        <div class="text-white p-md-4 p-2" style="width: 100%;line-height: 2.0">
                            <h1 class="error-pay-prop-title">{{ propTitle }}</h1>
                            <p class="error-pay-prop-info">当前可领取</p>
                            <img class="info-img"
                                :src="(propTitle == '挑战成功' || propTitle == '通关') ? successInfoData[0]?.cover : baozhaData?.skins?.cover"
                                alt="">
                            <p>{{ (propTitle == '挑战成功' || propTitle == '通关') ? successInfoData[0].name :
                                baozhaData?.skins?.name
                            }}</p>
                            <div class="error-pay-prop-price-info"
                                v-if="successInfoData[0]?.bean || baozhaData?.skins?.bean">
                                <money />
                                <span>{{ (propTitle == '挑战成功' || propTitle == '通关') ? successInfoData[0]?.bean :
                                    baozhaData?.skins?.bean }}</span>
                            </div>
                            <div class="error-pay-prop-btns">
                                <div @click="putBackpack" class="error-pay-prop-cancellation">放入背包</div>
                                <div @click="exchange" class="error-pay-prop-confirm">
                                    <money v-if="successInfoData[0]?.bean || baozhaData?.skins?.bean" />
                                    <span>{{ (propTitle == '挑战成功' || propTitle == '通关') ? successInfoData[0]?.bean :
                                        baozhaData?.skins?.bean }}兑换</span>
                                </div>
                            </div>
                        </div>
                        <div @click="putBackpack" class="position-absolute" style="right: 20px;top: 30px">
                            <div>
                                <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size:30px"></i>
                            </div>
                        </div>
                    </div>
                    <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;">
                    </div>
                </div>

                <!-- 玩法介绍 -->
                <div v-if="playingMethod">
                    <div style="width: 700px;top:15vh"
                        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg play_tips">
                        <div style="font-size: 25px;">玩法说明</div>
                        <div class="text-white p-md-4 p-2" style="line-height: 2.0;text-align: left;">
                            <p>1、拆弹战场共有6关，每关都有对应的饰品奖励。游戏开始前，玩家可根据价格区间选择闯关。</p>
                            <p>&nbsp;</p>
                            <p>2、点击“重置奖励”即可免费重置战场，点击“开始拆弹”按钮支付对应U币即可开始挑战。</p>
                            <p>&nbsp;</p>
                            <p>3、在关卡内，成功拆中奖品，则进入下一关，若拆中炸弹，则结束游戏，获得随机饰品</p>
                            <p>&nbsp;</p>
                            <p>4、玩家通过每一关后可选择放弃挑战，奖励为当前成功的最高层奖品。（例：玩家此时挑战第三层，放弃当前挑战，获得第二层奖品）</p>
                            <p>&nbsp;</p>
                            <p>5、玩家拆弹全部通关，即可获得当前战场最高层奖励。</p>
                            <p>&nbsp;</p>
                            <p>6、请仔细查阅《用户协议》及相应条款，保持理性消费!</p>
                        </div>
                        <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                            <div>
                                <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                            </div>
                        </div>
                    </div>
                    <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/saolei.js'
import $userApi from '@/api/user/index.js'
import sUserAgent from '@/assets/js/common.js'
export default {
    name: "index",
    components: {
    },
    data() {
        return {
            isBaoZha: false,
            phoneLeaderboard: false,
            isNextLevel: false,
            isNextLevelData: {}, // 上一关数据结果
            baozhaData: {},// 踩雷的结果
            nowIndex: 0, //当前炸弹索引
            propTitle: '挑战成功',
            successInfoData: [], // 奖品数据
            infoPayProp: false, // 挑战失败
            nextLevelPayProp: false, // 挑战成功
            saolei_status: 0,
            isOpen: false,
            payProp: false, // 支付弹窗
            isGameStart: false,
            dictionary: ['远古遗迹', '阿努比斯', '炼狱小镇', '荒漠迷城', '炙热沙漠Ⅱ', '托斯卡纳'],
            // 拆弹
            createData: {},
            playingMethod: false,
            active: 0,
            mineMoneyList: [],
            kingList: []
        }
    },
    mounted() {
        console.log('mounted');
        this.init();
    },
    created() {
    },
    computed: {
        ua() {
            return sUserAgent.sUserAgent()
        },
        nowLevel: function () {
            return this.createData?.passLevelItems[this.createData?.passLevelItems?.length - 1]?.level || 0;

        },
    },
    destroyed() {
        document.getElementById("guoguan").load();
        document.getElementById("baozha").load();
    },
    methods: {
        // 查看排行榜
        viewLeaderboard() {
            this.phoneLeaderboard = !this.phoneLeaderboard;
        },
        // 放入背包
        putBackpack() {
            document.getElementById("baozha").load();
            this.infoPayProp = false;
            this.isGameStart = false;
            this.saolei_status = 0
            this.isOpen = false;
            this.getInfoHandle()
            this.$EventBus.$emit("setBackage", 0)
        },
        async getUserInfo() {
            try {
                console.log("$userApi", $userApi);
                let res = await $userApi.getUsetInof()
                if (res?.code === 500) return
                if (res?.code === 200) {
                    this.$store.dispatch('userInfo')
                }

            } catch (error) {
                console.log(error);
            }
        },
        // 兑换分解
        async exchange() {
            try {
                document.getElementById("baozha").load();
                document.getElementById("guoguan").load();
                let data = {
                    data: [this.saolei_status ? this.successInfoData[0].id : this.baozhaData.skins.id]
                }
                let res = await $api.getRecycle(data)
                if (res?.code === 500) return
                if (res?.code === 200) {
                    this.$notify({
                        title: '提示',
                        message: res?.message,
                        type: 'success', position: 'top-left'
                    });
                    this.isGameStart = false;
                    this.saolei_status = 0
                    this.infoPayProp = false;
                    this.isOpen = false;
                    this.getInfoHandle()
                    this.getUserInfo()
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 继续挑战
        async nextLevelPayPropHandel() {
            // this.createData = []
            document.getElementById("guoguan").load();
            this.nextLevelPayProp = false;
            this.isNextLevel = false
            await this.getInfoHandle();
            this.$EventBus.$emit("setBackage", this.nowLevel);
            this.isOpen = false;
        },
        clickzhadanItem(e, zhadanItem, zhadanIndex) {
            console.log("e,zhadanItem,zhadanIndex", e, zhadanItem, zhadanIndex)
            // if (e.currentTarget.firstElementChild.firstElementChild.src) {
            //     // e.currentTarget.firstElementChild.firstElementChild.setAttribute('src', require('../../assets/img/dt/chengg.webp'))
            // }
            this.open(zhadanIndex)
        },
        async open(index) {
            try {
                let obj = this.createData?.passLevelItems?.filter(item => {
                    if (item.passState === 0) {
                        return item
                    }
                })
                let params = {
                    saolei_level_id: obj[0].id,
                    select_id: index + 1
                }
                let res = await $api.open(params)
                if (res?.code === 500) return
                if (res?.code === 200) {
                    if (res.data.saolei_status === 0) {
                        document.getElementById("baozha").play();
                        this.isBaoZha = true;
                        setTimeout(() => {
                            this.isBaoZha = false;
                            this.infoPayProp = true;
                        }, 1500);
                        this.propTitle = '挑战失败'
                        this.nowLevel = 0;
                        this.baozhaData = res?.data;

                        if (Object.prototype.toString.call(res?.data?.passLevelItems) === '[object Object]') {
                            let arr = [];
                            arr.push(res?.data?.passLevelItems)
                            this.isNextLevelData = arr;
                        } else {
                            this.isNextLevelData = res?.data?.passLevelItems?.filter(item => item.passState === 1)
                        }
                        this.$EventBus.$emit("setBackage", 0)
                        this.nowIndex = index;
                        this.saolei_status = res.data.saolei_status;
                        this.isNextLevel = true;
                    }
                    if (res.data.saolei_status === 1) {
                        document.getElementById("guoguan").play();
                        this.propTitle = '挑战成功'
                        setTimeout(() => {
                            this.nextLevelPayProp = true;
                        }, 1000);

                        this.getInfoHandle();
                        this.nowIndex = index;
                        this.saolei_status = res.data.saolei_status;
                        this.isNextLevel = true;
                    }
                    if (res.data.saolei_status === 2) {
                        document.getElementById("guoguan").play();
                        this.propTitle = '通关'

                        this.successInfoData = [res?.data?.skins]
                        this.saolei_status = res.data.saolei_status
                        // this.nextLevelPayProp = false;
                        setTimeout(() => {
                            this.infoPayProp = true;

                        }, 1000);
                        // this.isOpen = false;
                        //     setTimeout(() => {
                        //         this.isGameStart = false;
                        //         this.saolei_status = 0
                        //            this.nowIndex = index;
                        // this.saolei_status = res.data.saolei_status;
                        // this.isNextLevel = true;
                        //         this.getInfoHandle();
                        //     }, 3000);
                    }

                    // 弹出获奖弹窗
                } else {
                    this.$notify({
                        title: '失败',
                        message: res?.message,
                        type: 'error', position: 'top-left'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 终止挑战
        async giveUpHandel() {
            this.nextLevelPayProp = false
            try {
                let saolei_id = {
                    saolei_id: this.createData.id
                }
                let res = await $api.giveUp(saolei_id)
                if (res?.code === 500) return
                if (res?.code === 200) {
                    this.successInfoData = res?.data
                    this.infoPayProp = true
                    this.$EventBus.$emit("setBackage", 0)
                    // 弹出获奖弹窗
                }
            } catch (error) {
                console.log(error);
            }
        },
        itemClick(item, index) {
            console.log("item,index", item, index);
        },
        // 初始化数据
        async init() {
            await this.getInfoHandle();
            // 查询排行榜数据
            await this.king();

            if (this.createData.payState) return;
            this.active = this.createData?.price_id;
            await this.interval(this.active);
        },
        // 初始数据 info
        async getInfoHandle() {
            try {
                this.createData = [];
                this.isNextLevel = false;
                let res = await $api.getInfo();
                if (res?.code === 500) return
                if (res?.code === 200) {
                    if (res?.data?.payState) {
                        this.isGameStart = true;
                    }
                    this.createData = res?.data
                    this.mineMoneyList = res?.data?.priceConfig;// 选择奖励价格区间数据
                    if (this.saolei_status) {
                        this.isNextLevelData = res?.data?.passLevelItems?.filter(item => item.passState === 1)
                    }


                }
            } catch (error) {
                console.log(error);
            }
        },
        // 重置奖励
        async resetRewards() {
            try {
                let res = await $api.extractReward()
                if (res?.code === 500) return
                if (res?.code === 200) {
                    this.createData = res?.data;
                    // this.$notify({
                    //     title: '提示信息',
                    //     message: '重置成功',
                    //     type: 'success', position: 'top-left'
                    // });
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 排行榜单
        async king() {
            try {
                let res = await $api.getTopKing();
                if (res?.code === 500) return
                if (res?.code === 200) {
                    this.kingList = res?.data
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 更新
        async uploadData() {
            try {
                let res = await $api.extractReward();
                if (res?.code === 500) return
                if (res?.code === 200) {
                    this.createData = res?.data
                    this.mineMoneyList = res?.data?.priceConfig// 选择奖励价格区间数据
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 拆弹
        tabsHandel(item, index) {
            this.active = index
            this.interval(index)
        },

        async interval(price_id) {
            try {
                let res = await $api.userPriceUpdate({ price_id: price_id })
                if (res?.code === 500) return
                if (res?.code === 200) {
                    this.uploadData()
                }
            } catch (error) {
                console.log(error);
            }
        },

        // 点击此处重置奖励
        async restting() {
            await this.resetRewards()
        },
        // 开始游戏
        async confirmPay() {
            try {
                let saolei_id = {
                    saolei_id: this.createData?.id
                }
                let res = await $api.saoleiPay(saolei_id);
                if (res.code == 500) return
                if (res?.code === 200) {
                    this.payProp = false;
                    this.isGameStart = true;
                    await this.getInfoHandle();
                    await this.getUserInfo();

                }
            } catch (e) {
                console.log(e);
            }
        },

        async btnSubmit() {
            if (this.createData.payState) {
                this.isGameStart = true;
            } else {
                this.payProp = true;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.play_tips {
    width: 90%;
    left: 50% !important;
    transform: translateX(-50%);
}
.uptitle {
    font-weight: bold;
    font-size: 22px;
    font-weight: 300;
    margin-top: 20px;
    color: #fff;
    @media (max-width:600px) {
        font-size: 18px;
        margin-bottom: 20px;
    }
}

.upMoney {
    position: relative;
    margin-top: -36px;
    font-family: Monaco;
    font-size: 14px;
    font-weight: bold;
    text-shadow: 1px 1px 1px #222;
    @media (max-width:600px) {
        margin-top: -90px;
        margin-bottom: 100px;
    }
}

.zhadan.mlgbd-box {

    bottom: 90px;
    left: 50%;
    transform: translateX(-55%);

    .mlgbd img {
        width: 140px;

        background-color: #fff;
        border-radius: 50%;
    }
}

.pay-prop {
    .pay-prop-title {
        font-weight: 700;
        color: #ddd;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .pay-prop-info {
        margin: 30px 0;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .pay-prop-btns {
        display: flex;
        justify-content: space-around;
        color: #ddd;
        font-family: Monaco;
        cursor: pointer;

        &>div {
            border: 1px solid rgba(248, 225, 21, .5843137255);
            background-color: rgba(106, 97, 13, .3294117647);
            border-radius: 4px;
            padding: 8px 0;
            width: 130px;
            justify-content: center;
            display: flex;
            font-size: 14px;
            align-items: center;
            font-family: Monaco;
        }

    }
}

.error-pay-prop {
    .error-pay-prop-title {
        font-weight: 700;
        color: #ddd;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .error-pay-prop-info {
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .info-img {
        width: 180px;
    }

    .error-pay-prop-price-info {
        display: flex;
        justify-content: center;
    }

    .error-pay-prop-btns {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        color: #ddd;
        font-family: Monaco;
        cursor: pointer;

        &>div {
            border: 1px solid rgba(248, 225, 21, .5843137255);
            background-color: rgba(106, 97, 13, .3294117647);
            border-radius: 4px;
            padding: 8px 0;
            width: 130px;
            justify-content: center;
            display: flex;
            font-size: 14px;
            font-family: Monaco;
        }

        &>span {
            display: inherit;
        }
    }
}

.next-level-pay-prop {
    .next-level-pay-prop-title {
        font-weight: 700;
        color: #ddd;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .next-level-pay-prop-info {
        margin: 30px 0;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .next-level-pay-prop-btns {
        display: flex;
        justify-content: space-around;
        color: #ddd;
        font-family: Monaco;
        cursor: pointer;

        &>div {
            border: 1px solid rgba(248, 225, 21, .5843137255);
            background-color: rgba(106, 97, 13, .3294117647);
            border-radius: 4px;
            padding: 8px 0;
            width: 130px;
            justify-content: center;
            display: flex;
            font-size: 14px;
            align-items: center;
            font-family: Monaco;
        }

    }
}

.battlefield {
    background-image: url(../../assets/img/dt/default.jpg);

    .now-Level {
        // margin: 20px 0;
        color: #35C9EE;
        font-size: 20px;
        padding-top: 30px;
        font-size: 24px;
        font-family: Monaco;
        font-weight: bold;
    }

    .start-saolei-container {
        .leiqu-box {
            display: flex;
            align-items: center;
            justify-content: center;

            &>.right:nth-child(2n) {
                top: 220px !important;

            }

            .right {
                // margin-right: 20px;
                cursor: pointer;
                position: relative;

                .zhadan {
                    width: 220px;
                    // height: 565px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-repeat: no-repeat;

                    &>.cover {
                        width: 140px;
                        // height: 200px;
                        position: absolute;
                        bottom: 90px;
                        left: 50%;
                        transform: translateX(-55%);
                        background-color: #fff;
                        border-radius: 50%;
                    }

                    .dingshiqi {
                        width: 150px;
                        position: absolute;
                        bottom: 140px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                }
            }

            .right:last-child {
                margin-right: 0;
            }

        }

        .zhadan-nums {
            margin-top: 300px;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &>img {
                width: 125px;
            }

            &>p {
                color: #fff;
                font-size: 18px;

                &>span {
                    color: #7ABA0B;
                    font-weight: bold;
                    margin: 0 5px;
                    font-size: 25px;
                }
            }

            &>.give-up {
                margin: 20px auto;
                width: 150px;
                text-align: center;
                color: #fff;
                font-weight: bold;
                height: 65px;
                line-height: 65px;
                background-color: #999;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }

    .king-box-phone {
        width: 100%;
        height: 600px;
        overflow-y: auto;
        background-color: rgba(0, 0, 0, .5);

        &>div.title {
            padding: 30px;
            color: #44cbff;
            border-bottom: 2px solid #44cbff;
            font-weight: bold;
        }

        &>ul {
            &>li {
                display: flex;
                align-items: center;
                background-color: #1d1e1f;
                padding: 30px 10px;
                justify-content: space-between;

                &>.img-box {
                    display: flex;
                    align-items: center;

                    &>img {
                        width: 35px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    &>.name {}
                }

                &>.btn {
                    &>div {
                        padding: 2px 10px;
                        color: #fff;
                    }

                    &>.nb {
                        background-color: #00ff18;
                        color: #000;
                    }

                    &>.no-nb {
                        background-color: #656565;
                    }
                }
            }

            &>li:nth-child(2n) {
                background-color: #24282c;
            }
        }
    }

    .king-box::-webkit-scrollbar {
        display: none;
    }

    .king-box {
        z-index: 9;
        position: absolute;
        top: 30px;
        right: 4%;
        width: 300px;
        height: 480px;
        scrollbar-width: none;
        /* firefox */
        -ms-overflow-style: none;
        /* IE 10+ */
        overflow-x: hidden;
        overflow-y: auto;
        // background-color: #00121759;

        &>div.title {
            height: 63px;
            color: #44cbff;
            border-bottom: 2px solid #44cbff;
            font-weight: bold;
            background-image: url(../../assets/img/dt/icon1.png);
        }

        &>ul {
            &>li {
                display: flex;
                align-items: center;
                background-color: #1d1e1f;
                padding: 14px 20px;
                justify-content: space-between;

                &>.img-box {
                    display: flex;
                    align-items: center;

                    &>img {
                        width: 34px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }

                &>.btn {
                    &>div {
                        padding: 2px 10px;
                        color: #fff;
                    }

                    &>.nb {
                        background-color: #00ff18;
                        color: #000;
                    }

                    &>.no-nb {
                        background-image: url(../../assets/img/dt/icon2.png);
                        // background-color: #656565;
                        background-size: 70px 35px;
                        width: 70px;
                        height: 35px;
                        line-height: 32px;
                        font-family: Monaco;
                        font-size: 12px;
                    }
                }
            }

            &>li:nth-child(2n) {
                // background-color: #24282c;
            }
        }
    }

    .extension_index_img {
        margin-top: 30px;
    }

    .extension_index_img2 {
        margin-top: 0px;
        cursor: pointer;
        width: 180px;
    }

    .price-info {
        margin: 20px 0 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-submit {
        cursor: pointer;
    }

    .tabs {
        width: 558px;
        margin: 20px auto 30px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        // overflow: hidden;
        cursor: pointer;
        background-image: url(../../assets/img/dt/bj7.png);
        background-size: 558px 50px;

        @media (max-width:600px) {
            background-size: 100% 45px;
            height: 45px !important;
        }

        .tabs-item {
            padding: 20px;
            font-family: Monaco;
        }

        .tabs-item:first-child {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }

        .tabs-item:last-child {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }

        .tabs-item.active {
            background: #389EB7;
            padding: 15px;
            border-radius: 20px 0 20px 0;
            @media (max-width:600px) {
                padding: 0 15px;
                line-height:35px
            }
        }
    }

    // 移动端适配
    @media (max-width:600px) {
        .now-Level>img {
            width: 150px;
        }

        .extension_index_img.top-tips {
            width: 80px !important;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .extension_index_img {
            margin-top: 0px;
            width: 170px;
        }

        .extension_index_img2 {
            width: 28%;
            margin-top: 10px;
            cursor: pointer;
        }

        .tabs {
            width: 100%;
            height: 22px;
            margin: 10px auto;
            border: none;

            .tabs-item {
                height: 35px;
                line-height: 15px;
                padding: 10px;
                background: rgb(71, 129, 172);
                font-size: 12px;
                @media (max-width:600px) {
                    background-color: transparent;
                }
            }
        }

        .btn-submit {
            width: 210px;
        }

        .f-content-box {
            font-size: 12px !important;
            margin-top: 50px !important;
            height: 375px !important;
            flex-wrap: wrap;

            .item-live {
                width: 120px !important;
                margin-right: 5px !important;
                margin-left: 5px !important;
                margin-bottom: 10px !important;
                font-size: 12px !important;
                background-position: center;

                .content {
                    top: 20% !important;

                    &>div {
                        display: none;
                        font-size: 12px;
                    }

                    &>img {
                        width: 75px !important;
                        @media (max-width:600px) {
                            margin-top: 10px;
                        }
                    }
                }

                .top {
                    font-size: 12px;
                    white-space: nowrap;
                    top: 5px;
                    @media (max-width:600px) {
                        top: 8px;
                    }
                    @media (max-width:420px) {
                        top: 22px !important;
                    }
                    @media (max-width:380px) {
                        top: 5px !important;
                    }
                }

                .bottom {
                    bottom: 60px;

                    .bottom-top {
                        display: none !important;
                    }
                }
            }

            @media (max-width:600px) {
                .item-live:nth-child(3n) {
                margin-right: 0px;
            }
            }
        
            @media (max-width:375px) {
                .item-live:nth-child(2n) {
                margin-right: 0px;
            } 
            }
            
        }

        .start-saolei-container {
            .leiqu-box {
                flex-wrap: wrap !important;

                &>.right:nth-child(2n) {
                    top: -20px !important;
                }

                .right {
                    position: relative;
                    top: -20px;
                    height: 150px;

                    .renwua {
                        width: 100px;
                    }

                    .zhadan {
                        width: 90px;
                        height: 135px;
                        background-size: cover;

                        &>.cover {
                            width: 65px;
                            position: absolute;
                            bottom: 60px;
                            left: 50%;
                            transform: translateX(-55%);
                        }

                        .dingshiqi {
                            width: 65px;
                            bottom: 25px;
                        }
                    }
                }
            }

            .zhadan-nums {
                margin-top: 0 !important;

                &>img {
                    width: 65px;
                }

                &>p {
                    font-size: 12px;

                    &>.num {
                        font-size: 16px;
                    }
                }

                &>.give-up.hvr-buzz-out {
                    margin: 10px auto;
                    width: 90px;
                    text-align: center;
                    color: #fff;
                    font-weight: bold;
                    height: 30px;
                    line-height: 30px;
                    background-color: #999;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 12px;
                }
            }
        }

        .pay-prop {
            .pay-prop-btns {
                &>div {
                    width: 75px !important;
                    padding: 0 !important;
                }
            }
        }
    }

    @media (max-width:600px) {
        .battlefield {
            .extension_index_img {
                margin-top: 20px;
            }

            .f-content-box {
                border-radius: 0px;

                .text-center {
                    font-size: 14px;
                    margin-top: 20px !important;
                }

                .d-flex {
                    .flex {
                        font-size: 12px;

                        >span {
                            font-size: 12px;
                        }
                    }

                    >div:nth-child(2) {
                        width: 100px !important;
                    }
                }
            }
        }
    }


    @media (max-width:320px) {
        .f-content-box {
            .bottom {
                bottom: 10px !important;

                .bottom-top {
                    display: none !important;
                }
            }

        }
    }

    .f-content-box {
        display: flex;
        height: 348px;
        margin-top: 60px;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;

        @media (max-width:600px) {
            // height: 325px !important;
            margin-bottom: 80px;
        }

        .item-live {
            width: 276px;
            margin-right: 30px;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;

            .top {
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
            }

            .content {
                position: absolute;
                top: 30%;
                left: 50%;
                transform: translateX(-50%);

                &>img {
                    width: 128px;
                    margin-bottom: 10px;
                }

                .jewelry-name {
                    min-width: 250px;
                    font-family: Monaco;
                }
            }

            .bottom {
                position: absolute;
                bottom: 35px;
                left: 50%;
                transform: translateX(-50%);
          
                @media (max-width:600px) {
                    bottom: 25px;
                }

                @media (max-width:420px) {
                    bottom: 20px !important;  
                }

                @media (max-width:380px) {
                    bottom: 5px !important;  
                }

                

                .bottom-top {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    

                    &>img {
                        width: 20px;
                        margin-right: 5px;
                    }
                    > span {
                        font-family: Monaco;
                    }
                }

                .bottom-live {
                    @media (max-width:600px) {
                        margin-bottom: 3px;
                    }
                    margin-top: 10px;
                    font-family: Monaco;
                    
                }
            }
        }

        .item-live.live-bg1 {
            background-image: url('../../assets/img/dt/1.png');
        }

        .item-live.live-bg2 {
            background-image: url('../../assets/img/dt/2.png');
        }

        .item-live.live-bg3 {
            background-image: url('../../assets/img/dt/3.png');
        }

        .item-live.live-bg4 {
            background-image: url('../../assets/img/dt/4.png');
        }

        .item-live.live-bg5 {
            background-image: url('../../assets/img/dt/5.png');
        }

        .item-live.live-bg6 {
            margin-right: 0;
            background-image: url('../../assets/img/dt/6.png');
        }
    }
}



.battlefield {
    text-align: center;

    .img-item {
        height: 120px;

        @media (max-width:600px) {
            height: 70px !important;
            margin-left: -5px;
            margin-top: 40px !important;
        }
    }
}

.content.battlefield {
    width: 100% !important;
    max-width: inherit !important;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    height: calc(100% - 400px);
    position: relative;
    @media (max-width:600px) {
        height: auto;
    }

    >div {
        background-color: #0000005f;
        >div{
            width: 1720px;
            position: relative;
            margin: 0 auto;
            @media (max-width:600px) {
                width: 100%;
            }
        }
    }

    .is-bao-zha {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
        transform: translate(-50%, -70%);
    }

    @media (max-width: 1200px) {
        width: 100%;
    }

    &-explain {
        position: relative;
        padding-left: 20px;
        line-height: 1.4;
        font-size: 14px;

        &::before {
            content: '';
            width: 4px;
            height: 78px;
            background-color: yellow;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &-place {
        &-left {
            width: 720px;
            height: 200px;
            padding: 10px;
        }

        &-righr {
            width: 700px;
        }

    }
}</style>
