import {Message} from "element-ui";
var md5 = require('md5')
/**
 * 生成随机的字符串
 * */
let str = '92Skins.Cn&MessagePush*Key'
// 生成十六问的随机字符串
function randomString(len = 16) {
    //随机值
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678';
    //随机的长度
    var maxPos = $chars.length;
    //暂时定义为空的
    var str = '';
    //循环拼接
    for (var i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
}

// MD5加密
function md5jiami(client_id ){
    //随机字符串
    let randomStr = randomString()
    // 获取时间戳
    var timestamp = Date.parse(new Date())
    //  当前时间的时间戳
    let tiem = timestamp.toString().substr(0, 10)
    let app_id = '92skins.cn'
    let token = client_id + randomStr + tiem + app_id + str
    //md5加密
    let md5Token = md5(token)
    return {
        randomStr,
        tiem,
        md5Token
    }
}


// 拷贝问题
function CopyText({content}) {

    content = typeof content === 'string' ? content : content.toString() // 复制内容，必须字符串，数字需要转换为字符串
    /**
     * H5端的复制逻辑
     */
    // #ifdef H5
    if (!document.queryCommandSupported('copy')) { //为了兼容有些浏览器 queryCommandSupported 的判断
        // 不支持
        Message('浏览器不支持')
    }
    let textarea = document.createElement("textarea")
    textarea.value = content
    textarea.readOnly = "readOnly"
    document.body.appendChild(textarea)
    textarea.select() // 选择对象
    textarea.setSelectionRange(0, content.length) //核心
    let result = document.execCommand("copy") // 执行浏览器复制命令
    if(result){
        Message.success("复制成功~")
    }else{
        Message.success("复制失败，")
    }
    textarea.remove()
    // #endif
}

// 判断是不是电脑端或者手机端
function sUserAgent() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
        return 'phone'
    } else {
        return 'web'
    }
}


// file转base64
const blobToDataURL = (blob,cd) => {
    let reader = new FileReader();
    reader.onload = function (evt) {
        let base64 = evt.target.result;
        cd(base64);
    };
    reader.readAsDataURL(blob);


};
/**
 * url转base64格式
 * url:图片地址
 * 返回 Promise
 * imageUrlBase64(url)
 * 例子：
 * var url = 'https://img.alicdn.com/bao/uploaded/TB1qimQIpXXXXXbXFXXSutbFXXX.jpg';
 * imageUrl2Base64(url).then((base64) => {
 *   console.log('base64**************' + base64)
 *   }).catch(e => {
 *   console.log(e)
 *   })
 * **/
function imageUrlBase64(url) {
    return new Promise((resolve, reject) => {
        if(url) {
            // 创建一个img标签 new一个
            let image = new Image();
            //解决跨域报错，必须写在赋值给image.src的前面，否则偶尔报错，也可以这样写image.setAttribute('crossOrigin', 'anonymous');
            image.crossOrigin = 'anonymous';
            // 把图片地址给到这个标签
            image.src = url;
            //等待图片加载完成，转换图片为base64，异步
            image.onload = function() {
                // 创建画布
                let canvas = document.createElement('canvas');
                // 定义宽高
                canvas.width = image.width;
                canvas.height = image.height;
                let ctx = canvas.getContext('2d');
                /*
                    img	规定要使用的图像、画布或视频。
                    sx	可选。开始剪切的 x 坐标位置。
                    sy	可选。开始剪切的 y 坐标位置。
                    swidth	可选。被剪切图像的宽度。
                    sheight	可选。被剪切图像的高度。
                    x	在画布上放置图像的 x 坐标位置。
                    y	在画布上放置图像的 y 坐标位置。
                    width	可选。要使用的图像的宽度。（伸展或缩小图像）
                    height	可选。要使用的图像的高度。（伸展或缩小图像）
                */
                ctx.drawImage(image, 0, 0, image.width, image.height);
                // 截取是什么格式的 jpg，png......
                var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();
                // base 前缀
                let base64 = canvas.toDataURL('image/' + ext);
                // 返回base64
                resolve(base64);
            }
        }else {
            reject('传入图片地址')
        }
    });
}
/**
 * base64转file
 * dataURLtoFile(dataurl, filename)
 * dataurl：base64格式
 * filename：名称
 * 例子：
 * let fild =  dataURLtoFile(base64,'四叶草')
 * console.log(fild)
 * */
function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}


function AppLimit() {
    return ![
        '',
        'upcsgo.xinghouse.cn',
        'upcsgo.rzxiyou.cn',
        'upcsgo.jktfc.com',
        'upcsgo.jmpjws.cn',
        'upcsgo.wine-1.com',
        'upcsgo.jmzsfy.top'
    ].includes(window.location.host)
}

export default {
    md5jiami,
    CopyText,
    sUserAgent,
    blobToDataURL,
    imageUrlBase64,
    dataURLtoFile,
    AppLimit
}
