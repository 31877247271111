import {request} from "@/api/index";

function roll_open(data){
    return request({
        url: `/roll_sign/signIn`,
        method:'post',
        data
    })
}
function roll_index(){
    return request({
        url: `/roll_sign/index`,
        method:'get'
    })
}
function luckStar_open(data){
    return request({
        url: `/luckStar/open`,
        method:'post',
        data
    })
}

function luckStar_list(){
    return request({
        url: `/luckStar/list`,
        method:'get',
    })
}

function luckStartList(page = 1){
    return request({
        url: `/luckStar/luckStartList?page=${page}`,
        method:'get'
    })
}


export default {
    roll_index,
    roll_open,
    luckStar_open,
    luckStar_list,
    luckStartList,
}
