<template>
    <div class="content">
        <!-- title -->
        <div class="h3 text-white roll_title font-weight-bold text-center my-2">
            <div> ROLL
                <!-- <img :height="sUserAgent === 'web' ? '85px' : '45px'" src="../../assets/img/ADT/roll/roll.png" alt=""> -->
            </div>

            <div class="cursor-pointer text-center color-yellow" style="font-size: 16px;margin: 20px 0;margin-top: 0;"
                @click="playingMethod = true">
                <img style="width: 17px;height:17px;vertical-align: middle;margin-right: 4px;"
                    src="@/assets/img/ADT/zm/question-mark.png" alt="">
                <span>游戏规则</span>
            </div>
        </div>

        <!-- 显示分级 -->
        <div class="screening">
            <div class="type-btn _a"
                :class="[tabPosition === 'official' ? 'active' : '', sUserAgent === 'web' ? '' : 'ts-isPhone']"
                @click="tabPosition = 'official'">
                官方
            </div>
            <div class="type-btn _a center-type"
                :class="[tabPosition === 'anchor' ? 'active' : '', sUserAgent === 'web' ? '' : 'ts-isPhone']"
                @click="tabPosition = 'anchor'">
                主播 </div>
            <div class="type-btn _a"
                :class="[tabPosition === 'participant' ? 'active' : '', sUserAgent === 'web' ? '' : 'ts-isPhone']"
                @click="tabPosition = 'participant'">
                我参与的 </div>
        </div>


        <div class="roll-tabs d-flex align-items-center my-4 my-lg-3 justify-content-center"
            v-if="tabPosition !== 'participant'">
            <div class="px-3 py-2 mr-2 cursor-pointer" @click="navRoll('underway')"
                :class="navActive == 'underway' ? 'active-nav' : ''">
                进行中
            </div>
            <div class="px-3 py-2 mr-2 cursor-pointer" @click="navRoll('finished')"
                :class="navActive == 'finished' ? 'active-nav' : ''">已结束</div>
            <!-- <div class="px-3 py-2 mr-2 cursor-pointer" @click="navRoll('participate')"
                 :class="navActive == 'participate'?'active-nav':''">
                我参与的
            </div> -->
        </div>

        <div class="rooms">
            <!-- 进行中  已结束 -->
            <div class="roll-card _a m-none" v-for="item in rollList" :key="item.id">
                <router-link :to="'/active/rollHome/' + item.id + '?roll=1'">
                    <div class="new-roll card-head flex justify-between">
                        <div class="info-bg">
                            <img src="../../assets/img/ADT/roll/roll-sculpture.png" alt="">
                        </div>
                        <div class="num">
                            <span class="num common-hidden">
                                <img style="width: 15px;height:15px;" src="../../assets/img/ADT/roll/personnel.png" alt="">
                                {{ item.join_number }}/{{ item.people_number }}</span>

                        </div>
                        <div class="info-right">
                            <p :title="item.name" class="title common-hidden">
                                {{ item.name }}
                            </p>
                            <p class="time common-hidden">
                                开奖时间:{{ item.end_time }}
                            </p>
                        </div>
                    </div>
                    <!-- <div class="best">
                        <img :src="item.awards ? item.awards[0].box_record.cover : item.box_records[0].cover" alt="">
                    </div> -->
                    <ul class="less">
                        <li class="less-li rarity6"><img
                                :src="item.awards ? item.awards[1].box_record.cover : item.box_records[1].cover" alt="">
                        </li>
                        <li class="less-li rarity120"><img
                                :src="item.awards ? item.awards[2].box_record.cover : item.box_records[2].cover" alt="">
                        </li>
                        <li class="less-li rarity6"><img
                                :src="item.awards ? item.awards[3].box_record.cover : item.box_records[3].cover" alt="">
                        </li>
                    </ul>
                    <div class="info">
                        <div class="text-overflow-2" style="margin-bottom:10px;color:#ffc67b">{{ item.describe }}</div>
                        <div class="info-both">
                            <div style="font-size: 12px;color:#fff;" class="txt">总价值：</div>
                            <div class="total">
                                <div class="cp cfe4760" style="height: 20px; font-size: 18px;">
                                    <money />
                                    <span style="font-size: 16px;color: #F9C545;padding-left: 2px; margin-top: 0px;"
                                        class="cp-num">{{ item.award_bean
                                        }}</span>
                                </div>
                            </div>
                            <div style="font-size: 12px;color:#fff;" class="txt">礼品：</div>
                            <div title="100" style="font-size: 16px;color: #F9C545;" class="num common-hidden">{{
                                item.skins_number }}</div>
                        </div>
                        <div class="info-both">
                            <!-- <div class="txt">礼品：</div>
                            <div title="100" class="num common-hidden">{{ item.skins_number }}</div>
                            <div class="line"></div> -->
                            <!-- <div class="txt">玩家：</div>
                            <div class="num common-hidden">{{ item.join_number }}/{{ item.people_number }}</div> -->
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="roll-card-m _a pc-none" v-for="item in rollList" :key="'a' + item.id">
                <div class="new-roll card-head flex justify-between">
                    <div class="flex">
                        <div class="info-bg">
                            <img class="roll-logo" src="../../assets/img/ADT/roll/roll-sculpture.png" alt="">
                        </div>
                        <div class="num">
                            <span class="num common-hidden">
                                <img style="width: 15px;height:15px;" src="../../assets/img/ADT/roll/personnel.png" alt="">
                                {{ item.join_number }}/{{ item.people_number }}</span>

                        </div>
                        <div class="info-right">
                            <p :title="item.name" class="title common-hidden">
                                {{ item.name }}
                            </p>
                            <p class="time common-hidden">
                                开奖时间:{{ item.end_time }}
                            </p>
                        </div>
                    </div>
                    <!-- <div class="top-people">
                        {{ item.join_number }}
                    </div> -->
                </div>
                <ul class="less">
                    <li class="less-li rarity6">
                        <img :src="item.awards ? item.awards[0].box_record.cover : item.box_records[0].cover" alt="">
                        <div class="cp price cffcb77 size20">
                            <money style="width: 15px;height:15px;vertical-align: bottom;margin:0;" />
                            <span class="cp_num_1">{{ item.awards ? item.awards[0].bean : item.box_records[0].bean }}</span>
                        </div>
                    </li>
                    <li class="less-li rarity6">
                        <img :src="item.awards ? item.awards[1].box_record.cover : item.box_records[1].cover" alt="">
                        <div class="cp price cffcb77 size20">
                            <money style="width: 15px;height:15px;vertical-align: bottom;margin:0;" />
                            <span class="cp_num_1">{{ item.awards ? item.awards[1].bean : item.box_records[1].bean }}</span>
                        </div>
                    </li>
                    <li class="less-li rarity120">
                        <img :src="item.awards ? item.awards[2].box_record.cover : item.box_records[2].cover" alt="">
                        <div class="cp price cffcb77 size20">
                            <money style="width: 15px;height:15px;vertical-align: bottom;margin:0;" />
                            <span class="cp_num_1">{{ item.awards ? item.awards[2].bean : item.box_records[2].bean }}</span>
                        </div>
                    </li>
                    <li class="less-li rarity6">
                        <img :src="item.awards ? item.awards[3].box_record.cover : item.box_records[3].cover" alt="">
                        <div class="cp price cffcb77 size20">
                            <money style="width: 15px;height:15px;vertical-align: bottom;margin:0;" />
                            <span class="cp_num_1">{{ item.awards ? item.awards[3].bean : item.box_records[3].bean }}</span>
                        </div>
                    </li>
                </ul>
                
                <div class="total">
                    
                    <div class="total-price">
                        <div class="total-txt">总价值：</div>
                        <div class="total">
                            <div class="cp size26">
                                <money style="width: 15px;height:15px;vertical-align: bottom;margin:0;" />
                                <span class="cp-num" style="padding-left: 2px; margin-top: 0px;">{{ item.award_bean
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="skins_number">
                        <span class="total-txt">饰品：</span>
                        <span class="total-skin-num">{{ item.skins_number }}</span>
                    </div>
                </div>
                <div>
                    <p class="time text-overflow-2 common-hidden condition">参与条件:&nbsp;&nbsp;{{
                        item.describe }}
                    </p>
                </div>
                <router-link :to="'/active/rollHome/' + item.id + '?roll=1'">
                    <div class="btn common-btn">
                        加入房间
                    </div>
                </router-link>
            </div>
            <el-pagination background layout="prev, pager, next" :hide-on-single-page="total < 19" :total="total"
                :page-size="pageSize" @current-change="currentChange">
            </el-pagination>
        </div>

        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg notice">
                <div class="text-white p-md-4 p-2" style="line-height: 2.0">
                    <!-- 
                    <p>1.选择加入roll房间，根据房间规则可以加入房间。</p>
                    <p>2.达到加入条件后，即可加入房间。</p>
                    <p>2.加入房间，到达房间开奖日期后即可随机分配房间内的饰品。</p> -->
                    <p class="text-center">ROLL房规则</p>
                    1.ROLL房只限于对注册玩家发放福利，并作为粉丝回馈活动使用。部分ROLL房需要填写主播邀请码或存在充值门槛限制，请关注我们的页面介绍!<br>
                    2.ROLL房参加：选择相应ROLL房，点击“我要加入”，进入房间且满足房间要求后，点击“加入”即可参加活动！<br>
                    3.ROLL房结果：在已结束的ROLL房界面，点击“查看结果”，获奖名单可查看本期ROLL房所有获奖用户及其获取的相应饰品。若您在本期ROLL房中获取了奖品，则会在名单第一位高亮显示，方便您查看结果！<br>
                    4.请仔细查阅《用户协议》及相应条款，保持理性消费!
                    <!-- <p style="color:red">温馨提示：请您在游戏前仔细阅读《用户协议》及相应条款，保持理性消费！祝您游戏愉快！</p> -->
                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>

            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
    </div>
</template>

<script>

import $api from '@/api/rollHome/index'
import sUserAgent from '@/assets/js/common.js'
export default {
    name: "index",
    components: {

    },
    data() {
        return {
            tabPosition: 'official',
            total: 5,
            pageSize: 0,
            rollList: [],
            status: 0,
            navActive: 'underway',
            dataFUQ: 0,
            playingMethod: false
        }
    },
    computed: {
        dateMYD() {
            return this.dataFUQ * 1000
        },
        sUserAgent() {
            return sUserAgent.sUserAgent();
        },
    },
    created() {
    },
    mounted() {
        this.init()
    },
    watch: {
        tabPosition(val) {
            this.status = 0
            this.navActive = 'underway'
            if (val === 'official') {
                this.getRoomsList(1)
            } else if (val === 'anchor') {
                this.getRoomsList(1)
            } else if (val === 'participant') {
                this.navRoll('participate')
            }

        }
    },
    methods: {
        init() {
            this.getRoomsList()

        },
        // 点击获取官房主播全部roll房间
        rollType(type) {
            console.log(type)
            this.getRoomsList(1, type)
        },
        // 获取数据
        async getRoomsList(page = 1) {
            let type = this.tabPosition === 'official' ? 0 : 1
            try {
                this.rollList = []
                let { data, code, timestamp } = await $api.getRoomsList(page, this.status, type)
                if (code == 500) return
                this.rollList = data.data
                this.dataFUQ = timestamp
                this.total = data.total
                this.pageSize = data.per_page
            } catch (e) {
                console.log(e)
            }
        },
        // 点击翻页
        currentChange(val) {
            if (this.navActive == 'participate') {
                this.myGetRooms(val)
            } else {
                this.getRoomsList(val)
            }

        },
        // 进行中，已结束，我参与的
        navRoll(state) {
            this.navActive = state
            if (state == 'underway') {
                this.rollList = []
                this.status = 0
                this.getRoomsList(1)

            } else if (state == 'finished') {
                this.rollList = []
                this.status = 1
                this.getRoomsList(1)

            } else if (state == 'participate') {
                this.status = 2
                this.myGetRooms(1)
            }
        },
        myGetRooms(val = 1) {
            $api.getUserRooms(val).then(res => {
                this.rollList = []
                this.rollList = res.data.data
                this.total = res.data.total
                this.pageSize = res.data.per_page
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import "./collNew.scss";

.new-roll.card-head {
    background-image: url(../../assets/img/ADT/roll/roll-top-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

@media (max-width: 600px) {
    .new-roll.card-head {
        background-position: top;
    }

    .roll-tabs {
        &>div {
            font-size: 14px !important;
        }

        .active-nav {
            font-size: 14px !important;
        }
    }
}

.roll-tabs {
    &>div {
        font-size: 20px;
        font-weight: 400;
        color: rgba(255, 255, 255, .6);
        padding-bottom: 15px !important;
    }

    .active-nav {
        font-size: 20px;
        font-weight: bold;
        color: #E9B943;
        border-bottom: 3px solid #F9C545;
    }
}


.cp-num {
    font-family: MicrosoftYaHei-Bold;
    color:#F9C545;
    font-weight: bold;
}
.cp_num_1 {
    color: #ACBCCD;
    font-family: MicrosoftYaHei;
}


.screening {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: auto;

    >div {
        display: inline-block;
        width: 10rem;
        text-align: center;
        color: #fffffe;
        // background: #537189;
        padding: 0 2rem;
        margin-right: 10px;

        @media (max-width: 1200px) {
            width: 6rem;
        }
    }

    >div:first-of-type {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 11% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 11% 100%);
    }

    >div:last-child {
        -webkit-clip-path: polygon(0 0, 100% 0, 89% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 89% 100%, 0 100%);
    }

    .type-btn {
        height: 65px;
        line-height: 40px;
        border-radius: 0;
        font-size: 1rem;
        // border: 0 solid #000;
        color: #fff;
        opacity: .5;
        // background: #22263f;
        font-family: PingFang SC;
        font-weight: 700;
        padding: 10px 0;
    }

    .type-btn.ts-isPhone {
        height: 45px !important;
        line-height: 25px;
        font-size: 14px;
    }

    .type-btn:nth-child(1) {
        background-image: url(../../assets/img/ADT/roll/gf-d.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
    }

    .type-btn:nth-child(2) {
        background-image: url(../../assets/img/ADT/roll/zb-d.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
    }

    .type-btn:nth-child(3) {
        background-image: url(../../assets/img/ADT/roll/wcyd-d.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
    }

    .type-btn:nth-child(1):hover {
        // background-image: linear-gradient(180deg, #b89256, #db802c);
        background-image: url(../../assets/img/ADT/roll/gf-active.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        background-blend-mode: normal, normal;
        opacity: 1;
        color: #fff;
    }

    .type-btn:nth-child(1).active {
        // background-image: linear-gradient(180deg, #b89256, #db802c);
        background-image: url(../../assets/img/ADT/roll/gf-active.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        background-blend-mode: normal, normal;
        opacity: 1;
        color: #fff;
    }

    .type-btn:nth-child(2):hover {
        // background-image: linear-gradient(180deg, #b89256, #db802c);
        background-image: url(../../assets/img/ADT/roll/zb-active.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        background-blend-mode: normal, normal;
        opacity: 1;
        color: #fff;
    }

    .type-btn:nth-child(2).active {
        // background-image: linear-gradient(180deg, #b89256, #db802c);
        background-image: url(../../assets/img/ADT/roll/zb-active.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        background-blend-mode: normal, normal;
        opacity: 1;
        color: #fff;
    }

    .type-btn:nth-child(3):hover {
        // background-image: linear-gradient(180deg, #b89256, #db802c);
        background-image: url(../../assets/img/ADT/roll/wcyd-active.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        background-blend-mode: normal, normal;
        opacity: 1;
        color: #fff;
    }

    .type-btn:nth-child(3).active {
        // background-image: linear-gradient(180deg, #b89256, #db802c);
        background-image: url(../../assets/img/ADT/roll/wcyd-active.png);
        background-repeat: no-repeat;
        background-size: 90% 100%;
        background-position: center;
        background-blend-mode: normal, normal;
        opacity: 1;
        color: #fff;
    }
}

.roll_type {
    &:hover {
        color: var(--main-blue);
    }
}

.ROLL_pc {
    @media (max-width: 1200px) {
        display: none;
    }
}

.ROLL_phone {
    display: none;

    @media (max-width: 1200px) {
        display: block;
    }
}


.ROLL_user {
    border-radius: 10px;
    overflow: hidden;
    background-color: #090827;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, .3);

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
        background: linear-gradient(144deg, rgba(57, 85, 232, 0) -11%, rgba(57, 85, 232, 0.6) 111%);
    }
}

.content {
    width: 90%;
    margin: 0 auto;

    @media (max-width: 1366px) {
        width: 95%;
    }

    .roll_title {
        height: 100px;
        position: relative;
        >div {
            position: absolute;
            &:nth-child(1) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 40px;
                @media (max-width: 600px) {
                    font-size: 24px;
                }
            }
            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
                left: 70%;
            }
        }
        @media (max-width: 600px) {
            height: 60px;
        }
    }
}

.rooms {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 590px;

    &-item {
        @media (max-width: 1000px) {
            width: 100%;
        }

        & .room {
            @media (max-width: 1000px) {
                width: auto;
            }

            & .name {
                @media (max-width: 1000px) {
                    -webkit-line-clamp: 1 !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .rooms {
        width: 100%;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1440px) {
    .rooms {
        width: 790px;
    }
}

@media screen and (min-width: 720px) and (max-width: 1080px) {
    .rooms {
        width: 760px;
    }
}

@media screen and (min-width: 360px) and (max-width: 720px) {
    .rooms {
        width: 100%;
    }
}

.room {
    width: 340px;
    height: 360px;
    padding: 10px 20px;
    margin: 15px;
    position: relative;
    box-shadow: 1px 1px 3px #111;
    background-color: hsla(0, 0%, 100%, .03);
    background-size: contain;
    color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.5;
}

.room .head {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
}

.room .head .avatar {
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room .head .title {
    width: calc(100% - 85px);
    height: 100px;
    padding-left: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 90;
    text-align: left;
}

.room .head .title .name {
    font-size: 18px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.room .head .title .desc {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #b0b0b0;
    letter-spacing: 1px;
    position: absolute;
    top: 20%;
    padding: 0 10px 0 0px;
}

.room .head .title .desc .ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.room .head .title .tooltip {
    z-index: 200;
    display: none;
    position: absolute;
    top: 40%;
    background: #fff679;
    border-radius: 2px;
    color: #000;
    font-size: 12px;
    padding: 10px;
    transition: all .3s ease;
}

.room .let {
    color: var(--main-blue);
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top: 14%;
}

.room .gifts {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.room .gifts .item {
    width: 80px;
    height: 70px;
    position: relative;
}

.room .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Oxanium, sans-serif;
}

element.style {}

.room .content-item {
    width: 80px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.room .gifts .item>img {
    width: 90%;
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 80;
}

.room .content-item .num {
    font-size: 18px;
    font-weight: 600;
    color: var(--main-blue);
    overflow-wrap: normal;
}

.room .content-item .unit {
    font-size: 12px;
}


.room .join:hover {
    background: linear-gradient(225deg, rgb(66 130 82), rgb(59 115 74));
}

.room .join {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background: hsla(0, 0%, 100%, .05);
}

.room .join .btn {
    color: #fff;
    width: 100%;
}

.horn {
    background: linear-gradient(var(--main-blue), var(--main-blue)) left top, linear-gradient(var(--main-blue), var(--main-blue)) left top, linear-gradient(var(--main-blue), var(--main-blue)) right bottom, linear-gradient(var(--main-blue), var(--main-blue)) right bottom;
    background-repeat: no-repeat;
    background-size: 1px 20px, 20px 1px;
}

.notice {
    // left: 50% !important;
}
</style>
