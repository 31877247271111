<template>
    <div @click="$router.push({ name: 'timeBox' })" class="_a timeBox"
                  >
        <div>
            <div>{{ dateInfo.hours }}</div><div>时</div>
        </div>
        <div>
            <div>{{ dateInfo.minutes }}</div><div>分</div>
        </div>
        <div>
            <div>{{ dateInfo.seconds }}</div><div>秒</div>
        </div>
    </div>
  
  </template>
  
  <script>
    import $api from '@/api/time'
      export default {
          name: "timeBoxTitle",
          data() {
              return {
                dateInfo: {
                    days: "00",
                    hours: "00",
                    minutes: "00",
                    seconds: "00",
                },
                timeBoxList: {
                    end_time: 0,
                },
              }
          },
          props:{
          },
          created() {
            this.getTimeBoxList()
          },
          methods: {
            getTimelimit() {
                let remaining = new Date(this.timeBoxList.end_time * 1000).getTime() - new Date().getTime()
                this.dateInfo = this.remainingTime(remaining)
                if (remaining && remaining > 0) {
                    const countdown = setInterval(() => {
                        if (remaining < 1000) {
                            this.dateInfo = {
                                ays: "00",
                                hours: "00",
                                minutes: "00",
                                seconds: "00",
                            }
                            clearTimeout(countdown)
                            this.getTimeBoxList()
                            return;
                        } else {
                            remaining -= 1000;
                            this.dateInfo = this.remainingTime(remaining);
                        }
                    }, 1000);
                } else {
                    setTimeout(() => {
                        this.getTimeBoxList()
                    }, 2000)
                }
            },
            async getTimeBoxList() {
                let { code, data } = await $api.getTimeBoxList()
                if (code == 200) {
                    if (data[0]) {
                        this.timeBoxList = data[0]
                    }
                    this.getTimelimit()
                }
            },
            remainingTime(remaining) {
                const lasttime = {
                    days: Math.floor(remaining / (1000 * 60 * 60 * 24)),
                    hours: Math.floor(
                        (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    ),
                    minutes: Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((remaining % (1000 * 60)) / 1000),
                }
                for (const key in lasttime) {
                    if (lasttime[key] < 10 && lasttime[key] >= 0) {
                        lasttime[key] = '0' + lasttime[key]
                    }
                    if (lasttime[key] < 0) {
                        lasttime[key] = '00'
                    }
                }
                
                return lasttime
            },
          }
      }
  </script>
  
  <style scoped lang="scss">
.timeBox {
    background-image: url(../../assets/img/timeBox/time_box_nav.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 84%;
    width: 400px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width:600px) {
        background-image: url(../../assets/img/timeBox/phone/time_box_nav.webp);
        background-size: 100%;
        width: 90%;
        margin: 0 auto;
    }
    
    >div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;
        >div:nth-child(1) {
            font-family: MicrosoftYaHei-Bold;
            font-weight: bold;
        }
        width: 40px;
        text-align: center;
        &:nth-child(1) {
            margin-left: 55px;
        }
        @media (max-width:600px) {
            width: 13%;
            &:nth-child(1) {
                margin-left: 15%;
            }
            font-size: 14px;
        }
    }
    }
  </style>
  