<template>
    <div class="vip-fl">
        <div class="taskcenter-inform">
            <img style="width: 50px;padding: 0 15px;" src="../../assets/img/ADT/event-hall/laba.png" alt="">
            <div style="width:100%">
                <el-carousel height="30px" :interval="2000" direction="vertical" indicator-position="none">
                    <el-carousel-item v-for="(item, index) in inform" :key="index">
                        <p class="medium">
                            {{ item.timeTips }}
                            <span class="text-orge">{{ item.name }}</span>
                            完成
                            <span class="text-orge">{{ item.task }}</span>
                            获得
                            <span class="text-orge">{{ item.award }}</span>
                            !
                        </p>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="user-info">
            <div class="sideBar-user">
                <div class="img" @click="upImgVisible = true">
                    <img class="border1" src="@/assets/img/ADT/user/yuan.png" />
                    <img class="avatar" :src="user_info.avatar" />
                </div>
                <div class="info">
                    <span style="color: #fff;font-size: 14px;">{{ `${user_info.name}，您的当前会员等级` }}</span>
                    <img style="height: 18px;vertical-align: middle;"
                        :src="require(`@/assets/img/ADT/common/lv-max/v${user_info.vip_level}.png`)" alt="">
                    <span style="color: #fff;font-size: 14px;">{{ `，充值加成` }}</span>
                    <span style="color: rgba(249, 197, 69, 1);font-size: 14px;"> {{ czjc?czjc:0 }}%</span>
                    <div class="press" style="margin-top: 15px;">
                        <img v-if="user_info.vip_level"
                            :src="require(`@/assets/img/ADT/common/V${user_info.vip_level}@2x.png`)" />
                        <span style="width:50%"></span>
                    </div>
                </div>
            </div>

        </div>
        <div class="fl-table">

            <table cellspacing="0" cellpadding="0" style="width: 100%;">
                <thead class="table-thead">
                    <tr class="text-danger" style="font-size: 12px">
                        <th colspan="1" rowspan="1">
                            <div class="cell">VIP等级</div>
                        </th>
                        <th colspan="1" rowspan="1">
                            <div class="cell">充值金额</div>
                        </th>
                        <th colspan="1" rowspan="1">
                            <div class="cell">升级红包</div>
                        </th>
                        <th colspan="1" rowspan="1">
                            <div class="cell">充值加成</div>
                        </th>

                        <th colspan="1" rowspan="1">
                            <div class="cell">红包领取</div>
                        </th>
                    </tr>
                </thead>
                <tr class="table-tr" style="" v-for="(item, index) of lvData" :key="index">
                    <td rowspan="1" colspan="1">
                        <img v-if="item.level" style="height: 20px;"
                            :src="require(`@/assets/img/ADT/common/lv-max/v${item.level}.png`)" alt="">
                    </td>
                    <td rowspan="1" colspan="1">
                        <div class="cell">
                            {{ item.threshold }}
                        </div>
                    </td>
                    <td rowspan="1" colspan="1">

                        <div class="cell">
                            <money />
                            <span style="vertical-align: top;">{{ item.packet }}</span>
                        </div>
                    </td>
                    <td rowspan="1" colspan="1">
                        <div class="cell">{{ item.rebate }}%</div>
                    </td>
                    <td rowspan="1" colspan="1">
                        <div class="cell">
                            <div class="nth">
                                <div class="dt-draw-box" v-if="item.vip_reward">
                                    <!-- <img v-if="item?.vip_reward?.status" src="../../assets/img/news/ok.png" alt="" /> -->
                                    <span v-if="item?.vip_reward?.status" style="color:rgba(0, 185, 212, 1)">已发放</span>
                                    <div v-else class="dt-draw" @click="getViprewards(item.id)">
                                        领取福利
                                    </div>
                                </div>
                                <div v-else>
                                    <!-- <img v-if="item.level <= vipLevelReward" src="../../assets/img/news/ok.png" alt="" /> -->
                                    <span style="color:rgba(0, 185, 212, 1)" v-if="item.level <= vipLevelReward">已发放</span>
                                    <span v-else style="color:rgba(247, 196, 69, 1)">未满足</span>
                                    <!-- <img v-else src="../../assets/img/news/dt/no.png" alt="" /> -->
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>

        </div>

    </div>
</template>
<script>
import $api from '@/api/activity.js'
import $api2 from '@/api/vip/index.js'
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['user_info', 'web_info'])

    },
    data() {
        return {
            czjc: null,
            vipLevelReward: 0,// 0 - 12 vip等级
            FKamount: "", // 已领取金额
            FKdate: "", // 已领取日期
            isVerify: 0, // 0未认证 1已认证
            inform: [
                {
                    timeTips: '12分钟之前',   //时间
                    name: "小李**",   //人名
                    task: '对战消耗任务',   //目标
                    award: '保底20任务目标'   //奖励
                },
                { timeTips: '2分钟之前', name: "猪猪**", task: '盲盒消耗任务', award: '保底120任务目标' },
                { timeTips: '32分钟之前', name: "不知名**", task: '火海', award: '保底120任务目标' },
            ],
            lvData: [],
        };
    },
    watch: {
    },
    created() {
        this.informationList()
        this.getVipList()
    },
    methods: {
        //会员升级奖励
        getViprewards(id) {
            $api2.getViprewards({ vip_id: id }).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.$notify.closeAll();
                    this.$notify({
                        message: res.message,
                        position: "top-left",
                    });
                    this.getVipList(); // 重新获取列表数据
                }
            });
        },
        // vip福利
        getVipList() {
            $api2.getVipList().then(res => {
                if (res.code === 200) {
                    this.lvData = res.data?.list.filter((item,index) => index<=9)
                    this.vipLevelReward = res.data.vip_level_reward;
                    this.lvData.map((item) => {
                        if (item.level === this.vipLevelReward) {
                            console.log("item", item.rebate);
                            this.czjc = item.rebate
                        }
                    })
                }
            })
        },
        // 消息列表
        informationList() {
            $api.remindList().then(res => {
                if (res.code === 200) {
                    this.inform = res.data
                }
            })
        },

    }
}
</script>
<style scoped lang="scss">
.dt-draw-box {
  display: flex;
  align-content: center;
  justify-content: center;
  & > img {
    width: 24px;
  }
  .dt-draw {
    // margin-left: 20px;
    color: rgb(247, 196, 69);
    cursor: pointer;
    // border: 1px solid #ffcb77;
    // border-radius: 20px;
    // padding: 5px 10px;
    font-size: 16px;
  }
}
.vip-fl {
    .taskcenter-inform {
        margin-top: 10px;
        width: 100%;
        height: 35px;
        background-color: rgb(0, 0, 0, 0.3);
        border-radius: 6px;
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 18px;

        .medium {
            width: 100%;
            color: #848492;
            font-size: 14px;
            ;
            line-height: 30px !important;
        }
    }

    .user-info {
        margin: 25px 0 15px 0;

        >.sideBar-user {
            // padding: 30px 20px;
            display: flex;
            align-items: center;

            @media (max-width:600px) {
                padding: 10px 5px;
                margin-bottom: 20px;
            }

            >.img {
                margin-right: 14px;
                position: relative;
                width: 60px;
                height: 60px;

                >img {
                    width: 70px;
                    border-radius: 50%;
                    position: absolute;
                    z-index: 2;
                    left: 10px;
                    top: 10px;
                }

                >.border1 {
                    position: absolute;
                    left: 0px;
                    border: none;
                    top: 0px;
                    width: 60px;
                    height: 60px;
                    animation: warn1 5s linear infinite;
                }

                >.avatar {
                    width: 39px;
                }

                @keyframes warn1 {
                    0% {
                        transform: rotate(0deg);
                    }

                    25% {
                        transform: rotate(90deg);
                    }

                    50% {
                        transform: rotate(180deg);
                    }

                    75% {
                        transform: rotate(270deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }

            >.info {
                >h2 {
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 6px;

                    @media (max-width:600px) {
                        color: #01B5CF;
                    }
                }

                >p {
                    font-family: Monaco;
                    color: #ccc;
                    margin-bottom: 10px;
                    font-weight: 300;
                    font-size: 14px;
                }

                >.press {
                    background-color: #D1D3DE;
                    border-radius: 20px;
                    width: 200px;
                    height: 8px;
                    position: relative;

                    >img {
                        height: 26px;
                        position: absolute;
                        top: -8px;
                        left: -4px;
                        z-index: 2;
                    }

                    >span {
                        position: absolute;
                        width: 30%;
                        height: 8px;
                        background-color: #C5A473;
                        border-radius: 20px;
                    }
                }
            }
        }

    }

    .fl-table {
        border: 1px solid #1B3B53;
        border-radius: 6px;
        text-align: center;
        height: 300px;
        overflow-y: auto;

        .table-thead {
            height: 44px;
            line-height: 44px;
            background: #153044;

            .cell {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }


        }

        .table-tr {
            height: 44px;
            line-height: 44px;
            background: #102535;
            border-bottom: 2px solid RGBA(23, 52, 75, 1);
        }
    }
}
</style>