import Cookies from 'js-cookie'
const TokenKey = 'token'
const Lang = 'lang'
const WuXing = 'five_stars_num'
// 获取token
export function getToken() {
  /**
   * */
  return Cookies.get(TokenKey)
}
export function getExpires() {
  /**
   * */
  return Cookies.get('time')
}

export function setToken(token) {
  /**
   * */
  return Cookies.set(TokenKey, token,{ expires: 2 })
}
export function setWebInfo(info) {

  /**
   * */

  return Cookies.set('setWebInfo', info)
}
export function getWebInfo() {

  /**
   * */

  return Cookies.get('setWebInfo')
}

export function setExpires(expires_in) {
  var timestamp = Date.parse(new Date())
  expires_in = timestamp/1000 + expires_in

  /**
   * */
  return Cookies.set('time', expires_in)
}

// 删除token
export  function removeToken() {
  Cookies.remove(TokenKey)
  return 'removeToken'
}

// 获取Lang
export  function getLang() {
  return Cookies.get(Lang)
}
// 写入Lang
export function setLang(item) {
  return Cookies.set(Lang, item)
}
// 删除Lang
export  function removeLang() {
  return Cookies.remove(Lang)
}

export  function getOpenWuXing() {
  return Cookies.get(WuXing)
}
// 写入Lang
export function setOpenWuXing(item) {
  return Cookies.set(WuXing, item)
}
// 删除Lang
export  function removeOpenWuXing() {
  return Cookies.remove(WuXing)
}






