
import {request} from "../index";

function getVipList(){
  return request({
    url: `/vip/lists`,
    method:'get'
  })
}

// /user/id_card/verify  //身份认证 type:post params: {name / idcard}
// /vip/vipbrithday  //会员生日奖励 type:post params: {}
// /vip/viprewards //会员升级奖励 type:post params: {vip_id }

function getVipVerify(data){
  return request({
    url: `/user/id_card/verify`,
    method:'post',
    data:data,
  })
}

function getVipbrithday(){
  return request({
    url: `/vip/vipbrithday`,
    method:'post'
  })
}

function getViprewards(data){
  return request({
    url: `/vip/viprewards`,
    method:'post',
    data:data,
  })
}


export default {
  getVipList,
  getVipVerify,
  getVipbrithday,
  getViprewards
}
