<template>
    <span class="money" :style="`color:${color};font-size:${fontSize};width:${width};height:${width};'`" >
        <img v-if="integral" src="../../assets/img/ADT/home/up.png" style="width: 100%;height: 100%;" alt="">
        <img v-else src="../../assets/img/JF.png" style="width: 100%;height: 100%" alt="">
    </span>
</template>

<script>
    export default {
        name: "money",
        data() {
            return {}
        },
        props:{
            color:{
                type:String,
                default:'var(--main-yellow)'
            },
            width:{
                type: String,
                default: '20px'
            },
            fontSize:{
                type: String,
                default: '18px'
            },
            integral:{
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style lang="scss" scoped>
.money{
        margin-right: 5px;
        margin-top: 2px;
        display: inline-block;
        @media (max-width:1300px) {
            width: 20px;
            height: 20px;
        }
    }
</style>
