<template>
    <div class="footer-main">
        <div class="w1720 flex  m-none">
            <div class="margin-top-xl">
                <img class="foot-img" src="../assets/img/ADT/home/footer-logo.png" />
                <div class="text-withe">

                    <div style="font-size:14px;line-height:31px;font-weight: 400;color:#FFFFFF !important">
                        盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                    <div style="font-size:14px;line-height:31px;font-weight: 400;color:#989898 !important">版权所有| Copyright@
                        2022-2023 UPcsgo Tech Ltd</div>
                </div>
            </div>
            <div v-if="domain" class="flex main">
                <div class="margin-left-xl">
                    <div><a target="_blank" href="/active/help/2" class=" mb-2">用户协议</a></div>
                    <div><a target="_blank" href="/active/help/3" class=" mb-2">隐私条款</a></div>
                    <div><a target="_blank" href="/active/help/10" class=" mb-2">合规声明</a></div>
                    <div><a target="_blank" href="/active/help/4" class=" mb-2">协议与条款</a></div>
                    <div><a target="_blank" href="/active/help/11" class=" mb-2">反洗钱协议</a></div>
                    <div><a target="_blank" href="/help" class="">帮助中心</a></div>
                </div>

                <!-- <div class="margin-left-xl">
                    <div><a target="_blank" href="/active/help/2" class=" mb-2">手机app下载</a></div>
                    <div><a target="_blank" href="/active/help/3" class=" mb-2">交流群</a></div>
                    <div><a @click="KF" class=" mb-2">客服</a></div>
                </div> -->

            </div>
            <div v-if="domain" class="flex foot-cellphone">
                <!-- <div class="cellphone_item">
                    <el-popover :visible-arrow="false" width="380" trigger="click" offset="10">
                        <div class="_a" slot="reference"><i class="iconfont icon-a-22"></i>手机app</div>
                    </el-popover>
                </div> -->
                <div class="cellphone_item">
                    <el-popover width="200" trigger="click" offset="10">
                        <img style="width:180px;height:180px" :src="web_info.qq_group_qr_code" alt="">
                        <div slot="reference" class="_a"><i class="iconfont icon-a-111"></i>交流群</div>
                    </el-popover>
                </div>
            </div>


            <!-- 本地测试 -->
            <!-- -->

            <!-- 主域 -->
            <div v-if="domain" class="foot-right">

                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div v-if="gongzimingcheng" class="mb-2">合肥泽卉网络科技有限公司</div>
                
                <div>增值电信业务经营许可证 皖B2-20230187</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">皖ICP备2023003744号</a></div>

                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34012302001206"
                    style=";font-size:12px;margin-top:8px;cursor: pointer; display: flex;align-items: center;"><img
                        src="@/assets/img/1212212.png" style="width:16px;margin-right: 4px;" /> 皖公网安备 34012302001206号</a>
                <!-- <div class="filings-info">
                <img style="width: 45px;" src="../assets/img/ADT/home/filings1.png" alt="">
                <img style="width: 45px;" src="../assets/img/ADT/home/filings2.png" alt="">
            </div> -->
            </div>

            <!-- upcsgo.rzxiyou.cn对应的主体麻烦修改成下面的资料信息： -->
            <!-- 润州区喜游信息咨询中心   ICP备案：苏ICP备2023015680号-1 -->
            <div v-if="domain_rzxiyou" class="foot-right">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>润州区喜游信息咨询中心</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：苏ICP备2023015680号-1</a></div>
            </div>


            <!-- upcsgo.rzxyxx.cn对应的主体麻烦修改成下面的资料信息： -->
            <!-- 润州区逍遥游信息咨询中心  ICP备案：苏ICP备2023015411号-1 -->
            <div v-if="domain_rzxyxx" class="foot-right">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>润州区逍遥游信息咨询中心</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：苏ICP备2023015411号-1</a></div>
            </div>

            <!-- upcsgo.ycbfxx.cn -->
            <!-- 盐城步凡信息技术有限公司    苏ICP备2023007120号-1 -->
            <div v-if="domain_ycbfxx" class="foot-right">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>盐城步凡信息技术有限公司</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：苏ICP备2023007120号-1</a></div>
            </div>

            <!-- upcsgo.jktfc.cn -->
            <!-- 济宁田茜网络科技有限公司    ICP备案：鲁ICP备2023018201号-1 -->
            <div v-if="domain_jktfc" class="foot-right">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>济宁田茜网络科技有限公司</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：鲁ICP备2023018201号-1</a></div>
            </div>

            <!-- upcsgo.jktfc.cn -->
            <div v-if="domain_jmzsfy" class="foot-right">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>江门市掌上风云网络科技有限公司蓬江第一分公司  </div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：粤ICP备2023086982号-1</a></div>
            </div>

            <!-- 通用 -->
            <div v-if="domain_white" class="foot-right">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>{{ domain_white.name }}  </div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：{{ domain_white.icp }}</a></div>
            </div>

            <!-- upcsgo.xinghouse.cn -->
            <!-- 这个域名不显示资质主体，包括网站右下角的增值电信业务许可证以及备案的皖B2-20230187 -->
            <div v-if="domain_xinghouse" class="foot-right">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
            </div>

            <div v-if="domain_url == 'upcsgo.rzczy.cn'" class="foot-right">
                <div>版权：润州区畅之游信息咨询中心</div>
            </div>
            <div v-if="domain_url == 'upcsgo.jshongy.com'" class="foot-right">
                <div>版权：苏州鸿扬信息科技有限公司</div>
            </div>


            <!-- upcsgo.022net.org.cn -->
            <!-- 这个域名不显示资质主体，包括网站右下角的增值电信业务许可证以及备案的皖B2-20230187 -->
            <!-- UPCSGO是厦门同城互动信息技术有限公司旗下游戏饰品开箱平台，新人注册赠送免费宝箱，开出任何饰品归您真实拥有！希望您在UPCSGO玩得开心！    -->
            <!-- 电话：0592-2572766 -->
            <!-- 厦门 软件园二期望海路21号602室J区 -->
            <div class="foot-right" v-if="domain_022net">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div style="font-size: 12px;margin-bottom:8px;">UPCSGO是厦门同城互动信息技术有限公司旗下游戏饰品开箱平台</div>
                <div style="font-size: 12px;margin-bottom:8px;">新人注册赠送免费宝箱，开出任何饰品归您真实拥有！</div>
                <div style="font-size: 12px;margin-bottom:8px;">希望您在UPCSGO玩得开心！</div>
                <div style="font-size: 12px;margin-bottom:8px;">电话：0592-2572766</div>
                <div style="font-size: 12px;margin-bottom:8px;">地址：厦门 软件园二期望海路21号602室J区</div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>


        </div>
        <!-- 移动端底部 -->
        <div class="foot-main text-center pc-none">
            <div>
                <img height="50px" src="../assets/img/ADT/home/footer-logo.png" />
            </div>
            <!-- 本地测试 -->
            <!-- -->
            <!-- 主域 -->
            <div class="foot-right" v-if="domain">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div v-if="gongzimingcheng" class="mb-2">合肥泽卉网络科技有限公司</div>
                <div>增值电信业务经营许可证 皖B2-20230187</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">皖ICP备2023003744号</a></div>
                <div class="margin-top-xs d-flex justify-content-center align-items-center">
                    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34012302001206"
                        style=";font-size:12px;cursor: pointer; display: flex;align-items: center;"><img
                            src="@/assets/img/1212212.png" style="width:16px;margin-right: 4px;" /> 皖公网安备
                        34012302001206号</a>
                </div>

                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot">
                    <div><a target="_blank" href="/active/help/2" class=" mt-2">用户协议</a></div>
                    <!-- <div><a class=" mt-2"></a></div> -->
                    <div><a target="_blank" href="/active/help/3" class=" mt-2">隐私条款</a></div>
                    <div><a target="_blank" href="/active/help/4" class=" mt-2">协议与条款</a></div>
                    <div><a target="_blank" href="/active/help/10" class=" mb-2">合规声明</a></div>
                    <div><a target="_blank" href="/active/help/11" class=" mb-2">反洗钱协议</a></div>
                    <div><a target="_blank" href="/help" class="">帮助中心</a></div>

                </div>
            </div>
            <!-- upcsgo.rzxiyou.cn对应的主体麻烦修改成下面的资料信息： -->
            <!-- 润州区喜游信息咨询中心   ICP备案：苏ICP备2023015680号-1 -->
            <div class="foot-right" v-if="domain_rzxiyou">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>润州区喜游信息咨询中心</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：苏ICP备2023015680号-1</a></div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>
            <!-- upcsgo.rzxyxx.cn对应的主体麻烦修改成下面的资料信息： -->
            <!-- 润州区逍遥游信息咨询中心  ICP备案：苏ICP备2023015411号-1 -->
            <div class="foot-right" v-if="domain_rzxyxx">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>润州区逍遥游信息咨询中心</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：苏ICP备2023015411号-1</a></div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>
            <!-- upcsgo.xinghouse.cn -->
            <!-- 这个域名不显示资质主体，包括网站右下角的增值电信业务许可证以及备案的皖B2-20230187 -->
            <div class="foot-right" v-if="domain_xinghouse">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>

            <div v-if="domain_url == 'upcsgo.rzczy.cn'" class="foot-right">
                <div>版权：润州区畅之游信息咨询中心</div>
            </div>
            <div v-if="domain_url == 'upcsgo.jshongy.com'" class="foot-right">
                <div>版权：苏州鸿扬信息科技有限公司</div>
            </div>





            <!-- upcsgo.022net.org.cn -->
            <!-- 这个域名不显示资质主体，包括网站右下角的增值电信业务许可证以及备案的皖B2-20230187 -->
            <!-- UPCSGO是厦门同城互动信息技术有限公司旗下游戏饰品开箱平台，新人注册赠送免费宝箱，开出任何饰品归您真实拥有！希望您在UPCSGO玩得开心！    -->
            <!-- 电话：0592-2572766 -->
            <!-- 厦门 软件园二期望海路21号602室J区 -->
            <div class="foot-right" v-if="domain_022net">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div style="font-size: 12px;margin-bottom:8px;">UPCSGO是厦门同城互动信息技术有限公司旗下游戏饰品开箱平台</div>
                <div style="font-size: 12px;margin-bottom:8px;">新人注册赠送免费宝箱，开出任何饰品归您真实拥有！</div>
                <div style="font-size: 12px;margin-bottom:8px;">希望您在UPCSGO玩得开心！</div>
                <div style="font-size: 12px;margin-bottom:8px;">电话：0592-2572766</div>
                <div style="font-size: 12px;margin-bottom:8px;">地址：厦门 软件园二期望海路21号602室J区</div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>



            <!-- upcsgo.ycbfxx.cn -->
            <!-- 盐城步凡信息技术有限公司    苏ICP备2023007120号-1 -->

            <div class="foot-right" v-if="domain_ycbfxx">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>盐城步凡信息技术有限公司</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：苏ICP备2023007120号-1</a></div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>

            <!-- upcsgo.jktfc.cn -->
            <!-- 济宁田茜网络科技有限公司    ICP备案：鲁ICP备2023018201号-4 -->

            <div class="foot-right" v-if="domain_jktfc">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>济宁田茜网络科技有限公司</div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：鲁ICP备2023018201号-4</a></div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>

            <!-- upcsgo.jmzsfy.top -->
            <div class="foot-right" v-if="domain_jmzsfy">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>江门市掌上风云网络科技有限公司蓬江第一分公司 </div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：粤ICP备2023086982号-1</a></div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>

            <!-- 通用 -->
            <div class="foot-right" v-if="domain_white">
                <div class="text-bold text-withe">© UPCSGO 2023</div>
                <div>{{ domain_white.name }} </div>
                <div class="margin-top-xs"><a style="" href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank">ICP备案：{{ domain_white.icp }}</a></div>
                <div style="font-size:12px">盲盒有风险，拆盒需谨慎，最终商品以实际获取为准</div>
                <div class="mb-foot"></div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            domain_url: '',
        };
    },
    computed: {
        ...mapState(['user_info', 'steam_handle', 'login_is_show', 'login_top', 'web_info']),
        // 主域名
        domain: function () {
            let host = window.location.host
            return [`upcsgo.com`, `www.upcsgo.com`, `ks.upcsgo.com`, `192.168.2.134:8230`].includes(host) ? true : false;
        },
        // upcsgo.rzxiyou.cn
        domain_rzxiyou: function () {
            let host = window.location.host
            return [`upcsgo.rzxiyou.cn`].includes(host) ? true : false;
        },
        // upcsgo.rzxyxx.cn
        domain_rzxyxx: function () {
            let host = window.location.host
            return [`upcsgo.rzxyxx.cn`].includes(host) ? true : false;
        },
        // upcsgo.xinghouse.cn
        domain_xinghouse: function () {
            let host = window.location.host
            return [`upcsgo.xinghouse.cn`].includes(host) ? true : false;
        },
        // upcsgo.022net.org.cn
        domain_022net: function () {
            let host = window.location.host
            return [`upcsgo.022net.org.cn`].includes(host) ? true : false;
        },
        gongzimingcheng: function () {
            let host = window.location.host
            return [`ks.upcsgo.com`].includes(host) ? true : false;
        },
        // upcsgo.ycbfxx.cn
        domain_ycbfxx: function () {
            let host = window.location.host
            return [`upcsgo.ycbfxx.cn`].includes(host) ? true : false;
        },
        domain_jktfc: function () {
            let host = window.location.host
            return [`upcsgo.jktfc.com`].includes(host) ? true : false;
        },
        domain_jmzsfy: function () {
            let host = window.location.host
            return [`upcsgo.jmzsfy.top`, 'localhost:8230'].includes(host) ? true : false;
        },
        // 通用
        domain_white: () => {
            let list = {
                '192.168.2.134:8230': {
                    name: '111',
                    icp: '111-1'
                },
                'upcsgo.jmpjws.cn': {
                    name: '江门蓬江区方斯酷游信息咨询中心',
                    icp: '粤ICP备2023090857号-1'
                },
                
            }
            return list[window.location.host]
        }
    },
    created() {
        this.domain_url = window.location.host;
    },
    methods: {
        KF() {
            window.open("/chatlink.html", "_blank");
        }
    },
    mounted() {
        console.log("window.location.host---", window.location.host);
        console.log(this.domain_jmzsfy)

        console.log("domain---", this.domain);
        console.log("domain_rzxiyou---", this.domain_rzxiyou);
        console.log("domain_rzxyxx---", this.domain_rzxyxx);
        console.log("domain_xinghouse---", this.domain_xinghouse);
        console.log("domain_022net---", this.domain_022net);

    },
}
</script>

<style lang="scss">
.pay_main_list_main {
    
}
div,a {
    font-family: "Microsoft YaHei" !important;
}
.footer-main {
    background: #162F40;
    color: #fff;
    font-family: "Microsoft YaHei" !important;

    .mb-foot {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        >div {
            font-size: 12px;
            color: #fff !important;
            margin-top: 20px !important;
        }
    }

    .foot-main {
        width: 100%;
        height: 240px;
        position: relative;
        bottom: 0;
        // background: url('../assets/img/news/bLOGO.png') no-repeat;
        // background-position: right bottom;
        padding: 10px 0;
    }

    @media (max-width:1300px) {
        .flex {
            display: block;
        }
    }

    .foot-img {
        height: 80px;

        @media (max-width:1300px) {
            height: 60px;
        }
    }

    .w1720 {
        // background: url('../assets/img/news/bLOGO.png') no-repeat;
        // background-position: right bottom;
        padding-bottom: 30px;

        @media (max-width:1300px) {
            width: 100%;
        }
    }

    .main {
        margin-left: 100px;
        margin-top: 30px;
        padding-right: 80px;
        border-right: 1px solid rgba(255, 255, 255, 0.3);

        >div {
            line-height: 2;

            a {

                color: #fff !important;
            }
        }

        .text-withe {
            line-height: 80px;
            margin-right: 130px;
        }
    }

    .text-withe {
        color: #fff;
    }

    .foot-cellphone {
        margin-top: 30px;
        border-right: 1px solid rgba(255, 255, 255, 0.3);

        .cellphone_item {
            margin: 0 50px;
            line-height: 100px;
        }
    }

    .foot-right {
        margin-top: 30px;
        margin-left: 80px;

        @media (max-width:1300px) {
            margin-top: 10px;
            margin-left: 0px;

            .margin-top-xs {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        >.text-withe {
            line-height: 80px;

            @media (max-width:1300px) {
                line-height: 30px;
            }
        }

        >div:nth-child(2) {
            font-size: 12px;
        }

        >div:nth-child(3) {
            font-size: 12px;
        }

        .filings-info {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: start;

            img:first-child {
                margin-right: 20px;
            }
        }
    }
}
</style>