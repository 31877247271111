import {request} from "../index";

function getTimeBoxRecords(time_box_id){
    return request({
        url: `/time-box-records`,
        method:'post',
        data:{
            time_box_id
        }
    })
}

function getTimeBoxList(){
    return request({
        url: `/time-box-list`,
        method:'post',
        
    })
}
export default {
    getTimeBoxRecords,
    getTimeBoxList
}
