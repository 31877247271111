/**
 * 路由导航守卫
 * */
import router from './router'
import store from './store'
// 导入一些获取token等等方法
import { getToken,getExpires,setToken,setExpires,removeToken } from '@/utils/auth'
// 这是一个显示标题的
import getPageTitle from '@/utils/get-page-title'
// 组件element
// import Message from "element-ui/packages/message/src/main";
// import { Notification  } from 'element-ui'
// 请求
import $api from './api/login.js'



// 前置钩子
router.beforeEach(async(to, from, next) => {
    let url=window.location.host;
    if(url=='www.upcsgo.com' || url=='upcsgo.com'){
        document.title = getPageTitle(to.meta.title)
    }else{
        document.title = getPageTitle(to.meta.title)
    }
    next()
})

router.afterEach((to) => {

    let token = getToken()
    let expires = getExpires()
    let timestamp = Date.parse(new Date())
    timestamp = timestamp/1000 + (24*60*60*60)/100*1.5
    if(expires == undefined){
        let i = (24*60*60*60)/100*1
        setExpires(i)
    }
    if(token != undefined && timestamp > expires -  (24*60*60*60)/100*1 ){
        $api.getRefreshToken().then(res =>{
            if(res.code == 200){
                let expires_in = res.data.expires_in
                setToken(res.data.access_token)
                setExpires(expires_in)
            }else {
                removeToken()
                store.commit('USER_INFO',false)
                router.go(0)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    if(to.meta.login && !token ){
        removeToken()
        store.commit('USER_INFO',false)
        router.push('/Fnologin')
    }
    // store.dispatch('userInfo')
    store.commit('ISSHOWNAVPC',false)
    store.commit('ISSHOWNAVPHONE',false)
    if(to.name != 'mysteryBox'){
        setTimeout(()=>{
            window.scrollTo(0,0)
            document.getElementById('content').scrollTop = 0;
        },200)
    }
})


