
import { request } from "../index";
function getPKarenaList(page,is_anchor) {
  return request({
    url: `/arena/list?page=${page}&is_anchor=${is_anchor}`,
    method: 'get'
    
  })
}

function goingSocket(data){
  return request({
    url: `/arena/goingSocket`,
    method: 'post',
    data
  })
}

function getPKarenaHistory(page) {
  return request({
    url: `/arena/history?page=${page}`,
    method: 'get'
  })
}

function getPKarenaMyHistory(page) {
  return request({
    url: `/arena/me-history?page=${page}`,
    method: 'get'
  })
}
function getPKarenaboxList() {
  return request({
    url: `/arena/box-list`,
    method: 'get'
  })
}
function getPKarenaCreate(data) {
  return request({
    url: `/arena/create`,
    method: 'post',
    data
  })
}
function getPKarenaJoin(data) {
  return request({
    url: `/arena/join`,
    method: 'post',
    data
  })
}


function getPKarenaDetail(game_arena_id) {
  return request({
    url: `/arena/detail?game_arena_id=${game_arena_id}`,
    method: 'get'
  })
}

function getArenaJoinChannel(data) {
  return request({
    url: `/arena/join-channel`,
    method: 'POST',
    data
  })
}

// 对战榜 & 胜率榜
function getArenaRank(is_anchor){
  return request({
    url:`/arena/rank/by-bean?is_anchor=${is_anchor}`,
    method:'get',
  })
}
function getArenaRate(is_anchor){
  return request({
    url:`/arena/rank/by-rate?is_anchor=${is_anchor}`,
    method:'get'
  })
}

// 机器人
function getArenaDesignatedStatic(){
  return request({
    url: `/arena/designated_bot_users_static`,
    method:'get'
  })
}

function getTopTen(play_type,type,by_type){
  return request({
    url: `/arena/topTen?play_type=${play_type}&type=${type}&by_type=${by_type}`,
    method:'get',
  })
}

function getHistory(page,is_anchor,play_type,is_me){
  return request({
    url:`/arena/history?page=${page}&is_anchor=${is_anchor}&play_type=${play_type}&is_me=${is_me}`,
    method:'get'
  })
}
function getHistory1(page,is_anchor,user_id){
  return request({
    url:`/arena/history?page=${page}&is_anchor=${is_anchor}&user_id=${user_id}`,
    method:'get'
  })
}

function createPk(data){
  return request({
    url:`/arena/create`,
    method:'post',
    data
  })
}


function getBoxList(){
  return request({
    url: `/box/list`,
    method:'get',
  })
}

function getDesignatedId(){
  return request({
    url:`/arena/designated_bot_users_id`,
    method:'get'
  })
}



function getWinWorth(user_id,page){
  return request({
    url:`/arena/winWorth?user_id=${user_id}&page=${page}`,
    method:'get'
  })
}
function getLatestSeven(user_id){
  return request({
    url:`/user/storage/latestSeven?user_id=${user_id}`,
    method:'get'
  })
}
function getUserOpenStatic(user_id){
  return request({
    url:`/user/open/static?user_id=${user_id}`,
    method:'get'
  })
}

function getBlindGame(user_id){
  return request({
    url:`/user/blindGame?user_id=${user_id}`,
    method:'get'
  })
}

function getUserHome(data){
  return request({
    url:`/user/home`,
    method:'post',
    data
  })
}


function joinBot(data){
  return request({
    url:`/arena/join/bot`,
    method:'post',
    data
  })
}

function awardList(is_anchor){
  return request({
    url: `/arena/ranking/awardList?is_anchor=${is_anchor}`,
    method: 'get',
  })
}

function receiveAward(data){
  return request({
    url: `/arena/ranking/receiveAward`,
    method:'post',
    data
  })
}







export default {
  getWinWorth,
  getLatestSeven,
  getUserOpenStatic,
  getUserHome,

  getPKarenaList,
  getArenaJoinChannel,
  getPKarenaHistory,
  getPKarenaMyHistory,
  getPKarenaboxList,
  getPKarenaCreate,
  getPKarenaDetail,
  getPKarenaJoin,
  getArenaDesignatedStatic,
  getArenaRank,
  getArenaRate,
  getTopTen,
  getHistory,
  getHistory1,
  createPk,
  getBoxList,
  getDesignatedId,
  getBlindGame,
  goingSocket,
  joinBot,
  awardList,
  receiveAward
}
