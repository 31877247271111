import {request} from "./index";

async function baiduApi(newType,logidUrl){
  let conversionTypes = [
    {
      "logidUrl": logidUrl,
      "newType": newType
    }
  ]
  return request({
    method: 'post',
    url: "/ocpc/uploadConvertData",
    data: {conversionTypes},
  })
}
async function uploadConvertData(data){
  return request({
    method: 'post',
    url: "ocpcsm/uploadConvertData",
    data:data,
  })
}


export default {
  uploadConvertData,
  baiduApi
}
