import {request} from "./index";

function getInfo(){
    return request({
        url: `/saolei/getInfo`,
        method:'post',
    })
}

function extractReward(){
    return request({
        url: `/saolei/extractReward`,
        method:'post',
    })
}

function userPriceUpdate(data){
    return request({
        url: `/saolei/userPriceUpdate`,
        method:'post',
        data
    })
}

function saoleiPay(data){
    return request({
        url: `/saolei/pay`,
        method:'post',
        data
    })
}

function giveUp(data){
    return request({
        url:`/saolei/giveUp`,
        method:'post',
        data
    })
}
function open(data){
    return request({
        url:`/saolei/open`,
        method:'post',
        data
    })
}

function getRecycle(data) {
    return request({
        url: `/cash`,
        method:'post',
        data
    })
}

function getTopKing(){
    return request({
        url:`/saolei/topKing`,
        method:'post',
    })
}

export default {
    getInfo,
    extractReward,
    userPriceUpdate,
    saoleiPay,
    giveUp,
    open,
    getRecycle,
    getTopKing
}
