<template>
    <div class="cursor-pointer common-btn common-btn-main f-main-bg " style="">
        <div :style="size" class="text-center">
            <slot>
                <div class="text" :style="location">
                    {{title}}
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bottomBg",
        props:{
            size:{type:Object,default(){return{}}},
            title:{type: String,default:'文字title'},
            location:{type: Object,default(){return {}}}
        },
        data() {
            return {}
        },
    }
</script>

<style scoped>
    .text{
        text-align: center;
        color: #FFFFFF;
        line-height: 40px;
    }
    .btn{
    }
    .common-btn-main {
        padding: 0;
    }
</style>
