<template>
  <div class="content">
    <!-- {{ id }}
    {{ isShowTitle }} -->
    <div v-if="isShowTitle" class="h3 text-white font-weight-bold text-center my-5">
      {{ id == 0 ? '我的历史记录' : '最近掉落' }}
    </div>
    <div v-if="isShowBack" @click="codeBack"
      class="p-2 bg-yellow cursor-pointer d-flex col-md-1 col-4 justify-content-center ml-auto">
      返回
    </div>

    <div class="mt-3  content-list">
      <table cellspacing="0" cellpadding="0" border="0" class="content-list-box" style="">
        <colgroup>
          <col width="100">
          <col v-if="id != 0" width="200">
          <col width="200">
          <col width="120">
          <col width="120">
          <col width="100">
          <col width="100">
          <col width="200">
          <col width="150">
          <col width="150">
        </colgroup>
        <thead class="text-danger">

          <tr class="grade">
            <th colspan="1" rowspan="1">
              <div class="cell">合成结果 </div>
            </th>
            <th v-if="id != 0" colspan="1" rowspan="1">
              <div class="cell">用户</div>
            </th>

            <th colspan="1" rowspan="1">
              <div class="cell">饰品图片</div>
            </th>

            <th colspan="1" rowspan="1">
              <div class="cell">获得饰品外观</div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">获得饰品价值</div>
            </th>

            <th colspan="1" rowspan="1">
              <div class="cell">花费</div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">拉取量 </div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">目标饰品名称</div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">目标饰品外观 </div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">时间 </div>
            </th>


          </tr>
        </thead>
        <tr class="grade lucky-H-bg border-bottom-2" :class="item.award_id == item.get_award.id ? 'color-yellow-2' : ''"
          v-for="(item, index) in historyList" :key="index">
          <td>
            <div v-if="item.award_id != item.get_award.id"
              class="cell flex-column align-items-center justify-content-center">
              <div><img style="width:50px" src="@/assets/img/lucky/upgradeHistory__lose.png" alt=""></div>
              <div>失败</div>
            </div>
            <div v-else class="cell flex-column align-items-center justify-content-center">
              <div><img style="width:50px" src="@/assets/img/lucky/upgradeHistory__succeed.png" alt=""></div>
              <div>成功</div>
            </div>
          </td>

          <td v-if="id != 0">
            <div class="px-4 pb-1 text-center d-flex align-items-center">
              <img style="height: 50px" class="rounded-pill" v-lazy="item.user.avatar" alt="">
              <div class="text-overflow-1 ml-1">{{ item.user.name }}</div>
            </div>
          </td>

          <td>
            <div class="px-4  text-center d-flex flex-column align-items-center">
              <img style="height: 50px" class="" v-lazy="item.get_award.cover" alt="">
              <div class="" style="font-size: 12px">{{ item.get_award_name }}</div>
            </div>
          </td>

          <td>
            <div class="cell">{{ item.get_award_dura_alias }}</div>
          </td>

          <td>
            <div class="cell">{{ item.get_bean }}</div>
          </td>



          <td>
            <div class="cell">{{ item.use_bean }}</div>
          </td>

          <td>
            <div class="cell">{{ item.percent + '%' }}</div>
          </td>

          <td>
            <div class="cell">
              {{ item.award_name }}
            </div>
          </td>

          <td>
            <div class="cell">{{ item.award_dura_alias }}</div>
          </td>
          <td>
            <div class="cell">{{ item.created_at }}</div>
          </td>




        </tr>
      </table>
      <div class="mt-3" v-if="id == 0">
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
          @current-change="currentChange">
        </el-pagination>
      </div>
    </div>


  </div>
</template>
<script>

import $api from '@/api/lucky/index'
export default {
  name: "history",
  props: {
    id: {
      type: [String, Number],
    },
    isShowBack: {
      type: [Boolean],
      default: true
    },
    isShowTitle: {
      type: [Boolean],
      default: true
    }
  },
  components: {
  },
  data() {
    return {
      activetab: "history",
      historyList: [],
      pageSize: 0,
      total: 0,
    }
  },
  created() {
    // this.id = this.$route.params.id

  },
  async mounted() {

    if (this.id == 0) {
      await this.getHistoryList()
    } else {
      this.getHistory()
    }
  },
  computed: {

  },
  watch: {
    id: {
      handler(newVal) {
        console.log("newVal",newVal);
        if (newVal == 0) {
          this.getHistoryList()
        } else {
          this.getHistory()
        }

      },
      immediate: true,
      deep: true,
    },

  },
  methods: {
    // 翻页
    currentChange(val) {
      console.log(this.id)
      if (this.id == 0) {
        this.getHistoryList(val)
      } else {
        this.getHistory()
      }
      window.scrollTo(0, 0)
    },
    // 获取数据列表
    getHistoryList(page = 1) {
      $api.meHistory(page).then(res => {
        console.log(res)
        this.historyList = res.data.data
        this.total = res.data.total
        this.pageSize = res.data.per_page
      })

    },
    getHistory() {
      $api.getHistory(this.id).then(res => {
        console.log(res)
        this.historyList = res.data
        this.total = 0
        this.pageSize = 0
      })

    },
    codeBack() {
      this.$emit('codeBack', false)
    }
  }

}

</script>

<style scoped lang="scss">
.color-yellow-2 {
  color: rgb(255, 181, 38);
}
.border-bottom-2 {
  border-bottom: 1px solid #1e7a91 !important;
}
.content-list {
  overflow-x: scroll;
  width: 100%;

  &-box {
    width: 100%;

    @media (max-width: 1366px) {
      width: 1200px;
    }
  }
}

.lucky-H-bg {
  // background-image: url("../../assets/img/lucky/upgradeHistory__item__bg.png") !important;
  background-image: linear-gradient(0deg, #1c4058, #1c4058 33%, #184d68 66%, #184d68);
  background-size: 100% 100%;
  margin-bottom: 10px;
}

td {
  height: 100px;
  vertical-align: middle;
}

.grade {
  background-image: linear-gradient(0deg, #1c4058, #1c4058 33%, #184d68 66%, #184d68);

  @media (max-width: 1200px) {
    font-size: 12px;
  }
}

.input-code {
  @media (max-width: 1200px) {
    width: 150px !important;
  }
}

.tong-height {
  height: 38px;
  line-height: 38px;
}

.side-box {
  width: 204px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
  width: 90%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 96%;
    font-size: 12px !important;
  }
}

.cell {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 14px;

  @media (max-width: 1200px) {
    padding: 5px;
  }
}

.highlighted-message {
  padding: 19px 5px 19px 16px;
  border: 1px solid #fada62;
  border-radius: 8px;
  background-color: rgba(250, 218, 98, 0.12);
}</style>
