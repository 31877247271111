<template>
  <div style="width: 100%">
    <div class="d-flex justify-content-center
     align-items-center" style="width: 100%;height: 700px">
      <div class="text-center">
        <img v-lazy="require('@/assets/img/common/unll.png')" alt="">
        <div class="mt-3" style="opacity: .8">
          {{message}}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
    export default {
        name: "vacancy",
        data() {
            return {}
        },
        props:{
            message:{
              type:String,
              default:'您的库存还没有饰品......'
            }
        }
    }
</script>

<style scoped>

</style>
