<template>
  <div class="content">
    <div class="h3 lucky-title-top text-white font-weight-bold text-center">
      <div class="biaoti_img">
        <img src="@/assets/img/ADT/zm/biaoti_1.png" />
        <div class="cursor-pointer text-center " style="font-size: 16px;position: relative;z-index:99;" @click="playingMethod = true">
          <img style="width: 17px;height:17px;vertical-align: middle;margin-right: 4px;" src="@/assets/img/ADT/zm/question-mark.png"
            alt="">
          <span style="font-size: 18px;color: #2586A3;">游戏规则</span>
        </div>
    
      </div>

    </div>
    <div class="mp-content" v-show="!historyList">

      <div class="d-flex align-items-center justify-content-around lucky-fontSize" style="position: relative;" :style="{right: ua === 'web' ? 0: '-70px'}">
        <div class="d-md-flex d-block">
          <!-- <div v-if="SelectedPic" class="mr-2 p-3 cursor-pointer" @click="luckyHistoryQ">
            最近掉落
          </div> -->
        </div>
        <div>
          <div class="d-flex">
            <div class="cursor-pointer">
              <img style="width: 40px;" @click="animationOFF = !animationOFF;" v-if="!animationOFF"
                :src="require('../../assets/img/ADT/zm/t-close.png')" alt="">
              <img style="width: 40px;" @click=" animationOFF = !animationOFF; " v-else
                :src=" require('../../assets/img/ADT/zm/t-open.png') " alt="">
              <!-- <el-switch class="animationOFF" v-model="animationOFF" active-color="#13ce66" inactive-color="#ff4949">
              </el-switch> -->
              <!-- 关闭动画 -->
            </div>
          </div>
        </div>
      </div> 
      <div class="content-top d-flex justify-content-around align-items-center">
        <!-- <div style="opacity: 0;" class="d-flex flex-column align-items-center justify-content-center content-top-left">
          <img :src="SelectedPic
              ? SelectedPic.cover
              : require('@/assets/img/lucky/upgrade__icon__arrow_pic.png')
            " alt="" :class="SelectedPic ? 'content-top-active' : ''" style="width: 220px"
            class="content-top-left-img" />
          <div style="width: 220px" class="text-overflow-1 text-center">
            {{ SelectedPic ? SelectedPic.name : "请选择你想要得到的物品" }}
          </div>
          <span class="mt-3 upDom">
            <span v-if="SelectedPic">
              <money></money>{{ SelectedPic ? SelectedPic.bean : "0.00" }}
            </span>
            <img v-else style="" class="content-top-left-img-ico" src="../../assets/img/lucky/upgrade__icon__arrow.png"
              alt="" />
          </span>
        </div> -->
        <div class="d-flex justify-content-center align-items-center position-relative content-top-yuan">
          
          <img class="outer-circle position-absolute" src="../../assets/img/ADT/zm/outer-circle.png" alt="" />
          <img class="inner-ring position-absolute" src="../../assets/img/ADT/zm/inner-ring.png" alt="" />
          <img src="../../assets/img/ADT/zm/upgrade__rotary.png" alt="" />
          <img class="position-absolute anShanDian" src="../../assets/img/lucky/shengji_1.png" style="" alt=""
            v-if="btnStatus" />
          <img v-if="!oprnClickIsShow" class="position-absolute content-top-yuan-xian" name="1"
            :style="`transform: rotate(${360 * (successRate / 100)}deg);transition: all 0s;`"
            src="../../assets/img/ADT/zm/upgrade__rotary__pointer.png" alt="" />
          <img v-else class="position-absolute content-top-yuan-kai-jiang" name="2"
            :style="`transform: rotate(${successRateTow}deg);`" src="../../assets/img/ADT/zm/upgrade__rotary__pointer.png"
            alt="" />
          <!-- <img
            class="position-absolute"
            src="../../assets/img/lucky/upgrade__rotary__box.png"
            alt=""
          /> -->
          <div class="position-absolute">
            <linear-gradient r="162" :value="successRate2 / 100"></linear-gradient>
          </div>

          <div class="position-absolute text-center">
            <div v-if="!loing">
              <div class="font-weight-bold h1" style="font-size: 80px;color: #FFB526;">
                {{ successRate }}%
              </div>
              <div>设置追梦概率</div>
            </div>

            <div v-else class="lucky-error-succeed animate__fadeInUp animate__animated">
              <div v-if="resData.skin_id != SelectedPic.id" class="lucky-error">
                升级失败
              </div>
              <div v-else class="lucky-succeed">升级成功</div>
              <div class="font-weight-bold">
                {{ successRate }} <span class="">%</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="opacity: 0;" class="phone-therr d-flex flex-column align-items-center justify-content-center">
          <img src="../../assets/img/lucky/upgrade__icon__arrow_pic.png" alt="" style="width: 220px" />
          <div style="width: 220px" class="text-overflow-1 text-center">
            随机得到的饰品
          </div>
          <div style="opacity: 0">随机得到的饰品</div>
          <div style="opacity: 0">随机得到的饰品</div>
        </div> -->
      </div>

      <!-- {{SelectedPic}} -->
      <div v-if="!SelectedPic" class="content-bottom choose-accessories cursor-pointer" style="background-image:none;">
        <div style="margin: 0 auto;padding-left: 0px;" class="flex align-items-center justify-content-center">
          <div class="left" @click="selectClose = true">
            <span>选择想要的物品</span>
            <!-- <img src="../../assets/img/ADT/" alt=""> -->
          </div>
       
        </div>
      </div>

      <div v-else class="content-bottom choose-accessories cursor-pointer yixuanshipin" @click=" selectClose = true ">
        <img :src="
          SelectedPic
            ? SelectedPic.cover
            : require('@/assets/img/lucky/upgrade__icon__arrow_pic.png')
        " alt="" style="width: 220px" class="content-top-left-img yixuanshipin-img" />
        <div style="width: 220px" class="text-overflow-1 text-center yixuanshipin-name">
          {{ SelectedPic ? SelectedPic.name : "请选择你想要得到的物品" }}
        </div>
        <!-- <span class="mt-3 upDom">
            <span v-if="SelectedPic">
              <money></money>{{ SelectedPic ? SelectedPic.bean : "0.00" }}
            </span>
            <img v-else style="" class="content-top-left-img-ico" src="../../assets/img/lucky/upgrade__icon__arrow.png"
              alt="" />
          </span> -->
      </div>


      <div class="content-bottom biaochi">
        <div class="d-flex justify-content-center success_rate">
          <div>
            成功率<span style="color: #F9C545;min-width: 65px;display: inline-block;margin-left: 5px;"> {{ successRate }}%
            </span>
          </div>

        </div>
        <!-- 进度条 -->
        <div class="content-bottom-slide">
          <el-slider :show-tooltip=" false " class="content-bottom-slide-slider" :min=" 0 " :max=" 75 "
            v-model="successRate "></el-slider>
          <div style="margin-top: 15px;">
            <img v-if="ua === 'web'" class="content-bottom-slide-slider-img" src="@/assets/img/lucky/jindutiao.png" alt="" />
            <img v-else class="content-bottom-slide-slider-img" src="@/assets/img/lucky/jindutiao-phone.png" alt="" />
          </div>
        </div>

      </div>

      <!-- 按钮 -->
      <div class="content-bottom-btn shengji-btn" v-if=" btnStatus || btnStatus == null "
        @click=" openAward(SelectedPic, successRate) ">
        <div class="upgrade-now">
          <money style="width: 30px;height:30px;vertical-align: middle;" />
          <span>{{ money }}</span>
          <span>升级</span>
        </div>
      </div>
      <div class="content-bottom-btn shengji-btn" v-else @click=" btnStatus = null, oprnClickIsShow = false ">
        <div>继续追梦</div>
      </div>
      <div class="text-center color-yellow mt-3 mb-5">
        *升级概率范围为5%~75%
      </div>


      
      <div v-if="SelectedPic">
          <history :id=" SelectedPicID " @codeBack=" codeBack " :isShowBack=" false " :isShowTitle=" true "></history>
        </div>

      <!-- 奖品弹窗 -->
      <div class="" v-if=" false ">
        <div style="width: 700px; left: calc(50% - 350px); top: 50px;"
          class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
          <div>
            <div class="d-flex flex-column align-items-center justify-content-center py-5 flex-wrap">
              <div>
                <img :src=" resData.cover " width="300px" alt="" />
              </div>
              <div class="text-center">
                <div>{{ resData.name }}</div>
                <div class="mt-2">{{ resData.dura_alias }}</div>
                <div class="mt-2">
                  <money />{{ resData.bean }}
                </div>
              </div>
            </div>
            <div class="d-flex px-5 justify-content-center mt-3">
              <div @click=" svgaIsShowOff " style="width: 250px;"
                class="text-white text-center f_btn_green cursor-pointer">
                放入仓库
              </div>
            </div>
            <div @click=" svgaIsShowOff " class="position-absolute" style="right: 20px; top: 30px">
              <div>
                <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
      </div>

      <!-- 武器弹窗 -->
      <div class="select" v-if=" selectClose ">
        <div class="select-top d-flex justify-content-between p-md-4 p-2">
          <div class="select_thing">选择饰品</div>
          <div @click=" selectClose = false ">
            <!-- <i class="el-icon-close"></i> -->
            <img width="24" src="../../assets/img/ADT/zm/luckyPup/close.png" alt="">
          </div>
        </div>
        <div class="select-lucky">
          <lucky-pup @pitchOn=" pitchOn "></lucky-pup>
        </div>
        <div class="position-fixed bg-bg-2 op-4 z-index-0" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
      </div>

      <!-- 中将弹窗 -->
      <transition name="el-fade-in-linear">
        <div v-if=" svgaIsShow ">
          <div style="z-index: 12000;" class="f-border-top awardListPop position-fixed pop-up py-5 px-4 history_bg">
            <div>
              <div class="h5 text-center">您获得了</div>
              <div class="awardListPop-box d-flex justify-content-center pt-5 flex-wrap">
                <div class="text-center rounded award-box position-relative ggbg2"
                  :class=" resData.skin_id == SelectedPic.id ? 'ggbg2' : 'ggbg5' " style="width:300px;">
                  <div class="my-1 text-overflow-1 title_qian" style="line-height: 16px;">
                    {{ resData.name }}
                  </div>
                  <img :src=" resData.cover " alt="" style="width: 80%; height: 90px;" />
                  <div v-if=" resData.skin_id != SelectedPic.id " class="d-flex justify-content-between huishoubtn"
                    style="font-size: 14px">
                    <div class="text-center w-50 p-2">
                      <money />{{ resData.bean }}
                    </div>
                    <!-- <div
                      class="text-center w-50 p-2 cursor-pointer"
                      @click="recycle(resData)"
                    >
                      分解
                    </div> -->
                  </div>
                  <div v-else class="d-flex justify-content-between huishoubtn" style="font-size: 14px">
                    <div class="text-center w-100 p-2">
                      <money style="vertical-align: middle;" />{{ resData.bean }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mt-2 justify-content-between">
                <div @click=" svgaIsShowOff ">
                  <bottom-bg2>
                    <div style="color: #ffffff; font-size: 16px;margin-left:0px" class="">
                      {{
                      resData.skin_id != SelectedPic.id
                      ? "继续开箱"
                      : "放入背包"
                      }}
                    </div>
                  </bottom-bg2>
                </div>
                <div @click=" recycle(resData) " class="cursor-pointer">
                  <bottom-bg2>
                    <div style="color: #ffffff; font-size: 16px" class="">
                      <span style="margin-right: 10px; font-size: 14px">饰品分解</span>
                    </div>
                  </bottom-bg2>
                </div>
              </div>

              <div @click=" svgaIsShowOff " class="position-absolute" style="right: 20px; top: 30px">
                <div>
                  <!-- <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i> -->
                  <img style="width: 25px;" src="../../assets/img/ADT/home/close.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
        </div>
      </transition>
    </div>

    <!-- 玩法介绍 -->
    <div class="" v-if=" playingMethod ">
      <div style=""
        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg notice">
        <div class="text-white p-md-4 p-2" style="line-height: 2">
          <p>
            1.选择您想要的目标饰品，选择成功率5%-75%。
          </p>
          <p>&nbsp;</p>
          <p>
            2.追梦所需U币会随饰品价值和所选百分比区间进行变化。
          </p>
          <p>&nbsp;</p>
          <p>3.追梦成功，您将获得目标饰品。追梦失败，您将获得随机安慰奖。</p>
          <p>&nbsp;</p>
          <p>
            4.每次追梦的概率为独立事件，不会累积成功率。当您连续二次50%概率追梦时，不意味着有100%几率获得该饰品。
          </p>
          <p>&nbsp;</p>
          <p>
            5.追梦失败的补偿，1000-2000补偿88u币，2000-5000追梦失败补偿188u币，5000以上补偿288u币。
          </p>
          <p>&nbsp;</p>
          <p>
           6.请仔细查阅《用户协议》及相应条款，保持理性消费!
          </p>
          
        </div>
        <div @click=" playingMethod = false " class="position-absolute" style="right: 20px; top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi2 cursor-pointer" style="font-size: 30px"></i>
          </div>
        </div>
      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw; height: 100vh; left: 0; top: 0"></div>
    </div>
  </div>
</template>

<script>
import $api from "@/api/lucky/index";
import $url from "@/api/CSGO/index";
import luckyPup from "./common/luckyPup";
import linearGradient from "./common/linearGradient";
import bottomBg2 from "@/components/bottomBg2";
import history from "./history.vue";
import sUserAgent from "@/assets/js/common.js";
export default {
  name: "luckyIndex",
  data() {
    return {
      selectClose: false,
      playingMethod: false,
      successRate: 0,
      successRate2: 0,
      oprnClickIsShow: false,
      SelectedPic: null,
      animationOFF: true,
      resData: {},
      svgaIsShow: false,
      successRateTow: 30,
      loing: false,
      ClickAgain: false,
      historyList: false,
      SelectedPicID: 0,
      btnStatus: null
    };
  },
  components: {
    luckyPup,
    linearGradient,
    bottomBg2,
    history,
  },
  created() {
    setInterval(() => {
      // console.log(this.successRate);
      this.successRate2 = this.successRate - 0.6;
    }, 1000);
  },
  mounted() {
    console.log("sUserAgent");
    console.log(sUserAgent.sUserAgent());
    console.log(this.successRate);
  },
  computed: {
    money() {
      return (
        // 1.205
        (this.successRate / 100) *
        (this.SelectedPic ? this.SelectedPic.bean * 1.12 : 0)
      ).toFixed(2);
    },
    ua() {
      console.log("sUserAgent");
      console.log(sUserAgent);
      return sUserAgent.sUserAgent();
    },
  },
  methods: {
    codeBack(Bon) {
      this.historyList = Bon;
    },
    // 点击我的记录
    luckyHistory() {
      this.historyList = true;
      this.SelectedPicID = 0;
    },
    // 点击饰品显示的记录
    // luckyHistoryQ() {
    //   this.historyList = true;
    //   this.SelectedPicID = this.SelectedPic.id;
    // },
    // 点击分解
    async recycle(item) {
      let arr = [];
      arr.push(item.id);
      let { message, code } = await $url.getRecycle({ data: arr });
      if (code == 500) return;
      this.$notify({
        message: message,
        type: "success",
        position: "top-left",
      });
      this.svgaIsShowOff();
      this.$store.dispatch("userInfo");
      this.ClickAgain = false;
    },
    // 两个数字之间的值
    getRandomNumber(min, max) {
      console.log(min, max)
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    // 点击开箱
    async openAward(SelectedPic = null, successRate) {
      this.$notify.closeAll()
      if (this.successRate < 5) {
        this.$notify({
          showClose: false,
          message: "成功率不得低于5%",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      // 没有选择饰品的情况
      if (SelectedPic == null) {
        this.$notify({
          showClose: false,
          message: "请选择饰品",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      if (this.ClickAgain) {
        this.$notify({
          showClose: false,
          message: "正在开箱，稍后......",
          type: "warning",
          position: "top-left",
        });
        return;
      }
      this.ClickAgain = true;
      // 发出请求
      let { data, code } = await $api.getluckyOpen({
        id: SelectedPic.id,
        percent: (successRate / 100).toFixed(2),
      });
      if (code == 500) {
        return;
      }
      // console.log(
      //   this.getRandomNumber(360 * (this.successRate / 100) + 1, 360)
      // );
      // // 得到结果
      if (SelectedPic.id == data.skin_id) {
        // 暂时没用

        this.successRateTow =
          360 * 3 +
          360 * (this.successRate / 100) -
          Math.floor(Math.random() * (360 * (this.successRate / 100)));
      } else {
        let editVal = 360 * 3 +
          360 * (this.successRate / 100) +
          this.getRandomNumber(360 * (this.successRate / 100) + 10, 360) -
          360 * (this.successRate / 100);
        if (editVal <= 1442 && editVal >= 1432) {
          this.successRateTow = editVal - 10
        } else {
          this.successRateTow = editVal
        }
      }

      this.resData = data;
      // 更新金币
      this.$store.dispatch("userInfo");
      // 动画关闭的时候
      if (!this.animationOFF) {
        this.oprnClickIsShow = false;
        window.scrollTo(0, 1000);
        this.svgaIsShow = true;
        this.$store.dispatch("userInfo");
      } else {
        this.oprnClickIsShow = true;
        this.btnStatus = true;
        // 声音是六秒
        document.getElementById("luckey").play();
        setTimeout(() => {
          this.loing = true;
        }, 6000);
        setTimeout(() => {
          this.svgaIsShow = true;
          this.btnStatus = false;
        }, 8000);
      }
    },
    pitchOn(item) {
      this.SelectedPic = item.item;
      this.SelectedPicID = item.item.id;
      setTimeout(() => {
        this.selectClose = false;
      }, 500);
    },
    // 关闭结果
    svgaIsShowOff() {
      this.svgaIsShow = false;
      this.loing = false;
      this.ClickAgain = false;
    },
  },
};
</script>

<style scoped lang="scss">
.content-bottom-btn.shengji-btn {
  background-image: url('../../assets/img/ADT/zm/upgrade-now.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.choose-accessories.yixuanshipin {
  width: 350px;
  padding: 20px;
  margin: 60px auto 30px auto;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/img/ADT/zm/jewelry.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;

  .yixuanshipin-img {
    position: relative;
    top: -25px;
  }

  .yixuanshipin-name {
    position: absolute;
    bottom: 20px;

  }
}

@media (max-width: 600px) {
  .choose-accessories {
    padding-left: -60px !important;
    .left {
      width: 190px !important;
    height: 49.3px !important;
    }

    .animation  img {
      width: 30px !important;
      height: 30px !important;
    }
  }
  .content-bottom.biaochi {
    width: 100% !important;
    .success_rate {
      margin-top: 20px;
      > div {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .choose-accessories.yixuanshipin {
    margin: 75px auto 30px auto !important;
  }

  .content-bottom.biaochi {
    height: 120px !important;
  }
  .font-weight-bold.h1 {
    font-size: 50px !important;
  }
  }

.choose-accessories {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  height: auto !important;
  border: none !important;

  .left {
    // margin: 0 0 0 39%;
    width: 329px;
    height: 83.3px;
    background-image: url('../../assets/img/ADT/zm/choose-accessories-img.png');
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;

    &>span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .right {
    margin-left: 25px;

    img {
      cursor: pointer;
      width: 45px;
      height: 45px;
    }

    .animation {}

    .audio {}
  }
}

.outer-circle {
  top: 50%;
  left: 50%;
  position: absolute;
  background-size: 100% 100%;
  animation: rotate360z 8s linear infinite;
}

.inner-ring {
  top: 50%;
  left: 50%;
  position: absolute;
  background-size: 100% 100%;
  animation: rotate360f 8s linear infinite;
}

@-webkit-keyframes rotate360z {
  0% {
    transform: translate(-50%, -50%) rotate(0)
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn)
  }
}

@keyframes rotate360z {
  0% {
    transform: translate(-50%, -50%) rotate(0)
  }

  to {
    transform: translate(-50%, -50%) rotate(1turn)
  }
}

@-webkit-keyframes rotate360f {
  0% {
    transform: translate(-50%, -50%) rotate(1turn)
  }

  to {
    transform: translate(-50%, -50%) rotate(0)
  }
}

@keyframes rotate360f {
  0% {
    transform: translate(-50%, -50%) rotate(1turn)
  }

  to {
    transform: translate(-50%, -50%) rotate(0)
  }
}

.biaoti_img {
  position: relative;
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: center;
  >img {
    margin-left: 100px;
    height: 45px;
  }
  >div {
    position: absolute;
    left: 10%;
  }

  @media (max-width: 1000px) {
    height: 50px;
    img {
      height: 30px;
    }
  }
}

.lucky-title-top {
  margin-top: 50px;
  @media (max-width: 1000px) {
    font-size: 1.25rem !important;
    margin-top: 20px;
  }
}

.lucky-fontSize {
  @media (max-width: 1000px) {
    font-size: 0.8rem !important;
    position: relative;
    z-index: 9;

    .animationOFF {
      transform: scale(0.8);
    }
  }
}

.lucky-error-succeed {
  width: 100px;
  height: auto;
}

.lucky-error {
  background-image: url("../../assets/img/lucky/upgrade__bg__faild.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 30px;
}

.lucky-succeed {
  background-image: url("../../assets/img/lucky/upgrade__bg__succeed.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 30px;
}

.content {
  width: 96%;
  margin: 0 auto;

  .mp-content {
    margin-top: 3rem;
    @media (max-width: 600px) {
      margin-top: 2rem;
    }
  }

  &-top {
    width: 100%;
    height: 360px;
    background-size: 100% 100%;
    // background-image: url("../../assets/img/lucky/upgrade__skins.png");

    @media (max-width: 1200px) {
      height: auto;
      background-image: none;

      & .phone-therr {
        display: none !important;
      }
    }

    @media (max-width: 960px) {
      transform-origin: top;
      transform: scale(0.9);
    }

    @media (max-width: 750px) {
      transform-origin: top;
      transform: scale(0.9);
      flex-direction: column-reverse !important;
    }

    &-yuan {
      width: 500px;
      height: 360px;

      @media (max-width: 1000px) {
        transform: scale(0.8);
      }

      @media (max-width: 960px) {
        height: 210px;
      }

      &-xian {
        // transition: all 0.5s;
      }

      /* 这里动画多少秒就是多少秒 */
      &-kai-jiang {
        transition: all 6s;
      }
    }

    &-active {
      animation: animationName 3s infinite;
    }

    &-left {
      @media (max-width: 960px) {
        padding: 5px;
        margin-top: 65px;
        background-image: linear-gradient(0deg, #1d2027, #13161c);
        background-blend-mode: normal, normal;
        border-radius: 10px;
        border: 1px solid #1c2029;
        transform-origin: top;
        transform: scale(0.9);

        &-img {
          width: 120px !important;
        }
      }
    }
  }

  &-bottom {
    padding: 25px 100px 0;
    margin-top: 80px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    background-blend-mode: normal, normal;
    border-radius: 10px;
    border: 1px solid #1c2029;

    &-btn {
      margin: 27px auto 0;
      width: 360px;
      height: 72px;
      border-radius: 6px;
      background-size: contain;
      display: flex;
      font-size: 24px;
      background-color: #1E7A91;
      border-radius: 6px;
      border: 1px solid #1E7A91;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition-duration: 0.3s;
      -webkit-transition-duration: 0.3s;
      cursor: pointer;

      @media (max-width: 1000px) {
        font-size: 1rem;
        width: 60%;
        margin-top: 10px;
        height: 50px;
        background-size: 100% 100%;
      }
    }

    &-btn1 {
      opacity: 0.2;
      // background-image: url("../../assets/img/lucky/upgrade__btn__bg.png");
    }

    &-btn2 {
      // background-image: url("../../assets/img/lucky/upgrade__btn__bg__active.png");
    }

    .biaochi .content-bottom-slide {
      width: 100%;
      height: 88px;
      // background-color: #1A1B25;
      border-radius: 4px;
      padding: 30px 200px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      margin-top: 10px;

      &-slider-img {
        width: 103%;
        height: 40px;
        margin-left: -14px;

        @media (max-width: 1200px) {
          width: 101.5%;
          margin-left: 0;
          height: auto;
        }
      }
    }

    @media (max-width: 1200px) {
      margin-top: 75px;
      padding: 10px 10px 0;
      font-size: 0.8rem !important;
      height: 210px;

      &-slide {
        padding: 20px 20px;
        height: 55px;
      }
    }
  }
}

.content-bottom.biaochi {
  width: 538px;
  margin: 0 auto;
  // border: 1px solid #fff;
  background-image: url('../../assets/img/ADT/zm/biaochi-bg.png');
  background-size: 100%;
  background-repeat: no-repeat;

  .content-bottom-slide {
    @media (min-width: 600px) {
      margin-top: 20px;
    }
  }

  .content-bottom-slide-slider-img {
    width: 100%;
  }

}

/* 弹窗列表也面 */
.select {
  position: fixed;
  top: 15vh;
  left: 20%;
  width: 60%;
  z-index: 1002;

  &-top {
    background-color: #17192C;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: 0;
    /*width: 100%;// 由于下面列表是90  所以改成九十*/
    /*background-image: url("../../assets/img/lucky/shop_top.png");*/
    /*background-size: 100% 100%;*/
    .select_thing {
      font-family: MicrosoftYaHei-Bold;
    }
  }

  &-lucky {
    /*background-image: url("../../assets/img/lucky/img_tancuan.png");*/
    /*background-size: 100% 100%;*/
    background-color: #17192C;
    border-bottom-right-radius: 20px;
border-bottom-left-radius: 20px;
    border-top: 0;
  }

  @media (max-width: 1200px) {
    top: 5vh;
    width: 90%;
    left: 5%;

    &-slide {
      padding: 10px 5px;
    }
  }
}

.upDom {
  animation: animationName 5s infinite;
}

.anShanDian {
  animation: animationShanDian 0.3s infinite;
}

.awardListPop {
  width: 700px;
  height: 500px;
  left: calc(50% - 350px);
  top: calc(50% - 250px);
  z-index: 999;
  position: relative;


  @media (max-width: 1200px) {
    width: 98%;
    height: 50%;
    left: 1%;

  }

  &-box {
    width: 50%;
    margin: 0 auto;

    @media (max-width: 1200px) {
      overflow: auto;
      background: #000;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.awardListPop-box {
  margin-bottom: 30px;
}

@keyframes animationName {
  0% {
    transform: translateY(-20%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-20%);
  }
}

@keyframes animationShanDian {
  10% {
    transform: rotate(10deg) scale(0);
  }

  10% {
    transform: rotate(45deg) scale(0.5);
  }

  20% {
    transform: rotate(90deg) scale(0);
  }

  30% {
    transform: rotate(125deg) scale(0.6);
  }

  40% {
    transform: rotate(160deg) scale(0);
  }

  50% {
    transform: rotate(185deg) scale(0.8);
  }

  60% {
    transform: rotate(220deg) scale(0);
  }

  70% {
    transform: rotate(255deg) scale(0.4);
  }

  80% {
    transform: rotate(290deg) scale(0);
  }

  0% {
    transform: rotate(300deg) scale(0.3);
  }

  90% {
    transform: rotate(330deg) scale(0);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }

}

// notice 
.notice {
  width: 700px; left: calc(50% - 350px); top: 15vh;z-index: 9999;
}
</style>
