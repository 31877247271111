<template>
    <div class="act_head">
        <img class="roll-sign" src="../../../assets/img/ADT/roll/gf.png" alt="">
        <div class="head_l">
            <div class="head">
                <img src="../../../assets/img/ADT/roll/roll-sculpture.png" alt="">
            </div>
            <p style="color:#fff;" class="title common-hidden m-none">{{ details.name }}</p>

            <div class="pc-none phone-class-head">
                <p class="title common-hidden">{{ details.name }}</p>
                <div class="pc-none flex align-items-center">
                    <div class="info-both flex align-items-center">
                        <div class="txt" style="font-size: 14px;color: #FFFFFF;opacity: 0.3;">价值：</div>
                        <div class="total">
                            <div class="cp cfe4760 flex align-items-center" style="height: 20px; font-size: 20px;">
                                <money />
                                <span class="cp-num" style="font-size: 16px;font-weight: bold;color: #F9C545;">{{
                                    details.award_bean }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-both flex align-items-center head-right">
                        <div class="txt" style="font-size: 14px;color: #FFFFFF;opacity: 0.3;">饰品：</div>
                        <div class="num common-hidden" style="font-size: 16px;font-weight: bold;color: #F9C545;">
                            {{ details.awardCount || 0 }}
                        </div>

                    </div>
                </div>
            </div>

            <div class="foot m-none">
                <!-- <div class="circle">F</div> -->
                <div class="info">
                    <div class="info-both">
                        <div class="txt" style="font-size: 14px;color: #FFFFFF;opacity: 0.3;">价值：</div>
                        <div class="total">
                            <div class="cp cfe4760" style="height: 20px; font-size: 20px;">
                                <money />
                                <span class="cp-num" style="font-size: 16px;font-weight: bold;color: #F9C545;">{{
                                    details.award_bean }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-both">
                        <div class="txt" style="font-size: 14px;color: #FFFFFF;opacity: 0.3;">饰品：</div>
                        <div class="num common-hidden" style="font-size: 16px;font-weight: bold;color: #F9C545;">
                            {{ details.awardCount || 0 }}
                        </div>

                    </div>
                    <div class="info-both">

                        <div class="txt" style="font-size: 14px;color: #FFFFFF;opacity: 0.3;">人员：</div>
                        <div class="num common-hidden">
                            <span style="font-size: 16px;font-weight: bold;color: #F9C545;">{{ details.join_number
                            }}</span>/{{ details.people_number }}
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="line"></div>
        <div class="head_r">
            <div class="info">
                <div class="right-info-head">
                    <span class="span1 m-none">参与条件：</span><span class="span2 m-none">{{ details.describe }}</span>

                    <div class="pc-none">
                        <div style="font-size: 14px;margin-bottom: 10px;color: #2FA9CB;font-weight: bold;">参与条件</div>
                        <div style="font-size: 12px;color: #2FA9CB;margin-bottom: 20px;">{{ details.describe }}</div>
                    </div>
                </div>

                <div class="ongoing">
                    <div class="ongoing-txt">
                        <img src="../../../assets/img/ADT/roll/djs.png">

                    </div>
                    <div class="time">
                        <div class="time-main" :class="{ 'active': !days }">
                            <div class="time-main-top">
                                {{ checkTime(days) }}
                            </div>
                            <div class="time-main-bottom">天</div>
                        </div>
                        <div class="time-symbol">:</div>
                        <div class="time-main" :class="{ 'active': !hours }">
                            <div class="time-main-top">
                                {{ checkTime(hours) }}
                            </div>
                            <div class="time-main-bottom">时</div>
                        </div>
                        <div class="time-symbol">:</div>
                        <div class="time-main" :class="{ 'active': !minutes }">
                            <div class="time-main-top">
                                {{ checkTime(minutes) }}
                            </div>
                            <div class="time-main-bottom">分</div>
                        </div>
                        <div class="time-symbol">:</div>
                        <div class="time-main" :class="{ 'active': !seconds }">
                            <div class="time-main-top">
                                {{ checkTime(seconds) }}
                            </div>
                            <div class="time-main-bottom">秒</div>
                        </div>
                    </div>
                </div>

                <div class="jrfj" @click="AddRollHome">
                    <img src="../../../assets/img/ADT/roll/jrfj.png" alt="">
                    <span v-if="token != undefined && details.status != 1 && !roomStatus">加入房间</span>
                    <span v-if="details.status != 1 && roomStatus==1">已加入</span>
                    <span v-if="token == undefined && details.status != 1">请登录</span>
                    <span v-if="details.status == 1">已结束</span>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        token: {
            type: [String,Number],
            default: '' 
        },
        roomStatus: {
            type: [String,Number],
            default: ''
        },
        details: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            timer: '',
        }
    },
    methods: {
        AddRollHome(){
            this.$parent.AddRollHome()
        },
        checkTime(i) { //将0-9的数字前面加上0，例1变为01 
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        },
        countTime() {
            //获取当前时间
            let date = new Date();
            let now = date.getTime();
            //设置截止时间
            let str = this.details.end_time;
            let endDate = new Date(str);
            let end = endDate.getTime();
            //时间差
            let leftTime = end - now;
            //定义变量 d,h,m,s保存倒计时的时间
            if (leftTime >= 0) {
                this.days = Math.floor(leftTime / 1000 / 60 / 60 / 24);
                this.hours = Math.floor(leftTime / 1000 / 60 / 60 % 24);
                this.minutes = Math.floor(leftTime / 1000 / 60 % 60);
                this.seconds = Math.floor(leftTime / 1000 % 60);
            } else {
                clearInterval(this.timer); //为负数时清掉定时器
            }
            //递归每秒调用countTime方法，显示动态时间效果
            if (this.days == 0 && this.hours == 0 && this.minutes == 0 && this.seconds == 0) {
                clearInterval(this.timer); //时间倒计完清掉计时器，并且跳转，这里根据自己的需求写就好了
            }

        },
    },
    created() {
        this.timer = setInterval(this.countTime, 1000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style lang="scss">
@media (max-width:600px) {
    .pc-none.phone-class-head {

        .txt,
        .cp-num,
        .num {
            font-size: 12px !important;
            color: #fff;
        }

        .head-right {
            margin-left: 15px;
        }
    }
}

.act_head {
    position: relative;
    width: 100%;
    height: 326px;
    display: flex;
    align-items: center;
    background-color: rgba(25, 53, 74, .6);
    border-radius: 20px;

    @media (max-width:600px) {
        padding-left: 20px;
        background-image: url(../../../assets/img/ADT/roll/roll-top-bg.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;

 
    }

    .roll-sign {
        width: 63px;
        position: absolute;
        right: 0;
        top: 0;
    }

    @media (max-width:1300px) {
        border-radius: 10px;
        width: calc(100% - 28px);
        height: auto;
        margin: 0 14px 20px 14px;
        display: block;
        // background-color: #19354A;
        padding-bottom: 80px;
  
    }

    .head_l {
        flex-shrink: 0;
        width: 370px;
        height: 100%;
        // background-color: #19354A;
        border-radius: 16px 0 0 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 25px;

        @media (max-width:1300px) {
            padding-top: 20px;
            width: 100%;
            background: none;
        }


        @media (max-width:600px) {

            display: flex !important;
            flex-direction: row !important;
            align-items: center !important;

            .head {
                width: 45px !important;
                height: 45px !important;
                margin-right: 10px;
            }

            p.title.common-hidden {
                font-size: 18px;
                font-weight: bold;
                color: #FFFFFF;
            }

        }

        .head {
            width: 70px;
            height: 70px;
            overflow: hidden;

            @media (max-width:1300px) {
                width: 70px;
                height: 70px;
                border-radius: 14px;
                margin-bottom: 10px;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;

            }
        }

        .foot {
            min-width: 270px;
            height: 64px;
            // background-color: #19354A;
            background-blend-mode: normal, normal;
            border-radius: 32px 14px 14px 32px;
            display: flex;
            align-items: center;

            .circle {
                flex-shrink: 0;
                width: 64px;
                height: 100%;
                border: 3px solid rgba(254, 71, 96, .3);
                border-radius: 50%;
                position: relative;
                text-align: center;
                line-height: 58px;
                font-family: Montserrat-SemiBold;
                font-size: 18px;
            }

            .info {
                flex: 1;
                min-width: 0;
                padding: 0 10px;
                display: flex;
                flex-direction: column;


                .info-both {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    .txt {
                        flex-shrink: 0;
                        font-size: 12px;
                        color: #8b9695;
                    }

                    .total {
                        flex: 1;
                        min-width: 0;
                    }

                    .cp.cfe4760 {
                        color: #F9C545;

                        .cp-img {
                            flex-shrink: 0;
                            height: 100%;
                            -o-object-fit: contain;
                            object-fit: contain;
                        }
                    }

                    .cp {
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                    }

                    :first-child {
                        margin-bottom: 3px;
                    }
                }

                .line {
                    flex-shrink: 0;
                    margin: 0 10px;
                    width: 1px;
                    height: 15px;
                    background-color: #253e3b;
                }
            }
        }
    }

    .title {
        flex-shrink: 0;
        font-size: 26px;
        color: #F9C545;
        line-height: 1;
        margin-bottom: 45px;
        font-weight: bold;

        @media (max-width:1300px) {
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .line {
        width: 1px;
        height: 80%;
        background-color: rgba(47, 169, 203, .26);
    }

    .head_r {
        // border-left: 1px solid #2FA9CB;
        flex: 1;
        min-width: 0;
        margin-left: 1px;
        // background-color: #19354A;
        background-blend-mode: normal, normal;
        border-radius: 0 16px 16px 0;
        padding: 0 54px 0 70px;
        display: flex;
        position: relative;

        @media (max-width:1300px) {
            display: block;
            padding: 10px;
        }

        .info {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            height: 237px;

            .right-info-head {
                .span1 {
                    font-size: 18px;
                    font-weight: bold;
                    color: #999999;
                    line-height: 26px;
                }

                .span2 {
                    font-size: 18px;

                    color: #2FA9CB;

                }

            }

            @media (max-width:1300px) {
                width: 100%;
                height: auto;
            }

            .detail {
                flex: 1;
                min-width: 0;
                width: 744px;
                color: #cecfd6;
                font-size: 15px;
                line-height: 26px;
                overflow: hidden;

                @media (max-width:1300px) {
                    line-height: 28px !important;
                    font-size: 16px;
                    width: 100%;
                }
            }

            @media (max-width:1300px) {
                .detail.time {
                    font-size: 12px;
                }
            }

            .need {
                background-image: none;
                border-color: #ff445d;
                color: #ff445d;
                box-shadow: inset 0 0 5px 0 #ff445d, 0 0 5px 0 #ff445d;
                -webkit-box-shadow: 0 0 5px 0 #ff445d inset, 0 0 5px 0 #ff445d;
                -moz-box-shadow: 0 0 5px 0 #ff445d inset, 0 0 5px 0 #ff445d;
            }

            .btn {
                margin-top: 20px;
                flex-shrink: 0;
                min-width: 340px;
                max-width: 100%;
                background-blend-mode: normal, normal;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 700;
                line-height: 36px;
                text-align: center;

                @media (max-width:1300px) {
                    width: 100%;
                }
            }

            .ongoing {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .jrfj {
                cursor: pointer;

                &>img {
                    height: 65px;
                    position: absolute;
                    bottom: -45px;
                    left: 100px;
                }

                &>span {
                    position: absolute;
                    bottom: -25px;
                    left: 233px;
                    font-size: 23px;
                    color: #FFFFFF;
                }

                @media (max-width:600px) {
                    &>img {
                        height: 40px;
    position: absolute;
    bottom: -80px;
    left: 50px;
                }

                &>span {
                    position: absolute;
                    bottom: -68px;
    left: 130px;
    font-size: 14px;
    color: #FFFFFF;
                }
                }
            }

            .ongoing-txt {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    width: 90px;
                    margin-bottom: 8px;
                    margin-right: 25px;
                }

                span {
                    font-size: 14px;
                    line-height: 1;
                    color: #8b8c97;
                }
                @media (max-width:600px) {
                    img {
                    width: 60px;
                    margin-bottom: 0px;
                    margin-right: 15px;
                } 
                }
            }

            .time {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .time-main {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                padding: 10px 20px;
                border: 2px solid #175B6C;
                border-radius: 4px;
                background-color: rgba(29, 30, 49, 1);


                @media (max-width:1300px) {
                    width: 40px;
                    height: 50px;
                }

                .time-main-top {
                    font-size: 26px;
                    font-family: Montserrat-ExtraBold;
                    letter-spacing: 1px;
                    color: #F9C545;
                    line-height: 1;
                    margin-bottom: 3px;
                    font-weight: bold;

                    @media (max-width:1300px) {
                        font-size: 16px;
                    }
                    @media (max-width:600px) {
                        font-size: 14px;
                    }
                }

                .time-main-bottom {
                    margin-top: 12px;
                    line-height: 1;
                    font-size: 18px;
                    color: #FFFFFF;
                    @media (max-width:600px) {
                        font-size: 14px;
                        margin-top: 2px;
                    }
                }
            }

            .time-main.active .time-main-top {
                color: #484a55;
            }

            .time-symbol {
                margin: 0 7px;
                font-size: 16px;
                color: #8c88b1;
                opacity: .4;
            }

        }

    }

    .foot-m {
        width: calc(100% - 40px);
        margin: 0 20px;
        height: 4.2rem;
        background-image: linear-gradient(90deg, #39191e, #31181d), linear-gradient(#21222c, #21222c);
        background-blend-mode: normal, normal;
        border-radius: .8rem;
        display: flex;
        margin-bottom: 10px;

        .foot-li {
            flex: 1;
            min-width: 0;
            height: 100%;
            padding: .4rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-right: 1px solid #253e3b;

            .txt {
                height: 2rem;
                line-height: 2rem !important;
                font-size: 16px;
                color: #8b9695;
            }

            .skin {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAmdJREFUaEPtWettwjAQPptFYJPyp2GLmkkKk+BuQfqnYZJmEeLqjI0c1888FGgdCQmRI3ff3XevmMCTX+TJ7Yf/DeD6yt4JgRcA+WkFQEMJXACgIWfemtEVO7buOnjD3wiBA8rL/whoVp/8OJQJgyKAxggBXwCw9ihuCYEjOXOO98WOvSh5n50ovydn3uQCyQaQYIy2Ab3LKYVLxPi7/BAQ2QC6iqHnkTJ4NcrT0nOaJooiv5wpBBwohQ9NL4d8S2u+yYlCFgCxY0wIOGnjac23LmWWnBRB431cN51CCGxzqJQF4FqxEwFgKhGRs5LjrsuKFNCae3WZtAwBdekZA2BjVxpTgapQWG1AAPBVzfc+sKoofMciOxqAFep0AAH6aKO6iokUsDaIvAjc6r70aoyrvQhEAPQoFInWKAA5inIA5OTWWADYwLAKyc4bqtupACz+z1tGVa03u+q9WdmlLwbA1TNitExKYqUYS6VvTMjpM1PJyrkKO7vdS3pJbHptKs1TP8fuEz0AXcWwFj+S5134e3liA5C1OKVMxjwby4HY/837oUT3Agi1/hTlUwJAfbrR4XfTtgLAF40SAcsz2RRSXdY7LsfyYMoIWEkczAFz24rZuNh9bxK7NqnFrPQotneLXhWK7bRLg3Fta959wJc0qSCmyAFzMvD1pQJgzj5QIqC9O2QuKjlwG+Du431J4tTyqeUKhQqF1Ks+ewNKpVKhUKHQX6LQkO1sbA6EtjAzD0PT6CNtZ96XvqFjH/M8LLX4zCIXYkDwgEOtmHg4vdjrRtcL3SQKzeLKGR6adcQ0g/7Rj3x6AD9/Um9PsBiAzgAAAABJRU5ErkJggg==) no-repeat 0;
                background-size: 1.6rem 1.6rem;
                padding-left: 2rem;
            }

            .num {
                font-size: 14px;
                color: #fff;
            }

            .cp.cfe4760 {
                color: #F9C545;
            }

            .cp.size26 {
                height: 2rem;
                font-size: 14px;
            }

            .cp {
                display: flex;
                align-items: center;
                max-width: 100%;
            }

            .cp-img {
                width: 1rem;
            }

            .people {
                background: url("../../../assets/img/tu/rollIndexPeople.png") no-repeat 0;
                background-size: 1.6rem 1.6rem;
                padding-left: 2rem;
            }
        }

        .foot-li:last-child {
            border-right: none;
        }
    }
}</style>