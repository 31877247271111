<template>
  <div class="box-table">
    <div class="top">
      <div class="left">
        <h2>奖励规则</h2>
      </div>
    </div>
    <div class="bot">
      <div class="fl-table">
        <div style="width: 100%;">
          <div class="table-head text-danger table-tr" style="font-size: 12px">
            <div class="table-td">
              <div class="cell">奖励规则</div>
            </div>
            <div class="table-td">
              <div class="cell">下级充值</div>
            </div>
            <div class="table-td">
              <div class="cell">可获收益</div>
            </div>
            <div class="table-td">
              <div class="cell">佣金领取</div>
            </div>
            <div class="table-td" v-if="sUserAgent == 'web'">
              <div class="cell">收益说明</div>
            </div>
          </div>
          <div class="table-tr table-data-tr" style="" v-for="(item, index) of list" :key="index">
            <div class="table-td">
              <div class="cell">
                <img v-if="item.level" style="height: 20px;vertical-align: middle;"
                     :src="require(`@/assets/img/ADT/common/lv-max/v${item.level}.png`)" alt="">
              </div>
            </div>
            <div class="table-td">
              <div class="cell">
                {{ item.invite_total }}
              </div>
            </div>
            <div class="table-td">

              <div class="cell">
                <span class="money-img">
                  <money/>
                </span>
                <span style="vertical-align: middle;">{{ item.amount }}</span>
              </div>
            </div>
            <div class="table-td">
              <div class="cell">
                <div class="nth">
                  <div class="dt-draw-box" v-if="item.userReward && item.userReward.id">
                    <div v-if="item?.userReward?.is_supply === 1" class="dt-draw disable">
                      已领取
                    </div>
                    <div v-else class="dt-draw" @click="getViprewards(item?.userReward?.id)">
                      领取
                    </div>
                  </div>
                  <div v-else>
                    <!-- <img v-if="item.level <= vipLevelReward" src="../../assets/img/news/ok.png" alt="" /> -->
                    <span style="color: #FFFFFF;">未满足</span>
                    <!-- <img v-else src="../../assets/img/news/dt/no.png" alt="" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="table-td" :class="sUserAgent != 'web' ? 'mobile-td' : ''">
              <div class="cell">{{ item.description }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import $api from '@/api/user/index'
import {mapState} from "vuex";
import sUserAgent from "@/assets/js/common";

export default {
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(['user_info', 'web_info']),
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  mounted() {
    // this.getPromote()
  },
  methods: {
    // 推广数据
    async getPromote() {
      try {
        let {data, code} = await $api.getPromote()
        if (code == 500)
          return
        this.list = data
      } catch (e) {
        console.log(e)
      }
    },

    // 领取福利
    getViprewards(id) {
      $api.getPromoteReward({id}).then(({code, message}) => {
        if (code == 200) {
          this.$notify.closeAll();
          this.$notify({
            message: message,
            position: "top-left",
          });
          this.getPromote(); // 重新获取列表数据
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
// 表格
.box-table {
  // background-image: url(../../assets/img/ADT/user/bot.png);
  background-image: url(../assets/img/ADT/user/promotion-bg.png);
  background-position: center top;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 921px;
  //position: absolute;
  //height: 430px;
  //bottom: 2%;
  //left: 50%;
  //margin-left: -510px;
  background-size: 100% 100%;
  padding: 40px 40px 40px;

  @media (max-width: 600px) {
    top: 65%;
    width: 100%;
    //height: 360px;
    left: inherit;
    background-image: url(../assets/img/ADT/user/user-invite-bg-mobile.png);
    margin-left: inherit;
    margin: 0 auto;
    background-size: 100% 100%;
    padding: 0;
    padding-bottom: 18px;
  }

  > .top {
    //padding: 0 40px;
    margin: 0 0 24px 0;
    display: flex;
    justify-content: space-between;
    //padding-bottom: 20px;

    @media (max-width: 600px) {
      padding: 12px 0 12px 14px;
      margin: 0;
      flex-direction: column;
      align-items: flex-start;

    }

    > .left {
      flex: 2;

      > h2 {
        color: #FFFFFF;
        font-weight: bold;
        font-size: 24px;
        font-style: italic;

        @media (max-width: 600px) {
          // display: none;
          font-size: 14px;
          font-style: normal;
        }
      }

      > p {
        display: flex;
        align-items: center;
        color: #F9C545;
        font-style: italic;
        font-family: Monaco;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;

        @media (max-width: 600px) {
          font-size: 14px;
          margin-bottom: 4px;

          > .upb {
            display: none;
          }
        }

        > img {
          margin: 0 4px;

          @media (max-width: 600px) {
            margin-right: 8px;
          }
        }
      }

      > .tip {
        color: #ccc;

        font-weight: 400;
        font-size: 14px;
        padding-left: 24px;

        @media (max-width: 600px) {
          margin-bottom: 16px;
          font-size: 12px;
          color: #ddd;

        }
      }
    }

    > .right {
      flex: 1;


      > h2 {
        color: #F9C546;
        font-weight: bold;
        font-size: 24px;
        font-style: italic;
        margin-bottom: 20px;

        @media (max-width: 600px) {
          font-size: 14px;
          padding: 0 10%;
          margin-bottom: 10px;
          margin-top: 8px;
          color: #ddd;
          font-style: italic;
        }
      }

      > .box {
        width: 400px;
        display: flex;
        justify-content: space-between;
        background-color: #8bc9ff2f;
        border-radius: 8px;
        padding: 20px;

        @media (max-width: 600px) {
          flex: 0 0 80%;
          max-width: 80%;
          align-items: center;
          margin: 0 auto;
          padding: 14px 20px;
        }

        > a {
          flex: 1;
          text-align: center;
          border-right: solid 1px #7978b2a7;

          > h2 {
            color: #F9C545;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Monaco;
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: bold;

            @media (max-width: 600px) {
              font-size: 16px;
            }

            img {
              margin-right: 4px;

              @media (max-width: 600px) {
                width: 16px;
              }
            }

          }

          > p {
            @media (max-width: 600px) {
              font-size: 12px;

            }
          }
        }

        > a:last-child {
          border: none;
        }
      }
    }
  }

  > .bot {
    // background-image: url(../../assets/img/ADT/user/hh.png);
    // background-size: 940px 100px;
    width: 100%;
    // padding: 20px 40px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;

    @media (max-width: 600px) {
      //max-width: 350px;
      width: 98%;
      min-width: 320px;
      // display: none;
    }

    .fl-table {
      // border: 1px solid #1B3B53;
      // border-radius: 6px;
      text-align: center;
      height: 100%;
      overflow-y: auto;
      font-size: 14px;
      @media (max-width: 600px) {
        //height: 236px;
      }

      .table-tr {
        height: 55px;
        background: rgba(255, 255, 255, .05);
        border-bottom: 2px solid #1C2037;
        display: flex;
        width: 100%;
        color: #fff;
        position: relative;

        &.table-data-tr {
          @media (max-width: 600px) {
            flex-wrap: wrap;
            height: 57px;
            background: rgba(255, 255, 255, 0);
          }
        }

        .table-td {
          display: flex;
          width: 100px;
          align-items: center;
          justify-content: center;
          @media (max-width: 600px) {
            flex: 1 1 0;
            height: 30px;
          }

          &:nth-last-child(1) {
            flex-grow: 1;
          }

          &.mobile-td {
            width: 100%;
            position: absolute;
            bottom: 0;
            color: #736C99;
            font-size: 12px;
            padding-left: 20px;
            justify-content: flex-start;
          }
        }

        .cell {
          //display: inline-block;
          font-family: Oxanium, "Microsoft YaHei" !important;
          display: flex;
          align-items: center;
          justify-content: center;

          .money-img {
            display: inline-flex;
            //vertical-align: middle;
            margin-top: -2px;
            line-height: 1;
          }

          .dt-draw {
            width: 56px;
            height: 26px;
            line-height: 26px;
            background: #F7C445;
            border-radius: 13px;
            color: #331E71;
            font-size: 14px;

            &.disable {
              background: #787290;
              color: #B6B6B6;
            }
          }
        }

        &.table-head {
          height: 40px;
          line-height: 40px;
          background: #00B9D4;
          @media (max-width: 600px) {
            background: #413D91;
            height: 37px;
          }

          .table-td {
            @media (max-width: 600px) {
              height: 37px;
            }
          }

          .cell {
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            @media (max-width: 600px) {
              color: #BBAAEA;
              font-size: 12px;
            }
          }
        }
      }
    }

    > div {
      flex: 1;
      position: relative;

      > h2 {
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
      }

      > div {
        > input {
          height: 44px;
          background-color: #272448;
          width: 95%;
          color: #ccc;
          font-family: Monaco;
          font-size: 14px;
          text-indent: 10px;
          border-radius: 4px;
        }

        > a {
          position: absolute;
          height: 36px;
          width: 80px;
          background-color: #724DFC;
          position: absolute;
          right: 24px;
          top: 30px;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
