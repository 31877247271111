<template>
    <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class=" rounded overflow-hidden cursor-pointer" :style="'width:100%'" >
        <div class="bg-size  bg-repeat position-relative d-flex flex-wrap
        justify-content-center align-items-center" >
            <div class="position-relative now-bg"
                 style="width: 100%;padding: 25px;">
                 <div class="text-overflow-1">{{resData.name}}{{resData.odds_percent}}</div>
             
                <!-- <div class="position-absolute bglogo-e" style="width: 80px; height: 80px;z-index:2;left:20%;top:20%"></div> -->
               
          <div class="img-box flex" style="justify-content: space-between;
    align-items: center;margin-top: 25px;
">
            <img class="wuqi-img"
                     :src="resData.cover" alt="">
                <div class="footer-info">
                    <div style="font-size: 16px" class="mt-2 ">{{resData.dura_alias || '暂无'}}</div>
                    <div style="margin-top: 12px;color: #F9C545;" class="font-weight-bold white-space-no flex align-items-center"><money /> {{resData.bean}}</div>
<!--                    <div style="font-size: 12px" class="mt-2 "><i class="el-icon-star-on mr-2"></i>{{resData.odds_percent}}</div>-->
                    
                </div>
          </div>
            </div>
        </div>

        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            // 传输武器整体宽度
            width:{
                type:String,
                default:"14%"
            },
            // 武器显示的高度，宽度和整体宽度是一样的
            heightWeapon:{
                type:String,
                default:"150px"
            },
            // 传输背景 style 对象形式传输
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            // 武器对应的宽度 高度 带单位 style 对象形式传输
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
            //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
.wuqi-img {
    width: 119px;

}

@media (max-width: 600px) {
    .wuqi-img {
    width: 65px;

}
.position-relative.now-bg,
.footer-info div {
    font-size: 12px !important;
}
.position-relative.now-bg {
    padding: 5px !important;
    
}

.img-box.flex {
    justify-content: space-around !important;
}
.text-overflow-1 {
    padding-left: 15px;
    text-align: center !important;
}
        }


.position-relative.now-bg {
    background: linear-gradient(0deg,#2e3e6d,#2e3e6d 33%,#212948 66%,#212948);
    
border-radius: 8px;
}
    .rounded-img{
        height:80%;
        @media (max-width: 1000px) {
            height: 40%;
            width: 40%;
        }
    }
    .cover-content{
    }
    .cover-cover{
        & img{
            transition: all .5s;
        }
        &:hover img{
            transform: translateY(-20px)
        }
    }
    .weapon{
        &-name{
            padding: 10px 0;
        }
    }
</style>
