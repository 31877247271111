<template>
  <div class="jackpot">
    <div class="jackpot_main flex" v-if="item.length">
        <div class="jackpot_main_item start " @click="awardsView(item[0])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg1"><img :src="item[0].cover" alt="" class="prize"></div>
            </div>
            <img src="../../../assets/love/5.png" width="50" />
        </div>
        <div class="jackpot_main_item " @click="awardsView(item[4])">
        <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[4].cover" alt="" class="prize">
                </div>
            </div>
            
        </div>
        <div class="jackpot_main_item " @click="awardsView(item[5])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[5].cover" fit="fill" alt="" class="prize">
                </div>
            </div>
            
        </div>
        <div class="jackpot_main_item end" @click="awardsView(item[6])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[6].cover" alt="" class="prize">
                </div>
            </div>
            
        </div>
    </div>
    <div class="jackpot_main flex" v-if="item.length">
        <div class="jackpot_main_item start " @click="awardsView(item[1])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg2">
                <img :src="item[1].cover" alt="" class="prize">
                </div>
            </div>
            <img src="../../../assets/love/1.png" width="50" />
        </div>
        <div class="jackpot_main_item" @click="awardsView(item[7])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[7].cover" alt="" class="prize">
                </div>
            </div>
        </div>
        <div class="jackpot_main_item " @click="awardsView(item[8])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[8].cover" fit="fill" alt="" class="prize">
                </div>
            </div>
        </div>
        <div class="jackpot_main_item end" @click="awardsView(item[9])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[9].cover" alt="" class="prize">
                </div>
            </div>
        </div>
    </div>
    <div class="jackpot_main flex" v-if="item.length">
        <div class="jackpot_main_item start " @click="awardsView(item[2])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg2">
                <img :src="item[2].cover" alt="" class="prize">
                </div>
            </div>
            <img src="../../../assets/love/3.png" width="50" />
        </div>
        <div class="jackpot_main_item" @click="awardsView(item[10])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[10].cover" alt="" class="prize">
                </div>
            </div>
        </div>
        <div class="jackpot_main_item " @click="awardsView(item[11])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg4">
                <img :src="item[11].cover" fit="fill" alt="" class="prize">
                </div>
            </div>
        </div>
        <div class="jackpot_main_item end" @click="awardsView(item[3])">
            <div class="text-center item_text"></div>
            <div class="img_box">
                <div class="img_bg img_bg3">
                <img :src="item[3].cover" alt="" class="prize">
                </div>
            </div>
            <img src="../../../assets/love/4.png" width="50" />
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    item:{
      type:Array,
      default:()=>[]
    }
  },
  methods:{
    awardsView(data){
      this.$emit('awardsView',data)
    }
  }
}
</script>

<style>

</style>