<template>
  <div>
    <div class="content mt-3 position-relative">
      <!-- <div class="h3 text-white font-weight-bold text-center my-5">
        <div>{{ $route.meta.title }}</div>
      </div> -->
      <!-- 对战回合 :class="detailsData.status == 2?'filter-grayscale-1':''" -->
      <!-- 声音开关 - 定位 -->
      <div class="position-absolute d-flex" style="right:0;top:20px">
        <!-- <div class="cursor-pointer common-btn-main mr-2 bg-blue px-4 py-2 rounded" style="line-height:20px"
          @click="$router.push(`/FmysteryBox?typeurl=${urlType}&hisPageRecord=${hisPageRecord}`)">
          返回
        </div> -->
        <!-- <el-switch class="mr-3"
                         v-model="voiceSwitch"
                         active-color="#ff4949"
                         inactive-color="#979797">
              </el-switch>{{voiceSwitch?'打开声音':'关闭声音'}} -->
      </div>
      <div class="d-flex justify-content-between align-items-center py-3 px-4 pk-top-header">
        <div class="font-weight-bold">
          <span class="return" @click="$router.push(`/FmysteryBox?typeurl=${urlType}&hisPageRecord=${hisPageRecord}`)">返回</span>
          <span>房间编号: {{ game_arena_id }}</span>
        </div>
        <div :class="!detailsData.game_type && 'game_type_0' || 'game_type_1'">{{ detailsData.game_type && '地转' || '天旋' }}</div>
        <!-- <div class="" style="font-size: 12px">{{ detailsData.box_num }}轮
        </div> -->
        <!-- <div class="cursor-pointer" v-if="detailsData.status == 2" @click="$router.push(`/FmysteryBox`)">完成</div>
        <div class="cursor-pointer" v-if="detailsData.status == 3">已取消</div>
        <div v-if="detailsData.status == 0">等待中...</div> -->
        <div>总价: {{ detailsData.total_bean }}</div>
      </div>

      <div class="w-100 pt-4 history_bg" style="">
        <div class="content-bout-box  d-flex justify-content-center align-items-center"
          :class="detailsData.status == 2 ? '' : ''">
          
          <div v-for="(item, index) in detailsData.game_arena_box" :key="index" @click="lockBoxGun(item, index)"
            class="text-center content-bout-box-item weaponBox"
            :style="index + 1 < slotIndex && activeStatus && 'filter: grayscale(100%)'"
            :class="index + 1 == slotIndex ? detailsData.status == 2 ? '' : (activeStatus ? 'active active_bg' : '') : ''">
            <!-- <div class="content-bout-box-item-index m-auto">{{ index + 1 }}</div> -->
            <div
              style="width:130px;height:156px;border-radius: 4px;margin-top: 8px;overflow: hidden;">
              <img width="130px" class="" :src="item.box?.type == 1 ? item.box?.intact_cover : item.box?.cover" alt="">
            </div>
            <img class="weapon" v-if="item.box?.type == 0" :src="item.box?.weapon_cover" alt="">
          </div>
        </div>
      </div>

      <div class="slotMatchina">
        <div v-for="(item, index) in detailsData.game_arena_player" :key="index">
        <div :class="item?.user.id == user_info.id ? 'slotDongF_me': 'slotDongF'" v-if="!moneyJieShu" :style="{ height: detailsData.status == 1 ? '200px' : '0px' }">
          <div class="line_light" v-if="detailsData.status == 1"></div>
          <div class="slotDong" v-if="!moneyJieShu" :style="{ height: detailsData.status == 1 ? '300px' : '0px' }">
            <div :class="`${slotAn ? 'slotListBox' : 'slotListBox1'}`"
              :style="{ transform: `translateY(${slotAn ? '-' + slotY + 'px' : '0px'})` }">
              <div :style="{ display: slotAn ? 'block' : 'none' }" class="ls"
                v-for="(child, childIndex) in slotList[index]" :key="childIndex">
                <img :src="child?.skins?.cover" height="100" />
                <p>{{ child?.skins?.name.replace(/\s*/g, "") }}</p>
                <p class="slotMoney"><img src="@/assets/img/shandian_ceshi.png" height="20" />{{ child?.skins?.bean }}</p>
              </div>
              <div :style="{ display: !slotAn && slotIndex != 0 ? 'block' : 'none' }" class="ls">
                <img
                  :src="detailsData.game_arena_player[index]?.game_award?.[slotIndex - (slotStatus == 1 ? 2 : 1)]?.skins?.cover"
                  height="100" />
                <p>{{
                  detailsData.game_arena_player[index]?.game_award?.[slotIndex - (slotStatus == 1 ? 2 :
                    1)]?.skins?.name.replace(/\s*/g,
                      "") }}</p>
                <p class="slotMoney"><img src="@/assets/img/shandian_ceshi.png" height="20" />{{
                  detailsData.game_arena_player[index]?.game_award?.[slotIndex - (slotStatus == 1 ? 2 : 1)]?.skins?.bean
                }}</p>

              </div>
            </div>
          </div>
        </div>

          <!-- 包裹的div 是定义失败成功的 -->
          <div v-if="item" :class="item.success ? '' : detailsData.status == 2 ? '' : ''"
            class="overflow-hidden slotMatchinaSuccess">
            <!-- 老虎机抽奖页面 A里面的div顺序不要动  -->
            <div class="content-pk-item-pk d-fle pk_item"
              :style="{ height: detailsData.status == 1 && !moneyJieShu ? '10px' : '200px' }">
              <div class="content-pk-item-pk-box">
                <div class="text-center content-pk-item-pk-box-item an-item position-relative" :name="t.a"
                  v-for="(t, index) in item.game_arena_box[indexBox].box.contains" :key="index">
                  <div class="position-absolute" style="width: 90%; height: 90%;"></div>
                  <!-- 结束的时候 -->
                  <div v-if="detailsData.status == 2 && item.success && index==0" class="content_success">
                    <img class="victory" src="@/assets/img/pk/victory.png" alt="">
                  </div>
                  <div v-else-if="detailsData.status == 2 && !item.success && index==0" :class="item.is_me ? 'content_fail_me':'content_fail'">
                    <img src="@/assets/img/pk/defeat.png" alt="">
                  </div>
                  <div v-if="detailsData.status == 2">
                    <div v-if="item.success" class="">
                      <div class="pk_ready">
                        <!-- <svg class="pk_ready_svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                          role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor"
                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                            class=""></path>
                        </svg> -->
                      </div>

                      <!-- <svg viewBox="0 0 293.5 236.1" class="pk_stat_winbg">
                        <path d="M34.6 194.8L171.9 0l121.6 136.1-258.9 58.7z" fill-opacity=".039"></path>
                        <path d="M173.9 236.1L59.5 119.8l153.6-12.2-39.2 128.5z" fill-opacity=".129"></path>
                        <path d="M200.9 219.3l-52-88.9 98.4 10.1-46.4 78.8z" fill-opacity=".051"></path>
                        <path d="M87.7 215.1L0 150.5l108.2 46.6-20.5 18z" fill-opacity=".2"></path>
                        <path d="M233.2 104.6l35.3-59.5 10.7 58.7-46 .8z" fill-opacity=".071"></path>
                      </svg> -->

                      <div class="m-auto d-flex justify-content-center align-items-center">

                        <!--                                            <img width="50px" height="50px" class="rounded-pill"-->
                        <!--                                                 :src="item.user.avatar" alt="">-->
                        <!--                                            <div class="ml-3">{{item.user.name}}</div>-->
                      </div>
                      <div style="font-size: 20px; font-weight: 700; color: #3c6">
                        胜利<countTo :decimals="2" :startVal='0' :endVal='(item.sum || 0) * 1' :duration='2000'></countTo>
                      </div>
                    </div>

                    <div v-else>


                      <svg viewBox="0 0 293.5 236.1" class="pk_stat_losebg">
                        <path d="M34.6 194.8L171.9 0l121.6 136.1-258.9 58.7z" fill-opacity=".039"></path>
                        <path d="M173.9 236.1L59.5 119.8l153.6-12.2-39.2 128.5z" fill-opacity=".129"></path>
                        <path d="M200.9 219.3l-52-88.9 98.4 10.1-46.4 78.8z" fill-opacity=".051"></path>
                        <path d="M87.7 215.1L0 150.5l108.2 46.6-20.5 18z" fill-opacity=".2"></path>
                        <path d="M233.2 104.6l35.3-59.5 10.7 58.7-46 .8z" fill-opacity=".071"></path>
                      </svg>

                      <div style="font-size: 20px;font-weight:700;">
                        <!-- 奖励$0.01 -->
                      </div>
                    </div>

                  </div>

                  <!-- 当不是进行中的时候显示 -->
                  
                  <div v-if="detailsData.status == 0 || detailsData.status == 3" :class="item.user.id == user_info.id ? 'content_add_me':'content_add'">
                    <img src="@/assets/img/pk/prepare.png" alt="">
                    <img src="@/assets/img/pk/round.png" alt="">
                    <img src="@/assets/img/pk/added.png" alt="">
                    <div class="status_text">准备完毕</div>
                    <div class="pk_ready">
                      
                      <!-- <svg class="pk_ready_svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                          class=""></path>
                      </svg> -->
                    </div>

                    <!-- <svg viewBox="0 0 293.5 236.1" class="pk_stat_winbg">
                      <path d="M34.6 194.8L171.9 0l121.6 136.1-258.9 58.7z" fill-opacity=".039"></path>
                      <path d="M173.9 236.1L59.5 119.8l153.6-12.2-39.2 128.5z" fill-opacity=".129"></path>
                      <path d="M200.9 219.3l-52-88.9 98.4 10.1-46.4 78.8z" fill-opacity=".051"></path>
                      <path d="M87.7 215.1L0 150.5l108.2 46.6-20.5 18z" fill-opacity=".2"></path>
                      <path d="M233.2 104.6l35.3-59.5 10.7 58.7-46 .8z" fill-opacity=".071"></path>
                    </svg> -->


                    <div v-if="false" class="m-auto d-flex justify-content-center align-items-center">
                      <img width="50px" height="50px" class="rounded-pill" :src="item.user.avatar" alt="">
                      <div class="ml-3">{{ item.user.name }}</div>
                    </div>
                  </div>

                  <!-- 进行中的时候显示 -->
                  <div v-if="detailsData.status == 1">
                    <div v-if="moneyJieShu" style="font-size: 50px"
                      class="d-flex justify-content-center align-items-center">
                      <money :font-size="'40px'" />
                      <countTo :decimals="2" :startVal='0' :endVal='(item.money) * 1' :duration='1000'>
                      </countTo>
                    </div>

                  </div>


                </div>
              </div>
            </div>

            <!-- 用户 -->
            <div :class="item.success ? ' ' : detailsData.status == 2 ? '' : ''" style="" class="d-md-flex userinfo padding-right-xs
                         border-left-0 border-right-0">
              <div v-if="detailsData.status == 2 && item.sum">
                本轮累计：<money /> {{ item.sum }}
              </div>
              <div>
                <img width="60px" height="60px" class="rounded-pill" :src="item.user.avatar" alt="">
              </div>
              <div class="text-overflow-1">{{ item.user.name }}</div>
              
              <div v-if="detailsData.status == 1" class="d-flex">
                <!-- 进行中显示 -->
                <span class="d-md-flex d-none">{{ detailsData.status_alias }} </span>
                <money class="ml-2"></money>
                <!-- 使用数字滚动插件 -->
                <countTo :decimals="2" :startVal='0' :endVal='(item.money) * 1' :duration='1000'></countTo>
              </div>
              <!-- <div v-if="detailsData.status == 2 && item.sum" class="text-right d-flex">

                <span class="d-md-block d-none">获取的金币</span>
                <money class="ml-2"></money> {{ item.sum }}
              </div> -->
            </div>


            <!-- 获奖展示 进行中 -->
            <div v-if="detailsData.status == 1" class="d-flex  flex-wrap w-12">
              <div class="content-award col-lg-4 col-md-4 col-sm-6 col-12 animate__animated "
                v-for="(t, i) in item.game_award_pk" :key="i">
                <div class="item">
                  <img class="content-award-img" :src="t.skins.cover" alt="">
                  <div class="text-overflow-1 text-center" style="font-size: 12px;">
                    {{ t.skins.name }}
                  </div>
                  <div class="mt-2 d-flex align-items-center justify-content-center"
                    style="font-size: 16px; line-height: 16px; color: var(--pk-green) ">
                    <money font-size="12px"></money>
                    {{ t.skins.bean }}
                  </div>
                  <div class="bottom_line"></div>
                </div>
              </div>
            </div>

            <!-- 结束 -->
            <div v-if="detailsData.status == 2" class="d-flex 
                          flex-wrap w-12 ">
              <div class="content-award col-lg-4 col-md-4 col-sm-6 col-12" v-for="(t, i) in item.game_award" :key="i">
                <div class="item">
                  <div data-v-72eb0163="" class="position-absolute bglogo-e"
                    style="width: 80px; height: 80px; z-index: 2; left: 30%; top: 20%;"></div>
                  <img class="content-award-img " :src="t.skins.cover" alt="">
                  <div class="text-overflow-1 text-center m-auto" style="font-size: 12px;width: 80%">
                    {{ t.skins.name }}
                  </div>
                  <div class="mt-2 d-flex align-items-center justify-content-center"
                    style="font-size: 16px; line-height: 16px; color: var(--pk-green) ">
                    <money font-size="12px" style=""></money>
                    {{ t.skins.bean || (fildErr / yessSum).toFixed(2) }}
                    
                  </div>
                  <div class="bottom_line"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 点击加入 -->
          <template v-if="!item">
            <div :name="index" class="overflow-hidden content-pk-item  pl-0 pr-0">
              <div class="content-pk-item-pk-box-item w-100 p-0 ">
                <div class="other_status">
                  <div>
                    <img class="waitimg" src="@/assets/img/pk/wait.webp" alt="">
                    <div class="status_text">等待中...</div>
                  </div>
                </div>
                <!-- <div style="" @click="addPk(index)" class="text-center" v-if="detailsData.status ==0">
                  <button style="font-size: 24px; background-color: #e94d8b!important;color:#fff" class="sefl_pk_button">
                    <div class="self_pk_right"></div>
                    {{ peopleStatus == 2 ? '等待加入中' : '点击加入' }}
                    <div class="self_pk_right"></div>
                  </button>
                </div> -->

                <div style="" class="text-center" v-if="detailsData.status ==3">
                  <button style="font-size: 24px; background-color: #999 !important;color:#fff" class="sefl_pk_button">
                    已取消
                    <div class="self_pk_right"></div>
                  </button>
                </div>
                
              </div>
            </div>
            <!-- 加入 -->
            <div v-if="detailsData.status == 0 && peopleStatus != 2" class="addPkF">
              <button @click="addPk(index)" class="addPk">
                <img src="@/assets/img/pk/add_pk.png" alt="">
              </button>
            </div>
          </template>

        </div>
      </div>



      <div class="d-none">
        <div class="h4">对战回合</div>
        <div class="d-flex content-bout history_bg align-items-center">
          <div class="content-bout-sum h4">
            <span class="color-yellow h1">{{ detailsData.status == 2 ? detailsData.box_num : indexBox + 1 }}</span>
            / {{ detailsData.box_num }}
          </div>
          <div class="content-bout-box d-flex" :class="detailsData.status == 2 ? '' : ''">
            <div v-for="(item, index) in detailsData.game_arena_box" :key="index"
              class="text-center content-bout-box-item "
              :class="index == indexBox ? detailsData.status == 2 ? '' : 'active' : ''">
              <img width="100px" :src="item.box?.type == 1 ? item.box?.intact_cover : item.box?.cover" alt="">
              <div class="mt-2">{{ item.box.name || '' }}</div>
            </div>
          </div>
          <div class="ml-auto content-bout-add-pk">
            <div v-show="detailsData.status == 2" class="px-5 py-4 bg-yellow cursor-pointer rounded">
              对战结束
              <money color="var(--main-brown)" />{{ detailsData.total_bean }}
            </div>
            <div v-show="detailsData.status == 0" @click="addPk(item, index)"
              class="px-5 py-4 bg-yellow cursor-pointer rounded">
              加入对战
              <money color="var(--main-brown)" />{{ detailsData.total_bean }}
            </div>
            <div v-show="detailsData.status == 1" class="bg-dark-brown cursor-pointer">
              <!--                        <img width="200px" :src="require('@/assets/img/dengdai.gif')" alt="">-->
            </div>
          </div>
        </div>
      </div>







      <!-- 写一个弹出窗口，之后是胜利者和失败者的信息，之后两个人比较 -->
      <el-dialog custom-class="pop-up" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false"
        :visible.sync="Against_the_end" width="700px">
        <div class="p-4 text-white">
          <h3 class="h3 text-center mb-4">不知从何入手试试这些</h3>
          <p class="mb-3">
            最近骗子横行繁多，并会模仿官方客服、进行线下交易、线下转账，骗术繁多！
            大家擦亮眼睛，切勿贪图小便宜！上当受骗！！
          </p>
          <h5 class="h5 color-yellow">网站福利</h5>
          <p class="mb-3">
            注册红包：注册即可领取1~1000元随机红包，红包自动进入余额，可全站使用！
            免费ROLL房：注册并通过核验URL，即可进入无门槛ROLL房，感受饰品从天而降！
            免费盲盒：本站为水友提供了等级盲盒、时间盲盒、推广盲盒等福利，白嫖到爽！
          </p>
          <h5 class="h5 color-yellow">特别说明：</h5>
          <p class="mb-3">
            本站拥有正规备案；采用公平算法保证掉落随机；支持饰品自动发货；请放心娱乐！
          </p>
          <h5 class="h5 color-yellow">提别提醒：</h5>
          <p class="text-danger mb-3">
            本站禁止交易、返现；禁止绑定他人URL，谨防被骗！
            禁止恶意注册账号，一经发现永久封禁！
            开盲盒即为娱乐，HXD勿上头！
          </p>
        </div>
      </el-dialog>


      <!-- 宝箱内的武器 -->
      <div v-show="lockBoxGunIsShow">
        <div style="" class="position-fixed pop-up content-pop z-index-100">
          <div>
            <div class="h5 mt-3 ml-3">包含以下奖励</div>
            <div class="d-flex flex-wrap content-pop-lock align-content-start">

              <div v-for="(item, index) in boxGunListContains" :key="index"
                class="content-pop-lock-item col-lg-3 col-md-3 col-6 " :class="'all-bg'+item.level">
                <img width="100%" class="mx-auto d-block mt-3" :src="item.skins.cover" alt="">
                <div class="text-center px-3 text-overflow-1">
                  <div class="gunboxtext ">{{ item.skins.name }}</div>
                </div>
                <div
                  class="mx-auto d-flex justify-content-between align-items-center  text-center p-2  mt-2 cursor-pointer"
                  style="">
                  <div style="font-size: 12px;color:#ddd ">{{ item.skins.dura_alias }}</div>
                  <div style="font-size: 12px; color: #75dc9e; font-weight: 900; ">
                    <money /> {{ item.skins.bean }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div @click="lockBoxGunIsShow = false" class="position-absolute" style="right: 20px;top: 30px">
            <div>
              <i class="iconfont text-white icon-guanbi-02-02 cursor-pointer" style="font-size:30px"></i>
            </div>
          </div>
        </div>
        <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
      </div>
    </div>
    <audio preload="auto" ref="audio" :src="openMp"></audio>
  </div>
</template>

<script>

import $api from '@/api/pk/index'
import countTo from 'vue-count-to';
import { mapState } from 'vuex'
export default {
  name: "pkweb",
  components: { countTo },

  data() {
    return {
      openMp: require('@/assets/music/open1.mp3'),
      // 声音开关
      voiceSwitch: true,
      // 对战ID
      game_arena_id: '',
      // 渲染页面 数据 对战详情 数据在disposalData这个函数兰的，需要整理数据
      detailsData: {},
      // 对战回合显示
      box_num: 0,
      // 找到哪一个宝箱进行循环遍历
      indexBox: 0,
      urlType: '',
      // 动画
      openAn: false,
      // 渲染页面显示不出来了，放外面一个
      status: 0,
      // 对战结束的时候弹出窗口
      Against_the_end: false,
      // 有几人加入了
      sum: 0,
      // 失败这的金额
      fildErr: 0,
      // 中将人数
      yessSum: 1,
      // 是否结束 0等待 2结束代表这正在进行
      full_house: 0,

      successAndErr: false,
      // 对战结束的时候
      moneyJieShu: false,
      // 进行中使用 underway进行到多少回合
      underway: 0,
      // 是否是进行中round = 1为进行中，别的没传此参数
      round: null,
      //
      detailsDataUnderway: {},
      // 看士第几回合进来的
      box_huihe: 0,
      // 两个定时器
      time1: null,
      tiem2: null,
      time: null,
      // 显示前面几回合的枪支
      huihe_gun: true,
      // 指示灯
      game_gun: false,
      // settem的定时器
      settime: null,


      // 包厢内武器
      boxGunListContains: [],
      lockBoxGunIsShow: false,
      currentType: 1,
      hisPageRecord: null,
      peopleStatus: 0,

      activeStatus: true,

      slotList: [
        {},
        {},
        {},
        {}
      ],
      slotIndex: 0,
      slotAn: false,
      slotNumber: 0,
      slotY: 0,
      slotStatus: 0,
      slotSuccess: []
    }
  },

  computed: {
    user_num: {
      get() {
        return this.detailsData.user_num - this.detailsData.game_arena_player.length
      },
      set() {

      }
    },
    ...mapState(['user_info']),
  },
  mounted() {
    this.$refs.audio.load()
    this.init()
  },
  created() {
    this.game_arena_id = this.$route.params.id
    this.sum = this.$route.query.sum
    // 进行中使用 underway进行到多少回合
    // 是否是进行中round = 1为进行中，别的没传此参数
    this.urlType = this.$route.query.typeurl

    this.hisPageRecord = String(this.$route.query.hisPageRecord) == 'undefined' ? 1 : this.$route.query.hisPageRecord;
    this.peopleStatus = this.$route.query.peopleStatus;
    this.underway = this.$route.query.underway
    this.round = this.$route.query.round
    this.initWebSocket()
  },
  beforeDestroy() {
    clearTimeout(this.time1)
    clearTimeout(this.tiem)
    clearTimeout(this.tiem2)
    clearTimeout(this.settime)
    this.$refs.audio.pause()
    this.$router.go(0)
  },
  watch: {
    detailsData: {
      handler(newVal) {
        this.detailsData = newVal
      },
      immediate: true,//页面监听开始的时候就直接调用：见上文解释
      deep: true,//见上文解释
    },

  },
  methods: {
    async joinBot(bot_id, game_arena_id) {
      let { code } = await $api.joinBot({
        bot_id,
        game_arena_id,
        seat: 1
      })
      console.log(code)
    },
    // 观战的时候显示
    async underwayPk() {
      let { data } = await $api.getPKarenaDetail(this.game_arena_id)
      let resData = JSON.parse(JSON.stringify(data))

      if (this.round > resData.box_num) {
        this.round = resData.box_num - 1
      }
      // resData.game_arena_box.splice(resData.game_arena_box.length*1 - this.round,this.round)
      resData.status = 1
      resData.status_alias = '进行中'
      let box_huihe = this.round * 1
      this.box_huihe = JSON.parse(JSON.stringify(box_huihe))
      this.indexBox = box_huihe

      await this.disposalData(resData)
      await this.turn()

    },
    async init() {
      // if(this.underway == 1){
      //   await this.underwayPk()
      // }else {

      await this.getPKarenaDetail()
      // }
    },
    // 获取结束的时候数据 初始化
    async getPKarenaDetail() {
      try {
        let { data, code } = await $api.getPKarenaDetail(this.game_arena_id)
        if (code == 500) return

        if (data.status == 0) {
          // this.slotList = data.game_arena_box[this.slotIndex]?.box?.contains;
          for (var i = 0; i < data.user_num; i++) {
            this.slotList[i] = this.anwe_sort(data.game_arena_box[this.slotIndex]?.box?.contains)
          }
          for (var o = 0; o < data.game_arena_player.length; o++) {
            if (data.game_arena_player[o].game_award) {
              this.slotList[data.game_arena_player[o].seat].push(data.game_arena_player[o]?.game_award[this.slotIndex])
            } else {
              data.game_arena_player[o].game_award = []
            }
          }
          this.slotNumber = data.game_arena_box[this.slotIndex]?.box?.contains.length + 1;
          this.slotY = (this.slotNumber - 1) * 300
        } else if (data.status == 1) {
          let ii = data.current_round
          this.slotIndex = ii
          for (var j = 0; j < data.user_num; j++) {
            this.slotList[j] = this.anwe_sort(data.game_arena_box[ii - 1]?.box?.contains)
          }
          for (var o1 = 0; o1 < data.game_arena_player.length; o1++) {
            if (data.game_arena_player[o1].game_award) {
              this.slotList[data.game_arena_player[o1].seat].push(data.game_arena_player[o1]?.game_award[this.slotIndex])
            } else {
              data.game_arena_player[o1].game_award = []
            }
          }
          // this.slotList = data.game_arena_box[i - 1]?.box?.contains;
          this.slotNumber = data.game_arena_box[ii - 1]?.box?.contains.length + 1;
          this.slotY = (this.slotNumber - 1) * 300;

        }


        let obj = this.disposalData(data)
        this.detailsData = obj
        if (obj.play_type == 2) {
          setTimeout(() => {
            // if( this.peopleStatus == 2 ){
            this.joinBot(this.$route.query.bot_id, this.$route.params.id)
            // }
          }, 3000)
        }
        let game_arena_player = JSON.parse(JSON.stringify(data))
        // 结束的时候
        if (this.detailsData.status == 2) {
          // 当超过两个人中将的时候
          if (obj.win_user_id.length >= 2) {

            this.yessSum = obj.win_user_id.length
            // 计算总金额
            let sum = 0
            this.detailsData.game_arena_player.forEach((item) => {
              item.game_award.forEach(a => {
                sum = sum + (a.skins.bean * 1)
              })
            })

            this.detailsData.game_arena_player.forEach((item, index) => {
              item.success = false
              // 中奖的钱金额增加
              obj.win_user_id.forEach(t => {
                if (item.user_id == t) {
                  item.success = true
                  item.sum = (sum / obj.win_user_id.length).toFixed(2);
                }
              })
              // 失败的人
              if (!item.success) {
                // 获取失败人 枪支的总价格
                item.game_award.map(a => {
                  this.fildErr = this.fildErr + (a.skins.bean * 1)
                })
                // 这里是大于两人 显示的失败状态
                item.game_award = [
                  {
                    skins: {
                      cover: require('@/assets/img/liangliang.png'),
                      name: '惜败',
                      // bean:0.01
                    }
                  }
                ]
              } else { //胜利的人
                item.game_award.push({
                  skins: {
                    cover: require('@/assets/img/money.png'),
                    name: '钱袋子',
                    bean: 0
                  }
                })
              }
              item = game_arena_player.game_arena_player[index]
            })
          } else { // 中将这是一个人的时候
            let arrAllList = []
            this.detailsData.game_arena_player.forEach(item => {
              let arrList = JSON.stringify(item.game_award)
              arrAllList.push(...JSON.parse(arrList))
            })
            this.detailsData.game_arena_player.forEach(item => {
              item.game_award = arrAllList
              obj.win_user_id.map(t => {
                if (item.user_id == t) {
                  item.success = true
                  // 计算价格
                  let sum = 0
                  item.game_award.forEach(m => {
                    sum = sum + (m.skins.bean * 1)
                  })
                  // 输出价格
                  item.sum = sum.toFixed(2)

                  return item.game_award
                } else {
                  item.game_award = [
                    {
                      skins: {
                        cover: require('@/assets/img/liangliang.png'),
                        name: '惜败',
                        // bean:0.01
                      }
                    }
                  ]
                }
              })
            })
          }
        }
        if (this.detailsData.status == 1) {
          // console.log('进来了')
          // clearInterval(this.time1)
          // clearInterval(this.tiem)
          // clearInterval(this.tiem2)
          // clearTimeout(this.settime)
          // this.openAn = false;
          // document.getElementById('open').pause()
          // this.turnEverOne( Number(obj.current_round)-1 )

          setTimeout(() => {
            this.goingSocket(obj.id, this.user_info?.id)
          }, 200)

          this.currentType = 2;


        }


      } catch (e) {
        console.log(e)
      }
      setTimeout(() => {
        this.successAndErr = true
      }, 2000)
    },
    async goingSocket(id, user_id) {
      await $api.goingSocket({
        game_arena_id: id,
        user_id
      })
    },
    // 整理数据
    disposalData(res) {
      // 进行深拷贝对象，如果返回的是一个数组需要转换成对象的形式
      let data = JSON.parse(JSON.stringify(res))
      if (this.detailsData.status == 0) {
        // 等待的时候走着
        this.detailsData = data
        return
      }
      // 根据座位号排序
      // 下面是进行中
      data.game_arena_player.forEach((item, index, arr) => {

        // 全部宝箱给到每一个用户
        item.game_arena_box = JSON.parse(JSON.stringify(data.game_arena_box))
        // 进行中中将数组
        item.game_award_pk = []
        // 进行中的金额
        item.money = 0
        // 亮灯
        item.game_gun = false
        // 中将ID - 需要深拷贝，记住深拷贝 - 返回中将数组
        let awar_id = JSON.parse(JSON.stringify(item.game_award))
        // 获取中将的数组
        let awar_id_arr = []
        // 奖中奖IDpush到中将数组中
        awar_id.forEach(i => {
          awar_id_arr.push(i)
        })

        // 判断是几个人，开始的，之后根绝这个给下面展示的枪支添加一个true或者空
        if (data.user_num == 2 && data.user_num == arr.length) {

          for (let j = 0; j < data.box_num; j++) {
            let sum = data.game_arena_player[0].game_award[j].skins.bean
            let sum2 = data.game_arena_player[1].game_award[j].skins.bean
            if (sum > sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true

            } else {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
            if (sum == sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
          }
        }
        if (data.user_num == 3 && data.user_num == arr.length) {

          for (let j = 0; j < data.box_num; j++) {
            let sum = data.game_arena_player[0].game_award[j].skins.bean
            let sum2 = data.game_arena_player[1].game_award[j].skins.bean
            let sum3 = data.game_arena_player[2].game_award[j].skins.bean
            if (sum > sum2) {
              if (sum > sum3) {
                data.game_arena_player[0].game_award[j].skins.game_gun = true
              }
            }
            if (sum2 > sum) {
              if (sum2 > sum3) {
                data.game_arena_player[1].game_award[j].skins.game_gun = true
              }
            }
            if (sum3 > sum) {
              if (sum3 > sum3) {
                data.game_arena_player[2].game_award[j].skins.game_gun = true
              }
            }
            if (sum == sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
            if (sum == sum3) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
            if (sum2 == sum3) {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
          }
        }
        if (data.user_num == 4 && data.user_num == arr.length) {

          for (let j = 0; j < data.box_num; j++) {
            let sum = data.game_arena_player[0].game_award[j].skins.bean
            let sum1 = data.game_arena_player[1].game_award[j].skins.bean
            let sum2 = data.game_arena_player[2].game_award[j].skins.bean
            let sum3 = data.game_arena_player[3].game_award[j].skins.bean
            if (sum > sum1) {
              if (sum > sum2) {
                if (sum > sum3) {
                  data.game_arena_player[0].game_award[j].skins.game_gun = true
                }
              }
            }
            if (sum1 > sum) {
              if (sum1 > sum2) {
                if (sum1 > sum3) {
                  data.game_arena_player[1].game_award[j].skins.game_gun = true
                }
              }
            }
            if (sum2 > sum) {
              if (sum2 > sum1) {
                if (sum2 > sum3) {
                  data.game_arena_player[2].game_award[j].skins.game_gun = true
                }
              }
            }
            if (sum3 > sum) {
              if (sum3 > sum1) {
                if (sum3 > sum2) {
                  data.game_arena_player[2].game_award[j].skins.game_gun = true
                }

              }
            }
            if (sum == sum1) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[1].game_award[j].skins.game_gun = true
            }
            if (sum == sum2) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
            if (sum == sum3) {
              data.game_arena_player[0].game_award[j].skins.game_gun = true
              data.game_arena_player[3].game_award[j].skins.game_gun = true
            }
            if (sum1 == sum2) {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
              data.game_arena_player[2].game_award[j].skins.game_gun = true
            }
            if (sum1 == sum3) {
              data.game_arena_player[1].game_award[j].skins.game_gun = true
              data.game_arena_player[3].game_award[j].skins.game_gun = true
            }
            if (sum2 == sum3) {
              data.game_arena_player[2].game_award[j].skins.game_gun = true
              data.game_arena_player[3].game_award[j].skins.game_gun = true
            }
          }
        }

        // 全部的武器，进行遍历，之后和中将数组中的箱子id比较，如果相同直接返回对象之后渲染
        item.game_arena_box.forEach((e) => {
          let obj = awar_id_arr.find(t => {
            if (e.box_id == t.box_id) {
              // 防止重复的箱子重复循环
              t.box_id = ''
              return e
            }
          })
          let contains = []
          for (let i = 0; i < 70; i++) {
            let index = Math.floor(Math.random() * e.box.contains.length)
            contains.push(e.box.contains[index])
          }
          e.box.contains = [...contains]
          if (obj != undefined) {
            obj.level = 1
            e.box.contains.forEach((item) => {
              if (item.skin_id == obj.skins.id) {
                obj.level = item.level
              }
            })
            // 哪一个中将  现在是第一个
            obj.a = '1'
            e.box.contains.splice(60, 1, obj)
          }
        })
      })
      let arrData = JSON.parse(JSON.stringify(data))

      // 新加 为了让参与的用户和回合数相等
      // arrData.game_arena_player.length = arrData.user_num

      let arrIndex = []
      arrIndex.length = arrData.user_num
      for (let index = 0; index < arrData.game_arena_player.length; index++) {
        let arrDataIndex = arrData.game_arena_player[index]
        arrIndex[arrDataIndex.seat] = arrDataIndex
      }
      arrData.game_arena_player = arrIndex

      // end 新加
      this.detailsData = arrData
      return arrData
    },
    // 打乱数组
    anwe_sort(arr) {
      let sort = []
      for (let i = 0; i < arr.length; i++) {
        let item = arr[Math.floor(Math.random() * arr.length)]
        sort.push(item)
      }

      // this.detailsData.game_arena_player.map((item) => {
      //   if( item.user_id == id ){
      //     sort.push(item.game_award[this.slotIndex])
      //   }
      // })

      return sort
    },
    // 点击加入对战
    async addPk(item) {
      if (this.voiceSwitch) {
        document.getElementById('mysound').play()
      }
      try {
        let obj = {
          game_arena_id: this.game_arena_id,
          seat: item
        }
        let { message, code } = await $api.getPKarenaJoin(obj)
        if (code == 500) return
        if (code == 200) {
          this.$notify({
            message: message,
            position: 'top-left'
          });
          this.$store.dispatch('userInfo')
          this.user_num = this.sum * 1 + 1

        }

      } catch (e) {
        console.log(e)
      }

    },
    // 转动
    turnEverOne(indexBox) {
      console.log(indexBox)
      this.openAn = true
      if (this.voiceSwitch) {
        // this.$refs.audio.play()
      }
      if (this.currentType == 2) {
        if (this.detailsData.box_num != this.indexBox + 1) {
          this.indexBox++
        }
        this.detailsData.game_arena_player.forEach(item => {
          [...Array(indexBox + 1)].map((c, i) => {
            item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
          })
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, (indexBox) + 1)
          item.game_gun = item.game_award[indexBox].skins.game_gun;
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
          this.currentType = 1;
        })
      }

      // setTimeout(()=>{
      //   if( this.currentType == 3  ){
      //     this.$refs.audio.pause()
      //     this.$refs.audioCopy.play()
      //     this.currentType = 1;
      //   }
      // },8850)

      this.tiem = setTimeout(() => {
        this.detailsData.game_arena_player.forEach(item => {
          // 金币
          item.money = ((item.money * 1) + (item.game_award[indexBox].skins.bean * 1)).toFixed(2)
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, indexBox + 1)
          item.game_gun = item.game_award[indexBox].skins.game_gun
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
          item.game_gun = false
        })
      }, 5800)
      this.time1 = setTimeout(() => {
        if (indexBox == this.detailsData.box_num - 1) {

          this.detailsData.game_arena_player.map(item => {
            item.money = 0
          })
          this.moneyJieShu = true
          // this.detailsData.status = 2
          this.openAn = false
        } else {
          setTimeout(() => {
            this.openAn = false
          }, 900)
        }
        this.$refs.audio.pause()
      }, 9000)
    },
    // 转动
    turnEverOne1(indexBox) {
      console.log( `观战的轮次${indexBox}` )
      this.openAn = true
      if (this.voiceSwitch) {
        // this.$refs.audio.play()
      }
      if (this.currentType == 2) {
        if (this.detailsData.box_num != this.indexBox + 1) {
          this.indexBox++
        }
        this.detailsData.game_arena_player.forEach(item => {
          [...Array(indexBox)].map((c, i) => {
            item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
          })
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, indexBox)
          item.game_gun = item.game_award[indexBox].skins.game_gun;
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
          this.currentType = 1;
        })
      }

      // setTimeout(()=>{
      //   if( this.currentType == 3  ){
      //     this.$refs.audio.pause()
      //     this.$refs.audioCopy.play()
      //     this.currentType = 1;
      //   }
      // },8850)

      this.tiem = setTimeout(() => {
        this.detailsData.game_arena_player.forEach(item => {
          // 金币
          item.money = ((item.money * 1) + (item.game_award[indexBox].skins.bean * 1)).toFixed(2)
          console.log('下次回合进来了')
          // 进行中的展示奖品
          // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
          item.game_award_pk = item.game_award.slice(0, indexBox + 1)
          item.game_gun = item.game_award[indexBox].skins.game_gun
          setTimeout(() => {
            item.game_gun = false
          }, 2000)
          item.game_gun = false
        })
      }, 5800)
      this.time1 = setTimeout(() => {
        if (indexBox == this.detailsData.box_num - 1) {

          this.detailsData.game_arena_player.map(item => {
            item.money = 0
          })
          this.moneyJieShu = true
          // this.detailsData.status = 2
          this.openAn = false
        } else {
          setTimeout(() => {
            this.openAn = false
          }, 900)
        }
        this.$refs.audio.pause()
      }, 9000)
    },
    // 转动
    turn() {
      console.log('turn')
      if (this.round == this.detailsData.box_num) {
        this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=0&round=' + 0)
        this.$router.go(0)
        return
      }
      if (this.round > 0) {
        this.indexBox = this.round * 1
        this.underway = 1
        this.round = this.indexBox
        // this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=1&round=' + this.round)
        this.detailsData.game_arena_player.forEach(item => {
          // 金币
          item.money = ((item.money * 1) + (item.game_award[this.indexBox].skins.bean * 1)).toFixed(2)
          // 进行中的展示奖品
          item.game_award_pk = item.game_award.slice(0, this.indexBox)
        })
      }
      // 第一次转动
      this.settime = setTimeout(() => {
        this.openAn = true
        if (this.voiceSwitch) {
          document.getElementById('open').play()
        }
      }, 100)
      // 后面的几次转动
      if (this.detailsData.status == 1) {
        /* 不知道为啥需要两个定时器 */
        this.tiem = setInterval(() => {
          this.detailsData.game_arena_player.forEach(item => {
            // 金币
            item.money = ((item.money * 1) + (item.game_award[this.indexBox].skins.bean * 1)).toFixed(2)
            // 进行中的展示奖品
            // item.game_award_pk.push(item.game_award[this.indexBox]) // 开将之后不显示之前奖品
            item.game_award_pk = item.game_award.slice(0, this.indexBox + 1)
            item.game_gun = item.game_award[this.indexBox].skins.game_gun
            setTimeout(() => {
              item.game_gun = false
            }, 2000)
          })
        }, 8900)
        // 10秒的话停顿2秒 css控制的8秒
        this.time1 = setInterval(async () => {

          this.indexBox += 1

          if (this.indexBox >= this.detailsData.box_num) {
            try {
              document.getElementById('open').pause()

              this.indexBox = 0
              clearInterval(this.time1)
              clearInterval(this.tiem)
              this.openAn = false
              this.moneyJieShu = true
              this.detailsData.game_arena_player.map(item => {
                item.money = 0
                item.success = false
                item.gun = false
                item.game_award_pk.forEach(it => {
                  it.gun = false
                })
                this.detailsData.win_user_id.forEach(t => {
                  if (item.user_id == t) {
                    item.success = true
                  }
                })
                return item
              })
              let i = 0
              this.tiem2 = setInterval(() => {
                // if(i > this.detailsData.game_arena_player.length + 1){
                //   this.getPKarenaDetail() // 初始化数据 - 结束的时候
                //   this.detailsData.status = 2
                //   clearInterval(this.tiem2)
                // }
                try {
                  this.detailsData.game_arena_player.forEach(item => {
                    // 金币
                    item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
                  })
                } catch (e) {
                  i = this.detailsData.game_arena_player.length + 2
                  this.getPKarenaDetail() // 初始化数据 - 结束的时候
                  this.detailsData.status = 2
                  clearInterval(this.tiem2)
                }
                i++

              }, 2000)
              if (this.$route.name == 'mysteryBox') {
                this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=0&round=' + this.indexBox)
              }
            } catch (e) {
              console.log(e)
            }
          } else {
            this.openAn = false
            setTimeout(() => {
              this.openAn = true
            }, 100)
            if (this.$route.name == 'mysteryBox') {
              this.underway = 1
              this.round = this.indexBox
              // this.$router.push('/active/mysteryBox/' + this.game_arena_id + '?underway=1&round=' + this.indexBox)
            }
            if (this.voiceSwitch) {
              document.getElementById('open').play()
            }
          }
        }, 9000)

      }
    },
    timeCeil(Data) {
      // 结束时间

      setInterval(() => {

        var time1 = Date.parse(Data.updated_at) / 1000
        // 当前时间
        var time2 = Date.parse(new Date()) / 1000
        // 删除数据 这个位置直记没有走
        let ceil = Math.ceil((time1 + Data.box_num * 10 - time2) / 10)// 得到回合数
        Data.ceil = Data.box_num - ceil

      }, 3000)
    },
    // 打开弹窗查看武器
    lockBoxGun(item) {
      this.lockBoxGunIsShow = true
      this.boxGunListContains = item.box.contains

    },

    /* 实时通讯 */
    initWebSocket() {
      let _this = this
      this.$ws.addEventListener('message', (event) => {
        let data = event.data
        let { Data, MsgType } = JSON.parse(data)
        /*  创建对战 */
        if (MsgType == 'CreateGroup') {
          if (Data.id == _this.game_arena_id) {
            this.detailsData.status = Data.status
          }
        }
        // 进入房间如果还在进行中
        if (MsgType == 'goingGroup') {
          if (this.game_arena_id == Data.game_arena_id && this.user_info?.id == Data.data.user_id) {
            this.slotSuccess = Data.win_user_id;
            this.slotAn = false;
            this.slotIndex = Data.current_round
            for (var i = 0; i < this.detailsData.user_num; i++) {
              this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[Data.current_round - 1]?.box?.contains);
              this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[Data.current_round - 1])
            }
            // this.slotList = this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains
            this.slotNumber = this.detailsData.game_arena_box[Data.current_round - 1]?.box?.contains.length + 1;
            this.slotY = (this.slotNumber - 1) * 300;
            console.log(`1262当前回合${this.slotIndex}`)
            this.$refs.audio.pause()
            this.$refs.audio.play()
            this.slotAn = true;
            this.clearReast1()
            _this.indexBox = Number(Data.current_round) - 1
            _this.turnEverOne1(Number(Data.current_round) - 1)

            var time1 = setInterval(() => {
              if (_this.detailsData.game_arena_box.length == this.slotIndex) {
                console.log('本场结束')
                this.clearData(this.slotSuccess)
                clearInterval(time1)
              } else {
                this.slotAn = false;
                this.$refs.audio.pause()
                this.$refs.audio.play()
                this.slotAn = true;
                this.slotIndex = Number(this.slotIndex) + 1
                console.log(`1278当前回合${this.slotIndex}`)
                this.clearReast1()

                _this.detailsData.status = Data.status
                _this.detailsData.status_alias = Data.status_alias
                // 需要判断是不是当前页面，当前页面需要执行动画，不是当前页面不执行动画
                if (this.$route.name == 'mysteryBoxs' && this.game_arena_id == Data.game_arena_id) {
                  _this.indexBox = this.slotIndex - 1
                  console.log(`1286当前回合${this.slotIndex}`)

                  let _data = Data.data;
                  _data.game_arena_box = _this.detailsData.game_arena_box
                  if (_this.indexBox == 0) {
                    _this.disposalData(_data)
                  }

                  this.openAn = false
                  clearTimeout(this.time1)
                  clearTimeout(this.tiem)

                  _this.turnEverOne1(_this.indexBox)
                  // if( _this.indexBox > 0 ){
                  //   _this.detailsData.game_arena_player.forEach(item => {
                  //     // 金币
                  //     item.money = ((item.money * 1) + (item.game_award[Data.current_round-1].skins.bean * 1)).toFixed(2)
                  //     // 进行中的展示奖品
                  //     item.game_award_pk = item.game_award.slice(0, Data.current_round-1)
                  //   })
                  // }
                }
              }
            }, 6000)

          }
        }
        /* 加入房间 */
        if (MsgType == "joinGroup") {
          if (Data.game_arena_id == _this.game_arena_id) {
            let data = JSON.parse(JSON.stringify(_this.detailsData.game_arena_player[0]))
            let { game_arena_box, game_award_pk, money } = data
            let obj = {
              seat: Data.seat,
              game_arena_id: Data.game_arena_id,
              user: {
                siyecao: '四叶草',
                id: Data.user_id,
                name: Data.name,
                avatar: Data.avatar,
              },
              game_arena_box,
              game_award: Data.awards_res,
              game_award_pk,
              money
            }
            _this.detailsData.game_arena_player.splice(obj.seat, 1, obj)

            console.log(Data.awards_res, 1336)

            if (_this.detailsData.user_num == 2) {
              _this.slotList[1].push(Data.awards_res[0])
            } else {
              _this.slotList[Data.seat].push(Data.awards_res[0])
            }
          }
        }
        /* 进行中状态 */
        if (MsgType == "startGroup" && Data.game_arena_id == _this.game_arena_id) {
          this.slotSuccess = Data.win_user_id;
          if (this.slotStatus == 0) {
            this.slotAn = false;
            this.$refs.audio.pause()
            this.$refs.audio.play()
            this.slotAn = true;
            this.slotIndex = this.slotIndex + 1
            this.clearReast()

            _this.detailsData.status = Data.status
            _this.detailsData.status_alias = Data.status_alias
            // 需要判断是不是当前页面，当前页面需要执行动画，不是当前页面不执行动画
            
            if (this.$route.name == 'mysteryBoxs' && this.game_arena_id == Data.game_arena_id) {
              const game_arena_player = Data.data.game_arena_player
              for (var u = 0; u < this.detailsData.user_num; u++) {
                this.slotList[u] = this.anwe_sort(this.detailsData.game_arena_box[Data.current_round - 1]?.box?.contains);
                this.slotList[u].push(game_arena_player[u].game_award[Data.current_round - 1])
              }
              _this.indexBox = this.slotIndex - 1
              console.log(`当前回合${this.slotIndex}`)

              let _data = Data.data;
              _data.game_arena_box = _this.detailsData.game_arena_box
              if (_this.indexBox == 0) {
                _this.disposalData(_data)
              }

              this.openAn = false
              clearTimeout(this.time1)
              clearTimeout(this.tiem)

              _this.turnEverOne(_this.indexBox)
              console.log('一轮结束' + _this.indexBox)
              // if( _this.indexBox > 0 ){
              //   _this.detailsData.game_arena_player.forEach(item => {
              //     // 金币
              //     item.money = ((item.money * 1) + (item.game_award[Data.current_round-1].skins.bean * 1)).toFixed(2)
              //     // 进行中的展示奖品
              //     item.game_award_pk = item.game_award.slice(0, Data.current_round-1)
              //   })
              // }
            }


            var time = setInterval(() => {
              if (_this.detailsData.game_arena_box.length == this.slotIndex) {
                console.log('本场结束')
                this.clearData(this.slotSuccess)
                clearInterval(time)
              } else {
                // console.log(this.slotIndex)
                this.slotAn = false;
                this.$refs.audio.pause()
                this.$refs.audio.play()
                this.slotAn = true;
                this.slotIndex = this.slotIndex + 1
                this.clearReast()

                _this.detailsData.status = Data.status
                _this.detailsData.status_alias = Data.status_alias
                // 需要判断是不是当前页面，当前页面需要执行动画，不是当前页面不执行动画
                if (this.$route.name == 'mysteryBoxs' && this.game_arena_id == Data.game_arena_id) {
                  _this.indexBox = this.slotIndex - 1
                  console.log(`当前回合${this.slotIndex}`)

                  let _data = Data.data;
                  _data.game_arena_box = _this.detailsData.game_arena_box
                  if (_this.indexBox == 0) {
                    _this.disposalData(_data)
                  }

                  this.openAn = false
                  clearTimeout(this.time1)
                  clearTimeout(this.tiem)

                  _this.turnEverOne(_this.indexBox)
                  // if( _this.indexBox > 0 ){
                  //   _this.detailsData.game_arena_player.forEach(item => {
                  //     // 金币
                  //     item.money = ((item.money * 1) + (item.game_award[Data.current_round-1].skins.bean * 1)).toFixed(2)
                  //     // 进行中的展示奖品
                  //     item.game_award_pk = item.game_award.slice(0, Data.current_round-1)
                  //   })
                  // }
                }
              }
            }, 6000)

          }

          // else if (this.slotStatus == 1) {
          //   // 刷新页面后的逻辑
          //   this.slotAn = false;
          //   this.slotIndex = this.slotIndex + 1
          //   for (var i = 0; i < this.detailsData.user_num; i++) {
          //     this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains);

          //     this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[this.slotIndex - 1])
          //   }
          //   // this.slotList = this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains
          //   this.slotNumber = this.detailsData.game_arena_box[this.slotIndex - 1]?.box?.contains.length + 1;
          //   this.slotY = (this.slotNumber - 1) * 300;
          //   setTimeout(() => {
          //     this.slotAn = true;
          //     this.clearReast1()
          //   }, 500)
          // }
        }
        /* 结束 */
        if (MsgType == "endGroup") {
          if (Data.id == _this.game_arena_id) {
            
            // this.detailsData.win_user_id = Data.win_user_id;
            // this.$refs.audio.pause()
            // this.indexBox = 0
            // this.activeStatus = false;
            // clearTimeout(this.time1)
            // clearTimeout(this.tiem)
            // this.openAn = false
            // this.moneyJieShu = true
            // this.detailsData.game_arena_player.map(item => {
            //   item.money = 0
            //   item.success = false
            //   item.gun = false
            //   item.game_award_pk.forEach(it => {
            //     it.gun = false
            //   })
            //   this.detailsData.win_user_id.forEach(t => {
            //     if (item.user_id == t) {
            //       item.success = true
            //     }
            //   })
            //   return item
            // })
            // let i = 0
            // this.tiem2 = setInterval(() => {
            //   // if(i > this.detailsData.game_arena_player.length + 1){
            //   //   this.getPKarenaDetail() // 初始化数据 - 结束的时候
            //   //   this.detailsData.status = 2
            //   //   clearInterval(this.tiem2)
            //   // }
            //   try {
            //     this.detailsData.game_arena_player.forEach(item => {
            //       // 金币
            //       item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
            //     })
            //   } catch (e) {
            //     i = this.detailsData.game_arena_player.length + 2
            //     this.getPKarenaDetail() // 初始化数据 - 结束的时候
            //     this.detailsData.status = 2
            //     clearInterval(this.tiem2)
            //   }
            //   i++

            // }, 2000)
          }
        }
      })
    },
    clearData(slotSucc) {
      // this.detailsData.win_user_id = Data.win_user_id;
      this.$refs.audio.pause()
      this.indexBox = 0
      this.activeStatus = false;
      clearTimeout(this.time1)
      clearTimeout(this.tiem)
      this.openAn = false
      this.moneyJieShu = true
      this.detailsData.game_arena_player.map(item => {
        item.money = 0
        item.success = false
        item.gun = false
        item.game_award_pk.forEach(it => {
          it.gun = false
        })
        slotSucc.forEach(t => {
          if (item.user_id == t) {
            item.success = true
          }
        })
        return item
      })
      let i = 0
      this.tiem2 = setInterval(() => {
        // if(i > this.detailsData.game_arena_player.length + 1){
        //   this.getPKarenaDetail() // 初始化数据 - 结束的时候
        //   this.detailsData.status = 2
        //   clearInterval(this.tiem2)
        // }
        try {
          this.detailsData.game_arena_player.forEach(item => {
            // 金币
            item.money = ((item.money * 1) + (item.game_award[i].skins.bean * 1)).toFixed(2)
          })
        } catch (e) {
          i = this.detailsData.game_arena_player.length + 2
          this.getPKarenaDetail() // 初始化数据 - 结束的时候
          this.detailsData.status = 2
          clearInterval(this.tiem2)
        }
        i++

      }, 2000)
    },
    clearReast() {
      setTimeout(() => {

        for (var i = 0; i < this.detailsData.user_num; i++) {
          this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[this.slotIndex]?.box?.contains);

          this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[this.slotIndex])
        }

        this.slotNumber = this.detailsData.game_arena_box[this.slotIndex]?.box?.contains.length + 1;
        this.slotY = (this.slotNumber - 1) * 300;
        this.slotAn = false;

      }, 5600)

    },
    clearReast1() {
      setTimeout(() => {
        for (var i = 0; i < this.detailsData.user_num; i++) {
          this.slotList[i] = this.anwe_sort(this.detailsData.game_arena_box[this.slotIndex]?.box?.contains);

          this.slotList[i].push(this.detailsData.game_arena_player[i].game_award[this.slotIndex])
        }
        this.slotNumber = this.detailsData.game_arena_box[this.slotIndex]?.box?.contains.length + 1;
        this.slotY = (this.slotNumber - 1) * 300;
        this.slotAn = false;
        this.slotStatus = 0
      }, 5200)
    }

  },
}
</script>

<style scoped lang="scss">
.game_type_0 {
  color: #fff;
  background: #e95500;
  padding: 5px;
  border-radius: 5px;
}
.game_type_1 {
  @extend .game_type_0;
  background: #1e9bbb;
}
.slotMatchinaSuccess {
  position: relative;
  >.content-pk-item-pk {}
  .content_success {
    background-image: url("../../../assets/img/pk/content_success.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    .victory {
      animation: victoryMove 1s ease 0s 1;
    }
    @keyframes victoryMove {
      0% {
        transform: scale(1)
      }
      50% {
        transform: scale(1.5)
      }
      100% {
        transform: scale(1)
      }
    }
  }
  .content_fail {
    @extend .content_success;
    background-image: url('../../../assets/img/pk/fail.png');
  }

  .content_fail_me {
    @extend .content_success;
    background-image: url('../../../assets/img/pk/blue_bg.png');
  }

  .content_add {
    @extend .content_success;
    background-image: url('../../../assets/img/pk/fail.png');
    position: relative;
    img {
      position: absolute;
    }
    .status_text {
      position: absolute;
      margin-top: 7rem;
    }
    
  }
  .content_add_me {
    @extend .content_add;
    background-image: url('../../../assets/img/pk/blue_bg.png');
  }
}

@keyframes xunzhuan {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes xunzhuan_an {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(-180deg);
    }
    75% {
      transform: rotate(-270deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  .prepare {
    animation: xunzhuan 0.5s ease 0s infinite;
  }
  .round {
    animation: xunzhuan_an 0.5s ease 0s infinite;
  }

.slotMatchina {
  display: flex;
  margin: 40px 0;
  position: relative;
  gap: 5px;
  .slotDongF {
    background-image: url('../../../assets/img/pk/fail.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
    overflow: hidden;
    position: relative;
    .line_light {
      content: "";
      position: absolute;
      top: 100px;
      width: 50%;
      left: 25%;
      z-index: 999;
      height: 1px;
      opacity: .5;
      background-image: linear-gradient( 90deg,hsla(0,0%,100%,0),#bf89fd,hsla(0,0%,100%,0));
    }
  }
  .slotDongF_me {
    @extend .slotDongF;
    background-image: url('../../../assets/img/pk/blue_bg.png');
  }

  .slotDong {
    overflow: hidden;
    width: 100%;
    height: 300px;
    margin-top: -40px;
    

    >.slotListBox {
      transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -moz-transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -webkit-transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -ms-transition: all 4.6s cubic-bezier(0, 0, 0, 1);
      -o-transition: all 4.6s cubic-bezier(0, 0, 0, 1);

      >.ls {
        height: 300px;
        text-align: center;

        >img {
          margin-top: 65px;
        }

        >p {
          font-family: Monaco;
        }

        >.slotMoney {
          margin-top: 4px;
          display: flex;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          color: #2AC88A;

          >img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }

    >.slotListBox1 {
      >.ls {
        height: 300px;
        text-align: center;

        >img {
          margin-top: 65px;
        }

        >p {
          font-family: Monaco;
        }

        >.slotMoney {
          margin-top: 4px;
          display: flex;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          color: #2AC88A;

          >img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  >div {
    flex: 1;
    background: rgba(34,21,97,0.5);
    // margin: 0 .5px;
    position: relative;
    

  }
}
.active_bg {
  background-image:url('../../../assets/img/pk/content_2_1_bg.png') !important;
}
.history_bg {
  .weaponBox:not(:nth-child(1)) {
    &::before {
      content: url('../../../assets/img/pk/arrow.png');
      position: absolute;
      left: -13%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.weaponBox {
  margin-top: -20px;
  background-image: url("../../../assets/img/pk/content_2_bg.png");
  background-repeat: no-repeat;
  background-position: center 100%;
  img {
    margin-top: -30px;
  }
  >.weapon {
    position: absolute;
    height: 80px;
    width: auto;
    z-index: 99;
    left: 50%;
    top: 45px;
    margin-left: -53px;
  }
}
.addPkF {
  display: flex;
  align-items: center;
  height: 160px;
  justify-content: center;
}
.addPk {
  border: none;
}
.other_status {
  position: absolute;
  background-image: url("../../../assets/img/pk/fail.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .status_text {
    margin-top: 7rem;
  }
  .waitimg {
    width: 122px;
  }
  >div {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    position: absolute;
  }
}

.other_status_me {
  @extend .other_status;
  background-image: url("../../../assets/img/pk/blue_bg.png");
}

.gunboxtext {
  font-size: 12px;
  position: absolute;
  top: 10px;
  color: #ddd
}

.active_light {
  position: relative;

  &::before {
    content: '';
    /*background-image: url("../../assets/img/pk/100skins-yes.png");*/
    width: 100%;
    left: 0 !important;
    position: absolute;
    top: 100%;
    left: 0 !important;
    z-index: -1;
    animation: light 1s;
    box-shadow: 0 0 50px 20px rgba(255, 0, 0, .8);
    animation-iteration-count: 2;
    opacity: 0;
  }
}

.active_light_err {
  position: relative;

  &::before {
    content: '';
    background-repeat: no-repeat;
    background-position: center;

    width: 100%;
    left: 0 !important;
    position: absolute;
    top: 100%;
    left: 0 !important;
    z-index: -1;
    animation: light 1s;
    box-shadow: 0 0 50px 20px rgba(63, 63, 63, .8);
    animation-iteration-count: 2;
    opacity: 0;
  }
}

.successAndErr {
  display: none;
}

.loading-zhuan {
  animation: clockwise_rotation 2.5s linear infinite;
}

/* 定义动画 */

.an .an-item {
  // transition: all 9s cubic-bezier(0, 0, 0, 1) .1s;
  // transform: translateY(-21000px)
  animation-play-state: running;
  animation: mymove 9.4s cubic-bezier(0, 0, 0, 1) .1s infinite;
}

.an1 .an-item {
  animation-play-state: paused
}

@keyframes mymove {
  0% {
    transform: translateY(0px)
  }

  100% {
    transform: translateY(-21000px)
  }
}




.content {
  width: 90%;
  margin: 0 auto;

  @media (max-width:1200px) {
    width: 96%;
    left: 2%;
  }

  &-bout {
    height: 150px;
    width: 100%;
    padding: 0 15px;

    &-sum {
      padding: 0 20px 0 0px;

      @media (max-width:1200px) {
        display: none;
      }
    }

    &-box {
      &-item {
        margin: 0 10px;
        position: relative;

        & img {
          @media (max-width:1200px) {
            width: 70px !important;
          }
        }

        &-index {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          background-color: rgb(114, 123, 140);
        }
      }

      & .active div:nth-child(2) {
        border: solid 1px #ffc67bb4;
        border-radius: 4px;
        box-shadow: 0 0 20px #FFC67B;
      }
    }

    &-add-pk {
      @media (max-width:1200px) {
        display: none;
      }
    }
  }

  &-pk {
    &-item {
      // border-right: 2px solid #000;

      /*margin-top: 50px;*/
      &-sum {
        height: 80px;
        padding: 0 30px;
      }

      &-pk {
        /*background-color: rgba(42,42,42,.2);*/
        overflow: hidden;
        height: 350px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;

        &::before {
          /* 中将标识 */
          /*content: url("../../assets/img/aysmk.png");*/

        }

        &-box {
          height: 200px;

          &-item {
            background-color: rgba(47, 39, 66, .2);
            /*border-top: 1px solid rgba(251,202,1,1);*/
            /*background-image: linear-gradient(0deg,rgba(251,202,1,1), rgba(0,0,0,0));*/
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
            padding: 0px;
            height: 200px;
            position: relative;

            /*margin: 0 5px;*/
            &-gun {
              height: 150px;

              @media (max-width: 1200px) {
                width: 100%;
              }
            }
          }
        }
      }

      &-pk-bg {}

      & .active {
        /*background-image: url("../../assets/img/success.png");*/
        background-size: 100%;
      }

      & .error {
        /*background-image: url("../../assets/img/error.png");*/
        background-size: 100%;
      }
    }
  }

  &-award {
    &-img {
      height: 80px;

      @media (max-width:1500px) {
        width: 96%;
        height: auto;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 50px 0;
    position: relative;
    border: none;
    .item {
      margin: 5px 0;
      // background: rgba($color: #8967E0, $alpha: 0.15);
      background-image: radial-gradient(circle at bottom, #6c62a5 -50%, #221561);
      width: calc(100% - 10px);
      height: 100%;
      // padding: 5% 0;
      border-radius: 5px;
      text-align: center;
    }
    .bottom_line {
      position: absolute;
      height: 0.1rem;
      background: #8f4aff;
      bottom: 6px;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
    }

    &-active {
      &::before {
        content: '';
        width: 50%;
        left: 25%;
        height: 1px;
        background-size: 100%;
        position: absolute;
        top: -0px;
        background-color: red;
        z-index: -1;
        animation: light 1s;
        box-shadow: 0 0 10px 2px rgba(255, 255, 255, .5);
        animation-iteration-count: 2;
        opacity: 0;
      }
    }
  }
}

.pk-top-header {
  height: 66px;
  background: #312D66;
  opacity: 0.8;
  border-radius: 10px 10px 0px 0px;
}
.return {
  padding: 10px 20px;
  background: #3F389B;
  border: 2px solid #6D68D8;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.pk_pk_pk {
  /*background: linear-gradient(rgba(15, 14, 43,1),rgba(31, 28, 79,1));*/
}

@keyframes light {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.PK_gun_index {
  // background-color: #0e0e1e;
  --gradient-color: 255, 255, 255;
  --gradient-opacity: 0.2;
  border-style: solid;
  border-width: 1px;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, rgba(var(--gradient-color), 0), rgba(var(--gradient-color), 0) 28%, rgba(var(--gradient-color), var(--gradient-opacity)) 50%, rgba(var(--gradient-color), 0) 74%, rgba(var(--gradient-color), 0) 0%);
}

.content-pop {
  width: 800px;
  left: calc(50% - 350px);
  top: 100px;
  height: 650px;

  &-box {
    height: 450px;
    width: 720px;
    margin: 0 auto;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      border: 1px solid #000000;
      width: 160px !important;
      background-color: #090827;

      &:hover &-add {
        display: block;
      }

      &-add {
        display: none;
      }
    }

    & .active {
      background-color: var(--main-yellow);

      &::before {}
    }
  }

  &-lock {
    margin-top: 20px;
    height: 550px;
    overflow: scroll;
    overflow-x: hidden;
    padding: 30px;

    &-item {
      position: relative;
      background-color: var(--f-main);
      border: 1px solid #000;
      /*border: 1px solid var(--main-brown);*/
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    left: 0;
    height: 650px;
  }
}

.userinfo {
  height: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  >div:nth-child(1) {
    display: flex;
    align-items: center;
  }
  >div {
    text-align: center;
  }
  
}
.pk_item {
  &::before {
    background-image: none;
  }
}
</style>
