<template>
    <div class="record">
        <div class="title">
            <label v-for="(item,index) in title" :key="index">
                {{item}}
            </label>
        </div>
        <div class="content">
            <div v-for="(item,index) in records" :key="index">
                <div><img src="@/assets/img/ADT/home/UP@2x.png" />{{item.bean }}</div>
                <div>{{item.price }}</div>
                <div><p>{{ [100, 99].includes(item.pay_way) ? item.trade_no : item.code }}</p></div>
                <div>{{item.status_alias }}</div>
                <div>{{item.created_at}}</div>
            </div>
        </div>
        <div class="mt-3">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="recordstotal"
            :page-size="recordspageSize"
            @current-change="currentChange">
          </el-pagination>
        </div>
    </div>
</template>

<script>
import $api from '@/api/user/index';
export default {
    name: "index",
    data() {
        return {
            title:[
                `充值金额`,
                `充值价格`,
                `订单号`,
                `状态`,
                `时间`
            ],
            records:[],
            recordstotal:0,
            recordspageSize:0
        }
    },
    filters:{
      change(val){
        switch (val) {
          case 1:
            return '盲盒开箱';
          case 2:
            return "盲盒对战";
          case 3:
            return "幸运饰品";
          case 4:
            return "装备分解";
          case 5:
            return "充值";
          case 6:
            return "首充奖励";
          case 7:
            return "被邀充值收益";
        }
      }
    },
    created(){
        this.getRecharge_record()
    },
    methods:{
        // 充值记录
        async getRecharge_record(page = 1) {
            try {
                let { data, code } = await $api.getRecharge_record(page)
                if (code == 500) return
                this.records = data.data
                // this.records = this.records.concat(this.records,this.records)
                this.recordstotal = data.total
                this.recordspageSize = data.per_page
            } catch (e) {
                console.log(e)
                return
            }
        },
        currentChange(val){
            this.getRecharge_record(val)
        },
    }
}
</script>

<style lang="scss" scoped>
.record{
    padding: 40px;
    background-color: rgba(25, 53, 74, 0.7764705882);
    border-radius: 6px;

    @media (max-width:600px) {
        background-color: transparent;
        padding: 10px;
    }
    > .mt-3{
        display: flex;
        justify-content: center;
        * {
            font-family: Monaco;
        }
    }
    > .title{
        display: flex;
        height: 60px;
        background-color: #234e6ab3;
        color: #2EAACB;
        border-radius: 4px;
        @media (max-width:600px) {
            height: 50px;
            background-color: #175b6c44;
        }
        > label{
            flex: 1;
            text-align: center;
            line-height: 60px;
            font-weight:bold;
            font-size: 18px;
            @media (max-width:600px) {
                font-size: 14px;
                height: 50px;
                line-height: 50px;
            }
        }
        > label:last-child{
            display: none;
        }
    }
    > .content{
        background-color: #00000036;
        height: 54vh;
        > div{
            display: flex;
            border-bottom: solid 1px #1A3247;
            > div{
                flex: 1;
                text-align: center;
                height: 60px;
                line-height: 60px;
                font-family: Monaco;
                font-size: 18px;
                color: #ddd;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width:600px) {
                    font-size: 12px;
                    height: 50px;
                    line-height: 50px;
                }
                > img{
                    height: 20px;
                    margin-right: 4px;
                    @media (max-width:600px) {
                        height: 16px;
                    }
                }
            }
            @media (max-width:600px) {
                > div:nth-child(3n){
                    > p{
                        width: 80px;
                        font-family: Monaco;
                        word-wrap: break-word;
                    }
                }
            }
            > div:last-child{
                display: none;
            }
        }
        @media (max-width:600px) {
            > div:last-child{
                border-bottom: none;
            }
        }
    }
}
</style>