<template>
  <div class="traditional_index">
    <div class="traditional_main">
      <div class="traditional_center">
        <!-- <div class="traditional_login flex justify-between">
          
          <div class="login_r" v-if="!noLogin">
            <div class="login-btn _a" @click="login()">登录/注册</div>
          </div>
          <div @click="webIndex" class="login_IS_r flex" v-else>
             <img width="40px" height="40px" class="rounded-circle" :src="user_info.avatar" alt="">
              <div class="info_name">{{user_info.name}}</div>
          </div>
        </div> -->
        <img src="@/assets/love/biaoti.webp" />
        <p class="rule" v-if="sUserAgent != 'web'" @click="activityRule"><img src="@/assets/love/10.png" width="20"
            height="20" />活动规则</p>
        <div class="traditional_time text-center">活动时间:{{ skinsDate.start }}-{{ skinsDate.end }} <div
            v-if="sUserAgent == 'web'" class="regulation _a" @click="activityRule"><img src="@/assets/love/10.png"
              width="20" height="20" />活动规则</div>
        </div>
        <!-- <div class="text-center title1">
          <img src="../../assets/img/traditional/title_01.png"/>
        </div>
        <div class="jackpot">
          <div class="record_Award _a" @click="getLotteryList()">获奖记录</div>
          <div class="regulation _a" @click="activityRule">活动规则</div>
        </div> -->
        <pc :item="skinsList" @awardsView="awardsView" v-if="sUserAgent !== 'phone'"></pc>
        <phone :item="skinsList" @awardsView="awardsView" v-else></phone>
        <div class="firecrackers_btn_h5" v-if="sUserAgent === 'phone'">
          <div class="h2">幸运值:{{ luckValue }}</div>
          <el-progress color="#FD527C" define-back-color="#150A0D" :show-text="false" :stroke-width="10"
            :percentage="luckNun"></el-progress>
          <div class="tips_b">
            幸运值达到<span class="text-yellow">2666</span><br>
            必出<span class="text-yellow">折叠刀(★)|传说(崭新出厂)</span>
          </div>
          <div class="open_num openNumwarp">
            可开启次数<span class="text-yellow">{{ num }}</span>
          </div>
        </div>
        <div class="firecrackers_btn flex justify-center">
          <div class="firecrackers_btn_l fire_btn">
            <img src="../../assets/love/xin.webp" style="width:200px !important">
            <img class="_a" @click="runALottery(1)" src="../../assets/love/8.png" />
          </div>
          <div class="firecrackers_btn_main" v-if="sUserAgent !== 'phone'">
            <div class="h2">幸运值:{{ luckValue }}</div>
            <div class="press">
              <img src="@/assets/love/7.png" width="20" />
              <el-progress color="#FD527C" define-back-color="#150A0D" :show-text="false" :stroke-width="10"
                :percentage="luckNun"></el-progress>
              <img src="@/assets/love/7.png" width="20" />
            </div>
            <div class="tips_b">
              幸运值达到<span class="text-yellow">2666</span>
              必出<span class="text-yellow">{{ `${'折叠刀(★)|传说(崭新出厂)'.replace(/\s*/g, "")}` }}</span>
            </div>
            <div class="open_num">
              可开启次数: <span class="text-yellow">{{ num }}</span>
            </div>
          </div>
          <div class="firecrackers_btn_r fire_btn">
            <img src="../../assets/love/xin.webp" style="width:200px !important">
            <img class="_a" @click="runALottery(10)" src="../../assets/love/9.png">
          </div>
        </div>

        <h2 class="mublack_title" v-if="recordList.length!=0">获奖记录</h2>

        <div class="recoad" v-if="recordList.length!=0">
          <div>
            <div class="custom_record_main">
              <div class="flex justify-between box-item" v-for="(item, a) in recordList" :key="a">
                <div class="flex">
                  <div><img :src="item.skins_cover" /></div>
                  <div class="record_name">{{ item.skins_name }}</div>
                </div>
                <div class="record_time">{{ item.created_at }}</div>
              </div>
            </div>
            <el-pagination background :current-page.sync="currentPage" :small="sUserAgent === 'phone'"
              layout="prev, pager, next" :total="total" :page-size="10" @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="traditional_roll">
      <div class="roll_center">
        <div class="text_title text-center">
          <img src="../../assets/img/traditional/title_02.png"/>
        </div>
        <div class="roll_main">
          <div class="item_Box">
            <div class="sign_item">
              <div class="item" :class="{'act':item.status>-1,'static':item.status===1||item.is_past===1}" v-for="(item,index) in rollList " :key="index">
                <img :src="require(`../../assets/img/traditional/roll/${item.date}.png`)" alt="" class="not_img"/>
                <div class="sign_text">{{item.status===1?'已签到':item.is_past===1?'已过期':'待签到'}}</div>
                <div class="sign_btn" @click="signInDay(item)">{{item.status===1?'已签到':item.is_past===1?'未签到':'立即签到'}}</div>
              </div>
           </div>
        </div>
        <div class="big_roll"> 
          <div class="big_sign">已签到天数：<span style="color:#FFF000">{{sign_count}}</span></div>
          <div class="not_roll" @click="toRoll" :class="{'act':isUnLock}"></div>
        </div>
        <div class="sign_rule" @click="rollRule()">活动说明 &gt;&gt;</div>
        <div class="sign_foot text-center">活动最终解释权归MUCSGO所有</div>
      </div>
    </div>
    </div> -->
    <!-- 提示消息和获奖记录弹窗 -->
    <el-dialog :custom-class="`${numStatus ? `heTraditional heTraditionalhdpcs` : `heTraditional`}`" title=""
      :width="sUserAgent !== 'phone' ? '600px' : '98%'" :show-close="false" :visible.sync="outerVisible">
      <div class="heTraditional_close _a" @click="outerVisible = false"></div>
      <div v-if="showTxt === 'award'">
        <div class="heTraditional_head">奖励详情</div>
        <div class="heTraditional_main">
          <img :src="presentation.cover">
        </div>
        <div class="heTraditional_name">{{ presentation.name }}-{{ presentation.dura_alias }}</div>
      </div>
      <div class="heTraditional_bearThePalm" v-if="showTxt === 'bearThePalm'">
        <div class="heTraditional_head">恭喜您获得</div>
        <div class="heTraditional_bearThePalm_hid">
          <div class="heTraditional_bearThePalm_main " :class="{ 'hid-1': bearTheList.length === 1 }"
            v-for="item in bearTheList" :key="item.id">
            <div class="heTraditional_main">
              <img :src="item.cover">
            </div>
            <div class="heTraditional_name text-overflow-1">{{ item.name }}-{{ item.dura_alias }}</div>
          </div>
        </div>
      </div>
      <div class="heTraditional_explain" v-else-if="showTxt === 'explain'">
        <div class="explain_top">活动说明</div>
        <div class="text">
          <div class="">1.活动期间每累积充值50U币获得1颗爱心值;</div>
          <div class="">2.每击中一次爱心值可获得1点幸运值;</div>
          <div class="">3.幸运值越高则获得高级饰品概率越大;</div>
          <div class="">4.幸运值达到2666必获得终极大奖，幸运值达到1314保底出现一次一等奖，幸运值达到520保底出现一次二等奖，幸运值达到200保底出现一次三等奖;</div>
          <div class="">5.活动奖励从活动开始之日起每七天刷新1次，刷新后爱心值和幸运值将重置;</div>
          <div class="">6.当获得终极饰品后，本轮幸运520活动完成，幸运值和爱心值待幸运值重置后重新累计;</div>
        </div>
      </div>
      <div class="heTraditional_explain" v-else-if="showTxt === 'roll'">
        <div class="explain_top">活动说明</div>
        <div class="text">
          <div class="">1.活动期间每日完成登录，并完成任意充值即可获得当日签到资格;</div>
          <div class="">2.活动期间，完成所有签到，可进入春节专属ROLL房。</div>
        </div>
      </div>
    </el-dialog>
    <!-- 电脑端十连抽大屏弹窗 -->
    <el-dialog custom-class="heTraditional heTraditional_pc" title="" width="1200px" :show-close="false"
      :visible.sync="outerVisibleBig">
      <div class="heTraditional_close _a" @click="outerVisibleBig = false"></div>
      <div class="heTraditional_bearThePalm" v-if="showTxt === 'bearThePalm'">
        <div class="heTraditional_head">恭喜您获得</div>
        <div class="heTraditional_bearThePalm_hid">
          <div class="heTraditional_bearThePalm_main " :class="{ 'hid-1': bearTheList.length === 1 }"
            v-for="item in bearTheList" :key="item.id">
            <div class="heTraditional_main">
              <img :src="item.cover">
            </div>
            <div class="heTraditional_name text-overflow-1">{{ item.name }}-{{ item.dura_alias }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 获奖记录 -->
    <el-dialog custom-class="custom_record" title="" :width="sUserAgent !== 'phone' ? '800px' : '98%'" :show-close="false"
      :visible.sync="recordShow">
      <div class="heTraditional_close _a" @click="recordShow = false"></div>
      <div>
        <div class="custom_record_main">
          <div class="flex justify-between box-item" v-for="(item, a) in recordList" :key="a">
            <div class="flex">
              <div><img :src="item.skins_cover" /></div>
              <div class="record_name">{{ item.skins_name }}</div>
            </div>
            <div class="record_time">{{ item.created_at }}</div>
          </div>
        </div>
        <el-pagination background :current-page.sync="currentPage" :small="sUserAgent === 'phone'"
          layout="prev, pager, next" :total="total" :page-size="10" @current-change="currentChange">
        </el-pagination>
      </div>
    </el-dialog>
    <div class="masklove" v-if="maskloveStatus">
      <img src="@/assets/love/yanhua.webp" />
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import sUserAgent from "@/assets/js/common.js";
import { mapState } from "vuex";
import pc from "./coment/pc.vue";
import phone from "./coment/phone.vue";
import $api from '@/api/traditional';
export default {
  data() {
    return {
      maskloveStatus: false,
      recordShow: false,
      recordList: [],
      outerVisible: false,
      outerVisibleBig: false,
      showTxt: 'award',
      lotteryList: [],
      noLogin: false,
      presentation: {},
      skinsList: [],
      luckValue: 0,
      num: 0,
      skinsDate: {
        end: "",
        start: "",
      },
      bearTheList: [],
      rollList: [], //status-1未充值 0已充值未签到 1已签到
      sign_count: 0, //签到总数
      isUnLock: false,  //是否已解锁: true是 false否
      luckNun: 0,
      loading: false,
      currentPage: 1,
      total: 0,
      numStatus: true
    }
  },
  components: { pc, phone },
  computed: {
    ...mapState(['user_info', 'steam_handle', 'login_top', 'login_is_show', 'is_show_nav_pc', 'is_show_nav_phone', 'login_top_is_xie_yi']),
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  created() {
    this.noLogin = getToken()
    this.getJackpotList()
    this.getLotteryList()
    // this.getRollList()
  },
  methods: {
    webIndex() {
      this.$router.push('/')
    },

    // 获取奖池数据
    getJackpotList() {
      $api.luckStar_list().then(res => {
        this.skinsList = res.data.skins
        this.skinsDate = res.data.date
        this.num = res.data.num
        this.luckValue = res.data.luckValue
        this.luckNun = (Number(((res.data.luckValue / 2666) * 100).toFixed(2)))
      })
    },
    signInDay(data) {
      this.$message.closeAll()
      if (data.is_past === 1) {
        return false
      } else if (data.status === -1) {
        return this.$router.push({ path: '/user/recharge' })
      } else if (data.status === 1) {
        return this.$message('已签到');
      } else if (data.status === 0) {
        $api.roll_open({ date: data.date }).then(() => {
          this.$message.success('签到成功');
          this.getRollList()
        })
      }
    },
    // 获取开奖记录列表数据
    getLotteryList(val = 1) {
      this.currentPage = val
      this.$message.closeAll()
      $api.luckStartList(val).then(res => {
        if (res.code === 200) {
          // if (res.data.data.length === 0) {
          //   return this.$message('暂无获奖记录');
          // }
          this.recordList = res.data.data
          this.total = res.data.total
          // this.recordShow = true
        }
      })
    },
    currentChange(val) {
      this.getLotteryList(val)
    },
    // 获取roll列表数据
    getRollList() {
      $api.roll_index().then(res => {
        if (res.code === 200) {
          this.rollList = res.data.list
          this.sign_count = res.data.sign_count
          this.isUnLock = res.data.isUnLock
        }
      })
    },
    login() {
      this.$router.push({ path: '/' })
    },
    awardsView(data) {
      this.showTxt = 'award'
      this.presentation = data
      this.outerVisible = true
      this.numStatus = true;
    },
    activityRule() {
      this.showTxt = 'explain'
      this.outerVisible = true
    },
    rollRule() {
      this.showTxt = 'roll'
      this.outerVisible = true
    },
    toRoll() {
      if (this.isUnLock) {
        this.$router.push({ path: '/MUrollHome' })
      }
    },
    //启动开奖
    runALottery(val) {
      this.$message.closeAll()
      if (!this.num) {
        return this.$message('可开启次数不足');
      }
      if (this.loading) return false
      setTimeout(() => {
        this.loading = false
      }, 1000);
      this.loading = true
      $api.luckStar_open({ num: val }).then(res => {
        if (res.code === 200) {
          this.bearTheList = res.data.list
          this.showTxt = 'bearThePalm'
          this.maskloveStatus = true;
          setTimeout(() => {
            this.maskloveStatus = false
          }, 1160)
          console.log(val)
          if (val == 10) {
            this.numStatus = false
          } else if (val == 1) {
            this.numStatus = true
          }
          setTimeout(() => {
            if (val === 10 && this.sUserAgent !== 'phone') {
              this.outerVisibleBig = true
            } else {
              this.outerVisible = true

            }
            this.luckNun = (Number(((res.data.luckValue / 2666) * 100).toFixed(2)))
            this.luckValue = res.data.luckValue
            this.num = res.data.num
            this.getLotteryList()
          }, 1400)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.custom_record_main {
    box-shadow: none !important;
    
    color: #fff !important;
    padding: 20px;
    border-radius: 20px;

    @media (max-width:1300px) {
      padding: 0 10px;
      margin-bottom: 20px;
    }

    .custom_record_main {
      margin-bottom: 20px;

      @media (max-width:1300px) {
        margin-bottom: 20px;
        
      }
    }

    // .heTraditional_close{
    //   position: absolute;
    //   top: -20px;
    //   right: -40px;
    //   width: 100px;
    //   height: 80px;
    //   // background: url('../../assets/love/close.png') no-repeat !important;
    //   @media (max-width:1300px) {
    //     // background: url('../../assets/love/close.png') no-repeat !important;
    //     background-size: 100% 100% !important;
    //     position: absolute;
    //     top: -10px;
    //     right: -0;
    //     width: 36px;
    //     height: 30px;
    //   }
    // }
    .box-item {
      padding: 10px;
      color: rgba(255, 255, 255, 0.861) !important;
      border-bottom: 1px solid #ffffff2a;
      line-height: 30px;
      display: flex;
      align-items: center;
      font-family: Monaco;
      @media (max-width:600px) {
        padding: 10px 0 !important;
      }
      > .record_time{
      font-family: Monaco;
    }

      > .flex{
        align-items: center;
      }

      @media (max-width:1300px) {
        padding: 4px;
        line-height: 24px;
        font-size: 12px;
      }

      img {
        height: 60px;

        @media (max-width:1300px) {
          height: 35px;
        }
      }
    }

    .record_name {
      margin-left: 20px;
      font-family: Monaco;
      @media (max-width:600px) {
        margin-left: 10px;
      }
    }
    
  }
.mublack_title {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  background-image: url('../../assets/img/news/lanmu.png');
  color: #FFC67B;
  margin-top: 80px;

  @media (max-width:600px) {
    font-size: 20px;
    background-size: 1400px;
    margin-bottom: 10px;
    margin-top: 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.masklove {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000072;
  top: 0px;
  left: 0px;

  @media (max-width:600px) {
    position: fixed;
  }
}

.el-progress-bar__outer {
  border: solid 1px #DA476B;
}

@media (max-width:600px) {
  .openNumwarp {
    color: #ddd;
    font-weight: 300;

    >span {
      color: #DA476B !important;
    }
  }
}

.traditional_index {
  position: relative;

  // background: #B84226;
  .traditional_main {
    max-width: 1720px;
    margin: 0 auto;
    width: 100%;
    background: url('../../assets/love/1.jpg');
    background-size: 100% 1085px;
    background-repeat: no-repeat;
    position: relative;

    @media (max-width:1300px) {
      background: url('../../assets/love/bg.png');
      background-size: 100% auto;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
    }

    .traditional_center {
      // max-width: 1360px;
      margin: 0 auto;
      text-align: center;

      @media (max-width:1300px) {
        width: 100%;

        >.rule {
          display: flex;
          color: #DA476B;
          font-size: 14px;
          justify-content: center;
          align-items: center;

          >img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }

      >img {
        margin: 20px 0;
        margin-top: 0px;

        @media (max-width:600px) {
          width: 200px;
          margin-bottom: 10px;
        }
      }

      .traditional_login {
        max-width: 1360px;
        width: 100%;
        position: absolute;
        top: 0;

        .login_r {
          width: 200px;

          .login-btn {
            width: 160px;
            height: 46px;
            margin-top: 20px;
            text-align: center;
            line-height: 46px;
            color: #fff !important;
            background-image: linear-gradient(90deg, #1E7A91, #07b9e6);
            background-blend-mode: normal, normal;
            border-radius: 8px;
            border: 1px solid #1E7A91;
          }
        }

        .login_IS_r {
          width: 200px;
          margin-top: 20px;
          line-height: 46px;

          @media (max-width:1300px) {
            width: 140px;
            margin-top: 0px;
            line-height: 36px;
          }

          .info_name {
            margin-left: 20px;
            line-height: 40px;

            @media (max-width:1300px) {
              margin-left: 8px;
              font-size: 14px;
              line-height: 36px;
            }
          }
        }

        @media (max-width:1300px) {
          img {
            height: 36px;
            margin: 2px;
          }

          .login_r {
            width: 130px;

            .login-btn {
              width: 120px;
              height: 32px;
              font-size: 14px;
              margin-top: 4px;
              text-align: center;
              line-height: 32px;
              border-radius: 4px;
            }
          }
        }
      }

      .traditional_time {
        font-size: 18px;
        font-family: Monaco;
        color: #ddd;
        line-height: 65px;
        font-weight: 300;
        position: relative;
        text-align: center;

        @media (max-width:1300px) {
          font-weight: 100;
          font-size: 12px;
          font-weight: bold;
          padding-top: 10px;
          font-weight: 300;
          line-height: 25px;
          margin-bottom: 20px;
        }

        >.regulation {
          position: absolute;
          right: 130px;
          top: 0px;
          color: #DA476B;
          display: flex;
          align-items: center;
          font-weight: 400;

          >img {
            margin-right: 5px;

          }
        }
      }

      .title1 {
        margin-top: 75px;

        @media (max-width:1300px) {
          margin-top: 35px;
        }

        img {
          @media (max-width:1300px) {
            width: 100%;
          }
        }
      }

      .jackpot {
        background-size: 1540px 530px;
        background-image: url(../../assets/love/2.png) !important;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        margin: 0 auto;
        width: 1500px;
        margin-bottom: 0px;
        height: 530px;
        padding-top: 48px;
        margin-top: -18px;

        @media (max-width:1300px) {
          height: 45px;
          background-image: none;
          width: 100%;
          height: 320px;
          padding-top: 12px;
        }

        .record_Award {
          position: absolute;
          left: 10px;
          line-height: 40px;
          padding: 0 20px;
          background: url('../../assets/img/traditional/roll/sign_btn.png') no-repeat;
          background-size: 100% 100%;
          border-radius: 20px;

          @media (max-width:1300px) {
            top: 10px;
            font-size: 12px;
            line-height: 30px;
            border-radius: 30px;
          }
        }

        .regulation {
          position: absolute;
          right: 10px;
          font-size: 14px;
          padding: 0 20px;
          line-height: 40px;
          background: url('../../assets/img/traditional/roll/sign_btn.png') no-repeat;
          background-size: 100% 100%;
          border-radius: 20px;

          @media (max-width:1300px) {
            top: 10px;
            font-size: 12px;
            line-height: 30px;
            border-radius: 30px;
          }
        }
      }

      .jackpot_main:first-child {
        margin-bottom: 10px;
      }

      .jackpot_main {
        display: flex;
        justify-content: center;

        @media (max-width:1300px) {
          margin: 0 auto 10px auto;
          width: 100%;
          justify-content: space-evenly;
        }

        .jackpot_main_item.start {
          background-size: 220px 210px !important;
          width: 220px;
          height: 210px;
          position: relative;
          background: url('../../assets/love/6.png') no-repeat;

          @media (max-width:1300px) {
            height: 5.5rem;
            width: 10rem;
            background-size: 100% 100% !important;
          }

          .img_bg {
            background-repeat: no-repeat;
          }

          .item_text {
            color: #9f1c12;

            @media (max-width:1300px) {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }

        .jackpot_main_item.end {
          background-size: 220px 210px !important;
          width: 220px;
          position: relative;
          height: 210px;
          background: url('../../assets/love/6.png') no-repeat;

          @media (max-width:1300px) {
            height: 5.5rem;
            width: 10rem;
            background-size: 100% 100% !important;
          }

          .img_bg {
            background-repeat: no-repeat;
          }

          .item_text {
            color: #9f1c12;

            @media (max-width:1300px) {
              font-size: 12px;
              // line-height: 20px;
              // height: 20px;
            }
          }
        }

        .jackpot_main_item {
          padding: 14px 0px;
          background: url('../../assets/love/6.png') no-repeat;
          background-size: 220px 210px;
          width: 220px;
          cursor: pointer;
          margin: 0 10px;
          height: 210px;

          @media (max-width:1300px) {
            background-size: 100%;
            height: 5.5rem;
            flex: 0 0 22%;
            margin: 0px;
            max-width: 24%;
            background-size: 100% 100%;
          }

          .img_bg {
            background-repeat: no-repeat;
            background-position: center;
            margin: 0 auto;

            @media (max-width:1300px) {
              background-size: 100% 100%;
            }

            img {
              height: 105px;
              margin-top: 14px;

              @media (max-width:600px) {
                height: 40px;
                margin-top: 0px;
              }
            }
          }

          // .img_bg1{
          //   background-image: url('../../assets/img/traditional/img_bg_01.png');
          // }
          // .img_bg2{
          //   background-image: url('../../assets/img/traditional/img_bg_02.png');
          // }
          // .img_bg3{
          //   background-image: url('../../assets/img/traditional/img_bg_03.png');
          // }
          // .img_bg4{
          //   background-image: url('../../assets/img/traditional/img_bg_04.png');
          // }
          .item_text {
            font-size: 16px;
            height: 20px;
            font-family: SimSun;
            font-weight: 600;

            @media (max-width:1300px) {
              width: 100%;
              font-size: 12px;
              margin-top: -15px;
              // display: none;

              // height: 20px;
              // font-weight: 100;
              // line-height: 20px;
            }
          }

          .tip {
            display: none;
            font-size: 14px;
            font-family: SimSun;
            font-weight: 900;
            color: #ffe586 !important;
            line-height: 20px;
            text-align: center;
          }
        }

        // .jackpot_main_item:hover{
        //   background: url('../../assets/img/traditional/center_01.png') no-repeat;
        //   background-size: 100% 100%;
        //   .hover_none{
        //     display: none;
        //   }
        //   .tip{
        //     display: block;
        //   }
        //   @media (max-width:1300px) {
        //     background: url('../../assets/img/traditional/center.png') no-repeat;
        //     background-size: 100% 100%;
        //   }
        // }
        // .jackpot_main_item.end:hover{
        //   background: url('../../assets/img/traditional/end_01.png') no-repeat;
        //   background-size: 100% 100%;
        //   @media (max-width:1300px) {
        //     background: url('../../assets/img/traditional/end.png') no-repeat;
        //     background-size: 100% 100%;
        //   }
        // }
        // .jackpot_main_item.start:hover{
        //   background: url('../../assets/img/traditional/start_01.png') no-repeat;
        //   background-size: 100% 100%;
        //   @media (max-width:1300px) {
        //     background: url('../../assets/img/traditional/start.png') no-repeat;
        //     background-size: 100% 100%;
        //   }
        // }
      }

      .firecrackers_btn {
        .fire_btn {
          text-align: center;
          width: 300px;

          @media (max-width:1300px) {
            width: 35%;
            margin: 2%;

            >img:first-child {
              display: none;
            }
          }

          >img {
            width: 100%;
            margin-bottom: 20px;

            @media (max-width:1300px) {
              margin-bottom: 10px;
            }
          }
        }

        &_main {
          text-align: center;
          color: #fff;
          margin: 0 80px;

          .press {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            width: 300px;

            >.el-progress {
              width: 240px;
            }

            >img {
              margin: 0 10px;
            }
          }

          .h2 {
            padding-top: 60px;
            line-height: 40px;
            font-weight: 300;
            font-size: 20px;
            font-family: Monaco;
          }

          .tips_b {
            margin-top: 20px;
            line-height: 2;
            font-weight: 300;

            >span {
              color: #DA476B;
              font-family: Monaco;
            }
          }

          .open_num {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 300;
            font-family: Monaco;

            >span {
              font-family: Monaco;
              color: #DA476B;
              font-weight: bold;
            }
          }
        }
      }

      .firecrackers_btn_h5 {
        width: 60%;
        margin: 0 auto;
        color: #EED9A2;
        font-size: 12px;
        line-height: 1.8;
        text-align: center;

        @media (max-width:600px) {
          margin-bottom: 20px;
        }


        .h2 {
          font-size: 20px;
          line-height: 30px;

          @media (max-width:600px) {
            font-weight: 300;
            color: #ccc;
            font-size: 16px;
            font-family: Monaco;
          }
        }

        .tips_b {
          margin-top: 10px;

          @media (max-width:600px) {
            color: #fff;
            font-weight: 300;
            margin-bottom: 10px;

            >span {
              color: #DA476B;
              font-family: Monaco;
            }
          }
        }
      }
    }

    .text-yellow {
      color: #FFF000;

      @media (max-width:600px) {
        font-family: Monaco;

        >span {
          color: #DA476B !important;
        }
      }
    }
  }

  ._a {
    opacity: .8;
  }

  ._a:hover {
    opacity: 1;
  }

  .heTraditional {
    padding: 50px 0;
    background: url('../../assets/love/12.png') no-repeat !important;
    background-size: 100% 100% !important;
    // height: 400px;
    box-shadow: none !important;
    position: relative;

    @media (max-width:1300px) {
      background: url('../../assets/love/12.png') no-repeat !important;
      background-size: 100% 390px !important;
      background-position: center;
      width: 90% !important;
      position: absolute;
      left: 50%;
      margin-left: -45%;
      top: 50%;
      margin-top: -220px !important;
      height: 390px;
      padding: 20px 0;
    }

    &_close {
      position: absolute;
      top: 14px;
      right: -40px;
      width: 100px;
      height: 80px;
      background: url('../../assets/love//close.png') no-repeat !important;

      @media (max-width:1300px) {
        position: absolute;
        top: 10px;
        right: 10px;
        background-size: 30px 30px !important;
        width: 30px;
        height: 30px;
      }
    }

    &_head {
      text-align: center;
      font-size: 24px;
      font-family: SimSun;
      font-weight: 900;
      color: #ddd;
      line-height: 20px;
      margin-bottom: 20px;

      @media (max-width:600px) {
        font-size: 20px;
        margin-top: 20px;
      }
    }

    &_main {
      width: 279px;
      height: 199px;
      margin: 0 auto;
      // background: url('../../assets/img/traditional/big_prize.png') no-repeat !important;
      text-align: center;

      @media (max-width:1300px) {
        height: 40px !important;
        margin-bottom: 10px !important;
      }

      img {
        width: 80%;
        margin: 0 auto;

        @media (max-width:600px) {
          height: 48px !important;
          margin: 0 auto !important;
        }
      }
    }

    &_name {
      text-align: center;
      font-size: 24px;
      font-family: SimSun;
      font-weight: bold;
      color: #ccc;
      font-family: Monaco;
      line-height: 25px;
      margin-bottom: 30px;

      @media (max-width:600px) {
        font-size: 12px !important;
        width: 80px;
        font-weight: 300;
        transform: scale(.9);
      }
    }

    //活动样式
    &_explain {
      padding: 10px 50px 0px 50px;

      @media (max-width:1300px) {
        padding: 10px 30px;
      }

      .explain_top {
        font-size: 24px;
        font-family: SimSun;
        font-weight: 900;
        color: #ddd;
        margin-bottom: 20px;

        @media (max-width:1300px) {
          font-size: 16px;
          line-height: 0.72rem;
          margin-bottom: 15px;
        }
      }

      .text {
        font-size: 16px;
        font-family: Monaco;
        font-weight: 400;
        color: #ccc;
        line-height: 32px;

        >div {
          font-family: Monaco;
        }

        @media (max-width:1300px) {
          font-size: 12px;
          line-height: 20px;
          line-height: 2;
        }
      }
    }

    //获奖列表
    &_bearThePalm_hid {
      width: calc(100% - 40px);
      margin: 0 auto;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;

      @media (max-width:1300px) {
        height: 300px;
        width: calc(100% - 10px);
        justify-content: center;
      }

      >div {
        margin: 1%;

        @media (max-width:600px) {
          margin: 0%;
        }

        .heTraditional_main {
          background-size: 100% 100% !important;
          height: 180px;
          width: 100%;
          margin-bottom: 10px;

          >img {
            width: auto;
            height: 150px;
            margin: 20px 0;
          }

          @media (max-width:1300px) {
            height: 120px;
            width: 100%;
          }
        }

        .heTraditional_name {
          font-size: 24px;
        }
      }

      .hid-1 {
        margin: 0 auto;
      }
    }
  }

  @media (max-width:600px) {
    .heTraditionalhdpcs {
      background-size: 100% 340px !important;

      .heTraditional_main {
        height: 167px !important;

        >img {
          height: 167px !important;
        }
      }

      .heTraditional_name {
        width: 240px;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px !important;
      }

      .heTraditional_bearThePalm {
        .heTraditional_main {
          height: 167px !important;

          >img {
            height: 167px !important;
          }
        }

        .heTraditional_name {
          width: 240px;
          margin: 0 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 18px !important;
        }
      }
    }
  }

  .heTraditional.heTraditional_pc {
    padding: 50px 40px;
    background: url('../../assets/love/bgsss.png') no-repeat !important;
    background-size: 100% 540px !important;
    height: 540px;

    .heTraditional_bearThePalm_hid {
      width: calc(100% - 40px);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      overflow: hidden !important;

      >div {
        width: 175px;

        .heTraditional_main {
          background-size: 100% 100% !important;
          height: 126px;
          width: 100%;

          >img {
            height: 100px;
          }
        }

        >.heTraditional_name {
          font-size: 14px;
        }
      }
    }
  }

  

  .traditional_roll {
    .roll_center {
      max-width: 1720px;
      margin: 0 auto;
      height: 1270px;
      background-image: url('../../assets/img/traditional/roll/bg_02.jpg');

      background-size: 100%;
      background-position: top;

      @media (max-width:1300px) {
        width: 100%;
        height: 520px;
      }

      .text_title {
        padding-top: 20px;

        @media (max-width:1300px) {
          img {
            width: 100%;
          }
        }
      }
    }

    .roll_main {
      width: 1176px;
      height: 1087px;
      margin: 0 auto;
      background-image: url('../../assets/img/traditional/roll/sign_bg.png');
      background-size: 100% 100%;
      padding: 204px 170px 0;
      box-sizing: border-box;
      margin: 0 auto;
      position: relative;

      .sign_foot {
        width: calc(100% - 288px);
        text-align: center;
        position: absolute;
        bottom: -10px;
        font-size: 14px;
        color: #ccc;

        @media (max-width:1300px) {
          bottom: -30px;
          width: calc(100% - 108px);
          color: #000;
          font-size: 12px;
        }
      }

      @media (max-width:1300px) {
        background-image: url('../../assets/img/traditional/h5/sign_bg.png');
        width: 100%;
        padding: 65px 55px 0 !important;
        height: 360px;
        padding: 0;
      }

      .item_Box {
        width: 100%;
        height: 415px;
        overflow-y: auto;

        @media (max-width:1300px) {
          height: 155px;
          overflow-x: auto;
          overflow-y: hidden;
        }

        .sign_item {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          @media (max-width:1300px) {
            flex-flow: inherit;
          }

          .item {
            margin: 10px 5px;

            .not_img {
              width: 124px;
              -webkit-filter: grayscale(100%);
              filter: grayscale(100%);

              @media (max-width:1300px) {
                width: 64px;
              }
            }

            .sign_text {
              font-size: 14px;
              text-align: center;
              color: #FFCD8B;

              @media (max-width:1300px) {
                font-size: 12px;
              }
            }

            .sign_btn {
              display: block;
              margin: 5px auto;
              width: 100px;
              height: 30px;
              background: url('../../assets/img/traditional/roll/sign_btn.png') no-repeat;
              background-size: 100% 100%;
              font-size: 16px;
              text-align: center;
              font-weight: 900;
              color: #FEFFFF;
              line-height: 30px;
              cursor: pointer;

              @media (max-width:1300px) {
                font-size: 12px;
                width: 60px;
              }
            }
          }

          .item.act {
            .not_img {
              filter: none;
            }
          }

          .item.static {
            .sign_btn {
              opacity: .7;
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .big_roll {
        position: absolute;
        left: 57%;
        bottom: 150px;
        transform: translateX(-50%);
        display: flex;
        align-items: center;

        @media (max-width:1300px) {
          left: 50%;
          bottom: 45px;
        }

        .big_sign {
          font-size: 18px;
          color: #EED9A2;
          margin-right: 40px;

          @media (max-width:1300px) {
            font-size: 12px;
            margin-right: 10px;
          }
        }

        .not_roll {
          width: 216px;
          height: 71px;
          background: url('../../assets/img/traditional/roll/not_big.png') no-repeat;
          background-size: 100% 100%;

          @media (max-width:1300px) {
            width: 86px;
            height: 32px;
          }
        }

        .not_roll.act {
          background: url('../../assets/img/traditional/roll/yes1_big.png') no-repeat;
          background-size: 100% 100%;
        }
      }

      .sign_rule {
        position: absolute;
        left: 50%;
        bottom: 90px;
        transform: translateX(-50%);
        width: 118px;
        height: 36px;
        background: url('../../assets/img/traditional/roll/sign_btn.png') no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 32px;
        text-align: center;
        cursor: pointer;

        @media (max-width:1300px) {
          width: 98px;
          height: 30px;
          font-size: 12px;
          line-height: 30px;
          position: absolute;
          left: 50%;
          bottom: 0px;
        }
      }
    }
  }
}</style>
