<template>
    <!-- header全局公共的 -->
    <div class="header p-0 m-0">
        <div class="header-phone-left position-relative align-items-center">
            <div v-show="is_show_nav_phone ? 'd-block' : ''" class="position-absolute header-phone-left-nav">
                <router-link v-for="(item, index) in routers" :key="index" :to="'/' + item.path" v-show="item.meta.isShow"
                    class="" :active-class="'header-conter-active header-phone-left-active'">
                    <div class="pl-3 py-3" style="">
                        <div class="">
                            {{ item.meta.title }}
                        </div>
                    </div>
                </router-link>
            </div>
            <div @click="offNav" v-if="is_show_nav_phone ? 'd-block' : ''" class="header-phone-left-nav-bg"></div>
        </div>
        <div class="position-fixed app-phone d-md-none d-block" style="z-index: 9999">
            <div class="app-phone-nav pt-2 new-bg-xx">
                <div class="d-flex flex-column align-items-center"
                    :style="`background-image:url(${require(`@/assets/img/ADT/common/nav-phone/${$route.name === 'mysteryBox' ? '00' : '0'}.png`)})`"
                    :class="`${$route.name === 'mysteryBox' ? 'act nav-index-color' : ''}`" @click="$router.push({ name: 'mysteryBox' })">
                    <span class="xx-title">竞技场</span>
                </div>

                <!-- <div class="d-flex flex-column align-items-center"
                    :style="`background-image:url(${require(`@/assets/img/ADT/common/nav-phone/${$route.name === 'Battlefield' ? '55' : '5'}.png`)})`"
                    :class="`${$route.name === 'Battlefield' ? 'act nav-index-color' : ''}`" @click="$router.push({ name: 'Battlefield' })">
                    <span class="xx-title">拆弹</span>
                </div> -->


                <!-- <div class="d-flex flex-column align-items-center"
                    :style="`background-image:url(${require(`@/assets/img/ADT/common/nav-phone/${$route.name === 'mysteryBox' ? 'px00' : 'px0'}.png`)})`"
                    :class="`${$route.name === 'mysteryBox' ? 'act nav-index-color' : ''}`" @click="$router.push({ name: 'mysteryBox' })">
                    <span class="xx-title">竞技场</span>
                </div> -->

                <div class="d-flex flex-column align-items-center"
                    :style="`background-image:url(${require(`@/assets/img/ADT/common/nav-phone/${$route.name === 'lucky' ? '11' : '1'}.png`)})`"
                    :class="`${$route.name === 'lucky' ? 'act nav-index-color' : ''}`"
                    @click="$router.push({ path: '/Flucky' })">
                    <span class="xx-title">追梦</span>
                </div>
                <div class="d-flex flex-column align-items-center" @click="$router.push({ name: 'SCGOindex' })"
                    :class="`${$route.name === 'SCGOindex' ? 'act nav-index-color' : ''}`"
                    :style="`background-image:url(${require(`@/assets/img/ADT/common/nav-phone/${$route.name === 'SCGOindex' ? '22' : '2'}.png`)})`">
                    <span class="xx-title tsdd">首页</span>
                </div>
                <div class="d-flex flex-column align-items-center"
                    :style="`background-image:url(${require(`@/assets/img/ADT/common/nav-phone/${$route.name === 'rollHome' ? '33' : '3'}.png`)})`"
                    :class="`${$route.name === 'rollHome' ? 'act nav-index-color' : ''}`"
                    @click="$router.push({ name: 'rollHome' })">
                    <span class="xx-title">ROLL房</span>
                </div>
                <div class="d-flex flex-column align-items-center"
                    :style="`background-image:url(${require(`@/assets/img/ADT/common/nav-phone/${$route.name === 'info' ? '44' : '4'}.png`)})`"
                    :class="`${$route.name === 'info' ? 'act nav-index-color' : ''}`" @click="userInfo">
                    <span class="xx-title">我的</span>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import $api from '@/api/help/index'
import { mapState } from 'vuex'

export default {
    name: 'headerTop',
    data() {
        return {
            domain_url: '',
            routers: [],
            routersUser: [],
            isShowNavActive: false,
            isShowNavPc: false,
            mobile_click_nav: false,
            mobile_click_nav_index: 0,
            off_laba: true,
            // 文章列表
            hrlpList: [],
            help: false,
            // 登陆之前点击显示
            login_is_show_pup: this.login_top_is_xie_yi,
            checked01: false,
            checked02: false,
            checked03: false,
            checked04: false,

        }
    },
    created() {
        this.domain_url = window.location.host;
    },
    mounted() {
        this.getarticle()
        this.routers = this.$router.options.routes[0].children
        let rouer = this.$router.options.routes.find(item => {
            return item.name == "user"
        })
        this.routersUser = rouer.children
        if (getToken()) {
            setTimeout(() => {
                this.$store.commit("LOGIN_IS_SHOW", false)
            }, 500)
        }
    },
    computed: {
        ...mapState(['user_info', 'steam_handle', 'login_top', 'login_is_show', 'is_show_nav_pc', 'is_show_nav_phone', 'login_top_is_xie_yi']),
    },
    methods: {
        keFu() {
            window.open('/chatlink.html', "_blank")
        },
        userInfo() {
            if (getToken()) {
                this.$router.push({ name: 'info' })
            } else {
                this.$store.commit("LOGIN_TOP", true)
            }
        },
        login_is_show_pup_off() {
            this.login_is_show_pup = false
            this.$store.commit("LOGIN_TOP_IS_XIE_YI", false)
        },
        // 获取文章列表
        async getarticle() {
            let { data } = await $api.getarticle()
            this.hrlpList = data
        },
        // 注册
        register() {
            this.$store.commit("LOGIN_TOP", true)
            this.$store.commit("REGISTER", 'register')
        },
        // steam 提示登录
        steamLogin() {
            this.$confirm('打开加速器，跳转更快捷呦！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
            }).then(() => {
                window.open("https://api.mmcsgo.com/api/steam/login", '_blank')
            }).catch(() => {

            });
        },
        mobile_click_nav_url(item, index) {
            this.mobile_click_nav = false
            this.mobile_click_nav_index = index
            this.$router.push({ path: '/' + item.path })
        },
        // 跳转APP下载
        appDown() {
            this.$router.push('/APPDown')
        },

        laba_off_on() {
            this.off_laba = !this.off_laba
            if (!this.off_laba) {
                document.querySelectorAll("video, audio").forEach(elem => {
                    elem.muted = true;
                    elem.pause();
                });
            } else {
                document.querySelectorAll("video, audio").forEach(elem => {
                    elem.muted = false;
                });
            }

        },
        userNav() {
            this.isShowNavPc = !this.isShowNavPc
            this.$store.commit('ISSHOWNAVPC', this.isShowNavPc)

        },
        login() {
            this.login_is_show_pup = false
            if (!getToken()) {
                this.$store.commit("LOGIN_TOP", true)
            } else {
                this.$store.commit("LOGIN_IS_SHOW", false)
            }
        },
        loginOut() {
            this.$confirm('您是否退出登录！', '提示', {
                confirmButtonText: '退出',
                cancelButtonText: '取消',
                type: 'success',
                customClass: 'pkClass'
            }).then(() => {
                this.$store.commit("LOGIN_IS_SHOW", true)
                this.$store.dispatch('removeInfo')
                this.$router.push('/')
            }).catch(() => {
                console.log(12)
            })
        },
        isShowNav() {
            this.isShowNavActive = !this.isShowNavActive
            // this.$store.commit("ISSHOWNAVPC",false)
            this.$store.commit("ISSHOWNAVPHONE", true)
        },
        offNav() {
            this.isShowNavActive = false
            // this.$store.commit("ISSHOWNAVPC",false)
            this.$store.commit("ISSHOWNAVPHONE", false)

        }
    }
}
</script>
<style scoped lang="scss">
.app-phone-nav.new-bg-xx {
    background-image: url('../assets/img/ADT/common/footer-nav-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    &>div {
        background-size: 35px;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        color: #00869A;

        &>.xx-title {
            position: absolute;
            bottom: 8px;
            font-size: 12px;
        }

        &>.xx-title.tsdd {
            bottom: 12px;
        }
    }
}

.app-phone {
    bottom: 0;
    left: 0;
    right: 0;

    // background-image: url('../assets/img/phone-nav/navbg-bottom.png');
    // background-size: 100% 100%;
    &-nav {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 80px;

        .flex-column {
            width: 100%;
            height: 100%;
            padding-top: 16px;
            // background-image: url('../assets/img/phone-nav/buttom1.jpg');
            background-size: 100% 100%;

            .iconfont {
                font-size: 22px;
                margin-bottom: 10px;
                opacity: 0.8;
            }
        }

        .flex-column.act {
            // background-image: url('../assets/img/phone-nav/buttom2.jpg');

            .iconfont {
                opacity: 1;
            }
        }
    }
}

.header_PC {}

.header_PHONE {
    display: none;
}

@media (max-width: 960px) {
    .header_PHONE {
        display: block;
    }

    .header_PC {
        display: none;
    }
}

.border-t-b {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}

.phone_nav_list>.phone_nav {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: var(--main-yellow);
    }
}

.pc {
    width: 250px;
    overflow: auto;
    height: 100%;

    &::-webkit-scrollbar {
        display: none
    }
}

.shrink {
    width: 70px;

    &-title {
        position: absolute;
        z-index: -2;
        white-space: nowrap;
        text-align: center;
        width: 0;
        left: 100%;
        overflow: hidden;
        transition: all .5s;
    }

    &-con:hover {
        .shrink-title {
            width: 150px;

        }
    }
}

@media (max-width: 960px) {
    .pc {
        display: none !important;
    }

    .shrink {
        display: none !important;
    }
}

.top {
    height: 56px;
}

.header {
    height: 100vh;
    background-color: #0d101a;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);

    &-phone {
        &-left {
            display: none;
            height: 100%;

            @media (max-width: 960px) {
                display: none;
            }

            &-nav {
                top: 100%;
                position: relative;
                width: 300%;
                height: 100vh;
                background: var(--main-bg);
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
                z-index: 2;

                &-bg {
                    position: absolute;
                    width: 100vw;
                    height: calc(100vh - 80px);
                    background: #000000;
                    opacity: .8;
                    z-index: -2;
                    top: 80px;
                    left: 0;
                }

            }

            & span {
                display: block;
                width: 40px;
                height: 3px;
                border-radius: 2px;
                background: red;
                margin: 6px 20px;
                transition: all .5s;
            }

            &:hover {
                & span:nth-child(2) {
                    display: none;
                }

                & span:nth-child(1) {
                    transform: rotate(180deg);
                }

                & span:nth-child(3) {
                    transform: rotate(-180deg);
                }

                &-nav {
                    display: block;
                }
            }

            &-active>div {
                border-left: 4px solid;
                background-image: var(--main-yellow-gradual-x);
            }
        }

        &-logo {
            @media (max-width: 1200px) {
                display: block;
                margin: 0 auto;
            }
        }
    }

    &-left {
        width: 100%;
        height: 160px;

        & img {
            height: 100%;
        }

        @media (max-width: 1200px) {
            display: none;
        }
    }

    &-conter {
        width: 100%;
        height: 100%;
        margin-left: 40px;

        @media (max-width: 1200px) {
            display: none !important;
        }

        &-item {
            width: 11%;
            height: 100%;
            background-size: 100% 100%;
            background-image: url('../assets/img/nav/nav-item.png');
            filter: grayscale(1);
            font-size: 1.3rem;
            transition: all .3s;

            @media (max-width: 1600px) {
                width: 15%;
            }

            @media (max-width: 1366px) {
                width: 18%;
            }

            @media (max-width: 1200px) {
                width: 14%;
            }

            @media (max-width: 960px) {
                display: none;
            }

            &-text {
                margin-top: 5px;
                font-size: .9rem;

                @media (max-width: 1600px) {
                    font-size: .8rem
                }
            }

            &:hover {
                color: var(--main-yellow);
                filter: grayscale(0);
                background-image: url('../assets/img/nav/nav-item-active.png');
            }

            @media (max-width: 960px) {
                font-size: 12px
            }
        }

        &-active {
            color: var(--main-yellow);
            filter: grayscale(0);
            background-image: url('../assets/img/nav/nav-item-active.png');

            &::before {
                content: '';
                width: 2px;
                height: 50px;
                background-color: var(--main-blue);
                ;
                position: absolute;
                left: 0;
            }
        }

    }

    &-login {
        /*background-image: url('../assets/img/nav/login.png');*/
        background-size: 100% 100%;
        font-size: 14px;
        width: 140px;
        background-color: #e94d8b;
        border: 1px solid #e94d8b;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;

        @media (max-width: 960px) {
            width: 80px;
            font-size: 12px;
        }
    }

    &-user {
        width: 370px;

        @media (max-width: 960px) {
            display: none;
        }

        &-phone {
            display: none;

            @media (max-width: 960px) {
                display: block;
                position: relative;

            }

            & .active2 {
                display: block;
                position: absolute;
                left: 0;
                width: 100%;
                top: 60px;
            }

            &-title {
                /*background: rgba(0, 0, 0, 0.5);*/
                /*border: 1px solid #000000;*/
                border-radius: 5px;
                padding: 5px 5px;
                line-height: 22px;
            }
        }

        & .active {
            display: block !important;
        }

        &-nav {
            display: none;
            width: 250px;
            position: absolute;
            top: 30px;
            left: 0;
            text-align: center;
            padding: 20px 0;
            z-index: -1;
            background: var(--main-bg);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);

            &-active {
                color: var(--main-yellow)
            }

            &-hove {
                transition: all .5s;
            }

            &-hove:hover {
                color: var(--main-yellow)
            }
        }

        &:hover &-nav {
            /*display: block;*/
        }

        &-img {
            right: 10px;
        }

        &-name {
            width: 230px;
            /*padding: 0 10px;*/
            overflow-y: hidden;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;

            &-vip {
                margin-left: 5px;
                width: 40px;
                height: 18px;
                bottom: 0px;
                right: -15px;

                & img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &-money {
            width: 250px;
            border: 3px solid #000000;
            border-left: 0;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
        }

        &-name,
        &-money {
            font-size: 14px;
            line-height: 30px;
        }
    }
}


.bonanza {
    position: relative;

    margin-bottom: 10px;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        background-size: 100% 100%;
    }
}
</style>
