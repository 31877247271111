<template>
    <div class="px-md-5 px-1 mt-5 w1720">
        <div class="d-flex flex-wrap">
            <div class="content-left col-lg-3 col-sm-12" style="">
                <div style="; background-color: var(--f-main); border-radius: 15px " class="d-flex justify-content-between p-3">
                    <div class="position-relative" @click="upImgVisible=true">
                        <img :src="user_info.avatar" width="100px" height="100px" alt="">
                        <div class="bg_dark_blue  p-2 text-center position-absolute bottom-0 right-0 left-0
              cursor-pointer">

                            <!-- <el-upload
                                    class=""
                                    :action="`*`"
                                    :before-upload='beforeUpload'
                            >
                                <div size="small" type="primary" style="font-size: 12px">点击更换头像</div>
                            </el-upload> -->
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-around align-items-end">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div class="mr-6"  >
                                <span style="display: -webkit-box;" >
                                    <money/>{{user_info.bean}}
                                </span>
                                <span style="font-size: 12px;padding-left: 2rem !important;" >
                                    余额
                                </span>
                            </div>
                            <div  >

                                <span  style="display: -webkit-box;margin-left: 1rem;" >
                                  <money :integral="false" color="#FFF" />{{user_info.integral}}
                                </span>

                                <span style="font-size: 12px;padding-left: 3rem !important;" >
                                    积分
                                </span>

                            </div>
                        </div>
                        <div class="ml-auto  " style="position: relative;z-index: 999">
                            <div class="mr-3 d-none">
                                <Task-in :bottom-right="false">
                                    <div class="f_btn3 px-3 py-1 rounded cursor-pointer">
                                        <div>
                                            签到
                                        </div>
                                    </div>
                                </Task-in>
                            </div>
                            <router-link to="/user/recharge" class="f_btn3 px-md-5 px-3 py-1 rounded cursor-pointer">
                                充值
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="mt-3 px-2">
                    <div class="h3 font-weight-bold text-white">{{name}}</div>
                    <div class="op-6 " style="font-size: 12px">用户ID：{{user_info.id}}</div>
                </div>
            </div>

            <div class="ml-auto content-right px-2 col-lg-9 col-sm-12 fixed-nav"  >
                <!--<el-tabs v-model="activeName" @tab-click="handleClick" class="fixed-nav-content">
                    <el-tab-pane label="设置" name="user">
                        <div class="h5 mt-3 font-weight-bold d-md-block d-none">账户设置</div>
                        <div class="d-md-flex row mt-3">
                            <div class=" mr-0 col-md-3 col-12">
                                <div @click="user.info = 1" :class="user.info == 1? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">个人资料
                                </div>
                                <div @click="user.info = 2" :class="user.info == 2? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">
                                    交易报价链接
                                </div>
                                <div @click="user.info = 4" :class="user.info == 4? 'f-yellow-color' :''"
                                     class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">快速帮助
                                </div>
                            </div>
                            <div v-if="user.info == 1" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(&#45;&#45;f-main); background-color: var(&#45;&#45;main-bg-2) " name="个人资料">
                                <div class="h5 mb-0 font-weight-bold p-3 w-100"
                                     style="background-color: var(&#45;&#45;f-main) ">个人资料
                                </div>

                                <div class="w-100 px-3 " style="">
                                    <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                        <div>修改昵称</div>
                                        <div>
                                             <span class="mr-3 pb-2" style="border-bottom:1px dashed var(&#45;&#45;main-blue);">
                                                <input style="width: 150px;font-size: 14px;" v-model="name" class="text-white"  type="text"  placeholder="输入昵称">
                                             </span>
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(&#45;&#45;main-blue);font-size: 14px;"
                                                  @click="set_name">保&nbsp;&nbsp;&nbsp;&nbsp;存
                                            </span>
                                        </div>
                                    </div>

                                    &lt;!&ndash; 绑定邀请码  &ndash;&gt;
                                    <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                        <div>邀请码</div>
                                        <div v-if="user_info.inviter != null">
                                           <span class="mr-3 pb-2" style="border-bottom:1px dashed var(&#45;&#45;main-blue);">

                                            <input style="width: 150px;font-size: 14px;" readonly=""  disabled=""  :value="user_info.inviter.invite_code" class="text-white"  type="text" >
                                            </span>
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(&#45;&#45;main-blue);font-size: 14px;" > 已绑定</span>

                                        </div>

                                        <div v-else>
                                            <span class="mr-3 pb-2" style="border-bottom:1px dashed var(&#45;&#45;main-blue);">

                                                <input  style="width: 150px;font-size: 14px;" v-model="invite_code" class="text-white" disabled="true"   type="text"    placeholder="仅注册时可更改">

												</span>

                                            <span class="px-4 py-2 text-center rounded cursor-pointer " style="border:1px solid var(&#45;&#45;main-blue);font-size: 14px;" @click="getSet_inviter" >已锁定</span>

                                        </div>
                                    </div>

                                    <div class="align-items-center mt-3 d-flex  justify-content-between" style="height: 50px">
                                        <div>账户手机</div>
                                        <div class="">
                                            <span class="mr-3 pb-2" style="border-bottom:1px dashed var(&#45;&#45;main-blue);">

                                            <input style="width: 150px;font-size: 14px;" readonly=""  disabled=""  :value="user_info.mobile" class="text-white"  type="text" >
                                            </span>
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(&#45;&#45;main-blue);font-size: 14px;" @click="changePassword">更&nbsp;&nbsp;&nbsp;&nbsp;新</span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3  justify-content-between align-items-center" style="height: 50px">
                                        <div>账户控制</div>
                                        <div class="text-right">
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(&#45;&#45;main-blue);font-size: 14px;"
                                                  @click="loginOut">登&nbsp;&nbsp;&nbsp;&nbsp;出</span>
                                        </div>
                                    </div>






                                </div>
                            </div>
                            <div v-if="user.info == 2" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(&#45;&#45;f-main); background-color: var(&#45;&#45;main-bg-2) " name="交易报价链接">
                                <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                     style="background-color: var(&#45;&#45;f-main)">
                                    交易报价链接

                                    <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" style="font-size: 0.8rem; color: var(&#45;&#45;main-blue)" target="_blank">点我获取交易链接</a>


                                </div>
                                <div class="p-4">
                                    <input class="input py-4 px-4 " v-model="steam_url"
                                           type="text" placeholder="交易报价链接 ">
                                </div>
                                <div class="d-flex justify-content-end align-items-center pb-3 px-3">


                                    <div>
                                        取消
                                    </div>
                                    <div class="f-main-bg px-5 py-2 text-center rounded ml-3"
                                         @click="getSteamLink">
                                        保存
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div v-if="user.info == 4" class="col-md-9 p-0 pb-5"
                                 style="border: 3px solid var(&#45;&#45;f-main); background-color: var(&#45;&#45;main-bg-2) " name="社交资料">
                                <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                     style="background-color: var(&#45;&#45;f-main)">
                                    快速帮助
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4 ">
                                    <div>网站客服</div>
                                    <div class="col-md-8 col-12">该客服处理充值、提货问题 QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4">
                                    <div>一般问题</div>
                                    <div class="col-md-8 col-12">该客服处理您在网站使用中的各种问题。QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                                </div>
                                <div class="px-4 d-flex justify-content-between mt-4">
                                    <div>提货帮助</div>
                                    <div class="col-md-8 col-12"><a href="/active/help/5" style="text-decoration-line:underline">如何提取您的装备和需要注意的事项？</a></div>
                                </div>

                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="仓库" name="back"></el-tab-pane>
                    <el-tab-pane label="取回历史" name="old">
                        <storage-list  :Storage-list-list="StorageList" :storage-listpage-size="StorageListpageSize"
                                       :storage-listtotal="StorageListtotal"></storage-list>
                    </el-tab-pane>
                    <el-tab-pane label="充值历史" name="pay">
                        <pay :table-data="tableData" :total="total" :page-size="pageSize" ></pay>
                    </el-tab-pane>
                    <el-tab-pane label="收支明细" name="income">
                        <income :table-data="records" :total="recordstotal" :page-size="recordspageSize" ></income>
                    </el-tab-pane>
                </el-tabs>-->

                <el-tabs v-model="activeName" @tab-click="handleClick" class="fixed-nav-content ">
                    <el-tab-pane label="设置" name="user"></el-tab-pane>
                    <el-tab-pane label="仓库" name="back"></el-tab-pane>
                    <el-tab-pane label="取回历史" name="old"></el-tab-pane>
                    <el-tab-pane label="充值历史" name="pay"></el-tab-pane>
                    <el-tab-pane label="收支明细" name="income"></el-tab-pane>
                </el-tabs>
            </div>

            <div class="ml-auto content-right px-2 col-lg-9 col-sm-12 maxTop"     >
                <!--设置-->
                <div v-if="activeName === 'user'">
                    <div class="h5 mt-3 font-weight-bold d-md-block d-none">账户设置</div>
                    <div class="d-md-flex row mt-3">
                        <div class=" mr-0 col-md-3 col-12">
                            <div @click="user.info = 1" :class="user.info == 1? 'f-yellow-color' :''"
                                 class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">个人资料
                            </div>
                            <div @click="user.info = 2" :class="user.info == 2? 'f-yellow-color' :''"
                                 class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">
                                交易报价链接
                            </div>
                            <div @click="user.info = 4" :class="user.info == 4? 'f-yellow-color' :''"
                                 class=" px-4 py-3 bg-bg cursor-pointer" style="margin-bottom: 1px">快速帮助
                            </div>
                        </div>
                        <div v-if="user.info == 1" class="col-md-9 p-0 pb-5"
                             style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="个人资料">
                            <div class="h5 mb-0 font-weight-bold p-3 w-100"
                                 style="background-color: var(--f-main) ">个人资料
                            </div>

                            <div class="w-100 px-3 " style="">
                                <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                    <div>修改昵称</div>
                                    <div>
                                             <span class="mr-3 pb-2" style="border-bottom:1px dashed var(--main-blue);">
                                                <input style="width: 120px;font-size: 14px;" v-model="name" class="text-white"  type="text"  placeholder="输入昵称">
                                             </span>
                                        <span class="px-4 py-2 text-center rounded cursor-pointer"
                                              style="border:1px solid var(--main-blue);font-size: 14px;"
                                              @click="set_name" >保存
                                            </span>
                                    </div>
                                </div>

                                <!-- 绑定邀请码  -->
                                <div class="d-flex mt-3 d-block justify-content-between align-items-center" style="height: 50px">
                                    <div>邀请码</div>
                                    <div v-if="user_info.inviter != null">
                                           <span class="mr-3 pb-2" style="border-bottom:1px dashed var(--main-blue);">

                                            <input style="width: 120px;font-size: 14px;" readonly=""  disabled=""  :value="user_info.inviter.invite_code" class="text-white"  type="text" >
                                            </span>
                                        <span class="px-3 py-2 text-center rounded cursor-pointer"
                                              style="border:1px solid var(--main-blue);font-size: 14px;padding-left: 1.1rem !important;" >已绑定</span>

                                    </div>

                                    <div v-else>
                                            <span class="mr-3 pb-2" style="border-bottom:1px dashed var(--main-blue);">

                                                <input  style="width: 120px;font-size: 14px;" v-model="invite_code" class="text-white" disabled="true"   type="text"    placeholder="仅注册时可更改">

												</span>

                                        <span class="px-3 py-2 text-center rounded cursor-pointer " style="border:1px solid var(--main-blue);font-size: 14px;padding-left: 1.1rem !important;" @click="getSet_inviter" >已锁定</span>

                                    </div>
                                </div>

                                <div class="align-items-center mt-3 d-flex  justify-content-between" style="height: 50px">
                                    <div>账户手机</div>
                                    <div class="">
                                            <span class="mr-3 py-2" style="border-bottom:1px dashed var(--main-blue);">

                                            <input style="width: 120px;font-size: 14px;" readonly=""  disabled=""  :value="user_info.mobile" class="text-white"  type="text" >
                                            </span>
                                        <span class="px-4 py-2 text-center rounded cursor-pointer"
                                              style="border:1px solid var(--main-blue);font-size: 14px;" @click="changePassword">更新</span>
                                    </div>
                                </div>
                                <div class="d-flex mt-3  justify-content-between align-items-center" style="height: 50px">
                                    <div>账户控制</div>
                                    <div class="text-right">
                                            <span class="px-4 py-2 text-center rounded cursor-pointer"
                                                  style="border:1px solid var(--main-blue);font-size: 14px;"
                                                  @click="loginOut">登出</span>
                                    </div>
                                </div>






                            </div>
                        </div>
                        <div v-if="user.info == 2" class="col-md-9 p-0 pb-5"
                             style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="交易报价链接">
                            <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                 style="background-color: var(--f-main)">
                                交易报价链接

                                <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" style="font-size: 0.8rem; color: var(--main-blue)" target="_blank">点我获取交易链接</a>


                            </div>
                            <div class="p-4">
                                <input class="input py-4 px-4 " v-model="steam_url"
                                       type="text" placeholder="交易报价链接 ">
                            </div>
                            <div class="d-flex justify-content-end align-items-center pb-3 px-3">

                                <div v-if="!user_info.steam_url"  class="f-main-bg px-5 py-2 text-center rounded ml-3"
                                     @click="getSteamLink">
                                    保存
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div v-if="user.info == 4" class="col-md-9 p-0 pb-5"
                             style="border: 3px solid var(--f-main); background-color: var(--main-bg-2) " name="社交资料">
                            <div class="h4 mb-0 font-weight-bold p-3 w-100 "
                                 style="background-color: var(--f-main)">
                                快速帮助
                            </div>
                            <div class="px-4 d-flex justify-content-between mt-4 ">
                                <div>网站客服</div>
                                <div class="col-md-8 col-12">该客服处理充值、提货问题 QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                            </div>
                            <div class="px-4 d-flex justify-content-between mt-4">
                                <div>一般问题</div>
                                <div class="col-md-8 col-12">该客服处理您在网站使用中的各种问题。QQ {{web_info.qq_group_number}} (10:00-22:00)</div>
                            </div>
                            <div class="px-4 d-flex justify-content-between mt-4">
                                <div>提货帮助</div>
                                <div class="col-md-8 col-12"><a href="/active/help/5" style="text-decoration-line:underline">如何提取您的装备和需要注意的事项？</a></div>
                            </div>

                        </div>
                    </div>
                </div>
                <!--仓库-->
                <div v-if="activeName === 'back'"></div>
                <!--取回历史-->
                <div v-if="activeName === 'old'">
                    <storage-list  :Storage-list-list="StorageList" :storage-listpage-size="StorageListpageSize"
                                   :storage-listtotal="StorageListtotal"></storage-list>

                </div>
                <!--充值历史-->
                <div v-if="activeName === 'pay'">
                    <pay :table-data="tableData" :total="total" :page-size="pageSize" ></pay>

                </div>
                <!--收支明细-->
                <div v-if="activeName === 'income'">
                    <income :table-data="records" :total="recordstotal" :page-size="recordspageSize" ></income>
                </div>

            </div>
        </div>


        <div class="content-top mt-5 d-flex justify-content-between row" style="font-size: 14px">
            <div class="col-lg-3 col-md-4 col-6 content-top-sum ">
                <div class="bg-size bg-repeat d-flex align-items-center justify-content-center m-auto"
                     style="width: 88px;height: 88px"
                     :style="'background-image: url(' + require('@/assets/img/creation_pk_home_sum.png') + ')'">
                    当心！
                </div>
                <div class="ml-2">
                    <p>所有问题必须通过https://www.upcsgo.com或电子邮件解决。 忽略在任何社交网络上向您发送信息或向您发送电子邮件的人，他们可能是冒充 upcsgo
                        站点管理员</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-6 content-top-sum ">
                <div class="bg-size bg-repeat d-flex align-items-center justify-content-center  m-auto"
                     style="width: 88px;height: 88px"
                     :style="'background-image: url(' + require('@/assets/img/creation_pk_home_sum.png') + ')'">
                    赚佣金！
                </div>
                <div class="ml-2">
                    <p>最多可将您推荐人资金的 10% 存入您的余额！ 您可以在合作伙伴页面上找到有关合作伙伴的详细信息！</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-6 content-top-sum ">
                <div class="bg-size bg-repeat d-flex align-items-center justify-content-center  m-auto"
                     style="width: 88px;height: 88px"
                     :style="'background-image: url(' + require('@/assets/img/creation_pk_home_sum.png') + ')'">
                    游戏准则
                </div>
                <div class="ml-2">
                    <p>我们鼓励客户以负责任的方式以合理的方式使用我们的服务。 您可以随时请求立即禁用您的帐户。</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-6 d-none content-top-sum ">
                <div class="bg-size bg-repeat d-flex align-items-center justify-content-center  m-auto"
                     style="width: 88px;height: 88px"
                     :style="'background-image: url(' + require('@/assets/img/creation_pk_home_sum.png') + ')'">
                    常见问题和其他问题
                </div>
                <div class="ml-2">
                    <p>查看页面常见问题解答。 它详细描述了可能出现的所有问题。 如需支持，请访问https://www.upcsgo.com。 有关详细的功能说明，请访问 How To 部分。</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-6 content-top-sum ">
                <div class="bg-size bg-repeat d-flex align-items-center justify-content-center  m-auto"
                     style="width: 88px;height: 88px"
                     :style="'background-image: url(' + require('@/assets/img/creation_pk_home_sum.png') + ')'">
                    开箱前
                </div>
                <div class="ml-2">
                    <p>确保您熟悉所有取回选项和限制：检查您的 账户的交易能力，确保没有交易禁令或任何限制（否则我们的机器人将无法向您发送您的物品）。</p>
                </div>
            </div>

        </div>
        <!-- 旧的 -->
        <!-- 侧边栏 200px 高度100vh -->

        <!-- 内容区域 -->
        <div v-if="false" class="content mt-4">
            <!--   头像和用户基本展示   -->
            <div class="d-flex align-items-center">
                <div class="position-relative border-dark-blue">
                    <img width="130px" height="130px" :src="user_info.avatar" alt="">
                    <div class="bg_dark_blue position-absolute bottom-0 left-0 p-2 text-center
             right-0 cursor-pointer">
                        <!-- 点击更换头像
                        <el-upload
                                class="op-0 position-absolute bottom-0 left-0 p-2 right-0"
                                action="*"
                                :before-upload='beforeUpload'>
                            <div size="small" type="primary">点击更换头像</div>
                        </el-upload> -->
                    </div>

                </div>
                <div class="ml-3">
                    <div class="mb-3">用户手机号：{{user_info.mobile}}</div>
                    <div class="mb-3">邀请码：{{user_info.invite_code}}
                        <span class="bg-dark-brown px-2 py-1 cursor-pointer" style="font-size: 14px" @click="authCopy">此处点击复制</span>
                    </div>
                    <div class="mb-3">SteamID：{{user_info.steam_id}}</div>
                    <div>账户余额：
                        <money/>
                        {{user_info.bean}}
                    </div>
                </div>
            </div>
            <!-- 修改昵称 -->
            <div class="mt-4">
                <div class="h4 mb-1">修改昵称</div>
                <div class="p-4 bg-bg-2 d-flex justify-content-between">
                    <div class="border-dark-blue d-flex  align-items-center " style="width: 600px">
                        <div class="bg_dark_blue px-2 input-height title-phone">修改昵称</div>
                        <div class="ml-3"><input style="" v-model="name" class="text-white input-width" type="text"
                                                 placeholder="输入昵称"></div>
                        <div class="bg-dark-brown input-height px-3 ml-auto cursor-pointer" @click="set_name">保存</div>
                    </div>

                </div>
            </div>

            <div class="mt-4">
                <div class="h4 mb-1">Steam交易链接</div>
                <div class="p-4 bg-bg-2">
                    <div>
                        <div class="border-dark-blue d-flex  align-items-center">
                            <div class="bg_dark_blue px-2 input-height title-phone">Steam交易链接</div>
                            <div class="ml-3">

                                <input style="" class="text-white input-width setam-link" type="text"
                                       placeholder="输入Steam交易链接" v-model="steam_url">
                            </div>
                            <div class="bg-dark-brown input-height px-3 ml-auto cursor-pointer"
                                 @click="getSteamLink">保存
                            </div>
                        </div>
                        <div class="mt-2">
                            1) 获取你的Steam交易链接 <a target="_blank"
                                                href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
                                                class="cursor-pointer color-yellow">点击这里</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 绑定邀请码 -->
            <div class="mt-4">
                <div class="h4 mb-1">绑定邀请码</div>
                <div class="p-4 bg-bg-2">
                    <div>
                        <div class="border-dark-blue d-flex  align-items-center">
                            <div class="bg_dark_blue px-2 input-height title-phone">绑定邀请码</div>
                            <div class="ml-3">
                                <div v-if="user_info.inviter != null">
                                    <span>邀请人：{{user_info.inviter.name}}；邀请码：{{user_info.inviter.invite_code}}</span>
                                </div>
                                <input v-else v-model="invite_code" style=""
                                       class="text-white input-width" type="text"
                                       placeholder="输入绑定邀请码">
                            </div>
                            <div v-if="user_info.inviter == null"
                                 class="bg-dark-brown input-height px-3 ml-auto cursor-pointer"
                                 @click="getSet_inviter">保存
                            </div>
                        </div>
                        <div class="mt-2">
                            1) 邀请码只能绑定一个
                        </div>
                    </div>
                </div>
            </div>
            <!--   账号管理    -->
            <div class="mt-4 p-4 bg-bg-2">

                <div class="d-flex justify-content-between mb-3 cursor-pointer">
                    <div>修改密码</div>
                    <div @click="changePassword">修改</div>
                </div>
            </div>
        </div>


        <!--  验证码需要登录的那个弹窗 -->
        <el-dialog
                custom-class="login_egister"
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :visible.sync="passwordShow"
                width="500px">
            <div class="login d-flex py-5 px-3">
                <div class="login-right">

                    <!-- 验证码 -->
                    <div class="d-flex border-dark-brown align-items-center mt-3">
                        <div class="bg-bg-2 px-3 py-2 login-right-input">

                            <div>
                                <i class="iconfont text-white icon-dunpai  cursor-pointer" style="font-size:26px"></i>
                            </div>
                        </div>
                        <div class="ml-2">
                            <input v-model="form.verify" class="text-white d-block password"
                                   style="font-size: 16px;" type="text" placeholder="请输入验证码">
                        </div>
                        <div @click="getCode" :class="getCodeSeond != '获取验证码'? 'cursor-not' :''"
                             class=" bg-bg-2 px-3 ml-auto color-yellow cursor-pointer"
                             style="height: 42px;line-height: 40px">
                            <div>
                                {{getCodeSeond}}
                            </div>
                        </div>
                    </div>

                    <!-- 密码 - 注册 -->
                    <div class="d-flex border-dark-brown align-items-center mt-3">
                        <div class="bg-bg-2 px-3 py-2 login-right-input">

                            <div>
                                <i class="iconfont text-white icon-mima  cursor-pointer" style="font-size:26px"></i>
                            </div>
                        </div>
                        <div class="ml-2">
                            <input v-model="form.password" class="text-white d-block"
                                   style="font-size: 16px;width: 200px" type="password" placeholder="请输入密码">
                        </div>
                    </div>

                    <!-- 用户登录和steam登录 -->
                    <div class="d-flex justify-content-center align-items-center">
                        <bottom-bg @click.native="changePasswordClick" :title="'确认修改'" class="mt-3 px-5"></bottom-bg>
                    </div>
                </div>
                <div @click="passwordShow = false" class="position-absolute" style="right: 30px;top: 20px">
                    <div>
                        <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>
            </div>
        </el-dialog>


        <!-- vueCropper 剪裁图片实现-->
        <el-dialog class="cropper-dialog" width="600px" :close-on-click-modal="false" :visible.sync="dialogVisible"
                   append-to-body>
            <div class="cropper-content d-md-flex d-block justify-content-center align-items-center">
                <div class="cropper" style="text-align:center">
                    <vueCropper
                            @real-time="realTime"
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :auto-crop-width="option.autoCropWidth"
                            :auto-crop-height="option.autoCropHeight"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                    ></vueCropper>
                </div>
                <div class="cropper d-none justify-content-between align-items-center text-center">
                    <div>
                        <div class="show-preview"
                             :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}">
                            <div :style="previews.div">
                                <img width="100px" height="100px" :src="previews.url" :style="previews.img">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="show-preview rounded-pill"
                             :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}">
                            <div :style="previews.div">
                                <img class="" width="100px" height="100px" :src="previews.url" :style="previews.img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer d-flex justify-content-center">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
            </div>
        </el-dialog>

        <!-- 更换头像 -->
        <el-dialog center custom-class="img_custom_info" :close-on-click-modal="false" :width="ua==='phone'?'90%':'1000px'" :visible.sync="upImgVisible">
             <span slot="title" style="height:50px;line-height:50px">
                更换头像
            </span>
            <div class="padding-lr padding-bottom img_custom_m">
                <div @click="infoIndex=index" style="display: inline-block;" :class="infoIndex===index?'border_img':''" class="margin-right-sm margin-bottom-sm" v-for="(item ,index) in imgUrls" :key="index">
                    <el-image :src="item" :style="{width:ua==='phone'?'120px':'150px',height:ua==='phone'?'120px':'150px'}"></el-image>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="upImgVisible = false">取 消</el-button>
                <el-button type="primary" @click="profileInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

    import bottomBg from "@/components/bottomBg";
    import pay from './common/pay'
    import income from './common/income'
    import {mapState} from 'vuex'
    import $api from '@/api/user/index'
    import StorageList from "./common/StorageList";
    import TaskIn from '@/views/TaskIn/index'
    import sUserAgent from '@/assets/js/common.js'
    export default {
        name: "index",
        components: {
            StorageList,
            bottomBg,
            pay,income,TaskIn

        },
        data() {
            return {


                upImgVisible:false,
                infoIndex:0,
                imgUrls: [],
                oppositelyUrls:[],
                // 新的
                activeName: 'user',
                // 充值记录
                tableData: [],

                // 取回历史
                StorageList: [],

                // 显示个人资料侧栏
                user: {
                    info: 1,
                },
                // end 新的

                passwordShow: false,
                getCodeSeond: '获取验证码',
                // 邀请码
                InvitationCode: '',
                // steam链接
                steam_url: '',
                // 新密码
                form: {
                    mobile: '',
                    password: '',
                    // 验证码
                    verify: ''
                },
                // 绑定邀请码
                invite_code: '',
                // 邀请人信息
                inviter: '',
                // 昵称
                name: '',
                // 头像裁切
                dialogVisible: false,
                // 防止重复提交
                loading: false,
                option: {
                    canMove: true,
                    img: '', // 裁剪图片的地址
                    info: true, // 裁剪框的大小信息
                    outputSize: 0.8, // 裁剪生成图片的质量
                    outputType: 'jpeg', // 裁剪生成图片的格式
                    canScale: false, // 图片是否允许滚轮缩放
                    autoCrop: true, // 是否默认生成截图框
                    autoCropWidth: 100, // 默认生成截图框宽度
                    autoCropHeight: 100, // 默认生成截图框高度
                    fixedBox: true, // 固定截图框大小 不允许改变
                    fixed: true, // 是否开启截图框宽高固定比例
                    fixedNumber: [1, 1], // 截图框的宽高比例
                    full: false, // 是否输出原图比例的截图
                    canMoveBox: false, // 截图框能否拖动
                    original: false, // 上传图片按照原始比例渲染
                    centerBox: false, // 截图框是否被限制在图片里面
                    infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                },
                previews: {},

                total: 0,
                StorageListtotal: 0,
                pageSize: 0,
                StorageListpageSize: 0,
                old:'',
                records:[],// 收支明细
                recordstotal:0,// 收支明细
                recordspageSize:0,// 收支明细

            }
        },
        computed: {
            ua(){

                return sUserAgent.sUserAgent()
            },
            money() {
                let benr = 0
                this.StorageList.forEach(item => {
                    benr += (item.bean) * 1
                })
                return benr.toFixed(2)
            },
            recyleMoney() {
                let benr = 0
                this.StorageList.forEach(item => {
                    if (item.isShow == true) {
                        benr += (item.bean) * 1
                    }
                })
                return benr.toFixed(2)
            },
            ...mapState(['user_info','web_info'])
        },
        created() {
            let { name } = this.$route.query
            if(name){
                this.activeName = name
            }
            setTimeout(() => {
                this.name = this.user_info.name
                this.steam_url = this.user_info.steam_url
                this.InvitationCode = this.user_info.invite_code
            }, 500)
        },
        watch:{
            upImgVisible(val){
                if(val){
                    this.infoIndex=0
                    $api.getAvatarList().then(res=>{
                        this.imgUrls=res.data.default_avatar
                        this.oppositelyUrls=res.data.post_avatar
                    })
                }
            }
        },
        mounted() {
            this.getRecharge_record()
            this.getUsetChange_records()


        },
        methods: {
            //选择成功头像
            profileInfo(){
                $api.getSet_avatar({path: this.oppositelyUrls[this.infoIndex]}).then(()=>{
                    this.upImgVisible=false
                     this.$notify({
                        message: "头像更换成功",
                        type: 'success', 
                        position: 'top-left'
                    });
                    this.$store.dispatch('userInfo')
                })
            },
            // 新的
            // 仓库跳转
            tabClickBack(){
                console.log('asdsada')
            },
            //退出登录
            loginOut() {
                this.$store.commit("LOGIN_IS_SHOW", true)
                this.$store.dispatch('removeInfo')
                this.$router.push('/')
            },
            /*handleClick(tab, event) {
                console.log('tab, event');
                console.log(tab, event);
                if(tab.name == 'back'){
                    this.$router.push('/user/info')
                }
                if(tab.name == 'old'){
                    this.getStorage()
                }
            },*/

            handleClick(tab, event) {
                console.log('tab, event');
                console.log(tab, event);
                console.log(this.activeName);
                if(tab.name == 'back'){
                    this.$router.push('/user/info')
                }
                if(tab.name == 'old'){
                    this.getStorage()
                }
            },
            async getStorage(page = 1, status = 1) {
                try {
                    let {data, code} = await $api.getStorage(page, status)
                    if (code == 500) return
                    data.data.forEach(item => {
                        if (item.back_message != '') {
                            console.log(123)
                        }
                        item.isShow = false
                    })
                    this.StorageList = data.data
                    this.StorageListtotal = data.total
                    this.StorageListpageSize = data.per_page
                } catch (e) {
                    console.log(e)
                }
            },
            // 充值记录
            async getRecharge_record(page = 1) {
                try {
                    let {data, code} = await $api.getRecharge_record(page)
                    if (code == 500) return
                    this.tableData = data.data
                    this.total = data.total
                    this.pageSize = data.per_page
                } catch (e) {
                    console.log(e)
                    return
                }
            },
            // 收支明细
            async getUsetChange_records(page = 1) {
                try {
                    let {data, code} = await $api.getUsetChange_records(page)
                    if (code == 500) return
                    this.records = data.data
                    this.recordstotal = data.total
                    this.recordspageSize = data.per_page
                } catch (e) {
                    console.log(e)
                    return
                }
            },
            currentChangeIncome(val){
                this.getUsetChange_records(val)
                window.scrollTo(0, 0)
            },
            // end 新的
            // 翻页
            currentChange(val) {
                this.getStorage(val)
                window.scrollTo(0, 0);
            },
            // 点击分解
            async recycle() {
                let arr = []
                try {
                    this.StorageList.forEach(item => {
                        if (item.isShow) {
                            arr.push(item.id)
                        }
                    })
                    let {message, code} = await $api.getCash({data: arr})
                    if (code == 500) return
                    if (code == 200) {
                        this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success', position: 'top-left'
                        });
                    }

                    this.getStorage()
                    this.$store.dispatch('userInfo')
                } catch (e) {
                    console.log(e)
                }

            },
            // 点击提取
            async extract() {
                let arr = []
                this.StorageList.forEach(item => {
                    if (item.isShow) {
                        arr.push(item.id)
                    }
                })
                try {
                    let {message, code} = await $api.getExtract({data: arr})
                    if (code == 500) return
                    if (code == 200) {
                        this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success', position: 'top-left'
                        });
                    }
                    this.getStorage()
                    this.$store.dispatch('userInfo')
                } catch (e) {
                    console.log(e)
                }

            },
            // 点击单独一个
            itemOne(item) {
                if (item.back_message != '') {
                    this.ReturnReason = true
                    this.back_message = item.back_message
                }
                item.isShow = !item.isShow
            },
            // 展开弹窗
            changePassword() {
                this.passwordShow = true
            },
            // 点击修改密码
            async changePasswordClick() {
                try {
                    this.form.mobile = this.user_info.mobile
                    let {message} = await $api.getpassword(this.form)
                    this.$notify({
                        message: message,position: 'top-left'
                    });
                } catch (e) {
                    console.log(e)
                    return
                }
            },
            // 更新邀请码
            async getSet_inviter() {
                window.open('https://www.mmcsgo.com/chatlink.html',"_blank")
                return
                // try {
                //     let {message, code} = await $api.getSet_inviter({invite_code: this.invite_code})
                //     if (code == 500) return
                //     if (code == 200) {
                //         this.$notify({
                //             title: '成功',
                //             message: message,
                //             type: 'success', position: 'top-left'
                //         });
                //     }
                // } catch (e) {
                //     console.log(e)
                //     return
                // }
            },
            // 获取验证码 - ok
            async getCode() {
                let data = await $api.getSendSms({mobile: this.user_info.mobile,is_use:2})
                if (data.code == 500) return
                this.$notify({
                    title: '成功',
                    message: data.message,
                    type: 'success', position: 'top-left'
                });
                this.getCodeSeond = 60
                let time = setInterval(() => {
                    this.getCodeSeond -= 1
                    if (this.getCodeSeond == 0) {
                        this.getCodeSeond = '获取验证码'
                        clearInterval(time)
                    }
                }, 1000)

            },
            // 更换头像
            async userSrc(response) {
                try {
                    this.$common.blobToDataURL(response, async (res) => {
                        let {data} = await $api.getImageBase64({data: res})
                        let {message, code} = await $api.getSet_avatar({path: data.url})
                        if (code == 500) return
                        this.$notify({
                            title: '成功',
                            message: message,
                            type: 'success', position: 'top-left'
                        });
                        this.$store.dispatch('userInfo')
                    })
                } catch (e) {
                    console.log(e)
                    return
                }
            },
            // 拷贝邀请码
            authCopy() {
                console.log(this.InvitationCode)
                this.$common.CopyText({content: this.InvitationCode})
            },
            // setam 链接
            async getSteamLink() {
                try {
                    let {message, code} = await $api.getSet_steam_url({steam_url: this.steam_url})
                    if (code == 500) return
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                } catch (e) {
                    console.log(e)
                    return
                }
            },
            // 跟新昵称
            async set_name() {
                try {
                    let {message,code} = await $api.getSet_name({name: this.name})
                    if(code == 500)return
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                    this.$store.dispatch('userInfo')
                } catch (e) {
                    console.log(e)
                }
            },
            // 上传头像裁切
            async beforeUpload(file) {
                const isLt5M = file.size / 1024 / 1024 < 5
                const isImage = file.type.indexOf('image');
                if (!isLt5M) {
                    this.$notify({
                        message: '图片大于5M,请压缩图片',
                    });
                    return false
                }
                if (isImage === -1) {
                    this.$notify({
                        message: '请上传图片格式,jpg或者png', position: 'top-left'
                    });
                    return false
                }
                this.$common.blobToDataURL(file, async (res) => {
                    this.$nextTick(() => {
                        this.option.img = res
                        this.previews.url = res
                        this.dialogVisible = true
                    })
                })
                return false
            },
            // 点击裁剪，这一步是可以拿到处理后的地址
            finish() {
                this.$refs.cropper.getCropData(async (data) => {
                    let {data: url} = await $api.getImageBase64({data: data})
                    let {message} = await $api.getSet_avatar({path: url.url})
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                    this.$store.dispatch('userInfo')
                    setTimeout(() => {
                        // 关闭裁切框
                        this.dialogVisible = false
                        // 防止重复提交
                        this.loading = false
                    }, 1000)
                })
            },
            realTime(data) {
                this.previews = data
            },
        }
    }
</script>

<style scoped lang="scss">
.img_custom_info{
    .img_custom_m{
        @media (max-width: 1200px) {
            height: 400px;
            overflow-y: scroll;
        }
    }
    .border_img{
        border: 2px solid rgb(247, 207, 5);
    }
}
    .content-left {
        width: 500px;
        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    .content-right {
        width: calc(100% - 530px);
        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    .record {
        width: 100%;
        background-color: var(--main-bg-2);
        padding: 20px;
    }

    .user_bottom {
        color: var(--main-yellow);
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: -1px;
            background-color: var(--main-yellow);
            left: 0;
            right: 0;
            height: 3px;
        }
    }

    .password {
        width: 200px;
        @media (max-width: 1200px) {
            width: 100px;
            font-size: 12px;
        }
    }

    .setam-link {
        width: 500px;
        @media (max-width: 1200px) {
            width: 300px;
        }
        @media (max-width: 960px) {
            width: 200px;
        }
    }

    .title-phone {
        @media (max-width: 1200px) {
            display: none;
        }
    }

    .cropper-content {
        & .cropper {
            width: 300px;
            height: 300px;
        }
    }

    .side-box {
        width: 204px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
    }

    .content {
        width: 50%;
        margin: 0 auto;
        @media (max-width: 1200px) {
            width: 96%;
            font-size: 12px;
        }
    }

    .input-height {
        height: 38px;
        line-height: 38px;
        width: 150px;
        text-align: center;
    }

    .input-width {
        width: 300px;
        @media (max-width: 1200px) {
            width: 150px;
        }
    }

    .login {
        &-left {
            margin-left: 30px;
            width: 170px;
        }

        &-right {
            margin-right: 30px;
            width: 770px;

            &-input {
                color: #FFFFFF;
                font-size: 25px;
            }
        }
    }


    .fixed-nav {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .fixed-nav-content {
        display: flex;
    }


    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    ::-webkit-scrollbar {
        width: 0.01rem;
        opacity: 0;
        display: none;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
        background-color: #fff;
        opacity: 0;
    }
    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
        width: 0.01rem;
        border-radius: 0.01rem;
        opacity: 0;
    }

    @media (max-width: 3300px) and (min-width: 800px) { /*for 1680 x 1050*/
        .maxTop{
                top: -10rem;
        }
    }













</style>
