<template>
    <div class="cursor-pointer f_btn_main f-main-bg " style="">
        <div :style="size" class="text-center">
            <slot>
                <div class="text" :style="location">
                    {{title}}
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bottomBg2",
        props:{
            size:{type:Object,default(){return{}}},
            title:{type: String,default:'文字title'},
            location:{type: Object,default(){return {}}}
        },
        data() {
            return {}
        },
    }
</script>

<style scoped>

    .text{
        text-align: center;
        color: #FFFFFF;
        line-height: 40px;
    }
    .f_btn_main{
        flex-shrink: 0;
    min-width: 100px;
    height: 40px;
    background-color: #1E7A91;
    border-radius: 6px;
    border: 1px solid #1E7A91;
    display: flex;
    align-items: center;
    justify-content: center;
    }
</style>
