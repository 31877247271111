<template>
<div>
  <div class="d-flex align-items-center">
      <!-- <div class="d-flex flex-wrap">
          <div @click="bill('1')" :class="status=='1'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
              提取完成
          </div>
          <div @click="bill('4')" :class="status=='4'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
              已申请提货
          </div>
          
          <div @click="bill('2')" :class="status=='2'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
              已兑换
          </div>

          <a target="_blank" href="/active/help/5" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
        如何取货？
            </a>
      </div> -->
  </div>
  <div class="flex-wrap mt-3 row d-flex" >
    <div @click="shoppinng(item)" class="back-gun col-lg-3 col-md-3 col-sm-6 col-6  mb-3"  v-for="(item,index) in StorageList || storageListList" :key="index">
        <weapon class="position-relative pt-4 border-bottom" :res-data="item" :class="item == 2? 'border-yellow' :''" 
        style="border-radius: 10px; box-shadow: 1px 1px 1px var(--main-bg); color: #ddd ">
            <div v-if="item.back_message != ''&&item.status == 0 " class="position-absolute  text-center py-1"
             :class="`back-color-${item.status}`"  :style="`back-color-${item.lv}`"
                 style="top: 0px;font-size: 12px;background:var(--main-yellow);color:#ddd">
                {{'退回-点击查看原因'}} - {{item.updated_at}}
            </div>
            <div v-else class="position-absolute  text-center py-1 " :class="`back-color-${item.status}`" 
                 style="top: 0px;font-size: 12px">
                {{item.status==4 ?'暂未发货' :item.status == 5 ?  '发货中' : item.status_alias}}{{item.updated_at}}
            </div>
            
        </weapon>
    </div>
    <vacancy  v-show="StorageList.length == 0"> </vacancy>
  </div>
  <!-- <el-pagination
    background
    layout="prev, pager, next"
    :total="StorageListtotal"
    :page-size="StorageListpageSize"
    @current-change="currentChange">
  </el-pagination> -->
  <div class="flex paging">
      <div class="paging-item _a" @click="laypage(0)">上一页</div>
      <div class="paging-item _a" @click="laypage(1)">下一页</div>
  </div>


  <el-dialog
    custom-class="pop-up"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="ReturnReason"
    width="700px">
    <div class="p-4 text-white">
      <h3 class="h3 text-center mb-4">退回信息</h3>
      <p class="mb-3">
       {{back_message}}
      </p>
      <div @click="ReturnReason = false" class="position-absolute" style="right: 20px;top: 30px">
        <div>
          <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
        </div>
      </div>
    </div>
  </el-dialog>


</div>
</template>

<script>
  import $api from '@/api/user/index'
  import weapon from "./weapon";
  export default {
    name: "StorageList",
    props:{
      storageListList:{
        type:Array,
        default(){return []}
      },
      status:{
        type:Number,
        default:1
      },
      StorageListtotal:{
        type:Number,
        default:0
      },
      StorageListpageSize:{
        type:Number,
        default:0
      },
    },
    data(){
      return{
        ReturnReason:false,
        StorageList:[],
        back_message:'',
        page:1,
      }
    },
    components:{weapon},
    mounted() {
    },
    watch:{
      storageListList(val){
        console.log('打印');
        console.log(val);
        this.StorageList=val
      }
    },
    methods:{
      laypage(val){
          if(val){
          this.page++
          this.getStorage(this.page)
          // window.scrollTo(0,0);
          }else if(this.page>1){
          this.page--
          this.getStorage(this.page)
          // window.scrollTo(0,0);
          }
      },
      shoppinng(item){
        console.log(item.back_message)
        if(item.back_message != ''){
          this.ReturnReason = true
          this.back_message = item.back_message
        }
      },
      async getStorage(page = 1){
        try {
          let {data,code} = await $api.getStorage(page,this.status)
          if (code == 500) return
          if(!data.data.length) return this.page--
          data.data.forEach(item => {
              if (item.back_message != '') {
                  console.log(123)
              }
              item.isShow = false
          })
          this.StorageList = data.data
          // this.StorageListtotal = data.total
          this.$emit('update:StorageListtotal',data.total)
          this.$emit('update:StorageListpageSize',data.per_page)
          // this.StorageListpageSize = data.per_page
          // if(status == 1){
          //   let {data,code} = await $api.getStorage(1)
          //   console.log(data,code)
          //   data.data.forEach(item =>{
          //     if(item.back_message != ''){
          //       this.StorageList.unshift(item)
          //     }
          //   })
          // }
        }catch (e) {
          console.log(e)
        }
      },

      currentChange(val){
        this.getStorage(val,this.status)
        window.scrollTo(0,0);
      },
      // 显示仓库状态
      bill(val){
        this.status = val
        let loading = this.$loading({lock:true,text: '正在加载',  spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'})
        setTimeout(() => {

          this.getStorage(1,val)
          setTimeout(async()=>{
            if(val == 4){
              let {data:data5} = await $api.getStorage(1,5)
              this.StorageList.unshift(...data5.data)
              let {data} = await $api.getStorage(1,6)
              this.StorageList.unshift(...data.data)
            }
          },100)
          loading.close();
        }, 500);

      },
    }
  }
</script>

<style scoped>
  .active{
    background: var(--main-yellow);
    color: var(--main-brown);
  }
</style>
