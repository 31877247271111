<template>
    <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class="roll-item rounded overflow-hidden" :style="'width:100%'">

        <div class="bg-size  bg-repeat position-relative d-flex flex-wrap
        justify-content-center align-items-center" :style="`background: rgba(9, 145, 170, 0.1);`">

            <!-- header -->
            <div class="px-3 text-center text-overflow-1 w-100 d-flex justify-content-center"
                style="font-size: 12px;flex-direction: column;margin-top: 20px;">
                <div style="font-size: 16px;color:#fff;" class="text-overflow-1">{{ resData.box_record.name }}</div>
                <div style="margin-top:10px;font-size: 14px;color:rgba(255, 255, 255, .5);" class="">
                    {{ resData.box_record.dura_alias }}</div>
            </div>

            <div class="d-md-flex roll-item-list pb-3 justify-content-around align-items-center cover-content cover-cover"
                style="flex-direction: column;">
                <img height="70%" style="top: 10px;" class="position-relative my-3" v-lazy="resData.box_record.cover"
                    alt="">
                <div class="d-md-block d-flex align-items-center justify-content-between px-md-0 px-2">
                    <div style="color: #F9C545;" class="d-flex align-items-center font-weight-bold">
                        <money /> {{ resData.bean }}
                    </div>
                </div>

            </div>
            <div class="w-100 d-none">
                <div class="text-overflow-1" style="font-size: 12px">{{ resData.box_record.name }}</div>
            </div>
        </div>

        <slot>
        </slot>
    </div>
</template>

<script>
export default {
    name: "weapon",
    props: {
        // 传输武器整体宽度
        width: {
            type: String,
            default: "14%"
        },
        // 武器显示的高度，宽度和整体宽度是一样的
        heightWeapon: {
            type: String,
            default: "150px"
        },
        // 传输背景 style 对象形式传输
        bgSize: {
            type: Object,
            default() {
                return {
                    height: '102px'
                }
            }
        },
        // 武器对应的宽度 高度 带单位 style 对象形式传输
        weaponSize: {
            type: Object,
            default() {
                return {
                    width: '115px',
                    marginTop: '25px'
                }
            }
        },
        //    传输的item
        resData: {
            type: Object,
            default() { return {} }
        }
    },
}
</script>

<style scoped lang="scss">
.cover-content {}

.cover-cover {
    & img {
        transition: all .5s;
    }

    &:hover img {
        transform: translateY(-20px)
    }
}

.weapon {
    &-name {
        padding: 10px 0;
    }
}

.roll-item {
    &-list {
        height: 140px;
        width: 100%;
        background-size: 100% 100%;

        @media (max-width:1300px) {
            text-align: center;
            height: 140px;

            img {
                margin: 0.5rem 0 !important;
            }
        }
    }
}</style>
