<template>
  <div class="d-flex">
    <div class="content">
      <div class="conten_tips" v-if="sUserAgent !== 'phone'">
        <img style="width: 20px;vertical-align: middle;" src="../../assets/img/ADT/event-hall/laba.png" alt="">
        {{
          web_info.site_name || `欢迎来到UPCSGO ！CSGO饰品即开即取 !本平台禁止未成年人消费，盲盒具有随机属性，请合理安排游戏时间，理性消费 !`
        }}
      </div>
      <!-- banner -->

      <div class="Phone">
        <div class="content-roll d-flex justify-content-between">
          <el-carousel style="width:100%" arrow="always" :autoplay="false"
                       :height="sUserAgent == 'phone' ? '150px' : '450px'" indicator-position="none">
            <el-carousel-item style="width:100%;height: 100%;" v-for="(item, index) in web_info.banners"
                              :key="index">
              <div class="transition-box" style="width:100%;height: 100%;" @click="bannerTap(item)">
                <img :src="item.image" style="width:100%;height: 100%;"/>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="content-roll-navigation " v-if="sUserAgent == 'phone'">
        <!-- <div style="color:#5BB2FC" class="text-huang" @click="$router.push({ name: 'lucky' })">
            <div>
                <img src="@/assets/img/icon/2.png" width="70" alt="">
            </div>
            <span>追梦</span>
        </div>
        <div style="color:#FFBB51" class="text-huang" @click="$router.push({ path: '/clearance' })">
            <div>
                <img src="@/assets/img/icon/1.png" width="70" alt="">
            </div>
            <span>拆弹战场</span>
        </div>
        <div style="color:#94FB6B" class="text-huang" @click="$router.push({ path: '/user/recharge' })">
            <div>
                <img src="@/assets/img/icon/3.png" width="70" alt="">
            </div>
            <span>充值</span>
        </div>
        <div style="color:#AF8CFF" class="text-huang" @click="$router.push({ name: 'compound' })">
            <div>
                <img src="@/assets/img/icon/4.png" width="70" alt="">
            </div>
            <span>汰换</span>
        </div>
        <div style="color:#FF8D88" class="text-huang" @click="$router.push({ name: 'shopping' })">
            <div>
                <img src="@/assets/img/icon/5.png" width="70" alt="">
            </div>
            <span>商城</span>
        </div> -->
        <!-- <div style="color:#ddd" class="text-huang" @click="$router.push({ path: '/FoutOfTheFree' })">
            <div style="margin-bottom: 15px;">
                <img style="width: 38px;" src="@/assets/img/ADT/home/phone/phone-bx.png" width="70" alt="">
            </div>
            <span>免费宝箱</span>
        </div> -->

        <div style="color:#ddd" class="text-huang" @click="$router.push({ name: 'Battlefield' })">
          <div style="margin-bottom: 15px;">
            <img style="width: 38px;" src="@/assets/img/ADT/home/phone/cd.png" width="70" alt="">
          </div>
          <span>拆弹</span>
        </div>
        <div style="color:#ddd" class="text-huang" @click="$router.push('/user/csactive');">
          <div style="margin-bottom: 15px;">
            <img style="width: 38px;" src="@/assets/img/ADT/home/phone/phone-hd.png" width="70" alt="">
          </div>
          <span>活动大厅</span>
        </div>
        <div style="color:#FFBB51" class="text-huang" @click=" $router.push({ path: '/user/recharge' }) ">
          <div style="margin-bottom: 15px;">
            <img style="width: 38px;" src="@/assets/img/ADT/home/phone/phone-cz.png" width="70" alt="">
          </div>
          <span>充值</span>
        </div>
        <div style="color:#ddd" class="text-huang" @click=" showcdk ">
          <div style="margin-bottom: 15px;">
            <img style="width: 38px;" src="@/assets/img/ADT/home/phone/phone-CDK.png" width="70" alt="">
          </div>
          <span>CDK</span>
        </div>
        <div style="color:#ddd" class="text-huang" @click=" KF ">
          <div style="margin-bottom: 15px;">
            <img style="width: 38px;" src="@/assets/img/ADT/home/phone/phone-kf.png" width="70" alt="">
          </div>
          <span>客服</span>
        </div>
      </div>

      <timeBoxTitle v-if="sUserAgent == 'phone'"></timeBoxTitle>
      <div class="conten_tips wrapvx" v-if=" sUserAgent == 'phone' ">
        <div class="lb">
          <img style="width: 20px;vertical-align: middle;" src="../../assets/img/ADT/event-hall/laba.png" alt="">
        </div>
        <div class="wrapvxFont">
          {{
            web_info.site_name || `欢迎来到UPCSGO ！CSGO饰品即开即取!本平台禁止未成年人消费，盲盒具有随机属性，请合理安排游戏时间，理性消费!`
          }}
        </div>
      </div>

      <!-- 导航 -->
      <!-- <div class="index-phone mt-3">
          <div class="d-flex justify-content-around flex-wrap">
              <div @click="$router.push({name:'compound'})" >
                  <template >
                      <div class="index-phone-item" >
                          <img style="width:40px" class="m-auto"
                          src="@/assets/img/phone-nav/compound.png" alt="#">
                          <div class="mt-1" style="font-size:12px">汰换</div>
                      </div>
                  </template>
              </div>
              <div @click="$router.push({name:'lucky'})" >
                  <template >
                      <div class="index-phone-item" >
                          <img style="width:40px" class="m-auto"
                               src="@/assets/img/phone-nav/lucky.png" alt="#">
                          <div class="mt-1" style="font-size:12px">追梦</div>
                      </div>
                  </template>
              </div>

              <div @click="$router.push({name:'recharge'})" >
                  <template >
                      <div class="index-phone-item" >
                          <img style="width:40px" class="m-auto"
                          src="@/assets/img/phone-nav/recharge.png" alt="#">
                          <div class="mt-1" style="font-size:12px">充值</div>
                      </div>
                  </template>
              </div>
              <div @click="$router.push({name:'welfare'})" >
                  <template >
                      <div class="index-phone-item" >
                          <img style="width:40px" class="m-auto"
                          src="@/assets/img/phone-nav/memberVip.png" alt="#">
                          <div class="mt-1" style="font-size:12px">合伙人</div>
                      </div>
                  </template>
              </div>
              <div @click="$router.push({name:'shopping'})" >
                  <template >
                      <div class="index-phone-item" >
                          <img style="width:40px" class="m-auto"
                               src="@/assets/img/phone-nav/shopping.png" alt="#">
                          <div class="mt-1" style="font-size:12px">积分商城</div>
                      </div>
                  </template>
              </div>



          </div>
      </div> -->

      <!-- 宝箱 -->
      <div class="content-box mt-5" :class=" sUserAgent == 'phone' ? 'phone-home-bg' : '' ">
        <div v-for="(      t, i      ) in       boxList       " :key=" i ">
          <div class="content-box-title" v-if=" t.box.length ">
            <div class="text-center content-box-title-top" :style=" `background-image:url(${t.src});` ">

              <div class="text-danger font-weight-bold flex-shrink-0
                            content-box-title-name" style="font-size: 24px">
              </div>
            </div>

          </div>
          <div class="content-box-list d-flex flex-wrap justify-content-center">
            <div class="dashboard_new">
              <div class="gun_card">
                <div class="gun_card_list"
                     :style=" { padding: t.id == 29 ? '20px 0' : '', background: t.id == 29 ? '' : '' } ">
                  <div v-for="(      item, index      ) in       t.box      " :key=" index "
                       class="gun_card_list_a">
                    <!-- {{t.id === 29}} -->
                    <router-link :to=" '/active/' + item.id + '?sorll=' + scroll " class=" ">
                      <!-- ${item.cover} -->
                      <!-- {{i}} -->
                      <div class="item-main" :style=" `background-image: url(${item.cover})` ">
                        <img
                            v-if=" (i == 0 && (index == 1 || index == 3)) || (i == 1 && (index == 2)) || (i == 2 && (index == 0)) || (i == 3 && (index == 0 || index == 3)) || (i == 4 && (index == 0)) || (i == 5 && (index == 0 || index == 4)) "
                            class="hot" src="@/assets/img/hot.png" alt="">
                        <img alt="" v-if=" item.weapon_cover_show == 1 " width="200px"
                             class="item-main-img" :src=" item.weapon_cover " lazy="loaded">
                        <div class="flex item-main-foot justify-between margin-top-xs">
                          <p :class="Number(item.dis_bean) > Number(item.bean) ? 'limitTimeCss': ''"
                             class="item-main-text text-overflow-1">{{ item.name }}</p>
                          <div class="flex foot-text">
                            <div
                                :class=" `${isShowLimitTime(item) ? 'xieline' : ''}` ">
                              <money v-if="!isShowLimitTime(item)" class="money"></money>
                              <span class="cp-num text-bold cp"
                                    style="padding-left: 2px; margin-top: 0px">{{
                                  isShowLimitTime(item) ? item.dis_bean : item.bean
                                }}</span>
                            </div>
                            <div class="zk" v-if="isShowLimitTime(item)">
                              {{ item.bean }}
                            </div>
                          </div>
                          <div class="limitTime" v-if="limitTime(item.end_time) && isShowLimitTime(item)">
                            {{ limitTime(item.end_time) }}
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

    <div class="indexPup d-flex justify-content-center align-items-center" v-if=" reminder == 1 ">
      <div class="position-relative">
        <div class="new_person_registration" @click=" reminderClick1 ">
          <!-- <div class="person_registration_close _a" @click="reminder=false"></div> -->
          <!-- <div class="person_registration_text text-bold text-huang">7-15</div>
          <div class="person_registration_txt text-bold">还可{{domain_url!='upcsgo.nnyqyx.com'?'免费':'即刻'}}开启新人CDK宝箱</div>
          <div class="person_registration_list flex">
              <div v-for="item in contains" :key="item.id">
                  <img :src="item.skins.cover">
                  <div class="text-center text-overflow-1">{{item.skins.name}}</div>
              </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="indexPup d-flex suoxiao justify-content-center align-items-center"
         v-if=" reminder == 2 && JSON.stringify(this.user_info) == '{}' ">
      <div class="position-relative">
        <div class="new_person_registration" @click=" reminderClick2 ">
          <!-- <div class="person_registration_close _a" @click="reminder=false"></div> -->
          <!-- <div class="person_registration_text text-bold text-huang">7-15</div>
          <div class="person_registration_txt text-bold">还可{{domain_url!='upcsgo.nnyqyx.com'?'免费':'即刻'}}开启新人CDK宝箱</div>
          <div class="person_registration_list flex">
              <div v-for="item in contains" :key="item.id">
                  <img :src="item.skins.cover">
                  <div class="text-center text-overflow-1">{{item.skins.name}}</div>
              </div>
          </div> -->
        </div>
      </div>
    </div>

    <transition name="el-fade-in-linear" v-if=" false ">
      <el-dialog custom-class="pop-up indexPup" :show-close=" false " :close-on-press-escape=" false "
                 :close-on-click-modal=" false " :visible.sync=" reminder " width="700px">

        <div class=" text-white" style="letter-spacing: 2px">
          <div class="w-100 h5 font-weight-bold p-3 bg-bg" style="border-top:1px solid #ffd800">upcsgo官方公告提示</div>
          <!--<h3 class="h3 text-center mb-4">upcsgo官方公告提示</h3>-->
          <div style="padding:5px 20px">
            <p class="mb-3">
              最近骗子横行繁多，大家擦亮眼睛，切勿贪图小便宜！上当受骗！！
            </p>
            <h5 class="h5 color-yellow">网站公告</h5>
            <p class="mb-3 text-danger" style="font-size: 16px">
              {{ web_info.site_bulletin }}
            </p>
            <h5 class="h5 color-yellow">最新优惠</h5>
            <p class="mb-3 text-danger" style="font-size: 16px">
            </p>

            <div class=" ml-auto cursor-pointer mt-2 mb-3" style="width: 200px">
              <div @click=" reminderClick " class="f_btn_green text-center">确认阅读</div>
            </div>
            <div @click=" reminderClick " class="position-absolute" style="right: 20px;top: 15px">
              <div>
                <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
              </div>

            </div>
          </div>
        </div>

      </el-dialog>
    </transition>
    <!-- 兑换CDK弹窗 -->
    <div class="CDKpopUp position-fixed" v-show=" isCDKpopUp " style="width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
      ">
      <div class="CDKpopUp1">
        <div class="qq-info">QQ官方交流群：619342798</div>
        <div class="cdk-input">
          <input placeholder="请输入输入CDK口令" v-model=" cdk " type="text">
        </div>

        <div class="cdk-submit" @click=" dhcdkHandle ">
          <img style="width: 110px;" src="../../assets/img/ADT/home/cdk-dh.png" alt="">
        </div>

        <div class="activeDel Btn_a" @click=" isCDKpopUp = false ">
                    <span class="activedel1">
                        <!-- <i class="el-icon-close cursor-pointer" style="font-size: 28px;font-weight: bold !important;"></i> -->
                        <img style="width: 30px;" src="../../assets/img/ADT/event-hall/close.png" alt="">
                    </span>
        </div>
      </div>
    </div>

    <!-- 兑换CDK成功弹窗 -->
    <div class="CDKSuccessPopUp position-fixed" v-show=" isCDKSuccessPopUp " style="width: 100vw;
                height: 100vh;
                left: 0;
                top: 0;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 9999;
            ">
      <div class="CDKSuccessPopUp1">
        <div class="cdk-input">
          <p>您领取了</p>
          <div>
            价值 <span style="color:#FFC600;">{{ cdkSpecies }}</span> 的红包
          </div>
        </div>

        <div class="cdk-submit" @click=" zhidaoledhcdkHandle ">
          <img style="width: 110px;" src="../../assets/img/ADT/home/zhidaole.png" alt="">
        </div>

        <div class="activeDel Btn_a" @click=" isCDKSuccessPopUp = false ">
                    <span class="activedel1">
                        <!-- <i class="el-icon-close cursor-pointer" style="font-size: 28px;font-weight: bold !important;"></i> -->
                        <img style="width: 30px;" src="../../assets/img/ADT/event-hall/close.png" alt="">
                    </span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $api from '@/api/CSGO/index'
import $api2 from '@/api/outOfTheFree/index'
import $api3 from "@/api/vd/index"
import {mapState} from 'vuex'
import sUserAgent from "@/assets/js/common.js"
import {getWebInfo, getToken} from '@/utils/auth'
import timeBoxTitle from '@/common/timeBoxTitle'

export default {
  components: {
    timeBoxTitle
  },
  name: "index",
  data() {
    return {
      cdkSpecies: undefined,
      isCDKSuccessPopUp: false,
      cdk: undefined,
      isCDKpopUp: false,  // CDK
      scroll: 0,
      ActiveShow: false,
      contains: [],
      // 手机端导航
      routers: [],
      // this.reminder==1 显示 & this.reminder==2 缩小 this.reminder==0 不显示
      reminder: 1,
      domain_url: '',
      boxList: [],
      sideBoxList: [],
      // roll列表
      ROLLList: [],
      options: {
        watchOverflow: true,
        slidesPerView: 2,
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      options_phone: {
        watchOverflow: true,
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
      },
      // 网站信息
      webInfo: {},
      // 开箱状态
      openBoxState: {
        openBox: 0,
        userSum: 0,
        onLine: 0,
        pkSum: 0
      },
      time: null,
      nowTime: new Date().getTime(),
      timer: null
    }
  },
  created() {
    this.pledgeChest()
    this.domain_url = window.location.host;
    window.addEventListener('scroll', this.handleScroll)
    this.scroll = Number(localStorage.sorll)
    // console.log(typeof this.scroll )
    // console.log( localStorage.sorll )
  },

  computed: {
    ...mapState(['web_info', 'login_register', 'user_info']),
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  watch: {
    scroll() {
      if (localStorage.sorll) {
        console.log('监听到')
        console.log(localStorage.sorll)
        this.$nextTick(() => {
          window.scrollTo({
            top: localStorage.sorll,
            behavior: 'smooth'
          })
        })

        setTimeout(() => {
          localStorage.removeItem('sorll')
        }, 1000)
      }
    }
  },
  mounted() {
    this.routers = this.$router.options.routes[0].children
    this.init()
    let codeRef = this.$route.query.ref_code
    if (codeRef != undefined) {
      window.sessionStorage.setItem('codeRef', codeRef);
    }
    if (getToken()) {
      this.reminder = false
    } else {
      this.reminder = true

    }
    // this.$nextTick(() => {

    //     this.$refs.btnS.click()
    // })
    this.$EventBus.$off("isCDKpopUp");
    this.$EventBus.$on("isCDKpopUp", () => {
      alert(333)
      this.isCDKpopUp = true;
    });


  },
  beforeDestroy() {
    clearInterval(this.time)
    clearInterval(this.timer)
    this.time = null
    this.timer = null
  },
  methods: {
    isShowLimitTime(item) {
      if (new Date(item.start_time).getTime() < this.nowTime
          && new Date(item.end_time).getTime() > this.nowTime
          && Number(item.dis_bean) > Number(item.bean)) {
        return true
      }
      return false
    },
    updateTime() {
      this.timer = setInterval(() => {
        this.nowTime = new Date().getTime()
      }, 1000)
    },
    limitTime(end_time) {
      if (!end_time) {
        return ''
      }
      let returnStr = ''
      let now = this.nowTime
      const limit = new Date(end_time).getTime()

      const day = (limit - now) / (1000 * 3600 * 24)
      const hours = ((limit - now) % (1000 * 3600 * 24)) / 3600 / 1000
      const minutes = (limit - now) % (1000 * 3600 * 24) % (3600 * 1000) / 1000 / 60
      const seconds = (limit - now) % (1000 * 3600 * 24) % (3600 * 1000) % (1000 * 60) / 1000

      // console.log(Math.trunc(day))
      if (Math.trunc(day) > 0) {
        returnStr += Math.trunc(day) + '天'
      }
      if (Math.trunc(hours) > 0) {
        returnStr += Math.trunc(hours) + '时'
      }
      if (Math.trunc(minutes) > 0) {
        returnStr += Math.trunc(minutes) + '分'
      }
      if (Math.trunc(seconds) > 0) {
        returnStr += Math.trunc(seconds) + '秒'
      }
      if (returnStr) {
        returnStr = '' + returnStr
      }
      return returnStr
    },
    KF() {
      window.open("/chatlink.html", "_blank");
    },
    // cdk兑换
    async dhcdkHandle() {
      this.$notify.closeAll();
      console.log('cdk-值:', this.cdk);
      if (!this.cdk) {
        this.$notify({
          type: 'error',
          message: '请输入口令',
          position: 'top-left'
        });
        return
      }
      let params = {
        type: 2,
        key: this.cdk,
      }
      try {
        let res = await $api3.getOpenRebBack(params);
        if (res.code === 200) {
          this.isCDKpopUp = false;
          this.isCDKSuccessPopUp = true;
          this.cdkSpecies = res.data || '0.00';
          this.$store.dispatch("userInfo");
        } else {
          this.$notify({
            type: 'error',
            message: res.message,
            position: 'top-left'
          });

        }

      } catch (e) {
        console.log(e);
      }
    },

    zhidaoledhcdkHandle() {
      this.isCDKSuccessPopUp = false;
    },

    showcdk() {
      this.isCDKpopUp = true;
    },
    handleScroll() {
      setTimeout(() => {
        this.scroll = document.documentElement.scrollTop;
      }, 500)

      // console.log(this.scroll)
    },
    // 跳转活动页面
    async csactive() {
      let token = await getToken()
      if (!token) {
        this.phoneShow = false
        return this.$store.commit("LOGIN_TOP", true)
      }
      if (this.sUserAgent == "phone") {
        this.phoneShow = false
        this.$router.push("/user/csactive");
      } else {
        this.ActiveShow = true;
      }
    },
    pledgeChest() {
      $api2.get_box_detail(275).then(res => {
        this.contains = res.data.contains.splice(0, 3)
      })
    },
    bannerTap(data) {
      if (data.href.indexOf('http') == -1) {
        if (!getToken()) {
          return this.$store.commit("LOGIN_TOP", true)
        } else {
          if (data.href === "/user/csactive") {
            if (this.sUserAgent == "phone") {
              this.$router.push(data.href)
            }
          } else {
            this.$router.push(data.href)
          }
        }

      } else if (data.href.indexOf('http') != -1) {
        window.location.href = data.href
      }
    },
    onSwiper() {
    },
    onSlideChange() {
    },
    init() {
      this.getBoxList()
      this.getROllListthree()
      this.get_box_hot()
      this.sumState()
      let Info = getWebInfo()
      if (Info) this.webInfo = JSON.parse(Info)
      this.updateTime()
    },
    sumState() {
      this.openBoxState.openBox = 1500 + Math.floor(Math.random() * 100)
      this.openBoxState.userSum = 1050 + Math.floor(Math.random() * 100)
      this.openBoxState.onLine = 1000 + Math.floor(Math.random() * 100)
      this.openBoxState.pkSum = 1000 + Math.floor(Math.random() * 100)
      let i = 0
      this.time = setInterval(() => {
        if (Math.floor(Math.random() * 10) > 3) {
          setTimeout(() => {
            this.openBoxState.openBox += Math.floor(Math.random() * 50)
            this.openBoxState.onLine += Math.floor(Math.random() * 50)
          }, 500)
          this.openBoxState.userSum += Math.floor(Math.random() * 100)
          this.openBoxState.pkSum += Math.floor(Math.random() * 100)
        } else {
          setTimeout(() => {
            this.openBoxState.openBox -= Math.floor(Math.random() * 50)
            this.openBoxState.onLine -= Math.floor(Math.random() * 50)
          }, 500)
          this.openBoxState.userSum -= Math.floor(Math.random() * 50)

          this.openBoxState.pkSum -= Math.floor(Math.random() * 50)
        }
        i++
        if (i === 50) {
          clearInterval(this.time)
          this.sumState()
        }
      }, 1000)
    },
    openBox() {

      document.getElementById('mysound').play()

    },
    mouseOverOpenBox() {
      document.getElementById('mouseOverBox').play()
    },
    mouseLeaveOpenBox() {
      document.getElementById('mouseOverBox').load()
    },
    reminderClick() {
      if (getToken()) {
        this.reminder = false
        return
      }
      this.reminder = false
      this.$store.commit("LOGIN_register", !this.login_register)
      this.$store.commit("LOGIN_TOP", true)
    },
    reminderClick1() {
      if (getToken()) {
        this.reminder = false
        return
      }
      this.reminder = 2
      this.$store.commit("LOGIN_TOP", true)
    },
    reminderClick2() {
      this.reminder = 1
    },
    sideBox(item) {
      this.$router.push('/active/' + item.id)
    },
    async getBoxList() {
      try {
        let {data, code} = await $api.getBoxList()
        if (code == 500) return
        this.boxList = data
        this.sideBoxList = data[0].box
      } catch (e) {
        console.log(e)
      }
    },
    async get_box_hot() {
      try {
        let {data, code} = await $api.get_box_hot()
        if (code == 500) return
        this.sideBoxList = data
      } catch (e) {
        console.log(e)
      }
    },
    async getROllListthree() {
      try {
        let {data: {data}, code} = await $api.getROllListthree()
        if (code == 500) return
        this.ROLLList = data.slice(0, 3)
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.limitTime {
  // margin-top: 30px;
  line-height: 20px;
  white-space: nowrap;
}

.money {
  height: auto !important;

  :deep(img) {
    vertical-align: middle;
  }
}

.content-box.phone-home-bg {
  background-image: url(../../assets/img/ADT/home/phone/phone-home-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: fixed;
}


.CDKSuccessPopUp {
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #fff;

  .CDKSuccessPopUp1 {
    width: 350px;
    height: 300px;
    left: calc(50% - 175px);
    top: calc(50% - 150px);
    position: relative;
    border-right: 10px;
    background: url("../../assets/img/ADT/home/cdk-success-pop.png") no-repeat;
    background-size: 100%;

    .cdk-input {
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      & > div {
        margin-top: 20px;
      }
    }

    .cdk-submit {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      & > img {
        width: 50%;
        height: 50%;
      }
    }

    .activeDel {
      position: absolute;
      top: 0;
      right: -20px;
      z-index: 9999;
      color: rgb(255, 183, 165);

      .activedel1 {
        padding: 10px;
        color: #000;
        font-weight: bold;
        border-radius: 50%;
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}

.CDKpopUp {
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #848492;

  .CDKpopUp1 {
    width: 350px;
    height: 300px;
    left: calc(50% - 175px);
    top: calc(50% - 150px);
    position: relative;
    border-right: 10px;
    background: url("../../assets/img/ADT/home/cdk-bg.png") no-repeat;
    background-size: 100%;

    .qq-info {
      position: absolute;
      bottom: 35%;
      right: 9%;
      text-align: center;
      color: #fff;
      font-size: 12px;
    }

    .cdk-input {
      position: absolute;
      top: 32%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > input {
        color: #fff;
        font-size: 14px;
      }
    }

    .cdk-submit {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      & > img {
        width: 50%;
        height: 50%;
      }
    }

    .activeDel {
      position: absolute;
      top: 0;
      right: -20px;
      z-index: 9999;
      color: rgb(255, 183, 165);

      .activedel1 {
        padding: 10px;
        color: #000;
        font-weight: bold;
        border-radius: 50%;
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}

.xieline {
  position: relative;
  margin-left: 25px;

  > span:last-child::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 47%;
    left: 10px;
    width: 60%;
    height: 1px;
    background-color: #FFCB77;
    transform: rotate(18deg);
  }
}

.suoxiao {
  background-color: transparent !important;
  width: auto !important;
  position: fixed !important;
  left: inherit !important;
  top: inherit !important;
  height: inherit !important;
  transform: scale(0.20);
  right: 60px !important;
  bottom: 120px !important;
  transform-origin: right bottom 0;
  // border: solid 1px #fff;
  border-radius: 100px;
  padding: 80px;
  // box-shadow: 2px 2px 10px #fff;
  // background-color: #161819 !important;

  > .position-relative {

    // animation: circle 10s linear infinite;
    // -webkit-transform: rotateY(0deg);
    .new_person_registration {
      background-size: contain !important;
      background: url('../../assets/img/index/topPup3.png') no-repeat;
    }
  }

}

@media (max-width: 600px) {
  .suoxiao {
    transform: scale(0.16);
    left: 60px !important;
    transform-origin: left bottom 0;
    bottom: 100px !important;

    > .position-relative {
      transform: scale(2.4) !important;
    }
  }
}

.wrapvx {
  position: relative;
  width: 100%;
  overflow: hidden;
  align-items: center;

}

.lb {
  width: 30px;
  background-color: #262632;
  height: 30px;
  line-height: 30px;
  left: 0px;
  position: absolute;
  z-index: 9;
}

.wrapvxFont {
  position: absolute;
  width: 740px;
  display: flex;
  align-items: center;
  transform: translateX(0%);
  animation: scrols 25s infinite linear;
}

@keyframes scrols {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.conten_tips {
  text-align: center;
  background-color: #262632;
  color: #eee;
  font-size: 14px;
  padding: 5px 0;

  @media (max-width: 1300px) {
    font-size: 12px;
    display: flex;
    height: 30px;
  }
}

.content-roll-navigation {
  width: calc(100% - 20px);
  border-radius: 6px;
  margin: 10px auto;
  margin-top: 20px;
  padding: 0px 0px;
  padding-bottom: 10px;
  // background-image: linear-gradient(to top, #1E1E2B, #12121A) !important;
  display: flex;

  > div {
    width: 25%;
    text-align: center;
    font-size: 12px;
    border-left: none !important;

    > span {
      margin-top: -8px;
      display: block;
      font-size: 13px;
      font-weight: 400;
      font-family: Monaco;
      opacity: .8;
    }

    .iconfont {
      display: block;
      font-size: 26px;
      margin-bottom: 8px;
      opacity: .9;
    }
  }

  > div:first-child {
    border-left: none !important;
  }

  .text-huang + .text-huang {
    border-left: 1px solid;
  }
}

.new_person_registration {
  width: 990px;
  height: 640px;
  background: url('../../assets/img/index/topPup2.png') no-repeat;
  background-size: contain;
  background-position: center;

  @media (max-width: 1300px) {
    width: 360px;
    height: 320px;
    background-position: center 0px;
    background-size: cover;

    .person_registration_close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .person_registration_text {
    position: absolute;
    top: 140px;
    left: 50%;
    font-size: 28px;

    @media (max-width: 1300px) {
      position: absolute;
      top: 65px;
      left: 50%;
      font-size: 16px;
    }
  }

  .person_registration_txt {
    position: absolute;
    top: 180px;
    left: 70px;
    font-size: 32px;
    color: #b6b9e6;

    @media (max-width: 1300px) {
      position: absolute;
      top: 88px;
      left: 8%;
      font-size: 16px;
    }
  }

  .person_registration_list {
    width: 450px;
    height: 100px;
    position: absolute;
    top: 270px;
    left: 140px;

    @media (max-width: 1300px) {
      width: 340px;
      height: 100px;
      position: absolute;
      top: 130px;
      left: 60px;
    }

    .text-center {
      font-size: 14px;

      @media (max-width: 1300px) {
        width: 70px;
        font-size: 12px;
      }
    }

    > div {
      margin-right: 60px;

      img {
        width: 160px;
      }

      @media (max-width: 1300px) {
        margin-right: 30px;

        img {
          width: 70px;
        }
      }
    }
  }

  .person_registration_close {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 115px;
    right: 75px;

    @media (max-width: 1300px) {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 40px;
      right: 25px;
    }
  }
}

.dashboard_new {
  max-width: 1720px;
  margin: 0 auto;

  @media (max-width: 1300px) {
    width: calc(100% - 20px);
  }

  &-container {
    margin: 30px;
  }

  &-text {
    font-size: 30px;
    line-height: 46px;
  }

  .gun_card {
    .gun_card_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 0px;
      column-gap: 90px;

      @media (max-width: 1300px) {
        margin-top: .5314009662rem;
        // width: 23.15rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: .1rem;
      }
    }

    .item-main {
      position: relative;
      // overflow: hidden;
      padding: 20px;
      width: 364px;
      height: 420px;
      margin-top: -20px;
      border-radius: 20px;
      // background: url('../../assets/img/news/red-bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center -20px;
      // box-shadow: 0px 1px 0px 0px rgba(255,255,255,0.08);
      transition-duration: .3s;
      -webkit-transition-duration: .3s;
      text-align: center;

      .hot {
        width: 55px;
        position: absolute;
        top: 65px;
        right: 55px;

        @media (max-width: 600px) {
          width: 40px;
          position: absolute;
          top: 30px;
          right: 25px;
        }
      }

      @media (max-width: 1300px) {
        border-radius: 6px;
        background-position: center -10px;
        width: 171px;
        margin-bottom: 30px;
        height: 207px;
        overflow: inherit;
      }

      .foot-text {
        height: 51px;

        @media (max-width: 1300px) {
          line-height: 20px;
          height: 35px;
          position: relative;
          z-index: 3;
        }

        > div {
          display: flex;
          align-items: center;

          > span {
            font-family: Arial-BoldMT;
            font-size: 13px;
          }

          > span:first-child {
            margin-top: -2px;
          }
        }

        > .zk {
          background-image: url(../../assets/zk.png);
          width: 85px;
          height: 51px;
          background-size: 80px 51px;
          background-repeat: no-repeat;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          font-family: Monaco;
          text-align: center;

          @media (max-width: 600px) {
            width: 70px;
            height: 35px;
            background-size: 70px 35px;
            font-size: 12px;
          }
        }
      }

      &-img {
        width: 86%;
        padding-top: 34px;
        border-radius: 10px;

        @media (max-width: 1300px) {
          padding-top: 16px;
        }
      }

      &-price {
        position: absolute;
        right: 12px;
        top: 13px;
        padding: 0 10px;
        max-width: calc(100% - 80px);
        height: 34px;
        background-color: #000;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .cp {
        font-family: Arial-BoldMT;
        color: #35C9EE;
      }

      .cp-img {
        flex-shrink: 0;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
      }

      &-foot {
        width: 100%;
        padding: 0 40px;
        position: absolute;

        bottom: -40px;
        left: 0;
        line-height: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > p {
          font-family: MicrosoftYaHei;
          font-size: 18px;
          width: 200px;
          margin-bottom: -15px;
          height: 50px;
          line-height: 50px !important;

          @media (max-width: 600px) {
            font-size: 14px;
            font-weight: Regular;
            background-size: 80px 30px;
            margin-bottom: -10px;
          }
        }

        > .foot-text {
          display: flex;
          align-items: center;
          margin-top: 10px;
          width: 200px;
          background-image: url(../../assets/img/ADT/home/titlebg.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 160px 50px;
          justify-content: center;

          @media (max-width: 600px) {
            margin-top: 0;
            background-size: 100px 30px;
          }

          .money {
            :deep(img) {
              vertical-align: middle;
            }

          }

          > span {
            font-family: Monaco;
            font-size: 16px;

            @media (max-width: 600px) {
              font-size: 12px;
            }
          }
        }

        @media (max-width: 1300px) {
          bottom: -40px;
          width: 100%;
          flex-direction: column;
          align-items: center;
          padding: 0 20px;
          font-size: 14px;
        }
      }

      &-name {
        position: absolute;
        left: calc(50% - 73px);
        bottom: 20px;
        padding: 0 10px;
        min-width: 147px;
        max-width: calc(100% - 30px);
        height: 34px;
        line-height: 34px !important;
        text-align: center;
        background-color: rgba(0, 0, 0, .8);
        border-radius: 6px;
        font-family: Montserrat-SemiBold;
        font-size: 14px;
        color: #fff;
        font-weight: 700;
      }

      &-bullet {
        position: absolute;
        left: 12px;
        top: 13px;
        margin: 0;
        width: 110px;
        height: 34px;
        border-radius: 8px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        color: #fff;

        span:first-child {
          font-size: 12px;
          font-weight: 700;
          color: #8885a1;
          padding-right: 7px;
        }

        span:last-child {
          font-family: Montserrat-SemiBold;
          font-size: 12px;
          color: #ff5722;
        }
      }
    }

    .gun_card_list_a {
      margin-bottom: 30px;
      // border-radius: 20px;
      a {
        display: flex;
        justify-content: center;
      }
    }

    // .gun_card_list_a:hover {
    //     box-shadow: 0 0 0 1px #22e2ff,0 0 15px 0 #22e2ff;
    //     border-radius: 20px;
    //     z-index: 22;
    //     transition: all .5s;
    //     transform: scale(1.05);
    //     @media (max-width:1300px) {
    //         transform:scale(1);
    //         box-shadow:none;
    //         box-shadow:6px;
    //     }
    // }
  }
}

.indexPup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, .5);

  @media (max-width: 1000px) {
    top: 0px;

    & img {
      width: 100%;
    }
  }
}

// 新写的手机端
.index-phone {
  @media (min-width: 1000px) {
    display: none !important;;
  }

  background-image: url('../../assets/img/phone-nav/navbg.png');
  background-size: 100% 100%;
  padding: 20px 10px;

  &-item {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
}

.price_bottom_bg {
  display: inline-block;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 14px;
  background-image: url('../../assets/img/phone-nav/item.png');
  background-size: 100% 100%;
  // background-color: #f3a45d;
  border-radius: 3px;
  margin-top: 8px;
  font-family: Roboto;
}

.sum-stast {
  font-size: 30px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}

.top-sum {
  font-size: 70px;

  @media (max-width: 1000px) {
    font-size: 24px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 90%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 40%;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 40%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
}

.swiper {
  height: 25.6rem;

  @media (max-width: 1000px) {
    height: 120px;
  }

  &-roll {
    height: 100%;
    transition: all .5s;

    &:hover {
      background-size: 110% 110%;
    }

    &-user {
      width: 86px;
      height: 86px;
      left: -40px;
      overflow: hidden;
      background-color: #090827;
      border-radius: 14px;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, .3);

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
        background: linear-gradient(144deg, rgba(57, 85, 232, 0) -11%, rgba(57, 85, 232, 0.6) 111%);

      }

    }
  }

  &-cover {
    width: 130px;
    left: calc(50% - 60px);
    top: calc(50% - 40px);
    height: 100px;
  }
}


.side-box {
  width: 200px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1366px) {
    width: 100%;
  }

  &-roll {
    width: 100%;

    @media (max-width: 1366px) {
      &-item:nth-child(3) {
        display: none !important;
      }
    }

    &-item {
      width: 31%;
      transition: all .5s;
      overflow: hidden;

      @media (max-width: 1600px) {
        width: 32%;
      }

      @media (max-width: 1366px) {
        width: 48%;
      }

      @media (max-width: 760px) {
        width: 48%;
      }

      &:hover {
        transform: translateY(-20px);
      }

      &-bg {
        left: 20%;
        z-index: 0;
      }
    }
  }

  &-activity {
    margin-left: 20px;
  }

  &-box {
    &-title {
      &-top {
        // border-top: 1px solid var(--main-blue);
        // border-bottom: 1px solid var(--main-blue);
        background-size: initial;
        background-position: top;
        background-repeat: no-repeat;

        // background-size: 100% 100%;
        @media (max-width: 1000px) {
          height: 80px;
          background-size: contain;
          background-position: center;
        }
      }

      &-name {
        font-size: 28px !important;
        border-top: 1px solid rgba(var(--main-blue), 1);
        border-bottom: 1px solid rgba(var(--main-blue), 1);
        // background-color: #0d101a;
        height: 165px;
        line-height: 165px;
        margin-top: 20px;

        @media (max-width: 1000px) {
          height: 40px;
          line-height: 40px;
        }
      }
    }

    &-list {
      width: 100%;
      display: flex;

      &-item {
        width: 25%;

        @media (max-width: 1600px) {
          width: 25%;
        }

        @media (max-width: 1366px) {
          width: 33.33%;
        }

        @media (max-width: 760px) {
          width: 50%;
        }

        &-img img {
          transition: all .5s;
        }

        &-open {
          width: 100px;
          margin: 0 auto;
          opacity: 0;
          transform: scale(5, 5);
          transition: all .5s;

          & > div {
            border-radius: 4px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
          }
        }

        .f_title {
          transition: all .5s;
          font-weight: 600
        }

        &-f {
          background-size: 150% 150%;
          background-position: center;
          transition: all .5s;
        }

        &:hover &-f {
          background-size: 100% 100%;
        }

        &:hover .f_title {
        }

        &:hover &-img img:nth-child(2) {
          transform: translateY(-20px);
          // transform: scale(1.2)
        }

        &:hover &-img img:nth-child(1) {
          // transform: scale(1.2)
        }

        &:hover &-money {
          /*display: none;*/
        }

        &:hover &-open {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes boxBg {
  to {
    transform: scale(.8);
  }

  from {
    transform: scale(1.15);
  }
}

@keyframes boxBox {
  to {
    transform: rotate(3deg);
  }

  from {
    transform: rotate(-10deg);
  }
}</style>
