<template>
    <div class="mf-box-container free mb-5">
        <!-- content -->
        <div class="h4 text-white font-weight-bold text-center">
            <!-- <div>{{$route.meta.title}}</div> -->
            <!-- <div class="cursor-pointer text-center color-yellow" style="font-size: 16px"
            @click="playingMethod = true">活动规则</div> -->
            <img v-if="ua === 'web'" class="box-banner" src="../../assets/img/ADT/common/new-people-bg-pc.jpg" alt="">
            <img v-else class="box-banner" src="../../assets/img/ADT/common/new-people-bg-phone.jpg" alt="">
        </div>

        <div class="row box-container">
            <router-link
                    :to="'/active/outOfTheFree/' + item.box_id+'?id='+item.id + '&type='+item.type"
                    v-for="(item,index) in box_list" :key="index"
                    class="text-center free-content bg-size-100 bg-repeat col-lg-2 col-md-3 col-sm-4 col-6">
                <div class="teshuzhaohuuimm">
                    <div class="img-box">
                        <el-image :src="item.box.cover" lazy style="min-width: 100px; min-height: 100px">
                         <div slot="placeholder" class="image-slot">
                            图片加载中<i class="dot el-icon-loading"></i>
                        </div>
                        </el-image>
                     
                    </div>
                    <div class="text-box">
                        <div class="h6 mt-3">{{item.name}}</div>
                    <div class="title_qian text-overflow-4" style="line-height:20px">{{item.description}}<br/></div> 
                    </div>
                 
                </div>
            </router-link>

            <vacancy v-if="box_list.length == 0"></vacancy>
        </div>
        <!-- 玩法介绍 -->
        <div class="" v-if="playingMethod">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                 class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2" style="line-height: 2.0">
                    <p>1.达到宝箱条件即可免费开启相应福利宝箱。</p>
                    <p>&nbsp;</p>
                    <p>2.新人注册，咨询客服，领取新人CDK赠礼，可开启新人CDK宝箱一次。</p>
                    <p>&nbsp;</p>
                    <p>3.每个账号仅限使用一次新人CDK，重复使用将被系统判定为恶意套利，可能会对您的账号进行封禁，为了您的账号安全，请勿重复使用新人CDK！</p>

                </div>
                <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>

            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>

    </div>
</template>

<script>
    import sUserAgent from "@/assets/js/common.js";
    import {mapState} from 'vuex'
    import $api from '@/api/outOfTheFree/index'
    export default {
        name: "indexV2",
        data() {
            return {
                blocks:[
                    { padding: '12px 19px', background: '#312414' },
                ],
                prizes:[],
                buttons: [{}],
                buttonsRepetition:true,
                activity_rules:false,

                box_list:[],
                cdk:'',
                box_gun_active:{},
                box_gun_active_is_show:false,
                playingMethod:false,
            }
        },
        computed:{
            ...mapState(['user_info']),
            ua() {
            console.log("sUserAgent");
            console.log(sUserAgent);
            return sUserAgent.sUserAgent();
            },
        },
        mounted() {
            this.init()
        },
        methods:{
            async init(){
                await this.getWelfare()

            },
            // 获取宝箱数据
            async getWelfare(){
                try {
                    let {data} = await $api.getWelfare()
                    data.forEach((item,index) =>{
                        if(item.box == null || item.box == undefined || !item.box){
                            data.splice(index,1)
                        }
                    })


                    this.box_list = data

                   

                }catch (e) {
                    console.log(e)
                }
            },
            // 整理数据
            welfareDataPrizes(resData){
                let data = JSON.parse(JSON.stringify(resData))
                let obj =data.box_list.map((item,index) =>{
                    let obj =  {
                        skin_id:item.skins.id,// 每个武器的id
                        borderRadius:1,
                        x: index>=4? index-4>=4?index-8:index-4 : index,
                        y: index<4? 0 : index<8 ? 1:2,
                        fonts: [
                            { text: 'F ' + item.skins.bean, fontSize: '12px', top: 5,fontColor:'#FFFFFF' },
                        ],
                        imgs:[{
                            src: item.skins.cover,
                            width:80,
                            height:60,
                            top:25,
                        }],
                        background:'rgba(246,175,24,.5)',
                    }
                    return obj
                })
                console.log(obj)
                return obj
            },

            async startCallBack(item,index){

                if(this.buttonsRepetition){
                    this.buttonsRepetition = false
                }
                let {data} = await $api.get_welfare_open({id:item.id,cdk:item.cdk})
                let i = this.box_list[index].box_list.findIndex(t =>{
                    return t.skin_id == data.skin_id
                })

                // 由于是循环出来的，所以传来的是一个数组
                let luck_grid = this.$refs[`LuckyGridClick${index}`]
                luck_grid[0].play()
                // let sum = Math.random() * 12
                setTimeout(() => {
                    luck_grid[0].stop(i)
                }, 2000)
            },
            // 抽奖结束
            endCallBack (prize) {
                // 抽奖结束 - 需要传输的东西在整理数据的时候传输进来需要
                this.buttonsRepetition = true
                this.box_gun_active_is_show = true
                this.box_gun_active = prize
                this.$notify({
                    title: '恭喜你获得',
                    message: `${prize.fonts[0].text}，已放入您的仓库`,
                    type: 'success',
                    position: 'top-left'
                });

                console.log(prize)

            }
        }
    }
</script>

<style lang="scss" scoped>
.teshuzhaohuuimm {

    .img-box {
        @media (max-width: 600px) {
            margin-bottom: 70px;
        } 
    }

    .text-box {
        @media (min-width: 1000px) {
            margin-top: -100px;
        }   
    }
    :deep(.el-image) {
        overflow: visible !important;
        
        img {
            // transform: scale(1) !important;
            width: 100%;
            
        }
    }

    @media (min-width: 1300px) {
        .el-image__inner {
    // max-height: 203px !important;
}
    }
    :deep(.el-image) {
        @media (max-width:600px) {
        max-height: 115px;
    }
    }
  
}
.mf-box-container {
    overflow-x: hidden;
    .box-banner {
    width: 100%;
    // height: 462px;
    @media (max-width:600px) {
        height: auto !important;
    }
}
.box-container {
    padding: 0 70px;
    margin-top: 65px;
    @media (max-width:600px) {
            padding: 0 !important;
            margin-top: -20px;
        }
    .free-content {
        margin-bottom: 40px;
        @media (min-width:1300px) {
            flex: 0 0 20% !important;
            padding-right: 0px !important;
            padding-left: 0px !important;
            margin-top: 0 !important;
        }
    }
    .title_qian {
        margin: 0 3rem;
        @media (max-width:600px) {
            margin: 0 1rem;
        }
        margin-top: 0.5rem;
    }
}
    .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        line-height: 100px;
        min-height: 100px;
        color: #909399;
        font-size: 14px;
    }
    .col-lg-3{
        flex: 0 0 20%;
        max-width: 25%;
        @media (max-width:1300px) {
            flex: 0 0 40%;
            padding-right: 0px;
            padding-left: 0px;
            margin-top: 0 !important;
        }
    }
}

</style>
