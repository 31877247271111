
<template>
  <div>

    <div class="d-flex flex-wrap">
      <div @click="change_typeCick('')" :class="status=='0'?'active' : ''" class="bg-brown px-4 mr-2 cursor-pointer d-flex align-items-center">
        全部
      </div>
      <el-select v-model="value" placeholder="请选择收支明细" @change="change_typeCick">
        <el-option
                v-for="item in change_type"
                :key="item.type"
                :label="item.name"
                :value="item.type">
        </el-option>
      </el-select>

    </div>

    <div>
      <div class="mt-3  content-list">
        <table cellspacing="0" cellpadding="0" border="0" class="content-list-box" style="">
          <colgroup>
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="200">
          </colgroup>
          <thead class="text-danger">
          <tr class="grade">
            <th colspan="1" rowspan="1">
              <div class="cell">变动金额 </div>
            </th>

            <th colspan="1" rowspan="1">
              <div class="cell">账户余额</div>
            </th>

            <th colspan="1" rowspan="1">
              <div class="cell">订单号</div>
            </th>
            <th colspan="1" rowspan="1">
              <div class="cell">消费方式</div>
            </th>

            <th colspan="1" rowspan="1">
              <div class="cell">时间</div>
            </th>

          </tr>
          </thead>
          <tr class="grade lucky-H-bg border-bottom"  v-for="(item,index) in tableData" :key="index">

            <td >
              <div class="cell">{{item.bean }}</div>
            </td>
            <td >
              <div class="cell">{{item.final_bean }}</div>
            </td>
            <td >
              <div class="cell">{{item.type_alias }}</div>
            </td>

            <td >
              <div class="cell">{{item.change_type_alias }}</div>
            </td>
            <td >
              <div class="cell">{{item.created_at}}</div>
            </td>
          </tr>
        </table>
        <div class="mt-3">
          <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  @current-change="currentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $api from '@/api/user/index'
  export default {
    name: "income",
    props:{

    },
    data(){
      return {
        change_type:[],
        tableData:[],
        type:'',
        status:0,
        pageSize:0,
        total:0,
        value:''
      }
    },
    filters:{
      change(val){
        switch (val) {
          case 1:
            return '盲盒开箱';
          case 2:
            return "盲盒对战";
          case 3:
            return "幸运饰品";
          case 4:
            return "装备分解";
          case 5:
            return "充值";
          case 6:
            return "首充奖励";
          case 7:
            return "被邀充值收益";
        }
      }
    },
    mounted() {
      this.getBeanChange_type()
      this.change_typeCick('')
    },
    methods:{
      currentChange(val){
        this.change_typeCick(this.type,val)
      },
      // 充值记录
      async change_typeCick(item,page = 1){
        console.log(item)
        this.type = item
          try {
            let {data, code} = await $api.getUsetChange_records(page,item)
            if (code == 500) return
            this.tableData = data.data
            this.total = data.total
            this.pageSize = data.per_page
          } catch (e) {
            console.log(e)
            return
          }
      },
      getBeanChange_type(){
        $api.getBeanChange_type().then( res => {
          let arr = []
          for(let key in res.data){
            if(key ==15||key==9||key==10||key==7||key==8||key==6||key==12|| key==13||key==14){
              continue;
            }
            if(key == 3){
              res.data[key] = '幸运拉货'
            }
            arr.push({name:res.data[key],type:key})
          }
          this.change_type = arr
        })
      }
    }
  }
</script>



<style scoped lang="scss">

  .active{
    background: var(--main-yellow);
    color: var(--main-brown);
  }

  .content-list{
    overflow-x: scroll;
    width: 100%;
    &-box{
      width: 100%;
      @media (max-width: 1366px) {
        // width: 600px;
      }
    }
  }

  td{
    height: 100px;
    vertical-align: middle;
  }
  .grade {
    background-image: linear-gradient(0deg,#13161b,#20232b),linear-gradient(#13161b,#13161b);
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }



  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
  }

  .content {
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
      font-size: 12px !important;
    }
  }

  .cell {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 14px;
    @media (max-width: 1200px) {
      width: 100px;
      word-break: break-all;
      line-height: 1.5;
      padding: 5px;
    }
  }
  .highlighted-message {
    padding: 19px 5px 19px 16px;
    border: 1px solid #fada62;
    border-radius: 8px;
    background-color: rgba(250,218,98,0.12);
  }

</style>
