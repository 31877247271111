<template>
  <div class="partner">
    <div class="box">
      <div class="top">
        <div class="left">
          <h2>拉新必赚</h2>
          <p><img src="@/assets/img/ADT/user/d.png" width="14" /> 每拉一个有效用户即可获得 <img
              src="@/assets/img/ADT/home/UP@2x.png" class="upb" width="20" /> 6{{ sUserAgent != 'web' ? 'UP币'
              : ''
            }}，被拉新用户可免费开启宝箱</p>
          <p class="tip">有效用户：充值满100，24小时内更新状态</p>
        </div>
        <div class="right">
          <h2>我的拉新</h2>
          <div class="box">
            <a>
              <h2>{{ user_info.people_invited_num || 0 }}</h2>
              <p>邀请总人数</p>
            </a>
            <a>
              <h2>{{ user_info.inviter != null ? '' + user_info.inviter.invite_code : '暂无' }}</h2>
              <p>我的上级</p>
              <!-- <p>有效用户</p> -->
            </a>
            <a>
              <h2><img src="@/assets/img/ADT/home/UP@2x.png" width="22" /> {{ (user_info.invite_total_recharge) }}
              </h2>
              <p>被邀用户充值</p>
              <!-- <p>奖励总额</p> -->
            </a>
          </div>
        </div>
      </div>
      <div class="bot" v-if="sUserAgent == 'web'">
        <div class="left">
          <h2>您的合作伙伴链接</h2>
          <div>
            <input type="text" disabled :value="invite_url + user_info.invite_code"> <a @click="authCopy(1)">复制</a>
          </div>
        </div>
        <div class="right">
          <h2>邀请代码</h2>
          <div>
            <input type="text" disabled :value="user_info.invite_code"> <a @click="authCopy(2)">复制</a>
          </div>
        </div>
      </div>
    </div>

    <div class="bot1" v-if="sUserAgent != 'web'">
      <div class="left">
        <h2>您的合作伙伴链接</h2>
        <div>
          <input type="text" disabled :value="invite_url + user_info.invite_code"> <a @click="authCopy(1)">复制</a>
        </div>
      </div>
      <div class="right">
        <h2>邀请代码</h2>
        <div>
          <input type="text" disabled :value="user_info.invite_code"> <a @click="authCopy(2)">复制</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import sUserAgent from "@/assets/js/common";

export default {
  data() {
    return {
      list: [],
      invite_url: '//www.upcsgo.com/?ref_code=',
      user: { promotion_level: 1 },
    }
  },
  mounted() {
    let host = window.location.host;
    this.invite_url = 'https://' + host + '/?ref_code=';
  },
  computed: {
    ...mapState(['user_info', 'web_info']),
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },
  methods: {
    // 拷贝推广链接
    authCopy(type) {
      if (type === 1) {
        this.$common.CopyText({ content: this.invite_url + this.user.invite_code })
      } else {
        this.$common.CopyText({ content: this.user.invite_code })
      }
    }
  }
};
</script>

<style scoped lang="scss">

.partner {
  position: relative;
  width: 100%;
  //height: 430px;
  margin-bottom: 20px;

  @media (max-width:600px) {}

  >.box {
    background-image: url(../assets/img/ADT/user/promotion-bg.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 30px;

    @media (max-width:600px) {
      top: 22%;
      width: 100%;
      background-size: 95%;
      left: inherit;
      background-image: url(../assets/img/ADT/user/bot1.png);
      margin-left: inherit;
      margin: 0 auto;
      background-size: 95% 230px;
      padding-bottom: 10px;

    }

    >.top {
      padding: 40px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      flex-direction: column;

      @media (max-width:600px) {
        padding: 24px 20px;
        flex-direction: column;
        align-items: center;

      }

      >.left {
        >h2 {
          color: #00F1F4;
          font-weight: bold;
          font-size: 24px;
          font-style: italic;
          margin-bottom: 20px;

          @media (max-width:600px) {
            display: none;
          }
        }

        >p {
          display: flex;
          align-items: center;
          color: #F9C545;
          font-style: italic;
          font-family: Monaco;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;

          @media (max-width:600px) {
            font-size: 14px;
            margin-bottom: 4px;
            align-items: flex-start;

            >.upb {
              display: none;
            }
          }

          >img {
            margin: 0 4px;

            @media (max-width:600px) {
              margin-top: 3px;
              margin-right: 8px;
            }
          }
        }

        >.tip {
          color: #DFDFDF;

          font-weight: 400;
          font-size: 14px;
          padding-left: 24px;

          @media (max-width:600px) {
            margin-bottom: 16px;
            font-size: 12px;
            color: #fff;

          }
        }
      }

      >.right {

        @media (max-width:600px) {
          width: 100%;
        }

        >h2 {
          color: #fff;
          font-weight: bold;
          font-size: 24px;
          font-style: italic;
          margin-bottom: 20px;

          @media (max-width:600px) {
            font-size: 14px;
            font-style: inherit;
            padding: 0 24px;
            margin-bottom: 10px;
            margin-top: 8px;
            color: #fff;
            font-style: italic;
          }
        }

        >.box {
          display: flex;
          justify-content: space-between;
          background-color: #8bc9ff2f;
          border-radius: 8px;
          padding: 20px;

          @media (max-width:600px) {
            flex: 0 0 80%;
            max-width: 100%;
            align-items: center;
            padding: 14px 10px;
            margin: 0 24px;
          }

          >a {
            flex: 1;
            text-align: center;
            border-right: solid 1px #7978b2a7;

            >h2 {
              color: #F9C545;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: Monaco;
              font-size: 20px;
              margin-bottom: 10px;
              font-weight: bold;

              @media (max-width:600px) {
                color: #fff;
                font-size: 16px;
              }

              img {
                margin-right: 4px;

                @media (max-width:600px) {
                  width: 16px;
                }
              }

            }

            >p {
              color: #fff;
              @media (max-width:600px) {
                font-size: 12px;

              }
            }
          }

          >a:last-child {
            border: none;
          }
        }
      }
    }

    >.bot {
      background-image: url(../assets/img/ADT/user/user-invite-bg.png);
      background-size: 100% 100px;
      width: calc(100% - 80px);
      padding: 20px 40px;
      margin: 0 40px;
      height: 100px;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;

      @media (max-width:600px) {
        display: none;
      }

      >div {
        flex: 1;
        position: relative;

        >h2 {
          font-weight: bold;
          color: #fff;
          margin-bottom: 10px;
        }

        >div {
          >input {
            height: 44px;
            background-color: #272448;
            width: 95%;
            color: #ccc;
            font-family: Monaco;
            font-size: 14px;
            text-indent: 10px;
            border-radius: 4px;
          }

          >a {
            height: 36px;
            width: 80px;
            background-color: #089DB7;
            position: absolute;
            right: 24px;
            top: 30px;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
            border-radius: 4px;
            color: #fff;
          }
        }
      }
    }
  }

  .bot1 {
    background-image: url(../assets/img/ADT/user/hh.png);
    background-size: 860px 100px;
    width: 860px;
    padding: 20px 40px;
    margin: 0 auto;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;

    @media (max-width:600px) {
      top: 46%;
      //position: absolute;
      background-image: url(../assets/img/ADT/user/bot11.png);
      flex-direction: column;
      background-size: 100% 100%;
      width: calc(100% - 16px);
      padding: 20px;
      height: 180px;
    }

    >div {
      flex: 1;
      position: relative;

      @media (max-width:600px) {
        margin-bottom: 20px;
      }

      >h2 {
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;

        @media (max-width:600px) {
          text-indent: 4px;
          font-size: 12px;
        }
      }

      >div {
        >input {
          height: 44px;
          background-color: #272448;
          width: 95%;
          color: #fff;
          font-family: Monaco;
          font-size: 14px;
          text-indent: 10px;
          border-radius: 4px;

          @media (max-width:600px) {
            font-size: 12px;
            height: 36px;
            width: 100%;
            background-color: #1c1939;
          }
        }

        >a {
          position: absolute;
          height: 36px;
          width: 80px;
          background-color: #724DFC;
          position: absolute;
          right: 24px;
          top: 30px;
          line-height: 36px;
          text-align: center;
          cursor: pointer;
          border-radius: 4px;

          @media (max-width:600px) {
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            right: 2px;
            width: 70px;
            top: 25px;
          }
        }
      }
    }
  }
}
</style>
