<template>
  <div class="download">
    <div class="app_center_content">
      <div v-if="device != 'ios'" class="android_img">
        <img class="pc_show" src="@/assets/img/appdown/android.png" alt="">
        <img class="border_img" src="@/assets/img/appdown/scale.png" alt="">
        <img class="link" src="@/assets/img/appdown/link_android.png" alt="">
        <input v-if="device == 'android'" class="" @click="appStartDown(1)" type="button" value="点击下载">
      </div>
      <div v-if="device != 'android'" class="ios_img">
        <img class="pc_show" src="@/assets/img/appdown/ios.png" alt="">
        <img class="border_img" src="@/assets/img/appdown/scale.png" alt="">
        <img class="link" src="@/assets/img/appdown/link_ios.png" alt="">
        <input v-if="device == 'ios'" @click="appStartDown(2)" type="button" value="点击下载">
      </div>
    </div>
    <!-- <video
      class="pcVideo"
      autoplay=""
      loop=""
      preload="auto"
      muted=""
      id="top-video"
    >
      <source src="@/assets/video/appDownloadBg.mp4" type="video/mp4" />
    </video> -->
    <!-- <video
      class="wapVideo"
      autoplay=""
      loop=""
      preload="auto"
      muted=""
      id="top-video"
    >
      <source src="@/assets/video/m_appDownloadBg.mp4" type="video/mp4" />
    </video> -->
    <!-- <img class="wapVideo" src="@/assets/video/m_appDownloadBg.webp" /> -->
    <!-- UPCSGO暂时无APP下载链接暂时隐藏 -->
    <!-- <div class="btn">
      <a v-if="device == 'android'" @click="appStartDown(1)">
        <img src="@/assets/video/androidBtn.gif" />
      </a>
      <a v-if="device == 'ios'" @click="appStartDown(2)">
        <img src="@/assets/video/iosBtn.gif" />
      </a>
      <a v-if="device == 'web'" @click="appStartDown(1)">
        <img src="@/assets/video/androidBtn.gif" />
      </a>
      <a v-if="device == 'web'" @click="appStartDown(2)">
        <img src="@/assets/video/iosBtn.gif" />
      </a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      device: "web",
    };
  },
  mounted() {
    this.isDevice();
    console.log(this.device)
  },
  methods: {
    appStartDown(type) {
      if (type == 1) {
        window.location = "https://www.upcsgo.com/android.apk";
      } else if (type == 2) {
        window.location = "https://www.upcsgo.com/ios.mobileconfig";
      }
    },
    isDevice() {
      var _this = this;
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        _this.device = "android";
      }
      if (isIOS) {
        _this.device = "ios";
      }
      if (!isAndroid && !isIOS) {
        _this.device = "web";
      }
    },
  },
};
</script>

<style scoped lang="scss" >
.download {
  position: relative;
  width: 100%;
  height: 110vh;
  background: url("~@/assets/img/appdown/app_download_pc.png") no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 0;
  @media (max-width:900px) {
    background: url("~@/assets/img/appdown/app_download_phone.png") no-repeat;
    background-size: 100%;
    height: 80vh;
    .pc_show {
      display: none;
    }
  }
  
  .app_center_content {
    width: 50rem;
    height: 25rem;
    position:absolute;
    top: 25%;
    display: flex;
    justify-content: center;
    > div {
      width: 45%;
    }
    input {
      color: #fff;
      background: #131b7d;
      padding: 0.7rem 3rem;
      border-radius: 0.5rem;
      border: 0.1rem solid #39d6fb;
    }

    @media  (max-width:900px) {
      width: 100%;
      top: calc(38vh);
      .border_img {
        width: 100%;
      }
    }
    .link {
      width: 66%;
      margin-top: -50%;
      transform: translateY(-37%);
      @media  (max-width:900px) {
        margin-top: -60%;
        width: 70%;
      }
    }
    .android_img {
      text-align: center;
      position: relative;
      left: 0;
      img {
      }
    }
    .ios_img {
      text-align: center;
    }
  }
  > .btn {
    display: flex;
    justify-content: center;
    margin-top: -100px;
    position: relative;
    > a {
      margin: 0 80px;
      > img {
        width: 300px;
      }
    }
  }
  .pcVideo {
    display: block;
    width: 100%;
  }
  .wapVideo {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .wapVideo {
    width: 100%;
    display: block !important;
  }
  .pcVideo {
    display: none !important;
  }
  .download {
    > .btn {
      margin-top: -50px;
      a {
        margin: 0 0px;
        > img {
          width: 200px !important;
        }
      }
    }
  }
}
</style>