<template>
    <div class="d-flex">
        <button @click="$router.go(-1)" class="fback">返回</button>
        <div class="content text-center" style="width: 100%;margin: 0 auto;">
            <!-- 端午 -->
            <img class="web" v-if="imgVal==10" style="margin: 0 auto;max-width:1400px;"  width="100%" src="../../assets/img/ADT/zt-img/PC-dw.jpg" alt="">
            <img class="phone" v-if="imgVal==10" style="margin: 0 auto;max-width:1400px;"  width="100%" src="../../assets/img/ADT/zt-img/H5-dw.jpg" alt="">
            <!-- 锦上添花 -->
            <img class="web" v-if="imgVal==11" style="margin: 0 auto;max-width:1400px;"  width="100%" src="../../assets/img/ADT/zt-img/PC-jsth.jpg" alt="">
            <img class="phone" v-if="imgVal==11" style="margin: 0 auto;max-width:1400px;"  width="100%" src="../../assets/img/ADT/zt-img/H5-jsth.jpg" alt="">
            <!-- 非酋任务 -->
            <img class="web" v-if="imgVal==12" style="margin: 0 auto;max-width:1400px;"  width="100%" src="../../assets/img/ADT/zt-img/PC-fq.jpg" alt="">
            <img class="phone" v-if="imgVal==12" style="margin: 0 auto;max-width:1400px;"  width="100%" src="../../assets/img/ADT/zt-img/H5-fq.jpg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                imgVal:false
            }
        },
        mounted(){
            this.imgVal = this.$route.params.id;
        }
    }
</script>

<style scoped lang="scss" >
    .fback {
        color: #fff;
        position: fixed;
        border: 2px solid #ccc;
        background: #e2a632;
        width: 60px;
        height: 40px;
        border-radius: 0.5rem;
        top: 1rem;
        right: 1rem;
        @media (min-width:600px) {
            display: none;
        }
    }
    @media screen and (max-width:680px){
        .web {
            display: none !important;
        }
        .phone{
            display: flex !important;
        }
    }
    /*PC*/
    @media screen and (min-width:700px){
        .web {
            display: flex !important;
        }
        .phone{
            display: none !important;;
        }
    }
</style>