<template>
    <div class="user_new">

        <div :style="{height: (ua != 'web' && user_info.is_verify == 0) ? '1200px':'1000px'}">
            <div class="sideBar">
                <div class="sideBar-user">
                    <div class="img" @click="upImgVisible = true">
                        <img class="border1" src="@/assets/img/ADT/user/yuan.png" />
                        <img :src="user_info.avatar" />
                    </div>
                    <div class="info">
                        <h2>{{ user_info.name }}</h2>
                        <p>ID:{{ user_info.id }}</p>
                        <div class="press">
                            <img v-if="user_info.vip_level"
                                :src="require(`@/assets/img/ADT/common/V${user_info.vip_level}@2x.png`)" />
                            <span style="width:50%"></span>
                        </div>
                    </div>
                </div>
                <div class="edit">
                    <a @click="changeName">改昵称</a>
                    <a @click="changePassword">改密码</a>
                    <a>邀请码:{{ user_info.invite_code }}</a>
                </div>
                <div class="recharge">
                    <div class="left">
                        <img src="@/assets/img/ADT/home/UP@2x.png" width="20" />{{ user_info.bean }}
                    </div>
                    <div class="right" @click="$router.push('/user/recharge')">
                        立即充值
                    </div>
                </div>
                <div class="recharge beibao" @click="openUrl(1)">
                    <div class="left">
                        <img src="@/assets/img/ADT/user/beibao.png" width="20" />我的背包
                    </div>
                    <div class="right">
                        <img src="@/assets/img/ADT/user/right.png" />
                    </div>
                </div>
                <div class="header pc-none" :class="user_info.is_verify != 0 ? 'is_verify' : ''">

                    <div class="authenticated" v-if="ua != 'web'">
                        <h2>实名认证: <a v-if="user_info.is_verify != 0"><img src="@/assets/love/rz_active.png"
                                    width="22" />已认证</a></h2>
                        <div class="idcard" v-if="user_info.is_verify == 0"><img src="@/assets/love/card.png" width="22" />
                            <input v-model="idcard" placeholder="请输入身份证号码" /></div>
                        <div class="idname" v-if="user_info.is_verify == 0"><img src="@/assets/love/name.png" width="22" />
                            <input v-model="idname" placeholder="请输入姓名" /></div>
                        <a class="rz" @click="cardNameSubmit()" v-if="user_info.is_verify == 0"><img
                                src="@/assets/love/rz.png" width="22" />
                            认证</a>
                    </div>

                </div>
                <div class="steam" v-if="ua != 'web'">
                    <h2>STEAM交易地址</h2>
                    <div class="stBox">
                        <input :disabled="steamStatus" v-model="steam_url" placeholder="请输入您的STEAM URL" />
                    </div>
                    <p class="p1">1、交易链接一旦绑定则无法更换,请谨慎操作,同一链接更新token不限制. </p>
                    <p>2、请勿绑定他人的steam交易链接</p>
                    <div class="btns">
                        <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
                            target="_blank">获取</a>
                        <a @click="getSteamLink()"><img src="@/assets/img/ADT/user/save.png" /> 保存地址</a>
                    </div>
                </div>
                <div class="menu">
                    <a class="steam1" @click="openUrl(2)" :class="`${index == 2 ? 'active' : ''}`"><img
                            src="@/assets/img/ADT/user/STEAM@2x.png" /> STEAM交易链接</a>
                    <a @click="openUrl(3)" :class="`${index == 3 ? 'active' : ''}`"><img class="no1"
                            src="@/assets/img/ADT/user/zd.png" /> 提货账单 <img src="@/assets/img/ADT/user/right.png"
                            v-if="ua != `web`" width="6" height="10" /></a>
                    <a @click="openUrl(4)" :class="`${index == 4 ? 'active' : ''}`"><img class="no1"
                            src="@/assets/img/ADT/user/sz.png" /> 收支明细 <img src="@/assets/img/ADT/user/right.png"
                            v-if="ua != `web`" width="6" height="10" /></a>
                    <!-- <a @click="openUrl(5)" :class="`${ index==5?'active':'' }`"><img src="@/assets/img/ADT/user/tg.png" /> 会员推广</a> -->
                    <a @click="openUrl(5)" :class="`${index == 5 ? 'active' : ''}`"><img class="no1"
                            src="@/assets/img/ADT/user/cz.png" /> 充值明细 <img src="@/assets/img/ADT/user/right.png"
                            v-if="ua != `web`" width="6" height="10" /></a>
                </div>
                <div class="bottom" :style="{bottom: (ua != 'web' && user_info.is_verify == 0) ? '-300px':'-120px'}" @click="loginOut">
                    退出登录
                </div>
            </div>
            <div class="sideContent" v-if="ua == 'web'">
                <div class="authenticated">
                    <h2>实名认证:</h2>
                    <div class="idcard" v-if="user_info.is_verify == 0"><img src="@/assets/love/card.png" width="22" />
                        <input v-model="idcard" placeholder="请输入身份证号码" /></div>
                    <div class="idname" v-if="user_info.is_verify == 0"><img src="@/assets/love/name.png" width="22" />
                        <input v-model="idname" placeholder="请输入姓名" /></div>
                    <a @click="cardNameSubmit()" v-if="user_info.is_verify == 0" class="rz"><img src="@/assets/love/rz.png"
                            width="22" /> 认证</a>
                    <a class="success" v-else><img src="@/assets/love/rz_active.png" width="22" />已认证</a>
                </div>
                <Knapsack v-if="index == 1" />
                <Steam v-if="index == 2" />
                <Bill v-if="index == 3" />
                <Record v-if="index == 4" />
                <Czrecord v-if="index == 5" />
            </div>
        </div>
        <!-- 
        <div class="header pc-none">
            <div class="head-top-user">
                <div class=" flex">
                    <div class="head_avatar" @click="upImgVisible = true">
                        <img :src="user_info.avatar">
                    </div>
                    <div class="head_avatar_vip">
                        <div class="head_avatar_vip_name">{{ user_info.name }}</div>
                        <div class="userId">ID:{{ user_info.id }}</div>
                        <div class="flex margin-top">
                            <img :src="`${require(`@/assets/img/nav/${user_info.vip_level}.png`)}`" alt="">
                            <div style="width:100px;" class="margin-left margin-top-xs"><el-progress color="#C79A5E"
                                    :show-text="false" :percentage="50"></el-progress></div>
                        </div>
                    </div>
                </div>
                <div class="head-bottom flex">
                    <div class="info_name" @click="changeName">修改昵称</div>
                    <div class="info_mima" @click="changePassword">修改密码</div>
                    <div class="info_code">邀请码<span class="text-huang">{{ user_info.invite_code }}</span></div>
                </div>
            </div>
            <div class="both module">
                <p class="title">我的账户资产</p>

                <div class="info flex" style="line-height:20px">
                    <div class="flex" style="margin-left:50px">
                        <money />{{ user_info.bean }}
                    </div>
                    <div class="flex" style="margin-left:50px">
                        <money :integral="false" color="#FFF" />{{ user_info.integral }}
                    </div>
                </div>
                <button class="btn recharge common-btn" @click="$router.push({ path: '/user/recharge' })">
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAAoklEQVQoka2RsQkCQRBF33qiKCpYhImBbViRPRhZgDWYGRtagKFt7IEeiPdlcDA6VHb3w09m4c3/s0HSGtgDc9J0B7YGaoBhIuQjAykXYuoDR2CcybmFQoHoFaF4tcqBbUaY1qpdgRnwTARZkFj012pgapcHLj4PgNwrYPKDUyMp6q2zhevwyd+/KVqigVMXwK5j2/KPZlXRG23co0TGAzi8AH54bZ9fkinkAAAAAElFTkSuQmCC">
                    <span>立即充值</span>
                </button>
            </div>
            <div class="authenticated" v-if="ua != 'web'">
                <h2>实名认证: <a v-if="user_info.is_verify != 0"><img src="@/assets/love/rz_active.png" width="22" />已认证</a></h2>
                <div class="idcard" v-if="user_info.is_verify == 0"><img src="@/assets/love/card.png" width="22" /> <input
                        v-model="idcard" placeholder="请输入身份证号码" /></div>
                <div class="idname" v-if="user_info.is_verify == 0"><img src="@/assets/love/name.png" width="22" /> <input
                        v-model="idname" placeholder="请输入姓名" /></div>
                <a @click="cardNameSubmit()" v-if="user_info.is_verify == 0"><img src="@/assets/love/rz.png" width="22" />
                    认证</a>
            </div>
            <div class="both module">
                <p class="title">STEAM交易地址</p>
                <div class="info">
                    <input v-model="steam_url" placeholder="输入您的 STEAM URL" type="text">
                </div>
                <p style="margin-top:12px;font-size: 12px;">1.交易链接一旦绑定则无法更换,请谨慎操作,同一链接更新token不限制.</p>
                <p style="margin-bottom: 8px;font-size: 12px;">2请勿绑定他人的steam交易链接</p>
                <div class="flex">
                    <button class="btn acquire" style="margin-top:10px">
                        <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
                            target="_blank" class="get common-btn">
                            <span>获取</span>
                        </a>
                    </button>
                    <button class="btn set" @click="getSteamLink" style="margin-top:10px">
                        <img src="../../static/roll/shezhidizhi.png">
                        <span>保存地址</span>
                    </button>
                </div>
            </div>
        </div> -->
        <!--  验证码需要登录的那个弹窗 -->
        <el-dialog custom-class="login_egister1" :show-close="false" :close-on-press-escape="false"
            :close-on-click-modal="false" :visible.sync="passwordShow" width="500px">
            <div class="login d-flex py-5 px-3" style="justify-content: center;">
                <div class="login-right">
                    <!-- 验证码 -->
                    <div class="d-flex border-dark-brown align-items-center mt-3">
                        <div class="bg-bg-2 login-right-input">
                            <div>
                                <i class="iconfont text-white icon-dunpai  cursor-pointer" style="font-size:26px"></i>
                            </div>
                        </div>
                        <div class="ml-2">
                            <input v-model="form.verify" class="text-white d-block password" style="font-size: 16px;"
                                type="text" placeholder="请输入验证码">
                        </div>
                        <div @click="getCode" :class="getCodeSeond != '获取验证码' ? 'cursor-not' : ''"
                            class="px-3 ml-auto color-yellow cursor-pointer" style="height: 42px;line-height: 40px">
                            <div>
                                {{ getCodeSeond }}
                            </div>
                        </div>
                    </div>
                    <!-- 密码 - 注册 -->
                    <div class="d-flex border-dark-brown align-items-center mt-3">
                        <div class="bg-bg-2 login-right-input">
                            <div>
                                <i class="iconfont text-white icon-mima  cursor-pointer" style="font-size:26px"></i>
                            </div>
                        </div>
                        <div class="ml-2">
                            <input v-model="form.password" class="text-white d-block" style="font-size: 16px;width: 200px"
                                type="password" placeholder="请输入密码">
                        </div>
                    </div>

                    <!-- 用户登录和steam登录 -->
                    <div class="d-flex justify-content-center align-items-center">
                        <bottom-bg @click.native="changePasswordClick" :title="'确认修改'" class="mt-3"></bottom-bg>
                    </div>
                </div>
                <div @click="passwordShow = false" class="position-absolute" style="right: 30px;top: 20px">
                    <img src="@/assets/img/ADT/user/close.png" width="20" height="20" />
                </div>
            </div>
        </el-dialog>
        <!--  修改昵称 -->
        <el-dialog :show-close="false" custom-class="nickModel" :close-on-press-escape="false" :close-on-click-modal="false"
            :visible.sync="nameShow" width="500px">
            <div class="login d-flex py-5 px-3">
                <div class="login-right" style="width:100%">
                    <!-- 密码 - 注册 -->
                    <div class="d-flex border-dark-brown align-items-center mt-3">
                        <div class="ml-2">
                            <input v-model="Newname" class="text-white d-block input_main" type="text"
                                placeholder="请输入新的昵称">
                        </div>
                    </div>

                    <!-- 用户登录和steam登录 -->
                    <div class="d-flex justify-content-center align-items-center">
                        <bottom-bg @click.native="set_name" :title="'确认修改'" class="mt-3"></bottom-bg>
                    </div>
                </div>
                <div @click="nameShow = false" class="position-absolute" style="right: 20px;top:15px;cursor: pointer;">
                    <img src="@/assets/img/ADT/user/close.png" width="20" height="20" />
                </div>
            </div>
        </el-dialog>
        <!-- 更换头像 -->
        <el-dialog center custom-class="img_custom_info" :close-on-click-modal="false"
            :width="ua === 'phone' ? '90%' : '1200px'" :visible.sync="upImgVisible">
            <span slot="title" style="height:50px;line-height:50px">
                更换头像
            </span>
            <div class="img_custom_m" style="text-align: center;" :class="ua === 'phone' ? 'padding-lr-xs' : 'padding-lr'">
                <div @click="infoIndex = index" style="display: inline-block;"
                    :class="infoIndex === index ? 'border_img' : ''" class="margin-right-sm margin-bottom-sm"
                    v-for="(item, index) in imgUrls" :key="index">
                    <el-image :src="item"
                        :style="{ width: ua === 'phone' ? '70px' : '150px', height: ua === 'phone' ? '70px' : '150px' }"></el-image>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="upImgVisible = false">取 消</el-button>
                <el-button type="primary" @click="profileInfo">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 玩法介绍 -->
        <div class="" v-if="ReturnReason">
            <div style="width: 700px;left: calc(50% - 350px);top:15vh"
                class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
                <div class="text-white p-md-4 p-2" style="line-height: 2.0">
                    {{ back_message }}
                    <p>{{ updated_at }}</p>
                </div>
                <div @click="ReturnReason = false" class="position-absolute" style="right: 20px;top: 30px">
                    <div>
                        <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
                    </div>
                </div>
            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
    </div>
</template>

<script>
import bottomBg from "@/components/bottomBg";
// import TaskIn from '@/views/TaskIn/index'
import sUserAgent from '@/assets/js/common.js'
import { mapState } from 'vuex'
import $api from '@/api/user/index';
import Steam from './components/steam';
import Knapsack from './components/knapsack';
import Record from './components/record';
import Bill from './components/bill';
import Czrecord from './components/czrecord';
export default {
    name: "index",
    components: {
        bottomBg,
        // TaskIn
        Steam,
        Knapsack,
        Record,
        Bill,
        Czrecord
    },
    data() {
        return {
            idcard: ``,
            idname: ``,
            nameShow: false,
            Newname: '',
            upImgVisible: false,
            infoIndex: 0,
            storageStatus: 0,
            imgUrls: [],
            oppositelyUrls: [],
            // 新的
            activeName: 'back',
            // 充值记录
            tableData: [],
            // 取回历史
            StorageList: [],
            back_message: '',
            updated_at: '',
            // 显示个人资料侧栏
            user: {
                info: 1,
            },
            // end 新的

            passwordShow: false,
            getCodeSeond: '获取验证码',
            // 邀请码
            InvitationCode: '',
            // steam链接
            steam_url: '',
            // 新密码
            form: {
                mobile: '',
                password: '',
                // 验证码
                verify: ''
            },
            // 绑定邀请码
            invite_code: '',
            // 邀请人信息
            inviter: '',
            // 昵称
            name: '',
            // 头像裁切
            dialogVisible: false,
            // 防止重复提交
            loading: false,
            option: {
                canMove: true,
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: 'jpeg', // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 100, // 默认生成截图框宽度
                autoCropHeight: 100, // 默认生成截图框高度
                fixedBox: true, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [1, 1], // 截图框的宽高比例
                full: false, // 是否输出原图比例的截图
                canMoveBox: false, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: false, // 截图框是否被限制在图片里面
                infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            previews: {},

            total: 0,
            StorageListtotal: 0,
            pageSize: 0,
            StorageListpageSize: 0,
            old: '',
            records: [],// 收支明细
            recordstotal: 0,// 收支明细
            recordspageSize: 0,// 收支明细
            page: 1, //当前页
            ReturnReason: false,

            index: 0,
            steamStatus: false,
        }
    },
    computed: {
        ua() {
            return sUserAgent.sUserAgent()
        },
        recyleMoney() {
            let benr = 0
            let index = 0
            this.StorageList.forEach(item => {
                if (item.isShow == true) {
                    benr += (item.bean) * 1
                    index++
                }
            })
            return { benr: benr.toFixed(2), index }
        },
        money() {
            let benr = 0
            this.StorageList.forEach(item => {
                benr += (item.bean) * 1
            })
            return benr.toFixed(2)
        },
        ...mapState(['user_info', 'web_info'])
    },
    created() {

        let { name } = this.$route.query
        this.index = this.$route.query.index;
        if (name) {
            this.activeName = name
        }
        setTimeout(() => {
            this.name = this.user_info.name
            this.steam_url = this.user_info.steam_url
            console.log(this.user_info.steam_url ? true : false)
            this.steamStatus = this.user_info.steam_url ? true : false
            this.InvitationCode = this.user_info.invite_code
        }, 500)
    },
    watch: {
        upImgVisible(val) {
            if (val) {
                this.infoIndex = 0
                $api.getAvatarList().then(res => {
                    this.imgUrls = res.data.default_avatar
                    this.oppositelyUrls = res.data.post_avatar
                })
            }
        },
        activeName: {
            handler(val) {
                if (val === 'back') {
                    this.getStorage(1, 0)
                }
            },
            immediate: true
        },
    },
    mounted() {
        this.getRecharge_record()
        this.getUsetChange_records()
    },
    methods: {
        async openUrl(index) {
            if (this.ua == 'web') {
                this.$router.push(`/user/user?index=${index}`)
                this.index = index;
            } else {
                if (index == 1) {
                    this.$router.push(`/user/knapsack`)
                } else if (index == 3) {
                    this.$router.push(`/user/bill1`)
                } else if (index == 4) {
                    this.$router.push(`/user/record`)
                } else if (index == 5) {
                    this.$router.push(`/user/czrecord`)
                }
            }
        },
        async cardNameSubmit() {
            if (!this.idcard) {
                this.$notify.closeAll();
                this.$notify({
                    message: `请输入身份证号码`,
                    position: "top-left",
                });
                return
            }
            if (!this.idname) {
                this.$notify.closeAll();
                this.$notify({
                    message: `请输入真实姓名`,
                    position: "top-left",
                });
                return
            }
            let { code, message } = await $api.getVipVerify({ name: this.idname, idcard: this.idcard })
            if (code == 200) {
                this.$notify.closeAll();
                this.$notify({
                    message,
                    position: "top-left",
                });
                this.$store.dispatch("userInfo");
            }
        },
        async itemTwo(item) {
            if (item.back_message != '') {
                this.ReturnReason = true
                this.back_message = item.back_message
                this.updated_at = item.updated_at
                // let {code} = await $api.mesClear({
                //     record_id: item.id
                // })
                // if( code == 200 ){
                //     this.StorageList[index].back_message = ''
                // }
            }
        },
        // 点击全选
        allList() {
            this.StorageList.forEach(item => {
                item.isShow = !item.isShow
            })
        },
        laypage(val) {
            if (val) {
                this.page++
                this.getStorage(this.page)
                // window.scrollTo(0,0);
            } else if (this.page > 1) {
                this.page--
                this.getStorage(this.page)
                // window.scrollTo(0,0);
            }
        },
        //选择成功头像
        profileInfo() {
            $api.getSet_avatar({ path: this.oppositelyUrls[this.infoIndex] }).then(() => {
                this.upImgVisible = false
                this.$notify({
                    message: "头像更换成功",
                    type: 'success',
                    position: 'top-left'
                });
                this.$store.dispatch('userInfo')
            })
        },
        // 新的
        // 仓库跳转
        tabClickBack() {
            console.log('asdsada')
        },
        //退出登录
        loginOut() {
            this.$store.commit("LOGIN_IS_SHOW", true)
            this.$store.dispatch('removeInfo')
            this.$router.push('/')
        },
        async getStorage(page = 1) {
            try {
                let { data, code } = await $api.getStorage(page, this.storageStatus, 0)
                if (code == 500) return
                if (!data.data.length) return this.page--
                data.data.forEach(item => {
                    if (item.back_message != '') {
                        console.log(123)
                    }
                    item.isShow = false
                })
                this.StorageList = data.data
                // .forEach(item => {
                //     item.isNew = false;
                // })
            } catch (e) {
                console.log(e)
            }
        },
        async getStorage1(page = 1) {
            try {
                let { data, code } = await $api.getStorage(page, this.storageStatus, 0)
                if (code == 500) return
                data.data.forEach(item => {
                    if (item.back_message != '') {
                        console.log(123)
                    }
                    item.isShow = false
                })
                this.StorageList = data.data
            } catch (e) {
                console.log(e)
            }
        },
        // 充值记录
        async getRecharge_record(page = 1) {
            try {
                let { data, code } = await $api.getRecharge_record(page)
                if (code == 500) return
                this.tableData = data.data
                this.total = data.total
                this.pageSize = data.per_page
            } catch (e) {
                console.log(e)
                return
            }
        },
        // 收支明细
        async getUsetChange_records(page = 1) {
            try {
                let { data, code } = await $api.getUsetChange_records(page)
                if (code == 500) return
                this.records = data.data
                this.recordstotal = data.total
                this.recordspageSize = data.per_page
            } catch (e) {
                console.log(e)
                return
            }
        },
        currentChangeIncome(val) {
            this.getUsetChange_records(val)
            window.scrollTo(0, 0)
        },
        // end 新的
        // 翻页
        currentChange(val) {
            this.getStorage(val, 0)
            window.scrollTo(0, 0);
        },
        // 点击分解
        async recycle() {
            let arr = []
            try {
                this.StorageList.forEach(item => {
                    if (item.isShow) {
                        arr.push(item.id)
                    }
                })
                let { message, code } = await $api.getCash({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                }
                this.getStorage1(1, 0)
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },
        // 点击提取
        async extract() {
            let arr = []
            this.StorageList.forEach(item => {
                if (item.isShow) {
                    arr.push(item.id)
                }
            })
            try {
                let { message, code } = await $api.getExtract({ data: arr })
                if (code == 500) return
                if (code == 200) {
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                }
                this.getStorage1(1, 0)
                this.$store.dispatch('userInfo')
            } catch (e) {
                console.log(e)
            }

        },
        // 点击单独一个
        itemOne(item) {
            item.isShow = !item.isShow
        },
        // 展开弹窗
        changePassword() {
            this.passwordShow = true
        },
        changeName() {
            this.nameShow = true
            this.Newname = this.name
        },
        // 点击修改密码
        async changePasswordClick() {
            try {
                this.form.mobile = this.user_info.mobile
                let { message } = await $api.getpassword(this.form)
                this.$notify({
                    message: message, position: 'top-left'
                });
            } catch (e) {
                console.log(e)
                return
            }
        },
        // 更新邀请码
        async getSet_inviter() {
            return window.open('/chatlink.html', "_blank")
        },
        // 获取验证码 - ok
        async getCode() {
            let data = await $api.getSendSms({ mobile: this.user_info.mobile, is_use: 2 })
            if (data.code == 500) return
            this.$notify({
                title: '成功',
                message: data.message,
                type: 'success',
                position: 'top-left'
            });
            this.getCodeSeond = 60
            let time = setInterval(() => {
                this.getCodeSeond -= 1
                if (this.getCodeSeond == 0) {
                    this.getCodeSeond = '获取验证码'
                    clearInterval(time)
                }
            }, 1000)
        },
        // 更换头像
        async userSrc(response) {
            try {
                this.$common.blobToDataURL(response, async (res) => {
                    let { data } = await $api.getImageBase64({ data: res })
                    let { message, code } = await $api.getSet_avatar({ path: data.url })
                    if (code == 500) return
                    this.$notify({
                        title: '成功',
                        message: message,
                        type: 'success', position: 'top-left'
                    });
                    this.$store.dispatch('userInfo')
                    setTimeout(() => {
                        this.name = this.user_info.name
                        this.steam_url = this.user_info.steam_url
                        this.InvitationCode = this.user_info.invite_code
                    }, 500)
                })
            } catch (e) {
                console.log(e)
                return
            }
        },
        // 拷贝邀请码
        authCopy() {
            console.log(this.InvitationCode)
            this.$common.CopyText({ content: this.InvitationCode })
        },
        // setam 链接
        async getSteamLink() {
            try {
                let { message, code } = await $api.getSet_steam_url({ steam_url: this.steam_url })
                if (code == 500) return
                this.$notify({
                    title: '成功',
                    message: message,
                    type: 'success', position: 'top-left'
                });
            } catch (e) {
                console.log(e)
                return
            }
        },
        // 跟新昵称
        async set_name() {
            try {
                let { message, code } = await $api.getSet_name({ name: this.Newname })
                if (code == 500) return
                this.nameShow = false
                this.$notify({
                    title: '成功',
                    message: message,
                    type: 'success', position: 'top-left'
                });
                this.$store.dispatch('userInfo')
                setTimeout(() => {
                    this.name = this.user_info.name
                    this.steam_url = this.user_info.steam_url
                    this.InvitationCode = this.user_info.invite_code
                }, 500)
            } catch (e) {
                console.log(e)
            }
        },
        // 上传头像裁切
        async beforeUpload(file) {
            const isLt5M = file.size / 1024 / 1024 < 5
            const isImage = file.type.indexOf('image');
            if (!isLt5M) {
                this.$notify({
                    message: '图片大于5M,请压缩图片',
                });
                return false
            }
            if (isImage === -1) {
                this.$notify({
                    message: '请上传图片格式,jpg或者png', position: 'top-left'
                });
                return false
            }
            this.$common.blobToDataURL(file, async (res) => {
                this.$nextTick(() => {
                    this.option.img = res
                    this.previews.url = res
                    this.dialogVisible = true
                })
            })
            return false
        },
        // 点击裁剪，这一步是可以拿到处理后的地址
        finish() {
            this.$refs.cropper.getCropData(async (data) => {
                let { data: url } = await $api.getImageBase64({ data: data })
                let { message } = await $api.getSet_avatar({ path: url.url })
                this.$notify({
                    title: '成功',
                    message: message,
                    type: 'success', position: 'top-left'
                });
                this.$store.dispatch('userInfo')
                setTimeout(() => {
                    // 关闭裁切框
                    this.dialogVisible = false
                    // 防止重复提交
                    this.loading = false
                }, 1000)
            })
        },
        realTime(data) {
            this.previews = data
        },
    }
}
</script>


<style lang="scss">
.img_custom_info {
    margin: 0 auto !important;
    min-height: inherit;
    top: 10vh !important;

    .border_img {
        border: solid 2px #409EFF !important;
    }

    @media (max-width:600px) {
        top: 0vh !important;
        margin-top: 10vh !important;

        .margin-right-sm {
            margin-right: 5px;
            border: solid 2px transparent;
        }

        .margin-right-sm:nth-child(4n) {
            margin-right: 0px;
        }
    }
}

.login_egister1 {
    width: 462px;
    height: 290px;
    background: #17192C;
    border: 2px solid #124C5A;
    opacity: 0.98;
    border-radius: 8px;
    position: absolute;
    left: 50% !important;
    margin-left: -231px !important;
    margin-top: -165px !important;
    top: 50% !important;

    @media (max-width:600px) {
        left: 50% !important;
        margin-left: -250px !important;
        transform: scale(.7)
    }

    .common-btn-main {
        margin-top: 40px !important;
        width: 159px;
        height: 49px;
        border: solid 1px #1E7A91;
        background: #1E7A91;
        border-radius: 4px;
        padding: 0 0px;
        max-width: 159px;

        >div {
            margin-top: 5px;
            font-size: 16px;

            >div {
                width: 159px;
            }
        }
    }
}

.nickModel {
    width: 462px;
    height: 290px;
    background: #17192C;
    border: 2px solid #124C5A;
    opacity: 0.98;
    border-radius: 8px;
    position: absolute;
    left: 50% !important;
    margin-left: -231px !important;
    margin-top: -165px !important;
    top: 50% !important;

    @media (max-width:600px) {
        left: 50% !important;
        margin-left: -250px !important;
        transform: scale(.7);
    }

    .login-right {
        margin-top: 20px;

        .border-dark-brown {
            background-color: #000;
            height: 60px;

            >div {
                >input {
                    width: 400px;
                    font-size: 18px;
                    text-indent: 10px;
                    color: #ccc;
                }
            }
        }

        .common-btn-main {
            margin-top: 40px !important;
            width: 159px;
            height: 49px;
            border: solid 1px #1E7A91;
            background: #1E7A91;
            border-radius: 4px;
            padding: 0 0px;
            max-width: 159px;

            >div {
                margin-top: 5px;
                font-size: 16px;

                >div {
                    width: 159px;
                }
            }
        }
    }
}

.common_bg {
    background: url(../../assets/img/ADT/user/center.jpg);
    background-repeat: no-repeat;
    background-position: center center !important;
    background-size: 100% !important;

    @media (max-width:600px) {
        background: url(../../assets/img/ADT/user/phone-center.jpg);
        background-repeat: no-repeat;
        background-position: center 80px !important;
        background-size: 100% !important;
        // background: inherit !important;
        background-color: #15182C !important;
    }
}


.header.pc-none.is_verify {
    @media (max-width:600px) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.authenticated {
    // background-color: #22232e;
    background-color: rgba(25, 53, 74, 0.7764705882);
    border-radius: 6px;
    padding: 20px 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    @media (max-width:600px) {
        background-color: #18263B;
        margin-top: 15px;
        padding: 0px;
        flex-direction: column;
    }

    >h2 {
        margin-right: 24px;
        font-size: 18px;

        @media (max-width:600px) {
            width: 100%;
            text-align: left;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: #b4b8cd;
            font-size: 12px;
            margin: 10px 0;

            >a {
                color: #4CBE00 !important;
                border: solid 1px #4CBE00;
                padding: 6px 6px;
                background-color: #4cbe001b;
                transform: scale(.8);
                display: flex;
                align-items: center;
                transform-origin: left;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 300 !important;

                >img {
                    width: 14px;
                    margin-right: 2px;

                }
            }
        }
    }

    >.success {
        color: #4CBE00 !important;
        border: solid 1px #4CBE00;
        background-color: #4cbe001b;
        transform: scale(.8);
        transform-origin: left;
    }

    input {
        color: #fff !important;
        font-family: Monaco;
    }

    >.idcard {
        border-bottom: solid 1px #e5b37560;
        margin-right: 20px;
        height: 48px;
        display: flex;
        align-items: center;
        width: 300px;

        @media (max-width:600px) {
            width: 100%;
            border-bottom: solid 1px #e5b37560;
            margin-right: 0px;
            font-size: 14px;
            margin-bottom: 0px;
        }

        >img {
            margin-right: 10px;

            @media (max-width:600px) {
                width: 16px;
            }
        }
    }

    >.idname {
        border-bottom: solid 1px #e5b37560;
        height: 48px;
        display: flex;
        align-items: center;
        margin-right: 30px;
        width: 300px;

        @media (max-width:600px) {
            width: 100%;
            margin-right: 0px;
            border-bottom: solid 1px #e5b37560;
            margin-bottom: 20px;
            font-size: 14px;
        }

        >img {
            margin-right: 10px;

            @media (max-width:600px) {
                width: 16px;
            }
        }
    }

    .rz {
        background-color: #193F55;
        border: none;

    }

    >a {
        border: solid 1px #e5b375c3;
        border-radius: 4px;
        background-color: #393436;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ddd !important;
        font-size: 18px;
        line-height: 48px;
        width: 140px;
        cursor: pointer;

        @media (max-width:600px) {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 20px;
            font-size: 14px;
            height: 44px;
            line-height: 44px;

            >img {
                width: 16px;
            }
        }

        >img {
            margin-right: 6px;
        }
    }
}

#success {
    position: absolute;
    top: 40px;
    cursor: pointer;
    width: 291px;
    z-index: 9;
    text-align: center;
    font-size: 12px;
    color: #79BD0D;
    -webkit-animation: hue 5s infinite linear;
}

@keyframes hue {
    from {
        -webkit-filter: hue-rotate(0deg);
    }

    to {
        -webkit-filter: hue-rotate(360deg);
    }
}

.warpAllBox {
    >.common-btn-main {
        margin: 0 20px;
    }
}

@media (max-width:600px) {
    .warpAllBox {
        width: 100%;
        justify-content: space-between;

        >.common-btn-main {
            min-width: inherit;
        }
    }

    #success {
        width: 100%;
    }

    .common-btn-main {
        padding: 0 10px;
    }

    .col-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.user_new {
    width: 1720px;
    margin: 0 auto;
    padding-top: 20px;

    @media (max-width:600px) {
        padding: 0px 10px !important;
    }

    >div {
        display: flex;

        @media (max-width:600px) {
            overflow: hidden;
            display: inherit;
            height: 1000px;
        }

        .sideBar {
            position: relative;
            background-color: #19354ac6;
            width: 360px;
            margin-right: 20px;
            margin-bottom: 80px;
            border-radius: 8px;
            height: 1000px;

            @media (max-width:600px) {
                width: 100%;
                background-color: transparent;
                height: 870px;
            }

            >.sideBar-user {
                padding: 30px 20px;
                display: flex;
                align-items: center;

                @media (max-width:600px) {
                    padding: 10px 5px;
                    margin-bottom: 20px;
                }

                >.img {
                    margin-right: 14px;
                    position: relative;
                    width: 90px;
                    height: 90px;

                    >img {
                        width: 70px;
                        border-radius: 50%;
                        position: absolute;
                        z-index: 2;
                        left: 10px;
                        top: 10px;
                    }

                    >.border1 {
                        position: absolute;
                        left: 0px;
                        border: none;
                        top: 0px;
                        width: 90px;
                        height: 90px;
                        animation: warn1 5s linear infinite;
                    }

                    @keyframes warn1 {
                        0% {
                            transform: rotate(0deg);
                        }

                        25% {
                            transform: rotate(90deg);
                        }

                        50% {
                            transform: rotate(180deg);
                        }

                        75% {
                            transform: rotate(270deg);
                        }

                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }

                >.info {
                    >h2 {
                        font-weight: bold;
                        font-size: 18px;
                        margin-bottom: 6px;

                        @media (max-width:600px) {
                            color: #01B5CF;
                        }
                    }

                    >p {
                        font-family: Monaco;
                        color: #ccc;
                        margin-bottom: 10px;
                        font-weight: 300;
                        font-size: 14px;
                    }

                    >.press {
                        background-color: #D1D3DE;
                        border-radius: 20px;
                        width: 200px;
                        height: 8px;
                        position: relative;

                        >img {
                            height: 26px;
                            position: absolute;
                            top: -8px;
                            left: -4px;
                            z-index: 2;
                        }

                        >span {
                            position: absolute;
                            width: 30%;
                            height: 8px;
                            background-color: #C5A473;
                            border-radius: 20px;
                        }
                    }
                }
            }

            >.edit {
                margin-top: -5px;
                margin-bottom: 25px;
                display: flex;
                justify-content: center;

                @media (max-width:600px) {
                    justify-content: space-between;

                    >a:nth-child(2) {
                        margin-left: -40px;
                    }
                }

                >a:first-child {
                    border-right: solid 1px #515a65;
                }


                >a {
                    font-family: Monaco;
                    color: #ccc;
                    height: 16px;
                    cursor: pointer;
                    flex: 0 0 80px;
                    line-height: 16px;
                    text-align: center;

                    @media (max-width:600px) {
                        color: #999;
                    }
                }

                >a:last-child {
                    color: #01B5CF;
                    flex: 0 0 180px;
                }
            }

            >.recharge {
                background-color: #35c9ee1a;
                margin: 0 20px;
                border-radius: 4px;
                display: flex;
                padding: 16px 20px;
                margin-bottom: 15px;
                justify-content: space-between;

                @media (max-width:600px) {
                    margin: 0px;
                    margin-bottom: 8px;
                    background-color: #18263B;
                }

                >.left {
                    display: flex;
                    align-items: center;
                    color: #D9AF48;
                    font-family: Monaco;

                    @media (max-width:600px) {
                        font-size: 18px;
                    }

                    >img {
                        margin-right: 4px;

                        @media (max-width:600px) {
                            margin-right: 10px;
                        }
                    }
                }

                >.right {
                    cursor: pointer;
                    color: #E8BA46;
                    background-image: url(../../assets/img/ADT/user/recharge.png);
                    background-size: 100px 40px;
                    width: 100px;
                    text-align: center;
                    line-height: 38px;
                    height: 40px;

                    @media (max-width:600px) {
                        background-size: 100px 35px;
                        width: 100px;
                        height: 35px;
                        font-size: 14px;
                        line-height: 34px;
                    }
                }
            }

            >.beibao {
                cursor: pointer;

                >div {
                    color: #01B5CF !important;
                    font-weight: bold;
                    font-size: 18px;

                    @media (max-width:600px) {
                        font-size: 16px !important;
                    }

                    >img {
                        height: 30px;
                        width: auto;
                        margin-right: 8px !important;

                        @media (max-width:600px) {
                            height: 24px;
                        }
                    }
                }

                >.right {
                    font-family: Monaco;
                    background-image: none;
                    width: auto;
                    color: #ccc !important;
                    font-weight: 300;
                    font-size: 16px;

                    @media (max-width:600px) {
                        font-size: 14px !important;
                    }

                    >img {
                        width: auto;
                        height: 10px;
                        margin-left: 4px;
                    }
                }
            }

            >.menu {
                display: flex;
                flex-direction: column;
                margin-left: 60px;

                @media (max-width:600px) {
                    margin-left: 0px;
                }

                >.steam1 {
                    @media (max-width:600px) {
                        display: none;
                    }
                }

                >a {
                    display: flex;
                    align-items: center;
                    height: 80px;
                    cursor: pointer;
                    font-family: Monaco;
                    font-size: 18px;

                    @media (max-width:600px) {
                        border-bottom: solid 1px #112B36;
                        font-size: 16px;
                        text-indent: 20px;
                        height: 65px;
                        color: #ddd;
                        justify-content: space-between;

                        @media (max-width:600px) {
                            >.no1 {
                                display: none;
                            }

                            >img {
                                width: 8px !important;
                                height: 12px !important;
                                opacity: .4;
                            }
                        }
                    }

                    >img {
                        height: 24px;
                        width: 24px;
                        margin-right: 20px;

                    }
                }

                >.active {
                    color: #03B5CF;
                    font-weight: bold;
                }
            }

            >.bottom {
                background-color: #2A3A4F;
                height: 60px;
                position: absolute;
                bottom: 0px;
                border-radius: 4px;
                text-align: center;
                left: 50%;
                margin-left: -50%;
                width: 100%;
                line-height: 60px;
                cursor: pointer;
                @media (min-width:600px) {
                    top: 71vh;
                }

                @media (max-width:600px) {
                    width: 95%;
                    margin-left: -47.5%;
                    height: 50px;
                    line-height: 50px;
                    
                }
            }
        }

        .sideContent {
            width: 1400px;
            border-radius: 8px;
        }
    }

    .header.pc-none {
        // margin-top: 60px;
        background-color: #18263B;
        border-radius: 6px;
        margin-bottom: 8px;
    }

    .head-top-user {
        margin-top: -50px;
        margin-bottom: 20px;
        position: relative;

        .head_avatar {
            margin-right: 20px;

            img {
                border: 2px solid #FFC67B;
                width: 80px;
                border-radius: 50%;
            }
        }

        .margin-top {
            margin-top: 10px;
        }

        .head_avatar_vip_name {
            font-weight: bold;
            color: #FFC67B;
            margin-bottom: 25px;
        }

        .head-bottom {
            color: rgba(255, 255, 255, 0.6);
            text-align: center;
            font-size: 14px;
            margin-top: 20px;

            .info_name {
                width: 100px;
            }

            .info_mima {
                width: 100px;
            }

            .info_code {
                width: calc(100% - 200px);

                .text-huang {
                    color: #FFC67B;
                    margin-left: 4px;
                }
            }
        }
    }

    .content {
        &-hover {
            & .active {
                position: relative;

                &::before {
                    content: url("../../assets/img/hover.png");
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    z-index: 9;
                }
            }
        }
    }

    @media (max-width: 1300px) {
        width: 100%;
        margin: 0;
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 10px;
    }

    .header {
        width: 100%;
        height: 163px;
        padding: 20px;
        position: relative;
        display: flex;
        background: #22232E;
        justify-content: space-between;
        align-items: flex-end;

        @media (max-width: 1300px) {
            height: auto;
            display: block;
        }

        .header-btn.return {
            display: flex;
            align-items: center;
            padding-left: 28px;
            text-decoration: none;
            position: absolute;
            left: 0;
            top: 0;
        }

        .head_avatar_img {
            position: relative;

            .head {
                border-radius: 50%;

                img {
                    border-radius: 50%;
                }
            }

            .info_head {
                display: block !important;
                position: absolute;
                left: 160px;
                top: 0;
            }
        }

        .header-btn {
            width: 192px;
            height: 40px;
            background-color: #767676;
            border-radius: 6px;

            img {
                width: 6px;
                margin-right: 39px;
            }

            span {
                font-size: 12px;
                color: #fff;
                line-height: 1;
            }
        }

        .header-btn.exit {
            position: absolute;
            top: -60px;
            right: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .module.both {
            border-left: 1px solid #111114;
            padding-left: 40px;

            @media (max-width:1300px) {
                border-top: 1px solid #111114;
                border-left: 0;
                padding-left: 0;
                margin-bottom: 10px;
            }

            .title {
                font-size: 12px;
                line-height: 1;
                font-weight: 700;
                color: #b4b8cd;
            }

            .info {
                margin-top: 21px;
                width: 100%;
                height: 30px;
                border-radius: 8px;
                position: relative;

                .price {
                    height: 100%;
                    padding-left: 18px;
                    display: flex;
                    align-items: center;
                }

                .cp.cffffff {
                    color: #fff;

                    .cp-img {
                        flex-shrink: 0;
                        height: 100%;
                        -o-object-fit: contain;
                        object-fit: contain;
                    }
                }

                .cp {
                    display: flex;
                    align-items: center;
                    max-width: 100%;
                }

                input {
                    background-color: #1B1C25;
                    border: none;
                    width: calc(100% - 118px);
                    padding: 0 17px;
                    height: 100%;
                    font-size: 12px;
                    color: #fff;

                    @media (max-width:1300px) {
                        width: 100%;
                    }
                }

                .get {
                    position: absolute;
                    right: -1px;
                    top: -1px;
                    width: 118px;
                    height: 44px;
                    background-color: #1f1e27;
                    border-radius: 8px;
                    border: 1px solid #6a6d81;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;

                    span {
                        padding-left: 7px;
                        font-size: 12px;
                        font-weight: 700;
                        color: #fff;
                    }
                }
            }

            .btn.set {
                margin-left: 20px;

                span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1;
                    padding-left: 12px;
                }
            }

            .btn.acquire {
                width: 200px;

                span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1;
                    padding-left: 12px;
                }
            }

            .btn {
                margin-top: 16px;
                width: 100%;
                color: #fff;
                background: #212741;
                border: 1px solid #5085C7;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 8px;
            }

            .btn.recharge {
                background: #393436;
                background-blend-mode: normal, normal;
                border: 1px solid #E5B375;

                span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1;
                    padding-left: 12px;
                }
            }
        }

        .module {
            width: 520px;

            @media (max-width:1300px) {
                width: 100%;
                padding-top: 10px;
            }
        }

        .info {
            .info_head_btn {
                height: 30px;
                line-height: 30px;
                color: rgba(255, 255, 255, 0.605);
                text-align: center;

                .info_name {
                    width: 200px;
                    border-right: 1px solid rgba(255, 255, 255, 0.605);
                }

                .info_mima {
                    width: 200px;
                    border-right: 1px solid rgba(255, 255, 255, 0.605);
                }

                .info_code {
                    width: calc(100% - 200px);
                }

                .text-huang {
                    color: #FFC67B;
                }
            }
        }

        .module.mid {
            flex-shrink: 0;
            height: 180px;
            margin: 0 30px;
            position: relative;

            @media (max-width:1300px) {
                height: 300px;
                margin: 0;
            }

            .head {
                width: 124px;
                height: 124px;
                border-radius: 50%;
                border: 2px solid #1E7A91;
                position: absolute;
                top: -30px;
                left: 0;
                z-index: 1;
                overflow: hidden;

                @media (max-width:1300px) {
                    width: 88px;
                    height: 88px;
                    left: calc(50% - 44px);
                    top: 10px;
                    border-radius: 14px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                .upload {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition-duration: .3s;
                    -webkit-transition-duration: .3s;

                    .el-upload {
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, .6);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .head:hover {
                .upload {
                    opacity: 1;
                }
            }

            .info {
                width: 100%;
                position: absolute;
                bottom: 20px;

                &_head {
                    display: flex;
                    align-items: center;
                }

                .info-type {
                    flex: 1;
                    min-width: 0;
                    display: flex;
                    align-items: center;
                    height: 20px;
                }
            }

            .margin-top {
                margin-top: 10px;
            }

            .info-nickname {
                max-width: 144px;
                font-size: 20px;
                font-weight: 700;
                flex-shrink: 0;
                margin-bottom: 30px;
                color: #FFC67B;
                border: none;
            }

            .info-pwd {
                flex: 1;
                min-width: 0;
                font-size: 12px;
                color: #e59d5d;
                line-height: 1;
                border: none;
                text-decoration: underline;
                text-align: right;
            }
        }
    }

    .csgo-table {
        margin-top: 15px;

        @media (max-width:1300px) {
            margin-top: 20px;
        }
    }

    .content-nav {
        height: 80px;
        background-color: #111114;
        border-bottom: 1px solid #21222c;
        border-radius: 12px 12px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 25px;

        @media (max-width:1300px) {
            height: 60px;
            overflow-x: auto;
        }

        &::-webkit-scrollbar {
            height: 2px;
        }

        .menu {
            flex-shrink: 0;
            height: 100%;
            padding: 5px;
            display: flex;

            .menu-li.active {
                // background-image: linear-gradient(90deg,#312d35,#212129),linear-gradient(#e59d5d,#e59d5d);
                // background-blend-mode: normal,normal;
                border-bottom: 2px solid #e59d5d;

                .title {
                    color: #e59d5d;
                }
            }

            .menu-li {
                margin-right: 5px;
                height: 100%;
                min-width: 130px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: #171720;
                overflow: hidden;

                @media (max-width:1300px) {
                    min-width: 100px;
                }

                img {
                    margin-right: 10px;
                    -o-object-fit: contain;
                    object-fit: contain;
                }

                .title {
                    font-size: 12px;
                    line-height: 1;
                    font-weight: 700;
                    color: #cecfd6;
                }
            }
        }

        .head-right {
            display: flex;
            align-items: center;

            .help {
                margin-left: 40px;
                text-decoration: none;
                flex-shrink: 0;
                height: 40px;
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 50px;
                    height: 40px;
                    -o-object-fit: contain;
                    object-fit: contain;
                    margin-right: 13px;
                }

                span {
                    line-height: 1;
                    font-size: 14px;
                    color: #6a6d81;
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.steam {
    padding: 20px;
    background-color: #18263B;
    border-radius: 6px;

    >h2 {
        font-size: 14px;
        font-family: Monaco;
        margin-bottom: 15px;
    }

    >div {

        >input {
            font-family: Monaco;
            color: #fff;
            width: 100%;
            font-size: 14px;
        }
    }

    >.stBox {
        background-color: #15182C;
        width: 100%;
        line-height: 50px;
        height: 50px;
        text-indent: 15px;
        margin-bottom: 10px;
    }

    >p {
        font-size: 12px;
        font-family: Monaco;
        color: #ccc;
        font-weight: 300;
        margin-bottom: 20px;
    }

    >.p1 {
        margin-bottom: 5px;
    }

    >.btns {
        display: flex;

        >a {
            background-color: #193F55;
            display: flex;
            align-items: center;
            margin-right: 10px;
            padding: 14px 30px;
            border-radius: 4px;
            justify-content: center;
            font-size: 14px;
            cursor: pointer;
            color: #ddd;

            @media (max-width:600px) {
                margin-right: 0px;
            }

            >img {
                width: 20px;
                margin-right: 4px;
            }
        }

        >a:first-child {
            flex: 1;

            @media (max-width:600px) {
                margin-right: 10px;
            }
        }

        >a:last-child {
            flex: 2;
        }
    }
}
</style>