import {request} from "../index";


function getTask(){
  return request({
    url: `/task`,
    method:'get'
  })
}

function task_receive(data){
  return request({
    url: `/task/receive`,
    method:'post',
    data
  })
}

function getTask_sign_up(data){
  return request({
    url: `/task/sign_up`,
    method:'post',
    data
  })
}



export default {
  getTask,task_receive,getTask_sign_up
}
