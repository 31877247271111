import {request} from "../index";


function getHistory(uid){
  return request({
    url: `/box/history?uid=${uid}`,
    method:'get'
  })
}

export default {
  getHistory
}
