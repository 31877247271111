
import {request} from "../index";

function get_box_hot() {
    return request({
        url: `/box/hot`,
        method:'get',
    })
}


function get_storage(page) {
    return request({
        url: `/storage?page=${page}`,
        method:'get',
    })
}


function get_synthesis_list(page) {
    return request({
        url: `/synthesis/list?page=${page}`,
        method:'get',
    })
}


function get_synthesis_compound(data) {
    return request({
        url: `/synthesis`,
        method:'post',
        data
    })
}

function getConsume(data) {
    return request({
        url: `/welfare_center/getConsume`,
        method: 'post',
        data
    })
}
function recoList() {
    return request({
        url: `/welfare_center/getConsume/recoList`,
        method: 'get'
    })
}
function supplyList() {
    return request({
        url: `/welfare_center/supply/index`,
        method: 'get'
    })
}
function drawRecharge(data) {
    return request({
        url: `/welfare_center/supply/post`,
        method: 'post',
        data
    })
}
function consumePost(data) {
    return request({
        url: `/welfare_center/consume/post`,
        method: 'post',
        data
    })
}
function activityList() {
    return request({
        url: `/welfare_center/activity/list`,
        method: 'get'
    })
}
function signIn(data) {
    return request({
        url: `/task/receive`,
        method: 'post',
        data
    })
}
function signUpLoginS(data) {
    return request({
        url: `/task/receive/sign_up_login`,
        method: 'post',
        data
    })
}
function signUp(data) {
    return request({
        url: `/task/sign_up`,
        method: 'post',
        data
    })
}

function signUpLogin(data) {
    return request({
        url: `/task/sign_up_login`,
        method: 'post',
        data
    })
}
function taskReceive() {
    return request({
        url: `/task`,
        method: 'get',
    })
}
function remindList(data) {
    return request({
        url: `/welfare_center/getUserTaskReward`,
        method: 'post',
        data
    })
}

function UserRed() {
    return request({
        url: `/red`,
        method: 'get',
    })
}

function UserRedOpen(data) {
    return request({
        url: `/red/open`,
        method: 'post',
        data
    })
}



export default {
    get_box_hot,
    get_storage,
    get_synthesis_list,
    get_synthesis_compound,
    remindList,
    signIn,
    taskReceive,
    signUpLogin,
    signUpLoginS,
    signUp,
    activityList,
    consumePost,
    drawRecharge,
    getConsume,
    recoList,
    supplyList,
    UserRed,
    UserRedOpen,
}
