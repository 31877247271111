<template>
    <div class="d-flex">
        <div class="content text-center" style="width: 100%;margin: 0 auto;">
            <img style="margin: 0 auto;max-width:1400px;"  width="100%" src="@/static/media/MissPartners.jpg?v=1" alt="">
        </div>

    </div>


</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                ios:false
            }
        },
        mounted() {
                this.isIos();
        },
        methods:{
            appStartDown(type) {

                if(type==1){
                    window.location = 'https://www.mmcsgo.com/upcsgo.apk?v=1';
                }else if(type==2){
                    window.location = 'https://www.mmcsgo.com/upcsgo.mobileconfig';
                }

               /* var browser=this.browser();
                if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
                    console.log('这是苹果')
                } else {
                }*/

            },
            isIos(){
                var browser=this.browser()
                if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
                    this.ios=true;
                }

            },
            browser(){
                var browser = {
                    versions: function () {
                        var u = navigator.userAgent;
                        return { //移动终端浏览器版本信息
                            trident: u.indexOf('Trident') > -1, //IE内核
                            presto: u.indexOf('Presto') > -1, //opera内核
                            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                            mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
                            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
                            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
                            iPad: u.indexOf('iPad') > -1, //是否iPad
                            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
                        };
                    }(),
                    language: (navigator.browserLanguage || navigator.language).toLowerCase()
                }

                return browser;
            }

        }

    }
</script>

<style scoped lang="scss" >
    @media screen and (max-width:680px){
        .web {
            display: none !important;;
        }
        .phone{
            display: flex !important;
        }
    }
    /*PC*/
    @media screen and (min-width:700px){
        .web {
            display: flex !important;
        }
        .phone{
            display: none !important;;
        }
    }



    .count_body_butt{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        position: relative;
    }
    .count_body_butt_box{
        margin:2.08rem auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }


    .count_body_butt_an{
        width: 14rem;
        height: 4rem;
        border-radius: 1rem;
        border: 0.2rem solid orange;
        background-size: contain;
        margin-right: 3.7rem;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .count_body_butt_ios{
        width: 14rem;
        height: 4rem;
        border-radius: 1rem;
        border: 0.2rem solid orange;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
    }

    .count_body_butt_phone_ios{
        width: 10rem;
        height: 3rem;
        border-radius: 0.5rem;
       // border: 0.2rem solid orange;
        background: orange;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: not-allowed
    }


    .count_body_font_butt{
        position: relative;
        bottom: -0.13rem;
        margin-top: 0.76rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 1.85333rem;
    }
    .download{
        border-radius: 0.5rem;
       // border: 0.2rem solid orange;
        background: orange;
        width: 9rem;
        height: 3rem;
        background-size: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


</style>