<template>
<!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class=" rounded overflow-hidden padding-tb-xs bg-size-100" :style="'width:100%;padding-top:0;'" :class="'all-bg' +resData.level">
        <div class="bg-size  bg-repeat position-relative d-flex flex-wrap align-items-center" >
            <!-- header -->
            <div class="px-2 act_wean_explain_name text-center text-overflow-1 w-100 d-flex justify-content-between" :class="'lv' +resData.level+'bg'" style="line-height: 47px;font-size: 16px;height: 47px;">
                <div style="color: #FFB526;" class="font-weight-bold flex"><money /> {{resData.skins.bean}}</div>
                <div class="text-overflow-1" style="color:#fff">{{resData.odds_percent}}</div>
            </div>
            <div class="d-md-flex spear_img d-block justify-content-around align-items-center position-relative">
                <img :src="resData.skins.cover" alt="">
            </div>
            
            <div class="act_wean_explain px-2">
                <div class="text-overflow-1" :class="'lv' +resData.level">{{resData.skins.name}}</div>
                <div style="font-size: 12px;" class="margin-top-xs">{{resData.skins.dura_alias || '暂无'}}</div>
                <div :class="'lv' +resData.level+'bg'" class="act_wean_explain_line"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            // 传输武器整体宽度
            width:{
                type:String,
                default:"14%"
            },
            // 武器显示的高度，宽度和整体宽度是一样的
            heightWeapon:{
                type:String,
                default:"150px"
            },
            // 传输背景 style 对象形式传输
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            // 武器对应的宽度 高度 带单位 style 对象形式传输
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
        //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
    .cover-content{
    }
    .cover-cover{
      & img{
        transition: all .5s;
      }
      &:hover img{
        transform: translateY(-20px)
      }
    }
    .weapon{
        &-name{
             padding: 10px 0;
         }
    }
    .spear_img{
        height: 135px;
        width: 100%;
        img{
            height:80%;
            @media (max-width:1300px) {
                height:90%;
            }
        }
        @media (max-width:1300px) {
            text-align: center;
            height: 80px;
        }
    }
    .act_wean_explain{
        width: 100%;
        text-align: center;
        .text-overflow-1{
            @media (max-width:1300px) {
                line-height: 1.6;
                font-size: 14px;
            }
        }

        // .act_wean_explain_line {
        //     position: relative;
        //     top: 10px;
        //     width: 50%;
        //     height: 3px;
        //     margin: 0 auto;
        // }
    }
    .act_wean_explain_name{
        .text-overflow-1{
            @media (max-width:1300px) {
                font-size: 12px;
            }
        }
    }
    
</style>
