<template>
  <div id="app">
    <div v-if="wxpage">
      <img
        width="100%"
        height="100%"
        src="@/assets/img/index/wxpage.png"
        alt=""
      />
    </div>
    <router-view v-else></router-view>
    <link
      rel="stylesheet"
      href="https://at.alicdn.com/t/c/font_3860251_oyz1rurjwtc.css"
    />
    <audio
      preload="auto"
      id="mysound"
      :src="require('@/assets/music/click.mp3')"
    ></audio>
    <audio
      preload="auto"
      id="mouseOverBox"
      :src="require('@/assets/music/mouseOverBox.mp3')"
    ></audio>


    <!-- 1 -->
    <audio
      preload="auto"
      id="open"
      :src="require('@/assets/music/open.mp3')"
    ></audio>
     <!-- 2 -->
     <audio
      preload="auto"
      id="openLeftBox"
      :src="require('@/assets/music/openLeftBox.mp3')"
    ></audio>

    <!-- 3 -->
    <audio
      preload="auto"
      id="openTopAwardList"
      :src="require('@/assets/music/openTopAwardList.mp3')"
    ></audio>

    <!-- 4 -->
    <audio
      preload="auto"
      id="launchMp3"
      :src="require('@/assets/music/launch1.mp3')"
    ></audio>
   <!-- 5 -->
   <audio
      preload="auto"
      id="succeed"
      :src="require('@/assets/music/succeed.mp3')"
    ></audio>
   
    <audio
      preload="auto"
      id="error"
      :src="require('@/assets/music/error.mp3')"
    ></audio>

 
    <audio
      preload="auto"
      id="luckey"
      :src="require('@/assets/music/bgm_zhuanbei.mp3')"
    ></audio>
  
    <audio
      preload="auto"
      id="birthday"
      :src="require('@/assets/music/birthday.mp3')"
    ></audio>

    <!-- <audio
      preload="auto"
      id="openStart"
      :src="require('@/assets/music/openStart.mp3')"
    ></audio> -->

    <!-- 新加 二版本声音 -->
    <audio
      ref="apple"
      loop="loop"
      preload="auto"
      id="openStart"
      :src="require('@/assets/music/new-open-audio.mp3')"
    ></audio>
    

    <audio preload="auto"  id="baozha" :src="require('@/assets/music/baozha.mp3')"></audio>
    <audio preload="auto"  id="guoguan" :src="require('@/assets/music/guoguan.mp3')"></audio>
  </div>
</template>
<script>
import sUserAgent from "@/assets/js/common.js";
import "@/utils/dice/dice"


export default {
  data() {
    return {
      wxpage: false,
      loadCount: 0,
    };
  },
  mounted() {

    this.$refs.apple.load();
    this.$refs.apple.pause();
    


    let domain_url = window.location.host;
    console.log("domain_url-------------",domain_url);
    if (domain_url == "upcsgo.com" || domain_url == "www.upcsgo.com") {
      this.wxpage = false;
    } else {
      window.sessionStorage.setItem("ua", sUserAgent.sUserAgent());
      let ua = window.sessionStorage.getItem("ua");
      if (ua == "phone") {
        console.log("手机端跳转地址");
      }
      if (this.isWeiXin()) {
        this.wxpage = true;
      }
    }
  },
  beforeCreate() {
    //执行下载所有图片
    let imgs = ["static/media/bg_treasure_box2.webp"];

    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {
        this.loadCount++;
      };
      image.src = img;
    }
  },
  methods: {
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
  sockets: {
    connect: function () {},
    customEmit: function () {},
  },
};
</script>

<style lang="scss">
/* 使用到的全局css */
@import "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/common.css";
@import "assets/css/element.css";
@import "assets/css/main.css";
@import "assets/iconfont/iconfont.css";

#app {
  color: #ffffff;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
