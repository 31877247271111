import $api from './pk/index.js'
import Vue from "vue";
import ReconnectingWebSocket from "reconnecting-websocket";
import $common from "@/assets/js/common.js";

let apiurl = ''
if(process.env.NODE_ENV === 'development') {
    apiurl = 'wss://api.upcsgo.com/wss';
    // apiurl = 'ws://csapi.fcsgo.com/wss';
    // apiurl = 'wss://api.upcsgo.com/wss'; // 这是本地之地
    // apiurl = 'ws://test_api.skcsgo.com/wss'; //
}else {
    apiurl = 'wss://api.upcsgo.com/wss'//这是线上地址 打包后的
    // apiurl = 'ws://csapi.fcsgo.com/wss';
}
let ws  = new ReconnectingWebSocket(apiurl);
Vue.prototype.$ws = ws
ws.addEventListener('open',()=>{
    ws.send('你好')
})
ws.addEventListener('message', function (event) {
    let data = event.data;
    let {Data, MsgType} = JSON.parse(data)
    if (MsgType == 'connect') {
        let md5jiamiObj = $common.md5jiami(Data.client_id)
        let websocket = {
            MsgType: 'auth',
            token: md5jiamiObj.md5Token,
            string: md5jiamiObj.randomStr,
            time: md5jiamiObj.tiem,
            app_id: '92skins.cn'
        }
        ws.send(JSON.stringify(websocket))
        $api.getArenaJoinChannel({client_id:Data.client_id})
    }
});
setInterval(()=>{
    ws.send('y')
},10000)
