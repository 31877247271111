<template>
  <!-- 侧边栏 -->
  <div class="position-fixed silde" :style="`right:${silde}px`">

    <div class="jss163" @click="hideTapShow()">
      <img v-if="silde < 0" src="@/assets/img/ADT/home/shou.png" alt="">
      <img v-else src="@/assets/img/ADT/home/open.png" alt="">
      <!-- <i :class="`el-icon-arrow-${silde<0?'left':'right'}`"></i> -->
    </div>
    <div class="position-absolute silde-off d-md-none d-none" @click="sildeOff">
      <span :class="`${silde === 0 ? 'el-icon-arrow-right' : 'el-icon-arrow-left'}`"></span>
    </div>
    <div class="silde-box">
      <div @click.stop="$emit('openActivity')"
           class="welfare_img position-relative cursor-pointer silde-serve silde-W d-md-flex d-flex flex-column justify-content-center align-items-center">
        <img width="100%" style="margin-bottom: 5px" src="@/assets/img/activity-icon.png" alt=""/>
        <div style="font-size: 12px">活动</div>
        <div style="font-size: 12px">大厅</div>
      </div>

      <div @click="$router.push({ path: '/FoutOfTheFree' })"
           class="welfare_img position-relative cursor-pointer silde-serve silde-W d-md-flex d-flex flex-column justify-content-center align-items-center">
        <img width="100%" style="margin-bottom: 5px" src="@/assets/img/ADT/home/free.png" alt=""/>
        <div style="font-size: 12px">免费</div>
        <div style="font-size: 12px">宝箱</div>
      </div>

      <div @click="$router.push('/user/recharge')"
           class="position-relative cursor-pointer silde-serve silde-W d-md-flex d-md-flex flex-column justify-content-center align-items-center"
           :style="`margin-top:${sUserAgent == 'web' ? '0px' : '10px'}`">
        <img width="50%;" style="margin-bottom: 5px" src="@/assets/img/ADT/home/up.png" alt=""/>
        <div style="font-size: 12px">充值</div>
      </div>

      <!-- <div
        @click="$router.push('/traditional')"
        class="position-relative cursor-pointer silde-serve silde-W d-md-flex d-md-flex flex-column justify-content-center align-items-center"
        :style="`margin-top:${sUserAgent=='web'?'0px':'10px'}`">
        <img width="90%" :src="require('@/assets/love/xinxiao.webp')" alt="" />
        <div style="font-size: 12px">在线客服</div>
      </div> -->
      <div @click="showCDK"
           class="position-relative cursor-pointer silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
        <img width="50%;" style="margin-bottom: 5px" :src="require('@/assets/img/ADT/home/cdk-icon.png')" alt=""/>
        <div style="font-size: 12px">CDK</div>
      </div>
      <div v-if="!filterDomain()" @click="showQQImg" @mouseleave="isQQShow = false" @mouseenter="isQQShow = true"
           class="QQ-container position-relative cursor-pointer silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
        <img width="50%;" style="margin-bottom: 5px" :src="require('@/assets/img/ADT/home/qq-icon.png')" alt=""/>
        <div style="font-size: 12px">QQ群</div>
        <!-- QQ -->
        <div v-if="isQQShow" class="QQ-pop-up">
          <p class="p1">QQ群</p>
          <p class="p2">619342798</p>
          <img src="../assets/img/ADT/common/QQ.png" alt="">
          <p class="p3">其它相关信息</p>
        </div>
      </div>

      <div v-if="isShowWechat"
           @mouseleave="isWechatShow = false" @mouseenter="isWechatShow = true"
           class="position-relative cursor-pointer silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
        <svg t="1697186339058" class="wechat_svg" viewBox="0 0 1024 1024" version="1.1"
             xmlns="http://www.w3.org/2000/svg" p-id="3874" width="50%">
          <path
              d="M175.181658 915.640212c-13.845973 0-25.885949-11.437978-25.885949-25.885949l0-74.647854c0-148.69371 89.697825-281.133451 228.157554-336.517343l18.661964-7.825985-16.253968-12.641975c-51.771899-40.93592-81.87184-101.737801-81.87184-167.355673 0-117.389771 95.717813-212.505585 212.505585-212.505585 117.389771 0 212.505585 95.717813 212.505585 212.505585 0 65.617872-29.497942 126.419753-81.87184 167.355673l-16.253968 12.641975 18.661964 7.825985c139.061728 55.383892 228.157554 187.221634 228.157554 336.517343l0 74.647854c0 13.845973-11.437978 25.885949-25.885949 25.885949L175.181658 915.640212 175.181658 915.640212zM512.300999 503.873016c-171.569665 0-311.233392 139.061728-311.233392 311.233392l0 48.761905 621.864785 0 0-48.761905C822.932393 643.536743 683.870664 503.873016 512.300999 503.873016L512.300999 503.873016zM511.699001 130.031746c-89.095826 0-161.335685 72.239859-161.335685 161.335685s72.239859 161.335685 161.335685 161.335685c89.095826 0 161.335685-72.239859 161.335685-161.335685S600.794827 130.031746 511.699001 130.031746L511.699001 130.031746z"
              p-id="3875"></path>
          <path
              d="M25.885949 803.066432c-13.845973 0-25.885949-11.437978-25.885949-25.885949l0-57.791887c0-107.75779 59.597884-205.883598 154.713698-255.247501l13.243974-6.621987-10.23398-11.437978c-29.497942-32.507937-45.751911-74.045855-45.751911-117.389771 0-81.269841 55.383892-151.703704 134.245738-170.365667 1.805996-0.601999 4.213992-0.601999 6.019988-0.601999 12.039976 0 22.273956 7.825985 25.283951 19.865961 3.009994 13.845973-5.417989 27.691946-18.661964 30.70194-55.985891 13.845973-94.513815 63.209877-94.513815 121.001764 0 48.761905 28.895944 93.309818 73.443857 113.175779 3.009994 1.203998 5.417989 3.009994 7.825985 5.417989 0.601999 0.601999 0.601999 0.601999 1.203998 1.203998 1.203998 1.203998 1.805996 3.009994 3.009994 5.417989l0.601999 0.601999c0.601999 1.203998 0.601999 1.805996 1.203998 3.009994 0 0.601999 0.601999 1.203998 0.601999 1.805996 0 0.601999 0 1.805996 0 3.009994l0 0.601999c0.601999 2.407995 0.601999 4.213992 0.601999 6.019988 0 0.601999-0.601999 1.203998-0.601999 1.805996l-0.601999 1.203998c-0.601999 1.203998-0.601999 1.805996-0.601999 3.009994 0 0.601999 0 1.203998-0.601999 1.805996-3.611993 7.825985-10.835979 13.845973-19.865961 15.049971l-0.601999 0-0.601999 0c-104.747795 26.487948-178.191652 120.399765-178.191652 228.759553l0 57.791887C51.1699 792.230453 39.731922 803.066432 25.885949 803.066432L25.885949 803.066432z"
              p-id="3876"></path>
          <path
              d="M998.114051 803.066432c-13.845973 0-25.885949-11.437978-25.885949-25.885949l0-57.791887c0-108.359788-73.443857-202.271605-178.191652-228.759553l-0.601999 0-0.601999 0c-9.029982-1.203998-16.253968-6.621987-19.865961-15.049971 0-0.601999 0-1.203998-0.601999-1.805996 0-1.203998-0.601999-1.805996-0.601999-3.009994l-0.601999-1.203998c0-0.601999-0.601999-1.203998-0.601999-1.805996 0-1.805996 0-3.009994 0.601999-5.417989l0-0.601999c0-0.601999 0-1.805996 0-3.009994 0.601999-0.601999 0.601999-1.203998 0.601999-1.805996 0 0 0.601999-0.601999 0.601999-1.805996l0.601999-1.203998c1.203998-3.009994 2.407995-4.213992 3.611993-5.417989 0.601999-0.601999 0.601999-0.601999 1.203998-1.203998 1.805996-1.805996 4.815991-4.213992 7.825985-5.417989 44.547913-19.865961 73.443857-64.413874 73.443857-113.175779 0-55.985891-37.925926-105.349794-91.503821-119.797766-13.845973-3.611993-22.273956-18.059965-18.059965-31.905938 3.009994-11.437978 13.243974-19.263962 24.681952-19.263962 2.407995 0 4.213992 0.601999 6.621987 0.601999 76.453851 20.46796 129.429747 90.299824 129.429747 169.16167 0 43.343915-16.253968 84.881834-45.751911 117.389771l-10.23398 11.437978 13.243974 6.621987c95.717813 49.363904 154.713698 147.489712 154.713698 255.247501l0 57.791887C1024 792.230453 1012.562022 803.066432 998.114051 803.066432L998.114051 803.066432z"
              p-id="3877"></path>
        </svg>
        <div style="font-size: 12px">公众号</div>
        <div v-show="isWechatShow" class="QQ-pop-up wechat">
          <p class="p1">微信公众号</p>
          <img src="../assets/img/ADT/common/wechat.jpg" alt="">
        </div>
      </div>

      <div @click="keFu"
           class="position-relative cursor-pointer silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
        <img width="50%;" style="margin-bottom: 5px" :src="require('@/assets/img/KF.png')" alt=""/>
        <div style="font-size: 12px">客服</div>
      </div>
      <div v-if="showApp()" @click="appDown"
           class="position-relative cursor-pointer silde-serve silde-W d-md-flex flex-column justify-content-center align-items-center">
        <img width="50%;" style="margin-bottom: 5px" :src="require('@/assets/img/ADT/home/app-icon.png')" alt=""/>
        <div style="font-size: 12px">APP</div>
      </div>
      <!-- <div
        @click="memberVip"
        class="position-relative cursor-pointer silde-serve silde-W d-md-flex d-flex flex-column justify-content-center align-items-center"
      >
        <img width="50%" :src="require('@/assets/img/silde/vip.png')" alt="" />
        <div>vip</div>
      </div> -->

      <!-- <div
        @click="redPacket(1)"
        class="cursor-pointer silde-serve silde-W d-flex flex-column justify-content-center align-items-center"
      >
        <img
          width="50%"
          :src="require('@/assets/img/silde/hongbao.png')"
          alt=""
        />
        <div>红包</div>
      </div> -->
      <!-- <div>
        <Task-in :bottom-right="false">
          <div
            class="cursor-pointer silde-serve silde-W d-flex flex-column justify-content-center align-items-center"
          >
            <img
              width="50%"
              :src="require('@/assets/img/silde/QD.png')"
              alt=""
            />
            <div>签到</div>
          </div>
        </Task-in>
      </div> -->

      <!-- <div
        class="d-md-none cursor-pointer silde-serve silde-W d-md-flex d-none flex-column justify-content-center align-items-center"
        @click="recharge"
      >
        <img width="50%" :src="require('@/assets/img/recharge.gif')" alt="" />
        充值
      </div> -->

      <!-- <div
        @click="appDown"
        class="position-relative cursor-pointer silde-serve d-lg-none silde-W d-md-flex d-flex flex-column justify-content-center align-items-center"
      >
        <img width="50%" :src="require('@/assets/img/silde/app.png')" alt="" />
        <div>app</div>
      </div> -->


    </div>

    <div v-show="immediatelyShopping" class="position-fixed z-index-1" style="
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
      ">
      <!-- 红包弹窗 -->
      <div v-show="immediatelyShopping" class="position-fixed z-index-100 immediatelyShopping">
        <div class="bg-size bg-repeat" :style="`background-image: url(${require('@/assets/img/vd/red_top.png')})`">
          <div class="immediatelyShopping-box px-2 pt-3">
            <div class="d-flex"></div>
            <div class="text-center mt-1">
              加官群 {{ web_info.qq_group_number }}，抢更多福利！
            </div>
            <div class="d-flex justify-content-center px-3 immediatelyShopping-input">
              <input placeholder="请输入红包口令" type="text" v-model="packet" class="d-block py-2"/>
            </div>
            <div class="d-flex justify-content-center">
              <img @click="OpenRebBack(2)" width="60px" :src="require('@/assets/img/vd/kai.png')" alt=""/>
            </div>
            <div class="mt-4 text-center">红包列表</div>
            <div class="immediatelyShopping-list mt-2">
              <div v-show="item.status == 1" :class="item.status != 2 ? '' : 'filter-grayscale-1'"
                   v-for="(item, index) in redList" :key="index" class="px-2 py-1 rounded mb-2"
                   style="background-color: #ad252c">
                <div class="d-flex mt-3">
                  <div
                      class="immediatelyShopping-item-img d-flex flex-column align-items-center justify-content-end bg-size-100 pb-md-2 pb-1"
                      :style="`background-image: url(${require('@/assets/img/vd/red_money.png')})`"
                      style="font-size: 12px">
                    <div>总数量</div>
                    <div>{{ item.num }}</div>
                  </div>
                  <div class="ml-2" style="font-size: 13px">
                    <div class="mb-2">{{ item.title }}</div>
                    <div class="mb-2">参与条件：{{ item.briefe }}</div>
                    <div class="mb-2">结束时间：{{ item.end_time }}</div>
                  </div>
                </div>
                <div v-if="item.remainder == 1" @click="OpenRebBack(1, item)"
                     class="cursor-pointer immediatelyShopping-pk text-center px-4">
                  抢红包
                </div>
                <div v-if="item.remainder == 0" class="cursor-pointer immediatelyShopping-pk text-center px-4">
                  已抢完
                </div>
              </div>
            </div>
            <!-- 关闭的指示点 -->
            <div @click="redPacket(0)" class="position-absolute" style="right: 0; top: 0">
              <i class="iconfont icon-guanbi2 text-white" style="font-size: 30px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 活动弹窗- 版本2 -->
    <div class="ActivityCenter position-fixed z-index-333" v-show="ActiveShow" style="width: 100vw;
        height: 100vh;z-index:5;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
      ">
      <div class="ActivityCenter1">
        <!-- 头部banner -->
        <div class="activeTitle"></div>

        <!-- 活动主页 -->
        <div class="ActivityCenter2">
          <div class="active_top">
            <div class="active-button" v-for="(item, index) in Activebutton" :key="index"
                 :class="{ active: currentIndex === item.value }" @click="liClick(item.value)">{{ item.label }}
            </div>
          </div>
          <div class="active-right">
            <component :is="comName" ref="comName"></component>
          </div>

        </div>
        <div class="activeDel Btn_a" @click="ActiveShow = false;">
          <span class="activedel1">
            <!-- <i class="el-icon-close cursor-pointer" style="font-size: 28px;font-weight: bold !important;"></i> -->
            <img style="width: 30px;" src="../assets/img/ADT/event-hall/close.png" alt="">
          </span>
        </div>
      </div>
    </div>


    <!-- 兑换CDK弹窗 -->
    <div class="CDKpopUp position-fixed z-index-333" v-show=" isCDKpopUp " style="width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
      ">
      <div class="CDKpopUp1">
        <div class="qq-info">QQ官方交流群：619342798</div>
        <div class="cdk-input">
          <input placeholder="请输入输入CDK口令" v-model=" cdk " type="text">
        </div>

        <div class="cdk-submit" @click=" dhcdkHandle ">
          <img style="width: 220px;" src="../assets/img/ADT/home/cdk-dh.png" alt="">
        </div>

        <div class="activeDel Btn_a" @click=" isCDKpopUp = false ">
          <span class="activedel1">
            <!-- <i class="el-icon-close cursor-pointer" style="font-size: 28px;font-weight: bold !important;"></i> -->
            <img style="width: 30px;" src="../assets/img/ADT/event-hall/close.png" alt="">
          </span>
        </div>
      </div>
    </div>

    <!-- 兑换CDK成功弹窗 -->
    <div class="successCDKpopUp position-fixed z-index-333" v-show=" isSuccessCDKpopUp " style="width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
      ">
      <div class="successCDKpopUp1">
        <div class="cdk-input">
          <p>您领取了</p>
          <div>
            价值 <span style="color:#FFC600;">{{ cdkSpecies }}</span> 的红包
          </div>
        </div>

        <div class="cdk-submit" @click=" isCDKSuccessPopUp ">
          <img style="width: 220px;" src="../assets/img/ADT/home/zhidaole.png" alt="">
        </div>

        <div class="activeDel Btn_a" @click=" isSuccessCDKpopUp = false ">
          <span class="activedel1">
            <!-- <i class="el-icon-close cursor-pointer" style="font-size: 28px;font-weight: bold !important;"></i> -->
            <img style="width: 30px;" src="../assets/img/ADT/event-hall/close.png" alt="">
          </span>
        </div>
      </div>
    </div>

    <el-dialog custom-class="custom_dialog" title="" :close-on-click-modal=" false " :visible.sync=" dialogVisible "
               append-to-body center width="300px">
      <div class="text-center padding-tb text-white">恭喜您领取到了<span class="text-orange">{{ muVal }}</span></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click=" dialogVisible = false ">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import sUserAgent from "@/assets/js/common.js";
import {mapState} from "vuex";
import $api from "@/api/vd/index";
import TaskIn from "@/views/TaskIn/index";
import redenvelopes from "./common/Redenvelopes.vue";
import vipfl from "./common/vipfl.vue"
import activehot from "./common/activehot.vue";
import freeskin from "./common/freeskin.vue";
import commonMy from "./common/my.vue";
import mrqdCom from "./common/mrqd.vue";

import gradebenefits from "./common/gradebenefits.vue";
import taskcenter from "./common/taskcenter.vue";

export default {
  name: "silde",
  components: {
    mrqdCom,
    TaskIn,
    commonMy,
    redenvelopes,
    activehot,
    freeskin,
    gradebenefits,
    taskcenter,
  },
  data() {
    return {
      cdkSpecies: undefined,
      isSuccessCDKpopUp: false,
      domain_url: '',
      cdk: undefined,
      isCDKpopUp: false,  // CDK
      isQQShow: false,
      isWechatShow: false,
      dialogVisible: false,
      muVal: '',
      comName: "mrqdCom",
      immediatelyShopping: false,
      QQ_qr_code: "",
      QQ_qr_code_is_show: false,
      qq_group_number: 123456789,
      packet: "",
      redList: [],
      silde: 0,
      // 是否显示侧边栏
      isShowSidle: false,
      // 侧栏滚动距离
      sidleSum: 0,
      ActiveShow: false,
      // 点击事件
      currentIndex: -1,
      loading: false,
      //   按钮名称
      Activebutton: [
        {
          label: "福利中心",
          value: -1
        },
        {
          label: "热门活动",
          value: 0
        },
        {
          label: "任务中心",
          value: 1
        },
        {
          label: "VIP福利",
          value: 2
        },

      ],
    };
  },
  created() {
    this.domain_url = window.location.host;
  },
  computed: {
    ...mapState(["web_info"]),
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
    isShowWechat() {
      const arr_list = ['www.upcsgo.com', 'upcsgo.com', '']
      return arr_list.includes(location.host)
    }
  },
  destroyed() {
    this.$EventBus.$off("showHD");
  },
  mounted() {
    this.init();
    this.QQ_qr_code = this.$store.state.web_info.qq_group_qr_code;
    this.qq_group_number = this.$store.state.web_info.qq_group_number;
    // window.addEventListener("scroll", this.winScroll); 取消滚动划出侧边栏
    this.$EventBus.$off("showHD");
    this.$EventBus.$on("showHD", () => {
      this.csactive();
    });
  },
  watch: {
    sidleSum(n, l) {
      if (l < n) {
        this.isShowSidle = true;
        if (this.sUserAgent == 'phone') {
          this.silde = -40
        } else {
          this.silde = -60
        }

      } else {
        this.isShowSidle = false;
        this.silde = 0
      }
    },
  },
  methods: {
    showApp() {
      return sUserAgent.AppLimit()
    },
    // 过滤域名
    filterDomain() {
      if ([`upcsgo.rzxiyou.cn`, `upcsgo.rzxyxx.cn`, `upcsgo.xinghouse.cn`, `upcsgo.022net.org.cn`].includes(window.location.host)) {
        return true;
      } else {
        return false;
      }
    },
    isCDKSuccessPopUp() {
      this.isSuccessCDKpopUp = false;
    },
    // cdk兑换
    async dhcdkHandle() {
      console.log('cdk-值:', this.cdk);
      this.$notify.closeAll();
      if (!this.cdk) {
        this.$notify({
          type: 'error',
          message: '请输入口令',
          position: 'top-left'
        });
        return
      }
      let params = {
        type: 2,
        key: this.cdk,
      }
      try {
        let res = await $api.getOpenRebBack(params);
        if (res.code === 200) {
          this.isCDKpopUp = false;
          this.isSuccessCDKpopUp = true;
          this.cdkSpecies = res.data || '0.00';
          this.$store.dispatch("userInfo");
        } else {
          this.$notify({
            type: 'error',
            message: res.message,
            position: 'top-left'
          });
        }

      } catch (e) {
        console.log(e);
      }
    },
    showQQImg() {
      this.isQQShow = !this.isQQShow;
    },
    drawTap() {
      if (this.$refs.comName) this.$refs.comName.taskDataList()
    },
    winScroll() {
      let top = Math.floor(
          document.documentElement.scrollTop || document.body.scrollTop
      );
      this.sidleSum = top;
    },

    async showCDK() {
      let token = await getToken()
      if (!token) {
        return this.$store.commit("LOGIN_TOP", true)
      }
      if (this.sUserAgent == "phone") {
        // alert(7777)
        // this.$router.push("/user/csactive");
        this.$EventBus.$emit('isCDKpopUp', true);
      } else {
        this.isCDKpopUp = true;
        this.currentIndex = -1
        this.comName = 'mrqdCom'
        if (this.$refs.myName) this.$refs.myName.init()
        this.$store.state.mission_state = !this.$store.state.mission_state
      }
    },


    // 跳转活动页面
    async csactive() {
      let token = await getToken()
      if (!token) {
        return this.$store.commit("LOGIN_TOP", true)
      }
      if (this.sUserAgent == "phone") {
        this.$router.push("/user/csactive");
      } else {
        this.ActiveShow = true;
        this.currentIndex = -1
        this.comName = 'mrqdCom'
        if (this.$refs.comName) {
          this.$refs.comName.init()
        }
        this.$store.state.mission_state = !this.$store.state.mission_state
      }
    },
    //点击隐藏侧边栏
    hideTapShow() {
      if (this.sUserAgent == "phone") {
        !this.silde ? this.silde = -40 : this.silde = 0
      } else {
        !this.silde ? this.silde = -60 : this.silde = 0
      }
    },
    liClick(index) {
      this.currentIndex = index;
      // if (index == 0) {
      //   this.comName = activehot;
      // }else if (index == 1) {
      //   this.comName = taskcenter;
      // }else if (index == 2) {
      //   this.comName = freeskin;
      // }else if (index == 3) {
      //   this.comName = redenvelopes;
      // }else if (index == 4) {
      //   this.comName = gradebenefits;
      // }
      if (index == -1) {
        this.comName = mrqdCom;
      } else if (index == 0) {
        this.comName = activehot;
      } else if (index == 1) {
        this.comName = taskcenter;
      } else if (index == 2) {
        this.comName = vipfl;
      } else if (index == 3) {
        this.comName = redenvelopes;
      } else if (index == 4) {
        this.comName = gradebenefits;
      }
    },
    // 跳转充值页面
    recharge() {
      this.$router.push("/user/recharge");
    },
    init() {
      this.getredList();
      if (this.sUserAgent == "phone") {
        this.silde = -55; // 手机端默认隐藏
        this.silde = 0; // 手机端默认隐藏
      } else {
        this.silde = 0;
      }
    },
    keFu() {
      window.open("/chatlink.html", "_blank");
    },
    QQQqun() {
      window.open("https://qm.qq.com/cgi-bin/qm/qr?k=WEHxjPg27hWw-mNT52kAdqsJZoaOl0d2&jump_from=webapi&authKey=Co9X0K0Oj9kkSp1AiYVSDIIaaMxeDtnEC/UnYq6XLoTkaHtkp6EyBaTWj4ogt6CN", "_blank");
      return;
    },
    toQQqun() {
      return;
    },
    // 跳转VIP
    memberVip() {
      this.$router.push("/FmemberVip");
    },
    // 跳转APP下载
    appDown() {
      this.$router.push("/appDownload");
    },

    // 隐藏显示
    sildeOff() {
      if (this.silde === 0) {
        if (this.sUserAgent == "phone") {
          this.silde = -40;
        } else {
          this.silde = -50;
        }
      } else {
        this.silde = 0;
      }
    },
    // 返回顶部
    backTop() {
      document.getElementById("content").scrollTo(0, 0);
    },
    // 打开红包
    redPacket(is) {
      if (is) {
        this.immediatelyShopping = true;
      } else {
        this.immediatelyShopping = false;
      }
    },
    QQ() {
      this.QQ_qr_code_is_show = !this.QQ_qr_code_is_show;
      setTimeout(() => {
        this.QQ_qr_code_is_show = false;
      }, 5000);
    },
    // 渲染列表
    async getredList() {
      let {data, code} = await $api.getRebBackList();
      if (code != 200) return;
      this.redList = data;
    },
    // 打开红包
    async OpenRebBack(sum, item) {
      if (this.loading) return
      this.loading = true
      let redOpen = {};
      if (sum == 1) {
        redOpen = {
          type: sum,
          id: item.id,
        };
      }
      if (sum == 2) {
        redOpen = {
          type: sum,
          key: this.packet,
        };
      }
      setTimeout(() => {
        this.loading = false
      }, 1000);
      try {
        let {data, code} = await $api.getOpenRebBack(redOpen);
        if (code != 200) return;
        this.dialogVisible = true
        this.muVal = data
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wechat_svg {
  fill: currentColor;
}

.wechat {
  position: absolute;
  background: url(../assets/img/ADT/common/QQ-bj.png) no-repeat center;
  background-size: contain;
  right: 45px;
  top: 0;
  width: 14rem;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  img {
    width: 50%;
  }
}

.QQ-container {
  position: relative;

  .QQ-pop-up {
    width: 194px;
    height: 257px;
    background-image: url(../assets/img/ADT/common/QQ-bj.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100%;
    position: absolute;
    top: 0;
    left: -195px;
    text-align: center;
    padding-top: 20px;

    & > p {
      font-size: 18px;
      color: #35C9EE;
    }

    .p2 {
      margin: 10px 0;
    }

    .p3 {

      font-size: 14px;
    }

    & > img {
      width: 118px;
      height: 118px;
    }
  }
}

@keyframes move {
  0% {
    transform: scale(1.3);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.3);
  }
}

@keyframes moveMb {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

.welfare_img {
  margin-left: -2px;

  img {
    width: 30px;
    animation: move 2s 0.15s linear infinite;
  }

  @media (max-width: 1300px) {
    img {
      width: 20px !important;
      animation: moveMb 2s 0.15s linear infinite;
    }
  }
}

.silde {
  // display: none;
  top: 30%;
  right: 0;
  width: 60px;
  z-index: 10;
  padding: 20px 0;
  // background-image: url("../assets/img/silde/Rank.png");
  // background-size: 100% 100%;
  background-color: #162F40;
  transition: all 0.5s;

  @media (max-width: 1000px) {
    top: 25%;
    font-size: 12px;
    width: 40px;
    display: none;

    .silde-box {
      text-align: center;
      padding-left: 0 !important;
    }

    /*display: none;*/
  }

  &-off {
    top: 40%;
    left: -20px;
    background-color: var(--main-yellow);
    width: 20px;
    height: 50px;
    font-size: 24px;
    line-height: 50px;
  }

  &-serve {
    /*border:1px solid #81cdf8;*/
    border-radius: 50px;
    margin-bottom: 0px;
    // width: 70px;
    height: 80px;

    @media (max-width: 1200px) {
      width: 40px;
      height: 60px;
      background-size: 100% 100%;
    }
  }

  &-weixin {
    /*border: 1px solid #81cdf8;*/
    right: 100%;
    border-radius: 10px;
    padding-right: 10px;
    display: none;
  }

  &-W:hover &-weixin {
    display: block;
  }
}

.immediatelyShopping {
  width: 350px;
  left: calc(50% - 175px);
  top: calc(25%);
  z-index: 999;
  background-color: #c02932;
  border-radius: 10px;

  &-list {
    height: 300px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-input {
    width: 150px;
    margin: 10px auto;
    background-color: #fffad8;
    border-radius: 5px;

    & input {
      width: 140px;
    }
  }

  &-item {
    &-img {
      width: 87px;
      height: 80px;
    }
  }

  &-pk {
    width: 100%;
    margin: 5px auto;
    background-color: #bd3840;
    padding: 10px;
    border-radius: 5px;
  }

  @media (max-width: 1200px) {
    width: 70%;
    left: 15%;
    top: calc(80px + 5%);

    &-box {
      &-con {
        &-img {
          width: 50%;
        }

        & img {
          width: 100%;
        }

        &-dis {
          margin-left: 20px !important;
          margin-top: 10px;
        }
      }
    }
  }
}

.jss163 {
  width: 20px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  // border-top-left-radius: 6px;
  // border-bottom-left-radius: 6px;
  // background: #3e3838;
  top: 50%;
  left: -30px;
  cursor: pointer;
  position: absolute;
  margin-top: -35px;
}


.successCDKpopUp {
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #fff;

  .successCDKpopUp1 {
    width: 650px;
    height: 420px;
    left: calc(50% - 325px);
    top: calc(50% - 210px);
    position: relative;
    border-right: 10px;
    background: url("../assets/img/ADT/home/cdk-success-pop.png") no-repeat;
    background-size: 100% 100%;

    .cdk-input {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -100%);
      text-align: center;

      & > p {
        font-size: 35px;
      }

      & > div {
        margin-top: 20px;
      }
    }

    .cdk-submit {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    .activeDel {
      position: absolute;
      top: 0;
      right: -20px;
      z-index: 9999;
      color: rgb(255, 183, 165);

      .activedel1 {
        padding: 10px;
        color: #000;
        font-weight: bold;
        border-radius: 50%;
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}

.CDKpopUp {
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #848492;

  .CDKpopUp1 {
    width: 650px;
    height: 420px;
    left: calc(50% - 325px);
    top: calc(50% - 210px);
    position: relative;
    border-right: 10px;
    background: url("../assets/img/ADT/home/cdk-bg.png") no-repeat;
    background-size: 100% 100%;

    .qq-info {
      position: absolute;
      bottom: 15px;
      right: 58px;
      text-align: center;
      color: #fff;
    }

    .cdk-input {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-65%, -100%);

      & > input {
        color: #fff;
        font-size: 22px;
      }
    }

    .cdk-submit {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    .activeDel {
      position: absolute;
      top: 0;
      right: -20px;
      z-index: 9999;
      color: rgb(255, 183, 165);

      .activedel1 {
        padding: 10px;
        color: #000;
        font-weight: bold;
        border-radius: 50%;
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}

.ActivityCenter {
  outline: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #848492;

  .ActivityCenter1 {
    width: 1129px;
    height: 781px;
    left: calc(50% - 550px);
    top: calc(50% - 390px);
    position: relative;
    border-right: 10px;
    background: url("../assets/img/ADT/event-hall/activity-bg.png") no-repeat;
    background-size: 100% 100%;

    .left-top {
      .left-top-button {
        white-space: nowrap;
        display: flex;
        overflow: hidden;

        .active-button {
          width: 100%;
          border: 0;
          max-width: 100%;
          min-width: auto;
          min-height: 44px;
          margin-left: 1px;
          padding-top: 6px;
          margin-right: 1px;
          padding-left: 12px;
          padding-right: 12px;
          padding-bottom: 6px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          font-size: 14px;
          text-align: center;
          color: #c3c3e2;
        }

        .tab_act {
          font-size: 16px;
          background: rgba(53, 201, 238, .2);
          color: #FFFFFF;
          // border: 1px solid #35C9EE;
        }

        .tab_act2 {
          font-size: 16px;
          font-weight: bold;
          color: #55330B;
          background: url(../assets/img/ADT/event-hall/hd-tabs-active.png);
          background-repeat: no-repeat;
          background-position: 100% 100%;
          background-size: auto;
        }
      }

      .left-center {
        width: 100%;
        height: calc(100% - 44px);
        background-color: yellow;
      }
    }


    .activeTitle {
      width: 100%;
      height: 144px;
    }

    .ActivityCenter2 {
      // display: flex;

      background: rgb(28, 32, 55);
      margin: 58px 25px 0 25px;
      z-index: 999;
      height: 575px;

      .active-left {
        display: none;
        // padding: 10px 0 0 10px;
        flex: 1;
        height: 100%;
        background-color: rgba(28, 32, 55, 1);
        text-align: center;

        // #017DA1
        .active-button {
          padding: 25px;
          cursor: pointer;
        }

        .active-button.active {
          color: #017DA1;
          background-color: #162F40;
        }
      }

      .active_top {
        display: flex;
        justify-content: space-between;
        gap: 5px;

        .active-button {
          background: rgba($color: #35C9EE, $alpha: 0.2);
          height: 45px;
          width: 25%;
          justify-content: center;
          cursor: pointer;
          color: #fff;
          display: flex;
          align-items: center;
        }

        .active-button.active {
          color: #55330B;

          background-color: #F9C545;
        }
      }

      .active-right {
        flex: 6;
        height: 100%;
        // margin-left: 14px;
      }
    }

    .activeDel {
      position: absolute;
      top: 0;
      right: -20px;
      z-index: 9999;
      color: rgb(255, 183, 165);

      .activedel1 {
        padding: 10px;
        color: #000;
        font-weight: bold;
        border-radius: 50%;
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
      }
    }
  }
}</style>
