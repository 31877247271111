<template>
  <div class="d-flex">

    <!-- 内容区域 -->
    <div class="content mt-4">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-wrap">
          <div @click="bill('1')" :class="status=='1'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            提取完成
          </div>
          <div @click="bill('2')" :class="status=='2'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            已兑换
          </div>
          <div @click="bill('3')" :class="status=='3'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            冻结中
          </div>
          <div @click="bill('4')" :class="status=='4'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            申请提货
          </div>
          <div @click="bill('5')" :class="status=='5'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            正在发货
          </div>
          <div @click="bill('6')" :class="status=='6'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            等待收货
          </div>
        </div>
      </div>
      <div class="flex-wrap mt-3 row d-flex" >
        <div class="back-gun mb-3" style="" v-for="(item,index) in StorageList" :key="index">
          <weapon class="position-relative " :res-data="item" :class="item == 2? 'border-yellow' :''">
            <div v-if="item == 2" class="position-absolute" style="right: 10px;top: 10px">
             
              <div> 
                <i class="iconfont icon-xuanzhong" style="1rem"></i>
              </div>
            </div>
          </weapon>
        </div>
          <vacancy  v-show="StorageList.length == 0"> </vacancy>
      </div>
      <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              @current-change="currentChange">
      </el-pagination>

      <div v-show="StorageList.length < 20 && StorageList.length != 0" style="height: 500px">

      </div>
    </div>


    <!-- 背包物品 - 显示退回原因 -->
    <el-dialog
            custom-class="pop-up"
            :show-close="false"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :visible.sync="ReturnReason"
            width="700px">
      <div class="p-4 text-white">
        <h3 class="h3 text-center mb-4">不知从何入手试试这些</h3>
        <p class="mb-3">
          最近骗子横行繁多，并会模仿官方客服、进行线下交易、线下转账，骗术繁多！
          大家擦亮眼睛，切勿贪图小便宜！上当受骗！！
        </p>
        <h5 class="h5 color-yellow">网站福利</h5>
        <p class="mb-3">
          注册红包：注册即可领取1~1000元随机红包，红包自动进入余额，可全站使用！
          免费ROLL房：注册并通过核验URL，即可进入无门槛ROLL房，感受饰品从天而降！
          免费盲盒：本站为水友提供了等级盲盒、时间盲盒、推广盲盒等福利，白嫖到爽！
        </p>
        <h5 class="h5 color-yellow">特别说明：</h5>
        <p class="mb-3">
          本站拥有正规备案；采用公平算法保证掉落随机；支持饰品自动发货；请放心娱乐！
        </p>
        <h5 class="h5 color-yellow">提别提醒：</h5>
        <p class="text-danger mb-3">
          本站禁止交易、返现；禁止绑定他人URL，谨防被骗！
          禁止恶意注册账号，一经发现永久封禁！
          开盲盒即为娱乐，HXD勿上头！
        </p>
        <div class=" ml-auto color-dark-brown" style="width: 180px">
          <bottom-bg @click.native="ReturnReason = false" title="确认"></bottom-bg>
        </div>
        <div @click="ReturnReason = false" class="position-absolute" style="right: 20px;top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi2  cursor-pointer" style="font-size:30px"></i>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import $api from '@/api/user/index'
  import bottomBg from "@/components/bottomBg";
  import weapon from "./common/weapon";
  export default {
    name: "index",
    components:{
      bottomBg,
      weapon,

    },
    data(){
      return {
        // 弹出框
        ReturnReason:false,
        StorageList:[],
        // 翻页数量
        total:0,
        pageSize:0,

        status:1
      }
    },
    created() {
    },
    mounted() {
      this.init()
    },
    methods:{
      init(){
        this.getStorage()
      },
      shoppinng(item,index){
        // 需要判断是不是退回的状态，之后在弹出窗口
        this.ReturnReason = true
        console.log(item,index)
      },
      // 获取仓库的信息
      async getStorage(page = 1,status = 1){
        try {
          let {data,code} = await $api.getStorage(page,status)
          if(code == 500)return
          data.data.forEach(item =>{
            if(item.back_message != ''){
              console.log(123)
            }
            item.isShow = false
          })
          this.StorageList = data.data
          this.total = data.total
          this.pageSize = data.per_page
        }catch (e) {
          console.log(e)
        }
      },
      // 显示仓库状态
      bill(val){
        this.status = val
        let loading = this.$loading({lock:true,text: '正在加载',  spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'})
        setTimeout(() => {
          this.getStorage(1,val)
          loading.close();
        }, 500);

      },
      currentChange(val){
        this.getStorage(val,this.status)
        window.scrollTo(0,0);
      },
    }
  }
</script>

<style scoped lang="scss">
  .back-gun{
    width: 9.3%;
    @media (max-width: 1600px) {
      width: 15%;
    }
    @media (max-width: 1366px) {
      width: 20%;
    }
    @media (max-width: 1200px) {
      width: 25%;
    }
    @media (max-width: 960px) {
      width: 33%;
    }
  }
  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5)
  }
  .content {
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
    }
    & .active{
      background: #000000;
    }
  }
</style>
