import {request} from "../index";


function getluckyType(){
    return request({
        url: `/lucky/type`,
        method:'get'
    })
}

function getluckyList(page = 1,type_id = 0,name = '',start = 0,end = 0,sort = 0){
    console.log(page,type_id,name,start,end,sort)
    if(name != ''){
        return request({
            url: `/lucky/list?page=${page}&type_id=${type_id}&name=${name}&sort=${sort}`,
            method:'get'
        })
    }
    if(end == 0){
        return request({
            url: `/lucky/list?page=${page}&type_id=${type_id}&sort=${sort}`,
            method:'get'
        })
    }
    if(end != 0){
        return request({
            url: `/lucky/list?page=${page}&type_id=${type_id}&start=${start}&end=${end}&sort=${sort}`,
            method:'get'
        })
    }

}

function getluckySearch(page,name){
    return request({
        url: `/lucky/search?name=${name}&page=${page}`,
        method:'get'
    })
}

function getluckydetail(id){
    return request({
        url: `/skins/detail?id=${id}`,
        method:'get'
    })
}

function getluckyOpen(data){
    return request({
        url: `/lucky/open`,
        method:'post',
        data
    })
}

function meHistory(page){
    return request({
        url: `/lucky/me-history?page=${page}`,
        method:'get'
    })
}



function getHistory(id){
    return request({
        url: `/lucky/history?id=${id}`,
        method:'get'
    })
}

export default {
    getluckyType,
    getluckyList,
    getluckySearch,
    getluckydetail,
    getluckyOpen,
    meHistory,
    getHistory
}
