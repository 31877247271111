<template>
    <div class="partner">
        <button @click="$router.go(-1)" class="fback">返回</button>
        <img width="100%" v-if="sUserAgent == 'web'" height="100%" src="@/assets/img/ADT/user/banner.jpg" alt="">
        <img width="100%" v-else height="1000px" src="@/assets/img/ADT/user/banner1.jpg" alt="">
        <div class="box">
            <div class="top">
                <div class="left">
                    <h2>拉新必赚</h2>
                    <p><img src="@/assets/img/ADT/user/d.png" width="14" /> 每拉一个有效用户即可获得 <img
                            src="@/assets/img/ADT/home/UP@2x.png" class="upb" width="20" /> 6{{ sUserAgent != 'web' ? 'UP币'
                                : ''
                            }}，被拉新用户可免费开启宝箱</p>
                    <p class="tip">有效用户：充值满100，24小时内更新状态</p>
                </div>
                <div class="right">
                    <h2>我的拉新</h2>
                    <div class="box">
                        <a>
                            <h2>{{ user.people_invited_num }}</h2>
                            <p>邀请总人数</p>
                        </a>
                        <a>
                            <h2>{{ user_info.inviter != null ? '' + user_info.inviter.invite_code : '暂无' }}</h2>
                            <p>我的上级</p>
                            <!-- <p>有效用户</p> -->
                        </a>
                        <a>
                            <h2><img src="@/assets/img/ADT/home/UP@2x.png" width="22" /> {{ (user.invite_total_recharge) }}
                            </h2>
                            <p>被邀用户充值</p>
                            <!-- <p>奖励总额</p> -->
                        </a>
                    </div>
                </div>
            </div>
            <div class="bot" v-if="sUserAgent == 'web'">
                <div class="left">
                    <h2>您的合作伙伴链接</h2>
                    <div>
                        <input type="text" disabled :value="invite_url + user_info.invite_code"> <a @click="authCopy(1)">复制</a>
                    </div>
                </div>
                <div class="right">
                    <h2>邀请代码</h2>
                    <div>
                        <input type="text" disabled :value="user_info.invite_code"> <a @click="authCopy(2)">复制</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="bot1" v-if="sUserAgent != 'web'">
            <div class="left">
                <h2>您的合作伙伴链接</h2>
                <div>
                    <input type="text" disabled :value="invite_url + user_info.invite_code"> <a @click="authCopy(1)">复制</a>
                </div>
            </div>
            <div class="right">
                <h2>邀请代码</h2>
                <div>
                    <input type="text" disabled :value="user_info.invite_code"> <a @click="authCopy(2)">复制</a>
                </div>
            </div>
        </div>
<!--        <user-promotion-reward></user-promotion-reward>-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import $api from '@/api/user/index'
import sUserAgent from '@/assets/js/common.js'
// import UserPromotionReward from "@/components/user-promotion-reward.vue";
export default {
    // components: {
    //   UserPromotionReward
    // },
    data() {
        return {
            list: [],
            invite_url: '//www.upcsgo.com/?ref_code=',
            user: { promotion_level: 1 },
        }
    },
    computed: {
        ...mapState(['user_info', 'web_info']),
        sUserAgent() {
            return sUserAgent.sUserAgent();
        },
    },
    mounted() {
      console.log(this.user_info)
        let host = window.location.host;
        this.invite_url = 'https://' + host + '/?ref_code=';
    },
    methods: {
        // 拷贝推广链接
        authCopy(type) {
            if (type == 1) {
                this.$common.CopyText({ content: this.invite_url + this.user.invite_code })
            } else {
                this.$common.CopyText({ content: this.user.invite_code })
            }
        },
        // 推广数据
        async getPromote() {
            try {
                let { data, code } = await $api.getPromote()
                if (code == 500) return
                this.list = data
            } catch (e) {
                console.log(e)
            }
        },

        // 领取福利
        getViprewards(id) {
            $api.getPromoteReward({ id }).then(({code, message}) => {
                if (code == 200) {
                    this.$notify.closeAll();
                    this.$notify({
                        message: message,
                        position: "top-left",
                    });
                    this.getPromote(); // 重新获取列表数据
                }
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.fback {
        color: #fff;
        position: fixed;
        border: 2px solid #ccc;
        background: #e2a632;
        width: 60px;
        height: 40px;
        border-radius: 0.5rem;
        top: 1rem;
        right: 1.5rem;
        @media (min-width:600px) {
            display: none;
        }
    }
.content {
    height: 50vh;
    // background-color: #1C2037;
}

.partner {
    position: relative;


    @media (max-width:600px) {}

    >.box {
        background-image: url(../../assets/img/ADT/user/bot.png);
        background-position: center top;
        background-repeat: no-repeat;
        margin: 0 auto;
        width: 1020px;
        position: absolute;
        height: 340px;
        bottom: 45%;
        left: 50%;
        margin-left: -510px;

        @media (max-width:600px) {
            top: 22%;
            width: 100%;
            background-size: 95%;
            left: inherit;
            background-image: url(../../assets/img/ADT/user/bot1.png);
            margin-left: inherit;
            margin: 0 auto;
            background-size: 95% 230px;
        }

        >.top {
            padding: 40px;
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;

            @media (max-width:600px) {
                padding: 24px 20px;
                flex-direction: column;
                align-items: center;

            }

            >.left {
                flex: 2;

                >h2 {
                    color: #00F1F4;
                    font-weight: bold;
                    font-size: 24px;
                    font-style: italic;
                    margin-bottom: 20px;

                    @media (max-width:600px) {
                        display: none;
                    }
                }

                >p {
                    display: flex;
                    align-items: center;
                    color: #F9C545;
                    font-style: italic;
                    font-family: Monaco;
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 8px;

                    @media (max-width:600px) {
                        font-size: 14px;
                        margin-bottom: 4px;

                        >.upb {
                            display: none;
                        }
                    }

                    >img {
                        margin: 0 4px;

                        @media (max-width:600px) {
                            margin-right: 8px;
                        }
                    }
                }

                >.tip {
                    color: #ccc;

                    font-weight: 400;
                    font-size: 14px;
                    padding-left: 24px;

                    @media (max-width:600px) {
                        margin-bottom: 16px;
                        font-size: 12px;
                        color: #ddd;

                    }
                }
            }

            >.right {
                flex: 1;



                >h2 {
                    color: #F9C546;
                    font-weight: bold;
                    font-size: 24px;
                    font-style: italic;
                    margin-bottom: 20px;

                    @media (max-width:600px) {
                        font-size: 14px;
                        font-style: inherit;
                        padding: 0 10%;
                        margin-bottom: 10px;
                        margin-top: 8px;
                        color: #ddd;
                        font-style: italic;
                    }
                }

                >.box {
                    width: 400px;
                    display: flex;
                    justify-content: space-between;
                    background-color: #8bc9ff2f;
                    border-radius: 8px;
                    padding: 20px;

                    @media (max-width:600px) {
                        flex: 0 0 80%;
                        max-width: 80%;
                        align-items: center;
                        margin: 0 auto;
                        padding: 14px 20px;
                    }

                    >a {
                        flex: 1;
                        text-align: center;
                        border-right: solid 1px #7978b2a7;

                        >h2 {
                            color: #F9C545;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: Monaco;
                            font-size: 20px;
                            margin-bottom: 10px;
                            font-weight: bold;

                            @media (max-width:600px) {
                                font-size: 16px;
                            }

                            img {
                                margin-right: 4px;

                                @media (max-width:600px) {
                                    width: 16px;
                                }
                            }

                        }

                        >p {
                            @media (max-width:600px) {
                                font-size: 12px;

                            }
                        }
                    }

                    >a:last-child {
                        border: none;
                    }
                }
            }
        }

        >.bot {
            background-image: url(../../assets/img/ADT/user/hh.png);
            background-size: 950px 100px;
            width: 950px;
            padding: 20px 40px;
            margin: 0 auto;
            height: 100px;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;

            @media (max-width:600px) {
                display: none;
            }

            >div {
                flex: 1;
                position: relative;

                >h2 {
                    font-weight: bold;
                    color: #fff;
                    margin-bottom: 10px;
                }

                >div {
                    >input {
                        height: 44px;
                        background-color: #272448;
                        width: 95%;
                        color: #ccc;
                        font-family: Monaco;
                        font-size: 14px;
                        text-indent: 10px;
                        border-radius: 4px;
                    }

                    >a {
                        position: absolute;
                        height: 36px;
                        width: 80px;
                        background-color: #724DFC;
                        position: absolute;
                        right: 24px;
                        top: 30px;
                        line-height: 36px;
                        text-align: center;
                        cursor: pointer;
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    .bot1 {
        background-image: url(../../assets/img/ADT/user/hh.png);
        background-size: 950px 100px;
        width: 950px;
        padding: 20px 40px;
        margin: 0 auto;
        height: 100px;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;

        @media (max-width:600px) {
            top: 46%;
            position: absolute;
            background-image: url(../../assets/img/ADT/user/bot11.png);
            flex-direction: column;
            background-size: 95% 180px;
            width: 100%;
            padding: 20px;
            height: 180px;
        }

        >div {
            flex: 1;
            position: relative;

            @media (max-width:600px) {
                margin-bottom: 20px;
            }

            >h2 {
                font-weight: bold;
                color: #fff;
                margin-bottom: 10px;

                @media (max-width:600px) {
                    text-indent: 4px;
                    font-size: 12px;
                }
            }

            >div {
                >input {
                    height: 44px;
                    background-color: #272448;
                    width: 95%;
                    color: #ccc;
                    font-family: Monaco;
                    font-size: 14px;
                    text-indent: 10px;
                    border-radius: 4px;

                    @media (max-width:600px) {
                        font-size: 12px;
                        height: 36px;
                        width: 100%;
                        background-color: #1c1939;
                    }
                }

                >a {
                    position: absolute;
                    height: 36px;
                    width: 80px;
                    background-color: #724DFC;
                    position: absolute;
                    right: 24px;
                    top: 30px;
                    line-height: 36px;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 4px;

                    @media (max-width:600px) {
                        font-size: 12px;
                        height: 30px;
                        line-height: 30px;
                        right: 2px;
                        width: 70px;
                        top: 25px;
                    }
                }
            }
        }
    }
}
</style>
