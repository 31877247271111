<template>
<!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class="rounded overflow-hidden"
         :class="'ggbg' +resData.lv" :style="'width:100%'" >
        <div class="bg-size-100 bg-position-center  bg-repeat position-relative d-flex flex-wrap
        justify-content-center align-items-center ">
            <div class="bglogo-e" style="width: 80px; height: 80px; position: absolute;"></div>
            <div class="px-3 text-center text-overflow-1 w-100 d-flex justify-content-center  "
                 style="line-height: 24px;font-size: 12px;height: 24px;;">

                <div class="text-overflow-1"><money :font-size="'12px'" />{{resData.bean}}</div>
            </div>
            <div class="d-flex justify-content-center" style="height: 120px">
                <img height="70%" class="position-relative my-3"
                     :src="resData.cover" alt="">
            </div>
            <div class="w-100 text-center d-flex px-2 ">
                <div class="text-overflow-1 text-left" style="font-size: 12px;line-height: 24px;width: 70%">{{resData.name}}</div>
                <div class="text-overflow-1" style="font-size: 12px;line-height: 24px">
                    {{resData.skins.dura_alias}}
                </div>

            </div>
        </div>

        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            // 传输武器整体宽度
            width:{
                type:String,
                default:"14%"
            },
            // 武器显示的高度，宽度和整体宽度是一样的
            heightWeapon:{
                type:String,
                default:"150px"
            },
            // 传输背景 style 对象形式传输
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            // 武器对应的宽度 高度 带单位 style 对象形式传输
            weaponSize:{
                type:Object,
                default(){
                    return {
                        width: '115px',
                        marginTop:'25px'
                    }
                }
            },
        //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            }
        },
    }
</script>

<style scoped lang="scss">
    .weapon{
        &-name{
             padding: 10px 0;
         }
    }
</style>
