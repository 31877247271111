<template>
    <div>
        我的背包
    </div>
</template>

<script>
    export default {
        name: "active"
    }
</script>

<style scoped>

</style>